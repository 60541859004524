import TickImage from "@assets/tick.svg";
import CrossImage from "@assets/cross.svg";
import theme from "../../../../../theme";
import FlexContainer from "../../../../bricks/flexContainer";
import Separator from "../seperator";
import {
  ConfirmationTitleText,
  ConfirmationTotalsDiv,
  ConfirmationValueText,
  StyledImage
} from "./betSlipConfirmation.style";

export interface IConfirmationTotals {
  totalBets: number;
  totalFailedBets: number;
}

const ConfirmationTotals = ({
  totalBets,
  totalFailedBets
}: IConfirmationTotals) => {
  const totalBetsPlaced = totalBets - totalFailedBets;

  return (
    <ConfirmationTotalsDiv>
      <FlexContainer marginleft="6%" marginright="6%" alignitems="center">
        <ConfirmationTitleText>
          <FlexContainer alignitems="center">
            <StyledImage src={TickImage} alt="Tick Image" />
            Total Bets Placed
          </FlexContainer>
        </ConfirmationTitleText>
        <ConfirmationValueText> {totalBetsPlaced} </ConfirmationValueText>
      </FlexContainer>
      {totalFailedBets !== 0 && (
        <>
          <Separator borderColor={theme.colors.purpleGrey} sideMargin={6} />
          <FlexContainer marginleft="6%" marginright="6%" alignitems="center">
            <ConfirmationTitleText>
              <FlexContainer alignitems="center">
                <StyledImage src={CrossImage} alt="Cross Image" />
                Total Failed Bets
              </FlexContainer>
            </ConfirmationTitleText>
            <ConfirmationValueText> {totalFailedBets} </ConfirmationValueText>
          </FlexContainer>
        </>
      )}
    </ConfirmationTotalsDiv>
  );
};

export default ConfirmationTotals;
