import { useContext, useEffect } from "react";
import Selections from "./selections";
import Summary from "./summary";
import { LuckyPickContext } from "../context/luckyPickContext";
import { LP_ACTIONS } from "../store/luckyPickActions";
import { BetPlacementPayloadData } from "../../../../store/features/betslipSlice";

interface ILuckyPickMain {
  closureCallback: () => void;
  onLuckyPickBet: (luckyPickBet: BetPlacementPayloadData) => void;
}

const ViewMain = ({ closureCallback, onLuckyPickBet }: ILuckyPickMain) => {
  const { lpState, lpDispatch } = useContext(LuckyPickContext);

  useEffect(() => {
    lpDispatch({
      type: LP_ACTIONS.UPDATE_CANCEL_FUNCTION,
      func: closureCallback
    });
  }, []);

  return lpState.isInSummary ? (
    <Summary onStrikeBet={onLuckyPickBet} />
  ) : (
    <Selections />
  );
};

export default ViewMain;
