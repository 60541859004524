import styled from "styled-components";
import { useState } from "react";
import Button from "../../bricks/button";
import FlexContainer from "../../bricks/flexContainer";
import { MediaContainer } from "./leaguesCommon/leagueButtons.style";

interface ITab {
  $isActive: boolean;
}

const Tab = styled(Button)<ITab>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  border: ${(props) => `1px solid ${props.theme.colors.purplePrimary} `};
  background: ${(props) =>
    props.$isActive ? props.theme.colors.lightAliceBlue : "transparent"};
  color: ${(props) => props.theme.colors.purplePrimary};
  font-weight: ${(props) => (props.$isActive ? "bold" : "normal")};
  font-size: 1rem;
`;

interface IMobTabs {
  tabNames: string[];
  callbackForSelection: (selectedIndex: number) => void;
}

const MobTabs = ({ tabNames, callbackForSelection }: IMobTabs) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const handleTabClick = (index: number) => {
    setCurrentIndex(index);
    callbackForSelection(index);
  };

  return (
    <MediaContainer>
      <FlexContainer
        rowgap="5px"
        width="100%"
        justifycontent="space-evenly"
        direction="column">
        {tabNames.map((item, index) => {
          return (
            <Tab
              key={`MobTab${item}`}
              text={item}
              $isActive={index === currentIndex}
              onClick={() => {
                handleTabClick(index);
              }}
            />
          );
        })}
      </FlexContainer>
    </MediaContainer>
  );
};

export default MobTabs;
