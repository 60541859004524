/* eslint-disable  import/prefer-default-export */
const details = navigator.userAgent;

const regexp = /android|iphone|kindle|ipad/i;

const isMobileDevice = regexp.test(details);

export const isHandHeldDevice = (): boolean => isMobileDevice;

export const isAndroid = (): boolean => /Android/i.test(navigator.userAgent);
