import styled from "styled-components";
import theme from "../../../../../../../theme";
import Container from "../../../../../../bricks/container";
import FlexContainer from "../../../../../../bricks/flexContainer";

const AbandonedRaceTextMob = styled.div`
  width: 75%;
  padding: 10px 14px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  font-size: ${theme.font.size.s};
  margin: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.meetingsLightGrey};
`;
interface IMeetingDropdown {
  color: string;
  borderColor: string;
  closureCallback?: () => void;
  contentToOpen: JSX.Element | JSX.Element[];
  isAbandoned: boolean;
}

const MeetingDropdown = ({
  color,
  closureCallback,
  contentToOpen,
  borderColor,
  isAbandoned
}: IMeetingDropdown) => {
  return (
    <FlexContainer
      width="99%"
      direction="column"
      height="max-content"
      bgcolor={color}
      marginleft="-2px"
      border={`0px solid ${borderColor}`}
      borderbottomleftradius="7px"
      borderbottomrightradius="7px"
      margintop="-5px"
      zIndex={2}
      columngap="5px">
      {isAbandoned && (
        <Container
          display="flex"
          width="100%"
          height="max-content"
          bgcolor="white"
          zIndex={1}>
          <AbandonedRaceTextMob>
            When races are abandoned entries will be null and void and bets will
            get refunded.
          </AbandonedRaceTextMob>
        </Container>
      )}
      {!isAbandoned && (
        <Container
          width="100%"
          height="max-content"
          bgcolor="white"
          border={`1px solid ${borderColor}`}
          bordertop="0"
          borderbottomleftradius="7px"
          borderbottomrightradius="7px"
          boxshadow={`0px 2px 7px -1px ${theme.colors.dropShadowGrey}`}
          zIndex={5}>
          {contentToOpen}
        </Container>
      )}
      <Container
        width="100%"
        height="30px"
        margintop="-10px"
        bgcolor={theme.colors.meetingsLightGrey}
        border={`1px solid ${theme.colors.meetingsLightGrey}`}
        borderbottomleftradius="7px"
        borderbottomrightradius="7px"
        zIndex={1}
        paddingtop="20px">
        <Container onClick={closureCallback}>Close</Container>
      </Container>
    </FlexContainer>
  );
};

export default MeetingDropdown;
