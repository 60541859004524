import RefreshIcon from "@assets/regenerate-icon.svg";
import { useContext, useEffect, useState } from "react";
import Container from "../../../../../bricks/container";
import FlexContainer from "../../../../../bricks/flexContainer";
import theme from "../../../../../../theme";
import { LuckyPickContext } from "../../../context/luckyPickContext";
import { LP_ACTIONS } from "../../../store/luckyPickActions";
import {
  CURRENCY,
  DEFAULT_NUM_BETS
} from "../../../constants/luckyPickConstants";
import { formatInDecimalAndLocal } from "../../../../../../utils/numberUtils";
import ActionButtons from "../actionButtons";
import {
  TitleDiv,
  TitleValueDiv,
  LowStakeMessage,
  PayoutValueDiv,
  PayoutSubText,
  GeneratButtonText
} from "./consolidation.style";
import {
  getPotentialPayout,
  getStake,
  isInvalidBet
} from "../../../../../../utils/betConfirmationUtils";

const Consolidation = () => {
  const { lpState, lpDispatch } = useContext(LuckyPickContext);
  const {
    betTypeWinDouble,
    betTypePlaceTreble,
    betTypeWinPlaceDouble,
    cancelCallback
  } = lpState;

  const [totalPayout, setTotalPayout] = useState<number>(0);
  const [totalStake, setTotalStake] = useState<number>(0);
  const [numBets, setNumBets] = useState<number>(DEFAULT_NUM_BETS);

  const generateNew = () => {
    lpDispatch({ type: LP_ACTIONS.GENERATE_NEW });
  };

  const onPlaceABetClick = () => {
    lpDispatch({ type: LP_ACTIONS.SHOW_SUMMARY, toShow: true });
  };

  const handleCancel = () => {
    cancelCallback();
  };

  useEffect(() => {
    const payout =
      getPotentialPayout(betTypeWinDouble) +
      getPotentialPayout(betTypePlaceTreble) +
      getPotentialPayout(betTypeWinPlaceDouble);

    const stake =
      getStake(betTypeWinDouble) +
      getStake(betTypePlaceTreble) +
      getStake(betTypeWinPlaceDouble);

    let tmpNumBets = DEFAULT_NUM_BETS;

    if (isInvalidBet(betTypeWinDouble)) --tmpNumBets;
    if (isInvalidBet(betTypePlaceTreble)) --tmpNumBets;
    if (isInvalidBet(betTypeWinPlaceDouble)) --tmpNumBets;

    if (numBets !== tmpNumBets) setNumBets(tmpNumBets);

    setTotalPayout(payout + stake);
    setTotalStake(stake);
  }, [
    betTypeWinDouble.payout,
    betTypePlaceTreble.payout,
    betTypeWinPlaceDouble.payout
  ]);

  return (
    <>
      <FlexContainer
        width="100%"
        columngap="8px"
        margintop="10px"
        marginbottom="5px">
        <FlexContainer
          marginleft="10px"
          width="32%"
          bgcolor={`linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`}
          height="2.8rem"
          border={`1px ${theme.colors.purplePrimary} solid`}
          borderradius="5px"
          justifycontent="space-around">
          <Container
            display="grid"
            alignitems="center"
            borderradius="5px"
            marginleft="5px">
            <img src={RefreshIcon} />
          </Container>
          <GeneratButtonText onClick={generateNew}>
            Generate New
          </GeneratButtonText>
        </FlexContainer>

        <FlexContainer width="12%" direction="column" marginleft="8px">
          <TitleDiv>TOTAL</TitleDiv>
          <TitleValueDiv>
            {`${numBets} ${numBets > 1 ? "Bets" : "Bet"} `}
          </TitleValueDiv>
        </FlexContainer>

        <FlexContainer width="19%" direction="column">
          <TitleDiv>STAKE</TitleDiv>
          <TitleValueDiv>
            {`${CURRENCY}  ${formatInDecimalAndLocal(totalStake)}`}
          </TitleValueDiv>
        </FlexContainer>

        <FlexContainer width="28%" direction="column">
          <TitleDiv>
            <span>
              RETURNS
              <PayoutSubText>
                <sup>
                  <i> **Limits Apply</i>
                </sup>
              </PayoutSubText>
            </span>
          </TitleDiv>
          <PayoutValueDiv>
            {`${CURRENCY}  ${formatInDecimalAndLocal(totalPayout)}`}
          </PayoutValueDiv>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        width="100%"
        height="10px"
        justifycontent="center"
        marginbottom="3px">
        <Container
          width="93%"
          borderbottom={`1px ${theme.colors.grey} solid`}
          height="2px"
        />
      </FlexContainer>

      <FlexContainer
        width="100%"
        direction="column"
        alignitems="center"
        bgcolor="none">
        <LowStakeMessage>
          {totalStake >= 50
            ? "Your bet of R50 or more qualifies you for the DOUBLE YOUR MONEY bonus!"
            : "Your total stake is under R50. Place a bet of R50 or more for the DOUBLE YOUR MONEY bonus!"}
        </LowStakeMessage>
        <ActionButtons
          cancelCallback={handleCancel}
          confirmCallback={onPlaceABetClick}
          toDisableconfirmButton={numBets === 0}
          confirmButtonText="Place Bet"
        />
      </FlexContainer>
    </>
  );
};

export default Consolidation;
