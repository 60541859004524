import { useSelector } from "react-redux";
import RefreshLogo from "@assets/refresh-icon-purple.svg";
import { IMyLeagues } from ".";
import theme from "../../../../theme";

import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import LeagueItemCardMob from "../leaguesCommon/leagueItemCardMob";

import {
  LeagueActionButtons,
  MediaResponsivenessContainer
} from "../leaguesCommon/leagueButtons.style";
import { RootState } from "../../../../store/store";
import NoLeagueOrErrorMessage from "./noLeagueOrErrorMessage";
import { RefreshDiv } from "../../header/header.style";

const MyLeaguesMob = ({
  data,
  totalOwnedLeagues,
  createALeagueCallback,
  deleteALeagueCallback
}: IMyLeagues) => {
  const { errorMessageOnRejection } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <FlexContainer direction="column" width="100%">
      <FlexContainer
        width="100%"
        justifycontent="space-between"
        margintop="10px"
        marginbottom="10px">
        <FlexContainer
          width="100%"
          justifycontent="space-evenly"
          rowgap="5px"
          direction="column">
          <LeagueActionButtons
            text="Create A League"
            onClick={createALeagueCallback}
          />
          <LeagueActionButtons
            text="Delete A League"
            onClick={deleteALeagueCallback}
            disabled={totalOwnedLeagues === 0}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        display="flex"
        alignitems="center"
        textalign="left"
        minwidth="fit-content"
        fontSize="15px"
        color={theme.colors.purplePrimary}
        gap={5}>
        <MediaResponsivenessContainer>
          My Leagues
          <RefreshDiv src={RefreshLogo} onClick={refreshPage} />
        </MediaResponsivenessContainer>
      </FlexContainer>

      <Container maxheight="400px" bgcolor="" overflow="auto">
        {data?.length! > 0 &&
          data
            ?.filter((league) => league.leagueStatus)
            .map((item, index) => {
              return (
                <LeagueItemCardMob
                  key={`myleaguesMobRowActive${item.leagueName}`}
                  data={item}
                  index={index}
                  isActive
                />
              );
            })}
        {data?.length! > 0 &&
          data
            ?.filter((league) => !league.leagueStatus)
            .map((item, index) => {
              return (
                <>
                  {index === 0 && (
                    <Container
                      textalign="left"
                      fontSize="1.1rem"
                      color={theme.colors.purplePrimary}
                      margintop="5px">
                      Inactive leagues
                    </Container>
                  )}
                  <LeagueItemCardMob
                    key={`myleaguesMobRowInActive${item.leagueName}`}
                    data={item}
                    index={index}
                    isActive={false}
                  />
                </>
              );
            })}

        {!data?.length && !errorMessageOnRejection && (
          <NoLeagueOrErrorMessage text="You have not created a league yet." />
        )}
        {!data?.length && errorMessageOnRejection && (
          <NoLeagueOrErrorMessage text={errorMessageOnRejection} color="red" />
        )}
      </Container>
    </FlexContainer>
  );
};

export default MyLeaguesMob;
