import styled from "styled-components";
import theme from "../../../../../theme";
import Container from "../../../../bricks/container";
import { IBetSlipBet } from "../../betslip.models";
import BetSlipTotals from "../betSlipTotals/betSlipTotals";
import { isAllToBet } from "../../helpers/betUtils";
import Calculations from "../../helpers/calculations";
import {
  getBetName,
  getFlavourText
} from "../../../../../utils/betConfirmationUtils";

interface ISummaryItem {
  data: IBetSlipBet;
}

const Title = styled.div`
  width: calc(100%-2px);
  background-color: ${theme.colors.purpleLight};
  color: ${theme.colors.grey};
  border: 1px solid ${theme.colors.purpleGrey};
  text-align: start;
  padding: 5px 20px;
`;

const SummaryItem = ({ data }: ISummaryItem) => {
  const { betType, betCount, isFixedOdds, stakeToBet } = data;
  const finalBetCount = isAllToBet(data) ? 1 : betCount;
  const totalStake = betCount * stakeToBet;

  return (
    <Container>
      <Title>
        <b>{`${getBetName(betType, betCount)}: ${getFlavourText(finalBetCount)}`}</b>
      </Title>
      <BetSlipTotals
        stake={isAllToBet(data) ? stakeToBet : totalStake}
        payout={Calculations.getPotentialReturn(data)}
        hasSP={!isFixedOdds}
        shouldUseLightTotals
      />
    </Container>
  );
};

export default SummaryItem;
