import { useEffect, useState } from "react";
import { DIRECTIONS } from "../../../../../../constants";
import theme from "../../../../../../theme";
import ArrowHead from "../../../../../bricks/arrowHead";
import FlexContainer from "../../../../../bricks/flexContainer";
import TextInSpan from "../../../../../bricks/textInSpan";
import { IFailedBetItemData } from "../betSlipConfirmationFailed";
import ConfirmationItem from "../betSlipConfirmationItems";
import Container from "../../../../../bricks/container";

interface IFailedBetItemDropdown {
  title: string;
  items: IFailedBetItemData[];
  onOpenCallback: (group: string) => void;
  toOpen?: boolean;
}

const FailedBetItemDropdown = ({
  title,
  items,
  onOpenCallback,
  toOpen = false
}: IFailedBetItemDropdown) => {
  const [isOpen, setIsOpen] = useState<boolean>(toOpen);

  const summaryFailedItems = items.map((item, key) => (
    <ConfirmationItem
      key={`${key.toLocaleString()}${item.betTitle}`}
      betTitle={item.betTitle}
      payout={item.payout}
      totalStake={item.totalStake}
      failedReason={item.failureReason}
      odds={item.totalOdds}
    />
  ));

  useEffect(() => {
    if (toOpen !== isOpen) {
      setIsOpen(toOpen);
    }
  }, [toOpen]);

  const onGroupClick = () => {
    setIsOpen((prev) => !prev);
    onOpenCallback(!isOpen ? title : "");
  };

  return (
    <FlexContainer direction="column" width="100%" justifycontent="right">
      <FlexContainer
        justifycontent="flex-end"
        columngap="5px"
        width="99.5%"
        maxheight="38px"
        bgcolor={theme.colors.purpleLight}
        border={`1px solid ${theme.colors.purplePrimary}`}
        marginbottom="5px"
        cursor="pointer"
        onClick={onGroupClick}>
        <TextInSpan padding="10px" width="65%">
          {title.toUpperCase()}
        </TextInSpan>
        <Container
          width="14%"
          display="grid"
          placecontent="center"
          cursor="pointer">
          <ArrowHead
            thickness={2}
            direction={isOpen ? DIRECTIONS.up : DIRECTIONS.down}
            width="5px"
            height="5px"
            color="black"
          />
        </Container>
      </FlexContainer>

      {isOpen && summaryFailedItems}
    </FlexContainer>
  );
};

export default FailedBetItemDropdown;
