const colors = {
  purpleGrey: "#C5C0CF",
  purpleLight: "#F5F5FF",
  purpleLightSecondary: "#ECECF9",
  purpleSecondary: "#764CBF",
  purplePrimary: "#5C2D91",
  disabledPurpleSecondary: "#B39ED8",
  disabledPurplePrimary: "#A78FC2",
  yellowPrimary: "#FCEE21",
  yellowSecondary: "#ECCB00",
  primary: "#FFF",
  bodyPrimary: "#F3F3F4",
  bodySecondary: "#F0F0F5",
  aqua: "#EFF5F5",
  aquaSecondary: "#8080FF",
  grey: "#8C8C8C",
  blue: "#7575A3",
  blueMagenta: "#86868d",
  greyBlue: "#E0E0EB",
  lightRed: "#CBC9C9",
  orangePrimary: "#893101",
  darkGrey: "#666666",
  darkGrey2: "#4D4D4D",
  lightGrey: "#F2F2F2",
  lightGrey2: "#D0D0E2",
  lightGrey3: "#C0C0D8",
  lightGrey4: "#B7B7B7",
  refreshGreen: "#009C10",
  topUpGreen: "#29A736",
  titleAndTextPurple: "#6639A3",
  backgroundPurple: "#F4F6FD",
  burgerMenuSeparator: "#6639A324",
  resultsMercury: "#E5E5E5",
  resultsLightGrey: "#CCCCCC",
  resultsDarkGrey: "#707070",
  translucentWhite: "#FFFFFFCC",
  cardSelected: "#BBCAFF",
  horseCardYellow: "#FBD402",
  horseCardBackground: "#EEEEEE",
  cardShadow: "#00000029",
  horseCardResulted: "#BEE5B0",
  horseCardBlack: "#202020",
  horseCardOddsBlue: "#003DB8",
  horseCardTotalPicksGreen: "#458A00",
  horseCardStatusGrey: "#A2A2A2",
  horseCardScratchedRed: "#F31100",
  meetingsLightGrey: "#D5D5D5",
  abandonedPink: "#E70777",
  dropShadowGrey: "#C0B5B5",
  pickNowRed: "#D81F32",
  leaderboardPurple: "#5C2D92",
  meetingWebTimerGrey: "#47476B",
  lightAliceBlue: "#F4F6FD",
  lightGray: "#D1D1E0",
  lightGrey6: "#B3B3CC",
  lightGrey5: "#A3A3C2",
  greenPrimary: "#008000",
  lightGreen: "#00B300",
  bluishGray: "#7575A3",
  lbPointsPurple: "#975DD9",
  shadowGrey: "#C2C2D6",
  darkSteelGrey: "#5A5A8C",
  darkSteelGrey2: "#8585AD",
  gray80: "#CCCCCC",
  purpleLight2: "#BBCAFF",
  gray91: "#E8E8E8",
  viewLeaderboardGray: "#616161",
  inputPurple: "#4F3199",
  forcedSelection: "#DFC5FE"
};

const raceColors = (raceColor: string) => {
  const raceToColors: { [key: string]: string } = {
    "Borrowdale Park": "#5C2D90",
    "Hollywoodbets Durbanville": "#AF8352",
    Fairview: "#049CA9",
    "Flamingo Park Sand": "#5C2D90",
    "Hollywoodbets Greyville": "#2A96D6",
    "Hollywoodbets Kenilworth": "#DC4D8B",
    Mauritius: "#5C2D90",
    "Hollywoodbets Scottsville": "#2A96D6",
    Turffontein: "#F35B58",
    Vaal: "#FD7C38",
    "Happy Valley": "#FF3333",
    "Sha Tin": "#FF0000",
    Kranji: "#FF3333"
  };

  return raceToColors[raceColor] || "#CC6600";
};

const font = {
  family: {
    base: "Poppins, sans-serif",
    title: "Poppins, sans-serif"
  },
  size: {
    none: "0",
    xxs: "0.35rem",
    xs: "0.5rem",
    s: "0.7rem",
    sm: "0.85rem",
    m: "1rem",
    l: "1.25rem",
    xl: "1.75rem",
    xxl: "2rem",
    xxxl: "2.5rem"
  },
  weight: {
    light: 200,
    medium: 400,
    semiBold: 600,
    bold: 700,
    heavyBold: 800
  },
  style: {
    normal: "normal",
    italic: "italic",
    oblique: "oblique"
  },
  align: {
    center: "center",
    left: "left",
    right: "right",
    justify: "justify",
    default: "center",
    spaceBetween: "space-between"
  },
  lineHeight: 1.6
};

const theme = {
  colors,
  raceColors,
  font
};

export default theme;
