import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TickImage from "@assets/tick.svg";
import CrossImage from "@assets/cross.svg";
import {
  AliasAvailableImage,
  AliasMessageDiv,
  AliasMessageLabel,
  AliasRequiredMessage
} from "./aliasModal.style";
import { RootState } from "../../../../store/store";

interface IAliasCheckItemProps {
  toShowNameAvailable: boolean;
  aliasRequiredMessage: string;
  hasUserInput: boolean;
  toShowIncorrect: boolean;
}

const AliasMessage = ({
  toShowNameAvailable,
  aliasRequiredMessage,
  hasUserInput,
  toShowIncorrect
}: IAliasCheckItemProps) => {
  const { error, errorMessage } = useSelector((state: RootState) => state.user);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const img = toShowNameAvailable ? TickImage : CrossImage;
  const message = toShowNameAvailable
    ? "This punter alias is available."
    : "This punter alias is not available.";
  const shouldShowCheck = hasUserInput && aliasRequiredMessage.length === 0;

  useEffect(() => {
    setShowMessage(hasUserInput);
  });

  return (
    <AliasMessageDiv>
      {showMessage && (
        <AliasRequiredMessage>{aliasRequiredMessage}</AliasRequiredMessage>
      )}

      {shouldShowCheck && !toShowIncorrect && (
        <>
          <AliasAvailableImage src={img} />
          <AliasMessageLabel>{message}</AliasMessageLabel>
        </>
      )}

      {toShowIncorrect && (
        <AliasRequiredMessage>{error || errorMessage}</AliasRequiredMessage>
      )}
    </AliasMessageDiv>
  );
};

export default AliasMessage;
