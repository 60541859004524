import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";

import LeaderboardGrid from "../components/views/leaderboardGrid";
import TabScroller, {
  type ITabscrollerTabData
} from "../components/views/tabScroller";

import type { RootState } from "../store/store";
import {
  getAllMeetings,
  getUpcomingMeetings
} from "../store/features/meetingsSlice";

import { getCMSImages, setCurrentPage } from "../store/features/pagesSlice";
import PlayNextChallenge from "../components/views/playNextChallenge";
import Container from "../components/bricks/container";
import FlexContainer from "../components/bricks/flexContainer";
import SearchBar from "../components/views/leaderboardGrid/searchBar";
import {
  GetLeaderboardForMeeting,
  GetLeaderboardMeetingIdMetaData,
  GetOwnSelection,
  clearPuntersList,
  setLeaderboardData
} from "../store/features/leaderboardSlice";

import ErrorHandler from "../components/errorHandler";
import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import { sortRacesWithSAPriority } from "../utils/raceCourseUtils";
import Carousal from "../components/views/newHomePage/postLogin/Carousel/carousel";

const Leaderboard = () => {
  const dispatch = useDispatch();
  const pageRoute = useLocation().pathname;

  const { lbWorkingMetaData, lbMeetingsMetaData } = useSelector(
    (state: RootState) => state.leaderboard
  );

  const punterId = useSelector(
    (state: RootState) => state.user.data?.user?.punterId
  );
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const currentMeetingId = useRef<number | string>(-1);

  const { allMeetingsMetaData, meetingsMetaData: allUpcomingMeetingsMetaData } =
    useSelector((state: RootState) => state.meetings);

  const lbList = useRef<ITabscrollerTabData[] | null>(null);

  const playNextChallengeData = useRef<ITabscrollerTabData[] | null>(null);
  const [tabScrollerData, setTabScrollerData] = useState<
    ITabscrollerTabData[] | null
  >(null);
  const cmsImagesData = useSelector(
    (state: RootState) => state.pages.cmsImages
  );

  useEffect(() => {
    if (allUpcomingMeetingsMetaData) {
      const tsData: ITabscrollerTabData[] = sortRacesWithSAPriority(
        allUpcomingMeetingsMetaData
      ).map(
        (item) =>
          ({
            label: item.raceCourseName,
            metadata: item
          }) as ITabscrollerTabData
      );

      playNextChallengeData.current = tsData;
    }
  }, [allUpcomingMeetingsMetaData]);

  useEffect(() => {
    if (
      !lbWorkingMetaData &&
      allMeetingsMetaData?.length &&
      lbMeetingsMetaData
    ) {
      const targetDate: string = lbMeetingsMetaData.meetingDate as string;

      let eventsList = [];

      eventsList = allMeetingsMetaData.filter(
        (item) =>
          item.meetingDate === targetDate &&
          (item.meetingStatusId === 0 ||
            item.meetingStatusId === 1 ||
            item.meetingStatusId === 2)
      );

      if (eventsList.length > 0) {
        dispatch(setLeaderboardData(eventsList));
      }
    }
  }, [allMeetingsMetaData, lbMeetingsMetaData, punterId]);

  useEffect(() => {
    if (lbWorkingMetaData) {
      if (lbWorkingMetaData.length > 0) {
        const tsData: ITabscrollerTabData[] = sortRacesWithSAPriority(
          lbWorkingMetaData
        ).map(
          (item) =>
            ({
              label: item.raceCourseName,
              metadata: item
            }) as ITabscrollerTabData
        );

        lbList.current = tsData;

        setTabScrollerData(lbList.current);

        currentMeetingId.current = lbList.current[0].metadata.meetingId;

        if (punterId)
          dispatch(
            GetOwnSelection({ punterId, meetingId: currentMeetingId.current })
          );

        dispatch(
          GetLeaderboardForMeeting({
            punterId,
            meetingId: currentMeetingId.current
          })
        );
      }
    }
  }, [lbWorkingMetaData]);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    if (!cmsImagesData) dispatch(getCMSImages());
    dispatch(setCurrentPage(pageRoute));
    dispatch(getUpcomingMeetings());
    dispatch(getAllMeetings());
    dispatch(GetLeaderboardMeetingIdMetaData());

    return () => {
      dispatch(clearPuntersList());
    };
  }, []);

  const onTabClick = (index: number) => {
    dispatch(
      GetLeaderboardForMeeting({
        punterId,
        meetingId: lbList.current![index].metadata.meetingId
      })
    );
  };

  const getWidth = () => `${isDesktop ? "95%" : "85%"}`;

  return (
    <FlexContainer
      direction="column"
      rowgap="1rem"
      alignitems="center"
      bgcolor={theme.colors.bodySecondary}>
      <Container width="100%">
        <Carousal />
      </Container>

      <FlexContainer
        direction={isDesktop ? "row" : "column"}
        alignitems="center"
        width={getWidth()}>
        <Container
          fontWeight="bold"
          color={theme.colors.purplePrimary}
          width={isDesktop ? "35%" : "100%"}
          textalign={isDesktop ? "left" : "center"}
          height="max-content"
          fontSize={isDesktop ? "1.6vw" : "2.5vw"}
          marginbottom={isDesktop ? "0" : "10px"}>
          Daily Leaderboard :
        </Container>

        {tabScrollerData && (
          <Container width={isDesktop ? "65%" : "100%"}>
            <ErrorHandler>
              <TabScroller
                data={tabScrollerData}
                callback={onTabClick}
                currentIndex={0}
              />
            </ErrorHandler>
          </Container>
        )}
      </FlexContainer>

      {!isDesktop && (
        <>
          <Container marginbottom="10px" width="85%">
            <ErrorHandler>
              <SearchBar />
            </ErrorHandler>
            <ErrorHandler>
              <LeaderboardGrid isSelected />
            </ErrorHandler>
          </Container>
          <ErrorHandler>
            <PlayNextChallenge data={playNextChallengeData.current} />
          </ErrorHandler>
        </>
      )}

      {isDesktop && (
        <FlexContainer width="95%">
          <Container width="75%" height="75%">
            <ErrorHandler>
              <LeaderboardGrid isSelected />
            </ErrorHandler>
          </Container>
          <FlexContainer
            marginleft="10px"
            direction="column"
            width="25%"
            rowgap="10px">
            <ErrorHandler>
              <SearchBar />
            </ErrorHandler>
            <div style={{ marginTop: "0px", width: "100%" }}>
              <ErrorHandler>
                <PlayNextChallenge data={playNextChallengeData.current} />
              </ErrorHandler>
            </div>
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default Leaderboard;
