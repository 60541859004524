import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from "styled-components";
import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";
import UpandDownButton from "../../composites/upAndDownButton/upAndDownButton";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";

const SelectedTitleContainer = styled(Container)`
  width: 90%;
  height: 100%;
  background: #f4f6fd;
  display: grid;
  place-content: center;
  border-top-left-radius: 3px;
  @media screen and (min-width: 769px) {
    font-size: 1.3rem;
  }
`;

const ItemContainer = styled(Container)`
  background: #f4f6fd;
  border-bottom: 1px solid #c2c2d6;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  @media screen and (min-width: 769px) {
    font-size: 1.3rem;
  }
`;

const HeaderLabel = styled.label`
  height: 100%;
  margin-left: 10vw;
`;

export interface IDropdownItem {
  label: string;
  data: any;
}

interface IDropDown {
  data: IDropdownItem[];
  onSelection: (data: IDropdownItem) => void;
  meetingId?: string | null;
  setIsSelected?: (index: number) => void;
}

const DropDown = ({
  data,
  onSelection,
  meetingId,
  setIsSelected
}: IDropDown) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const [toOpenList, setToOpenList] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [list, setList] = useState<IDropdownItem[] | null>(null);

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const onToggleClicked = () => {
    setToOpenList(!toOpenList);
  };

  useEffect(() => {
    if (data?.length) {
      if (meetingId) {
        const tempIndex = data.findIndex((item) => item.data.id === meetingId);
        if (tempIndex > -1) setCurrentIndex(tempIndex);
      }

      setList(data);
    }
  }, [data]);

  const onItemClick = (index: number) => {
    setCurrentIndex(index);

    setToOpenList(false);

    onSelection(list![index]);
    if (setIsSelected) {
      setIsSelected(index);
    }
  };

  const handleOutSideClick = () => {
    setToOpenList(false);
  };

  useOutsideClick(handleOutSideClick, menuContainerRef);

  return (
    <FlexContainer
      direction="column"
      width="100%"
      fontSize="3vw"
      marginbottom={isDesktop ? "8px" : "0px"}
      ref={menuContainerRef}>
      <FlexContainer
        border="1px solid #5C2D91"
        bgcolor="none"
        height="7vw"
        maxheight="40px"
        bordertopleftradius="3px"
        bordertoprightradius="3px">
        <SelectedTitleContainer>
          {list?.length && (
            <HeaderLabel>
              {" "}
              {currentIndex >= 0
                ? list[currentIndex].label
                : "Please select a race"}{" "}
            </HeaderLabel>
          )}
        </SelectedTitleContainer>

        <Container
          width="10%"
          height="100%"
          onClick={onToggleClicked}
          marginleft="0px"
          margintop="0px"
          bgcolor="none"
          bordertoprightradius="3px"
          borderleft="1px solid #5C2D91"
          cursor="pointer">
          <UpandDownButton
            showDown={!toOpenList}
            isBoxType
            width="100%"
            height="100%"
            borderColor="#5C2D91"
            topRightRadius="3px"
          />
        </Container>
      </FlexContainer>

      {toOpenList && list?.length && (
        <Container
          height="36vh"
          bgcolor="green"
          overflow="scroll"
          paddingbottom="1px"
          border="1px solid #5C2D91"
          bordertop="0px"
          style={{ zIndex: 300 }}
          boxshadow="0px 0px  0px #a3a3c2">
          <FlexContainer direction="column" bgcolor="green">
            {list.map((item, index) => (
              <ItemContainer
                key={`dditem_${item.label}`}
                onClick={() => {
                  onItemClick(index);
                }}>
                {item.label}
              </ItemContainer>
            ))}
          </FlexContainer>
        </Container>
      )}
    </FlexContainer>
  );
};

export default DropDown;
