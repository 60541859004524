import ArrowPuntersLogo from "@assets/arrow-punters-logo.svg";
import RefreshLogo from "@assets/refresh-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import FlexContainer from "../../bricks/flexContainer";
import type { RootState } from "../../../store/store";
import {
  HeaderContainer,
  ArrowPuntersDiv,
  AccountTextDiv,
  BalanceDiv,
  MenuContainer,
  MenuLink,
  VerticalSeperatorDiv,
  RefreshDiv,
  AccountContainer
} from "./header.style";
import { getClientBalance } from "../../../store/features/userSlice";
import Button from "../../bricks/button";
import openLink from "../../../utils/pageUtils";
import { getBalanceFormatted } from "../../../utils/currencyFormatUtils";
import BurgerMenu from "../../composites/burger-menu/burgerMenu";
import { SeperatorDiv } from "../betslipNew/components/betTypeDisplay/betTypeDisplay.style";
import useNavigation from "../../../hooks/useNavigation";
import theme from "../../../theme";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import HowToPlayControl from "../newHomePage/preLogin/howToPlayControl";
import SignupOrLogin from "../newHomePage/preLogin/signupOrLogin";
import RegistrationModal from "../modals/registrationModal";
import { setNavigationInitiated } from "../../../store/features/pagesSlice";
import { IConfig } from "../../../store/features/configSlice";

const DISPLAY_MENU_ITEMS: Array<{
  text: string;
  route: string;
  dependencyFlag?: string;
}> = [
  { text: "Home", route: "home" },
  { text: "Leaderboard", route: "leaderboard" },
  { text: "Results", route: "results" },
  {
    text: "Friends Challenge",
    route: "friendsChallenge",
    dependencyFlag: "showFriendsLeague"
  }
];

const Header = () => {
  const dispatch = useDispatch();
  const { toNavigate } = useNavigation();

  const location = useLocation();
  const { pathname } = location;
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const { balance, userName, isLoggedIn } = useSelector(
    (state: RootState) => state.user
  );
  const config = useSelector((state: RootState) => state.config.data);

  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);

  const resolvedMenuItemsList = useMemo(() => {
    return DISPLAY_MENU_ITEMS.filter(
      (item) =>
        !item.dependencyFlag || config[item.dependencyFlag as keyof IConfig]
    );
  }, [config]);

  const RefreshBalance = async () => {
    dispatch(getClientBalance({ userName }));
  };

  const handleModalState = (shouldShow: boolean) => {
    window.scrollTo({ top: 0 });
    setOpenModal(shouldShow);
  };

  const handleMenuClick = (item: string) => {
    if (activeLink !== item) {
      setActiveLink(item);
      toNavigate(item);
    }
  };

  const topUp = () => {
    openLink(`${config.redeemVoucherUrl}`);
  };

  useEffect(() => {
    if (pathname !== "") {
      const item =
        pathname === "/"
          ? resolvedMenuItemsList[0]
          : resolvedMenuItemsList.find((item) => pathname.includes(item.route));

      if (item) {
        setActiveLink(item.text);
      }
    }
  }, [pathname]);

  const handleArrowClick = () => {
    if (activeLink !== "Home") {
      toNavigate("Home");
      setActiveLink(DISPLAY_MENU_ITEMS[0].text);
    }
    dispatch(setNavigationInitiated(true));
    toNavigate("Home");
    setTimeout(() => {
      dispatch(setNavigationInitiated(false));
    }, 1);
  };

  return (
    <HeaderContainer ref={headerRef}>
      {openModal && (
        <div style={{ position: "absolute" }}>
          <RegistrationModal
            xOffset="calc(50vw - 210px)"
            yOffset="100px"
            shadeHeightOffset="66px"
            cancelCallback={() => handleModalState(false)}
          />
        </div>
      )}
      {isDesktop ? (
        <FlexContainer
          width="100%"
          bgcolor="none"
          height="100%"
          color="white"
          justifycontent="space-between"
          alignitems="center">
          <FlexContainer columngap="16px" marginleft="8px" alignitems="center">
            <FlexContainer
              paddingleft={isLoggedIn ? "0px" : "35px"}
              width="30px"
              alignitems="center">
              <ArrowPuntersDiv
                onClick={handleArrowClick}
                src={ArrowPuntersLogo}
              />
            </FlexContainer>
            {isLoggedIn && (
              <MenuContainer>
                {resolvedMenuItemsList.map((item) => (
                  <MenuLink
                    key={`Header_${item.text}`}
                    text={item.text}
                    onClick={() => handleMenuClick(item.text)}
                    active={activeLink === item.text}
                  />
                ))}
              </MenuContainer>
            )}
            {!isLoggedIn && <HowToPlayControl />}
          </FlexContainer>
          {isLoggedIn && (
            <AccountContainer>
              <FlexContainer
                width="fit-content"
                direction="column"
                rowgap="5px"
                marginright="12px">
                <AccountTextDiv>Acc No</AccountTextDiv>
                <div style={{ marginLeft: "-1px" }}>
                  <SeperatorDiv borderColor="white" width="100%" />
                </div>
                <AccountTextDiv>{`${userName}`}</AccountTextDiv>
              </FlexContainer>
              <VerticalSeperatorDiv />
              <FlexContainer
                marginleft="11px"
                direction="column"
                width="fit-content"
                fontSize="0.8rem"
                textalign="left">
                <div>Balance</div>
                <FlexContainer>
                  <BalanceDiv>
                    {getBalanceFormatted(
                      Math.abs(balance?.balanceAvailable ?? 0)
                    )}
                  </BalanceDiv>
                  <RefreshDiv onClick={RefreshBalance} src={RefreshLogo} />
                </FlexContainer>
              </FlexContainer>
            </AccountContainer>
          )}
          <FlexContainer
            paddingright={isLoggedIn ? "8px" : "36px"}
            alignitems="center">
            {isLoggedIn && (
              <FlexContainer width="65px" height="28px" marginright="8px">
                <Button
                  onClick={topUp}
                  fontSize="0.8rem"
                  fontWeight="normal"
                  backgroundcolor={theme.colors.topUpGreen}
                  border="1px solid white"
                  text="Top Up"
                  height="100%"
                />
              </FlexContainer>
            )}
            {!isLoggedIn && isDesktop ? null : (
              <BurgerMenu isLoggedIn={isLoggedIn} />
            )}
          </FlexContainer>
          {!isLoggedIn && (
            <FlexContainer>
              <SignupOrLogin
                shouldBeInRow
                onRegisterClicked={() => handleModalState(true)}
              />
            </FlexContainer>
          )}
        </FlexContainer>
      ) : (
        <FlexContainer
          width="100%"
          bgcolor="none"
          height="100%"
          color="white"
          justifycontent="space-between"
          alignitems="center">
          <FlexContainer columngap="8px" marginleft="8px">
            {isLoggedIn && (
              <FlexContainer
                width="fit-content"
                direction="column"
                rowgap="5px">
                <AccountTextDiv>Acc No</AccountTextDiv>
                <SeperatorDiv borderColor="white" />
                <AccountTextDiv>{`${userName}`}</AccountTextDiv>
              </FlexContainer>
            )}
            <FlexContainer
              paddingleft={isLoggedIn ? "0px" : "35px"}
              width="30px">
              <ArrowPuntersDiv
                onClick={() => {
                  toNavigate("Home");
                }}
                src={ArrowPuntersLogo}
              />
            </FlexContainer>
          </FlexContainer>
          {isLoggedIn && (
            <FlexContainer
              direction="column"
              width="38px"
              alignitems="center"
              fontSize="0.8rem">
              <div>Balance</div>
              <FlexContainer
                direction="row"
                alignitems="center"
                paddingleft="8px">
                <BalanceDiv>
                  {getBalanceFormatted(
                    Math.abs(balance?.balanceAvailable ?? 0)
                  )}
                </BalanceDiv>
                <RefreshDiv onClick={RefreshBalance} src={RefreshLogo} />
              </FlexContainer>
            </FlexContainer>
          )}
          <FlexContainer
            paddingright={isLoggedIn ? "8px" : "36px"}
            alignitems="center">
            {isLoggedIn && (
              <FlexContainer width="65px" height="28px" marginright="8px">
                <Button
                  onClick={topUp}
                  fontSize="0.8rem"
                  fontWeight="normal"
                  backgroundcolor={theme.colors.topUpGreen}
                  border="1px solid white"
                  text="Top Up"
                  height="100%"
                />
              </FlexContainer>
            )}
            <BurgerMenu isLoggedIn={isLoggedIn} />
          </FlexContainer>
        </FlexContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
