import styled from "styled-components";
import { type INumberContainerBase, NumberContainerBase } from "./horseNumber";
import {
  formatInDecimal,
  getOrdinalForNumber
} from "../../../utils/numberUtils";
import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";
import {
  HorseNameLabel,
  HorseOddsLabel,
  SaddleNumberLabel,
  TotalSelectedLabel
} from "./horseCard.styles";

interface IHorseDataContainerBase extends INumberContainerBase {
  selected?: boolean;
  $inactive?: boolean;
  $isEven?: boolean;
  $isClosed?: boolean;
}

const HorseDataContainerBase = styled(
  NumberContainerBase
)<IHorseDataContainerBase>`
  display: flex;
  flex-direction: column;
  padding: 2px 5px 0px 4px;
  cursor: pointer;
  background: none;
`;

interface IHorsecard extends INumberContainerBase {
  index: number;
  name: string;
  odds: string | number;
  totalSelected: string | number;
  callback: () => void;
  isSelected: boolean;
  isScratched: boolean;
  isClosed: boolean;
  finishingPosition: number | string;
  horsePoints: number;
  saddleNumber: number;
  isPending: boolean;
  isReserve: boolean;
  isfavorite: boolean;
  totalOdds: number;
}

interface IHorseContainerStates {
  $isSelected: boolean;
  $hasResulted: boolean;
  $isClosed: boolean;
  $isEven: boolean;
  $isPending?: boolean;
  $isWinner?: boolean;
  $isScratched?: boolean;
  $isReserve?: boolean;
}

const MainContainer = styled(FlexContainer)`
    display:flex;
    flex-direction:column;
    background: none
    border-radius:5px;
    width:100%;
    height:100%;
    cursor: pointer;
`;

const getBackgroundColors = (
  layer: string,
  selected: boolean,
  pending: boolean,
  resulted: boolean,
  even: boolean,
  isWinner: boolean
) => {
  if (layer === "top") {
    if (pending && !resulted) return "#a2a2a2";
    if (isWinner) return "#5C2D91";
    if (selected) return "#eeeeee";
  } else {
    if (selected) return "#bbcaff";
    if (resulted) return "#bee5b0";
  }
  return even ? "#eeeeee" : "#f4f6fd";
};

const TopContainer = styled(FlexContainer)<IHorseContainerStates>`
  width: 100%;
  height: 30%;
  background: ${(props) =>
    getBackgroundColors(
      "top",
      props.$isSelected,
      props.$isPending,
      props.$hasResulted,
      props.$isEven,
      props.$isWinner!
    )};

  color: ${(props) =>
    (props.$isReserve && "grey") ||
    (props.$isWinner && "white") ||
    (props.$isScratched && "red") ||
    "#5C2D91"};

  font-size: 0.6rem;
`;

const MiddleContainer = styled(FlexContainer)<IHorseContainerStates>`
  width: 100%;
  height: 40%;
  background: ${(props) =>
    getBackgroundColors(
      "middle",
      props.$isSelected,
      props.$isPending,
      props.$hasResulted,
      props.$isEven,
      false
    )};

  color: #5c2d91;
  font-size: 0.6rem;
  cursor: pointer;
`;
const BottomContainer = styled(FlexContainer)<IHorseContainerStates>`
  width: 100%;
  height: 30%;
  background: ${(props) =>
    getBackgroundColors(
      "bottom",
      props.$isSelected,
      props.$isPending,
      props.$hasResulted,
      props.$isEven,
      false
    )};

  cursor: pointer;
`;
const getTopContainerText = ({
  selected,
  finishingPosition,
  horsePoints,
  isPending,
  isScratched,
  isReserve,
  odds,
  totalOdds
}: {
  selected: boolean;
  finishingPosition: number;
  horsePoints: number;
  isPending: boolean;
  isScratched: boolean;
  isReserve: boolean;
  odds: number | string;
  totalOdds: number;
}) => {
  if (isScratched) return "SCRATCHED";
  if (isReserve && totalOdds !== 0) return "RESERVE RUNNER";
  if (finishingPosition > 0)
    return `${finishingPosition}${getOrdinalForNumber(finishingPosition)} - ${
      finishingPosition === 1
        ? formatInDecimal(Number(horsePoints) + Number(odds) + 1)
        : formatInDecimal(Number(horsePoints))
    } Points`;
  if (isPending) return "PENDING";

  if (selected) return "Selected";

  return "";
};

const Horsecard = ({
  index,
  saddleNumber,
  name,
  odds,
  totalSelected,
  callback,
  isSelected,
  isScratched,
  isReserve,
  totalOdds,
  isClosed,
  finishingPosition,
  horsePoints,
  isPending,
  isfavorite
}: IHorsecard) => (
  <HorseDataContainerBase
    onClick={callback}
    height="3.3rem"
    width="97%"
    shadow="0px 0px  #e6e6e6"
    radius="0px">
    <MainContainer
      borderradius="5px"
      border="0px solid #4d73ff"
      boxshadow="1px 1px 4px #b3b3cc">
      <TopContainer
        bordertopleftradius="4px"
        bordertoprightradius="4px"
        cursor="pointer"
        $isSelected={isSelected}
        $isClosed={isClosed}
        $isEven={index % 2 === 0}
        $hasResulted={Number(finishingPosition) > 0}
        $isWinner={finishingPosition === 1}
        $isPending={isPending}
        $isScratched={isScratched}
        $isReserve={isReserve}>
        <span style={{ marginLeft: "5px" }}>
          {" "}
          {getTopContainerText({
            selected: isSelected,
            finishingPosition: Number(finishingPosition),
            horsePoints: Number(horsePoints),
            isPending,
            isScratched,
            isReserve,
            odds,
            totalOdds
          })}
        </span>
      </TopContainer>
      <MiddleContainer
        cursor="pointer"
        $isSelected={isSelected}
        $isClosed={isClosed}
        $isEven={index % 2 === 0}
        $hasResulted={Number(finishingPosition) > 0}
        $isWinner={finishingPosition === 1}>
        <FlexContainer
          width="99%"
          bgcolor=""
          padding="0px"
          columngap="4px"
          alignitems="center"
          paddingtop="2px"
          cursor="pointer">
          <SaddleNumberLabel> {saddleNumber}</SaddleNumberLabel>
          <HorseNameLabel isScratched={isScratched}> {name} </HorseNameLabel>
        </FlexContainer>
      </MiddleContainer>

      <BottomContainer
        borderbottomleftradius="5px"
        borderbottomrightradius="5px"
        $isSelected={isSelected}
        $isClosed={isClosed}
        $isEven={index % 2 === 0}
        $hasResulted={finishingPosition > 0}
        $isWinner={finishingPosition === 1}>
        <FlexContainer
          width="99%"
          bgcolor=""
          padding="0px"
          columngap="0px"
          alignitems="center"
          justifycontent="space-between"
          borderbottomleftradius="5px"
          borderbottomrightradius="5px"
          cursor="pointer">
          <HorseOddsLabel> {Number(odds) > 0 ? odds : ""}</HorseOddsLabel>
          {isfavorite && (
            <Container
              width="20%"
              height="100%"
              bgcolor="purplePrimary"
              borderradius="3px"
              color="white"
              paddingleft="2px"
              paddingright="2px"
              marginbottom="1px"
              maxwidth="40px"
              fontSize="0.7rem"
              marginleft="16%"
              cursor="inherit">
              Fav{" "}
            </Container>
          )}
          <TotalSelectedLabel> {totalSelected}</TotalSelectedLabel>
        </FlexContainer>
      </BottomContainer>
    </MainContainer>
  </HorseDataContainerBase>
);

export default Horsecard;
