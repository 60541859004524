import theme from "../../../../../theme";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";

export interface IBoxedMessage {
  children: JSX.Element | JSX.Element[];
  backgroundColor?: string;
  borderRadius?: string;
  marginTop?: string;
  marginBottom?: string;
  paddingTop?: string;
  paddingBottom?: string;
  gap?: string;
  paddingLeft?: string;
  paddingRight?: string;
  opacity?: number;
  width?: string;
}

const BoxedMessage = ({
  children,
  backgroundColor = theme.colors.backgroundPurple,
  borderRadius,
  marginTop,
  marginBottom,
  paddingTop,
  paddingBottom,
  gap,
  paddingLeft,
  paddingRight,
  opacity,
  width
}: IBoxedMessage) => {
  return (
    <FlexContainer
      direction="column"
      alignitems="center"
      width={width ?? "70%"}
      maxheight="max-content"
      borderradius={borderRadius ?? "5px"}
      position="relative"
      paddingtop={paddingTop ?? "10px"}
      paddingbottom={paddingBottom ?? "10px"}
      margintop={marginTop ?? "30px"}
      marginbottom={marginBottom ?? "0px"}
      rowgap={gap ?? "0px"}
      paddingleft={paddingLeft ?? "0px"}
      paddingright={paddingRight ?? "0px"}
      color={theme.colors.titleAndTextPurple}>
      <Container
        position="absolute"
        width="100%"
        height="100%"
        bgcolor={backgroundColor}
        borderradius="8px"
        opacity={opacity ?? 0.9}
        zIndex={0}
      />
      {children}
    </FlexContainer>
  );
};

export default BoxedMessage;
