import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import LeagueNotificationItem from "../leagueNotificationItem";
import { IMyLeagueItem } from "../../../../../store/features/friendsLeagueSlice";

const NotificationsRenderedList = ({
  currentLeague,
  confirmationCallback
}: {
  currentLeague: IMyLeagueItem;
  confirmationCallback: (
    title: string,
    description: string,
    yesCallback: () => void
  ) => void;
}) => {
  const { currentNotifications } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  return currentNotifications?.length! > 0
    ? currentNotifications?.map((item, index) => {
        return (
          <LeagueNotificationItem
            key={`${item.leagueNotificationId}${item.createdDate}`}
            index={index}
            message={item.message}
            date={item.createdDate}
            needApproval={item.notificationType === 0}
            punterId={item.punterId}
            leagueCode={currentLeague.leagueCode}
            callbackForReject={confirmationCallback}
          />
        );
      })
    : null;
};

export default NotificationsRenderedList;
