import {
  GetDefaultBackgroundColor,
  GetPositionDecorator
} from "../selectionsUtil";
import { IRaceRunner } from "../../../../../../../store/features/meetingsSlice";
import theme from "../../../../../../../theme";
import {
  CardContainer,
  TopCardDiv,
  MiddleCardDiv,
  SaddleSpan,
  NameSpan,
  BottomCardDiv,
  BottomTextSpan,
  FavDiv
} from "./horseCard.style";
import { formatInDecimal } from "../../../../../../../utils/numberUtils";

interface IHorseCard {
  index: number;
  horseInfo: IRaceRunner;
  onCardClicked: (runner: IRaceRunner, selected: boolean) => void;
  isSelected: boolean;
  isReserved: boolean;
  isPending?: boolean;
  isForcedSelection?: boolean;
}

const HorseCard = ({
  index,
  horseInfo,
  onCardClicked,
  isSelected,
  isReserved,
  isPending = false,
  isForcedSelection
}: IHorseCard) => {
  const {
    saddle,
    horseName,
    runnerStatus,
    totalSelected,
    hollywoodOdds,
    finishingPosition,
    horsePoints,
    favourite
  } = horseInfo;

  const isScratched = runnerStatus !== 1;
  const isResulted = finishingPosition !== 0;
  const isWinner = finishingPosition === 1;

  const onCardClick = () => {
    if (!isScratched && !isPending) {
      onCardClicked(horseInfo, !isSelected);
    }
  };

  const hasStatus = () => {
    return isSelected || isScratched || isResulted || isReserved || isPending;
  };

  const positionDecoration =
    GetPositionDecorator[Number(finishingPosition)] || "th";

  const winningPoints =
    finishingPosition === 1
      ? formatInDecimal(Number(horsePoints) + Number(hollywoodOdds) + 1)
      : formatInDecimal(Number(horsePoints));

  const getStatus = () => {
    if (isScratched) return "SCRATCHED";
    if (isResulted)
      return `${finishingPosition}${positionDecoration} - ${winningPoints} points`;
    if (isPending) return "PENDING";
    if (isSelected) return "Selected";
    if (isReserved) return "RESERVED RUNNER";
    return "";
  };

  return (
    <CardContainer
      background={GetDefaultBackgroundColor(index + 1)}
      isSelected={isSelected}
      isResulted={isResulted}
      isForcedSelection={isForcedSelection}
      onClick={onCardClick}>
      <TopCardDiv
        hasStatus={hasStatus()}
        isWinner={isWinner}
        isScratched={isScratched}>
        {hasStatus() && (
          <span style={{ paddingLeft: "8px" }}>{getStatus()}</span>
        )}
      </TopCardDiv>

      <MiddleCardDiv>
        <SaddleSpan>{saddle}</SaddleSpan>
        <NameSpan isScratched={isScratched}>{horseName}</NameSpan>
      </MiddleCardDiv>

      <BottomCardDiv>
        <BottomTextSpan align="left" color={theme.colors.horseCardOddsBlue}>
          {hollywoodOdds}
        </BottomTextSpan>
        {favourite && <FavDiv>Fav</FavDiv>}
        <BottomTextSpan
          align="right"
          color={theme.colors.horseCardTotalPicksGreen}>
          {totalSelected}
        </BottomTextSpan>
      </BottomCardDiv>
    </CardContainer>
  );
};

export default HorseCard;
