import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../components/bricks/container";
import FlexContainer from "../components/bricks/flexContainer";
import LeaguesHeader from "../components/views/friendsLeagues/leaguesHeader/leaguesHeader";
import MyLeagues from "../components/views/friendsLeagues/myLeagues";
import theme from "../theme";
import {
  displayManageForLeague,
  getStatsMeetings,
  openLeaderboard,
  reset,
  setIsQueriedLeagueToJoinChecked,
  shareALeague
} from "../store/features/friendsLeagueSlice";
import { RootState } from "../store/store";
import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import MobTabs from "../components/views/friendsLeagues/mobTabs";
import CreateLeagueModal from "../components/views/friendsLeagues/createLeague/createLeagueModal";
import JoinedLeagues from "../components/views/friendsLeagues/joinedLeagues";
import MyStats from "../components/views/friendsLeagues/mystats";
import ManageLeague from "../components/views/friendsLeagues/manageLeague";
import LeagueLeaderboard from "../components/views/friendsLeagues/league-leaderboard/leagueLeaderboard";
import {
  MOB_TABS,
  WEB_HEADER_TABS
} from "../components/views/friendsLeagues/constants/friendsLeaguesConstants";
import DeleteALeagueModal from "../components/views/friendsLeagues/deleteLeague/deleteLeagueModal";
import JoinALeagueModal from "../components/views/friendsLeagues/joinedLeagues/joinaLeagueModal";
import UseRefreshLeagues from "../components/views/friendsLeagues/hooks/useRefreshLeagues";

const FriendsLeague = () => {
  const dispatch = useDispatch();
  const { refreshLeaguesList } = UseRefreshLeagues();
  const [toShowCreateALeagueModal, setToShowCreateALeagueModal] =
    useState<boolean>(false);
  const [toShowShareALeagueModal, setToShowShareALeagueModal] =
    useState<boolean>(false);
  const [currentMobTabIndex, setCurrentMobTabIndex] = useState<number>(0);
  const [toShowStats, setToShowStats] = useState<boolean>(false);
  const [toShowJoinALeagueModal, setToShowJoinALeagueModal] =
    useState<boolean>(false);
  const [toShowManageLeague, setToShowManageLeague] = useState<boolean>(false);
  const [toShowDeleteALeagueModal, setToShowDeleteALeagueModal] =
    useState<boolean>(false);
  const [toShowLeaderboard, setToShowLeaderboard] = useState<boolean>(false);
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const [activeHeaderButton, setActiveHeaderButton] = useState<string>(
    WEB_HEADER_TABS[0]
  );

  const queriedLeagueCodeToJoin = useRef<string | null>(null);
  const { punterId } = useSelector((state: RootState) => state.user);

  const {
    myLeagues,
    shareALeagueData,
    joinedLeagues,
    newLeagueCreationData,
    toDisplayManageForLeague,
    currentLeagueForLeaderboard,
    isQueriedLeagueToJoinChecked
  } = useSelector((state: RootState) => state.friendsLeague);

  const hideManageLeague = () => {
    dispatch(displayManageForLeague(null));
  };

  const hideLeaderboard = () => {
    dispatch(openLeaderboard(null));
  };

  const isActionFromNonActiveButton = (callerName?: string) => {
    return callerName && activeHeaderButton !== callerName;
  };

  const handleCreateALeagueAction = (callerName?: string) => {
    if (isActionFromNonActiveButton(callerName)) {
      setActiveHeaderButton(callerName!);
    }

    hideManageLeague();
    setToShowCreateALeagueModal(true);
    if (toShowStats) {
      setToShowStats(false);
    }
  };

  const handleJoinALeagueCallback = (callerName?: string) => {
    if (isActionFromNonActiveButton(callerName))
      setActiveHeaderButton(callerName!);

    if (toShowStats) {
      setToShowStats(false);
    }
    setToShowJoinALeagueModal(true);
  };

  const handleDeleteALeagueCommandCallback = (callerName?: string) => {
    if (isActionFromNonActiveButton(callerName))
      setActiveHeaderButton(callerName!);

    if (toShowStats) {
      setToShowStats(false);
    }

    setToShowDeleteALeagueModal(true);
  };

  const handleMyStatsCallback = () => {
    if (!toShowStats) {
      setActiveHeaderButton("");
      hideManageLeague();
      hideLeaderboard();
      setToShowStats(true);
    }
  };

  const handleShowLeaguesCallback = (callerName: string) => {
    if (isActionFromNonActiveButton(callerName))
      setActiveHeaderButton(callerName);

    hideManageLeague();
    if (toShowStats) {
      setToShowStats(false);
    }
    if (toShowLeaderboard) {
      hideLeaderboard();
    }

    refreshLeaguesList();
  };

  const closureCallBackForCreateShareLeagueModal = () => {
    setActiveHeaderButton(WEB_HEADER_TABS[0]);

    if (toShowCreateALeagueModal) setToShowCreateALeagueModal(false);
    if (toShowShareALeagueModal) {
      dispatch(shareALeague(null));
      setToShowShareALeagueModal(false);
    }
    if (toShowJoinALeagueModal) setToShowJoinALeagueModal(false);
    if (toShowDeleteALeagueModal) setToShowDeleteALeagueModal(false);
  };

  const onMobTabClick = (index: number) => {
    setCurrentMobTabIndex(index);
    setToShowManageLeague(false);
    setToShowLeaderboard(false);
  };

  useEffect(() => {
    dispatch(getStatsMeetings());
  }, []);

  useEffect(() => {
    if (!isQueriedLeagueToJoinChecked) {
      const { origin } = window.location;
      const path = window.location.pathname;

      const codeArr = window.location.search?.split("?")[1]?.split("=");

      if (codeArr?.length) {
        queriedLeagueCodeToJoin.current = codeArr[1];
        setToShowJoinALeagueModal(true);
      }

      dispatch(setIsQueriedLeagueToJoinChecked(true));

      setTimeout(() => {
        window.history.replaceState({}, origin + path);
      }, 3000);
    }
  }, [myLeagues]);

  const getModalsAndManageLeaguesRenderables = useCallback(() => {
    return (
      <>
        {(toShowCreateALeagueModal || toShowShareALeagueModal) && (
          <CreateLeagueModal
            stateUpdaterCallback={closureCallBackForCreateShareLeagueModal}
            codeToshare={shareALeagueData?.leagueToShare}
          />
        )}
        {toShowJoinALeagueModal && (
          <JoinALeagueModal
            stateUpdaterCallback={closureCallBackForCreateShareLeagueModal}
            code={queriedLeagueCodeToJoin.current!}
          />
        )}
        {toShowManageLeague && (
          <ManageLeague
            leagues={myLeagues!}
            selectedLeagueName={toDisplayManageForLeague!}
          />
        )}
        {toShowDeleteALeagueModal && (
          <DeleteALeagueModal
            data={myLeagues}
            stateUpdaterCallback={closureCallBackForCreateShareLeagueModal}
          />
        )}
      </>
    );
  }, [
    shareALeagueData,
    myLeagues,
    toShowJoinALeagueModal,
    toShowManageLeague,
    toShowCreateALeagueModal,
    toShowShareALeagueModal,
    toShowDeleteALeagueModal
  ]);

  useEffect(() => {
    refreshLeaguesList();
  }, [punterId]);

  useEffect(() => {
    if (shareALeagueData?.leagueToShare) {
      setToShowShareALeagueModal(true);
    }
  }, [shareALeagueData]);

  useEffect(() => {
    if (newLeagueCreationData) {
      const {
        requestedLeagueToCreate,
        leagueCreatedJustNow,
        createdLeagueCode
      } = newLeagueCreationData;

      if (
        requestedLeagueToCreate === leagueCreatedJustNow &&
        createdLeagueCode
      ) {
        refreshLeaguesList();
      }
    }
  }, [newLeagueCreationData]);

  useEffect(() => {
    if (toDisplayManageForLeague && !toShowManageLeague) {
      setToShowManageLeague(true);
    } else if (!toDisplayManageForLeague && toShowManageLeague) {
      setToShowManageLeague(false);
    }
  }, [toDisplayManageForLeague]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (!toShowLeaderboard && currentLeagueForLeaderboard) {
      setToShowLeaderboard(true);
    } else if (!currentLeagueForLeaderboard && toShowLeaderboard) {
      setToShowLeaderboard(false);
    }
  }, [currentLeagueForLeaderboard]);

  const canShowOwnAndJoinedleagues = () => {
    return !toShowStats && !toShowManageLeague && !toShowLeaderboard;
  };

  const handleClose = () => {
    handleShowLeaguesCallback("Leagues");
    return true;
  };

  return (
    <FlexContainer
      width="100%"
      justifycontent="center"
      bgcolor={theme.colors.bodySecondary}
      paddingbottom="20px"
      paddingtop="20px"
      position="relative">
      {isDesktop ? (
        <FlexContainer
          direction="column"
          alignitems="center"
          width="95%"
          border={`1px solid ${theme.colors.purplePrimary}`}
          borderradius="5px"
          rowgap="20px"
          paddingbottom="20px"
          bgcolor="white">
          <LeaguesHeader
            actionCallBacks={[
              handleShowLeaguesCallback,
              handleJoinALeagueCallback,
              handleCreateALeagueAction,
              handleDeleteALeagueCommandCallback
            ]}
            statsCallback={handleMyStatsCallback}
            activeButton={activeHeaderButton}
            totalOwnedLeagues={myLeagues?.length ?? 0}
          />
          {toShowLeaderboard && myLeagues && (
            <LeagueLeaderboard selectedLeague={currentLeagueForLeaderboard!} />
          )}

          {canShowOwnAndJoinedleagues() && (
            <MyLeagues data={myLeagues} handleClose={handleClose} />
          )}
          {canShowOwnAndJoinedleagues() && (
            <JoinedLeagues data={joinedLeagues} handleClose={handleClose} />
          )}
          {toShowStats && <MyStats />}
          {getModalsAndManageLeaguesRenderables()}
        </FlexContainer>
      ) : (
        <FlexContainer
          direction="column"
          alignitems="center"
          width="95%"
          paddingbottom="2px">
          <Container
            width="100%"
            textalign="left"
            paddingbottom="15px"
            paddingtop="5px"
            fontSize="1.3rem"
            color={theme.colors.purplePrimary}
            fontWeight="bold">
            Friends Challenge
          </Container>

          <MobTabs tabNames={MOB_TABS} callbackForSelection={onMobTabClick} />

          {toShowLeaderboard && myLeagues && (
            <LeagueLeaderboard selectedLeague={currentLeagueForLeaderboard!} />
          )}
          {currentMobTabIndex === 0 &&
            !toShowManageLeague &&
            !toShowLeaderboard && (
              <MyLeagues
                data={myLeagues}
                createALeagueCallback={handleCreateALeagueAction}
                deleteALeagueCallback={handleDeleteALeagueCommandCallback}
                handleClose={handleClose}
                totalOwnedLeagues={myLeagues?.length ?? 0}
              />
            )}
          {currentMobTabIndex === 1 && (
            <JoinedLeagues
              data={joinedLeagues}
              joinALeagueCallback={handleJoinALeagueCallback}
              handleClose={handleClose}
            />
          )}
          {currentMobTabIndex === 2 && <MyStats />}
          {getModalsAndManageLeaguesRenderables()}
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default FriendsLeague;
