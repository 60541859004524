import React from "react";
import Container from "../../bricks/container";
import FlexContainer from "../../bricks/flexContainer";

interface IBurgerMenuIcon {
  width: string;
  height: string;
  callback: () => void;
  background?: string;
  color?: string;
  open: boolean;
}
const BurgerMenuIcon = ({
  width,
  height,
  background,
  color,
  callback,
  open
}: IBurgerMenuIcon) => {
  const innerColor = color ?? "#5C2D91";

  return (
    <Container
      bgcolor={background ?? "white"}
      width={width}
      height={height}
      borderradius={`calc(${width}/2)`}
      display="grid"
      placecontent="center">
      {open ? (
        <Container
          bgcolor="pink"
          display="grid"
          placecontent="center"
          marginbottom="2px"
          onClick={callback}>
          <Container
            position="relative"
            bgcolor="pink"
            width={`calc(${width}/1.6)`}>
            <Container
              position="absolute"
              width="100%"
              height={`calc(${height}*0.05)`}
              minheight="2px"
              bgcolor={innerColor}
              style={{ transform: "rotateY(0deg) rotate(45deg)" }}
            />
            <Container
              position="absolute"
              width="100%"
              height={`calc(${height}*0.05)`}
              minheight="2px"
              bgcolor={innerColor}
              style={{ transform: "rotateY(0deg) rotate(-45deg)" }}
            />
          </Container>
        </Container>
      ) : (
        <FlexContainer
          width={`calc(${width}/2)`}
          direction="column"
          rowgap={`calc(${height}*0.1)`}
          onClick={callback}>
          <Container
            width="100%"
            height={`calc(${height}*0.05)`}
            minheight="2px"
            bgcolor={innerColor}
          />
          <Container
            width="100%"
            height={`calc(${height}*0.05)`}
            minheight="2px"
            bgcolor={innerColor}
          />
          <Container
            width="100%"
            height={`calc(${height}*0.05)`}
            minheight="2px"
            bgcolor={innerColor}
          />
        </FlexContainer>
      )}
    </Container>
  );
};

export default BurgerMenuIcon;
