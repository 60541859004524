import { CloseIconContainer } from "./closeIcon.style";

interface ICloseIconProps {
  onClick: () => void;
}

const CloseIcon = ({ onClick }: ICloseIconProps) => {
  return <CloseIconContainer onClick={onClick}>&times;</CloseIconContainer>;
};

export default CloseIcon;
