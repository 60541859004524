/* eslint-disable  react/no-danger */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexContainer from "../../components/bricks/flexContainer";
import { getCMSData } from "../../store/features/pagesSlice";
import type { RootState } from "../../store/store";
import "../styles/pagestyles.css";
import theme from "../../theme";
import { FAQ_IDENTIFIER_TEXT } from "../../constants";

const FaqPage = () => {
  const dispatch = useDispatch();

  const cmsData = useSelector((state: RootState) => state.pages.cmsData);
  const cmsError = useSelector((state: RootState) => state.pages.cmsErrorText);

  const [bodyHtmlText, setBodyHtmlText] = useState<string>("");

  useEffect(() => {
    if (!cmsData) {
      dispatch(getCMSData());
    } else {
      setBodyHtmlText(cmsData[FAQ_IDENTIFIER_TEXT].body);
    }
  }, []);
  useEffect(() => {
    if (!bodyHtmlText && cmsData)
      setBodyHtmlText(cmsData[FAQ_IDENTIFIER_TEXT].body);
  }, [cmsData]);
  return (
    <FlexContainer
      height="70vh"
      direction="column"
      alignitems="center"
      rowgap="5px"
      bgcolor={theme.colors.bodyPrimary}
      paddingbottom="10px">
      {cmsError && (
        <span>
          Sorry, Frequently asked questions data is not available at the moment.
        </span>
      )}

      <div className="faq" dangerouslySetInnerHTML={{ __html: bodyHtmlText }} />
    </FlexContainer>
  );
};

export default FaqPage;
