import { memo, useContext } from "react";
import { IMyLeagueItem } from "../../../../store/features/friendsLeagueSlice";
import MyLeaguesWeb from "./myLeaguesWeb";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import MyLeaguesMob from "./myLeaguesMob";

export interface IMyLeagues {
  data: IMyLeagueItem[] | null;
  totalOwnedLeagues?: number;
  createALeagueCallback?: () => void;
  deleteALeagueCallback?: () => void;
  handleClose: () => boolean;
}

const MyLeagues = ({
  data,
  totalOwnedLeagues,
  createALeagueCallback,
  deleteALeagueCallback,
  handleClose
}: IMyLeagues) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return isDesktop ? (
    <MyLeaguesWeb leagues={data} handleClose={handleClose} />
  ) : (
    <MyLeaguesMob
      data={data}
      createALeagueCallback={createALeagueCallback}
      deleteALeagueCallback={deleteALeagueCallback}
      handleClose={handleClose}
      totalOwnedLeagues={totalOwnedLeagues}
    />
  );
};

export default memo(MyLeagues);
