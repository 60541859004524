import styled from "styled-components";

export const InputDiv = styled.input`
  fontsize: 1rem;
  border: none;
  color: #000;
  width: 90%;
  outline: none;
`;

export const ImageDiv = styled.img`
  width: auto;
  height: 100%;
  background: none;
  cursor: pointer;
  margin-right: -1px;
`;
