import { RACE_SELECTION_CACHE_NEW } from "../../../../../../constants";
import { IBetslipBetType as OldBetType } from "../../../../../../store/features/betslipSlice";
import {
  IMeetingSelections,
  IRaceRunner,
  ISavePunterSelectionReqData
} from "../../../../../../store/features/meetingsSlice";
import theme from "../../../../../../theme";
import { IBetslipBetType } from "../../../../betslipNew/betslip.models";

export const GetDefaultBackgroundColor = (index: number): string => {
  return index % 2 === 0
    ? theme.colors.backgroundPurple
    : theme.colors.horseCardBackground;
};

export const GetBackgroundColor = (
  defaultColor: string,
  isSelected?: boolean,
  isResulted?: boolean,
  isForcedSelection?: boolean
): string => {
  if (isForcedSelection ?? false) {
    return theme.colors.forcedSelection;
  }

  if (isSelected ?? false) {
    return theme.colors.cardSelected;
  }

  if (isResulted ?? false) {
    return theme.colors.horseCardResulted;
  }

  return defaultColor;
};

export const GetStatusBackgroundColor = (
  hasStatus: boolean,
  isWinner?: boolean
): string => {
  if (isWinner ?? false) {
    return theme.colors.purplePrimary;
  }

  return hasStatus ? theme.colors.horseCardStatusGrey : "none";
};

export const GetStatusTextColor = (
  isWinner?: boolean,
  isScratched?: boolean
): string => {
  if (isWinner ?? false) {
    return "white";
  }

  return isScratched
    ? theme.colors.horseCardScratchedRed
    : theme.colors.purplePrimary;
};

export const GetPositionDecorator: { [key: number]: string } = {
  1: "st",
  2: "nd",
  3: "rd"
};

export const SortRunners = (runnerA: IRaceRunner, runnerB: IRaceRunner) => {
  if (runnerA.finishingPosition === 0) {
    return 1;
  }

  if (runnerB.finishingPosition === 0) {
    return -1;
  }

  if (runnerA.finishingPosition > runnerB.finishingPosition) {
    return 1;
  }

  if (runnerA.finishingPosition < runnerB.finishingPosition) {
    return -1;
  }

  return 0;
};

export const findFavoriteHorse = (runners: IRaceRunner[]) => {
  const favouriteHorse = runners.filter((runner) => runner.favourite);
  const filteredList = runners.filter((runner) => runner.hollywoodOdds !== 0);

  if (favouriteHorse?.length) return favouriteHorse[0];

  if (filteredList?.length) {
    const favHorse = filteredList.reduce((runnerA, runnerB) =>
      runnerA.hollywoodOdds < runnerB.hollywoodOdds ? runnerA : runnerB
    );

    return favHorse;
  }

  return null;
};

export const UpdateWithFavHorse = (runners: IRaceRunner[]) => {
  const proxyRunners = [...runners];
  const favHorse = findFavoriteHorse(runners);

  if (favHorse) {
    const index = Number(favHorse.saddle) - 1;
    const newRunner = {
      ...favHorse,
      favourite: true
    };
    proxyRunners.splice(index, 1, newRunner);
  }

  return proxyRunners;
};

export const CachedSelectedRaces = (
  currentMeetingId: number
): IRaceRunner[] => {
  const parsedData = localStorage.getItem(
    currentMeetingId + RACE_SELECTION_CACHE_NEW
  );

  if (parsedData != null) return JSON.parse(parsedData);
  return [];
};

export const MapToSaveSelections = (
  currentMeetingId: number,
  punterId: number,
  runners: IRaceRunner[]
) => {
  const selections: ISavePunterSelectionReqData[] = [];

  runners.forEach((runner) => {
    selections.push({
      meetingId: currentMeetingId,
      punterId,
      raceNumber: Number(runner.raceNumber),
      selection: Number(runner.saddle),
      horseName: runner.horseName,
      isValid: true
    });
  });

  return selections;
};

export const MapToMeetingSelections = (runners: IRaceRunner[]) => {
  const selections: IMeetingSelections[] = [];

  runners.forEach((runner) => {
    selections.push({
      horseName: runner.horseName,
      points: Number(runner.horsePoints),
      raceNumber: Number(runner.raceNumber),
      saddle: Number(runner.saddle)
    });
  });

  return selections;
};

export const MapToBet = (oldBets: OldBetType[] | null) => {
  const newBets: IBetslipBetType[] = [];

  if (oldBets?.length) {
    oldBets.forEach((bet) => {
      newBets.push({
        deductionPercentageUsed: bet.deductionPercentageUsed,
        eventBetTypeDetailMapID: bet.eventBetTypeDetailMapID,
        eventDateTime: bet.eventDateTime,
        eventDetailID: bet.eventDetailID,
        eventDetailName: bet.eventDetailName,
        eventDetailNameExtra: bet.eventDetailNameExtra?.toString() ?? "",
        eventDetailNumber: bet.eventDetailNumber,
        eventDetailOfferedOdds: bet.eventDetailOfferedOdds,
        eventID: bet.eventID,
        eventName: bet.eventName,
        eventNumber: bet.eventNumber,
        fK_EventBetTypeMapID: bet.fK_EventBetTypeMapID,
        fK_EventDetailStatusID: Number(bet.fK_EventDetailStatusID),
        favourite: bet.favourite === 1,
        finishingPosition: bet.finishingPosition,
        firstTimer: bet.firstTimer
      });
    });
  }

  return newBets;
};
