import { useRef } from "react";
import Container from "../../../bricks/container";
import useOutsideClick from "../../../../hooks/useOutsideClick";

interface ILeagueModalbase {
  children: JSX.Element | JSX.Element[];
  closureCallback: () => void;
}

const LeagueModalbase = ({ children, closureCallback }: ILeagueModalbase) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(() => {
    closureCallback();
  }, ref);

  return (
    <Container
      position="absolute"
      bgcolor="none"
      width="100%"
      height="100%"
      zIndex={100}>
      <Container position="relative" bgcolor="none" width="100%" height="100%">
        <Container
          width="100%"
          height="100%"
          bgcolor="black"
          position="absolute"
          zIndex={110}
          opacity={0.4}
        />

        <Container
          ref={ref}
          position="relative"
          width="auto"
          maxwidth="300px"
          height="auto"
          bgcolor="white"
          zIndex={120}
          top="10%"
          transform="translate(-50%, 0)"
          left="50%"
          borderradius="10px"
          padding="10px">
          {children}
        </Container>
      </Container>
    </Container>
  );
};

export default LeagueModalbase;
