import { styled } from "styled-components";
import theme from "../../../theme";

interface IMeetingRaceCard {
  isMeetingRaceCard?: boolean;
  navigationText?: string;
}

interface INextPreviousButtonStyle {
  width?: string | number;
  height?: string | number;
  color?: string;
  background?: string;
  borderRaius?: string;
  border?: string;
  padding?: string;
  opacity?: number;
  isButtonEnabled?: boolean;
  isMeetingRaceCard?: boolean;
}

export interface INextPreviousButton extends INextPreviousButtonStyle {
  enabled?: boolean;
  callBack?: () => void;
  isNext?: boolean;
  isActive?: boolean;
  navigationText?: string;
}

interface IRenderableProps extends IMeetingRaceCard {
  next: boolean;
}

export const ActiveButton = styled.div<INextPreviousButtonStyle>`
  display: flex;
  width: ${(props) =>
    props.width ?? (props.isMeetingRaceCard ? "60px" : "65pt")};
  height: ${(props) =>
    props.height ?? (props.isMeetingRaceCard ? "20px" : "16pt")};
  color: white;
  background: ${(props) =>
    props.background ??
    `linear-gradient(${theme.colors.purpleSecondary},${theme.colors.purplePrimary} )`};
  border-radius: ${(props) =>
    props.borderRaius ?? (props.isMeetingRaceCard ? "10px" : "0")};
  border: ${(props) =>
    props.border ?? `1px solid ${theme.colors.purplePrimary}`};
  padding: ${(props) => props.padding ?? "0"};
  align-items: center;
  justify-content: space-evenly;
  opacity: ${(props) => props.opacity ?? 1};
  cursor: ${(props) => (props.isButtonEnabled ? "pointer" : "default")};
  margin-top: 12px;
  margin-right: 28px;
  margin-left: 28px;
`;

const SmallNavigationButton = styled.div<IMeetingRaceCard>`
  font-size: ${(props) => (props.isMeetingRaceCard ? "9pt" : "0.6rem")};
`;

const BigNavigationButton = styled.div<IMeetingRaceCard>`
  font-size: ${(props) => (props.isMeetingRaceCard ? "9pt" : "0.6rem")};
  font-weight: bold;
`;

export const getRightRenderable = ({
  next,
  isMeetingRaceCard,
  navigationText: meetingCardText
}: IRenderableProps) =>
  next ? (
    <>
      <SmallNavigationButton isMeetingRaceCard={isMeetingRaceCard}>
        {isMeetingRaceCard ? meetingCardText : "Next"}
      </SmallNavigationButton>
      <BigNavigationButton isMeetingRaceCard={isMeetingRaceCard}>
        {">"}
      </BigNavigationButton>
    </>
  ) : (
    <>
      <BigNavigationButton isMeetingRaceCard={isMeetingRaceCard}>
        {"<"}
      </BigNavigationButton>
      <SmallNavigationButton isMeetingRaceCard={isMeetingRaceCard}>
        {isMeetingRaceCard ? meetingCardText : "Previous"}
      </SmallNavigationButton>
    </>
  );
