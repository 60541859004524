import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IPastMeetingsInfoRequest,
  GetPastMeetingInformation,
  IPastMeetings
} from "../../../../../store/features/resultsSlice";
import { RootState } from "../../../../../store/store";
import GroupHeader from "../../../../composites/group-header/groupHeader";
import ResultsRaceCard from "../results-race-card/resultsRaceCard";
import useNavigation from "../../../../../hooks/useNavigation";
import {
  ResultsContainer,
  ResultsHeadings,
  ViewMoreLink
} from "./results.style";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";

const Results = () => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const { toNavigate } = useNavigation();

  const { userId } = useSelector((state: RootState) => state.user);
  const { resultsShown } = useSelector((state: RootState) => state.config.data);
  const resultedMeeting = useSelector(
    (state: RootState) => state.results.resultedMeetings
  );

  const pastMeetingsRequest: IPastMeetingsInfoRequest = {
    numberOfRaces: resultsShown
  };

  useEffect(() => {
    if (!resultedMeeting) {
      dispatch(GetPastMeetingInformation(pastMeetingsRequest));
    }
  }, [resultedMeeting]);

  const pastMeetings = resultedMeeting?.pastMeetings;

  const [openResultIndex, setOpenResultIndex] = useState<number>(-1);

  const onViewClick = (index: number) => {
    if (openResultIndex === index) {
      setOpenResultIndex(-1);
    } else {
      setOpenResultIndex(index);
    }
  };

  const results = pastMeetings?.map((meeting: IPastMeetings, index: number) => {
    return (
      <ResultsRaceCard
        key={`ResultsCard_${meeting.meetingId}`}
        index={index}
        raceCourseName={meeting.raceCourseName}
        raceDate={meeting.meetingDate}
        meetingId={meeting.meetingId}
        userId={userId}
        shouldOpen={openResultIndex === index}
        onViewClick={onViewClick}
      />
    );
  });

  return (
    <ResultsContainer isDesktop={isDesktop}>
      <ResultsHeadings>
        <GroupHeader
          color={isDesktop ? "black" : "white"}
          title="Results"
          imageBackgroundColor={isDesktop ? "black" : ""}
          toMask={isDesktop}
        />
        <ViewMoreLink
          isDesktop={isDesktop}
          onClick={() => toNavigate("Results")}>
          View More
        </ViewMoreLink>
      </ResultsHeadings>
      {results}
    </ResultsContainer>
  );
};

export default Results;
