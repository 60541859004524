import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IMyLeagueItem,
  displayManageForLeague,
  getLeagueMembers,
  getLeagueNotifications
} from "../../../../store/features/friendsLeagueSlice";

import FlexContainer from "../../../bricks/flexContainer";
import { RootState } from "../../../../store/store";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import NotificationsView from "./notifications/notificationsView";
import MembersView from "./members/membersView";
import ManageLeagueTabsMob from "./manageLeagueTabsMob";
import ConfirmationModal from "../../modals/confirmationModal";

interface IManageLeague {
  leagues: IMyLeagueItem[];
  selectedLeagueName?: string;
}

interface IManageLeagueConfirmationDialog {
  title: string;
  description: string;
  yesCallback: () => void | null;
}

const ManageLeague = ({ leagues, selectedLeagueName }: IManageLeague) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const [currentLeague, setCurrentLeague] = useState<IMyLeagueItem>(
    leagues.find((league) => league.leagueName === selectedLeagueName) ??
      leagues[0]
  );

  const [isInLeagueActivity, setIsInLeagueActivity] = useState<boolean>(true);

  const { currentLeagueToManage, toForceUpdateNotifications } = useSelector(
    (state: RootState) => state.friendsLeague
  );
  const dispatch = useDispatch();
  const { punterId } = useSelector((state: RootState) => state.user);

  const confirmationModalData = useRef<IManageLeagueConfirmationDialog | null>(
    null
  );

  const [toShowConfirmationDialog, setToShowConfirmationDialog] =
    useState(false);

  const attemptToGetLeagueMembers = () => {
    if (isDesktop || (!isDesktop && !isInLeagueActivity)) {
      dispatch(
        getLeagueMembers({ leagueCode: currentLeagueToManage!.leagueCode })
      );
    }
  };

  const callToGetNotificationsAndMembers = (leagueCode: string) => {
    dispatch(getLeagueNotifications({ punterId, leagueCode }));
    attemptToGetLeagueMembers();
  };

  const showConfirmationCallback = (
    title: string,
    description: string,
    yesCallback: () => void
  ) => {
    confirmationModalData.current = { title, description, yesCallback };

    setToShowConfirmationDialog(true);
  };

  const handlePostAssertionFromConfirmationDialog = () => {
    setToShowConfirmationDialog(false);
    confirmationModalData.current = null;
  };

  const handleYesClickFromConfirmation = (callback: () => void) => {
    callback();
    handlePostAssertionFromConfirmationDialog();
  };

  const getConfirmationDialog = () => {
    const { title, description, yesCallback } = confirmationModalData.current!;

    return title ? (
      <ConfirmationModal
        isModalOpen
        modalTitle={title}
        modalDescription={description}
        onYesCallback={() => {
          handleYesClickFromConfirmation(yesCallback);
        }}
        onNoCallback={handlePostAssertionFromConfirmationDialog}
        left={isDesktop ? 50 : 52}
        bottom={70}
        maxWidth="93%"
      />
    ) : null;
  };

  useEffect(() => {
    if (currentLeagueToManage) {
      callToGetNotificationsAndMembers(currentLeagueToManage.leagueCode);
    }
  }, [currentLeagueToManage]);

  useEffect(() => {
    return () => {
      dispatch(displayManageForLeague(null));
    };
  }, []);

  useEffect(() => {
    if (toForceUpdateNotifications) {
      callToGetNotificationsAndMembers(currentLeagueToManage!.leagueCode);
    }
  }, [toForceUpdateNotifications]);

  return isDesktop ? (
    <FlexContainer width="98%" columngap="20px" height="auto">
      <NotificationsView
        leagues={leagues}
        currentLeague={currentLeague}
        setCurrentLeague={setCurrentLeague}
        confirmationCallback={showConfirmationCallback}
      />
      <MembersView
        currentLeague={currentLeague}
        leagues={leagues}
        setCurrentLeague={setCurrentLeague}
        confirmationCallback={showConfirmationCallback}
      />

      {toShowConfirmationDialog && getConfirmationDialog()}
    </FlexContainer>
  ) : (
    <FlexContainer direction="column" width="100%" height="auto">
      <ManageLeagueTabsMob
        isInLeagueActivity={isInLeagueActivity}
        setIsInLeagueActivity={setIsInLeagueActivity}
      />

      {isInLeagueActivity && (
        <NotificationsView
          leagues={leagues}
          currentLeague={currentLeague}
          setCurrentLeague={setCurrentLeague}
          confirmationCallback={showConfirmationCallback}
        />
      )}
      {!isInLeagueActivity && (
        <MembersView
          currentLeague={currentLeague}
          leagues={leagues}
          setCurrentLeague={setCurrentLeague}
          confirmationCallback={showConfirmationCallback}
        />
      )}
      {toShowConfirmationDialog && getConfirmationDialog()}
    </FlexContainer>
  );
};

export default ManageLeague;
