import Container from "./container";
import Spinner from "./spinner";

interface ILocalSpinner {
  containerWidth: string;
  containerHeight: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  spinnerWidth?: string;
  spinnerHeight?: string;
}

const LocalSpinner = ({
  containerWidth,
  containerHeight,
  containerPaddingTop,
  containerPaddingBottom,
  spinnerWidth,
  spinnerHeight
}: ILocalSpinner) => {
  return (
    <Container
      width={containerWidth ?? "100%"}
      position="relative"
      height={containerHeight ?? "50px"}
      paddingtop={containerPaddingTop ?? "10px"}
      paddingbottom={containerPaddingBottom ?? "10px"}>
      <Spinner
        width={spinnerWidth ?? "50px"}
        height={spinnerHeight ?? "50px"}
      />
    </Container>
  );
};

export default LocalSpinner;
