import { useState, useEffect } from "react";
import { styled } from "styled-components";
import { useAuth } from "react-oidc-context";
import Button from "../../bricks/button";
import { setCookie } from "../../../utils/cookieUtils";

interface INotLoggedinModal {
  show: boolean;
  cancelCallback: () => void;
}

const Container = styled.div`
  background: white;
  border-radius: 10px;
  height: max-content;
  zindex: 81;
  position: absolute;
  margin: auto 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 0px 0px;
  row-gap: 10px;
  transform: translate(-50%, 50%);
  padding-bottom: 19px;
  font-size: 0.7rem;

  label:nth-of-type(1) {
    color: red;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 550px) {
    width: 90vw;
  }

  @media screen and (min-width: 551px) and (max-width: 768px) {
    width: 70vw;
  }

  @media screen and (min-width: 769px) and (max-width: 869px) {
    width: 60vw;
    font-size: 0.8rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 870px) and (max-width: 969px) {
    width: 50vw;
    font-size: 0.8rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 970px) and (max-width: 1069px) {
    width: 45vw;
    font-size: 0.8rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 1070px) and (max-width: 1369px) {
    width: 40vw;
    font-size: 0.9rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1.1rem;
    }
  }
  @media screen and (min-width: 1370px) and (max-width: 1679px) {
    width: 35vw;
    font-size: 0.9rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1.1rem;
    }
  }
  @media screen and (min-width: 1680px) {
    width: 25vw;
    font-size: 1rem;
    label:nth-of-type(1) {
      color: red;
      font-size: 1.1rem;
    }
  }
`;

const NotLoggedinModal = ({ show, cancelCallback }: INotLoggedinModal) => {
  const [toShow, setToShow] = useState(show);
  const auth = useAuth();

  const onLoginClick = () => {
    setToShow(false);

    const expiryDate = new Date();
    expiryDate.setTime(
      expiryDate.getTime() + (auth.isAuthenticated ? 2000 : 120000)
    );
    setCookie(
      "isInLoginprocess",
      "true",
      expiryDate,
      "/",
      window.location.hostname
    );
    auth
      .signinRedirect()
      .then((val) => val)
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err);
      });
  };
  useEffect(() => {
    setToShow(show);
  }, [show]);

  const onCancelClick = () => {
    cancelCallback();
    setToShow(false);
  };

  return toShow ? (
    <div
      style={{
        position: "absolute",
        zIndex: 80,
        width: "100%",
        height: "100%",
        fontFamily: "Poppins, sans-serif"
      }}>
      <div
        style={{
          position: "relative",
          background: "none",
          height: "100%",
          width: "100%"
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            opacity: 0.6,
            background: "black",
            display: "grid",
            placeContent: "center",
            top: "0",
            position: "fixed"
          }}
        />
        <Container>
          <span
            style={{
              width: "77%",
              textAlign: "center",
              borderBottom: "1px solid #f0f0f5",
              fontWeight: "bold",
              paddingTop: "5px",
              marginLeft: "10px",
              paddingBottom: "7px"
            }}>
            {" "}
            You're not logged in!
          </span>

          <span style={{ textAlign: "center", width: "80%", color: "#7575a3" }}>
            {" "}
            In order to make and submit your selections please login.
          </span>

          <Button
            onClick={onLoginClick}
            margintop="13px"
            width="7rem"
            height="1.8rem"
            gradient={{
              colors: ["#764CBF", "#5C2D91"],
              direction: "linear"
            }}
            text="Login"
            fontSize="0.8rem"
          />
          <Button
            onClick={onCancelClick}
            width="7rem"
            height="1.8rem"
            backgroundcolor="#fff)"
            fontcolor="#5C2D91"
            text="Cancel"
            border="1px solid #5C2D91"
            fontSize="0.8rem"
          />
        </Container>
      </div>
    </div>
  ) : (
    false
  );
};

export default NotLoggedinModal;
