import { useContext } from "react";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import FlexContainer from "../../../bricks/flexContainer";
import StatsByRacecourse from "./statsByRacecourse";
import StatsMilestones from "./statsMilestones";
import StatsNumberOfTimes from "./statsNumberOfTimes";

const MyStats = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <FlexContainer
      direction="column"
      width={`${isDesktop ? 98 : 100}%`}
      rowgap="20px">
      <StatsByRacecourse />
      <StatsNumberOfTimes />
      <StatsMilestones />
    </FlexContainer>
  );
};

export default MyStats;
