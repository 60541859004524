import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import LeaderboardFooter from "../../../../composites/leaderboard-card/leaderboard-footer/leaderboardFooter";
import LeaderboardCard from "../../../../composites/leaderboard-card/leaderboardCard";
import { MapFromLeaderboardPunterUnit } from "../../../../../utils/leaderboardUtils";

interface IRaceCardLeaderboard {
  currentMeetingId: number;
  punterId: number;
  meetingName: string;
  meetingDate: string;
  puntersPerPage: number;
  onPageClick: (page: number) => void;
}

const RaceCardLeaderboard = ({
  currentMeetingId,
  punterId,
  meetingName,
  meetingDate,
  puntersPerPage,
  onPageClick
}: IRaceCardLeaderboard) => {
  const { isFetching, currentPuntersList, totalPuntersWRTMeetings } =
    useSelector((state: RootState) => state.leaderboard);

  const totalPunters = totalPuntersWRTMeetings[currentMeetingId];

  const totalPages = Math.ceil(totalPunters / puntersPerPage);

  return (
    <div>
      <LeaderboardCard
        currentMeetingId={currentMeetingId}
        punterId={punterId}
        isFetching={isFetching}
        currentPuntersList={MapFromLeaderboardPunterUnit(currentPuntersList)}
      />
      <div style={{ width: "calc(100% + 16px)" }}>
        <LeaderboardFooter
          totalPages={totalPages}
          meetingName={meetingName}
          meetingDate={meetingDate}
          puntersPerPage={puntersPerPage}
          onPageClick={onPageClick}
        />
      </div>
    </div>
  );
};

export default RaceCardLeaderboard;
