import { useContext } from "react";
import { useSelector } from "react-redux";
import LeaderboardRank from "../leaderboard-rank/leaderboardRank";
import LeaderboardInfo from "../leaderboard-info/leaderboardInfo";
import LeaderboardPoints from "../leaderboard-points/leaderboardPoints";
import { LeaderboardItemDiv, LeaderboardCard } from "./LeaderboardItem.style";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import MiniCardWeb from "../leaderboard-mini-cards-web/leaderboardMiniCardsWeb";
import { RootState } from "../../../../store/store";
import PunterSelections from "../punterSelections";

interface ILeaderboardItem {
  index: number;
  punterId: string;
  meetingId: number;
  userId: number;
  punterRank: number;
  punterPoints: number;
  winners: number;
  secondPlaces: number;
  thirdPlaces: number;
  openSelections: boolean;
  onLeaderboardSelectionsClick: (
    index: number,
    meetingId: number,
    userId: number
  ) => void;
  isForFriendsLeague: boolean;
  canOpen?: boolean;
}

const LeaderboardItem = ({
  index,
  punterId,
  meetingId,
  userId,
  punterRank,
  punterPoints,
  winners,
  secondPlaces,
  thirdPlaces,
  openSelections,
  onLeaderboardSelectionsClick,
  isForFriendsLeague,
  canOpen = true
}: ILeaderboardItem) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const currentPunter = useSelector((state: RootState) => state.user.userId);

  return (
    <LeaderboardItemDiv
      isForFriendsLeagues={isForFriendsLeague}
      hasNoInteraction={!canOpen}>
      <LeaderboardCard
        hasNoInteraction={!canOpen}
        isCurrentPunter={currentPunter === punterId}
        onClick={() =>
          canOpen
            ? onLeaderboardSelectionsClick(index, meetingId, userId)
            : null
        }>
        <LeaderboardRank punterRank={punterRank} />
        <LeaderboardInfo
          punterId={punterId}
          winners={winners}
          secondPlaces={secondPlaces}
          thirdPlaces={thirdPlaces}
          openSelections={openSelections}
          isForFriendsLeague={isForFriendsLeague}
          rank={punterRank}
          canShowImages={punterPoints > 0}
        />
        <LeaderboardPoints punterPoints={punterPoints} punterId={punterId} />
        {isDesktop && (
          <MiniCardWeb
            winners={winners}
            secondPlaces={secondPlaces}
            thirdPlaces={thirdPlaces}
            openSelections={openSelections}
            isRestricted={!canOpen}
          />
        )}
      </LeaderboardCard>
      {openSelections && <PunterSelections />}
    </LeaderboardItemDiv>
  );
};

export default LeaderboardItem;
