import styled, { keyframes } from "styled-components";
import theme from "../../../theme";
import Button from "../../bricks/button";

interface IConfirmationModalComponent {
  isTakeABetModal: boolean;
}

interface IMainConfirmation extends IConfirmationModalComponent {
  translationX: number;
  translationY: number;
  left?: number;
  bottom?: number;
  width?: number;
}

interface IRotatingDiv {
  rotationDirection: string;
}

interface IBackgroundOverlay {
  startHeight: number;
}
interface IModalContainer {
  width?: string;
}

export const ModalContainer = styled.div<IModalContainer>`
  position: absolute;
  z-index: 80;
  width: ${(props) => props.width ?? "100%"};
  height: 100%;
  font-family: Poppins, sans-serif;
`;

export const ModalBody = styled.div`
  position: relative;
  background: none;
  height: 100%;
  width: 100%;
`;

export const BackgroundOverlay = styled.div<IBackgroundOverlay>`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  top: ${(props) => props.startHeight}px;
  left: 0;
  position: fixed;
`;

export const TextContainer = styled.div<IMainConfirmation>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 81;
  left: ${(props) => props.left}%;
  bottom: ${(props) => props.bottom}%;
  background: white;
  border-radius: 10px;
  max-width: ${(props) => (props.isTakeABetModal ? "20rem" : "101%")};
  min-width: 320px;
  height: fit-content;
  margin: auto 0;
  padding: 15px 0px 15px 0px;
  row-gap: 10px;
  transform: translate(
    ${(props) => `${props.translationX}%, ${props.translationY}%`}
  );
`;

export const StyledButton = styled(Button)`
  text-align: right;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
`;

export const ModalTitle = styled.span<IConfirmationModalComponent>`
  width: 77%;
  text-align: center;
  font-size: ${(props) => (props.isTakeABetModal ? "1.6rem" : "0.8rem")};
  color: ${theme.colors.purpleSecondary};
  border-bottom: 1px solid ${theme.colors.bodySecondary};
  font-weight: bolder;
  padding-top: 5px;
  margin-left: 10px;
  padding-bottom: 7px;
`;

export const ModalDescription = styled.span`
  font-size: 1rem
  text-align: center;
  width: 80%;
  color: ${theme.colors.blue};
`;

export const TitleUnderLine = styled("div")
  .withConfig({})
  .attrs({ className: "title" })`
  &.title {
    text-decoration: underline;
  }
`;

const Change = keyframes`
  from {
    color: red;
  }

  to {
    color: ${theme.colors.purpleSecondary};
  }
`;

const LeftRotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RightRotation = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export const RotatingDiv = styled.div<IRotatingDiv>`
  display: inline-block;
  animation: ${(props) =>
      props.rotationDirection === "left" ? LeftRotation : RightRotation}
    2s;
  height: 30px;
  padding: 16px;
`;

export const TermsText = styled.div`
  font-size: ${theme.font.size.xs};
  font-style: italic;
  color: ${theme.colors.blue};
`;

export const TextColor = styled.div`
  display: inline-block;
  animation: ${Change} 1s infinite alternate;
`;

export const FlyingMoneyStyling = styled.div`
  width: 30px;
  height: 30px;
`;
