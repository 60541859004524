import theme from "../../../../theme";
import Container from "../../../bricks/container";

interface ILeagueModalHeader {
  text: string;
}

const LeagueModalHeader = ({ text }: ILeagueModalHeader) => {
  return (
    <Container
      textalign="center"
      fontSize="1.2rem"
      fontWeight="bold"
      lineheight="1"
      color={theme.colors.purplePrimary}>
      {text}
    </Container>
  );
};

export default LeagueModalHeader;
