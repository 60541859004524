import { useContext } from "react";
import Icon from "@assets/exciting-jackpot-prizes.svg";
import BoxMessageWithIcon from "./common/boxMessageWithIcon";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";

const ExcitingJackpotDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const fontSize = isDesktop ? `${theme.font.size.s}` : "0.8rem";

  return (
    <BoxMessageWithIcon image={Icon} gap="15px" isDesktop={isDesktop}>
      <>
        <TextInSpan
          fontSize="1rem"
          fontWeight="bold"
          zIndex={20}
          lineHeight={1.5}>
          Exciting Jackpot Prizes
        </TextInSpan>
        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {
            "Our prize money varies daily, influenced by the number of runners on "
          }
          {"the card, and increases every meeting the jackpot isn't won. "}
          <b>
            Tipping all the winners can earn you between R125,000 for overseas
            courses and up to R1,000,000 for South African meetings.
          </b>
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {
            "And just like with our daily prizes, if you've placed bets totalling R50 or more, "
          }
          <b>your jackpot winnings are doubled.</b>
        </TextInSpan>
      </>
    </BoxMessageWithIcon>
  );
};

export default ExcitingJackpotDisplay;
