import styled from "styled-components";
import theme from "../../../../../../theme";
import {
  TargetDeviceWidth,
  mediaWidthSizeAbove,
  mediaWidthSizeBetween
} from "../../../../../../utils/mediaUtils";

export const TitleDiv = styled.div`
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.darkGrey};
  text-align: left;

  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.mediumMobile,
    ` font-size: ${theme.font.size.s}; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.sm}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: 0.9rem; `
  )};
`;

export const TitleValueDiv = styled.div`
  color: ${theme.colors.purplePrimary};
  font-weight: ${theme.font.weight.bold};
  text-align: left;
  padding-left: 1px;

  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.mediumMobile,
    ` font-size: ${theme.font.size.s}; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.sm}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.m}; `
  )};
`;

export const PayoutValueDiv = styled.div`
  color: ${theme.colors.refreshGreen};
  font-weight: ${theme.font.weight.bold};
  text-align: left;
  padding-left: 1px;
  overflow-wrap: anywhere;

  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.mediumMobile,
    ` font-size: ${theme.font.size.s}; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.sm}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.m}; `
  )};
`;

export const PayoutSubText = styled.span`
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.mediumMobile,
    ` font-size: ${theme.font.size.xxs}; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: 0.425rem; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: 0.5rem; `
  )};
`;

export const GeneratButtonText = styled.div`
  width: 70%;
  display: grid;
  align-items: center;
  color: ${theme.colors.purplePrimary};
  font-weight: bold;
  background-color: transparent;
  line-height: 1;
  padding: 2px;
  cursor: pointer;

  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.mediumMobile,
    ` font-size: ${theme.font.size.s}; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.m}; `
  )};
  ${mediaWidthSizeAbove(TargetDeviceWidth.smallDesktop, ` font-size: 1rem; `)};
`;

export const LowStakeMessage = styled.p`
  width: 80%;
  color: ${theme.colors.purplePrimary};
`;
