import theme from "../../../../theme";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import LeagueFormBase from "../modals/leagueFormBase";
import LeagueModalbase from "../modals/leagueModalBase";

interface IJoinALeagueConfirmation {
  backToHomeCallback: () => void;
}

const JoinALeagueConfirmation = ({
  backToHomeCallback
}: IJoinALeagueConfirmation) => {
  return (
    <LeagueModalbase closureCallback={backToHomeCallback}>
      <LeagueFormBase text="Join Request Sent!">
        <Container textalign="left" fontSize="0.9rem" color={theme.colors.grey}>
          Your join request has been sent to the creator of the league for
          approval.
        </Container>

        <Button
          margintop="20px"
          width="50%"
          background={`linear-gradient(
            ${theme.colors.purpleSecondary},
            ${theme.colors.purplePrimary}
            )`}
          text="Back to home"
          fontSize="0.7rem"
          onClick={backToHomeCallback}
        />
      </LeagueFormBase>
    </LeagueModalbase>
  );
};

export default JoinALeagueConfirmation;
