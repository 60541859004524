import styled from "styled-components";
import React from "react";
import theme from "../../../theme";
import { mediaWidthSizeAbove } from "../../../utils/mediaUtils";

export const HeaderContainer = styled.div`
  display: flex;
  padding: 8px;
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );

  font-family: Poppins, sans-serif;
  height: 50px;
  z-index: 85;
`;

export const ArrowPuntersDiv = styled.img`
  cursor: pointer;
`;

export const AccountTextDiv = styled.div`
  font-size: 0.8rem;
  line-height: 1;
  margin-top: 3px;
`;

export const BalanceDiv = styled.div`
  font-size: 1rem;
  direction: column;
  color: ${theme.colors.yellowPrimary};
`;

export const RefreshDiv = styled.img`
  cursor: pointer;
  padding: 5px;
  height: 12px;
  -webkit-tap-highlight-color: transparent;
`;

export const MenuContainer = styled.div`
  display: flex;
`;

export const MenuItem = styled.div<{ active: boolean }>`
  position: relative;
  padding: 10px 8px;
  cursor: pointer;
  color: white;

  &::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) =>
      props.active ? theme.colors.yellowPrimary : "transparent"};
    transform: scaleX(${(props) => (props.active ? 1 : 0)});
    transition: transform 0.2s ease;
    transform-origin: bottom right;
  }
`;

export const VerticalSeperatorDiv = styled.div`
  height: auto;
  margin: 0 10px;
  border: 1px solid ${theme.colors.lightGrey4};
`;

export interface IMenuLinkProps {
  text: string;
  onClick: () => void;
  active: boolean;
}

export const MenuLink = ({ text, onClick, active }: IMenuLinkProps) => (
  <MenuItem active={active} onClick={onClick}>
    {text}
  </MenuItem>
);

export const AccountContainer = styled.div`
  display: flex;
  flex-flow: row;
  place-content: normal flex-start;
  align-items: normal;
  gap: 0px;

  ${mediaWidthSizeAbove(767, `padding-right: 8%;`)}
  ${mediaWidthSizeAbove(850, `padding-right: 15%;`)}
  ${mediaWidthSizeAbove(1000, `padding-right: 20%;`)}
  ${mediaWidthSizeAbove(1400, `padding-right: 17%;`)}
  ${mediaWidthSizeAbove(1600, `padding-right: 14%;`)}
  ${mediaWidthSizeAbove(1800, `padding-right: 11%;`)}
`;
