import { styled } from "styled-components";
import FlexContainer from "../bricks/flexContainer";
import PhoneIcon from "../../assets/icon-phone-alt-1.svg";
import MailIcon from "../../assets/icon-material-email.svg";
import LocationIcon from "../../assets/Icon-map-postal-code.svg";

interface ICustomerSupport {
  width?: string;
}

const MainItemContainer = styled(FlexContainer)`
  color: #fff;
  font-weight: bold;
  column-gap: 10px;
  align-items: center;
  font-size: 0.7rem;

  @media screen and (min-width: 769px) and (max-width: 1437px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 0.8rem;
  }
`;

const Header = styled.label`
  margin-left: 1px;
  margin-bottom: 10px;
  @media screen and (min-width: 769px) and (max-width: 1437px) {
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 1.2rem;
  }
`;

const CustomerSupport = ({ width }: ICustomerSupport) => (
  <FlexContainer
    bgcolor="none"
    color="#fff"
    direction="column"
    alignitems="flex-start"
    rowgap="15px"
    width={`${width ?? "90%"}`}
    marginleft="0px">
    <Header> Customer Support</Header>

    <MainItemContainer>
      <img src={PhoneIcon} width="20px" height="20px" />
      <FlexContainer color="#fff" direction="column" alignitems="flex-start">
        <span style={{ margin: 0, color: "#D2CCDF", lineHeight: "1.3" }}>
          Tel :
        </span>
        <span
          style={{
            margin: 0,
            lineHeight: "1.3",
            marginLeft: "2px",
            fontWeight: "bold"
          }}>
          087 353 7634
        </span>
      </FlexContainer>
    </MainItemContainer>

    <MainItemContainer height="27px">
      <img src={MailIcon} width="20px" />

      <FlexContainer color="#fff" direction="column" alignitems="flex-start">
        <span style={{ margin: 0, color: "#D2CCDF", lineHeight: "1.3" }}>
          Email :
        </span>
        <span style={{ margin: 0, lineHeight: "1.3", fontWeight: "bold" }}>
          HWHelpline@hollywoodbets.net
        </span>
      </FlexContainer>
    </MainItemContainer>

    <MainItemContainer>
      <img src={PhoneIcon} width="20px" height="20px" />
      <FlexContainer color="#fff" direction="column" alignitems="flex-start">
        <span style={{ margin: 0, color: "#D2CCDF", lineHeight: "1.3" }}>
          Phone A Bet :
        </span>
        <span style={{ margin: 0, lineHeight: "1.3", fontWeight: "bold" }}>
          087 353 7634
        </span>
      </FlexContainer>
    </MainItemContainer>

    <MainItemContainer>
      <img src={LocationIcon} width="20px" height="20px" />
      <FlexContainer
        bgcolor="none"
        color="#fff"
        direction="column"
        alignitems="flex-start">
        <span style={{ margin: 0, color: "#D2CCDF", lineHeight: "1.3" }}>
          Postal Address :
        </span>
        <span
          style={{
            margin: 0,
            textAlign: "left",
            lineHeight: "1.3",
            fontWeight: "bold"
          }}>
          P.O.Box74234, Rochdale Park, 4034
        </span>
      </FlexContainer>
    </MainItemContainer>
  </FlexContainer>
);

export default CustomerSupport;
