import styled from "styled-components";
import theme from "../../../../theme";

interface ILeaderbordPointsProps {
  isCurrentPunter: boolean;
}

export const PointsContainerWeb = styled.div<ILeaderbordPointsProps>`
  column-gap: 5px;
  display: grid;
  align-content: center;
  width: 20%;
  height: 100%;
  background: ${(props) =>
    props.isCurrentPunter
      ? `linear-gradient(${theme.colors.yellowPrimary}, ${theme.colors.yellowSecondary})`
      : `linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.leaderboardPurple}
  )`};
  border: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : "none"};
`;

export const PointsContainerMob = styled.div<ILeaderbordPointsProps>`
  column-gap: 5px;
  display: grid;
  align-content: center;
  width: 20%;
  height: 100%;
  background: ${(props) =>
    props.isCurrentPunter
      ? `linear-gradient(${theme.colors.yellowPrimary}, ${theme.colors.yellowSecondary})`
      : `linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.leaderboardPurple}
  )`};
  border-top: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : "none"};
  border-bottom: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : "none"};
  border-left: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : "none"};
`;

export const PointsText = styled.div<ILeaderbordPointsProps>`
  color: ${(props) => (props.isCurrentPunter ? "black" : " white")};
  align-content: center;
  width: 100%;
`;
