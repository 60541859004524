import FirstPlace from "@assets/medal-gold.svg";
import SecondPlace from "@assets/medal-silver.svg";
import ThirdPlace from "@assets/medal-bronze.svg";

const GetLeaderboardPosition: { [key: number]: any } = {
  1: FirstPlace,
  2: SecondPlace,
  3: ThirdPlace
};

export default GetLeaderboardPosition;
