import { useSelector } from "react-redux";
import { useContext } from "react";
import { ILeaderboardPunterUnit } from "../../../../store/features/leaderboardSlice";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";

import { RootState } from "../../../../store/store";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import GridStandardFooterPageNumber from "./gridStandardFooterPageNumber";
import theme from "../../../../theme";
import NextPreviousButton from "../../../composites/next-previous-button/nextPreviousButton";

interface ILeaguesFooter {
  previousCallback: () => void;
  nextCallback: () => void;
  currentPuntersList: ILeaderboardPunterUnit[];
}

const LeaguesFooter = ({
  previousCallback,
  nextCallback,

  currentPuntersList
}: ILeaguesFooter) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const {
    currentMeetingForLeaderboard,
    currentLeagueForLeaderboard,
    currentLeaderboardTotalPages,
    currentLeaderboardPageData,
    currentPageNumber,
    isFetchingLeaderboard
  } = useSelector((state: RootState) => state.friendsLeague);

  const isEnabled = () => {
    return (
      currentPageNumber < currentLeaderboardTotalPages && !isFetchingLeaderboard
    );
  };

  return isDesktop ? (
    <FlexContainer
      bgcolor={theme.colors.lightAliceBlue}
      height="auto"
      padding="12px 0 12px 0"
      border={`1px solid ${theme.colors.purplePrimary}`}
      borderradius="0 0 5px 5px"
      boxshadow={`1px 1px 5px ${theme.colors.lightGray}`}
      margintop="7px"
      justifycontent="space-between">
      <FlexContainer
        direction="column"
        width="50%"
        alignitems="center"
        paddingleft="5px">
        <Container
          fontSize="max(1rem,1.3vw)"
          fontWeight="bold"
          color={theme.colors.purplePrimary}
          margintop="6px"
          textalign="left"
          width="100%">
          {" "}
          {currentLeagueForLeaderboard?.leagueName}{" "}
        </Container>

        <Container
          fontSize="max(0.9rem,1vw)"
          color={theme.colors.purplePrimary}
          textalign="left"
          width="100%">
          {" "}
          {currentMeetingForLeaderboard?.name}{" "}
        </Container>
      </FlexContainer>

      <FlexContainer
        width="40%"
        justifycontent="flex-end"
        alignitems="center"
        marginright="10px"
        columngap="10px">
        <NextPreviousButton
          enabled={currentPageNumber > 1 && !isFetchingLeaderboard}
          callBack={previousCallback}
          isActive={!!currentLeaderboardPageData?.length}
          width="min(10vw,70px)"
          height="min(3vw,24px)"
        />

        <Container
          fontSize="max(0.7rem,1vw)"
          margin="auto 0"
          color={theme.colors.purplePrimary}
          lineheight="1">
          <GridStandardFooterPageNumber
            currentPuntersList={currentPuntersList}
            totalNumPunters={-1}
            totalPages={currentLeaderboardTotalPages}
            currentPage={currentPageNumber}
          />
        </Container>
        <NextPreviousButton
          isNext
          enabled={isEnabled()}
          callBack={nextCallback}
          isActive={!!currentLeaderboardPageData?.length}
          width="min(10vw,70px)"
          height="min(3vw,24px)"
        />
      </FlexContainer>
    </FlexContainer>
  ) : (
    <FlexContainer
      direction="column"
      alignitems="center"
      rowgap="7px"
      bgcolor={theme.colors.lightAliceBlue}
      height="auto"
      padding="12px 0 12px 0"
      border={`1px solid ${theme.colors.purplePrimary}`}
      borderradius="0 0 5px 5px"
      margintop="7px">
      <FlexContainer width="90%" justifycontent="space-between">
        <NextPreviousButton
          enabled={currentPageNumber > 1 && !isFetchingLeaderboard}
          callBack={previousCallback}
          isActive={!!currentLeaderboardPageData?.length}
        />

        <Container
          fontSize="0.7rem"
          margin="auto 0"
          color={theme.colors.purplePrimary}>
          <GridStandardFooterPageNumber
            currentPuntersList={currentPuntersList}
            totalNumPunters={-1}
            totalPages={currentLeaderboardTotalPages}
            currentPage={currentPageNumber}
          />
        </Container>

        <NextPreviousButton
          isNext
          enabled={isEnabled()}
          callBack={nextCallback}
          isActive={!!currentLeaderboardPageData?.length}
        />
      </FlexContainer>

      <>
        <Container
          fontSize="0.7rem"
          fontWeight="bold"
          color={theme.colors.purplePrimary}
          margintop="6px">
          {" "}
          {currentLeagueForLeaderboard?.leagueName}{" "}
        </Container>

        <Container
          fontSize="0.55rem"
          color={theme.colors.purplePrimary}
          margintop="-4px">
          {" "}
          {currentMeetingForLeaderboard?.name}{" "}
        </Container>
      </>
    </FlexContainer>
  );
};

export default LeaguesFooter;
