import ReactLoading from "react-loading";
import styled from "styled-components";
import theme from "../../../../../theme";

export const StyledLoadingDiv = styled.div`
  text-align: center;
`;

const LoadingSpinner = () => (
  <StyledLoadingDiv>
    <ReactLoading
      type="balls"
      color={theme.colors.aquaSecondary}
      height="80px"
      width="80px"
    />
  </StyledLoadingDiv>
);

export default LoadingSpinner;
