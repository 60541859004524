import { useContext, useMemo } from "react";
import JacktopTrophyIcon from "@assets/jackpot-trophy.svg";
import theme from "../../../../../../../theme";
import Container from "../../../../../../bricks/container";
import FlexContainer from "../../../../../../bricks/flexContainer";
import MeetingFlag from "./meetingFlag";
import { getIntlFormat } from "../../../../../../../utils/currencyFormatUtils";
import Image from "../../../../../../bricks/image";
import TextInSpan from "../../../../../../bricks/textInSpan";
import { FORMATS, getFormatted } from "../../../../../../../utils/dateUtils";
import { MediaQueriesContext } from "../../../../../../../contexts/mediaQueriesContext";

interface INameAndJackpot {
  clickCallback: () => void;
  raceCourseName: string;
  startTime: string;
  jackpot: number;
  width: string;
  isHover?: boolean;
  isExpanded?: boolean;
  isAbandoned: boolean;
}

const NameAndJackpot = ({
  clickCallback,
  raceCourseName,
  startTime,
  jackpot,
  width,
  isHover,
  isExpanded,
  isAbandoned
}: INameAndJackpot) => {
  const getStartTime = useMemo(() => {
    return !isAbandoned ? `(${getFormatted(startTime, FORMATS["HH:MM"])})` : "";
  }, [startTime]);
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const getColor = () => {
    if (isDesktop) {
      if (isExpanded || isHover) return theme.colors.purplePrimary;
      return "black";
    }
    return theme.colors.purplePrimary;
  };

  return (
    <FlexContainer
      cursor="pointer"
      width={width}
      paddingleft="2px"
      borderradius="7px"
      borderbottomrightradius="0px"
      bordertoprightradius="0px"
      alignitems="center"
      paddingtop={isDesktop ? "0px" : "10px"}
      paddingbottom={isDesktop ? "3px" : "10px"}
      onClick={clickCallback}>
      <MeetingFlag width="20%" raceCourseName={raceCourseName} />

      <FlexContainer
        cursor="pointer"
        width="80%"
        direction="column"
        fontSize="0.6rem"
        alignitems="left"
        height="max-content">
        <Container
          cursor="pointer"
          color={getColor()}
          textalign="left"
          fontSize={isDesktop ? "min(1.5vw,1.2rem)" : "min(2.9vw,0.8rem)"}>
          <b>{raceCourseName}</b> {getStartTime}
        </Container>

        {!isAbandoned && (
          <FlexContainer
            cursor="pointer"
            height="20px"
            justifycontent="flex-start"
            alignitems="center"
            columngap="5px">
            <Container
              cursor="pointer"
              border={`1px solid ${theme.colors.purpleSecondary}`}
              borderradius="4px"
              maxheight="18px"
              width="18px"
              position="grid"
              placecontent="center">
              <Image src={JacktopTrophyIcon} scaleX={0.7} scaleY={0.7} />
            </Container>

            <FlexContainer
              direction="column"
              alignitems="flex-end"
              cursor="pointer">
              <Container
                cursor="pointer"
                fontSize={isDesktop ? "min(1.3vw,0.8rem)" : "min(2.4vw,0.7rem)"}
                lineheight="1"
                margintop="5px">
                {getIntlFormat(jackpot / 2)} + {getIntlFormat(jackpot / 2)} =
                <b> {getIntlFormat(jackpot)} </b>
              </Container>
              <TextInSpan
                width="max-content"
                fontSize="0.4rem"
                lineHeight={1.5}>
                <i>*terms and condiions apply</i>
              </TextInSpan>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default NameAndJackpot;
