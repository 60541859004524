import Button from "../../bricks/button";
import theme from "../../../theme";
import {
  BackgroundOverlay,
  ModalBody,
  ModalContainer,
  ModalDescription,
  ModalTitle,
  TextContainer,
  StyledButton
} from "./confirmationModal.style";

interface IRetryConfirmation {
  isModalOpen: boolean;
  modalTitle: string;
  modalDescription: string;
  shadowStartHeight?: number;
  translationX?: number;
  translationY?: number;
  onYesCallback: () => void;
  onNoCallback: () => void;
  left: number;
  bottom: number;
  maxWidth?: string;
}

const ConfirmationModal = ({
  isModalOpen: isOpen,
  modalTitle,
  modalDescription,
  onYesCallback: retryFailedBetsCallback,
  onNoCallback: closeModalCallback,
  maxWidth,
  shadowStartHeight = 0,
  translationX = -50,
  translationY = 50,
  left = 50,
  bottom = 35
}: IRetryConfirmation) => {
  return (
    isOpen && (
      <ModalContainer width={maxWidth}>
        <ModalBody>
          <BackgroundOverlay startHeight={shadowStartHeight} />
          <TextContainer
            translationX={translationX}
            translationY={translationY}
            isTakeABetModal={false}
            left={left}
            bottom={bottom}>
            <ModalTitle isTakeABetModal={false}>{modalTitle}</ModalTitle>
            <ModalDescription>{modalDescription}</ModalDescription>
            <Button
              onClick={retryFailedBetsCallback}
              margintop="13px"
              width="50%"
              height="32px"
              gradient={{
                colors: [
                  `${theme.colors.purpleSecondary}`,
                  `${theme.colors.purplePrimary}`
                ],
                direction: "linear"
              }}
              text="Yes"
            />
            <StyledButton
              onClick={closeModalCallback}
              width="50%"
              height="32px"
              backgroundcolor={theme.colors.primary}
              fontcolor={theme.colors.purplePrimary}
              text="No, thank you"
              border={`1px solid ${theme.colors.purplePrimary}`}
            />
          </TextContainer>
        </ModalBody>
      </ModalContainer>
    )
  );
};

export default ConfirmationModal;
