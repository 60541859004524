import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import HomeBackgroundImage from "@assets/home-page-background.jpg";
import BetSlipView from "./components/betSlipView";
import {
  IMeeting,
  IMeetingMetaData
} from "../../../store/features/meetingsSlice";
import { RootState } from "../../../store/store";
import {
  IBetSlipData,
  IBetSlipRace,
  IBetStrikeRetryRequest,
  IBetslipBetType
} from "./betslip.models";
import { processBetslip } from "./hooks/processBetslip";
import {
  BetSlipHoverParent,
  BetSlipBackGroundShade,
  BackgroundImage
} from "./betSlip.style";
import {
  retryFailedBets,
  setInitialState,
  strikeBet
} from "../../../store/features/newBetSlipSlice";
import { getClientBalance } from "../../../store/features/userSlice";
import BetslipConfirmation from "./components/betslipConfirmation/betslipConfirmation";
import { BetPlacementPayloadData } from "../../../store/features/betslipSlice";

export interface IBetSlipNewProps {
  bets: IBetslipBetType[] | null;
  placeBets: IBetslipBetType[] | null;
  spBets: IBetslipBetType[] | null;
  backToBetSlipCallback: () => void;
  closureCallback: () => void;
  shouldToggleBackground?: boolean;
  currentMeetingId?: number;
}

// NOTE: THIS NEEDS TO BE RENAMED ONCE WE GET RID OF THE OLD BETSLIP
const BetSlipNew = ({
  bets,
  placeBets,
  spBets,
  backToBetSlipCallback,
  closureCallback,
  shouldToggleBackground: shouldHideBackground = false,
  currentMeetingId = -1
}: IBetSlipNewProps) => {
  const dispatch = useDispatch();
  const { currentSelectedMeetingId, meetings, meetingsMetaData } = useSelector(
    (state: RootState) => state.meetings
  );

  const { isNavigationInitiated } = useSelector(
    (state: RootState) => state.pages
  );

  const { betStrikeResponse, limits } = useSelector(
    (state: RootState) => state.betslipNew
  );

  const hasStruckBet = useRef(false);
  const [isPlacingBet, setIsPlacingBet] = useState(false);
  const { userName } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (betStrikeResponse && hasStruckBet.current) {
      dispatch(getClientBalance({ userName }));
      setIsPlacingBet(betStrikeResponse.responseStatus !== 1);
    }
  }, [betStrikeResponse]);

  useEffect(() => {
    if (isNavigationInitiated) {
      closureCallback();
    }
  }, [isNavigationInitiated]);

  const doCloseAndClear = () => {
    closureCallback();
    dispatch(
      setInitialState({
        allowBet: false,
        bets: [],
        betStrikeResponse: null,
        meeting: {
          meetingDate: "",
          meetingId: 0,
          meetingName: "EMPTY"
        },
        races: [],
        forcedSP: false,
        limits,
        totalStake: 0,
        totalReturn: 0
      })
    );
  };

  const currentMeeting: IMeeting =
    meetings[
      currentMeetingId === -1 ? currentSelectedMeetingId : currentMeetingId
    ];
  const currentMeetingMetaTmp: IMeetingMetaData[] = meetingsMetaData
    ? meetingsMetaData.filter(
        (item) => item.meetingId === currentMeeting.meetingId
      )
    : [];
  const currentMeetingMeta =
    currentMeetingMetaTmp.length > 0 ? currentMeetingMetaTmp[0] : null;

  if (currentMeetingMeta === null || bets?.length === 0) {
    closureCallback();
    return null;
  }

  const betSlipRaces: IBetSlipRace[] = [];

  currentMeeting.races.forEach((race) => {
    const thisSelections = bets?.filter(
      (a) => a.eventNumber === race.raceNumber
    );
    if (thisSelections?.length) {
      const thisSelection = thisSelections[0];
      const raceHorses = race.runners.filter(
        (a) => a.horseName === thisSelection.eventDetailName
      );
      const raceHorse = raceHorses[0];
      const horseBetData = bets?.filter(
        (currentBet) => currentBet.eventDetailName === raceHorse?.horseName
      );
      const isFirstTimer =
        horseBetData?.length && horseBetData.length > 0
          ? horseBetData[0].firstTimer
          : false;

      betSlipRaces.push({
        raceNumber: parseInt(race.raceNumber.toString(), 10),
        meetingTime: race.raceTime,
        horseName: raceHorse?.horseName,
        horseNumber: thisSelection.eventDetailNumber,
        odds: thisSelection.eventDetailOfferedOdds!,
        placeOdds: 0,
        firstTimer: isFirstTimer!,
        selected: true
      });
    }
  });

  const fullBetSlipRaces: IBetSlipRace[] = [];

  betSlipRaces.forEach((race) => {
    const thisSelections = placeBets?.filter(
      (a) => a.eventDetailName === race.horseName
    );

    fullBetSlipRaces.push({
      ...race,
      placeOdds: thisSelections?.length
        ? thisSelections[0].eventDetailOfferedOdds!
        : 0
    });
  });

  const forcedSP =
    fullBetSlipRaces.filter((race) => race.odds === 0).length > 0;

  const betslipData: IBetSlipData = {
    meeting: {
      meetingDate: currentMeetingMeta.meetingDate,
      meetingId: parseInt(currentMeetingMeta.meetingId.toString(), 10),
      meetingName: currentMeetingMeta.raceCourseName
    },
    races: fullBetSlipRaces,
    bets: [],
    betStrikeResponse: null,
    allowBet: true,
    forcedSP,
    limits,
    totalStake: 0,
    totalReturn: 0
  };

  const onStrikeBet = (betslip: IBetSlipData) => {
    const betApiPayload = processBetslip(
      parseInt(userName, 10),
      bets,
      placeBets,
      spBets,
      betslip
    );
    setIsPlacingBet(true);
    dispatch(strikeBet(betApiPayload));
    hasStruckBet.current = true;
  };

  const onLuckyPickBet = (luckyPickBet: BetPlacementPayloadData) => {
    setIsPlacingBet(true);
    dispatch(strikeBet(luckyPickBet));
    hasStruckBet.current = true;
  };

  const buildRetryFailedBetsRequest = () => {
    const { isLuckyPick, failedBetRequests, totalBets } = betStrikeResponse!;
    const betRequests = failedBetRequests!.map((bet) => bet.request);

    const retryFailedBetsRequest: IBetStrikeRetryRequest = {
      betRequests,
      totalBets,
      isLuckyPick
    };

    return retryFailedBetsRequest;
  };

  const onRetryFailedBets = () => {
    const retryApiPayload = buildRetryFailedBetsRequest();

    setIsPlacingBet(true);
    dispatch(retryFailedBets(retryApiPayload));
    hasStruckBet.current = true;
  };

  return (
    <BetSlipHoverParent id="betslip_parent">
      {shouldHideBackground && <BackgroundImage src={HomeBackgroundImage} />}
      <BetSlipBackGroundShade onClick={closureCallback} />
      <BetSlipView
        initialState={betslipData}
        closureCallback={doCloseAndClear}
        onStrikeBet={onStrikeBet}
        onLuckyPickBet={onLuckyPickBet}
      />
      {hasStruckBet.current && (
        <BetslipConfirmation
          isProccessing={isPlacingBet}
          backToBetslipCallback={backToBetSlipCallback}
          backToHomeCallback={doCloseAndClear}
          retryFailedBetsCallback={onRetryFailedBets}
        />
      )}
    </BetSlipHoverParent>
  );
};

export default BetSlipNew;
