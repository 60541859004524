import styled from "styled-components";
import Container from "../bricks/container";

export const HeaderContainerMob = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  background: linear-gradient(#764cbf, #5c2d91);
  font-family: Poppins, sans-serif;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const HeaderContainerDesk = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  background: #f3f3f4;
  row-gap: 8px;
  width: 100%;
  align-items: left;
  font-family: Poppins, sans-serif;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const HeaderInternalContainerDesk = styled.div`
  position: relative;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: linear-gradient(#764cbf, #5c2d91);
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const LoginContainer = styled.div`
  width: 7vw;
  height: 2vw;
  border: 1px solid white;
  border-radius: 5px;
  display: grid;
  place-content: center;
  color: white;
  font-size: 1vw;
  cursor: pointer;
`;
// eslint-disable-next-line
export const LogoutContainer = styled.div`
  width: 6vw;
  height: 2vw;
  border: 1px solid white;
  border-radius: 5px;
  display: grid;
  place-content: center;
  color: white;
  margin-left: 10px;
  font-size: 1vw;
  cursor: pointer;
`;

export const UpDownArowContainer = styled(Container)`
  height: 10px;
  width: 15px;
  @media screen and (min-width: 769px) and (max-width: 1000px) {
    height: 10px;
    width: 15px;
  }

  @media screen and (min-width: 1001px) and (max-width: 1300px) {
    height: 12px;
    width: 18px;
  }
  @media screen and (min-width: 1301px) {
    height: 15px;
    width: 23px;
  }
`;

export const GallopTvDiv = styled.img`
  margin-left: 3px;
  max-width: 25%;
  min-width: 25%;
  display: block;
  background: white;
  border-radius: 5px;
  cursor: pointer;
`;

export const HollywoodBetsDiv = styled.img`
  max-width: 25%;
  min-width: 25%;
  display: block;
  background: white;
  border-radius: 5px;
  cursor: pointer;
`;

export const WinningFormDiv = styled.img`
  margin-right: 3px;
  max-width: 25%;
  min-width: 25%;
  display: block;
  background: white;
  border-radius: 5px;
  cursor: pointer;
`;

export const LoginSpan = styled.span`
  font-size: 3vw;
  color: white;
  height: fit-content;
  font-weight: bold;
  line-height: 1;
`;

export const LogoutSpan = styled.span`
  color: white;
  cursor: pointer;
  font-size: 1.3vw;
`;
