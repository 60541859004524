import { DIRECTIONS } from "../../../../../../../constants";
import theme from "../../../../../../../theme";
import ArrowHead from "../../../../../../bricks/arrowHead";
import Container from "../../../../../../bricks/container";

interface IOpenCloseControl {
  isOpen: boolean;
  width: string;
  onClickCallback: () => void;
}

const OpenCloseControl = ({
  isOpen,
  width,
  onClickCallback
}: IOpenCloseControl) => {
  return (
    <Container
      cursor="pointer"
      width={width}
      height="30px"
      borderradius="5px"
      bgcolor={theme.colors.resultsMercury}
      display="grid"
      placecontent="center"
      marginright="10px"
      onClick={onClickCallback}>
      <ArrowHead
        thickness={2}
        direction={isOpen ? DIRECTIONS.up : DIRECTIONS.down}
        color="black"
      />
    </Container>
  );
};

export default OpenCloseControl;
