import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RaceCardSelections from "../race-card-selections/raceCardSelections";
import openLink from "../../../../../utils/pageUtils";
import { RootState } from "../../../../../store/store";
import {
  IMeetingSelections,
  IRaceRunner,
  getMeetingData,
  getProgressiveWinners
} from "../../../../../store/features/meetingsSlice";
import {
  DesktopDiv,
  MainMeetingRaceCardDiv,
  MeetingRaceCardButtonsDiv,
  NotificationDiv,
  QuickPickButton,
  SelectAllFavoritesButton,
  SelectionLeaderboardToggle,
  ViewTipsButton
} from "./meetingRaceCard.style";
import RaceCardLeaderboard from "../race-card-leaderboard/raceCardLeaderboard";
import {
  RACE_SELECTION_CACHE_NEW,
  SELECTION_CUTOFF_TIME_IN_MINUTES
} from "../../../../../constants";
import { isPast, isToday } from "../../../../../utils/dateUtils";
import {
  GetLeaderboardForMeeting,
  GetLeaderboardForMeetingWithPage
} from "../../../../../store/features/leaderboardSlice";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import useCurrentMeetingResultTracker from "./useCurrentMeetingResultTracker";
import { findFavoriteHorse } from "../race-card-selections/selection/selectionsUtil";

interface IMeetingCard {
  currentMeetingId: number;
  meetingName: string;
  color: string;
  onSelectionMade: () => void;
  onSubmitClicked: () => void;
  onTakeABetClicked: () => void;
}

const ITEMS_PER_PAGE = 10;

const MeetingRaceCard = ({
  currentMeetingId,
  meetingName,
  color,
  onSelectionMade,
  onSubmitClicked,
  onTakeABetClicked
}: IMeetingCard) => {
  const dispatch = useDispatch();

  const config = useSelector((state: RootState) => state.config.data);
  const { meetings, progressiveWinners, selections } = useSelector(
    (state: RootState) => state.meetings
  );

  const punterId = useSelector((state: RootState) => state.user.punterId);
  const currentMeeting = meetings[currentMeetingId];
  const favoriteHorses: IRaceRunner[] = [];

  const { addRacesToTrack, cancelAllRaceTrackings } =
    useCurrentMeetingResultTracker(punterId);

  const [raceSelections, setRaceSelections] = useState<IMeetingSelections[]>(
    []
  );
  const [shouldShowSelections, setShouldShowSelections] =
    useState<boolean>(true);

  const [hasUsedQuickPick, setHasUsedQuickPick] = useState<boolean>(false);

  const [hasUsedSelectAllFavouries, setHasUsedSelectAllFavouries] =
    useState<boolean>(false);
  const [hasAllFavoritesAvailable, setHasAllFavoritesAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    if (!meetings.length) {
      dispatch(getMeetingData({ punterId, meetingId: currentMeetingId }));
    }
    dispatch(getProgressiveWinners(currentMeetingId));

    const targetMeeting = meetings[currentMeetingId];

    if (isToday(targetMeeting.meetingDate)) {
      addRacesToTrack(targetMeeting.races, currentMeetingId);
    }

    return () => {
      cancelAllRaceTrackings();
    };
  }, []);

  useEffect(() => {
    const previousSelections = selections?.[currentMeetingId]
      ? selections[currentMeetingId]
      : [];
    setRaceSelections(previousSelections);

    if (previousSelections.length && currentMeeting) {
      const previousCookies =
        previousSelections
          .map((selection) => {
            return currentMeeting.races[
              Number(selection?.raceNumber) - 1
            ]?.runners.find(
              (runner: IRaceRunner) => runner.horseName === selection?.horseName
            );
          })
          .filter((runner) => runner !== undefined && runner !== null) ?? [];

      localStorage.setItem(
        currentMeetingId + RACE_SELECTION_CACHE_NEW,
        JSON.stringify(previousCookies)
      );
    }
  }, [selections]);

  useEffect(() => {
    const { races } = currentMeeting;

    races.forEach((race) => {
      if (findFavoriteHorse(race.runners) === null) {
        setHasAllFavoritesAvailable(false);
        return;
      }

      setHasAllFavoritesAvailable(true);
    });
  }, [currentMeeting]);

  const isPending = isPast(
    currentMeeting?.races?.[0]?.raceTime.toString(),
    SELECTION_CUTOFF_TIME_IN_MINUTES
  );

  const onQuickPick = () => {
    if (!isPending) {
      onSelectionMade();
      const selectedRaces = currentMeeting.races;

      const filteredData: IRaceRunner[] = [];

      selectedRaces.forEach((item) => {
        const filteredRunners: IRaceRunner[] = item.runners.filter((runner) => {
          return runner.runnerStatus === 1;
        });

        const randomIndex = Math.floor(Math.random() * filteredRunners.length);

        filteredData.push(filteredRunners[randomIndex]);
      });

      localStorage.setItem(
        currentMeetingId + RACE_SELECTION_CACHE_NEW,
        JSON.stringify(filteredData)
      );

      setHasUsedQuickPick(true);

      setTimeout(() => {
        setHasUsedQuickPick(false);
      }, 1);
    }
  };

  const onSelectAllFavorites = () => {
    if (!isPending && hasAllFavoritesAvailable) {
      onSelectionMade();

      const { races } = currentMeeting;

      races.forEach((race) => {
        const favouriteHorse = findFavoriteHorse(race.runners);

        if (favouriteHorse !== null) {
          favoriteHorses.push(favouriteHorse);
        }
      });

      localStorage.setItem(
        currentMeetingId + RACE_SELECTION_CACHE_NEW,
        JSON.stringify(favoriteHorses)
      );

      setHasUsedSelectAllFavouries(true);

      setTimeout(() => {
        setHasUsedSelectAllFavouries(false);
      }, 1);
    }
  };

  const handleTips = () => {
    switch (meetingName) {
      case "Hollywoodbets Greyville":
      case "Hollywoodbets Scottsville":
        openLink(`${config.blogKZNUrl}`);
        break;

      case "Turffontein":
      case "Vaal":
        openLink(`${config.blogGautengUrl}`);
        break;

      case "Hollywoodbets Kenilworth":
      case "Hollywoodbets Durbanville":
        openLink(`${config.blogCPTUrl}`);
        break;

      case "Kranji":
        openLink(`${config.blogSingaporeUrl}`);
        break;

      case "Fairview":
        openLink(`${config.blogFairviewUrl}`);
        break;

      case "Happy Valley":
      case "Sha Tin":
        openLink(`${config.blogHongKongUrl}`);
        break;

      default:
        openLink(`${config.blogUrl}`);
        break;
    }
  };

  const pageTo = (page: number) => {
    if (page === 1) {
      dispatch(
        GetLeaderboardForMeeting({
          punterId,
          meetingId: currentMeetingId
        })
      );
    } else {
      dispatch(
        GetLeaderboardForMeetingWithPage({
          pageId: page,
          meetingId: currentMeetingId,
          numberPerPage: ITEMS_PER_PAGE
        })
      );
    }
  };

  const onLeaderboardToggle = () => {
    pageTo(1);
    setShouldShowSelections(!shouldShowSelections);
  };

  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <MainMeetingRaceCardDiv>
      <DesktopDiv isDesktop={isDesktop}>
        <NotificationDiv isDesktop={isDesktop}>
          <div>
            Use the <b>Quick Pick</b> {"button OR select one horse from "}
            <b>EACH RACE</b> and click <b>SUBMIT</b> to make your selections.
          </div>
        </NotificationDiv>
        <MeetingRaceCardButtonsDiv isDesktop={isDesktop}>
          <SelectAllFavoritesButton
            isDisabled={!hasAllFavoritesAvailable || isPending}
            onClick={onSelectAllFavorites}
            disabled={!hasAllFavoritesAvailable || isPending}>
            Select All Favourites
          </SelectAllFavoritesButton>
          <QuickPickButton
            isDisabled={!shouldShowSelections || isPending}
            onClick={onQuickPick}
            disabled={!shouldShowSelections || isPending}>
            Play Quick Pick
          </QuickPickButton>
          <ViewTipsButton onClick={handleTips} backgroundcolor={color}>
            View Tips
          </ViewTipsButton>
          <SelectionLeaderboardToggle
            onClick={onLeaderboardToggle}
            backgroundcolor={color}>
            {`View ${shouldShowSelections ? "Leaderboard" : "Selections"}`}
          </SelectionLeaderboardToggle>
        </MeetingRaceCardButtonsDiv>
      </DesktopDiv>
      <div style={{ width: "100%" }}>
        {shouldShowSelections ? (
          <RaceCardSelections
            currentMeetingId={currentMeetingId}
            meetingData={currentMeeting}
            selections={raceSelections}
            progressives={progressiveWinners}
            punterId={Number(punterId)}
            onSelectionMade={onSelectionMade}
            onSubmitClicked={onSubmitClicked}
            onTakeABetClicked={onTakeABetClicked}
            isPending={isPending}
            hasBeenQuickPicked={hasUsedQuickPick}
            hasSelectedAllFavorites={hasUsedSelectAllFavouries}
          />
        ) : (
          <RaceCardLeaderboard
            currentMeetingId={currentMeetingId}
            meetingName={meetingName}
            meetingDate={currentMeeting.meetingDate}
            punterId={Number(punterId)}
            puntersPerPage={ITEMS_PER_PAGE}
            onPageClick={pageTo}
          />
        )}
      </div>
    </MainMeetingRaceCardDiv>
  );
};

export default MeetingRaceCard;
