import theme from "../../../../../../../../theme";

const useCountdownHelper = (
  nextRaceTime: number | null,
  isHover: boolean,
  meetingStartTime: number | null,
  daysLeft: number
) => {
  const getBackgroundColor = () => {
    return isHover
      ? theme.colors.purplePrimary
      : theme.colors.meetingsLightGrey;
  };

  const getClockIconContainerWidth = () => {
    return nextRaceTime ? "min(40%,7vw)" : "min(35%,6vw)";
  };
  const getClockIconColor = () => {
    return isHover ? "yellow" : theme.colors.meetingWebTimerGrey;
  };

  const getSelectionCountDownWidth = () => {
    return nextRaceTime ? "65%" : "70%";
  };

  const canStartNextRace = () => {
    return nextRaceTime && !Number.isNaN(nextRaceTime);
  };

  const canStartInitialMeetingCountDown = () => {
    return meetingStartTime && daysLeft === 0;
  };

  const getDaysRemaining = () => {
    return `${daysLeft} ${daysLeft > 1 ? "Days" : "Day"}`;
  };

  const getColorForText = () => {
    return isHover ? "white" : "black";
  };

  return {
    getBackgroundColor,
    getClockIconContainerWidth,
    getClockIconColor,
    getSelectionCountDownWidth,
    canStartNextRace,
    canStartInitialMeetingCountDown,
    getDaysRemaining,
    getColorForText
  };
};

export default useCountdownHelper;
