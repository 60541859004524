/* eslint-disable */
const BetConsolidatedDisplay = ({
  totalBets,
  totalStake,
  totalPayout
}: {
  totalBets: number;
  totalStake: string;
  totalPayout: string;
}) => (
  <div
    style={{
      width: "90%",
      borderRadius: "3px",
      height: "max-content",
      background: "#f5f5ff",
      border: "1px solid #764CBF",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "10px 5px 10px 20px"
    }}>
    {!!totalBets && (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background: "",
            width: "90%",
            alignItems: "center"
          }}>
          <div style={{ width: "50%" }}>
            {" "}
            <label style={{ color: "#1e1e2f" }}>Total Bets</label>
          </div>
          <div style={{ width: "50%" }}>
            <label
              style={{
                color: "#5C2D91",
                fontSize: "1.1rem",
                fontWeight: "bold"
              }}>
              {totalBets}
            </label>
          </div>
        </div>
        <div
          style={{
            color: "#1e1e2f",
            width: "95%",
            borderBottom: "1px solid #b1b1cd",
            marginTop: "5px",
            marginBottom: "7px"
          }}
        />
      </>
    )}

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        alignItems: "center"
      }}>
      <div style={{ width: "50%" }}>
        {" "}
        <label style={{ color: "#1e1e2f" }}> Stake</label>
      </div>
      <div style={{ width: "50%" }}>
        <label
          style={{
            color: "#5C2D91",
            fontSize: "1.1rem",
            fontWeight: "bold",
            maxWidth: "30vw",
            overflow: "auto",
            textAlign: "left"
          }}>
          {totalStake}
        </label>
      </div>
    </div>
    <div
      style={{
        color: "#1e1e2f",
        width: "95%",
        borderBottom: "1px solid #b1b1cd",
        marginTop: "5px",
        marginBottom: "7px"
      }}
    />
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        alignItems: "center"
      }}>
      <div style={{ width: "50%" }}>
        {" "}
        <label
          style={{ color: "#1e1e2f", lineHeight: "1", width: "max-content" }}>
          Potential Return
        </label>
      </div>
      <div style={{ width: "50%", overflowX: "auto" }}>
        {" "}
        <label
          style={{
            color: "#5C2D91",
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "inherit",
            overflow: "auto",
            textAlign: "left"
          }}>
          {" "}
          {totalPayout}
        </label>
      </div>
    </div>
  </div>
);

export default BetConsolidatedDisplay;
