import { useSelector } from "react-redux";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import theme from "../../../../theme";
import LeagueRowItemWeb from "../leaguesCommon/leagueRowItemWeb";
import LeaguesTableHeader from "../leaguesCommon/leaguesTableHeader";
import { RootState } from "../../../../store/store";
import { IMyLeagues } from "../myLeagues";

const JoinedLeaguesWeb = ({ data, handleClose }: IMyLeagues) => {
  const { errorMessageOnRejection } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const islastRow = (rowNum: number) => {
    return rowNum === data!.length - 1;
  };

  return (
    <FlexContainer
      direction="column"
      width="98%"
      border={`1px solid ${theme.colors.lightGray}`}
      borderradius="5px">
      <Container
        textalign="left"
        bgcolor={theme.colors.lightAliceBlue}
        paddingleft="20px"
        paddingbottom="15px"
        paddingtop="15px"
        fontSize="1.2rem"
        color={theme.colors.purplePrimary}
        bordertopleftradius="5px"
        bordertoprightradius="5px">
        Joined Leagues
      </Container>

      <LeaguesTableHeader />

      <Container
        maxheight="210px"
        overflow="auto"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px">
        {data?.length! > 0 &&
          data?.map((item, index) => {
            return (
              <LeagueRowItemWeb
                key={`LeagueRowItemWeb${item.leagueName}`}
                index={index}
                isLastRow={islastRow(index)}
                leagueName={item.leagueName}
                rank={item.rank}
                numPlayers={item.totalPlayers}
                leagueCode={item.leagueCode}
                isActive={item.leagueStatus}
                toCaterForSidebar={data?.length > 1}
                isForJoinedLeagues
                handleClose={handleClose}
              />
            );
          })}
      </Container>

      {!data?.length && !errorMessageOnRejection && (
        <Container
          bgcolor="white"
          borderbottomleftradius="5px"
          borderbottomrightradius="5px"
          fontSize="0.8rem"
          paddingbottom="5px"
          paddingtop="10px"
          fontStyle="italic">
          You have not joined any league recently.
        </Container>
      )}
      {!data?.length && errorMessageOnRejection && (
        <Container
          bgcolor="white"
          borderbottomleftradius="5px"
          borderbottomrightradius="5px"
          fontSize="0.8rem"
          paddingbottom="5px"
          color="red"
          paddingtop="10px"
          fontStyle="italic">
          {errorMessageOnRejection}
        </Container>
      )}
    </FlexContainer>
  );
};

export default JoinedLeaguesWeb;
