import styled from "styled-components";
import theme from "../../../../theme";
import { IButton, StyledButton } from "../../../bricks/button";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";

interface ITeamMemberRowItem {
  name: string;
  callback: (name: string) => void;
  isOwner?: boolean;
}

const RemovePlayerButton = styled(StyledButton)<IButton>`
  width: 80%;
  font-size: max(0.45rem, 0.7vw);
  color: black;
  background-color: ${theme.colors.primary};
  border: 1px solid ${theme.colors.lightGrey5};
  @media screen and (max-width: 960px) and (min-width: 768px) {
    font-size: max(0.32rem, 0.5vw);
  }
`;

const TeamMemberRowItem = ({ name, callback, isOwner }: ITeamMemberRowItem) => {
  return (
    <FlexContainer alignitems="center">
      <Container
        textalign="left"
        width="70%"
        fontSize="max(0.8rem,1vw)"
        color={`${isOwner ? theme.colors.purplePrimary : "black"}`}
        fontWeight={isOwner ? "bold" : "normal"}>
        {name}
      </Container>
      <Container width="30%" textalign="left">
        {!isOwner && (
          <RemovePlayerButton
            onClick={() => {
              callback(name);
            }}>
            Remove Player
          </RemovePlayerButton>
        )}
      </Container>
    </FlexContainer>
  );
};

export default TeamMemberRowItem;
