import styled from "styled-components";

interface IImage {
  src: string;
  width?: string;
  height?: string;
  scaleX?: number;
  scaleY?: number;
  objectFit?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  transform?: string;
  display?: string;
  transformOrigin?: string;
  backgroundBlendMode?: string;
  paddingRight?: number;
}

const StyledImage = styled.img<IImage>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "auto"};
  max-width: ${(props) => props.maxWidth ?? "none"};
  min-width: ${(props) => props.minWidth ?? "inherit"};
  max-height: ${(props) => props.maxHeight ?? "none"};
  min-height: ${(props) => props.minHeight ?? "none"};
  object-fit: ${(props) => props.objectFit ?? "fill"};

  transform: ${(props) =>
    props.scaleX || props.scaleY
      ? `scale(${props.scaleX ?? 1},${props.scaleY ?? 1})`
      : "none"};
  display: ${(props) => props.display ?? "inline"};
  transform-origin: ${(props) => props.transformOrigin ?? "50% 50% 0"};
  background-blend-mode: ${(props) => props.backgroundBlendMode ?? "normal"};
  padding-right: ${(props) => props.paddingRight ?? 0}px;
`;
const Image = (props: IImage) => {
  const { scaleX, scaleY } = props;
  return <StyledImage {...props} scaleX={scaleX} scaleY={scaleY} />;
};

export default Image;
