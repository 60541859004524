import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { LeaderboardContainer, LeaderboardText } from "./leaderboard.style";
import {
  IPunterSelectionRequest,
  GetPunterSelection
} from "../../../../../store/features/resultsSlice";
import LeaderboardItem from "../../../../composites/leaderboard-card/leaderboard-item/leaderboardItem";

import LocalSpinner from "../../../../bricks/localSpinner";

export interface ILeaderboard {
  meetingId: number;
  punterId: string;
}

const Leaderboard = ({ meetingId, punterId }: ILeaderboard) => {
  const { currentPunterResults, topPunters, isFetching } = useSelector(
    (state: RootState) => state.results
  );
  const dispatch = useDispatch();

  const [openSelections, setOpenSelections] = useState<number>(-2);

  const onLeaderboardSelectionsClick = (
    index: number,
    meetingId: number,
    userId: number
  ) => {
    if (openSelections === index) {
      setOpenSelections(-2);
    } else {
      setOpenSelections(index);
    }
    const request: IPunterSelectionRequest = {
      meetingId,
      userId
    };
    if (openSelections !== index) {
      dispatch(GetPunterSelection(request));
    }
  };

  const leaderboard = topPunters.map((punter, index) => {
    const leaderboardResults = [];

    if (punter.userId !== punterId) {
      leaderboardResults.push(
        <LeaderboardItem
          index={index}
          punterId={punter.userId}
          meetingId={meetingId}
          userId={punter.punterId}
          punterRank={punter.rank}
          punterPoints={punter.total}
          winners={punter.winners}
          secondPlaces={punter.secondPlace}
          thirdPlaces={punter.thirdPlace}
          openSelections={openSelections === index}
          onLeaderboardSelectionsClick={onLeaderboardSelectionsClick}
          isForFriendsLeague={false}
        />
      );
    }

    return leaderboardResults;
  });

  return (
    <LeaderboardContainer>
      {isFetching ? (
        <LocalSpinner containerWidth="100%" containerHeight="50px" />
      ) : (
        <>
          {currentPunterResults ? (
            <LeaderboardItem
              index={-1}
              punterId={currentPunterResults.userId}
              meetingId={meetingId}
              userId={currentPunterResults.punterId}
              punterRank={currentPunterResults.rank}
              punterPoints={currentPunterResults.total}
              winners={currentPunterResults.winners}
              secondPlaces={currentPunterResults.secondPlace}
              thirdPlaces={currentPunterResults.thirdPlace}
              openSelections={openSelections === -1}
              onLeaderboardSelectionsClick={onLeaderboardSelectionsClick}
              isForFriendsLeague={false}
            />
          ) : (
            <LeaderboardText>
              <div>You did not make any selections for this meeting</div>
            </LeaderboardText>
          )}

          {leaderboard}
        </>
      )}
    </LeaderboardContainer>
  );
};

export default Leaderboard;
