import styled from "styled-components";
import {
  GetBackgroundColor,
  GetStatusBackgroundColor,
  GetStatusTextColor
} from "../selectionsUtil";
import theme from "../../../../../../../theme";

interface IHorseStatus {
  isSelected?: boolean;
  isScratched?: boolean;
  isReserved?: boolean;
  isPending?: boolean;
  isResulted?: boolean;
  isWinner?: boolean;
  isForcedSelection?: boolean;
}

interface IHorseCardContainer extends IHorseStatus {
  background: string;
}

interface ITopCardDiv extends IHorseStatus {
  hasStatus: boolean;
}

interface IBottomText {
  color: string;
  align: string;
}

export const CardContainer = styled.div<IHorseCardContainer>`
  background: ${(props) =>
    GetBackgroundColor(
      props.background,
      props.isSelected,
      props.isResulted,
      props.isForcedSelection
    )};
  height: 63px;
  width: 145px;
  display: flex;
  flex-direction: column;
  text-align: start;
  border-radius: 5px;
  cursor: pointer;
`;

export const SaddleSpan = styled.span`
  color: ${theme.colors.horseCardBlack};
  font-size: ${theme.font.size.m};
  font-family:
    Segoe UI,
    Regular;
  font-weight: ${theme.font.weight.semiBold};
  width: 20px;
  height: 26px;
  line-height: 2.2;
`;

export const NameSpan = styled.span<IHorseStatus>`
  color: ${theme.colors.purplePrimary};
  font-size: 0.65rem;
  word-break: break-all;
  font-family: Poppins, Bold;
  font-weight: ${theme.font.weight.semiBold};
  width: calc(100% - 20px);
  text-decoration: ${(props) =>
    props.isScratched ? "red line-through" : "none"};
`;

export const TopCardDiv = styled.div<ITopCardDiv>`
  display: flex;
  align-items: center;
  height: 15px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${(props) =>
    GetStatusBackgroundColor(props.hasStatus, props.isWinner)};
  font-size: 0.6rem;
  color: ${(props) => GetStatusTextColor(props.isWinner, props.isScratched)};
  font-weight: ${theme.font.weight.semiBold};
`;

export const MiddleCardDiv = styled.div`
  padding: 0px 6px;
  display: flex;
  line-height: 1.2;
  align-items: flex-end;
`;

export const BottomCardDiv = styled.div`
  padding: 0px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: end;
  justify-content: space-between;
  font-size: ${theme.font.size.xs};
  height: 15px;
`;

export const BottomTextSpan = styled.span<IBottomText>`
  color: ${(props) => props.color};
  line-height: 0.8;
  font-weight: 600;
  width: 30%;
  text-align: ${(props) => (props.align === "left" ? props.align : "right")};
`;

export const FavDiv = styled.div`
  background: ${theme.colors.purplePrimary};
  color: white;
  padding: 0px 6px;
  border-radius: 2px;
  font-size: 0.7rem;
  margin-bottom: -2px;
`;
