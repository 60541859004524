import { useContext, useEffect, useState } from "react";
import SwitchIcon from "@assets/updown.svg";
import Cross from "@assets/grey-cross-icon.svg";
import theme from "../../../../../../theme";
import Container from "../../../../../bricks/container";
import FlexContainer from "../../../../../bricks/flexContainer";
import {
  BET_TYPES,
  BET_TYPES_DISPLAY_NAMES,
  CURRENCY,
  SELECTION_TYPES
} from "../../../constants/luckyPickConstants";
import { LP_ACTIONS } from "../../../store/luckyPickActions";
import {
  formatInDecimal,
  formatInDecimalAndLocal
} from "../../../../../../utils/numberUtils";
import { LuckyPickContext } from "../../../context/luckyPickContext";
import { ILuckyPickBetType } from "../../../store/luckyPickReducer";
import TotalStakeView from "../totalStakeView";
import SelectionRow from "../selectionRow/selectionRow";
import { IBetslipBetType } from "../../../../../../store/features/betslipSlice";
import { canSwitchWinPlace } from "../../../utils/luckypickUtils";
import { SeperatorDiv } from "../../../../betslipNew/components/betTypeDisplay/betTypeDisplay.style";
import {
  NameDisplayDiv,
  NameDisplay,
  TitleContainer,
  ValuesContainer,
  CancelBetImage,
  BetTypeToggleImage
} from "./betTypeView.style";

interface IBetTypeView {
  title: string;
  type: string;
}

const BetTypeView = ({ title, type }: IBetTypeView) => {
  const { lpState, lpDispatch } = useContext(LuckyPickContext);
  const { betTypeWinDouble, betTypePlaceTreble, betTypeWinPlaceDouble } =
    lpState;
  const [btData, setBtData] = useState<ILuckyPickBetType>();

  const getSelectionType = (index: number) => {
    if (type === BET_TYPES.WIN_PLACE_DOUBLE) {
      if (index === 0)
        return lpState.isWinOnTopInWinPlaceDouble
          ? SELECTION_TYPES.WIN
          : SELECTION_TYPES.PLACE;
      if (index === 1)
        return lpState.isWinOnTopInWinPlaceDouble
          ? SELECTION_TYPES.PLACE
          : SELECTION_TYPES.WIN;
    }

    return null;
  };

  const onStakeUpdaterInput = (value: number) => {
    let action: string = "";

    if (type === BET_TYPES.WIN_DOUBLE) {
      action = LP_ACTIONS.UPDATE_BET_TYPE_WIN_DOUBLE;
    } else if (type === BET_TYPES.PLACE_TREBLE) {
      action = LP_ACTIONS.UPDATE_BET_TYPE_PLACE_TREBLE;
    } else if (type === BET_TYPES.WIN_PLACE_DOUBLE) {
      action = LP_ACTIONS.UPDATE_BET_TYPE_WIN_PLACE_DOUBLE;
    }

    if (value > -1) lpDispatch({ type: action, stake: value });
  };

  const isWinPlaceDouble = () => {
    return type === BET_TYPES.WIN_PLACE_DOUBLE;
  };

  const canSwitch = () => {
    return canSwitchWinPlace(
      betTypeWinPlaceDouble.horses[lpState.isWinOnTopInWinPlaceDouble ? 0 : 1]
        .eventDetailName,
      lpState.placeBetHorses
    );
  };

  const doSwitch = () => {
    if (canSwitch()) {
      lpDispatch({ type: LP_ACTIONS.SWITCH_WIN_PLACE_DOUBLE });
    }
  };

  useEffect(() => {
    if (type === BET_TYPES.WIN_DOUBLE && betTypeWinDouble.horses.length > 0) {
      setBtData(betTypeWinDouble);
    }
  }, [betTypeWinDouble]);

  useEffect(() => {
    if (
      type === BET_TYPES.PLACE_TREBLE &&
      betTypePlaceTreble.horses.length > 0
    ) {
      setBtData(betTypePlaceTreble);
    }
  }, [betTypePlaceTreble]);

  useEffect(() => {
    if (
      type === BET_TYPES.WIN_PLACE_DOUBLE &&
      betTypeWinPlaceDouble.horses.length > 0
    ) {
      setBtData(betTypeWinPlaceDouble);
    }
  }, [betTypeWinPlaceDouble]);

  const getPotenetialReturn = () => {
    if (btData === undefined || btData?.stake === undefined) {
      return 0;
    }
    return btData.payout + btData.stake;
  };

  return (
    <FlexContainer
      id="lp_bt_main_flex_col"
      direction="column"
      width="100%"
      borderbottom={`2px ${theme.colors.purplePrimary} solid`}
      margintop="10px">
      <FlexContainer
        id="lp_bt_header_flex_row_1"
        direction="column"
        width="95%"
        paddingleft="5%">
        <NameDisplayDiv>
          <NameDisplay>{BET_TYPES_DISPLAY_NAMES[title]}</NameDisplay>
          <Container marginleft="8px" width="24px">
            {isWinPlaceDouble() && (
              <BetTypeToggleImage
                canSwitch={canSwitch()}
                onClick={canSwitch() ? doSwitch : undefined}
                src={SwitchIcon}
              />
            )}
          </Container>
        </NameDisplayDiv>

        <FlexContainer
          width="100%"
          paddingbottom="10px"
          paddingtop="5px"
          alignitems="end">
          <Container width="calc(100% - 25px)">
            <TitleContainer>
              <Container width="32%" textalign="left">
                TOTAL STAKE
              </Container>
              <Container width="26%" textalign="left">
                ODDS
              </Container>
              <Container width="42%" textalign="left">
                POTENTIAL RETURN
              </Container>
            </TitleContainer>
            <ValuesContainer direction="row" width="100%" alignitems="center">
              <FlexContainer direction="column" width="32%">
                <TotalStakeView
                  defaultValue={btData?.stake!}
                  callback={onStakeUpdaterInput}
                  width={85}
                />
              </FlexContainer>
              <Container
                width="26%"
                textalign="left"
                fontWeight="bold"
                display="grid"
                alignitems="center"
                paddingleft="4px"
                paddingright="4px"
                overflowwrap="anywhere">
                {formatInDecimal(btData?.odds ? btData?.odds : 0)}
              </Container>
              <Container
                width="42%"
                textalign="left"
                fontWeight="bold"
                color={
                  btData?.isPayoutOverLimit ? "red" : theme.colors.refreshGreen
                }
                display="grid"
                alignitems="center"
                overflowwrap="anywhere">
                {`${CURRENCY} ${formatInDecimalAndLocal(getPotenetialReturn())}`}
              </Container>
            </ValuesContainer>
          </Container>
          <CancelBetImage
            src={Cross}
            onClick={() => {
              onStakeUpdaterInput(0);
            }}
          />
        </FlexContainer>
      </FlexContainer>

      {btData?.isPayoutOverLimit && (
        <Container
          width="96%"
          paddingleft="5px"
          paddingright="5px"
          fontSize="0.8rem"
          color="red">
          Current payout for bet is over the limit. Reduce your stake.
        </Container>
      )}

      <SeperatorDiv borderColor={theme.colors.lightGrey2} />

      <Container
        minheight="70px"
        marginbottom="3px"
        margintop="8px"
        marginleft="24px">
        {btData?.horses?.map((horse: IBetslipBetType, index: number) => {
          return (
            <SelectionRow
              key={`${title}${horse.eventDetailName}`}
              bettype={title}
              horseNumber={horse.eventDetailNumber}
              horseName={horse.eventDetailName}
              eventNumber={horse.eventNumber}
              odds={horse.eventDetailOfferedOdds}
              selectionType={getSelectionType(index)}
              winOrPlace={getSelectionType(index)}
            />
          );
        })}
      </Container>
    </FlexContainer>
  );
};

export default BetTypeView;
