import styled from "styled-components";
import theme from "../../../../theme";

interface IMiniContainerProps {
  isDropdown?: boolean;
  openSelections?: boolean;
}

export const MainContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 28%;
  height: fit-content;
  border-radius: 4px;
  column-gap: 4px;
  margin-right: 8px;
`;

export const MiniContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${theme.colors.resultsMercury};
  border-radius: 4px;
`;

export const MiniContainerText = styled.div`
  font-size: 0.7rem;
  color: ${theme.colors.titleAndTextPurple};
  width: 100%;
  font-weight: bold;
  align-content: center;
`;

export const MiniDropdown = styled.div<IMiniContainerProps>`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: ${(props) =>
    props.openSelections ? "white" : theme.colors.cardSelected};
  border: 1px solid
    ${(props) =>
      props.openSelections
        ? theme.colors.titleAndTextPurple
        : theme.colors.cardSelected};
  border-radius: 4px;
`;

export const MiniContainerDropdown = styled.img<IMiniContainerProps>`
  color: ${(props) =>
    props.openSelections ? "white" : theme.colors.cardSelected};
  cursor: pointer;
  width: 75%;
  height: auto;
  margin-top: ${(props) => (props.openSelections ? "0px" : "3x")};
  margin-bottom: ${(props) => (props.openSelections ? "3px" : "0x")};
  transform: ${(props) => (props.openSelections ? "rotate(180deg)" : "none")};
`;
