import React, { useContext } from "react";
import Saddle1 from "@assets/saddle-cloths-1.svg";
import Saddle2 from "@assets/saddle-cloths-2.svg";
import Saddle3 from "@assets/saddle-cloths-3.svg";
import Saddle4 from "@assets/saddle-cloths-4.svg";
import Saddle5 from "@assets/saddle-cloths-5.svg";
import Saddle6 from "@assets/saddle-cloths-6.svg";
import Saddle7 from "@assets/saddle-cloths-7.svg";
import Saddle8 from "@assets/saddle-cloths-8.svg";
import Saddle9 from "@assets/saddle-cloths-9.svg";
import Saddle10 from "@assets/saddle-cloths-10.svg";
import Saddle11 from "@assets/saddle-cloths-11.svg";
import Saddle12 from "@assets/saddle-cloths-12.svg";
import Saddle13 from "@assets/saddle-cloths-13.svg";
import Saddle14 from "@assets/saddle-cloths-14.svg";
import Saddle15 from "@assets/saddle-cloths-15.svg";
import Saddle16 from "@assets/saddle-cloths-16.svg";
import Saddle17 from "@assets/saddle-cloths-17.svg";
import Saddle18 from "@assets/saddle-cloths-18.svg";
import Saddle19 from "@assets/saddle-cloths-19.svg";
import Saddle20 from "@assets/saddle-cloths-20.svg";
import { styled } from "styled-components";
import Container from "../bricks/container";
import Image from "../bricks/image";
import { MediaQueriesContext } from "../../contexts/mediaQueriesContext";
import theme from "../../theme";

interface ISaddleNumberDisplay {
  value: number;
  isSpecial?: boolean;
  minWidth?: string;
  maxHeight?: string;
  maxWidthForLow?: string;
  maxHeightForLow?: string;
  fontSize?: string;
}

const TextDisplayContainer = styled(Container)`
  background: none;
  display: grid;
  place-content: center;
`;

const getSaddleRemaining = (val: number) => {
  if (val === 14) return Saddle14;
  if (val === 15) return Saddle15;
  if (val === 16) return Saddle16;
  if (val === 17) return Saddle17;
  if (val === 18) return Saddle18;
  if (val === 19) return Saddle19;
  if (val === 20) return Saddle20;

  return null;
};

const getSaddle = (val: number) => {
  if (val === 1) return Saddle1;
  if (val === 2) return Saddle2;
  if (val === 3) return Saddle3;
  if (val === 4) return Saddle4;
  if (val === 5) return Saddle5;
  if (val === 6) return Saddle6;
  if (val === 7) return Saddle7;
  if (val === 8) return Saddle8;
  if (val === 9) return Saddle9;
  if (val === 10) return Saddle10;
  if (val === 11) return Saddle11;
  if (val === 12) return Saddle12;
  if (val === 13) return Saddle13;

  return getSaddleRemaining(val);
};

const SaddleNumberDisplay = ({
  value,
  isSpecial,
  minWidth,
  maxHeight,
  maxWidthForLow,
  maxHeightForLow,
  fontSize
}: ISaddleNumberDisplay) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <Container
      width="max-content"
      height="max-content"
      display="grid"
      placecontent="center">
      {value < 21 && (
        <Image
          src={getSaddle(value)}
          width="auto"
          minWidth={minWidth ?? "32px"}
          minHeight={`min(${maxHeight ?? "25px"},${isDesktop ? "3.8vw" : "6vw"})`}
          height="100%"
        />
      )}

      {value > 20 && (
        <TextDisplayContainer
          border={`${isSpecial ? "0" : "1"}px solid${theme.colors.lightGrey5}`}
          fontSize={fontSize ?? "0.8rem"}
          color={isSpecial ? "yellow" : ""}
          borderbottomleftradius={`${isSpecial ? "0" : "9"}px`}
          boxshadow={isSpecial ? "" : `1px 1px 2px ${theme.colors.shadowGrey}`}
          minwidth={`min(${maxWidthForLow},${isDesktop ? "4vw" : "6vw"})`}
          minheight={`min(${maxHeightForLow},3vw)`}>
          {value}
        </TextDisplayContainer>
      )}
    </Container>
  );
};

export default SaddleNumberDisplay;
