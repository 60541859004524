import Bell from "@assets/icons8-notification.gif";
import styled from "styled-components";
import Container from "../../../bricks/container";
import Image from "../../../bricks/image";

const NotificationDisplay = styled.span`
  position: absolute;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.purplePrimary};
  z-index: 20;
`;

interface INotificationBell {
  numNotifications: number;
}

const NotificationBell = ({ numNotifications }: INotificationBell) => {
  return (
    <Container
      cursor="pointer"
      position="relative"
      display="grid"
      placecontent="center"
      marginleft="2%">
      <Image
        src={Bell}
        width="16px"
        height="16px"
        backgroundBlendMode="screen"
      />
      <NotificationDisplay> {numNotifications}</NotificationDisplay>
    </Container>
  );
};

export default NotificationBell;
