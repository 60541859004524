/* eslint-disable */
import React from "react";
import { styled } from "styled-components";
import { getBalanceFormatted } from "../../../utils/currencyFormatUtils";

const StakeLabel = styled.label`
  font-size: 0.8rem;
  font-weight: bold;
  width: 60%;

  @media screen and (min-width: 769px) {
    width: 70%;
  }
`;

const StakeValueLabel = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
  color: #5c2d91;
  overflow: auto;
  text-align: left;
  width: 40%;

  @media screen and (min-width: 769px) ) {
    width: 30%;
  }
`;

interface IBetslipSummaryItemCard {
  bettype: string;
  numBets: number;
  totalStake: number;
  totalPayout: number;
  isSp: boolean;
}

const BetslipSummaryItemCard = ({
  bettype,
  numBets,
  totalStake,
  totalPayout,
  isSp
}: IBetslipSummaryItemCard) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "white",
      fontFamily: "Poppins, sans-serif",
      color: "#8c8c8c"
    }}>
    <div
      style={{
        background: "#F4F6FD",
        display: "flex",
        borderBottom: "1px solid #d9d9d9",
        borderTop: "1px solid #d9d9d9",
        paddingTop: "6px",
        paddingBottom: "4px"
      }}>
      <label
        style={{ fontSize: "0.9rem", marginLeft: "20px", fontWeight: "bold" }}>
        {" "}
        {`${bettype}: ${numBets} ${numBets > 1 ? "Bets" : "Bet"}`}
      </label>
    </div>
    <div
      style={{
        width: "90%",
        background: "white",
        display: "flex",
        borderBottom: "1px solid #d9d9d9",
        marginLeft: "5%",
        paddingTop: "6px",
        paddingBottom: "4px",
        alignItems: "center"
      }}>
      <StakeLabel> Stake:</StakeLabel>
      <StakeValueLabel> {getBalanceFormatted(totalStake)}</StakeValueLabel>
    </div>
    <div
      style={{
        width: "90%",
        background: "white",
        display: "flex",
        marginLeft: "5%",
        paddingTop: "6px",
        paddingBottom: "0px",
        alignItems: "center",
        borderBottom: "0px solid #d9d9d9"
      }}>
      <StakeLabel> Potential Return:</StakeLabel>
      <StakeValueLabel>
        {" "}
        {isSp ? "SP" : getBalanceFormatted(totalPayout)}
      </StakeValueLabel>
    </div>
    <div style={{ paddingTop: "1px", paddingBottom: "3px" }} />
  </div>
);

export default BetslipSummaryItemCard;
