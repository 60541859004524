import styled from "styled-components";
import theme from "../../../../theme";
import Container from "../../../bricks/container";

interface ICancel {
  width?: number;
  height?: number;
  borderColor?: string;
  color?: string;
  clickCallback: () => void;
}

const AngledBar = styled.div<{ color?: string; isForward?: boolean }>`
  height: 70%;
  width: 12%;
  position: absolute;
  transform: translateX(380%)
    rotate(${(props) => (props.isForward ? "45deg" : "-45deg")});
  background: ${(props) => props.color ?? props.theme.colors.lightGray};
`;

const Cancel = ({
  width,
  height,
  borderColor,
  color,
  clickCallback
}: ICancel) => {
  return (
    <Container
      width={`${width ?? 50}px`}
      height={`${height ?? 50}px`}
      bgcolor={theme.colors.primary}
      position="relative"
      display="grid"
      alignitems="center"
      borderradius="5px"
      border={`1px solid ${borderColor ?? theme.colors.lightGrey6}`}
      cursor="pointer"
      onClick={clickCallback}>
      <AngledBar color={color ?? theme.colors.lightGrey6} isForward />
      <AngledBar color={color ?? theme.colors.lightGrey6} />
    </Container>
  );
};

export default Cancel;
