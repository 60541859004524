import styled from "styled-components";

const LinkButtonContainer = styled.div`
  margin-top: 15px;
  width: 92%;
  display: grid;
  row-gap: 20px;
`;

export default LinkButtonContainer;
