import styled from "styled-components";
import MenuItem from "./menuItem";

interface IMenucontainer {
  background?: string;
}
const Menucontainer = styled.div<IMenucontainer>`
  background: ${(props) => props.background ?? "#F4F6FD"};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: auto;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
  max-width: 150px;
  border: 1px solid #b3b3cc;
  // box-shadow:2px 2px 4px #efeff5;
`;

interface IMenuItems {
  items: string[];
  callback?: (item: string) => void;
}
const MenuItems = ({ items, callback }: IMenuItems) => (
  <Menucontainer>
    {items.map((item) => (
      <MenuItem key={`menuItem_${item}`} text={item} callback={callback} />
    ))}
  </Menucontainer>
);

export default MenuItems;
