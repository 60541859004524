import styled from "styled-components";
import SaddleNumberDisplay from "../../composites/saddleNumberDisplay";
import theme from "../../../theme";

const getNormalBackground = (isEven: boolean) =>
  isEven ? "#e8eff7" : "#f2f2f2";

export interface INumberContainerBase {
  color?: string;
  position?: string;
  radius?: string;
  width?: string;
  height?: string;
  background?: string;
  shadow?: string;
  $isEven?: boolean;
}

export const NumberContainerBase = styled.div<INumberContainerBase>`
  position: ${(props) => props.position ?? "relative"};
  border-radius: ${(props) => props.radius ?? "5px"};
  width: ${(props) => props.width ?? "5px"};
  height: ${(props) => props.height ?? "5px"};
  background: ${(props) =>
    props.background ?? getNormalBackground(!!props.$isEven)};
  box-shadow: ${(props) => props.shadow ?? "1px 1px 10px #000"};
`;

const MainBaseContainer = styled(NumberContainerBase)`
  display: grid;
  place-content: center;
  max-width: 60px;
  padding: 2px 5px 0px 6px;
`;

interface IHorsenumber {
  value?: string | number;
}
const HorseNumber = ({ value }: IHorsenumber) => {
  return (
    <MainBaseContainer
      width="10vw"
      height="3.3rem"
      shadow={`2px 2px 2px ${theme.colors.gray80}`}
      $isEven={Number(value) % 2 === 0}>
      <SaddleNumberDisplay
        value={Number(value)}
        maxHeight="40px"
        maxWidthForLow="40px"
        maxHeightForLow="30px"
        minWidth="40px"
      />
    </MainBaseContainer>
  );
};

export default HorseNumber;
