import styled from "styled-components";
import theme from "../../../../theme";

interface ILeaderboardInfoProps {
  isCurrentPunter: boolean;
}

export const InfoContainer = styled.div<ILeaderboardInfoProps>`
  column-gap: 5px;
  display: flex;
  align-items: center;
  width: 75%;
  height: 100%;
  background: white;
  justify-content: space-between;
  border-top: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : `1px solid ${theme.colors.resultsMercury}`};
  border-bottom: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : `1px solid ${theme.colors.resultsMercury}`};
`;

export const InfoText = styled.div`
  font-size: ${theme.font.size.m};
  color: ${theme.colors.titleAndTextPurple};
  font-weight: bolder;
  width: 50%;
  text-align: left;
  padding-left: 10px;
  @media screen and (max-width: 450px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 400px) {
    font-size: ${theme.font.size.s};
  }
`;
