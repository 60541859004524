import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Container from "../../bricks/container";

import FlexContainer from "../../bricks/flexContainer";
import CarousalItem from "./carousaltem";
import type { RootState } from "../../../store/store";
import { isHandHeldDevice } from "../../../utils/deviceUtils";
import Dots from "../dots/dots";

const Carousal = () => {
  const INTERVAL_DELAY: number = 5000;
  const SLIDE_TIME_IN_SECS: number = 1;
  const [activeIndex, setActiveIndex] = useState(0);

  const carousalContainer = useRef<HTMLDivElement | null>(null);
  const sliderContainer = useRef<HTMLDivElement | null>(null);

  const cmsImagesData = useSelector(
    (state: RootState) => state.pages.cmsImages
  );

  const interval: any = useRef(null);
  const direction = useRef(-1);
  const distanceToTravel = useRef(1);
  const currentIndex = useRef(0);

  const [items, setItems] = useState<Array<{ type: string; link: string }>>([]);

  const [numItemsAwaited, setNumItemsAwaited] = useState<{ num: number }>({
    num: -1
  });

  const itemsReceived = useRef<
    Array<{ type: string; data: HTMLImageElement | HTMLVideoElement }>
  >([]);

  const [canStartSlide, setCanStartSlide] = useState<boolean>(false);

  const itemWidthPercent = useRef(0);
  const timeOut = useRef<any>(null);

  const move = (isNext: boolean = true) => {
    if (sliderContainer.current && carousalContainer.current) {
      if (isNext) {
        if (direction.current === 1) {
          sliderContainer.current.prepend(
            sliderContainer.current.lastElementChild as HTMLDivElement
          );
          direction.current = -1;
        }

        carousalContainer.current.style.justifyContent = "flex-start";
        sliderContainer.current.style.transform = `translate(-${
          itemWidthPercent.current * distanceToTravel.current
        }%)`;
      } else {
        if (direction.current === -1) {
          sliderContainer.current.appendChild(
            sliderContainer.current.firstElementChild as HTMLDivElement
          );
          direction.current = 1;
        }

        carousalContainer.current.style.justifyContent = "flex-end";
        sliderContainer.current.style.transform = `translate(${
          itemWidthPercent.current * distanceToTravel.current
        }%)`;
      }
    }
  };

  const startNextPrevious = (isNext: boolean = true) => {
    clearInterval(interval.current);
    interval.current = null;
    move(isNext);
  };

  const adjustChild = (
    distanceToTravel: number,
    slider: HTMLDivElement,
    direction: number
  ) => {
    for (let i = 0; i < distanceToTravel; i++) {
      if (direction === -1) {
        slider.appendChild(slider.children[0]);
      } else {
        slider.prepend(slider.children[slider.children.length - 1]);
      }
    }

    if (direction === -1) {
      currentIndex.current =
        (currentIndex.current + distanceToTravel) %
        itemsReceived.current.length;
    } else {
      const diffBetweenIndexes = currentIndex.current - distanceToTravel;
      currentIndex.current =
        diffBetweenIndexes > -1
          ? diffBetweenIndexes
          : itemsReceived.current.length - 1;
    }
  };

  const startTheRotation = () => {
    if (sliderContainer.current && itemsReceived.current.length > 1) {
      itemWidthPercent.current = 100 / itemsReceived.current.length;
      const slider: HTMLDivElement = sliderContainer.current;

      if (slider) {
        slider.addEventListener("transitionend", () => {
          adjustChild(distanceToTravel.current, slider, direction.current);

          slider.style.transition = "none";
          slider.style.transform = "translate(0)";

          timeOut.current = setTimeout(() => {
            slider.style.transition = `all ${SLIDE_TIME_IN_SECS}s`;

            if (!interval.current)
              interval.current = setInterval(move, INTERVAL_DELAY);
          });

          distanceToTravel.current = 1;

          setActiveIndex(currentIndex.current);
        });
      }

      interval.current = setInterval(move, INTERVAL_DELAY);
    }
  };

  const config = useSelector((state: RootState) => state.config.data);

  useEffect(() => {
    if (cmsImagesData) {
      const slideIdentifier = isHandHeldDevice()
        ? config.rotatorMobi
        : config.rotator;

      const filteredImages = cmsImagesData.filter(
        (img) => img.altText === slideIdentifier && img.active
      );

      if (filteredImages && filteredImages.length >= 1) {
        const today = new Date(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()} 00:00:00`
        );
        const filteredListofValidEvents = filteredImages.filter(
          (img) =>
            new Date(img.endDate) >= today && new Date(img.startDate) <= today
        );
        const list = filteredListofValidEvents.map((img) => ({
          type: "image",
          link: img.imageURL
        }));
        setItems(list);
        setNumItemsAwaited({ num: list.length });
      }
    }
  }, [cmsImagesData]);

  useEffect(() => {
    if (items.length) {
      items.forEach((item) => {
        if (item.type === "image") {
          const img = new Image();
          img.src = item.link;

          img.onload = () => {
            const temp = --numItemsAwaited.num;

            setNumItemsAwaited({ num: temp });

            const index = items.findIndex(
              (item) => item.link.toLocaleLowerCase() === img.currentSrc
            );

            itemsReceived.current[index] = { type: "image", data: img };

            if (numItemsAwaited?.num === 0) {
              setCanStartSlide(true);

              setTimeout(() => {
                startTheRotation();
              }, 10);
            }
          };

          img.onerror = () => {
            const temp = --numItemsAwaited.num;

            setNumItemsAwaited({ num: temp });
          };
        }
      });
    }
  }, [items]);

  useEffect(
    () => () => {
      clearInterval(interval.current);
      clearTimeout(timeOut.current);
    },
    []
  );

  const onDotClick = (newindex: number) => {
    setActiveIndex(newindex);

    const factor = newindex - currentIndex.current;

    if (factor !== 0) {
      clearInterval(interval.current);
      interval.current = null;
      distanceToTravel.current = Math.abs(factor);
      move(factor > 0);
    }
  };

  return (
    canStartSlide && (
      <FlexContainer
        width="100%"
        height="auto"
        alignitems="center"
        direction="column">
        <FlexContainer
          height="auto"
          overflow="hidden"
          position="relative"
          borderradius="8px">
          <div
            ref={carousalContainer}
            style={{
              width: "100%",
              height: "auto",
              flexDirection: "row",
              position: "relative",
              display: "flex",
              justifyContent: "flex-start"
            }}>
            <div
              ref={sliderContainer}
              style={{
                display: "flex",
                height: "auto",
                width: `${itemsReceived.current.length * 100}%`,
                flexShrink: "0",
                transition: `all ${SLIDE_TIME_IN_SECS}s`
              }}>
              {itemsReceived.current.map((item) => (
                <div
                  key={`item_${item.data.src}`}
                  style={{
                    display: "flex",
                    width: `${itemWidthPercent.current}%`,
                    flexBasis: `${itemWidthPercent.current}%`,
                    flex: "1",
                    flexShrink: "0",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  <CarousalItem
                    key={`CarousalItem_${item.data.src}`}
                    type={item.type}
                    content={item.data.src}
                  />
                </div>
              ))}
            </div>
          </div>

          {itemsReceived.current.length > 1 && (
            <FlexContainer
              bgcolor="none"
              position="absolute"
              justifycontent="space-between"
              width="100%">
              <Container
                cursor="pointer"
                onClick={() => {
                  startNextPrevious(false);
                }}
                fontfamlily="cursive"
                color="white"
                fontSize="1.2rem"
                fontWeight="900"
                width="fit-content"
                height="fit-content"
                display="grid"
                placecontent="center"
                margin="auto 0"
                paddingleft="2px">
                {"<"}{" "}
              </Container>
              <Container
                cursor="pointer"
                onClick={() => {
                  startNextPrevious();
                }}
                fontfamlily="cursive"
                bgcolor="none"
                color="white"
                fontSize="1.2rem"
                fontWeight="900"
                width="fit-content"
                height="fit-content"
                display="grid"
                placecontent="center"
                margin="auto 0"
                paddingright="2px">
                {">"}{" "}
              </Container>
            </FlexContainer>
          )}
        </FlexContainer>

        {itemsReceived.current.length > 1 && (
          <div style={{ width: "max-content", marginTop: "2px" }}>
            <Dots
              total={itemsReceived.current.length}
              clickCallback={onDotClick}
              activeIndex={activeIndex}
            />
          </div>
        )}
      </FlexContainer>
    )
  );
};

export default Carousal;
