import theme from "../../../../theme";
import Container from "../../../bricks/container";

interface INoLeagueOrErrorMessage {
  text: string;
  color?: string;
}

const NoLeagueOrErrorMessage = ({ color, text }: INoLeagueOrErrorMessage) => {
  return (
    <Container
      bgcolor={theme.colors.primary}
      borderbottomleftradius="5px"
      borderbottomrightradius="5px"
      fontSize="0.8rem"
      paddingbottom="5px"
      paddingtop="10px"
      fontStyle="italic"
      color={color}>
      {" "}
      {text}{" "}
    </Container>
  );
};

export default NoLeagueOrErrorMessage;
