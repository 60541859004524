import { styled } from "styled-components";
import Container from "../bricks/container";
import FlexContainer from "../bricks/flexContainer";

const PossibleDTContainer = styled(Container)`
  @media screen and (min-width: 280px) and (max-width: 350px) {
    font-size: 2.95vw;
  }
  @media screen and (min-width: 351px) and (max-width: 545px) {
    font-size: 2.95vw;
  }
  @media screen and (min-width: 546px) {
    font-size: 2.9vw;
  }
`;

interface IPossibleWinners {
  winner?: number | string;
  races?: number | string;
  color?: string;
  totalPlayers?: number | string;
}
const PossibleWinners = ({
  winner,
  races,
  color,
  totalPlayers
}: IPossibleWinners) => (
  <FlexContainer
    width="100%"
    direction="column"
    bgcolor="white"
    border={`2px solid ${color}`}
    borderradius="6px"
    boxshadow="2px 2px 10px #a2a2c3">
    {/* <FlexContainer width='94%' justifycontent="space-between" alignitems="center" padding="7px" bordertopleftradius="6px" bordertoprightradius='6px' >
        <Container fontSize="2.4vw" color='black' fontWeight="bold" textalign="left"> POSSIBLE PUNTERS JACKPOT WINNERS:</Container>

        <Container bgcolor={color} borderradius="4px" width='36%' lineheight="1" padding="3px 0" color="white" fontSize="5vw" fontWeight="bold">{`${races}/${races}-${winner || 0}`}</Container>

     </FlexContainer>
     <FlexContainer width='94%' justifycontent="flex-end" alignitems="center" padding="7px" columngap='5px' borderbottomleftradius="6px" borderbottomrightradius="6px" margintop='-5px' >
        <Container fontSize="2.4vw" color='black' fontWeight="bold" textalign="left"> TOTAL PLAYERS:</Container>

        <Container bgcolor={color} borderradius="4px" width='36%' lineheight="1" padding="3px 0" color="white" fontSize="5vw" fontWeight="bold">{totalPlayers}</Container>

     </FlexContainer> */}
    <FlexContainer
      width="96%"
      justifycontent="flex-end"
      alignitems="center"
      padding="7px"
      columngap="5px"
      bordertopleftradius="6px"
      bordertoprightradius="6px">
      <PossibleDTContainer
        fontSize="2.8vw"
        color="black"
        width="62%"
        fontWeight="bold"
        textalign="right">
        {" "}
        Possible Punters Jackpot Winners :
      </PossibleDTContainer>

      <Container
        bgcolor={color}
        borderradius="4px"
        width="36%"
        lineheight="1"
        padding="3px 0"
        color="white"
        fontSize="5vw"
        fontWeight="bold">{`${races}/${races}-${winner ?? 0}`}</Container>
    </FlexContainer>
    <FlexContainer
      width="96%"
      justifycontent="flex-end"
      alignitems="center"
      padding="7px"
      columngap="5px"
      borderbottomleftradius="6px"
      borderbottomrightradius="6px"
      margintop="-5px">
      <PossibleDTContainer
        fontSize="2.8vw"
        color="black"
        width="62%"
        fontWeight="bold"
        textalign="right">
        {" "}
        Total Players :
      </PossibleDTContainer>

      <Container
        bgcolor={color}
        borderradius="4px"
        width="36%"
        lineheight="1"
        padding="3px 0"
        color="white"
        fontSize="5vw"
        fontWeight="bold">
        {totalPlayers}
      </Container>
    </FlexContainer>
  </FlexContainer>
);

export default PossibleWinners;
