import styled from "styled-components";
import { IButton, StyledButton } from "../../../../bricks/button";
import theme from "../../../../../theme";

interface IColorLegend {
  color: string;
}
interface ISelectionContentDiv {
  isDesktop: boolean;
}

interface ISelectionButton extends IButton {
  isDisabled: boolean;
}

export const RaceCardSelectionsDiv = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 8px;
  width: calc(100% - 16px);
  height: fit-content;
  overflow-x: auto;

  padding-bottom: 8px;
`;

export const SelectionContent = styled.div<ISelectionContentDiv>`
  width: ${(props) => (props.isDesktop ? "100%" : "max-content")};
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

export const HorsePositionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const RaceCardBottomDiv = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  padding-bottom: 18px;
  justify-content: start;
`;

export const TsAndCsDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  font-size: 0.5rem;
  justify-content: space-between;
`;

export const LegendsDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  line-height: 0.6;
`;

export const ColorLegend = styled.div<IColorLegend>`
  background-color: ${(props) => props.color};
  width: 8px;
  height: 8px;
`;

export const SelectionsButton = styled(StyledButton)<ISelectionButton>`
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  max-width: 200px;
  width: 50%;
  cursor: ${(props) => (props.isDisabled ? "inherit" : "pointer")};
  @media screen and (min-width: 350px) and (max-width: 750px) {
    font-size: ${theme.font.size.sm};
  }
`;
