import styled from "styled-components";
import theme from "../../../../../theme";

export const HorseDisplayDiv = styled.div`
  text-align: left;
  width: 100%;
`;

export const OddsDisplayDiv = styled.div`
  align-items: center;
  display: flex;
  text-align: right;
  width: wrap-content;
  white-space: nowrap;
  padding-right: 10px;
  font-size: ${theme.font.size.s};
`;

export const RaceDisplayDiv = styled.div`
  font-size: 0.65em;
  text-align: left;
  font-height: 10px;
`;

export const HorseNumberDisplay = styled.b`
  font-weight: ${theme.font.weight.heavyBold};
  color: ${theme.colors.purplePrimary};
  padding-right: 10px;
  font-size: 1.05em;
`;

export const ExtraBoldDisplay = styled.b`
  font-weight: ${theme.font.weight.heavyBold};
`;

export const PurpleText = styled.text`
  color: ${theme.colors.purplePrimary};
`;

export const RemoveDisplayDiv = styled.div`
  width: wrap-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 5px;
`;

export const RemoveButtonDisplay = styled.img`
  width: 15px;
  cursor: pointer;
`;
