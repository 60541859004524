import styled from "styled-components";
import theme from "../../../../../theme";

export const LeaderboardContainer = styled.div`
  height: fit-content;
  width: 100%;
  background-color: white;
  border-radius: 0 0 8px 8px;
  display: grid;
  align-content: center;
  justify-items: center;
  padding-bottom: 15px;
  box-shadow: 0px 0px 8px ${theme.colors.cardShadow};
`;

export const LeaderboardText = styled.div`
  width: 75%;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  font-size: 0.65rem;
  margin-top: 10px;
  column-gap: 16px;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: ${theme.colors.meetingsLightGrey};
`;
