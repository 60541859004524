/* eslint-disable */

import { type IBetslipBetType } from "../../../../store/features/betslipSlice";

const LEGS = {
  TWO: 2,
  THREE: 3,
  FOUR: 4
};
interface StakeAndPayout {
  stake: number | undefined;
  payout: number | undefined;
}

const useMultiple = () => {
  const getAccmulatedValuesFromIndices = (
    bets: IBetslipBetType[],
    indices: number[]
  ) => {
    let accumulated: number = 1;
    accumulated =
      indices.reduce(
        (accumulated, value) =>
          accumulated * (bets[value].eventDetailOfferedOdds + 1),
        accumulated
      ) - 1;

    return accumulated;
  };

  const getMultiples = (
    bets: IBetslipBetType[],
    startIndexx: number = 0,
    middleStartIndexx: number = 1,
    middleEndIndexx: number = -1,
    legsx: number = 4,
    accumattedAmount: number
  ): StakeAndPayout => {
    let count = 0;

    const recursiveExtractor = (
      startIndex: number = 0,
      middleStartIndex: number = 1,
      middleEndIndex: number = -1,
      legs: number = 4,
      total: number
    ): { count: number; total: number } | undefined => {
      //   if(legs === 2) console.log(" ++getMultiples startIndex =  " +startIndex +",middleStartIndex = "+middleStartIndex +",middleEndIndex = "+middleEndIndex +",legs = "+legs)

      if (legs <= LEGS.TWO) middleStartIndex = -1;

      const len = bets.length;

      let midEndindex = middleEndIndex;
      const midInBetweenIndices: number[] = [];
      if (legs > LEGS.THREE) {
        const offsetNum = legs - LEGS.THREE; // 4-3 =1 or 5-3 =2
        midEndindex =
          midEndindex < 0 ? middleStartIndex + offsetNum : middleEndIndex;
        if (offsetNum > 1) {
          for (let i = 1; i < offsetNum; i++) {
            midInBetweenIndices.push(middleStartIndex + 1);
          }
        }
      }

      let indices: number[] = [];

      let toBumpUpStartindex: boolean = false;

      //  console.log("!@! ---- func start----count = " + count + ",startIndex = " + startIndex + ",middleStartIndex = " + middleStartIndex + ",midEndindex = " + midEndindex + ",total = " + total)

      if (startIndex < len - legs) {
        if (
          (legs <= LEGS.THREE &&
            middleStartIndex === len - 1 &&
            midEndindex < 0) ||
          (legs > LEGS.THREE &&
            middleStartIndex === len - 1 - legs + LEGS.THREE &&
            midEndindex === len - 1)
        ) {
          toBumpUpStartindex = true;
        }
      } else if (
        startIndex === len - 1 ||
        middleStartIndex === len - 1 ||
        midEndindex === len - 1
      )
        return { count, total };

      if (toBumpUpStartindex)
        return recursiveExtractor(++startIndex, ++startIndex, -1, legs, total);

      for (let index = startIndex; index < len; index++) {
        indices = [startIndex];
        if (midEndindex > 0) {
          // when 4 legs or more
          indices.push(middleStartIndex);
          if (midInBetweenIndices?.length) {
            indices.push(...midInBetweenIndices);
          }
          indices.push(midEndindex);

          if (index > midEndindex) {
            indices.push(index);
            //  console.log("!@! indices = " + indices)

            total += getAccmulatedValuesFromIndices(bets, indices);

            if (legs === 2)
              console.log(`++getMultiples combo ,count = ${count}`);

            count++;

            if (index === len - 1) {
              const newMidStartIndex =
                midEndindex >= len - 2 && middleStartIndex < len - 2
                  ? ++middleStartIndex
                  : middleStartIndex;
              const newMidEndIndex = midEndindex < len - 2 ? ++midEndindex : -1;

              return recursiveExtractor(
                startIndex,
                newMidStartIndex,
                newMidEndIndex,
                legs,
                total
              );
            }
          }
        } else {
          // when less than 4 legs
          // middleStartIndex > 0, when 3 legs

          if (
            (middleStartIndex > 0 && index > middleStartIndex) ||
            (middleStartIndex < 0 && index > startIndex)
          ) {
            if (middleStartIndex > 0) indices.push(middleStartIndex);
            indices.push(index);

            total += getAccmulatedValuesFromIndices(bets, indices);
            count++;
            // if(legs === 2) console.log("++getMultiples combo ,count = " + count)
            if (index === len - 1) {
              const newMidStartIndex =
                middleStartIndex > 0 && middleStartIndex <= len - (legs - 1)
                  ? middleStartIndex + 1
                  : -1;
              const newStartIndex =
                middleStartIndex > 0 ? startIndex : startIndex + 1;

              return recursiveExtractor(
                newStartIndex,
                newMidStartIndex,
                -1,
                legs,
                total
              );
            }
          }
        }
      }
    };

    const calculated = recursiveExtractor(
      startIndexx,
      middleStartIndexx,
      middleEndIndexx,
      legsx,
      accumattedAmount
    );

    return {
      stake: calculated?.count,
      payout: Number(calculated?.total.toFixed(2))
    };
  };

  const getDouble = (bets: IBetslipBetType[]) =>
    getMultiples(bets, 0, 1, -1, LEGS.TWO, 0);
  const getTreble = (bets: IBetslipBetType[]) =>
    getMultiples(bets, 0, 1, -1, LEGS.THREE, 0);
  const getQuad = (bets: IBetslipBetType[]) =>
    getMultiples(bets, 0, 1, -1, LEGS.FOUR, 0);

  return [getDouble, getTreble, getQuad];
};

export default useMultiple;
