/* eslint-disable */

import { useState, useEffect, useRef, Fragment } from "react";
import CloseIcon from "@assets/bs-close-window-x.svg";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import PrevButton from "@assets/arrow-down.svg";
import NextButton from "@assets/arrow-up.svg";
import TickImage from "@assets/tick.svg";
import { styled } from "styled-components";
import Button from "../../bricks/button";
import UseMultiple from "./hooks/useMultiple";
import {
  type IBetslipBetType,
  betstrikeResponseSelector,
  oldStrikeBet as strikeBet
} from "../../../store/features/betslipSlice";
import type { RootState } from "../../../store/store";
import { CurrentraceCourseNameSelector } from "../../../store/features/meetingsSlice";
import { getBalanceFormatted } from "../../../utils/currencyFormatUtils";
import BettypeCardNew from "./bettypeCardNew/bettypeCardNew";
import BetslipSummaryItemCard from "./betslipSummaryItemCard";
import BetConsolidatedDisplay from "./betConsolidatedDisplay";
import Container from "../../bricks/container";
import FlexContainer from "../../bricks/flexContainer";
import NumberDisplay from "../../composites/numberDisplay";
import { getClientBalance } from "../../../store/features/userSlice";
import openLink from "../../../utils/pageUtils";

const TYPES = {
  ALL_TO_WIN: "ALL TO WIN",
  WIN_SINGLE_BETS: "WIN - SINGLE BETS",
  ALL_TO_PLACE: "ALL TO PLACE",
  PLACE_SINGLE_BETS: "PLACE - SINGLE BETS",
  DOUBLES: "DOUBLES",
  TREBLES: "TREBLES",
  QUADS: "QUADS"
};
const ALLOWED_NUMBER_KEY_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8];

const SubmissionWaitingContainer = styled.div`

background:white;
border-radius:5px;
width:90%;
height:max-content;
margin:auto 0;
display:flex;
flex-direction:column;
align-items:center;
margin-top:5px;
margin-bottom:9px;
padding;15px 0px 20px 0px;
row-gap:10px;
& label {
  width:100%;
  font-size:3.6vw;
}

@media screen and (min-width: 769px) { 
  
  & label {
    width:90%;
    font-size:1.7rem;
  }
 }


 }

`;

const FirstTimerLimit = {
  Single: 50000,
  Double: 100000,
  Treble: 200000,
  Quad: 400000,
  Multiple: 400000
};

const RegularLimit = {
  Single: 100000,
  Double: 200000,
  Treble: 400000,
  Quad: 1000000,
  Multiple: 1000000
};

const BET_STRIKE_STATUS = {
  PROCESSING: "processing",
  SUCCESS: "success",
  SUCCESS_WITH_ERRORS: "successWithErrors",
  BETS_OVER_LIMIT: "betsOverLimit"
};

const BET_SUCCESS = "Fantastic! All your bets have been successfully placed.";
const BET_SUCCESS_WITH_ERRORS =
  "All your bets could not be successfully placed.";
const BET_PROCESSING = "Your bets are being processed now, kindly wait.";
const BET_WITH_OVER_LIMIT = "Following bets are over the payout limit.";

const getAlltoWin = (bets: IBetslipBetType[]): number => {
  const accumulator: number = 1;
  console.log("XXXX " + bets.length);
  const final: number = bets.reduce(
    (accumulator, currentValue) =>
      accumulator * (currentValue.eventDetailOfferedOdds + 1),
    accumulator
  );

  return Number((final - 1).toFixed(2));
};

const getWinSingleBets = (bets: IBetslipBetType[]): number => {
  const accumulator: number = 0;

  const final = bets.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.eventDetailOfferedOdds,
    accumulator
  );

  return Number(final.toFixed(2));
};

const getAlltoPlace = (placeBets: IBetslipBetType[]): number => {
  const accumulator: number = 1;

  const final = placeBets.reduce(
    (accumulator, currentValue) =>
      accumulator * (currentValue.eventDetailOfferedOdds + 1),
    accumulator
  );

  return Number((final - 1).toFixed(2));
};

const getPlaceSingleBets = (placeBets: IBetslipBetType[]): number => {
  const accumulator: number = 0;

  const final = placeBets.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.eventDetailOfferedOdds,
    accumulator
  );

  return Number(final.toFixed(2));
};

type BettypeData = Record<
  string,
  {
    stake: number | undefined;
    payout: number | undefined;
    numBets: number | undefined;
    stakePerBet: number | undefined;
    payoutPerBetUnit: number;
    isSP: boolean;
  }
>;
interface IBetslip {
  open: boolean;
  totalNumberOfRaces: number;
  bets: IBetslipBetType[] | null;
  placeBets: IBetslipBetType[] | null;
  spbets: IBetslipBetType[] | null;
  closureCallback: () => void;
}

const Betslip = ({
  open,
  totalNumberOfRaces,
  bets,
  placeBets,
  spbets,
  closureCallback
}: IBetslip) => {
  const dispatch = useDispatch();

  const [toOpen, setToOpen] = useState(false);

  const [getDouble, getTreble, getQuad] = UseMultiple();

  const apiBets = bets;
  const apiPlacebets = placeBets;

  const apiSpbets: IBetslipBetType[] = spbets || [];
  const balances = useSelector((state: RootState) => state.user.balance);

  const userName = useSelector((state: RootState) => state.user.userName);
  const raceCourseName = useSelector(CurrentraceCourseNameSelector);
  const betStrikeResonse = useSelector(betstrikeResponseSelector);

  const [globalStakePerBet, setGlobalStakePerBet] = useState<number>(1);

  const [isInSummary, setIsInSummary] = useState(false);
  const [isInSubmission, setIsInSubmission] = useState(false);
  const [isSubmissionConfirmed, setIsSubmissionConfirmed] = useState(false);

  const betStrikeStatus = useRef<string>("");
  const errorTexts = useRef<string[]>([]);
  const limitsDisplay = useRef<any[]>([]);

  const stakePerBetInputRef = useRef<HTMLInputElement>(null);

  const [stakeAndPayoutTree, setStakeAndPayoutTree] = useState<BettypeData>({});
  const [stakeAndPayoutDynamicTree, setStakeAndPayoutDynamicTree] =
    useState<BettypeData>({});

  const [betslipNotAvaliable, setBetslipNotAvaliable] =
    useState<boolean>(false);

  const currentBetStrikePayloadData = useRef<{ bets: any[]; singleBet: any }>({
    bets: [],
    singleBet: null
  });

  const isInvalidChar = useRef<boolean>(false);
  const currentValue = useRef<number>(0);

  useEffect(() => {
    setToOpen(open);
  }, [open]);

  const closeBetslip = () => {
    if (!isInSubmission) {
      setToOpen(false);
      closureCallback();
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const onApiInitialData = (
    bets: IBetslipBetType[],
    placebets: IBetslipBetType[]
  ) => {
    const getProcessed = (type: string) => {
      let tempStake: number | undefined = 1;
      let tempPayout: number | undefined = 0;

      if (type === TYPES.ALL_TO_WIN) {
        tempStake = 1;
        tempPayout = getAlltoWin(bets);
      } else if (type === TYPES.WIN_SINGLE_BETS) {
        tempStake = bets.length;
        tempPayout = getWinSingleBets(bets);
      } else if (type === TYPES.ALL_TO_PLACE) {
        tempStake = placebets.length > 0 ? 1 : 0;
        tempPayout = getAlltoPlace(placebets);
      } else if (type === TYPES.PLACE_SINGLE_BETS) {
        tempStake = placebets.length;
        tempPayout = getPlaceSingleBets(placebets);
      } else if (type === TYPES.DOUBLES) {
        const double = getDouble(bets);
        tempStake = double.stake;
        tempPayout = double.payout;
      } else if (type === TYPES.TREBLES) {
        const treble = getTreble(bets);
        tempStake = treble.stake;
        tempPayout = treble.payout;
      } else if (type === TYPES.QUADS) {
        const quad = getQuad(bets);
        tempStake = quad.stake;
        tempPayout = quad.payout;
      }

      return {
        stake: tempStake,
        payout: tempPayout,
        numBets: tempStake,
        payoutPerBetUnit: (tempPayout! / tempStake!).toFixed(2)
      };
    };

    const o: BettypeData = {};

    Object.values(TYPES).forEach((type) => {
      const result = getProcessed(type);
      o[type] = {
        stake: result.stake,
        payout: result.payout,
        numBets: result.numBets,
        stakePerBet: globalStakePerBet,
        payoutPerBetUnit: Number(result.payoutPerBetUnit),
        isSP: false
      };
    });

    setStakeAndPayoutTree((prev) => ({ ...prev, ...o }));

    setStakeAndPayoutDynamicTree(JSON.parse(JSON.stringify(o)));
  };

  const onUpdateFromBettypes = (type: string, stakePerBet: number) => {
    const o = { ...stakeAndPayoutDynamicTree[type] };
    o.stake = stakePerBet * o.numBets!;
    o.payout = o.payoutPerBetUnit * o.stake;
    o.stakePerBet = stakePerBet;

    setStakeAndPayoutDynamicTree((prev) => ({ ...prev, [type]: { ...o } }));
  };

  const onSPSelectionForBettype = (type: string) => {
    const o = { ...stakeAndPayoutDynamicTree[type] };

    o.isSP = !o.isSP;

    setStakeAndPayoutDynamicTree((prev) => ({ ...prev, [type]: { ...o } }));
  };

  const getTotalCurrentPayout = (): string => {
    const totalPayout = Object.values(stakeAndPayoutDynamicTree).reduce(
      (accumulator, type) => Number(type.isSP ? 0 : type.payout) + accumulator,
      0
    );

    return getBalanceFormatted(Number(totalPayout.toFixed(2)));
  };

  const getTotalCurrentStake = (toFormat: boolean = true): string => {
    const totalStake = Object.values(stakeAndPayoutDynamicTree).reduce(
      (accumulator, type) => Number(type.stake) + accumulator,
      0
    );

    if (!toFormat) return totalStake.toFixed(2);

    return getBalanceFormatted(totalStake.toFixed(2));
  };

  const getTotalBets = (): number =>
    Object.values(stakeAndPayoutDynamicTree)
      .filter((item) => item.stakePerBet! > 0 && item.stake! > 0)
      .reduce((accumulator, type) => Number(type.numBets) + accumulator, 0);

  useEffect(() => {
    if (Object.keys(stakeAndPayoutTree).length === 0) {
      const o: BettypeData = {};
      Object.values(TYPES).forEach((type) => {
        o[type] = { stake: 0, payout: 0, numBets: 0, stakePerBet: 1 };
      });
      setStakeAndPayoutTree((prev) => ({ ...prev, ...o }));
    }

    if (apiBets?.length) {
      onApiInitialData(apiBets, apiPlacebets || []);
    } else {
      setBetslipNotAvaliable(true);
    }
  }, [apiBets]);

  useEffect(() => {
    if (stakePerBetInputRef.current)
      stakePerBetInputRef.current.value = globalStakePerBet.toString();
  }, [stakeAndPayoutDynamicTree]);

  useEffect(() => {
    const tempStakeAndPayoutDynamicTree = { ...stakeAndPayoutDynamicTree };

    for (const [key, value] of Object.entries(tempStakeAndPayoutDynamicTree)) {
      console.log(`${key}: ${JSON.stringify(value)}`);

      value.stakePerBet = globalStakePerBet;
      value.stake = value.stakePerBet * value.numBets!;
      value.payout = value.payoutPerBetUnit * value.stake;
    }

    if (Object.keys(tempStakeAndPayoutDynamicTree).length) {
      setStakeAndPayoutDynamicTree(tempStakeAndPayoutDynamicTree);
    }
  }, [globalStakePerBet]);

  const onGlobalStakePerBetInputChange = (e) => {
    if (isInvalidChar.current) {
      e.target.value = currentValue.current;
    } else {
      const inputValue = Number(e.target.value);
      if (e.target.value === "" && currentValue.current === 0)
        e.target.value = 0;

      setGlobalStakePerBet(inputValue);
    }
  };

  const onKeyPress = (e) => {
    isInvalidChar.current = false;
    if (
      !ALLOWED_NUMBER_KEY_CODES.includes(e.keyCode) ||
      !ALLOWED_NUMBER_KEY_CODES.includes(e.which)
    ) {
      isInvalidChar.current = true;
      currentValue.current = e.target.value;
      e.preventDefault();
    }
  };

  const onGlobalStakePerBetUpdaterChange = (isIncrement: boolean = true) => {
    let final: number = globalStakePerBet;

    const incrementFactor: number = isIncrement ? 1 : final > 0 ? -1 : 0;

    final += incrementFactor;

    setGlobalStakePerBet(final);
  };

  const canShowQuad = () => apiBets?.length >= 4;
  const canShowTreble = () => apiBets?.length >= 3;
  const canShowDouble = () => apiBets?.length >= 2;

  const canConfirmABet = (): boolean => {
    const balanceAvailable = balances?.balanceAvailable as number;
    const balance = Math.abs(balanceAvailable);
    const totalCurrentStake = parseInt(getTotalCurrentStake(false));
    return balance > 0 && totalCurrentStake <= balance;
  };

  const canProceedToSummary = () => Number(getTotalCurrentStake(false)) > 0;

  const isEligibaleForBet = (type: string) => {
    const o = stakeAndPayoutDynamicTree[type];

    return (
      o.numBets! > 0 && o.stake! > 0 && o.payout! > 0 && o.stakePerBet! > 0
    );
  };

  const getOddsList = (): number[] =>
    apiBets?.map((bet) => bet.eventDetailOfferedOdds) as number[];

  const getCalculatedOdds = (oddsList: number[], legs: number) =>
    oddsList
      .slice(0, legs)
      .map((a) => (a + 1) * 1)
      .reduce((l, r) => l * r) - 1;

  const buildCombo = (
    title: string,
    type: number,
    legs: number,
    stake: number,
    odds: number,
    payout: number,
    pid: number,
    data: any[]
  ) => ({
    combinatonInfo: {
      BetTitle: title,
      CombinationBetTypeId: type,
      LegsPerBet: legs,
      TotalStake: stake,
      TotalOdds: odds,
      Payout: payout,
      GUID: null,
      pid
    },
    dt: data?.length ? data : null
  });

  const getCombo = (type: string) => {
    const targetDynamicObject = stakeAndPayoutDynamicTree[type];
    const odds = targetDynamicObject.payout! / targetDynamicObject.stake!;

    const comboType: number = type.includes("ALL") ? 1 : 3;
    const payout = type.includes("ALL") ? targetDynamicObject.payout! : 0;

    const legs = (): number => {
      if (type.includes("ALL")) return apiBets?.length as number;
      if (type === TYPES.DOUBLES) return 2;
      if (type === TYPES.TREBLES) return 3;
      if (type === TYPES.QUADS) return 4;

      return -1;
    };

    const totalOdds = (): number => {
      if (targetDynamicObject.isSP) return 0;
      if (type.includes("ALL")) return odds;
      if (type === TYPES.DOUBLES) return getCalculatedOdds(getOddsList(), 2);
      if (type === TYPES.TREBLES) return getCalculatedOdds(getOddsList(), 3);
      if (type === TYPES.QUADS) return getCalculatedOdds(getOddsList(), 4);

      return -1;
    };
    const nonPlacebets = targetDynamicObject.isSP ? apiSpbets : apiBets;
    const data = type.includes("PLACE") ? apiPlacebets! : nonPlacebets!;

    const updatedData: IBetslipBetType[] = [];

    data.forEach((a) =>
      updatedData.push({
        ...a
      })
    );

    if (targetDynamicObject.isSP) {
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].eventDetailOfferedOdds = 0;
      }
    }
    console.log("XXX buildCombo " + type);
    return buildCombo(
      type,
      comboType,
      legs(),
      targetDynamicObject.stake!,
      totalOdds(),
      payout,
      parseInt(userName),
      updatedData
    );
  };
  /**
   * callback on click of confirm bet button
   */
  const onConfirmBetAction = () => {
    if (!canConfirmABet()) {
      return;
    }

    const betObject: { bets: any[]; singleBet: any } = {
      bets: [],
      singleBet: {
        PID: parseInt(userName)
      }
    };

    for (const [key, value] of Object.entries(stakeAndPayoutDynamicTree)) {
      console.log(`${key}: ${value}`);

      if (!key.includes("SINGLE")) {
        if (isEligibaleForBet(key)) betObject.bets.push(getCombo(key));
      } else if (key === TYPES.WIN_SINGLE_BETS) {
        const updatedData: IBetslipBetType[] = [];
        apiSpbets.forEach((a) =>
          updatedData.push({
            ...a
          })
        );

        if (stakeAndPayoutDynamicTree[key].isSP) {
          for (let i = 0; i < updatedData.length; i++) {
            updatedData[i].eventDetailOfferedOdds = 0;
          }
        }

        betObject.singleBet.SingleWinStake =
          stakeAndPayoutDynamicTree[key].stake;
        betObject.singleBet.SingleWinHorses = stakeAndPayoutDynamicTree[key]
          .isSP
          ? updatedData
          : apiBets;
      } else if (key === TYPES.PLACE_SINGLE_BETS) {
        const updatedData: IBetslipBetType[] = [];

        if (apiPlacebets != null) {
          apiPlacebets.forEach((a) =>
            updatedData.push({
              ...a
            })
          );

          if (stakeAndPayoutDynamicTree[key].isSP) {
            for (let i = 0; i < updatedData.length; i++) {
              updatedData[i].eventDetailOfferedOdds = 0;
            }
          }
        }

        betObject.singleBet.SinglePlaceStake =
          stakeAndPayoutDynamicTree[key].stake;
        betObject.singleBet.SinglePlaceHorses = updatedData;
      }
    }

    currentBetStrikePayloadData.current = betObject;

    if (!isInSubmission) {
      setIsInSubmission(true);
      limitsDisplay.current = [];
      dispatch(strikeBet(betObject));
    }
  };
  // TODO: copied from vue code base, refactor
  const allCombinationsUtil = (
    arr: any[],
    dataArr: any[],
    start: number,
    end: number,
    index: number,
    r,
    store
  ) => {
    let temp = [];

    if (index === r) {
      temp = [];
      for (let j = 0; j < r; j++) {
        temp.push(dataArr[j]);
      }
      store.push(temp);
    }
    for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
      dataArr[index] = arr[i];
      allCombinationsUtil(arr, dataArr, i + 1, end, index + 1, r, store);
    }
  };
  // TODO: copied from vue code base, refactor
  const getOddsFormultiples = (arrinput: any[]) => {
    let product = 1;
    arrinput
      .map((x) => x + 1)
      .forEach((item) => {
        product *= item;
      });

    return product;
  };

  const checkAndSetSinglePlaceLimits = () => {
    const singleWinStake =
      currentBetStrikePayloadData.current.singleBet.SingleWinStake;
    const singleWinHorses =
      currentBetStrikePayloadData.current.singleBet.SingleWinHorses;
    const singlePlaceStake =
      currentBetStrikePayloadData.current.singleBet.SinglePlaceStake;
    const singlePlaceHorses =
      currentBetStrikePayloadData.current.singleBet.SinglePlaceHorses;

    const addToLimitsSummary = (
      horsesDataList: any[],
      finalList: any[],
      stakeSingle: number
    ) => {
      horsesDataList.forEach(
        ({
          firstTimer,
          eventDetailName,
          eventNumber,
          eventDetailOfferedOdds,
          eventDetailNumber
        }: {
          firstTimer: boolean;
          eventDetailName: string;
          eventNumber: 1;
          eventDetailOfferedOdds: number;
          eventDetailNumber: number;
        }) => {
          payoutSingleBets = eventDetailOfferedOdds * stakeSingle;

          if (firstTimer) {
            if (payoutSingleBets > FirstTimerLimit.Single) {
              finalList.push({
                BT: "Single",
                Hrs: [
                  {
                    HorseName: eventDetailName,
                    RACE: eventNumber,
                    HorseNumber: eventDetailNumber
                  }
                ],
                OvrB: payoutSingleBets - FirstTimerLimit.Single
              });
            }
          } else {
            console.log("X-> RegularLimit.Single " + RegularLimit.Single);
            if (payoutSingleBets > RegularLimit.Single) {
              finalList.push({
                BT: "Single",
                Hrs: [
                  {
                    HorseName: eventDetailName,
                    RACE: eventNumber,
                    HorseNumber: eventDetailNumber
                  }
                ],
                OvrB: payoutSingleBets - RegularLimit.Single
              });
            }
          }
        }
      );

      return finalList;
    };

    const limitsSummary: any[] = [];

    let IndividualStakeSingle = 0;
    let payoutSingleBets = 0;

    if (singleWinStake > 0) {
      IndividualStakeSingle = singleWinStake / singleWinHorses.length;
      payoutSingleBets = 0;

      addToLimitsSummary(singleWinHorses, limitsSummary, IndividualStakeSingle);
    }

    if (singlePlaceStake > 0) {
      IndividualStakeSingle = singlePlaceStake / singleWinHorses.length;

      addToLimitsSummary(
        singlePlaceHorses,
        limitsSummary,
        IndividualStakeSingle
      );
    }

    const bets = currentBetStrikePayloadData.current.bets;

    let array = bets.length > 0 ? bets[0].dt : [];
    let r = 2;
    let n = array.length;
    let store: any[] = [];
    let tempArray = new Array(r);
    let stakePerDay = 0;
    allCombinationsUtil(array, tempArray, 0, n - 1, 0, r, store);

    currentBetStrikePayloadData.current.bets.forEach((item) => {
      if (item.combinatonInfo.CombinationBetTypeId !== 1) {
        if (item.combinatonInfo.LegsPerBet === 4) {
          array = bets.length > 0 ? bets[0].dt : [];
          r = 4;
          n = array.length;
          store = [];
          tempArray = new Array(r);
          allCombinationsUtil(array, tempArray, 0, n - 1, 0, r, store);

          stakePerDay = item.combinatonInfo.TotalStake / store.length;

          store.forEach((combo) => {
            let payout = getOddsFormultiples(
              combo.map((x) => x.eventDetailOfferedOdds)
            );
            payout = stakePerDay * (payout - 1);
            payout = payout;

            if (combo.filter((x) => x.firstTimer == true).length > 0) {
              payout > FirstTimerLimit.Quad
                ? limitsSummary.push({
                    BT: "Quads",
                    Hrs: combo.map(
                      ({
                        eventDetailName,
                        eventNumber,
                        eventDetailNumber
                      }: {
                        eventDetailName: string;
                        eventNumber: number;
                        eventDetailNumber: number;
                      }) => {
                        return {
                          HorseName: eventDetailName,
                          RACE: eventNumber,
                          HorseNumber: eventDetailNumber
                        };
                      }
                    ),
                    OvrB: payout
                  })
                : () => {};
            } else {
              payout > RegularLimit.Quad
                ? limitsSummary.push({
                    BT: "Quads",
                    Hrs: combo.map(
                      ({
                        eventDetailName,
                        eventNumber,
                        eventDetailNumber
                      }: {
                        eventDetailName: string;
                        eventNumber: number;
                        eventDetailNumber: number;
                      }) => {
                        return {
                          HorseName: eventDetailName,
                          RACE: eventNumber,
                          HorseNumber: eventDetailNumber
                        };
                      }
                    ),
                    OvrB: payout
                  })
                : () => {};
            }
          });
        } else {
          if (item.combinatonInfo.LegsPerBet == 3) {
            array = bets.length > 0 ? bets[0].dt : [];
            r = 3;
            n = array.length;
            store = [];
            tempArray = new Array(r);
            allCombinationsUtil(array, tempArray, 0, n - 1, 0, r, store);

            stakePerDay = item.combinatonInfo.TotalStake / store.length;

            store.forEach((combo) => {
              let payout = getOddsFormultiples(
                combo.map((x) => x.eventDetailOfferedOdds)
              );
              payout = stakePerDay * (payout - 1);
              payout = payout;

              if (combo.filter((x) => x.firstTimer == true).length > 0) {
                payout > FirstTimerLimit.Treble
                  ? limitsSummary.push({
                      BT: "Trebles",
                      Hrs: combo.map(
                        ({
                          eventDetailName,
                          eventNumber,
                          eventDetailNumber
                        }: {
                          eventDetailName: string;
                          eventNumber: number;
                          eventDetailNumber: number;
                        }) => {
                          return {
                            HorseName: eventDetailName,
                            RACE: eventNumber,
                            HorseNumber: eventDetailNumber
                          };
                        }
                      ),
                      OvrB: payout - RegularLimit.Treble
                    })
                  : () => {};
              } else {
                payout > RegularLimit.Treble
                  ? limitsSummary.push({
                      BT: "Trebles",
                      Hrs: combo.map(
                        ({
                          eventDetailName,
                          eventNumber,
                          eventDetailNumber
                        }: {
                          eventDetailName: string;
                          eventNumber: number;
                          eventDetailNumber: number;
                        }) => {
                          return {
                            HorseName: eventDetailName,
                            RACE: eventNumber,
                            HorseNumber: eventDetailNumber
                          };
                        }
                      ),
                      OvrB: payout - RegularLimit.Treble
                    })
                  : () => {};
              }
            });
          } else {
            if (item.combinatonInfo.LegsPerBet == 2) {
              array = bets.length > 0 ? bets[0].dt : [];
              r = 2;
              n = array.length;
              store = [];
              tempArray = new Array(r);
              allCombinationsUtil(array, tempArray, 0, n - 1, 0, r, store);

              stakePerDay = item.combinatonInfo.TotalStake / store.length;

              store.forEach((combo) => {
                let payout = getOddsFormultiples(
                  combo.map((x) => x.eventDetailOfferedOdds)
                );
                payout = stakePerDay * (payout - 1);
                payout = payout;

                if (combo.filter((x) => x.firstTimer == true).length > 0) {
                  if (payout > FirstTimerLimit.Double) {
                    limitsSummary.push({
                      BT: "Doubles",
                      Hrs: combo.map(
                        ({
                          eventDetailName,
                          eventNumber,
                          eventDetailNumber
                        }: {
                          eventDetailName: string;
                          eventNumber: number;
                          eventDetailNumber: number;
                        }) => {
                          return {
                            HorseName: eventDetailName,
                            RACE: eventNumber,
                            HorseNumber: eventDetailNumber
                          };
                        }
                      ),
                      OvrB: payout - RegularLimit.Double
                    });
                  }
                } else {
                  if (payout > RegularLimit.Double) {
                    limitsSummary.push({
                      BT: "Doubles",
                      Hrs: combo.map(
                        ({
                          eventDetailName,
                          eventNumber,
                          eventDetailNumber
                        }: {
                          eventDetailName: string;
                          eventNumber: number;
                          eventDetailNumber: number;
                        }) => {
                          return {
                            HorseName: eventDetailName,
                            RACE: eventNumber,
                            HorseNumber: eventDetailNumber
                          };
                        }
                      ),
                      OvrB: payout - RegularLimit.Double
                    });
                  }
                }
              });
            }
          }
        }
      }
    });

    if (limitsSummary.length) {
      // Getting unique bet types

      const bettypes: any[] = limitsSummary.map((item) => item.BT);
      const uniqeList = new Set(bettypes);
      let tempArray: any[] = [];
      const outObj: any[] = [];

      uniqeList.forEach((uniqueBetType: string) => {
        limitsSummary.forEach((RejectBet) => {
          if (uniqueBetType == RejectBet.BT) {
            tempArray.push(RejectBet.Hrs);
          }
        });

        outObj.push({
          BetType: uniqueBetType,
          RBs: tempArray
        });
        tempArray = [];
      });

      limitsDisplay.current = outObj;

      // setLimitsDisplay(outObj)
    }
  };

  useEffect(() => {
    console.log(
      `BETSLIP betStrikeResonse =   ${JSON.stringify(betStrikeResonse)}`
    );

    const response = betStrikeResonse;

    errorTexts.current = [];

    limitsDisplay.current = [];

    if (response?.ResponseStatus === 1) {
      let count = 0;

      response.response.forEach((x: any) => {
        if (x.ResponseType === -1) {
          count += 1;

          if (
            x.ResponseObject.Errors[0].ErrorDetail.toUpperCase().includes(
              "PAYOUT"
            )
          ) {
            errorTexts.current.push(
              `Payout limit exceeded of  ${getBalanceFormatted(1000000)}`
            );
          } else {
            errorTexts.current.push(x.ResponseObject.Errors[0].ErrorDetail);
          }
        }
      });

      if (count > 0)
        betStrikeStatus.current = BET_STRIKE_STATUS.SUCCESS_WITH_ERRORS;
    }

    if (isInSubmission) {
      checkAndSetSinglePlaceLimits();

      if (limitsDisplay.current.length > 1)
        betStrikeStatus.current = BET_STRIKE_STATUS.BETS_OVER_LIMIT;

      setIsInSubmission(false);
      setIsSubmissionConfirmed(true);

      dispatch(getClientBalance({ userName }));
    }
  }, [betStrikeResonse]);

  const onModifyBetsAction = () => {
    if (!isInSubmission) {
      setIsInSummary(false);
    }
  };

  const getBetStrikeStatusText = () => {
    if (betStrikeStatus.current === BET_STRIKE_STATUS.SUCCESS_WITH_ERRORS)
      return BET_SUCCESS_WITH_ERRORS;
    if (betStrikeStatus.current === BET_STRIKE_STATUS.BETS_OVER_LIMIT)
      return BET_WITH_OVER_LIMIT;

    return BET_SUCCESS;
  };

  const topup = () => {
    openLink("https://m.hollywoodbets.net/account/redeem-voucher");
  };

  const opPlaceABet = () => {
    setIsInSummary(true);
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      {toOpen && (
        <div
          id="betslip_main_container"
          style={{
            position: "absolute",
            zIndex: 80,
            width: "100%",
            height: "calc(max-content +10px)",
            fontFamily: "Poppins, sans-serif",
            marginTop: "-10px"
          }}>
          <div
            style={{
              position: "relative",
              background: "none",
              height: "100%",
              width: "100%"
            }}>
            <div
              id="betslip_overlay"
              style={{
                width: "100%",
                height: "100%",
                opacity: 0.6,
                background: "black",
                display: "grid",
                placeContent: "center",
                top: "0",
                position: "fixed"
              }}
            />

            <div
              style={{
                background: "white",
                border: "1px solid #5C2D91",
                borderRadius: "5px",
                width: "95vw",
                maxWidth: "750px",
                height: "max-content",
                zIndex: 81,
                position: "relative",
                left: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "15px",
                transform: "translate(-50%, -70px)"
              }}>
              {!isInSubmission && (
                <div
                  style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    top: "-30px",
                    right: "10px",
                    fontFamily: "Comic Sans MS"
                  }}>
                  <img onClick={closeBetslip} src={CloseIcon} />
                </div>
              )}

              {isSubmissionConfirmed && !isInSubmission && (
                <>
                  {window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  })}

                  <div
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      width: "90%",
                      height: "max-content",
                      margin: "auto 0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "5px",
                      padding: "15px 0px 20px 0px",
                      rowGap: "10px"
                    }}>
                    <label
                      style={{
                        maxWidth: "100%",
                        textAlign: "center",
                        fontSize: "3.6vw",
                        color: "#5C2D91",
                        borderBottom: "1px solid #b3b3cc",
                        fontWeight: "bold",
                        paddingTop: "5px",
                        marginLeft: "10px",
                        paddingBottom: "7px"
                      }}>
                      {" "}
                      {getBetStrikeStatusText()}
                    </label>

                    {errorTexts.current.length <= 0 &&
                      limitsDisplay.current.length <= 0 && (
                        <img
                          src={TickImage}
                          style={{
                            marginLeft: "0px",
                            width: "400px",
                            height: "40px"
                          }}
                        />
                      )}

                    {errorTexts.current.length > 0 &&
                      limitsDisplay.current.length <= 0 && (
                        <>
                          <p
                            style={{
                              maxWidth: "90%",
                              fontSize: "3.5vw"
                            }}>
                            Here are the reasons why some bets could not be
                            proccessed{" "}
                          </p>
                          <ol
                            style={{
                              marginTop: "-10px",
                              maxWidth: "90%",
                              fontSize: "3vw"
                            }}>
                            {errorTexts.current.map((item, index) => (
                              <li key={`item_${index}`}> {item}</li>
                            ))}{" "}
                          </ol>
                        </>
                      )}

                    {limitsDisplay.current.length > 0 && (
                      <FlexContainer
                        direction="column"
                        width="97%"
                        justifycontent="flex-start">
                        {limitsDisplay.current.map((obj, i) => {
                          const { BetType, RBs } = obj;

                          return (
                            <Fragment key={`LS_container_${i}`}>
                              <label
                                style={{
                                  fontSize: "3vw",
                                  fontWeight: "bold",
                                  width: "fit-content"
                                }}>
                                {" "}
                                {BetType}
                              </label>

                              {RBs.map((horses: any[], indx: number) => (
                                <FlexContainer
                                  key={`LS_horses_${indx}`}
                                  width="100%"
                                  height="30px"
                                  border="1px solid #d2d2e0"
                                  borderradius="4px"
                                  marginbottom="5px"
                                  columngap="10px"
                                  alignitems="center"
                                  justifycontent="center">
                                  {horses.map((data, index) => (
                                    <FlexContainer
                                      key={`LS_horse_${index}_${data.HorseName}`}
                                      width="auto"
                                      height="96%"
                                      direction="row"
                                      columngap="2px"
                                      alignitems="center">
                                      <label
                                        style={{
                                          fontSize: "2.2vw",
                                          fontWeight: "bold",
                                          color: "#7575a3"
                                        }}>{`RACE - ${data.RACE}`}</label>

                                      <NumberDisplay
                                        width="1.2rem"
                                        height="1.2rem"
                                        shadow="2px 2px 3px #cccccc"
                                        value={data.HorseNumber}
                                      />
                                    </FlexContainer>
                                  ))}
                                </FlexContainer>
                              ))}
                            </Fragment>
                          );
                        })}
                      </FlexContainer>
                    )}

                    <label
                      style={{
                        fontSize: "0.7rem",
                        textAlign: "center",
                        width: "80%",
                        color: "#7575a3"
                      }}>
                      {" "}
                      Keep a lookout for any race changes.
                    </label>
                    <label
                      style={{
                        fontSize: "0.7rem",
                        textAlign: "center",
                        width: "80%",
                        color: "#7575a3"
                      }}>
                      {" "}
                      Selection close 5 minutes before the race.
                    </label>

                    <Button
                      onClick={closeBetslip}
                      margintop="13px"
                      width="50%"
                      height="1.8rem"
                      gradient={{
                        colors: ["#764CBF", "#5C2D91"],
                        direction: "linear"
                      }}
                      text="Back to Home"
                    />
                  </div>

                  {/* </div> */}
                </>
              )}

              {isInSubmission && (
                <>
                  {window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  })}

                  <SubmissionWaitingContainer>
                    <div>
                      <ReactLoading
                        type="balls"
                        color="#8080ff"
                        height="80px"
                        width="80px"
                      />
                    </div>

                    <label
                      style={{
                        maxWidth: "100%",
                        textAlign: "center",
                        color: "#5C2D91",
                        borderBottom: "0px solid #b3b3cc",
                        fontWeight: "bold",
                        paddingTop: "5px",
                        marginLeft: "10px",
                        paddingBottom: "7px"
                      }}>
                      {" "}
                      {BET_PROCESSING}
                    </label>
                  </SubmissionWaitingContainer>

                  {/* </div> */}
                </>
              )}

              {!isSubmissionConfirmed && !isInSubmission && (
                <>
                  {/* header starts */}

                  <FlexContainer
                    direction="column"
                    bgcolor="linear-gradient(#764CBF,#5C2D91)"
                    width="100%"
                    height="100%"
                    alignitems="center"
                    color="white"
                    bordertopleftradius="5px"
                    bordertoprightradius="5px">
                    {!apiBets && (
                      <ReactLoading
                        type="spokes"
                        color="#8080ff"
                        height="20px"
                        width="20px"
                      />
                    )}
                    {apiBets && (
                      <>
                        <Container
                          width="80%"
                          textalign="center"
                          bgcolor="none">
                          <label
                            style={{
                              fontSize: "0.8rem"
                            }}>
                            {`${raceCourseName?.toUpperCase()}  -  BETSLIP`}{" "}
                          </label>
                        </Container>

                        <FlexContainer
                          width="80%"
                          justifycontent="space-between"
                          bgcolor="none"
                          marginbottom="5px">
                          <label
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "normal"
                            }}>
                            {" "}
                            {totalNumberOfRaces + 1 - apiBets?.length <
                            totalNumberOfRaces
                              ? `Race ${
                                  totalNumberOfRaces + 1 - apiBets?.length
                                } - ${totalNumberOfRaces} `
                              : `Race ${totalNumberOfRaces} only`}{" "}
                          </label>
                          <label
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "normal"
                            }}>
                            Balance{" "}
                            <span
                              style={{
                                fontWeight: "bold"
                              }}>
                              {getBalanceFormatted(
                                Math.abs(balances?.balanceAvailable) || ""
                              )}
                            </span>{" "}
                          </label>
                        </FlexContainer>
                      </>
                    )}
                  </FlexContainer>

                  {/* //header ends */}

                  {/* All to come and multiple bet types area starts */}

                  {!isInSummary && (
                    <>
                      <FlexContainer
                        width="100%"
                        direction="column"
                        rowgap="5px"
                        alignitems="flex-end">
                        <FlexContainer
                          justifycontent="space-between"
                          width="100%"
                          alignitems="center">
                          <label
                            style={{
                              width: "fit-content",
                              color: "#5C2D91",
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              marginTop: "0px",
                              marginLeft: "12px"
                            }}>
                            ALL TO COME
                          </label>

                          <div
                            id="globalStakePerBetUpdater"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "fit-content",
                              marginTop: "-10px",
                              marginBottom: "10px",
                              marginRight: "10px"
                            }}>
                            <label
                              style={{
                                width: "97%",
                                color: "#5C2D91",
                                fontSize: "0.5rem",
                                fontWeight: "bold"
                              }}>
                              {" "}
                              Update stake per bet{" "}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: "#5C2D91",
                                width: "100%",
                                height: "20px",
                                border: "0px solid #5C2D91",
                                borderLeft: "0px",
                                borderTop: "0px",
                                alignItems: "center",
                                columnGap: "4px"
                              }}>
                              <img
                                onClick={() => {
                                  onGlobalStakePerBetUpdaterChange(false);
                                }}
                                src={PrevButton}
                                style={{
                                  marginLeft: "0px",
                                  width: "16px",
                                  height: "16px"
                                }}
                              />
                              <div
                                style={{
                                  width: "2rem",
                                  height: "0.8rem",
                                  border: "1px solid #5C2D91",
                                  borderRadius: "3px",
                                  display: "flex",
                                  columnGap: "0px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "2px 4px"
                                }}>
                                <label
                                  style={{
                                    color: "#764CBF",
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                    lineHeight: "1"
                                  }}>
                                  {" "}
                                  R
                                </label>
                                <input
                                  id="updateStakeperbetInputField"
                                  type="tel"
                                  size={0.5}
                                  value={globalStakePerBet}
                                  onKeyDown={onKeyPress}
                                  onChange={onGlobalStakePerBetInputChange}
                                  ref={stakePerBetInputRef}
                                  style={{
                                    width: "93%",
                                    color: "#764CBF",
                                    fontWeight: "bold",
                                    fontSize: "0.7rem",
                                    border: "none",
                                    outline: "none",
                                    lineHeight: "1",
                                    verticalAlign: "top",
                                    paddingTop: "0px",
                                    textAlign: "left"
                                  }}
                                />
                              </div>

                              <img
                                onClick={() => {
                                  onGlobalStakePerBetUpdaterChange();
                                }}
                                src={NextButton}
                                style={{
                                  marginLeft: "0px",
                                  width: "16px",
                                  height: "16px"
                                }}
                              />
                            </div>
                          </div>
                        </FlexContainer>

                        <BettypeCardNew
                          typeName={TYPES.ALL_TO_WIN}
                          stake={
                            stakeAndPayoutDynamicTree[TYPES.ALL_TO_WIN].stake
                          }
                          payout={stakeAndPayoutTree[TYPES.ALL_TO_WIN].payout}
                          updaterCallback={onUpdateFromBettypes}
                          stakeperBet={
                            stakeAndPayoutDynamicTree[TYPES.ALL_TO_WIN]
                              .stakePerBet
                          }
                          numberOfBets={
                            stakeAndPayoutTree[TYPES.ALL_TO_WIN].numBets!
                          }
                          toShowSp={!!apiSpbets?.length}
                          isSp={
                            stakeAndPayoutDynamicTree[TYPES.ALL_TO_WIN].isSP
                          }
                          spCallback={onSPSelectionForBettype}
                        />

                        <BettypeCardNew
                          typeName={TYPES.WIN_SINGLE_BETS}
                          stake={
                            stakeAndPayoutDynamicTree[TYPES.WIN_SINGLE_BETS]
                              .stake
                          }
                          payout={
                            stakeAndPayoutTree[TYPES.WIN_SINGLE_BETS].payout
                          }
                          updaterCallback={onUpdateFromBettypes}
                          stakeperBet={
                            stakeAndPayoutDynamicTree[TYPES.WIN_SINGLE_BETS]
                              .stakePerBet
                          }
                          numberOfBets={
                            stakeAndPayoutTree[TYPES.WIN_SINGLE_BETS].numBets!
                          }
                          toShowSp={!!apiSpbets?.length}
                          isSp={
                            stakeAndPayoutDynamicTree[TYPES.WIN_SINGLE_BETS]
                              .isSP
                          }
                          spCallback={onSPSelectionForBettype}
                        />

                        {apiPlacebets?.length > 0 && (
                          <>
                            <BettypeCardNew
                              typeName={TYPES.ALL_TO_PLACE}
                              stake={
                                stakeAndPayoutDynamicTree[TYPES.ALL_TO_PLACE]
                                  .stake
                              }
                              payout={
                                stakeAndPayoutTree[TYPES.ALL_TO_PLACE].payout
                              }
                              updaterCallback={onUpdateFromBettypes}
                              stakeperBet={
                                stakeAndPayoutDynamicTree[TYPES.ALL_TO_PLACE]
                                  .stakePerBet
                              }
                              numberOfBets={
                                stakeAndPayoutTree[TYPES.ALL_TO_PLACE].numBets!
                              }
                            />

                            <BettypeCardNew
                              typeName={TYPES.PLACE_SINGLE_BETS}
                              stake={
                                stakeAndPayoutDynamicTree[
                                  TYPES.PLACE_SINGLE_BETS
                                ].stake
                              }
                              payout={
                                stakeAndPayoutTree[TYPES.PLACE_SINGLE_BETS]
                                  .payout
                              }
                              updaterCallback={onUpdateFromBettypes}
                              stakeperBet={
                                stakeAndPayoutDynamicTree[
                                  TYPES.PLACE_SINGLE_BETS
                                ].stakePerBet
                              }
                              numberOfBets={
                                stakeAndPayoutTree[TYPES.PLACE_SINGLE_BETS]
                                  .numBets!
                              }
                            />
                          </>
                        )}

                        {canShowDouble() && (
                          <label
                            style={{
                              width: "97%",
                              color: "#5C2D91",
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              textAlign: "left"
                            }}>
                            MULTIPLE BET TYPE
                          </label>
                        )}
                        {canShowDouble() && (
                          <BettypeCardNew
                            typeName={TYPES.DOUBLES}
                            stake={
                              stakeAndPayoutDynamicTree[TYPES.DOUBLES].stake
                            }
                            payout={stakeAndPayoutTree[TYPES.DOUBLES].payout}
                            updaterCallback={onUpdateFromBettypes}
                            stakeperBet={
                              stakeAndPayoutDynamicTree[TYPES.DOUBLES]
                                .stakePerBet
                            }
                            numberOfBets={
                              stakeAndPayoutTree[TYPES.DOUBLES].numBets!
                            }
                            toShowSp={!!apiSpbets?.length}
                            isSp={stakeAndPayoutDynamicTree[TYPES.DOUBLES].isSP}
                            spCallback={onSPSelectionForBettype}
                          />
                        )}
                        {canShowTreble() && (
                          <BettypeCardNew
                            typeName={TYPES.TREBLES}
                            stake={
                              stakeAndPayoutDynamicTree[TYPES.TREBLES].stake
                            }
                            payout={stakeAndPayoutTree[TYPES.TREBLES].payout}
                            updaterCallback={onUpdateFromBettypes}
                            stakeperBet={
                              stakeAndPayoutDynamicTree[TYPES.TREBLES]
                                .stakePerBet
                            }
                            numberOfBets={
                              stakeAndPayoutTree[TYPES.TREBLES].numBets!
                            }
                            toShowSp={!!apiSpbets?.length}
                            isSp={stakeAndPayoutDynamicTree[TYPES.TREBLES].isSP}
                            spCallback={onSPSelectionForBettype}
                          />
                        )}
                        {canShowQuad() && (
                          <BettypeCardNew
                            typeName={TYPES.QUADS}
                            stake={stakeAndPayoutDynamicTree[TYPES.QUADS].stake}
                            payout={stakeAndPayoutTree[TYPES.QUADS].payout}
                            updaterCallback={onUpdateFromBettypes}
                            stakeperBet={
                              stakeAndPayoutDynamicTree[TYPES.QUADS].stakePerBet
                            }
                            numberOfBets={
                              stakeAndPayoutTree[TYPES.QUADS].numBets!
                            }
                            toShowSp={!!apiSpbets?.length}
                            isSp={stakeAndPayoutDynamicTree[TYPES.QUADS].isSP}
                            spCallback={onSPSelectionForBettype}
                          />
                        )}
                      </FlexContainer>
                      {/* </div>   */}
                      {/* All to come and multiple bet types area ENDS */}
                      <BetConsolidatedDisplay
                        totalBets={0}
                        totalStake={getTotalCurrentStake()}
                        totalPayout={getTotalCurrentPayout()}
                      />
                      <label
                        style={{
                          color: `${canConfirmABet() ? "#5C2D91" : "green"}`,
                          fontSize: "0.7rem",
                          fontWeight: "bold"
                        }}>
                        {" "}
                        {`You have ${getBalanceFormatted(
                          Math.abs(balances?.balanceAvailable)
                        )} in your Hollywoodbets account.`}
                      </label>
                      {/* top up and place bet buttons starts */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "85%",
                          columnGap: "10px"
                        }}>
                        <Button
                          onClick={topup}
                          fontSize="0.7rem"
                          fontWeight="normal"
                          height="1.7rem"
                          width="50%"
                          gradient={{
                            colors: ["#764CBF", "#5C2D91"],
                            direction: "linear"
                          }}
                          text="Top up your account"
                        />

                        <Button
                          onClick={opPlaceABet}
                          fontSize="0.7rem"
                          fontWeight="normal"
                          height="1.7rem"
                          width="50%"
                          gradient={{
                            colors: ["#764CBF", "#5C2D91"],
                            direction: "linear"
                          }}
                          text="Place a bet"
                          disabled={!canProceedToSummary()}
                        />
                      </div>{" "}
                      {/* top up and place bet buttons ends */}
                      <div
                        onClick={closeBetslip}
                        style={{
                          color: "#5C2D91",
                          fontSize: "0.9rem"
                        }}>
                        Cancel Betslip
                      </div>
                    </>
                  )}
                  {/* conditional selection UI ends */}

                  {/* conditional summary UI starts */}

                  {isInSummary && (
                    <>
                      <div
                        onClick={onModifyBetsAction}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "5px"
                        }}>
                        <div
                          style={{
                            marginLeft: "10px",
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            background: "#F4F6FD",
                            display: "grid",
                            placeContent: "center",
                            border: "1px solid #d9d9d9",
                            fontWeight: "bold",
                            color: "#9494b8"
                          }}>
                          {" "}
                          {"<"}
                        </div>
                        <label
                          style={{
                            width: "max-content",
                            color: "#5C2D91",
                            fontSize: "0.6rem"
                          }}>
                          {" "}
                          Modify Bets{" "}
                        </label>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          rowGap: "5px",
                          marginTop: "-10px"
                        }}>
                        <label
                          style={{
                            width: "max-content",
                            color: "#5C2D91",
                            fontSize: "1rem",
                            fontWeight: "bold"
                          }}>
                          Bet Summary
                        </label>

                        {Object.keys(stakeAndPayoutDynamicTree)
                          .filter((item) => isEligibaleForBet(item))
                          .map((item) => {
                            const o = stakeAndPayoutDynamicTree[item];
                            return (
                              <BetslipSummaryItemCard
                                key={`BetslipSummaryItemCard${item}`}
                                bettype={item}
                                numBets={o.numBets || 0}
                                totalStake={o.stake || 0}
                                totalPayout={o.payout || 0}
                                isSp={o.isSP}
                              />
                            );
                          })}

                        <BetConsolidatedDisplay
                          totalBets={getTotalBets()}
                          totalStake={getTotalCurrentStake()}
                          totalPayout={getTotalCurrentPayout()}
                        />

                        <label
                          style={{
                            color: `${canConfirmABet() ? "#5C2D91" : "green"}`,
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}>
                          {" "}
                          {`You have ${getBalanceFormatted(
                            Math.abs(balances?.balanceAvailable)
                          )} in your Hollywoodbets account.`}
                        </label>
                        {!canConfirmABet() && (
                          <label
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              fontWeight: "bold"
                            }}>
                            {" "}
                            You dont have sufficient funds to place this bet,
                            please top up your account.
                          </label>
                        )}

                        {isInSubmission && !isSubmissionConfirmed && (
                          <div>
                            <ReactLoading
                              type="spokes"
                              color="#8080ff"
                              height="30px"
                              width="30px"
                            />
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "85%",
                            columnGap: "10px"
                          }}>
                          <Button
                            onClick={closeBetslip}
                            width="50%"
                            height="1.7rem"
                            backgroundcolor="#fff"
                            fontcolor="#5C2D91"
                            text="Cancel"
                            border="1px solid #5C2D91"
                          />

                          {!isInSubmission && !isSubmissionConfirmed && (
                            <Button
                              onClick={onConfirmBetAction}
                              fontSize="0.7rem"
                              fontWeight="normal"
                              height="1.7rem"
                              width="50%"
                              gradient={{
                                colors: ["#764CBF", "#5C2D91"],
                                direction: "linear"
                              }}
                              text="Confirm Bet"
                              disabled={!canConfirmABet()}
                            />
                          )}
                          {isInSubmission && (
                            <Button
                              fontSize="0.7rem"
                              fontWeight="normal"
                              height="1.7rem"
                              width="50%"
                              gradient={{
                                colors: ["#764CBF", "#5C2D91"],
                                direction: "linear"
                              }}
                              text="Submitting bet"
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <label
                    style={{
                      fontSize: "0.5rem",
                      fontStyle: "italic"
                    }}>
                    {" "}
                    Subject to maximum winnings per general T's & C's ( section
                    214 )
                  </label>
                  <label
                    style={{
                      fontSize: "0.5rem",
                      fontStyle: "italic",
                      marginTop: "-5px"
                    }}>
                    {" "}
                    Limits apply
                  </label>
                  <div
                    style={{
                      width: "100%",
                      height: "20px"
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Betslip;
