import { useSelector } from "react-redux";
import openLink from "../utils/pageUtils";

import { ImageDiv, MainPageDiv, LabelDiv, VideoDiv } from "./learnToPlay.style";

import type { RootState } from "../store/store";

const LearnToPlay = () => {
  const config = useSelector((state: RootState) => state.config.data);
  const videoLink = config.puntersHowToPlayVideoUrl;
  const NavigateToPodcasts = () => {
    openLink(`${config.youtubeUrl}`);
  };

  return (
    <MainPageDiv>
      <LabelDiv>Learn How to Play! </LabelDiv>
      <VideoDiv>
        <video controls>
          <source src={videoLink} type="video/mp4" />
          <track src="" kind="captions" srcLang="en" label="English captions" />
        </video>
      </VideoDiv>
      <LabelDiv> Horse Racing Previews </LabelDiv>
      <ImageDiv onClick={NavigateToPodcasts} />
    </MainPageDiv>
  );
};

export default LearnToPlay;
