import styled from "styled-components";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import { getColor } from ".";

interface IPunterNameContainer {
  $isSpecial?: boolean;
}

export const StatusContainer = styled(Container)`
  display: grid;
  place-content: center;
  height: fit-content;
  width: fit-content;
  line-height: 1;
  padding-right: 7px;
  @media screen and (max-width: 320px) {
    font-size: 0.5em;
    font-weight: bold;
  }

  @media screen and (min-width: 321px) and (max-width: 400px) {
    font-size: 0.6em;
    font-weight: bold;
  }

  @media screen and (min-width: 401px) and (max-width: 500px) {
    font-size: 0.7em;
    font-weight: bold;
  }

  @media screen and (min-width: 501px) and (max-width: 599px) {
    font-size: 0.8em;
    font-weight: bold;
  }

  @media screen and (min-width: 600px) {
    font-size: 1em;
  }
`;

export const PunterNameContainer = styled(Container)<IPunterNameContainer>`

color:${(props) => (props.$isSpecial ? props.theme.colors.purplePrimary : "none")} ;
font-weight:${(props) => (props.$isSpecial ? "bold" : "normal")} ;
text-align:left;
wordwrap:break-word;
line-height:10px;
@media screen  and (max-width: 290px) {
   width:55%;
   background:none;
   paddingleft=5px
   }
@media screen  and (min-width: 291px) and (max-width: 460px){
   width:60%;
   paddingleft=0px
   }
   @media screen  and (min-width: 461px) and (max-width: 680px){
    width:65%;
    paddingleft=0px
    }
   @media screen  and (min-width: 681px) {
    width:70%;
    paddingleft=0px
    }

@media screen  and (max-width: 320px) {
    font-size:${(props) => (props.$isSpecial ? "3.5vw" : "3.2vw")} ;
   }

   @media screen and (min-width: 321px) and (max-width: 599px) {
    font-size:${(props) => (props.$isSpecial ? "3.5vw" : "2.9vw")} ;
   }

   @media screen and (min-width: 600px)  {
    font-size:${(props) => (props.$isSpecial ? "3.5vw" : "2.5vw")} ;
    }
   @media screen and (min-width: 700px)  {
    font-size:${(props) => (props.$isSpecial ? "3.3vw" : "2.3vw")} ;
    }

`;

export const WinnerSecThirdContainer = styled(FlexContainer)`
  justify-content: flex-end;
  padding-right: 5px;
  column-gap: 5px;
  align-items: center;
  @media screen and (max-width: 290px) {
    width: 45%;
  }
  @media screen and (min-width: 291px) and (max-width: 460px) {
    width: 40%;
  }
  @media screen and (min-width: 461px) and (max-width: 680px) {
    width: 35%;
  }
  @media screen and (min-width: 681px) {
    width: 30%;
  }
`;

export const RankContainer = styled(FlexContainer)<IPunterNameContainer>`
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.$isSpecial ? props.theme.colors.purplePrimary : "white"};
  @media screen and (max-width: 320px) {
    width: 14%;
  }
  @media screen and (min-width: 321px) {
    width: 12%;
  }
  @media screen and (min-width: 322px) and (max-width: 476px) {
    width: 11%;
  }
  @media screen and (min-width: 477px) and (max-width: 600px) {
    width: 10%;
  }
  @media screen and (min-width: 601px) {
    width: 9%;
  }
`;

export const MiddleContainer = styled(FlexContainer)`
  background: white;
  align-items: center;
  height: 25px;
  justify-content: space-between;
  padding-left: 5px;

  @media screen and (max-width: 320px) {
    width: 66%;
  }
  @media screen and (min-width: 321px) {
    width: 68%;
  }
  @media screen and (min-width: 322px) and (max-width: 476px) {
    width: 69%;
  }
  @media screen and (min-width: 477px) and (max-width: 600px) {
    width: 70%;
  }
  @media screen and (min-width: 601px) {
    width: 71%;
  }
`;

export const TotalContainer = styled(Container)<IPunterNameContainer>`
  width: 18%;
  background: ${(props) =>
    props.$isSpecial
      ? `linear-gradient(${props.theme.colors.yellowPrimary}, ${props.theme.colors.yellowSecondary})`
      : `linear-gradient(${props.theme.colors.purpleSecondary},${props.theme.colors.purplePrimary})`};
  border-left: ${(props) =>
    props.$isSpecial
      ? `1px solid ${props.theme.colors.purplePrimary}`
      : "none"};
  color: ${(props) => getColor(props.$isSpecial)};
  font-weight: ${(props) => (props.$isSpecial ? "bold" : "none")};
  height: 25px;
  line-height: 1px;
  display: grid;
  place-content: center;

  @media screen and (max-width: 320px) {
    font-size: 0.6rem;
  }
  @media screen and (min-width: 320px) and (max-width: 420px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 421px) and (max-width: 500px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 501px) and (max-width: 600px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 601px) {
    font-size: 1rem;
  }
`;

export const TotalContainerWeb = styled(Container)<IPunterNameContainer>`
  width: 15%;
  background: ${(props) =>
    props.$isSpecial
      ? `linear-gradient(${props.theme.colors.yellowPrimary}, ${props.theme.colors.yellowSecondary})`
      : `linear-gradient(${props.theme.colors.purpleSecondary},${props.theme.colors.purplePrimary})`};
  border-left: ${(props) =>
    props.$isSpecial
      ? `1px solid ${props.theme.colors.purplePrimary}`
      : "none"};
  border-right: ${(props) =>
    props.$isSpecial
      ? `1px solid ${props.theme.colors.purplePrimary}`
      : "none"};
  color: ${(props) => getColor(props.$isSpecial)};
  font-weight: ${(props) => (props.$isSpecial ? "bold" : "none")};
  display: grid;
  place-content: center;
  font-size: 1.5vw;
`;
