import styled from "styled-components";

export interface ISeparatorDiv {
  borderColor?: string;
  sideMargin?: number;
}

export const SeparatorDiv = styled.div<ISeparatorDiv>`
  display: flex;
  align-text: left;
  width: calc(100% - ${(props) => (props.sideMargin ?? 1) * 2}% - 2px);
  margin: 0% ${(props) => props.sideMargin ?? 1}%;
  border: 1px solid ${(props) => props.borderColor ?? "Black"};
`;

const Separator = (props: ISeparatorDiv) => {
  return <SeparatorDiv {...props} />;
};

export default Separator;
