import { useSelector } from "react-redux";
import { ILeaderboardPunterUnit } from "../../../../store/features/leaderboardSlice";
import FlexContainer from "../../../bricks/flexContainer";
import PunterItem from "../punterItem";
import { RootState } from "../../../../store/store";
import GridMessage from "./gridMessage";

interface IListOfPunters {
  currentPunters: ILeaderboardPunterUnit[] | null;
  indexToExpand: number;
  isForFriendsLeague: boolean;
  expansionCallback: (index: number) => void;
  totalPagesNum: number;
  currentPageNumber: number;
  isMobile: boolean;
  isFetching: boolean;
  isSelected: boolean;
}

const ListOfPunters = ({
  currentPunters,
  indexToExpand,
  isForFriendsLeague,
  expansionCallback,
  totalPagesNum,
  currentPageNumber,
  isMobile,
  isFetching,
  isSelected
}: IListOfPunters) => {
  const { userId } = useSelector((state: RootState) => state.user);

  return (
    <FlexContainer direction="column" rowgap="4px">
      {currentPunters !== undefined &&
        currentPunters?.length > 0 &&
        !isFetching &&
        currentPunters?.map((item, index) => (
          <PunterItem
            key={`PunterItem${item.userId}${item.rank}`}
            index={index}
            punterData={item}
            isSpecial={item.userId === userId && index === 0}
            rank={item.rank}
            leaderName={item.userId as string}
            indexToOpen={indexToExpand}
            total={item.total}
            winners={item.winners}
            second={item.secondPlace}
            third={item.thirdPlace}
            openNotifier={expansionCallback}
            isMobile={isMobile}
            isForMiniLeagues={isForFriendsLeague}
            isLast={
              isForFriendsLeague &&
              index === currentPunters.length - 1 &&
              totalPagesNum === currentPageNumber
            }
          />
        ))}

      {!isSelected && !isFetching && (
        <GridMessage message="Please select a race from the drop down above." />
      )}

      {currentPunters?.length === 0 && isSelected && !isFetching && (
        <GridMessage message="No data available for this meeting." />
      )}
    </FlexContainer>
  );
};

export default ListOfPunters;
