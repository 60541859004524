import { useContext } from "react";
import Icon from "@assets/our-reach.svg";
import BoxMessageWithIcon from "./common/boxMessageWithIcon";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";

const OurReachDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const fontSize = isDesktop ? `${theme.font.size.s}` : "0.8rem";

  return (
    <BoxMessageWithIcon image={Icon} gap="15px" isDesktop={isDesktop}>
      <>
        <TextInSpan
          fontSize="1rem"
          fontWeight="bold"
          zIndex={20}
          lineHeight={1.5}>
          Our reach
        </TextInSpan>
        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {"We proudly feature races from "}
          <b>
            all South African courses, as well as international tracks like
            Kranji (Singapore) and Sha Tin (Hong Kong).
          </b>
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {"With "}
          <b>886 meetings held to date,</b>
          {
            " our community of punters has grown and thrived, creating a vibrant and competitive environment."
          }
        </TextInSpan>
      </>
    </BoxMessageWithIcon>
  );
};

export default OurReachDisplay;
