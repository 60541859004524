import { memo } from "react";
import Container from "../../../../bricks/container";
import ValueDisplay from "./valueDisplay";

export interface IItemYourHighestPoints {
  point: string;
  highest: string;
  courseName: string;
  date: string;
}

const ItemYourHighestPoints = ({
  point,
  highest,
  courseName,
  date
}: IItemYourHighestPoints) => {
  const isDataAvailable =
    point !== "undefined" &&
    highest !== "undefined" &&
    courseName !== "undefined" &&
    date !== "undefined";

  return (
    <>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.9 : 0.6}rem`}
        lineheight="1"
        fontWeight={isDataAvailable ? "bold" : "normal"}>
        {isDataAvailable && (
          <span>
            The highest points you scored in a day{" "}
            <ValueDisplay value={point} />{" "}
          </span>
        )}
        {!isDataAvailable && <span> Data not available.</span>}
      </Container>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.7 : 0.6}rem`}
        fontStyle="italic">
        {isDataAvailable &&
          `The highest points scored by a punter in a day is ${highest} (${courseName}, ${date}).`}
        {!isDataAvailable && `Data not available.`}
      </Container>
    </>
  );
};

export default memo(ItemYourHighestPoints);
