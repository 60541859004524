import React, { useContext, useEffect, useState } from "react";
import FlexContainer from "../../../../bricks/flexContainer";
import Container from "../../../../bricks/container";
import theme from "../../../../../theme";
import { LuckyPickContext } from "../../context/luckyPickContext";
import {
  BET_TYPES_DISPLAY_NAMES,
  BET_TYPES
} from "../../constants/luckyPickConstants";
import { IBetslipBetType } from "../../../../../store/features/betslipSlice";
import { LP_ACTIONS } from "../../store/luckyPickActions";
import { formatInDecimal } from "../../../../../utils/numberUtils";
import { ILuckyPickBetType } from "../../store/luckyPickReducer";
import ActionButtons from "./actionButtons";

interface IHorsePickItem {
  horse: IBetslipBetType;
  isSelected: boolean;
}

const HorsePick = () => {
  const { lpState, lpDispatch } = useContext(LuckyPickContext);

  const {
    winBetHorses,
    placeBetHorses,
    betTypeWinDouble,
    betTypePlaceTreble,
    betTypeWinPlaceDouble
  } = lpState;

  const { betType, toReplace } = lpState.horseSelectionOpenData!;

  const [currentChosenHorses, setCurrentChosenHorses] = useState<
    IBetslipBetType[]
  >([]);
  const [horsesToChooseFrom, setHorsesToChooseFrom] = useState<
    IHorsePickItem[]
  >([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    let resolvedHorseList: IBetslipBetType[] = [];
    let resolvedBetType: ILuckyPickBetType;
    if (betType === BET_TYPES.WIN_DOUBLE) {
      setCurrentChosenHorses(betTypeWinDouble!.horses);
      resolvedHorseList = winBetHorses;
      resolvedBetType = betTypeWinDouble;
    } else if (betType === BET_TYPES.PLACE_TREBLE) {
      setCurrentChosenHorses(betTypePlaceTreble!.horses);
      resolvedHorseList = placeBetHorses;
      resolvedBetType = betTypePlaceTreble;
    } else if (betType === BET_TYPES.WIN_PLACE_DOUBLE) {
      setCurrentChosenHorses(betTypeWinPlaceDouble!.horses);

      const index: number = betTypeWinPlaceDouble!.horses.findIndex(
        (item) => item.eventDetailName === toReplace
      );
      const topRowList = lpState.isWinOnTopInWinPlaceDouble
        ? winBetHorses
        : placeBetHorses;
      const bottomRowList = lpState.isWinOnTopInWinPlaceDouble
        ? placeBetHorses
        : winBetHorses;

      resolvedHorseList = index === 0 ? topRowList : bottomRowList;
      resolvedBetType = betTypeWinPlaceDouble;
    }

    setHorsesToChooseFrom(
      resolvedHorseList
        .filter(
          (horse) =>
            resolvedBetType!.horses.findIndex(
              (currHorse: IBetslipBetType) =>
                currHorse.eventDetailName === horse.eventDetailName
            ) < 0
        )
        .map((item) => {
          return { horse: item, isSelected: false };
        })
    );
  }, []);

  const handleClick = (name: string) => {
    const data = [...horsesToChooseFrom].map((item) => {
      return {
        horse: item.horse,
        isSelected: item.horse.eventDetailName === name
      };
    });
    setHorsesToChooseFrom([...data]);
  };

  const handleConfirm = () => {
    const selected = horsesToChooseFrom.find((item) => item.isSelected)?.horse;

    lpDispatch({
      type: LP_ACTIONS.UPDATE_SELECTED_HORSE,
      betType,
      horse: selected,
      toReplace
    });
  };

  const handleCancel = () => {
    lpDispatch({ type: LP_ACTIONS.CLOSE_HORSE_SELECTION });
  };

  const getTitle = (): string => {
    if (betType === BET_TYPES.WIN_DOUBLE)
      return BET_TYPES_DISPLAY_NAMES.winDouble;
    if (betType === BET_TYPES.PLACE_TREBLE)
      return BET_TYPES_DISPLAY_NAMES.placeTreble;
    if (betType === BET_TYPES.WIN_PLACE_DOUBLE)
      return BET_TYPES_DISPLAY_NAMES.winPlaceDouble;

    return "";
  };

  const getRowColor = (isSelected: boolean, index: number) => {
    if (isSelected)
      return `linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`;
    return index % 2 === 0
      ? `${theme.colors.primary}`
      : `${theme.colors.purpleLight}`;
  };

  return (
    <FlexContainer direction="column" width="100%" position="absolute">
      <FlexContainer
        direction="column"
        alignitems="flex-start"
        paddingleft="20px"
        bgcolor={theme.colors.purpleLight}
        paddingtop="10px">
        <Container
          color={theme.colors.purplePrimary}
          fontSize="1rem"
          fontWeight="bold">
          {`Horse Selections - ${getTitle()}`}
        </Container>

        <FlexContainer
          width="100%"
          color={theme.colors.darkGrey2}
          fontWeight="bold"
          paddingbottom="5px"
          paddingtop="15px">
          <Container width="66%" textalign="left">
            Horse Name
          </Container>
          <Container width="30%" textalign="left">
            Odds
          </Container>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer direction="column" width="100%">
        {currentChosenHorses?.map((horse: IBetslipBetType, index) => {
          return (
            <FlexContainer
              key={`currentChosenHorse${horse.eventDetailName}`}
              width="95%"
              paddingleft="20px"
              color="grey"
              bgcolor={
                index % 2 === 0
                  ? `${theme.colors.primary}`
                  : `${theme.colors.purpleLight}`
              }
              paddingtop="10px"
              paddingbottom="10px"
              fontSize="0.9rem">
              <Container width="67.2%" textalign="left">
                {horse.eventDetailName}
              </Container>
              <Container textalign="left">
                {formatInDecimal(horse.eventDetailOfferedOdds)}
              </Container>
            </FlexContainer>
          );
        })}
        {horsesToChooseFrom?.map((item, index) => {
          return (
            <FlexContainer
              key={`horsesToChooseFrom${item.horse.eventDetailName}`}
              width="95.5%"
              paddingleft="20px"
              color={theme.colors.purplePrimary}
              bgcolor={getRowColor(item.isSelected, index)}
              paddingtop="10px"
              paddingbottom="10px"
              fontWeight="bold"
              fontSize="1rem"
              onClick={() => {
                handleClick(item.horse.eventDetailName);
              }}>
              <Container width="67.2%" textalign="left" bgcolor="none">
                {item.horse.eventDetailName}
              </Container>
              <Container textalign="left" bgcolor="none">
                {formatInDecimal(item.horse.eventDetailOfferedOdds)}
              </Container>
            </FlexContainer>
          );
        })}
      </FlexContainer>
      <ActionButtons
        backgroundColor={theme.colors.purpleLight}
        cancelCallback={handleCancel}
        confirmCallback={handleConfirm}
        toDisableconfirmButton={
          !horsesToChooseFrom.find((item) => item.isSelected)
        }
        confirmButtonText="Confirm Selection"
      />
    </FlexContainer>
  );
};

export default HorsePick;
