import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { useContext, useState } from "react";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import theme from "../../../theme";
import LeaderboardItem from "./leaderboard-item/leaderboardItem";
import NewSearchBar from "../../views/newHomePage/postLogin/newSearchBar";
import {
  SearchDiv,
  SearchNotification,
  LeaderboardItemsContainer,
  LoadingParent,
  LoadingDiv,
  LeaderboardContainer
} from "./leaderboardCard.style";
import {
  GetPunterSelection,
  IPunterSelectionRequest
} from "../../../store/features/resultsSlice";

export interface ILeaderboardPunter {
  punterId: number;
  userId: string;
  rank: number;
  total: number;
  winners: number;
  secondPlace: number;
  thirdPlace: number;
}

interface ILeagueLeaderboard {
  currentMeetingId: number;
  punterId: number;
  isFetching: boolean;
  currentPuntersList: ILeaderboardPunter[];
  isWaitingForMeetingSelection: boolean;
  canViewOthersSelection: boolean;
}

const LeagueLeaderboardCard = ({
  currentMeetingId,
  punterId,
  isFetching,
  currentPuntersList,
  canViewOthersSelection,
  isWaitingForMeetingSelection = false
}: ILeagueLeaderboard) => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const [canOpenSelections, setCanOpenSelections] = useState<number>(-2);

  const onShowSelectionsClick = (
    index: number,
    meetingId: number,
    userId: number
  ) => {
    if (canOpenSelections === index) {
      setCanOpenSelections(-2);
    } else {
      setCanOpenSelections(index);
    }

    const request: IPunterSelectionRequest = {
      meetingId,
      userId
    };

    if (canOpenSelections !== index) {
      dispatch(GetPunterSelection(request));
    }
  };

  const currentPunter = currentPuntersList?.find(
    (punter) => punter.punterId === punterId
  );

  const otherPunters = currentPuntersList?.map((punter, index) => {
    const punterLeaderboardItems = [];

    if (punter.punterId !== punterId) {
      punterLeaderboardItems.push(
        <LeaderboardItem
          index={index}
          punterId={punter.userId.toString()}
          meetingId={currentMeetingId}
          userId={Number(punter.punterId)}
          punterRank={Number(punter.rank)}
          punterPoints={Number(punter.total)}
          winners={Number(punter.winners)}
          secondPlaces={Number(punter.secondPlace)}
          thirdPlaces={Number(punter.thirdPlace)}
          openSelections={canOpenSelections === index}
          onLeaderboardSelectionsClick={onShowSelectionsClick}
          isForFriendsLeague
          canOpen={canViewOthersSelection}
        />
      );
    }

    return punterLeaderboardItems;
  });

  return (
    <LeaderboardContainer>
      {!isWaitingForMeetingSelection && (
        <SearchDiv isDesktop={isDesktop}>
          <NewSearchBar meetingId={currentMeetingId} isForFriendsLeague />
        </SearchDiv>
      )}

      <LeaderboardItemsContainer>
        {isFetching && (
          <LoadingParent>
            <LoadingDiv />
            <ReactLoading
              type="spokes"
              color={theme.colors.aquaSecondary}
              height="60px"
              width="60px"
            />
          </LoadingParent>
        )}

        {currentPuntersList.length === 0 && !isFetching && (
          <SearchNotification isDesktop={isDesktop}>
            <div>
              {isWaitingForMeetingSelection
                ? "Please select a meeting from the drop down above."
                : "No punter data available for this meeting."}
            </div>
          </SearchNotification>
        )}

        {currentPunter && (
          <LeaderboardItem
            index={-1}
            punterId={currentPunter.userId.toString()}
            meetingId={currentMeetingId}
            userId={Number(currentPunter.punterId)}
            punterRank={Number(currentPunter.rank)}
            punterPoints={Number(currentPunter.total)}
            winners={Number(currentPunter.winners)}
            secondPlaces={Number(currentPunter.secondPlace)}
            thirdPlaces={Number(currentPunter.thirdPlace)}
            openSelections={canOpenSelections === -1}
            onLeaderboardSelectionsClick={onShowSelectionsClick}
            isForFriendsLeague
          />
        )}

        {otherPunters}
      </LeaderboardItemsContainer>
    </LeaderboardContainer>
  );
};

export default LeagueLeaderboardCard;
