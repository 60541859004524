export const BET_TYPES = {
  WIN_DOUBLE: "winDouble",
  PLACE_TREBLE: "placeTreble",
  WIN_PLACE_DOUBLE: "winPlaceDouble"
};

export const BET_TYPES_DISPLAY_NAMES: { [key: string]: string } = {
  winDouble: "WIN DOUBLE",
  placeTreble: "PLACE TREBLE",
  winPlaceDouble: "WIN/PLACE DOUBLE"
};

export const SELECTION_TYPES = {
  WIN: "win",
  PLACE: "place"
};

export const DEFAULT_STAKES = {
  WIN_DOUBLE: 10,
  PLACE_TREBLE: 20,
  WIN_PLACE_DOUBLE: 20
};

export const CURRENCY = "R";

export const STAKE_DISTRIBUTION = {
  WIN_DOUBLE: 0.2,
  PLACE_TREBLE: 0.4,
  WIN_PLACE_DOUBLE: 0.4,
  WIN_DOUBLE_WITH_NO_PLACE_BET: 1,
  WIN_DOUBLE_WITH_ONE_PLACE_BET: 0.4,
  PLACE_TREBLE_AS_ONLY_PLACE_BET: 0.6,
  WIN_PLACE_DOUBLE_AS_ONLY_PLACE_BET: 0.6
};

export const DEFAULT_NUM_BETS: number = 3;

export const BETTYPES_TO_LIMITS_MAP: { [key: string]: string } = {
  winDouble: "double",
  placeTreble: "treble",
  winPlaceDouble: "double"
};
