import styled from "styled-components";
import theme from "../../../../../theme";
import {
  TargetDeviceWidth,
  mediaWidthSizeAbove,
  mediaWidthSizeBetween
} from "../../../../../utils/mediaUtils";

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  align-items: center;
  color: white;
  padding: 10px;
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.m}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.l}; `
  )};
  fontfamlily: ${theme.font.family.base};
`;

export const BetSlipTitle = styled.span`
  padding-bottom: 5px;
`;

export const MeetingNameDiv = styled.b`
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.l}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.xl}; `
  )};
`;
export const BalanceDiv = styled.div`
  padding-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.m}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.l}; `
  )};
`;

export const LuckyPickBetslipToggleButton = styled.div<{ toDisable?: boolean }>`
  display: flex;
  background: linear-gradient(
    ${theme.colors.yellowPrimary},
    ${theme.colors.yellowSecondary}
  );
  width: 30%;
  height: 1.5rem;
  color: ${theme.colors.purplePrimary};
  text-align: center;
  font-size: 1.1rem;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${theme.colors.purpleSecondary};
  cursor: pointer;
  font-weight: bold;
  padding: 4px 2px 2px 2px;
  opacity: ${(props) => (props.toDisable ? 0.3 : 1)};
  @media screen and (min-width: 100px) and (max-width: 318px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 319px) and (max-width: 350px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 351px) and (max-width: 383px) {
    font-size: 1rem;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  position: relative;
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.xs}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.s}; `
  )};
`;

export const InfoImageDisplay2 = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const LPInfoTextDiv = styled.div`
  position: absolute;
  top: 85px;
  left: 38%;
  width: 55%;
  height: 52px;
  background: white;
  border: 1px solid ${theme.colors.purplePrimary};
  border-radius: 3px;
  padding: 3px;
  font-size: ${theme.font.size.s};
  z-index: 20;
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` top: 55px; 
      left: 38%; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.s}; `
  )};
`;

export const LPTextScrollDiv = styled.div`
  max-height: 3.4rem;
  height: 99%;
  background: none;
  overflow-y: scroll;
  overflow: auto;
`;

export const LPInfoText = styled.ul`
  font-size: ${theme.font.size.s};
  color: ${theme.colors.purpleSecondary};
  line-height: 1.1;
  display: block;
  text-align: left;
  margin: 0;
  padding-left: 15px;
  list-style-type: disc;
`;
