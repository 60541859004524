import { useContext } from "react";
import { useSelector } from "react-redux";
import { InfoContainer, InfoText } from "./leaderboardInfo.style";
import LeaderboardInfoMiniCard from "./leaderbordInfoMiniCard";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import { RootState } from "../../../../store/store";
import HorseShoeOrWhip from "../../../views/leaderboardGrid/punterItem/horseShoeOrWhip";

export interface ILeaderboardInfo {
  punterId: string;
  winners: number;
  secondPlaces: number;
  thirdPlaces: number;
  openSelections: boolean;
  isForFriendsLeague: boolean;
  rank: number;
  canShowImages: boolean;
}

const LeaderboardInfo = ({
  punterId,
  winners,
  secondPlaces,
  thirdPlaces,
  openSelections,
  isForFriendsLeague,
  rank,
  canShowImages
}: ILeaderboardInfo) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const currentPunter = useSelector((state: RootState) => state.user.userId);

  return (
    <InfoContainer isCurrentPunter={currentPunter === punterId}>
      <InfoText>{punterId}</InfoText>
      {canShowImages && (
        <HorseShoeOrWhip isForFriendsLeague={isForFriendsLeague} rank={rank} />
      )}
      {!isDesktop && (
        <LeaderboardInfoMiniCard
          winners={winners}
          secondPlace={secondPlaces}
          thirdPlace={thirdPlaces}
          openSelections={openSelections}
          isRestricted={!canShowImages}
        />
      )}
    </InfoContainer>
  );
};

export default LeaderboardInfo;
