import styled from "styled-components";
import theme from "../../../../../../theme";
import FlexContainer from "../../../../../bricks/flexContainer";
import {
  TargetDeviceWidth,
  mediaWidthSizeAbove,
  mediaWidthSizeBelow,
  mediaWidthSizeBetween
} from "../../../../../../utils/mediaUtils";

interface IBetTypeToggleImage {
  canSwitch: boolean;
}

export const ValuesContainer = styled(FlexContainer)`
  ${mediaWidthSizeBelow(
    TargetDeviceWidth.mediumMobile,
    ` font-size: 0.75rem; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.sm}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: 0.9rem; `
  )};
`;

export const NameDisplayDiv = styled.div`
  display: flex;
  align-text: left;
  width: fit-content;
  white-space: nowrap;
  margin: 8px 0px;
  align-items: center;
`;

export const NameDisplay = styled.b`
  font-size: ${theme.font.size.m};
  color: ${theme.colors.purplePrimary};
`;

export const CancelBetImage = styled.img`
  padding: 8px;
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  row-gap: 8px;
  margin-bottom: 8px;

  ${mediaWidthSizeBelow(
    TargetDeviceWidth.mediumMobile,
    `font-size: 0.75rem; `
  )};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mediumMobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.sm}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    `  font-size: 0.9rem; `
  )};
`;

export const BetTypeToggleImage = styled.img<IBetTypeToggleImage>`
  display: flex;
  place-content: center;
  opacity: ${(props) => (props.canSwitch ? "1" : "0.3")};
  cursor: pointer;
`;
