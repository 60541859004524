import styled from "styled-components";
import theme from "../../../../../../../theme";

export const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 139px;
  text-align: left;
  padding-left: 6px;
`;

export const RaceNumberDiv = styled.div`
  font-size: 1rem;
  font-weight: ${theme.font.weight.semiBold};
`;

export const TextDiv = styled.div`
  font-size: 0.8rem;
`;
