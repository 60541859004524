import { useContext } from "react";
import BoxedMessage from "./common/BoxedMessage";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import { CoveringDiv, DesktopDiv } from "./overThousandDisplay.style";

const OverThousandDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return isDesktop ? (
    <DesktopDiv>
      <BoxedMessage
        opacity={1}
        backgroundColor="white"
        paddingTop="20px"
        paddingBottom="20px">
        <TextInSpan
          fontSize="min(9vw,1.9rem)"
          lineHeight={1.5}
          zIndex={20}
          fontWeight="600"
          textAlign="center">
          <b>Over R1,000,000</b> can be won each day <br />
          for selecting all of the winners for a race meeting!
        </TextInSpan>
      </BoxedMessage>
    </DesktopDiv>
  ) : (
    <CoveringDiv>
      <BoxedMessage marginTop="16px" opacity={1} backgroundColor="white">
        <TextInSpan
          fontSize="min(9vw,1.4rem)"
          lineHeight={1.5}
          zIndex={20}
          fontWeight="800">
          Over R1,000,000
        </TextInSpan>
        <TextInSpan lineHeight={1.5} zIndex={20} fontWeight="600">
          can be won each day
        </TextInSpan>
        <TextInSpan lineHeight={1.5} zIndex={20} fontWeight="600">
          for selecting all of the
        </TextInSpan>
        <TextInSpan lineHeight={1.5} zIndex={20} fontWeight="600">
          winners for a race meeting!
        </TextInSpan>
      </BoxedMessage>
    </CoveringDiv>
  );
};

export default OverThousandDisplay;
