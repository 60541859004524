import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import LeaderboardGrid from "../components/views/leaderboardGrid";
import type { RootState } from "../store/store";
import "react-loading-skeleton/dist/skeleton.css";
import FlexContainer from "../components/bricks/flexContainer";
import Container from "../components/bricks/container";
import SearchBar from "../components/views/leaderboardGrid/searchBar";
import {
  GetLeaderboardForMeeting,
  GetLeaderboardForMeetingWithPage
} from "../store/features/leaderboardSlice";
import DropDown, { type TDropdownItem } from "../components/views/dropdown";
import { type ITabscrollerTabData } from "../components/views/tabScroller";
import PlayNextChallenge from "../components/views/playNextChallenge";
import { isBeforeToday, isToday } from "../utils/dateUtils";
import { getUpcomingMeetings } from "../store/features/meetingsSlice";
import theme from "../theme";
import ErrorHandler from "../components/errorHandler";
import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import { sortRacesWithSAPriority } from "../utils/raceCourseUtils";
import Carousal from "../components/views/newHomePage/postLogin/Carousel/carousel";

const Results = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const specifiedMeetingId = searchParams.get("meetingId");
  const totalNumbersWRTEvents = useSelector(
    (state: RootState) => state.leaderboard.totalPuntersWRTMeetings
  );
  const allUpcomingMeetingsMetaData = useSelector(
    (state: RootState) => state.meetings.meetingsMetaData
  );
  const playNextChallengeData = useRef<ITabscrollerTabData[] | null>(null);
  const punterId = useSelector(
    (state: RootState) => state.user.data?.user?.punterId
  );
  const competitionData = useSelector(
    (state: RootState) => state.user.data?.competition
  );
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const [dropDownList, setDropDownList] = useState<TDropdownItem[] | null>(
    null
  );
  const [currentMeetingId, setCurrentMeetingId] = useState<string>(
    specifiedMeetingId ?? ""
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMeetingData, setHasMeetingData] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const requestForPunters = (
    pagenum: number,
    meetingid: string,
    perPage: number = 10,
    searchfor: string = ""
  ) => {
    if (totalNumbersWRTEvents[meetingid]) {
      dispatch(
        GetLeaderboardForMeetingWithPage({
          pageId: pagenum,
          meetingId: meetingid,
          numberPerPage: perPage,
          searchFor: searchfor
        })
      );
    } else {
      dispatch(GetLeaderboardForMeeting({ punterId, meetingId: meetingid }));
    }
  };

  const getMeetingResults = (
    meetingId: string,
    pageid?: number,
    toSetLocalMeetingId?: boolean
  ) => {
    if (meetingId) {
      if (toSetLocalMeetingId) setCurrentMeetingId(meetingId);
      if (pageid) setCurrentPage(pageid);
      requestForPunters(1, meetingId, 10, "");
    }
  };

  useEffect(() => {
    if (allUpcomingMeetingsMetaData) {
      const tsData: ITabscrollerTabData[] = sortRacesWithSAPriority(
        allUpcomingMeetingsMetaData
      ).map(
        (item) =>
          ({
            label: item.raceCourseName,
            metadata: item
          }) as ITabscrollerTabData
      );

      playNextChallengeData.current = tsData;
      if (!hasMeetingData) setHasMeetingData(true);
    }
  }, [allUpcomingMeetingsMetaData]);

  useEffect(() => {
    if (specifiedMeetingId) {
      getMeetingResults(specifiedMeetingId);
    }
    dispatch(getUpcomingMeetings());
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      setCurrentMeetingId("");
    };
  }, []);

  useEffect(() => {
    if (competitionData) {
      const ddListData: TDropdownItem[] = competitionData?.meetings
        .filter((item: any) => isToday(item.date) || isBeforeToday(item.date))
        .map(
          (item: any) =>
            ({
              label: `${item.date.split("T")[0]} - ${item.raceCourseName}`,
              data: item
            }) as TDropdownItem
        );

      setDropDownList(ddListData);
    }
  }, [competitionData]);

  const onDropDownSelection = (data: TDropdownItem) => {
    getMeetingResults(data.data.id, 1, true);
  };

  const onNextPrev = (isNext: boolean) => {
    let newpage = isNext ? currentPage + 1 : currentPage - 1;

    if (currentPage <= 0) newpage = 1;

    setCurrentPage(newpage);
    requestForPunters(newpage, currentMeetingId, 10, "");
  };

  const getWidth = () => `${isDesktop ? "95%" : "85%"}`;

  const isDropDownSelected = (index: number) => {
    setIsSelected(index !== -1);
  };

  return (
    <FlexContainer
      direction="column"
      rowgap="1rem"
      alignitems="center"
      bgcolor={theme.colors.bodySecondary}>
      <Container width="100%">
        <Carousal />
      </Container>
      <FlexContainer
        direction="column"
        alignitems={isDesktop ? "left" : "center"}
        width={getWidth()}>
        <Container
          fontWeight="bold"
          width="100%"
          color={theme.colors.purplePrimary}
          textalign={isDesktop ? "left" : "center"}
          height="max-content"
          fontSize={isDesktop ? "1.6vw" : "2.5vw"}
          marginbottom={isDesktop ? "0" : "10px"}>
          Results:
        </Container>
        {!isDesktop && (
          <ErrorHandler>
            <DropDown
              data={dropDownList!}
              onSelection={onDropDownSelection}
              meetingId={specifiedMeetingId}
              setIsSelected={isDropDownSelected}
            />
          </ErrorHandler>
        )}
      </FlexContainer>

      {!isDesktop && (
        <Container marginbottom="10px" width="84%">
          <ErrorHandler>
            <SearchBar isInResults />
          </ErrorHandler>
          <ErrorHandler>
            <LeaderboardGrid
              isPartlyParentControlled
              nextPreviousUpdater={onNextPrev}
              currentMeetingIdSetExternally={currentMeetingId}
              isSelected={isSelected}
            />
          </ErrorHandler>
        </Container>
      )}

      {isDesktop && (
        <FlexContainer bgcolor={theme.colors.aqua} width="95%">
          <Container width="75%" height="75%">
            <ErrorHandler>
              <DropDown
                data={dropDownList!}
                onSelection={onDropDownSelection}
                meetingId={specifiedMeetingId}
                setIsSelected={isDropDownSelected}
              />
            </ErrorHandler>
            <ErrorHandler>
              <LeaderboardGrid
                isPartlyParentControlled
                nextPreviousUpdater={onNextPrev}
                currentMeetingIdSetExternally={currentMeetingId}
                isSelected={isSelected}
              />
            </ErrorHandler>
          </Container>
          <FlexContainer
            marginleft="10px"
            direction="column"
            width="25%"
            rowgap="10px">
            <ErrorHandler>
              <SearchBar />
            </ErrorHandler>

            <ErrorHandler>
              <PlayNextChallenge data={playNextChallengeData.current} />
            </ErrorHandler>
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default Results;
