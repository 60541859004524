import InfoIcon from "@assets/bs-information-icon.svg";
import InfoCloseicon from "@assets/bs-close-info-x.svg";
import Cross from "@assets/grey-cross-icon.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AllToSelector from "../allToSelector/allToSelector";
import theme from "../../../../../theme";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import UpandDownButton from "../../../../composites/upAndDownButton/upAndDownButton";
import { formatInDecimalAndLocal } from "../../../../../utils/numberUtils";
import {
  CollapseDisplayDiv,
  NameDisplayDiv,
  NameDisplay,
  InfoDisplayDiv,
  InfoImageDisplay,
  SeperatorDiv,
  LabelDiv,
  LabelValueDiv,
  StakeCurrencyText,
  InputDisplay,
  PayoutValueDiv,
  LegsDisplayDiv,
  RemoveButtonDisplay,
  InfoTextDiv,
  InfoTextScrollDiv,
  InfoText,
  OverLimitText
} from "./betTypeDisplay.style";
import useInfotext from "../../../betslip/hooks/useInfotext";
import { BetType, IBetSlipBet, IBetSlipMeeting } from "../../betslip.models";
import {
  updateBetStake,
  updateBetTypeEnabled,
  updateBetTypeOddType
} from "../../../../../store/features/newBetSlipSlice";
import Calculations from "../../helpers/calculations";
import Separator from "../seperator";
import { isAllToBet } from "../../helpers/betUtils";
import ToggleBetType from "./toggleBetType";

export interface IBetTypeDisplay {
  data: IBetSlipBet;
  meeting: IBetSlipMeeting;
  index: number;
  zeroOddsSelected: boolean;
}

const BetTypeDisplay = ({
  data,
  meeting,
  index,
  zeroOddsSelected
}: IBetTypeDisplay) => {
  const {
    betType,
    betCount,
    finalOdds,
    stakeToBet,
    enabled,
    isFixedOdds,
    legs,
    overLimit
  } = data;
  const [isOpenList, setIsOpenList] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [potentialPayout, setPotentialPayout] = useState(0);
  const [getInfoForType] = useInfotext();
  const dispatch = useDispatch();

  const shouldShowDropdown = legs?.length;
  const totalStake = Calculations.getTotalStakeForBetType(data);

  const isPlace =
    betType === BetType.SinglePlace || betType === BetType.AllToPlace;

  const enabledLegs = legs.filter((leg) => leg.enabled);

  const legItems = enabledLegs.map((leg, tempIndex) => {
    const legIndex = legs.findIndex((realLeg) => realLeg === leg);

    return (
      <div key={`Leg${leg.race.horseName}`}>
        {tempIndex !== 0 && (
          <Separator borderColor={theme.colors.purplePrimary} />
        )}
        <AllToSelector
          data={leg}
          meeting={meeting}
          betCardIndex={index}
          index={legIndex}
          isWin={betType === BetType.AllToCome}
          isFixedOdds={isFixedOdds}
        />
      </div>
    );
  });

  const currencySymbol = "R";

  const getFlavourText = () => {
    if (isAllToBet(data)) {
      return enabledLegs.length === 1
        ? `(1 BET, 1 LEG)`
        : `(1 BET, ${enabledLegs.length} LEGS)`;
    }

    return betCount === 1 ? "(1 BET)" : `(${betCount} BETS)`;
  };

  const getBetName = () => {
    let name = betType.toString().toUpperCase();

    if (betCount === 1 && name.lastIndexOf("S") > 1) {
      name = name.substring(0, name.length - 1);
    }

    return name;
  };

  const getFinalOdds = () => {
    if (!isFixedOdds) {
      return 0;
    }

    if (enabledLegs.length) {
      return Calculations.getCalculatedOdds(
        Calculations.getOddsFromLegs(legs, isPlace)
      );
    }
    return finalOdds;
  };

  const onOpenLegListClicked = () => {
    setIsOpenList(!isOpenList);
  };

  useEffect(() => {
    setPotentialPayout(getFinalOdds() * stakeToBet + totalStake);
  });

  const onStakeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const hasInvalidStake =
      (!stakeToBet && event.target.value === "0") || event.target.value === "";
    const stake = parseInt(
      hasInvalidStake ? "0" : event.target.value.replace(/^0+(?=\d)/, "")
    );

    dispatch(
      updateBetStake({
        index,
        stake
      })
    );
  };

  const onRemoveBetTypeClicked = () => {
    dispatch(
      updateBetTypeEnabled({
        index,
        isEnabled: false
      })
    );
  };

  const onOddsTypeToggled = (event: boolean) => {
    dispatch(
      updateBetTypeOddType({
        index,
        isFixedOdds: event
      })
    );
  };

  const extraErrorMsg = isAllToBet(data)
    ? "Reduce your stake or remove one of your legs below."
    : "";

  return (
    <div>
      {enabled && (
        <FlexContainer
          borderradius="0px"
          borderbottom={`2px solid ${theme.colors.purplePrimary}`}
          display="inline"
          height="fit-content">
          <Container
            borderradius="0px"
            width="calc(100% - 2px)"
            padding="2px"
            height="fit-content">
            <FlexContainer
              paddingleft="5px"
              position="relative"
              justifycontent="space-between">
              <FlexContainer>
                <NameDisplayDiv>
                  <NameDisplay>
                    {getBetName()} {getFlavourText()}
                  </NameDisplay>
                </NameDisplayDiv>
                <CollapseDisplayDiv>
                  {shouldShowDropdown ? (
                    <UpandDownButton
                      isSolidIcon
                      showDown={!isOpenList}
                      callback={onOpenLegListClicked}
                    />
                  ) : null}
                </CollapseDisplayDiv>
              </FlexContainer>
              {!isPlace && (
                <ToggleBetType
                  onOddsTypeToggled={onOddsTypeToggled}
                  isFixedOdds={isFixedOdds}
                  padding={8}
                  zeroOddsSelected={zeroOddsSelected}
                />
              )}
              <FlexContainer width="20px" justifycontent="right">
                <InfoDisplayDiv>
                  <InfoImageDisplay
                    src={openInfo ? InfoCloseicon : InfoIcon}
                    onClick={() => {
                      setOpenInfo(!openInfo);
                    }}
                  />
                </InfoDisplayDiv>
              </FlexContainer>
              {openInfo && (
                <InfoTextDiv>
                  <InfoTextScrollDiv>
                    <InfoText>{getInfoForType(betType)}</InfoText>
                  </InfoTextScrollDiv>
                </InfoTextDiv>
              )}
            </FlexContainer>

            <SeperatorDiv borderColor={theme.colors.purpleGrey} />

            <FlexContainer width="98%" padding="5px 0px 0px 5px" height="45px">
              <FlexContainer justifycontent="space-between" width="98%">
                <Container width="20%" paddingright="2px">
                  <LabelDiv>Stake {!isAllToBet && "per Bet"}</LabelDiv>
                  <LabelValueDiv>
                    <StakeCurrencyText>{currencySymbol}</StakeCurrencyText>
                    <InputDisplay
                      type="number"
                      value={stakeToBet.toString()}
                      onChange={onStakeChange}
                      min="0"
                    />
                  </LabelValueDiv>
                </Container>
                <Container width="20%" paddingright="2px">
                  <LabelDiv>Total Stake</LabelDiv>
                  <LabelValueDiv>
                    <b>
                      {`${currencySymbol}
                        ${formatInDecimalAndLocal(
                          Number.isNaN(totalStake) ? 0 : totalStake
                        )}`}
                    </b>
                  </LabelValueDiv>
                </Container>
                <Container width="45%" paddingbottom="2px">
                  <LabelDiv>Potential Return</LabelDiv>
                  <PayoutValueDiv overLimit={overLimit && isFixedOdds}>
                    {isFixedOdds ? (
                      <b>
                        {`${currencySymbol}
                        ${formatInDecimalAndLocal(potentialPayout)}`}
                      </b>
                    ) : (
                      <b>SP</b>
                    )}
                  </PayoutValueDiv>
                </Container>
              </FlexContainer>
              <Container display="block" padding="7px 2px 2px 2px">
                <RemoveButtonDisplay
                  src={Cross}
                  onClick={onRemoveBetTypeClicked}
                />
              </Container>
            </FlexContainer>
            {overLimit && isFixedOdds && (
              <OverLimitText>
                {`Current Payout for bet is over the limit. ${extraErrorMsg}`}
              </OverLimitText>
            )}
            {isOpenList && (
              <>
                <SeperatorDiv borderColor={theme.colors.purpleGrey} />
                <LegsDisplayDiv>{legItems}</LegsDisplayDiv>
              </>
            )}
          </Container>
        </FlexContainer>
      )}
    </div>
  );
};

export default BetTypeDisplay;
