import { memo } from "react";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import { MY_LEAGUES_HEADERS } from "../constants/friendsLeaguesConstants";

const LeaguesTableHeader = () => {
  return (
    <FlexContainer
      bgcolor={theme.colors.lightAliceBlue}
      fontSize="0.8rem"
      color="black"
      fontWeight="bold"
      paddingbottom="5px">
      {MY_LEAGUES_HEADERS.map((item, index) => {
        return (
          <Container
            key={`myleaguesheaheritem_${item}`}
            width="20%"
            textalign="left"
            paddingleft={index === 0 ? "20px" : "0px"}>
            {item}
          </Container>
        );
      })}
    </FlexContainer>
  );
};

export default memo(LeaguesTableHeader);
