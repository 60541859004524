import { useContext } from "react";
import Container from "../../bricks/container";
import FlexContainer from "../../bricks/flexContainer";
import SaddleNumberDisplay from "../../composites/saddleNumberDisplay";
import {
  formatInDecimal,
  getOrdinalForNumber
} from "../../../utils/numberUtils";
import theme from "../../../theme";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";

interface IRunnerItemLeague {
  index: string | number;
  horseNum: string | number;
  horseName: string;
  points: string | number;
  position?: number;
}

const RunnerItemLeagues = ({
  index,
  horseNum,
  horseName,
  points,
  position
}: IRunnerItemLeague) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const getBGColorOfPosition = () => {
    if (position! < 4) {
      return `linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`;
    }

    return theme.colors.gray91;
  };

  const getFontSizeForPoints = () => {
    return isDesktop ? "max(11px,1.1vw)" : "max(8px,1.7vw)";
  };

  return (
    <FlexContainer
      height="max-content"
      columngap="2px"
      alignitems="center"
      justifycontent="space-between"
      width="100%">
      <FlexContainer
        width="65%"
        justifycontent="flex-start"
        alignitems="center">
        <Container
          width="20%"
          maxwidth={isDesktop ? "max-content" : "35px"}
          textalign="left"
          lineheight="1"
          fontSize={isDesktop ? "max(1vw,0.55rem)" : "min(2.5vw,0.6rem)"}>
          {`Race ${index}.`}
        </Container>

        <Container width="max-content" marginleft={isDesktop ? "5px" : "2px"}>
          <SaddleNumberDisplay
            value={Number(horseNum)}
            maxHeight={isDesktop ? "26px" : "18px"}
            maxWidthForLow={isDesktop ? "30px" : "20px"}
            maxHeightForLow={isDesktop ? "8px" : "10px"}
            fontSize={isDesktop ? "0.8rem" : "0.6rem"}
          />
        </Container>

        <Container
          margintop="1px"
          marginleft="15px"
          height="auto"
          width="max-content"
          fontSize={isDesktop ? "max(1vw,0.55rem)" : "min(2.5vw,0.6rem)"}
          textalign="left"
          lineheight="1"
          overflowwrap="anywhere">
          {horseName}
        </Container>
      </FlexContainer>

      <FlexContainer
        width={isDesktop ? "30%" : "max(30%,15vw)"}
        borderradius="5px"
        border={`1px solid ${theme.colors.purplePrimary}`}
        alignitems="center"
        bgcolor={theme.colors.lbPointsPurple}>
        <Container
          width="60%"
          height="100%"
          color="white"
          fontSize={getFontSizeForPoints()}
          display="grid"
          placecontent="center">
          {formatInDecimal(Number(points))}
        </Container>

        <Container
          borderbottomrightradius="5px"
          bordertoprightradius="5px"
          width="40%"
          height="100%"
          bgcolor={getBGColorOfPosition()}
          color={theme.colors.purplePrimary}
          fontSize={getFontSizeForPoints()}
          display="grid"
          placecontent="center">
          {getOrdinalForNumber(position!, true)}
        </Container>
      </FlexContainer>
    </FlexContainer>
  );
};

export default RunnerItemLeagues;
