import { IMyLeagueItem } from "../../../../store/features/friendsLeagueSlice";
import theme from "../../../../theme";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";

interface ILeagueScrollerTabsMob {
  leagues: IMyLeagueItem[];
  currentLeague: IMyLeagueItem;
  onLeagueTabClick: (league: IMyLeagueItem) => void;
}

const LeagueScrollerTabsMob = ({
  leagues,
  onLeagueTabClick,
  currentLeague
}: ILeagueScrollerTabsMob) => {
  return (
    <Container
      width="95%"
      overflow="auto"
      height="max-content"
      marginleft="10px"
      margintop="5px">
      <FlexContainer width="fit-content" minwidth="100%" fontSize="0.7rem">
        {leagues.map((league: IMyLeagueItem) => {
          return (
            <Button
              key={`manageLeagueTab${league.leagueName}`}
              text={league.leagueName}
              width="11vw"
              minWidth="max-content"
              background="transparent"
              fontcolor={theme.colors.purplePrimary}
              fontSize="max(0.7rem, 0.9vw)"
              fontWeight={
                league.leagueName === currentLeague?.leagueName
                  ? "bold"
                  : "normal"
              }
              onClick={() => {
                onLeagueTabClick(league);
              }}
              borderbottom={
                league.leagueName === currentLeague?.leagueName
                  ? `3px solid ${theme.colors.purplePrimary}`
                  : "0px"
              }
            />
          );
        })}
      </FlexContainer>
    </Container>
  );
};

export default LeagueScrollerTabsMob;
