import Downarrow from "@assets/arrow-down.svg";
import Uparrow from "@assets/arrow-up.svg";
import SolidDownArrow from "@assets/solid-arrow-down.svg";
import SolidUpArrow from "@assets/solid-arrow-up.svg";
import DownarrowD from "@assets/down.png";
import UparrowD from "@assets/up.png";
import Container from "../../bricks/container";
import { ArrowDown, ArrowUp, ArrowDiv } from "./upAndDownButton.style";
import theme from "../../../theme";

interface IUpDownButton {
  showDown?: boolean;
  callback?: (active: boolean) => void;
  isBoxType?: boolean;
  width?: string;
  height?: string;
  topLeftRadius?: string;
  topRightRadius?: string;
  borderColor?: string;
  isSolidIcon?: boolean;
}

const UpandDownButton = ({
  showDown = true,
  callback,
  width,
  height,
  topLeftRadius,
  topRightRadius,
  borderColor,
  isBoxType = false,
  isSolidIcon = false
}: IUpDownButton) => {
  const handleClick = () => {
    if (callback) {
      const val: boolean = !showDown;
      callback(val);
    }
  };

  return isBoxType ? (
    <Container
      cursor="pointer"
      display="grid"
      placecontent="center"
      paddingbottom="0px"
      width={`${width ?? "4vw"}`}
      height={`${height ?? "2.2vw"}`}
      border={`0px solid ${borderColor}`}
      bgcolor={`linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`}
      onClick={handleClick}
      bordertopleftradius={`${topLeftRadius ?? 0}`}
      bordertoprightradius={`${topRightRadius ?? 0}`}>
      {showDown && <ArrowDown src={DownarrowD} />}
      {!showDown && <ArrowUp src={UparrowD} />}
    </Container>
  ) : (
    <ArrowDiv onClick={handleClick}>
      {showDown && <img src={isSolidIcon ? SolidDownArrow : Downarrow} />}
      {!showDown && <img src={isSolidIcon ? SolidUpArrow : Uparrow} />}
    </ArrowDiv>
  );
};

export default UpandDownButton;
