/* eslint-disable import/prefer-default-export */
export enum LP_ACTIONS {
  UPDATE_HORSES = "updateHorses",
  UPDATE_WIN_DOUBLE_HORSES = "updateWinDoubleHorses",
  UPDATE_PLACE_TREBLE_HORSES = "updatePlaceTrebleHorses",
  UPDATE_WIN_PLACE_DOUBLE_HORSES = "updateWinPlaceDoubleHorses",
  UPDATE_BET_TYPE_WIN_DOUBLE = "updateBetTypeWinDouble",
  UPDATE_BET_TYPE_PLACE_TREBLE = "updateBetTypePlaceTreble",
  UPDATE_BET_TYPE_WIN_PLACE_DOUBLE = "updateWinPlaceDouble",
  REFRESH_SINGLE_HORSE_FOR_BETTYPE = "refreshSingleHorseForBetType",
  GENERATE_NEW = "generateNew",
  UPDATE_STAKE_IN_BETTYPE = "updateStakeInBetType",
  DISTRIBUTE_STAKE = "distributeStake",
  OPEN_HORSE_SELECTION = "openHorseSelection",
  UPDATE_SELECTED_HORSE = "updateSelectedHorse",
  CLOSE_HORSE_SELECTION = "closeHorseSelection",
  SHOW_SUMMARY = "showSummary",
  UPDATE_CANCEL_FUNCTION = "updateCancelFunction",
  SWITCH_WIN_PLACE_DOUBLE = "switchWinPlaceDouble"
}
