import styled from "styled-components";
import { IButton, StyledButton } from "../../../../bricks/button";
import theme from "../../../../../theme";
import {
  TargetDeviceWidth,
  mediaWidthSizeBelow,
  mediaWidthSizeBetween
} from "../../../../../utils/mediaUtils";

interface IMeetingCardProps {
  isDesktop: boolean;
}

interface IQuickSelectionButtons extends IButton {
  isDisabled: boolean;
}

export const MainMeetingRaceCardDiv = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 8px 0px 8px;
  width: calc(100% - 16px);
  height: max-content;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const DesktopDiv = styled.div<IMeetingCardProps>`
  flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
  display: flex;
  width: calc(100% - 16px);
  padding: 8px;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
`;

export const MeetingRaceCardButtonsDiv = styled.div<IMeetingCardProps>`
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: ${({ isDesktop }) =>
    isDesktop ? "space-between" : "space-around"};
  padding: ${(props) => (props.isDesktop ? "10px 0px 10px 0px" : "16px 8px")};
  width: ${(props) => (props.isDesktop ? "50%" : "100%")};
  min-width: 300px;
  column-gap: 8px;

  ${mediaWidthSizeBetween(
    768,
    TargetDeviceWidth.mediumDesktop,
    `
      width: 60%;
    `
  )};
`;

export const NotificationDiv = styled.div<IMeetingCardProps>`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  font-size: 0.65rem;
  column-gap: 16px;
  text-align: ${({ isDesktop }) => (isDesktop ? "center" : "left")};
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.meetingsLightGrey};
  width: ${(props) => (props.isDesktop ? "50%" : "95%")};
  height: ${({ isDesktop }) => (isDesktop ? "max-content" : "auto")};

  ${mediaWidthSizeBetween(
    768,
    TargetDeviceWidth.mediumDesktop,
    `
      width: 40%;
    `
  )};
`;

export const QuickPickButton = styled(StyledButton)<IQuickSelectionButtons>`
  width: max-content;
  min-width: 85px;
  font-size: 0.7rem;
  background-color: ${(props) =>
    props.isDisabled
      ? theme.colors.meetingsLightGrey
      : theme.colors.yellowPrimary};
  border: 1px solid
    ${(props) =>
      props.isDisabled
        ? theme.colors.meetingsLightGrey
        : theme.colors.purplePrimary};
  color: ${(props) =>
    props.isDisabled
      ? theme.colors.resultsDarkGrey
      : theme.colors.purplePrimary};
  opacity: 1;
  cursor: ${(props) => (props.isDisabled ? "inherit" : "pointer")};

  ${mediaWidthSizeBetween(
    768,
    810,
    `
      font-size: 0.6rem;
    `
  )};

  ${mediaWidthSizeBelow(
    500,
    `
      font-size: 0.5rem;
      min-width: 45px;
    `
  )};
`;

export const SelectAllFavoritesButton = styled(
  StyledButton
)<IQuickSelectionButtons>`
  width: max-content;
  font-size: 0.7rem;
  background-color: ${(props) =>
    props.isDisabled
      ? theme.colors.meetingsLightGrey
      : theme.colors.purplePrimary};
  border: 1px solid
    ${(props) =>
      props.isDisabled
        ? theme.colors.meetingsLightGrey
        : theme.colors.yellowPrimary};
  color: ${(props) =>
    props.isDisabled
      ? theme.colors.resultsDarkGrey
      : theme.colors.yellowPrimary};
  opacity: 1;
  cursor: ${(props) => (props.isDisabled ? "inherit" : "pointer")};

  ${mediaWidthSizeBelow(
    500,
    `
      font-size: 0.5rem;
    `
  )};
`;

export const ViewTipsButton = styled(StyledButton)<IButton>`
  width: max-content;
  font-size: 0.7rem;
  background-color: ${(props) => props.backgroundcolor};
  box-shadow: 0px 3px 6px ${theme.colors.cardShadow};

  ${mediaWidthSizeBelow(
    500,
    `
      font-size: 0.5rem;
    `
  )};
`;

export const SelectionLeaderboardToggle = styled(StyledButton)<IButton>`
  width: max-content;
  min-width: 110px;
  font-size: 0.7rem;
  background-color: ${(props) => props.backgroundcolor};
  box-shadow: 0px 3px 6px ${theme.colors.cardShadow};

  ${mediaWidthSizeBelow(
    500,
    `
      font-size: 0.5rem;
      min-width: 90px;
    `
  )};
`;
