import styled from "styled-components";
import theme from "../../../../theme";

interface ILeaderboardProps {
  isCurrentPunter?: boolean;
  isForFriendsLeagues?: boolean;
  hasNoInteraction?: boolean;
}

export const LeaderboardItemDiv = styled.div<ILeaderboardProps>`
  width: ${(props) => (props.isForFriendsLeagues ? "100%" : "95%")};
  height: max-content;
  cursor: ${(props) => (props.hasNoInteraction ? "auto" : "pointer")};
`;

export const LeaderboardCard = styled.div<ILeaderboardProps>`
  margin-top: 15px;
  height: 50px;
  width: ${(props) => (props.isCurrentPunter ? "calc(100% - 2px)" : "100%")};
  background-color: white;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.hasNoInteraction ? "auto" : "pointer")};
  box-shadow: 0px 0px 8px ${theme.colors.cardShadow};
  border: ${(props) =>
    props.isCurrentPunter
      ? `1px solid ${theme.colors.titleAndTextPurple}`
      : "none"};
`;
