import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BetType,
  IBetSlipBet,
  IBetSlipData,
  IBetSlipLeg
} from "../betslip.models";
import {
  BetSlipHoverChild,
  CancelBetslip,
  DescriptorDiv,
  LimitLabel,
  SelectAllCheckbox,
  SelectAllDiv,
  SelectAllText,
  TermsLabel,
  ViewSelectionsText
} from "./betSlipView.styled";
import Calculations from "../helpers/calculations";
import HorseSelector from "./horseSelector/horseSelector";
import Container from "../../../bricks/container";
import BetTypeDisplay from "./betTypeDisplay/betTypeDisplay";
import theme from "../../../../theme";
import {
  selectAllHorses,
  setInitialState
} from "../../../../store/features/newBetSlipSlice";
import { RootState } from "../../../../store/store";
import BetSlipHeader from "./betSlipHeader/betSlipHeader";
import BetSlipTotals from "./betSlipTotals/betSlipTotals";
import BetSlipSummary from "./betSlipSummary/betSlipSummary";
import { isAllToBet } from "../helpers/betUtils";
import {
  ButtonStyle,
  ButtonsDiv
} from "./betSlipSubmission/betSlipSubmission.style";
import BetSlipSubmissionPlace from "./betSlipSubmission/betSlipSubmissionPlace";
import BetSlipSubmissionConfirm from "./betSlipSubmission/betSlipSubmissionConfirm";
import LuckyPick from "../../luckyPick";
import { BetPlacementPayloadData } from "../../../../store/features/betslipSlice";
import UpandDownButton from "../../../composites/upAndDownButton/upAndDownButton";

export interface IBetSlipContext {
  initialState: IBetSlipData;
  closureCallback: () => void;
  onStrikeBet: (betslip: IBetSlipData) => void;
  onLuckyPickBet: (luckyPickBet: BetPlacementPayloadData) => void;
}

const BetSlipView = ({
  initialState,
  closureCallback,
  onStrikeBet,
  onLuckyPickBet
}: IBetSlipContext) => {
  const dispatch = useDispatch();
  const [isOpenList, setIsOpenList] = useState(false);
  const { betslipNew } = useSelector((state: RootState) => state);
  const { meeting, races, bets, forcedSP, totalStake, totalReturn } =
    useSelector((state: RootState) => state.betslipNew);

  const [canShowSummary, setCanShowSummary] = useState(false);
  const { isLuckyPickOn } = useSelector((state: RootState) => state.betslip);
  const [zeroOddsSelected, setZeroOddsSelected] = useState(false);

  const strikeBetView = () => {
    onStrikeBet(betslipNew);
  };

  const isAllHorsesSelected =
    races.filter((x) => x.selected).length === races.length;

  const getBetLegs = (currentBetType: BetType) => {
    const betLegs: IBetSlipLeg[] = [];

    const existingBet = bets.filter((bet) => bet.betType === currentBetType)[0];

    races
      .filter((x) => x.selected)
      .forEach((selectedRace) => {
        const previousBetLeg = existingBet?.legs.filter(
          (leg) => leg.race === selectedRace
        );

        if (previousBetLeg?.length) {
          betLegs.push({
            race: selectedRace,
            enabled: previousBetLeg[0].enabled
          });
        } else {
          betLegs.push({
            race: selectedRace,
            enabled: true
          });
        }
      });

    return betLegs;
  };

  const getAllBetTypes = ({
    selectedCount,
    hasSelect,
    raceOdds,
    racePlaceOdds,
    doubles,
    trebles,
    quads,
    allToComeBc,
    allToComeLegs,
    allToPlaceLegs,
    allToPlaceBc
  }: any) => {
    const allBets: IBetSlipBet[] = [];
    bets.forEach((a) =>
      allBets.push({
        ...a
      })
    );

    allBets.forEach((bet) => {
      const data = bet;
      switch (data.betType) {
        case BetType.SingleWin:
          data.betCount = selectedCount;
          data.finalOdds = Calculations.getCalculatedOddsSingle(
            raceOdds,
            hasSelect
          );
          data.enabled = selectedCount > 0;
          data.isFixedOdds = !forcedSP;
          break;

        case BetType.SinglePlace:
          data.betCount = selectedCount;
          data.finalOdds = Calculations.getCalculatedOddsSingle(
            racePlaceOdds,
            hasSelect
          );
          data.enabled = !forcedSP && selectedCount > 0;
          break;

        case BetType.WinDoubles:
          data.betCount = doubles;
          data.finalOdds = Calculations.getMultiesOdds(raceOdds, 2);
          data.enabled = doubles > 0;
          data.isFixedOdds = !forcedSP;
          break;

        case BetType.WinTrebles:
          data.betCount = trebles;
          data.finalOdds = Calculations.getMultiesOdds(raceOdds, 3);
          data.enabled = trebles > 0;
          data.isFixedOdds = !forcedSP;
          break;

        case BetType.WinQuads:
          data.betCount = quads;
          data.finalOdds = Calculations.getMultiesOdds(raceOdds, 4);
          data.enabled = quads > 0;
          data.isFixedOdds = !forcedSP;
          break;

        case BetType.AllToCome:
          data.betCount = allToComeBc;
          data.finalOdds =
            allToComeBc > 0
              ? Calculations.getCalculatedOdds(
                  Calculations.getOddsFromLegs(allToComeLegs, false)
                )
              : 0;
          data.enabled = allToComeBc > 0;
          data.legs = allToComeLegs;
          data.isFixedOdds = !forcedSP;
          break;

        case BetType.AllToPlace:
          data.betCount = allToPlaceBc;
          data.finalOdds =
            allToPlaceBc > 0
              ? Calculations.getCalculatedOdds(
                  Calculations.getOddsFromLegs(allToPlaceLegs, true)
                )
              : 0;
          data.enabled = !forcedSP && allToPlaceBc > 0;
          data.legs = allToPlaceLegs;
          break;

        default:
          break;
      }
    });
    return allBets;
  };

  useEffect(() => {
    const addBets: IBetSlipBet[] = [];

    addBets.push({
      betType: BetType.SingleWin,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.SinglePlace,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.WinDoubles,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.WinTrebles,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.WinQuads,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.AllToCome,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    addBets.push({
      betType: BetType.AllToPlace,
      betCount: 0,
      finalOdds: 0,
      stakeToBet: 1,
      enabled: true,
      isFixedOdds: true,
      legs: [],
      overLimit: false
    });

    dispatch(
      setInitialState({
        ...initialState,
        bets: addBets
      })
    );
  }, []);

  useEffect(() => {
    const selectedCount = races.filter((a) => a.selected).length;
    const hasSelect = selectedCount > 0;

    const zeroOddsSelected = races.some(
      (race) => race.selected && race.odds === 0
    );
    setZeroOddsSelected(zeroOddsSelected);

    const hasRaces = betslipNew.races?.length > 0;
    const doubles = Calculations.combinations(selectedCount, 2);
    const trebles = Calculations.combinations(selectedCount, 3);
    const quads = Calculations.combinations(selectedCount, 4);

    const allToComeLegs = getBetLegs(BetType.AllToCome);
    const allToComeBc = allToComeLegs.filter((leg) => leg.enabled).length;

    const allToPlaceLegs = getBetLegs(BetType.AllToPlace);
    const allToPlaceBc = allToPlaceLegs.filter((leg) => leg.enabled).length;

    const raceOdds = races
      .filter((race) => race.selected)
      .map((item) => item.odds);

    const racePlaceOdds = races
      .filter((race) => race.selected)
      .map((item) => item.placeOdds);

    const allBets = getAllBetTypes({
      selectedCount,
      hasSelect,
      raceOdds,
      racePlaceOdds,
      doubles,
      trebles,
      quads,
      allToComeBc,
      allToComeLegs,
      allToPlaceLegs,
      allToPlaceBc
    });

    if (hasRaces) {
      dispatch(
        setInitialState({
          ...betslipNew,
          bets: allBets
        })
      );
    }
  }, [races]);

  const onSelectAllHorsesClicked = (event: any) => {
    dispatch(selectAllHorses(event.target.checked));
  };

  const selectedHorsesClicked = () => {
    setIsOpenList(!isOpenList);
  };

  const selectedHorseList = races.map((race, index) => (
    <HorseSelector
      key={`HorseSelector${race.horseName}`}
      index={index}
      data={race}
    />
  ));

  const enabledBetCount = bets.filter((bet) => bet.enabled).length;

  const getTotalNumberOfActiveBets = () => {
    const getResolvedBetCount = (bet: IBetSlipBet) => {
      return isAllToBet(bet) ? 1 : bet.betCount;
    };

    const numActiveBets = bets?.reduce((accumulator, bet) => {
      return bet.enabled &&
        (!bet.overLimit || !bet.isFixedOdds) &&
        bet.stakeToBet > 0 &&
        bet.betCount > 0
        ? accumulator + getResolvedBetCount(bet)
        : accumulator;
    }, 0);
    return numActiveBets;
  };

  const closeBetslip = () => {
    closureCallback();
    window.scrollTo({ top: 0 });
  };

  const betTypeDisplayList = bets.map((bet, index) => {
    return (
      <BetTypeDisplay
        key={`BetType${bet.betType}`}
        index={index}
        data={bet}
        meeting={meeting}
        zeroOddsSelected={zeroOddsSelected}
      />
    );
  });

  const balances = useSelector((state: RootState) => state.user.balance);

  const onPlaceABet = () => {
    setCanShowSummary(true);
    window.scrollTo({ top: 0 });
  };

  return (
    <BetSlipHoverChild>
      <BetSlipHeader forcedSP={forcedSP} zeroOddsSelected={zeroOddsSelected} />
      {isLuckyPickOn && (
        <LuckyPick
          closureCallback={closureCallback}
          onLuckyPickBet={onLuckyPickBet}
        />
      )}
      {!isLuckyPickOn && (
        <Container
          border={`2px solid ${theme.colors.purplePrimary}`}
          borderbottomrightradius="5px"
          borderbottomleftradius="5px"
          bgcolor="white">
          {!canShowSummary && (
            <>
              <Container
                display="block"
                padding="20px"
                borderbottom={
                  enabledBetCount > 0
                    ? `2px solid ${theme.colors.purplePrimary}`
                    : "none"
                }>
                <DescriptorDiv>
                  <b>SELECT HORSES</b>
                  <br />
                  <ViewSelectionsText>
                    View selections & select/unselect horses to make new bet
                    combinations
                  </ViewSelectionsText>
                  {selectedHorseList ? (
                    <UpandDownButton
                      isSolidIcon
                      showDown={!isOpenList}
                      callback={selectedHorsesClicked}
                    />
                  ) : null}
                  {isOpenList && (
                    <SelectAllDiv>
                      <SelectAllCheckbox
                        checked={isAllHorsesSelected}
                        type="checkbox"
                        onChange={onSelectAllHorsesClicked}
                      />
                      <SelectAllText>SELECT ALL</SelectAllText>
                    </SelectAllDiv>
                  )}
                  {isOpenList && selectedHorseList}
                </DescriptorDiv>
                {enabledBetCount > 0 && (
                  <BetSlipTotals
                    stake={totalStake}
                    payout={totalReturn}
                    bets={getTotalNumberOfActiveBets()}
                    hasSP={
                      bets.filter((bet) => bet.enabled && !bet.isFixedOdds)
                        .length > 0
                    }
                    shouldShowAllTotals={canShowSummary}
                    isLuckyPick={false}
                  />
                )}
                <BetSlipSubmissionPlace
                  currentStake={totalStake}
                  placeBetCallback={onPlaceABet}
                  totalActiveBets={getTotalNumberOfActiveBets()}
                />
              </Container>
              <Container paddingbottom="20px">{betTypeDisplayList}</Container>
            </>
          )}
          {canShowSummary && (
            <>
              <Container paddingtop="10px">
                <b style={{ color: theme.colors.purplePrimary }}>
                  Please Confirm Bet
                </b>
                {enabledBetCount > 0 && (
                  <BetSlipTotals
                    stake={totalStake}
                    payout={totalReturn}
                    bets={getTotalNumberOfActiveBets()}
                    hasSP={
                      bets.filter((bet) => bet.enabled && !bet.isFixedOdds)
                        .length > 0
                    }
                    shouldShowAllTotals={canShowSummary}
                  />
                )}
              </Container>
              <BetSlipSubmissionConfirm
                balance={balances?.balanceAvailable ?? 0}
                currentStake={totalStake}
                placeBetCallback={(show) => setCanShowSummary(show)}
                strikeBet={strikeBetView}
                isLuckyPick={false}
              />
              <BetSlipSummary data={bets} />
            </>
          )}
          {enabledBetCount > 0 && (
            <Container fontSize="0.75rem">
              <span style={{ marginRight: "20px" }}>
                <b style={{ color: theme.colors.purplePrimary }}>SP</b> =
                Starting Price
              </span>
              <span style={{ marginLeft: "20px" }}>
                <b style={{ color: theme.colors.purplePrimary }}>FO</b> = Fixed
                Odds
              </span>
            </Container>
          )}
          <ButtonsDiv flexDirection="column">
            {!canShowSummary && (
              <ButtonStyle
                width="200px"
                onClick={onPlaceABet}
                disabled={getTotalNumberOfActiveBets() === 0}>
                Place bet
              </ButtonStyle>
            )}
            <CancelBetslip onClick={closeBetslip}>Cancel Betslip</CancelBetslip>
          </ButtonsDiv>
          <br />
          <TermsLabel isLuckyPick={false}>
            Subject to maximum winnings per general T's & C's ( section 2.14 )
          </TermsLabel>
          <br />
          <LimitLabel isLuckyPick={false}> Limits apply </LimitLabel>
        </Container>
      )}
    </BetSlipHoverChild>
  );
};

export default BetSlipView;
