import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MENU_ITEMS_LOGGED_IN } from "../../../constants";
import useNavigation from "../../../hooks/useNavigation";
import { BURGER_MENU_ITEMS, burgerItemText } from "./burgerMenuConsts";
import { RootState } from "../../../store/store";
import openLink from "../../../utils/pageUtils";
import { setNavigationInitiated } from "../../../store/features/pagesSlice";

interface IBurgerMenuItem {
  burgerItem: BURGER_MENU_ITEMS;
  onItemSelected: () => void;
  onLoginSelect?: () => void;
  onLogoutSelect?: () => void;
}

const BurgerMenuItemsTextDiv = styled.div`
  padding: 8px 0px;
  cursor: pointer;
`;

const BurgerMenuItem = ({
  burgerItem,
  onItemSelected,
  onLoginSelect = () => {},
  onLogoutSelect = () => {}
}: IBurgerMenuItem) => {
  const dispatch = useDispatch();

  const config = useSelector((state: RootState) => state.config.data);

  const burgerItemUrl: { [key in BURGER_MENU_ITEMS]: string } = {
    [BURGER_MENU_ITEMS.FriendsLeague]: "Friends Challenge",
    [BURGER_MENU_ITEMS.Home]: "Home",
    [BURGER_MENU_ITEMS.Leaderboard]: "Leaderboard",
    [BURGER_MENU_ITEMS.LearnToPlay]: "Learn How to Play",
    [BURGER_MENU_ITEMS.Login]: "Login",
    [BURGER_MENU_ITEMS.Logout]: "Logout",
    [BURGER_MENU_ITEMS.Profile]: "",
    [BURGER_MENU_ITEMS.Register]: config.registerSouthAfricaUrl,
    [BURGER_MENU_ITEMS.Results]: "Results"
  };

  const { toNavigate } = useNavigation();

  const onBurgerItemClicked = () => {
    const itemUrl = burgerItemUrl[burgerItem];

    if (itemUrl in MENU_ITEMS_LOGGED_IN) {
      dispatch(setNavigationInitiated(true));
      toNavigate(burgerItemUrl[burgerItem]);
      setTimeout(() => {
        dispatch(setNavigationInitiated(false));
      }, 1);
    } else if (burgerItem === BURGER_MENU_ITEMS.Login) {
      onLoginSelect();
    } else if (burgerItem === BURGER_MENU_ITEMS.Logout) {
      onLogoutSelect();
    } else {
      openLink(itemUrl);
    }

    onItemSelected();
  };

  return (
    <BurgerMenuItemsTextDiv onClick={onBurgerItemClicked}>
      <b>{burgerItemText[burgerItem]}</b>
    </BurgerMenuItemsTextDiv>
  );
};

export default BurgerMenuItem;
