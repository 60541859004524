import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../store/store";
import {
  GetLeaderboardForMeetingWithPage,
  clearPuntersList,
  type ILeaderboardPunterUnit
} from "../../../store/features/leaderboardSlice";
import { FORMATS, getFormatted } from "../../../utils/dateUtils";
import FlexContainer from "../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import { ILeagueLeaderboardItem } from "../../../store/features/friendsLeagueSlice";
import GridStandardFooter from "./footers/gridStandardFooter";
import LeaguesFooter from "./footers/leaguesFooter";
import ListOfPunters from "./gridComponents/listOfPunters";
import Spinner from "../../bricks/spinner";
import Container from "../../bricks/container";

interface ILeaderboardGrid {
  isPartlyParentControlled?: boolean;
  nextPreviousUpdater?: (isNext: boolean) => void;
  currentMeetingIdSetExternally?: string;
  leaguePuntersList?: ILeagueLeaderboardItem[] | null;
  isForFriendsLeague?: boolean;
  leagueNextPreviousCallback?: (isNext: boolean) => void;
  isFetchingForLeagues?: boolean;
  isSelected?: boolean;
}

const MAX_IN_PAGE: number = 10;

const LeaderboardGrid = ({
  isPartlyParentControlled,
  nextPreviousUpdater,
  currentMeetingIdSetExternally,
  leaguePuntersList,
  isForFriendsLeague,
  leagueNextPreviousCallback,
  isFetchingForLeagues,
  isSelected = false
}: ILeaderboardGrid) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const dispatch = useDispatch();

  const [currentPageLocal, setCurrentPageLocal] = useState(1);

  const {
    lbWorkingMetaData,
    lbMeetingsMetaData: currentLBMeetingMetaData,
    currentPage,
    currentMeetingId,
    currentPuntersList,
    totalPuntersWRTMeetings,
    isFetching
  } = useSelector((state: RootState) => state.leaderboard);

  const { competitionData, userId } = useSelector(
    (state: RootState) => state.user
  );

  const allmeetingsMetaData = useSelector(
    (state: RootState) => state.meetings.meetingsMetaData
  );

  const {
    currentLeaderboardTotalPages,
    currentPageNumber,
    isFetchingLeaderboard
  } = useSelector((state: RootState) => state.friendsLeague);

  let currentMeetingName: string | undefined;
  const [currentMeetingTime, setCurrentMeetingTime] = useState<string>("");
  const [totalNumPages, setTotalNumPages] = useState<number>(0);
  const [isFetchingOnNextPrevious, setIsFetchingOnNextPrevious] =
    useState<boolean>(false);

  const getCurrentMeetingId = () => {
    return currentMeetingId
      ? lbWorkingMetaData!.findIndex(
          (item) => item.meetingId === currentMeetingId
        )
      : 0;
  };

  if (
    !currentMeetingIdSetExternally &&
    allmeetingsMetaData &&
    currentLBMeetingMetaData
  ) {
    currentMeetingName = lbWorkingMetaData?.length
      ? lbWorkingMetaData[getCurrentMeetingId()]?.raceCourseName
      : "";
  }

  const [indexToExpand, setIndexToExpand] = useState<number>(-1);
  const [currentPunters, setCurrentPunters] = useState<
    ILeaderboardPunterUnit[] | null
  >(null);

  const onExpansionButtonClick = (index: number) => {
    setIndexToExpand(index);
  };

  const getMeetingDate = (meetingId: string) => {
    const obj = competitionData?.meetings?.find(
      (item) => item.id === String(meetingId)
    );

    return obj?.date ?? "";
  };

  const setTheCurrentMeetingTime = (mmetingId: string | number) => {
    setCurrentMeetingTime(
      getFormatted(getMeetingDate(mmetingId as string), FORMATS["D,DT FM FY"])
    );
  };

  useEffect(() => {
    if (currentLBMeetingMetaData) {
      setTheCurrentMeetingTime(currentLBMeetingMetaData.meetingDate);
    }
  }, [currentLBMeetingMetaData]);

  useEffect(() => {
    return () => {
      dispatch(clearPuntersList());
    };
  }, []);

  useEffect(() => {
    setCurrentPageLocal(currentPage);
  }, [currentPage]);

  const getMeetingName = (meetingId: string) => {
    const obj = competitionData?.meetings?.find(
      (item) => item.id === meetingId
    );

    return obj?.raceCourseName ?? "";
  };

  const returnListAfterRemovingOwn = (
    list: ILeaderboardPunterUnit[],
    targetindex: number
  ): ILeaderboardPunterUnit[] => {
    const ammendedList = JSON.parse(JSON.stringify(list));
    const temp = ammendedList.splice(targetindex, 1);
    ammendedList.unshift(...temp);
    return ammendedList;
  };

  const getRearrangedListBasedOnOwn = (list: ILeaderboardPunterUnit[]) => {
    const isUserInList = list.findIndex(
      (item: ILeaderboardPunterUnit) => item.userId === userId
    );

    if (isUserInList >= 0) {
      return returnListAfterRemovingOwn(list, isUserInList);
    }

    return list;
  };

  const checkAndHandleNewList = (newList: ILeaderboardPunterUnit[]) => {
    if (newList?.length > 0) {
      setTotalNumPages(
        Math.ceil(totalPuntersWRTMeetings[currentMeetingId] / MAX_IN_PAGE)
      );

      setCurrentPunters(getRearrangedListBasedOnOwn(newList));

      if (
        isPartlyParentControlled &&
        currentMeetingIdSetExternally === String(currentMeetingId)
      ) {
        setTheCurrentMeetingTime(currentMeetingId);
      }
    }
  };

  const checkAndHandleNewEmptyList = (newList: ILeaderboardPunterUnit[]) => {
    if (!newList?.length) {
      setCurrentPunters([]);

      setTheCurrentMeetingTime(currentMeetingId);
    }
  };

  const setNextPreviousFetchingStateToFalse = () => {
    if (isFetchingOnNextPrevious) setIsFetchingOnNextPrevious(false);
  };

  useEffect(() => {
    if (isForFriendsLeague) return;

    if (currentPuntersList !== null && !isFetching) {
      setNextPreviousFetchingStateToFalse();
      checkAndHandleNewList(currentPuntersList);
      checkAndHandleNewEmptyList(currentPuntersList);
    } else {
      setCurrentPunters(null);
    }
  }, [currentPuntersList]);

  const closeAnyExpandedItem = () => {
    if (indexToExpand >= 0) setIndexToExpand(-1);
  };

  const updateAnyExternalCallback = (isNext: boolean) => {
    if (nextPreviousUpdater) {
      nextPreviousUpdater(isNext);
    }
  };

  const checkAndSendADirectQuery = (isNext: boolean) => {
    if (!isPartlyParentControlled) {
      dispatch(
        GetLeaderboardForMeetingWithPage({
          pageId: isNext ? currentPage + 1 : currentPage - 1,
          meetingId: currentMeetingId,
          numberPerPage: MAX_IN_PAGE
        })
      );
    }
  };

  const onNextClick = () => {
    closeAnyExpandedItem();

    if (!isFetchingOnNextPrevious) {
      setIsFetchingOnNextPrevious(true);
      updateAnyExternalCallback(true);
      checkAndSendADirectQuery(true);
    }
  };

  const onNextClickFriendsLeague = () => {
    closeAnyExpandedItem();
    leagueNextPreviousCallback!(true);
  };

  const onPreviousClickFriendsLeague = () => {
    closeAnyExpandedItem();
    leagueNextPreviousCallback!(false);
  };

  const onPreviousClick = () => {
    closeAnyExpandedItem();
    if (!isFetchingOnNextPrevious) {
      setIsFetchingOnNextPrevious(true);
      updateAnyExternalCallback(false);
      checkAndSendADirectQuery(false);
    }
  };

  const isWaitingForData = () => {
    return (currentPuntersList === null && isFetching) || isFetchingForLeagues;
  };

  useEffect(() => {
    if (isForFriendsLeague && leaguePuntersList) {
      const newList: ILeaderboardPunterUnit[] = leaguePuntersList.map(
        (item) => {
          return {
            punterId: item.punterId,
            userId: item.punterAlias,
            total: item.meetingPoints,
            winners: item.winners,
            secondPlace: item.secondPlaces,
            thirdPlace: item.thirdPlaces,
            rank: item.leagueRank,
            racePoints: 0,
            bonus: 0
          } as ILeaderboardPunterUnit;
        }
      );

      setCurrentPunters(newList);
    }
  }, [leaguePuntersList]);

  useEffect(() => {
    if (isFetchingLeaderboard && indexToExpand >= 0) setIndexToExpand(-1);
  }, [isFetchingLeaderboard]);

  return (
    <FlexContainer direction="column" marginbottom={`${isDesktop ? 10 : 0}px`}>
      {isWaitingForData() && (
        <Container
          position="relative"
          width="100%"
          height="50px"
          bgcolor="none">
          <Spinner width="50px" height="100%" />
        </Container>
      )}

      <ListOfPunters
        currentPunters={currentPunters}
        indexToExpand={indexToExpand}
        isForFriendsLeague={!!isForFriendsLeague}
        expansionCallback={onExpansionButtonClick}
        totalPagesNum={currentLeaderboardTotalPages}
        currentPageNumber={currentPageNumber}
        isMobile={!isDesktop}
        isFetching={!!isWaitingForData()}
        isSelected={isSelected}
      />

      {!isForFriendsLeague && (
        <GridStandardFooter
          currentMeetingName={
            currentMeetingName ??
            getMeetingName(
              `${currentMeetingIdSetExternally ?? currentMeetingId}`
            )
          }
          currentMeetingTime={currentMeetingTime}
          previousCallback={onPreviousClick}
          nextCallback={onNextClick}
          currentPageLocal={currentPageLocal}
          currentPuntersList={currentPuntersList!}
          currentMeetingId={currentMeetingId}
          totalNumPages={totalNumPages}
        />
      )}
      {isForFriendsLeague && (
        <LeaguesFooter
          previousCallback={onPreviousClickFriendsLeague}
          nextCallback={onNextClickFriendsLeague}
          currentPuntersList={currentPuntersList!}
        />
      )}
    </FlexContainer>
  );
};

export default LeaderboardGrid;
