import styled from "styled-components";
import theme from "../../../../theme";

export const RankContainer = styled.div`
  column-gap: 5px;
  display: grid;
  align-content: center;
  width: 11%;
  height: 100%;
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.leaderboardPurple}
  );
  box-shadow: 2px 0px 6px ${theme.colors.cardShadow};
  justify-content: center;
  z-index: 2;
`;

export const RankText = styled.div`
  color: white;
  align-content: center;
  width: 100%;
`;

export const RankIcon = styled.img`
  color: white;
  align-items: center;
  width: 30px;
  height: 100%;
`;
