import {
  INextPreviousButton,
  ActiveButton,
  getRightRenderable
} from "./nextPreviousButton.style";

const NextPreviousButton = ({
  enabled,
  isNext,
  isActive,
  callBack,
  isMeetingRaceCard,
  navigationText = "",
  ...props
}: INextPreviousButton) => (
  <>
    {!isActive && (
      <ActiveButton opacity={0.3} isButtonEnabled={false} {...props}>
        {getRightRenderable({
          next: !!isNext,
          isMeetingRaceCard,
          navigationText
        })}
      </ActiveButton>
    )}

    {isActive &&
      (enabled ? (
        <ActiveButton onClick={callBack} isButtonEnabled={enabled} {...props}>
          {getRightRenderable({
            next: !!isNext,
            isMeetingRaceCard,
            navigationText
          })}
        </ActiveButton>
      ) : (
        <ActiveButton opacity={0.3} isButtonEnabled={enabled} {...props}>
          {getRightRenderable({
            next: !!isNext,
            isMeetingRaceCard,
            navigationText
          })}
        </ActiveButton>
      ))}
  </>
);

export default NextPreviousButton;
