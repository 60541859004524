import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { RootState } from "../../../../../store/store";
import {
  IMeeting,
  IMeetingMetaData,
  IMeetingSelections,
  getMeetingData,
  getMeetingJackpot,
  getUpcomingMeetings
} from "../../../../../store/features/meetingsSlice";
import FlexContainer from "../../../../bricks/flexContainer";
import GroupHeader from "../../../../composites/group-header/groupHeader";
import {
  FORMATS,
  getDayByDate,
  getFormatted,
  isToday,
  isTomorrow
} from "../../../../../utils/dateUtils";
import { GetLeaderboardForMeeting } from "../../../../../store/features/leaderboardSlice";
import Container from "../../../../bricks/container";
import theme from "../../../../../theme";
import MeetingCardMob, {
  IMeetingRankData
} from "./meetingCard/mob/meetingCardMob";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import MeetingCardWeb from "./meetingCard/web/meetingCardWeb";
import MeetingRaceCard from "../meeting-race-card/meetingRaceCard";
import { isSAMeeting } from "../../../../../utils/raceCourseUtils";

interface IMeetings {
  onSelectionMade: () => void;
  onSubmitClicked: () => void;
  onTakeABetClicked: () => void;
  onMeetingOpened: (newId: number) => void;
}

const Meetings = ({
  onSelectionMade,
  onSubmitClicked,
  onTakeABetClicked,
  onMeetingOpened
}: IMeetings) => {
  const dispatch = useDispatch();

  const {
    meetingsMetaData,
    meetings,
    selections,
    jackpots,
    abandonedList,
    postponedList,
    abandonedMeetings
  } = useSelector((state: RootState) => state.meetings);
  const { meetingIdToDataMap } = useSelector(
    (state: RootState) => state.leaderboard
  );

  const { punterId } = useSelector((state: RootState) => state.user);

  const [currentExpanded, setCurrentExpanded] = useState<string>();

  const { isDesktop } = useContext(MediaQueriesContext).data;

  const [meetingsByDate, setMeetingsByDate] = useState<{
    [date: string]: IMeetingMetaData[];
  }>({});
  const [meetingsAndSelections, setMeetingsAndSelections] = useState<{
    [meetingId: string]: {
      meetings: IMeeting;
      selections: IMeetingSelections[];
    };
  }>({});
  const [jackpotsData, setJackpotsData] = useState<{
    [meetingId: string]: string;
  }>({});
  const tempData = useRef<{ [data: string]: IMeetingMetaData[] }>({});
  const tempRefOfMeetingToId = useRef<{
    [meetingId: string]: {
      meetings: IMeeting;
      selections: IMeetingSelections[];
    };
  }>({});

  const totalNumberOfMeetings = useRef<number>(-1);

  const getIsAbandoned = (meetingId: number) => {
    return meetingId > 0 && abandonedList.includes(meetingId);
  };

  const getIsPostponed = (meetingId: number) => {
    return meetingId > 0 && postponedList.includes(meetingId);
  };

  const getGroupTitle = (date: string) => {
    if (isToday(date)) return "Today";

    if (isTomorrow(date)) return "Tomorrow";

    return getDayByDate(date);
  };

  const getRankData = (meetingId: string): IMeetingRankData | null => {
    if (!meetingIdToDataMap) return null;
    const data = meetingIdToDataMap[meetingId];

    if (data?.punters.length) {
      const puntersOwnData = data?.punters.find(
        (item) => item.punterId === punterId
      );

      if (puntersOwnData) {
        const { rank, total, totalPunters } = puntersOwnData;

        return {
          points: Number(total),
          rank: Number(rank),
          totalPunters: Number(totalPunters),
          meetingId
        } as IMeetingRankData;
      }
    }

    return null;
  };

  const isReady = () => {
    return (
      meetingsAndSelections &&
      Object.keys(meetingsAndSelections).length ===
        totalNumberOfMeetings.current &&
      Object.keys(jackpotsData).length === totalNumberOfMeetings.current
    );
  };

  const onMeetingExpansion = (meetingId: string) => {
    if (currentExpanded !== meetingId) {
      setCurrentExpanded(meetingId);
    }

    onMeetingOpened(Number(meetingId));
  };

  const reArrangeMeetingsOrderBasedOnTime = () => {
    Object.values(tempData.current).forEach((meetingsListInDay) => {
      meetingsListInDay.sort((meet1, meet2) => {
        return (
          isSAMeeting(meet1.raceCourseName) &&
          isSAMeeting(meet2.raceCourseName) &&
          new Date(meetings[meet1.meetingId].meetingDate) -
            new Date(meetings[meet2.meetingId].meetingDate)
        );
      });
    });
  };

  useEffect(() => {
    if (meetingsMetaData && Object.keys(tempData.current).length === 0) {
      totalNumberOfMeetings.current = Object.keys(meetingsMetaData).length;

      meetingsMetaData.forEach((item: IMeetingMetaData) => {
        if (!tempData.current[item.meetingDate]) {
          tempData.current[item.meetingDate] = [];
        }

        tempData.current[item.meetingDate].push(item);

        dispatch(getMeetingData({ punterId, meetingId: item.meetingId }));
        dispatch(getMeetingJackpot({ meetingId: item.meetingId }));
        dispatch(
          GetLeaderboardForMeeting({
            punterId,
            meetingId: item.meetingId
          })
        );
      });
    }
  }, [meetingsMetaData]);

  useEffect(() => {
    if (meetings && selections) {
      for (const [key, value] of Object.entries(meetings)) {
        if (
          meetingsMetaData?.find(
            (meet) => Number(meet.meetingId) === Number(value.meetingId)
          )
        ) {
          tempRefOfMeetingToId.current[key] = {
            meetings: value,
            selections: selections[key]
          };
        }
      }

      if (abandonedMeetings) {
        for (const [key, value] of Object.entries(abandonedMeetings)) {
          tempRefOfMeetingToId.current[key] = {
            meetings: value,
            selections: selections[key]
          };
        }
      }

      if (
        Object.keys(tempRefOfMeetingToId.current).length ===
          totalNumberOfMeetings.current &&
        meetingsAndSelections
      ) {
        reArrangeMeetingsOrderBasedOnTime();

        setMeetingsByDate({ ...tempData.current });

        setMeetingsAndSelections({ ...tempRefOfMeetingToId.current });
      }
    }
  }, [meetings, selections, abandonedMeetings]);

  useEffect(() => {
    if (
      jackpots &&
      Object.keys(jackpots).length === totalNumberOfMeetings.current
    ) {
      const temp: { [meetingId: string]: string } = {};
      for (const [key, value] of Object.entries(jackpots)) {
        temp[key] = String(value.jackpotFigure);
      }
      setJackpotsData({ ...temp });
    }
  }, [jackpots]);

  useEffect(() => {
    if (!meetingsMetaData) {
      dispatch(getUpcomingMeetings());
    }
  }, []);

  const getHasSelections = (meetingId: number) => {
    return !!meetingsAndSelections[meetingId].selections?.length;
  };

  const getRaceColor = (meetingId: number, raceCourseName: string): string => {
    return !getHasSelections(meetingId)
      ? theme.colors.pickNowRed
      : theme.raceColors(raceCourseName);
  };

  const getCommonProps = (item: IMeetingMetaData) => {
    return {
      width: "100%",
      raceCourseName: item.raceCourseName,
      time: meetingsAndSelections[item.meetingId].meetings.meetingDate,
      jackpot: jackpotsData[item.meetingId],
      hasSelections: getHasSelections(Number(item.meetingId)),
      rankData: getRankData(String(item.meetingId)),
      isAbandoned: getIsAbandoned(Number(item.meetingId)),
      isPostponed: getIsPostponed(Number(item.meetingId)),
      allRaces: meetingsAndSelections[item.meetingId].meetings.races,
      canExpand: currentExpanded === String(item.meetingId),
      onExpandCallback: onMeetingExpansion,
      meetingId: String(item.meetingId),
      raceColor: getRaceColor(Number(item.meetingId), item.raceCourseName)
    };
  };

  const getMeetingRaceCardToEmbed = (item: IMeetingMetaData) => {
    return (
      <MeetingRaceCard
        currentMeetingId={Number(item.meetingId)}
        meetingName={item.raceCourseName}
        color={getRaceColor(Number(item.meetingId), item.raceCourseName)}
        onSelectionMade={onSelectionMade}
        onSubmitClicked={onSubmitClicked}
        onTakeABetClicked={onTakeABetClicked}
      />
    );
  };

  return (
    <FlexContainer direction="column" width={isDesktop ? "98%" : "92%"}>
      {!isReady() && meetingsMetaData?.length && (
        <FlexContainer direction="column" rowgap="10px">
          {meetingsMetaData.map((meeting) => {
            return (
              <Container
                key={`placeHolder${meeting.meetingId}`}
                width="90%"
                height="54px">
                <ReactLoading
                  type="spokes"
                  color={theme.colors.purpleLight}
                  height="100%"
                  width="50px"
                />
              </Container>
            );
          })}
        </FlexContainer>
      )}

      {isReady() &&
        Object.keys(meetingsByDate).map((date: string) => {
          return (
            <FlexContainer
              key={`meetingGroup${date}`}
              direction="column"
              rowgap="5px"
              marginbottom="10px">
              <GroupHeader
                color={isDesktop ? "black" : "white"}
                title={getGroupTitle(date)}
                subtitle={getFormatted(date, FORMATS["DT,Month"])}
                imageBackgroundColor={isDesktop ? "black" : ""}
                toMask={!!isDesktop}
              />
              {meetingsByDate[date]
                .filter(
                  (meet) =>
                    meetingsAndSelections[meet.meetingId].meetings.races
                      .length > 0 || getIsAbandoned(Number(meet.meetingId))
                )
                .map((item) => {
                  return isDesktop ? (
                    <MeetingCardWeb
                      key={`MeetingCardWeb${item.raceCourseName}${meetingsAndSelections[item.meetingId].meetings.meetingDate}`}
                      {...getCommonProps(item)}
                      contentToOpen={getMeetingRaceCardToEmbed(item)}
                    />
                  ) : (
                    <MeetingCardMob
                      key={`MeetingCardMob${item.raceCourseName}${meetingsAndSelections[item.meetingId].meetings.meetingDate}`}
                      {...getCommonProps(item)}
                      contentToOpen={getMeetingRaceCardToEmbed(item)}
                    />
                  );
                })}
            </FlexContainer>
          );
        })}
    </FlexContainer>
  );
};

export default Meetings;
