import styled from "styled-components";
import Button from "../../../bricks/button";
import theme from "../../../../theme";

interface IMediaContainer {
  justifyContent?: string;
}

export const LeagueActionButtons = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  border: 1px solid ${theme.colors.purplePrimary};
  background: ${theme.colors.lightAliceBlue};
  color: ${theme.colors.purplePrimary};
  border-color: ${theme.colors.purplePrimary};
`;

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
`;

export const MediaResponsivenessContainer = styled.div<IMediaContainer>`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  }};
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 770px) and (min-width: 490px) {
    font-size: 20px;
  }
`;
