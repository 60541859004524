import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateLeagueForm from "./createLeagueForm";
import ShareLeagueForm from "./shareLeagueForm";
import {
  createLeague,
  resetLeagueCreationData
} from "../../../../store/features/friendsLeagueSlice";
import { RootState } from "../../../../store/store";
import LeagueSharedConfirmation from "./leagueSharedConfirmation";
import LeagueModalbase from "../modals/leagueModalBase";
import CloseIcon from "../../../composites/close-icon/closeIcon";

interface ICreateLeagueModal {
  stateUpdaterCallback: () => void;
  codeToshare?: string;
}

const CreateLeagueModal = ({
  stateUpdaterCallback,
  codeToshare
}: ICreateLeagueModal) => {
  const dispatch = useDispatch();

  const { punterId } = useSelector((state: RootState) => state.user);

  const { newLeagueCreationData } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const [toShowCreateLeagueForm, setToShowCreateLeagueForm] =
    useState(!codeToshare);
  const [toShowShareLeagueForm, setToShowShareLeagueForm] =
    useState(!!codeToshare);
  const [toShowShareConfirmation, setToShowShareConfirmation] = useState(false);
  const [toDisableLaunchLeague, setToDisableLaunchLeague] = useState(false);

  const newLeagueCode = useRef<string | null>(null);

  const onLanuchLeagueClick = (val: string) => {
    setToDisableLaunchLeague(true);
    dispatch(createLeague({ punterId, leagueName: val }));
  };

  const onContinue = () => {
    setToShowCreateLeagueForm(false);
    setToShowShareLeagueForm(false);
    setToShowShareConfirmation(true);
  };

  const onCancel = () => {
    stateUpdaterCallback();
  };

  const getCode = () => {
    return codeToshare ?? newLeagueCode.current!;
  };

  useEffect(() => {
    if (newLeagueCreationData) {
      const {
        requestedLeagueToCreate,
        leagueCreatedJustNow,
        errorInLeagueCreation,
        createdLeagueCode
      } = newLeagueCreationData;

      if (errorInLeagueCreation) {
        setToDisableLaunchLeague(false);
        dispatch(resetLeagueCreationData());
      } else if (requestedLeagueToCreate === leagueCreatedJustNow) {
        newLeagueCode.current = createdLeagueCode;

        setTimeout(() => {
          setToShowCreateLeagueForm(false);
          setToShowShareLeagueForm(true);
        }, 500);

        dispatch(resetLeagueCreationData());
      }
    }
  }, [newLeagueCreationData]);

  return (
    <LeagueModalbase closureCallback={stateUpdaterCallback}>
      <>
        <CloseIcon onClick={stateUpdaterCallback} />
        {toShowCreateLeagueForm && (
          <CreateLeagueForm
            callbackForLaunchLeague={onLanuchLeagueClick}
            toDisable={toDisableLaunchLeague}
          />
        )}

        {toShowShareLeagueForm && (
          <ShareLeagueForm
            leagueCode={getCode()}
            link={`${window.location.href}?CODE=${getCode()}`}
            continueCallback={onContinue}
            cancelCallback={onCancel}
          />
        )}
        {toShowShareConfirmation && (
          <LeagueSharedConfirmation backToHomeCallback={onCancel} />
        )}
      </>
    </LeagueModalbase>
  );
};

export default CreateLeagueModal;
