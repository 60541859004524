import styled from "styled-components";

export interface IButton {
  text?: string;
  onClick?: () => void;
  backgroundcolor?: string;
  gradient?: { colors: string[]; direction: string };
  borderWidth?: number | string;
  width?: number | string;
  height?: number | string;
  fontcolor?: string;
  borderradius?: string;
  marginleft?: string;
  marginright?: string;
  margintop?: string;
  marginbottom?: string;
  margin?: string;
  style?: object;
  border?: string;
  padding?: string;
  enabled?: boolean;
  fontWeight?: string;
  fontSize?: string;
  disabled?: boolean;
  bordercolor?: string;
  background?: string;
  minWidth?: string;
  maxWidth?: string;
  borderbottom?: string;
  hasNoInteraction?: boolean;
}

const getGradient = ({
  colors
}: {
  colors: string[];
  direction: string;
}): string => {
  if (!colors?.length) return "";

  return `linear-gradient(${colors.toString()})`;
};

const getCursor = ({
  disabled,
  hasNoInteraction
}: {
  disabled?: boolean;
  hasNoInteraction?: boolean;
}) => {
  if (disabled) return "not-allowed";
  if (hasNoInteraction) return "default";

  return "pointer";
};

export const StyledButton = styled.button<IButton>`
  background-image: ${(props) =>
    props.gradient ? getGradient(props.gradient) : "none"};
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "none"};
  color: ${(props) => props.fontcolor ?? props.theme.colors.primary};
  padding: ${(props) => props.padding ?? "5px"};
  border-radius: ${(props) => props.borderradius ?? "5px"};
  outline: 0;
  letter-spacing: 1px;
  line-height: 1;
  margin: ${(props) => props.margin ?? "0 auto"};
  margin-left: ${(props) =>
    props.marginleft ?? (props.margin ? props.margin : "0px")};
  margin-right: ${(props) =>
    props.marginright ?? (props.margin ? props.margin : "0px")};
  margin-top: ${(props) =>
    props.margintop ?? (props.margin ? props.margin : "0px")};
  margin-bottom: ${(props) =>
    props.marginbottom ?? (props.margin ? props.margin : "0px")};
  cursor: ${({ disabled, hasNoInteraction }) =>
    getCursor({ disabled, hasNoInteraction })};
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "2rem"};
  border-width: ${(props) => props.borderWidth ?? 0}px;
  border-style: solid;
  border-color: ${(props) => props.bordercolor ?? "black"};
  font-size: ${(props) => props.fontSize ?? "inherit"};
  font-weight: ${(props) => props.fontWeight ?? "bold"};
  border: ${(props) => props.border};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  font-family: Poppins;
  background: ${(props) => props.background};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  border-bottom: ${(props) => props.borderbottom};
`;

const Button = (props: IButton) => {
  const { onClick, text } = props;
  return (
    <StyledButton onClick={onClick} {...props}>
      {text}
    </StyledButton>
  );
};

export default Button;
