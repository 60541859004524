import styled from "styled-components";

export interface IIsScratched {
  isScratched: boolean;
}

export const SaddleNumberLabel = styled.label`
  margin-left: 4px;
  background:;
  width: 10%;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1;
  cursor: inherit;
`;

export const HorseNameLabel = styled.label<IIsScratched>`
  margin-left: 2px;
  text-align: left;
  width: 88%;
  font-weight: bold;
  background:;
  line-height: 1;
  word-wrap: break-word;
  text-decoration: ${(props) =>
    props.isScratched ? "red line-through" : "none"};
  cursor: inherit;
`;

export const HorseOddsLabel = styled.label`
  margin-left: 4px;
  background:;
  width: 10%;
  font-size: 0.6rem;
  font-weight: bold;
  color: blue;
  cursor: inherit;
`;

export const TotalSelectedLabel = styled.label`
  margin-left: 0px;
  background:;
  width: 30%;
  font-size: 0.6rem;
  font-weight: bold;
  color: green;
  cursor: inherit;
`;
