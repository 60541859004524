import { styled } from "styled-components";
import Container from "../../../../bricks/container";
import theme from "../../../../../theme";

export const SelectedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

export const IndexText = styled.p`
  color: black;
  font-size: ${theme.font.size.m};
  margin: 0px 10px 0px 6px;
`;

export const SaddleNumber = styled(Container)`
  height: 100%;
  width: 2em;
  margin-right: 10px;
`;

export const HorseNameText = styled.p`
  color: black;
  font-size: ${theme.font.size.m};
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
`;

export const OddsText = styled.b`
  color: black;
  font-size: ${theme.font.size.s};
  margin-right: 15px;
`;
