import styled from "styled-components";
import { GetDefaultBackgroundColor } from "./selectionsUtil";
import theme from "../../../../../../theme";
import GetImage from "../../../common/saddleIconUtil";

interface IHorsenumber {
  horseNumber: number;
}

interface IHorsePositionDiv {
  background: string;
}

const HorsePositionDiv = styled.div<IHorsePositionDiv>`
  position: relative;
  border-radius: 5px;
  background: ${(props) => props.background};
  display: grid;
  place-content: center;
  width: 63px;
  height: 63px;
  box-shadow: 2px 2px 2px ${theme.colors.cardShadow};
`;

const HorsePosition = ({ horseNumber }: IHorsenumber) => (
  <HorsePositionDiv background={GetDefaultBackgroundColor(horseNumber)}>
    <img src={GetImage[horseNumber]} alt={`saddle${horseNumber}`} />
  </HorsePositionDiv>
);

export default HorsePosition;
