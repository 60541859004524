import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getPunterLeagues } from "../../../../store/features/friendsLeagueSlice";

const UseRefreshLeagues = () => {
  const dispatch = useDispatch();
  const { punterId } = useSelector((state: RootState) => state.user);

  const refreshLeaguesList = () => {
    if (punterId) {
      dispatch(getPunterLeagues({ punterId }));
    }
  };
  return { refreshLeaguesList };
};

export default UseRefreshLeagues;
