import { useEffect, useState, useRef } from "react";

interface IUseCountDown {
  startCountDown: () => void;
  stopCountDown: () => void;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  resetWithNewTime: (newTime: number) => void;
}

const DAYS_FACTOR: number = 86400000;
const HOURS_FACTOR: number = 3600000;
const MINUTES_FACTOR: number = 60000;

const getReturnValues = (countDown: number) => {
  let days = Math.floor(countDown / DAYS_FACTOR);
  let hours = Math.floor((countDown % DAYS_FACTOR) / HOURS_FACTOR);
  let minutes = Math.floor((countDown % HOURS_FACTOR) / MINUTES_FACTOR);
  let seconds = Math.floor((countDown % MINUTES_FACTOR) / 1000);

  if (days < 0) days = 0;
  if (hours < 0) hours = 0;
  if (minutes < 0) minutes = 0;
  if (seconds < 0) seconds = 0;

  return { days, hours, minutes, seconds };
};

const useCountdown = (
  targetDate: number | string,
  autoStart: boolean = false
): IUseCountDown => {
  const newDate = useRef<number>(new Date(targetDate).getTime());

  const interval = useRef<number | ReturnType<typeof setInterval>>(-1);

  const [countDown, setCountDown] = useState(newDate.current - Date.now());

  const startCountDown = () => {
    setCountDown(newDate.current - Date.now());

    interval.current = setInterval(() => {
      setCountDown(newDate.current - Date.now());
    }, 1000);
  };

  const stopCountDown = () => {
    clearInterval(interval.current);
    interval.current = -1;
    newDate.current = -1;
  };

  const resetWithNewTime = (newTime: number) => {
    newDate.current = new Date(newTime).getTime();
  };

  useEffect(() => {
    if (autoStart) startCountDown();

    return () => {
      stopCountDown();
    };
  }, []);

  return {
    startCountDown,
    stopCountDown,
    resetWithNewTime,
    ...getReturnValues(countDown)
  };
};

export default useCountdown;
