import { useContext } from "react";
import { styled } from "styled-components";
import { getIntlFormat } from "../../utils/currencyFormatUtils";
import Container from "../bricks/container";
import FlexContainer from "../bricks/flexContainer";
import CountdownTimer from "../composites/CountdownTimer";

import { MediaQueriesContext } from "../../contexts/mediaQueriesContext";

const PossibleDTContainer = styled(Container)`
  @media screen and (min-width: 769px) and (max-width: 851px) {
    font-size: 1vw;
  }
  @media screen and (min-width: 852px) {
    font-size: 0.9vw;
  }
`;

interface IPuntersJackpot {
  amount: number;
  color: string;
  showSimpleView?: boolean;
  winner?: number | string;
  races?: number | string;
  totalPlayers?: number | string;
  jackpotEndDate: number | null;
}

const PuntersJackpot = ({
  amount,
  color,
  showSimpleView,
  winner,
  races,
  totalPlayers,
  jackpotEndDate
}: IPuntersJackpot) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <>
      {isDesktop && !showSimpleView && (
        <>
          <FlexContainer
            width="100%"
            justifycontent="space-between"
            bgcolor="none">
            <Container
              id="UsethequickTextContainer"
              width="20%"
              height="inherit"
              bgcolor="white"
              border={`1px solid ${color}`}
              borderradius="6px"
              boxsizing="true"
              color="#000"
              fontSize="1.1vw"
              padding="7px">
              "Use the quick pick button or select one horse from EACH RACE and
              click submit to make your picks."
            </Container>

            <FlexContainer
              bgcolor={color}
              alignitems="center"
              columngap="5%"
              borderradius="6px"
              width="45%"
              height="inherit"
              padding="8px"
              color="white"
              boxshadow="2px 2px 10px #a2a2c3">
              <FlexContainer
                direction="column"
                width="40%"
                height="max-content">
                <Container
                  lineheight="1"
                  fontSize="2vw"
                  fontWeight="bold"
                  textalign="right">
                  Punters Jackpot{" "}
                </Container>
                <FlexContainer
                  direction="column"
                  justifycontent="flex-end"
                  textalign="right"
                  paddingtop="6px">
                  <Container
                    fontSize="1.5vw"
                    lineheight="1"
                    textalign="right">{`${getIntlFormat(
                    amount / 2
                  )} + ${getIntlFormat(amount / 2)} `}</Container>
                  <Container fontSize="0.4rem" textalign="right">
                    *terms & conditions apply
                  </Container>
                </FlexContainer>
              </FlexContainer>

              <Container
                textalign="left"
                fontSize="4vw"
                fontWeight="bold"
                lineheight="normal">
                {getIntlFormat(amount)}
              </Container>
            </FlexContainer>

            <FlexContainer
              direction="column"
              width="26%"
              border={`2px solid ${color}`}
              bgcolor="white"
              borderradius="8px"
              padding="0px"
              height="inherit">
              <FlexContainer
                width="98%"
                height="50%"
                justifycontent="flex-end"
                alignitems="center"
                padding="0px"
                columngap="5px"
                bordertopleftradius="6px"
                borderbottomrightradius="0px"
                margintop="0px">
                <PossibleDTContainer
                  color="black"
                  width="65%"
                  fontWeight="bold"
                  textalign="right"
                  paddingleft="10px">
                  {" "}
                  Possible Punters Jackpot Winners :
                </PossibleDTContainer>

                <Container
                  bgcolor={color}
                  borderradius="4px"
                  width="30%"
                  lineheight="1"
                  padding="3px 0"
                  color="white"
                  fontSize="1.6vw"
                  fontWeight="bold">{`${races}/${races}-${
                  winner ?? 0
                }`}</Container>
              </FlexContainer>
              <FlexContainer
                width="98%"
                height="50%"
                justifycontent="flex-end"
                alignitems="center"
                padding="0px"
                columngap="5px"
                borderbottomleftradius="6px"
                borderbottomrightradius="0px"
                margintop="0px">
                <PossibleDTContainer
                  color="black"
                  width="65%"
                  fontWeight="bold"
                  textalign="right"
                  paddingleft="10px">
                  {" "}
                  Total Players :
                </PossibleDTContainer>

                <Container
                  bgcolor={color}
                  borderradius="4px"
                  width="30%"
                  lineheight="1"
                  padding="3px 0"
                  color="white"
                  fontSize="1.6vw"
                  fontWeight="bold">
                  {totalPlayers}
                </Container>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <Container margintop="1vh" marginbottom="1vh">
            <CountdownTimer
              width="99%"
              borderColor={color}
              fontColor="black"
              fontSize="3vw"
              targetDate={jackpotEndDate}
            />
          </Container>
        </>
      )}

      {!isDesktop && (
        <>
          <FlexContainer
            bgcolor={color}
            alignitems="center"
            columngap="7px"
            borderradius="6px"
            width="97%"
            height="fit-content"
            padding="8px"
            color="white"
            boxshadow="2px 2px 10px #a2a2c3">
            <FlexContainer direction="column" width="40%" height="fit-content">
              <Container
                lineheight="1"
                fontSize="3.5vw"
                fontWeight="bold"
                textalign="right">
                Punters Jackpot
              </Container>
              <FlexContainer
                direction="column"
                justifycontent="flex-end"
                textalign="right"
                paddingtop="6px">
                <Container
                  fontSize="2.7vw"
                  lineheight="1"
                  textalign="right">{`${getIntlFormat(
                  amount / 2
                )} + ${getIntlFormat(amount / 2)} `}</Container>
                <Container fontSize="0.4rem" textalign="right">
                  *terms & conditions apply
                </Container>
              </FlexContainer>
            </FlexContainer>

            <Container
              textalign="left"
              fontSize="9vw"
              fontWeight="bold"
              lineheight="normal">
              {getIntlFormat(amount)}
            </Container>
          </FlexContainer>
          <CountdownTimer
            width="96%"
            borderColor={color}
            fontColor="black"
            fontSize="4vw"
            targetDate={jackpotEndDate}
          />
        </>
      )}

      {isDesktop && showSimpleView && (
        <>
          <FlexContainer
            direction="column"
            width="100%"
            rowgap="8px"
            height="fit-content"
            bgcolor={color}
            alignitems="center"
            color="#fff"
            borderradius="6px"
            paddingtop="10px"
            paddingbottom="10px">
            <Container
              lineheight="1"
              fontSize="1vw"
              fontWeight="bold"
              color="white"
              textalign="center">
              Punters Jackpot
            </Container>
            <Container
              width="90%"
              textalign="center"
              fontSize="4vw"
              fontWeight="bold"
              lineheight="normal">
              {getIntlFormat(amount)}
            </Container>

            <Container
              fontSize="1vw"
              lineheight="1"
              textalign="center">{`${getIntlFormat(
              amount / 2
            )} + ${getIntlFormat(amount / 2)} `}</Container>

            <Container fontSize="0.5rem" textalign="center">
              *terms & conditions apply
            </Container>
          </FlexContainer>

          <Container margintop="1vh" marginbottom="1vh">
            <CountdownTimer
              width="99%"
              borderColor={color}
              fontColor="black"
              fontSize="3vw"
              targetDate={jackpotEndDate}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default PuntersJackpot;
