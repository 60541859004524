import styled from "styled-components";
import theme from "../../../../../../theme";

interface IResultsViewProps {
  raceCourseName?: string;
  shouldOpen: boolean;
}

export const ViewContainer = styled.div<IResultsViewProps>`
  display: flex;
  position: relative;
  align-items: center;
  font-size: ${theme.font.size.sm};
  font-weight: bolder;
  color: ${theme.colors.purplePrimary};
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-left: ${(props) =>
    props.raceCourseName
      ? `2px solid ${theme.raceColors(props.raceCourseName)}`
      : "none"};
  border-radius: ${(props) => (props.shouldOpen ? "0 8px 0 0" : "0 8px 8px 0")};
  width: 15%;
  height: 46px;
  cursor: pointer;
`;

export const ViewText = styled.div<IResultsViewProps>`
  align-content: center;
  width: 100%;
`;

export const ViewArrow = styled.img<IResultsViewProps>`
  width: 20px;
  height: 20px;
  padding-top: ${(props) => (props.shouldOpen ? "3px" : "2px")};
  transform: ${(props) => (props.shouldOpen ? "rotate(180deg)" : "none")};
`;
