import { LinkButtonHolder, ButtonLogo, ButtonText } from "./linkButton.style";

interface ILinkButton {
  logo: string;
  text: string;
  isGallopTv?: boolean;
  onClick: () => void;
}

const LinkButton = ({ logo, text, isGallopTv, onClick }: ILinkButton) => {
  return (
    <LinkButtonHolder onClick={onClick}>
      <ButtonLogo isGallopTv={isGallopTv} src={logo} />
      <ButtonText>{text}</ButtonText>
    </LinkButtonHolder>
  );
};

export default LinkButton;
