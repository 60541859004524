import ReactLoading from "react-loading";
import theme from "../../theme";
import Container from "./container";

interface ISpinner {
  width?: string;
  height?: string;
}

const Spinner = ({ width, height }: ISpinner) => {
  return (
    <Container
      position="absolute"
      bgcolor="transparent"
      width="100%"
      height="100%"
      display="grid"
      placecontent="center">
      <ReactLoading
        type="spokes"
        color={theme.colors.aquaSecondary}
        height={height ?? "100px"}
        width={width ?? "100px"}
      />
    </Container>
  );
};

export default Spinner;
