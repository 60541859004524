import PurpleArrow from "@assets/arrow-down-purple.svg";
import Lock from "@assets/lock-league-leaderboard.svg";
import {
  InfoText,
  LockContainerWeb,
  MainContainerWeb,
  MiniContainerDropdownWeb,
  MiniContainerTextWeb,
  MiniContainerWeb,
  MiniDropdownWeb
} from "./leaderboardMiniCardsWeb.style";

export interface ILeaderboardInfo {
  winners: number;
  secondPlaces: number;
  thirdPlaces: number;
  openSelections: boolean;
  isRestricted: boolean;
}

const MiniCardWeb = ({
  winners,
  secondPlaces,
  thirdPlaces,
  openSelections,
  isRestricted
}: ILeaderboardInfo) => {
  return (
    <MainContainerWeb hasNoInteraction={isRestricted}>
      <InfoText>Winners:</InfoText>
      <MiniContainerWeb>
        <MiniContainerTextWeb>{winners}</MiniContainerTextWeb>
      </MiniContainerWeb>
      <InfoText>2nd:</InfoText>
      <MiniContainerWeb>
        <MiniContainerTextWeb>{secondPlaces}</MiniContainerTextWeb>
      </MiniContainerWeb>
      <InfoText>3rd:</InfoText>
      <MiniContainerWeb>
        <MiniContainerTextWeb>{thirdPlaces}</MiniContainerTextWeb>
      </MiniContainerWeb>
      <MiniDropdownWeb isDropdown openSelections={openSelections}>
        {isRestricted ? (
          <LockContainerWeb openSelections={openSelections} src={Lock} />
        ) : (
          <MiniContainerDropdownWeb
            openSelections={openSelections}
            src={PurpleArrow}
          />
        )}
      </MiniDropdownWeb>
    </MainContainerWeb>
  );
};

export default MiniCardWeb;
