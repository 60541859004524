import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../../../theme";
import hasBadWords from "../../../../utils/profanity";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import LabeledInput from "../../labled-input/labeledInput";
import { RootState } from "../../../../store/store";
import LeagueFormBase from "../modals/leagueFormBase";
import ConfettiAnimation from "../../../../utils/confettiDisplay";
import {
  hasNoLetter,
  hasSpecialChars
} from "../../../../utils/validationUtils";
import { CloseIconDiv } from "../../../composites/close-icon/closeIcon.style";

const NameList = styled.ul`
  list-style-type: disc;
  font-size: ${theme.font.size.s};
  text-align: left;
  padding-left: 10px;
  color: grey;
`;

interface ICreateLeagueForm {
  callbackForLaunchLeague: (value: string) => void;
  toDisable: boolean;
}

const getMessage = (val: string): string => {
  let msg = "";

  if (hasBadWords(val)) return "League name contains profanity";

  if (hasSpecialChars(val)) {
    return "League name can not contain special characters";
  }

  if (val.length > 5 && val.length < 26) {
    if (hasNoLetter(val)) {
      msg = "League name must contain at least 1 letter.";
    }
  } else if (val.length >= 26) {
    msg = "League name cannot be longer than 25 characters";
  } else {
    msg = "Not enough chars";
  }

  return msg;
};

const CreateLeagueForm = ({
  callbackForLaunchLeague,
  toDisable
}: ICreateLeagueForm) => {
  const { newLeagueCreationData } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const [inputErrorMessage, setInputErrorMessage] = useState<string>("");
  const [leagueName, setLeagueName] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [toDisableInput, setToDisableInput] = useState<boolean>(false);

  const onNameInput = (val: string) => {
    const newErrorMessage = getMessage(val);

    if (newErrorMessage.length > 0 && val.length > 0) {
      setInputErrorMessage(newErrorMessage);
      if (leagueName && leagueName != null) setLeagueName(null);
    } else if (newErrorMessage.length === 0 && inputErrorMessage.length > 0) {
      setInputErrorMessage("");

      if (val.length) setLeagueName(val);
    } else {
      setLeagueName(val ?? null);
    }
  };

  const onLaunchLeagueButtonClick = () => {
    setToDisableInput(true);
    callbackForLaunchLeague(leagueName!);
  };

  useEffect(() => {
    if (!toDisable) {
      setToDisableInput(false);
    }
  }, [toDisable]);

  useEffect(() => {
    if (newLeagueCreationData) {
      const {
        requestedLeagueToCreate,
        leagueCreatedJustNow,
        errorInLeagueCreation
      } = newLeagueCreationData;

      if (errorInLeagueCreation) {
        setInputErrorMessage(errorInLeagueCreation);
      } else if (leagueName === leagueCreatedJustNow) {
        setSuccessMessage(
          `League ${requestedLeagueToCreate} was successfully created.`
        );
      }
    }
  }, [newLeagueCreationData]);

  return (
    <LeagueFormBase text="Create A League">
      <>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          color={theme.colors.grey}
          fontStyle="italic">
          Please enter the name to launch your new league.
        </Container>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          margintop="10px">
          Name your league
        </Container>

        <LabeledInput
          width="92%"
          height="2.5rem"
          isPassword={false}
          align="left"
          placeholder="Enter your league name"
          valueUpdater={onNameInput}
          disable={toDisableInput}
        />

        {inputErrorMessage && (
          <Container textalign="left" fontSize="0.7rem" color="red">
            {inputErrorMessage}
          </Container>
        )}
        {successMessage && (
          <>
            <Container
              textalign="left"
              fontSize="0.8rem"
              color="green"
              fontWeight="bold">
              {successMessage}
            </Container>
            <Container>
              <ConfettiAnimation />
            </Container>
          </>
        )}

        <Container>
          <NameList as="ul">
            <li>
              <em>Minimum of 6 characters allowed.</em>
            </li>
            <li>
              <em>Maximum of 25 characters allowed.</em>
            </li>
            <li>
              <em>Cannot contain special characters.</em>
            </li>
            <li>
              <em>Cannot contain profanity.</em>
            </li>
          </NameList>
        </Container>
        <CloseIconDiv>
          <Button
            margintop="20px"
            width="50%"
            background={`linear-gradient(
                ${theme.colors.purpleSecondary},
                ${theme.colors.purplePrimary}
                )`}
            text="Launch League"
            fontSize="0.7rem"
            disabled={!leagueName || toDisable}
            onClick={onLaunchLeagueButtonClick}
          />
        </CloseIconDiv>
      </>
    </LeagueFormBase>
  );
};

export default CreateLeagueForm;
