import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LuckyPickContext } from "../../context/luckyPickContext";
import FlexContainer from "../../../../bricks/flexContainer";
import Container from "../../../../bricks/container";
import theme from "../../../../../theme";
import { BET_TYPES_DISPLAY_NAMES } from "../../constants/luckyPickConstants";
import { ILuckyPickBetType } from "../../store/luckyPickReducer";
import { BetPlacementPayloadData } from "../../../../../store/features/betslipSlice";
import { RootState } from "../../../../../store/store";
import {
  getPotentialPayout,
  getStake,
  isInvalidBet
} from "../../../../../utils/betConfirmationUtils";
import {
  getBetSlipSummaryFormat,
  getFormattedBet
} from "../../utils/luckypickUtils";
import BetSlipSummary from "../../../betslipNew/components/betSlipSummary/betSlipSummary";
import BetSlipTotals from "../../../betslipNew/components/betSlipTotals/betSlipTotals";
import { LP_ACTIONS } from "../../store/luckyPickActions";
import BetSlipSubmissionConfirm from "../../../betslipNew/components/betSlipSubmission/betSlipSubmissionConfirm";
import { ButtonStyle } from "../../../betslipNew/components/betSlipSubmission/betSlipSubmission.style";
import {
  TermsLabel,
  LimitLabel
} from "../../../betslipNew/components/betSlipView.styled";

type TotalValues = {
  numBets: number;
  stake: number;
  payout: number;
};

const Summary = ({
  onStrikeBet
}: {
  onStrikeBet: (bet: BetPlacementPayloadData) => void;
}) => {
  const { userName, balance } = useSelector((state: RootState) => state.user);
  const { lpState, lpDispatch } = useContext(LuckyPickContext);
  const { betTypeWinDouble, betTypePlaceTreble, betTypeWinPlaceDouble } =
    lpState;
  const [totalValues, setTotalValues] = useState<TotalValues | null>(null);
  const validBetTypes = useRef<string[]>([]);
  const hasStruckBet = useRef(false);

  const handlePlaceBet = () => {
    window.scrollTo({ top: 0 });
    hasStruckBet.current = true;

    const dataForBetFormation: {
      type: string | null;
      data: ILuckyPickBetType | null;
    }[] = validBetTypes.current.map((item) => {
      if (item === BET_TYPES_DISPLAY_NAMES.winDouble) {
        return { type: item, data: betTypeWinDouble };
      }
      if (item === BET_TYPES_DISPLAY_NAMES.placeTreble) {
        return { type: item, data: betTypePlaceTreble };
      }
      if (item === BET_TYPES_DISPLAY_NAMES.winPlaceDouble) {
        return { type: item, data: betTypeWinPlaceDouble };
      }

      return { type: null, data: null };
    });

    const formattedBetApiPayload = getFormattedBet(
      dataForBetFormation,
      +userName
    );

    onStrikeBet(formattedBetApiPayload);
  };

  const handleCancelClick = () => {
    lpState.cancelCallback();
  };

  const handleBackClick = () => {
    lpDispatch({ type: LP_ACTIONS.SHOW_SUMMARY, toShow: false });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });

    validBetTypes.current = Object.values(BET_TYPES_DISPLAY_NAMES).map(
      (name) => name
    );

    const final: TotalValues = { numBets: 0, stake: 0, payout: 0 };

    const payout =
      getPotentialPayout(betTypeWinDouble) +
      getPotentialPayout(betTypePlaceTreble) +
      getPotentialPayout(betTypeWinPlaceDouble);

    const stake =
      getStake(betTypeWinDouble) +
      getStake(betTypePlaceTreble) +
      getStake(betTypeWinPlaceDouble);

    if (isInvalidBet(betTypeWinDouble))
      validBetTypes.current.splice(
        validBetTypes.current.indexOf(BET_TYPES_DISPLAY_NAMES.winDouble),
        1
      );
    if (isInvalidBet(betTypePlaceTreble))
      validBetTypes.current.splice(
        validBetTypes.current.indexOf(BET_TYPES_DISPLAY_NAMES.placeTreble),
        1
      );
    if (isInvalidBet(betTypeWinPlaceDouble))
      validBetTypes.current.splice(
        validBetTypes.current.indexOf(BET_TYPES_DISPLAY_NAMES.winPlaceDouble),
        1
      );

    final.numBets = validBetTypes.current.length;
    final.payout = payout;
    final.stake = stake;

    setTotalValues(final);
  }, []);

  const summaryData = getBetSlipSummaryFormat(
    betTypeWinDouble,
    betTypePlaceTreble,
    betTypeWinPlaceDouble
  );

  return lpState ? (
    <FlexContainer direction="column" alignitems="center" width="100%">
      <Container
        margintop="10px"
        fontWeight="bold"
        color={theme.colors.purplePrimary}>
        Please Confirm Bet
      </Container>

      {totalValues?.numBets && (
        <BetSlipTotals
          stake={totalValues.stake}
          payout={totalValues.payout + totalValues.stake}
          bets={totalValues.numBets}
          hasSP={false}
          shouldShowAllTotals
          isLuckyPick
        />
      )}

      {totalValues && (
        <BetSlipSubmissionConfirm
          balance={balance?.balanceAvailable ?? 0}
          currentStake={totalValues.stake}
          placeBetCallback={handleBackClick}
          strikeBet={handlePlaceBet}
          isLuckyPick
        />
      )}

      <BetSlipSummary data={summaryData} />

      <ButtonStyle margintop="10px" width="150px" onClick={handleCancelClick}>
        Cancel Lucky Pick
      </ButtonStyle>
      <br />
      <TermsLabel isLuckyPick>
        Subject to maximum winnings per general T's & C's ( section 2.14 )
      </TermsLabel>
      <br />
      <LimitLabel isLuckyPick> Limits apply </LimitLabel>
    </FlexContainer>
  ) : null;
};

export default Summary;
