import { useSelector } from "react-redux";
import Container from "../../../../bricks/container";
import { ButtonStyle, ButtonsDiv } from "./betSlipSubmission.style";
import { RootState } from "../../../../../store/store";
import openLink from "../../../../../utils/pageUtils";
import BetSlipSubmissionBalance from "./betSlipSubmissionBalance";

interface IBetSlipSubmissionPlace {
  currentStake: number;
  placeBetCallback: () => void;
  totalActiveBets: number;
}

const BetSlipSubmissionPlace = ({
  totalActiveBets,
  currentStake,
  placeBetCallback
}: IBetSlipSubmissionPlace) => {
  const config = useSelector((state: RootState) => state.config.data);

  const topup = () => {
    openLink(`${config.redeemVoucherUrl}`);
  };

  return (
    <Container>
      <BetSlipSubmissionBalance currentStake={currentStake} />
      <ButtonsDiv flexDirection="column">
        <ButtonStyle
          width="200px"
          onClick={placeBetCallback}
          disabled={totalActiveBets === 0}>
          Place bet
        </ButtonStyle>
        <ButtonStyle width="150px" onClick={topup}>
          Top Up Account
        </ButtonStyle>
      </ButtonsDiv>
    </Container>
  );
};

export default BetSlipSubmissionPlace;
