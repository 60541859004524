import { useCallback, useEffect, useRef, useState } from "react";
import { IBetslipBetType } from "../../../../store/features/betslipSlice";
import { BET_TYPES } from "../constants/luckyPickConstants";

const USE_ABSOLUTE_RANDOM: boolean = true;

const useRandomHorses = (
  winBets: IBetslipBetType[],
  placeBets: IBetslipBetType[],
  winDoubleCombos: IBetslipBetType[][],
  placeTrebleCombos: IBetslipBetType[][],
  winPlaceDoubleCombos: IBetslipBetType[][]
): {
  currentWinDoubleCombo: IBetslipBetType[];
  currentPlaceTrebleCombo: IBetslipBetType[];
  currentWinPlaceDoubleCombo: IBetslipBetType[];
  generateWinDouble: () => void;
  generatePlaceTreble: () => void;
  generateWinPlaceDouble: () => void;
  refreshWinDouble: (toReplace: string) => void;
  refreshPlaceTreble: (toReplace: string) => void;
  refreshWinPlaceDouble: (toReplace: string) => void;
  switchWinPlaceDouble: () => void;
  handleSingleHorseSelectedFromOutside: (
    betType: string,
    toReplace: string,
    newHorseName: string
  ) => void;
} => {
  const [winDoubleIteratorIndex, setWinDoubleIteratorIndex] =
    useState<number>(0);
  const [placeTrebleIteratorIndex, setPlaceTrebleIteratorIndex] =
    useState<number>(0);
  const [winPlaceDoubleIteratorIndex, setWinPlaceDoubleIteratorIndex] =
    useState<number>(0);

  const winDoubleIteratorIndexRef = useRef(winDoubleIteratorIndex);
  const placeTrebleIteratorIndexRef = useRef(placeTrebleIteratorIndex);
  const winPlaceDoubleIteratorIndexRef = useRef(winPlaceDoubleIteratorIndex);

  const [currentWinDoubleCombo, setCurrentWinDoubleCombo] = useState<
    IBetslipBetType[]
  >([]);
  const [currentPlaceTrebleCombo, setCurrentPlaceTrebleCombo] = useState<
    IBetslipBetType[]
  >([]);
  const [currentWinPlaceDoubleCombo, setCurrentWinPlaceDoubleCombo] = useState<
    IBetslipBetType[]
  >([]);

  const currentWinDoubleComboRef = useRef(currentWinDoubleCombo);
  const currentPlaceTrebleComboRef = useRef(currentPlaceTrebleCombo);
  const currentWinPlaceDoubleComboRef = useRef(currentWinPlaceDoubleCombo);

  const horsesInCurrentWinDoubleRef = useRef<string[]>([]);
  const horsesInCurrentPlaceTrebleRef = useRef<string[]>([]);
  const horsesInCurrentWinPlaceDoubleRef = useRef<string[]>([]);

  const winBetsHorsesForWinDoubleNotUsedSoFar = useRef<string[]>([]);
  const placeBetHorsesForPlaceTrebleNotUsedSoFar = useRef<string[]>([]);

  const winbetsHorsesForWinPlaceDoubleNotUsedSoFar = useRef<string[]>([]);
  const placeBetHorsesForWinPlaceDoubleNotUsedSoFar = useRef<string[]>([]);

  const winDoublesUsedIndices = useRef<number[]>([]);
  const winDoublesRemainingIndices = useRef<number[]>([]);
  const placeTrebleUsedIndices = useRef<number[]>([]);
  const placeTrebleRemainingIndices = useRef<number[]>([]);
  const winPlaceDoubleUsedIndices = useRef<number[]>([]);
  const winPlaceDoubleRemainingIndices = useRef<number[]>([]);

  const winPlaceDoubleHasWinOnTop = useRef<boolean>(true);

  const getRandomValue = (length: number) => {
    return Math.floor(Math.random() * length);
  };

  const getWinDoubleRandomIndex = useCallback((): number => {
    let newIndex = -1;
    if (USE_ABSOLUTE_RANDOM) {
      if (
        winDoublesRemainingIndices.current.length === 0 &&
        winDoublesUsedIndices.current.length === winDoubleCombos.length
      ) {
        winDoublesRemainingIndices.current = [...winDoublesUsedIndices.current];
        winDoublesUsedIndices.current = [];
      }

      const winDoubleNewIndex = getRandomValue(
        winDoublesRemainingIndices.current.length
      );
      winDoublesUsedIndices.current = winDoublesUsedIndices.current.concat(
        winDoublesRemainingIndices.current.splice(winDoubleNewIndex, 1)
      );

      newIndex =
        winDoublesUsedIndices.current[winDoublesUsedIndices.current.length - 1];
    } else {
      newIndex = winDoubleIteratorIndexRef.current;

      newIndex = ++newIndex >= winDoubleCombos.length ? 0 : newIndex;
    }

    return newIndex;
  }, [winDoubleCombos]);

  const getPlaceTrebleRandomIndex = useCallback((): number => {
    let newIndex = -1;

    if (USE_ABSOLUTE_RANDOM) {
      if (
        placeTrebleRemainingIndices.current.length === 0 &&
        placeTrebleUsedIndices.current.length === placeTrebleCombos.length
      ) {
        placeTrebleRemainingIndices.current = [
          ...placeTrebleUsedIndices.current
        ];
        placeTrebleUsedIndices.current = [];
      }

      const winDoubleNewIndex = getRandomValue(
        placeTrebleRemainingIndices.current.length
      );
      placeTrebleUsedIndices.current = placeTrebleUsedIndices.current.concat(
        placeTrebleRemainingIndices.current.splice(winDoubleNewIndex, 1)
      );
      newIndex =
        placeTrebleUsedIndices.current[
          placeTrebleUsedIndices.current.length - 1
        ];
    } else {
      newIndex = placeTrebleIteratorIndexRef.current;
      newIndex = ++newIndex >= placeTrebleCombos.length ? 0 : newIndex;
    }

    return newIndex;
  }, [placeTrebleCombos]);

  const getWinPlaceDoubleRandomIndex = useCallback((): number => {
    let newIndex = -1;

    if (USE_ABSOLUTE_RANDOM) {
      if (
        winPlaceDoubleRemainingIndices.current.length === 0 &&
        winPlaceDoubleUsedIndices.current.length === winPlaceDoubleCombos.length
      ) {
        winPlaceDoubleRemainingIndices.current = [
          ...winPlaceDoubleUsedIndices.current
        ];
        winPlaceDoubleUsedIndices.current = [];
      }

      const winDoubleNewIndex = getRandomValue(
        winPlaceDoubleRemainingIndices.current.length
      );
      winPlaceDoubleUsedIndices.current =
        winPlaceDoubleUsedIndices.current.concat(
          winPlaceDoubleRemainingIndices.current.splice(winDoubleNewIndex, 1)
        );

      newIndex =
        winPlaceDoubleUsedIndices.current[
          winPlaceDoubleUsedIndices.current.length - 1
        ];
    } else {
      newIndex = winPlaceDoubleIteratorIndexRef.current;
      newIndex = ++newIndex >= winPlaceDoubleCombos.length ? 0 : newIndex;
    }

    return newIndex;
  }, [placeTrebleCombos]);

  useEffect(() => {
    winDoublesRemainingIndices.current = Array.from(
      { length: winDoubleCombos.length },
      (v, i) => i
    );
    placeTrebleRemainingIndices.current = Array.from(
      { length: placeTrebleCombos.length },
      (v, i) => i
    );
    winPlaceDoubleRemainingIndices.current = Array.from(
      { length: winPlaceDoubleCombos.length },
      (v, i) => i
    );

    const winBetsHorses = winBets.map((item) => item.eventDetailName);
    const placeBetsHorses = placeBets.map((item) => item.eventDetailName);

    winBetsHorsesForWinDoubleNotUsedSoFar.current = [...winBetsHorses];
    placeBetHorsesForPlaceTrebleNotUsedSoFar.current = [...placeBetsHorses];
    winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current = [...winBetsHorses];
    placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current = [...placeBetsHorses];

    setCurrentWinDoubleCombo(
      winDoubleCombos[USE_ABSOLUTE_RANDOM ? getWinDoubleRandomIndex() : 0]
    );
    setCurrentPlaceTrebleCombo(
      placeTrebleCombos[USE_ABSOLUTE_RANDOM ? getPlaceTrebleRandomIndex() : 0]
    );
    setCurrentWinPlaceDoubleCombo(
      winPlaceDoubleCombos[
        USE_ABSOLUTE_RANDOM ? getWinPlaceDoubleRandomIndex() : 0
      ]
    );
  }, []);

  /**
   *
   * @param toReplace
   */
  const refreshWinDouble = (toReplace: string) => {
    const indexToReplace = horsesInCurrentWinDoubleRef.current.findIndex(
      (item) => item === toReplace
    );

    const newHorseName =
      winBetsHorsesForWinDoubleNotUsedSoFar.current[
        getRandomValue(winBetsHorsesForWinDoubleNotUsedSoFar.current.length)
      ];

    const newHorseBetObj = winBets.find(
      (item) => item.eventDetailName === newHorseName
    );

    const newCombo = [...currentWinDoubleComboRef.current];

    newCombo[indexToReplace] = newHorseBetObj!;

    setCurrentWinDoubleCombo(newCombo);
  };
  const refreshPlaceTreble = (toReplace: string) => {
    if (
      !placeBetHorsesForPlaceTrebleNotUsedSoFar.current.length &&
      horsesInCurrentPlaceTrebleRef.current.length === 3
    ) {
      return;
    }

    const indexToReplace = horsesInCurrentPlaceTrebleRef.current.findIndex(
      (item) => item === toReplace
    );

    const newHorseName =
      placeBetHorsesForPlaceTrebleNotUsedSoFar.current[
        getRandomValue(placeBetHorsesForPlaceTrebleNotUsedSoFar.current.length)
      ];

    const newHorseBetObj = placeBets.find(
      (item) => item.eventDetailName === newHorseName
    );

    const newCombo = [...currentPlaceTrebleComboRef.current];

    newCombo[indexToReplace] = newHorseBetObj!;

    setCurrentPlaceTrebleCombo(newCombo);
  };

  const refreshWinPlaceDouble = (toReplace: string) => {
    const indexToReplace = horsesInCurrentWinPlaceDoubleRef.current.findIndex(
      (item) => item === toReplace
    );

    const topRowList = winPlaceDoubleHasWinOnTop.current
      ? winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current
      : placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current;
    const bottomRowList = winPlaceDoubleHasWinOnTop.current
      ? placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current
      : winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current;

    const newHorseName =
      indexToReplace === 0
        ? topRowList[getRandomValue(topRowList.length)]
        : bottomRowList[getRandomValue(bottomRowList.length)];

    if (!newHorseName) return;

    const resolvedTopList = winPlaceDoubleHasWinOnTop.current
      ? winBets
      : placeBets;
    const resolvedBottomList = winPlaceDoubleHasWinOnTop.current
      ? placeBets
      : winBets;

    const newHorseBetObj =
      indexToReplace === 0
        ? resolvedTopList.find((item) => item.eventDetailName === newHorseName)
        : resolvedBottomList.find(
            (item) => item.eventDetailName === newHorseName
          );

    const newCombo = [...currentWinPlaceDoubleComboRef.current];

    newCombo[indexToReplace] = newHorseBetObj!;

    setCurrentWinPlaceDoubleCombo(newCombo);
  };

  const switchWinPlaceDouble = () => {
    winPlaceDoubleHasWinOnTop.current = !winPlaceDoubleHasWinOnTop.current;

    const resolvedTopRowList = winPlaceDoubleHasWinOnTop.current
      ? winBets
      : placeBets;
    const resolvedBottomRowList = winPlaceDoubleHasWinOnTop.current
      ? placeBets
      : winBets;
    const firstNewHorseBetObj = resolvedTopRowList.find(
      (item) =>
        item.eventDetailName ===
        currentWinPlaceDoubleComboRef.current[0].eventDetailName
    );
    const secondNewHorseBetObj = resolvedBottomRowList.find(
      (item) =>
        item.eventDetailName ===
        currentWinPlaceDoubleComboRef.current[1].eventDetailName
    );

    const newCombo = [...currentWinPlaceDoubleComboRef.current];

    newCombo[0] = firstNewHorseBetObj!;
    newCombo[1] = secondNewHorseBetObj!;

    setCurrentWinPlaceDoubleCombo(newCombo);
  };

  const generateWinDouble = useCallback(() => {
    const newIndex = getWinDoubleRandomIndex();

    if (!USE_ABSOLUTE_RANDOM) setWinDoubleIteratorIndex(newIndex);

    setCurrentWinDoubleCombo(winDoubleCombos[newIndex]);
  }, [winDoubleIteratorIndex, currentWinDoubleCombo]);

  const generatePlaceTreble = useCallback(() => {
    const newIndex = getPlaceTrebleRandomIndex();
    if (!USE_ABSOLUTE_RANDOM) setPlaceTrebleIteratorIndex(newIndex);

    setCurrentPlaceTrebleCombo(placeTrebleCombos[newIndex]);
  }, [placeTrebleIteratorIndexRef, currentPlaceTrebleCombo]);

  const generateWinPlaceDouble = useCallback(() => {
    const newIndex = getWinPlaceDoubleRandomIndex();
    if (!USE_ABSOLUTE_RANDOM) setWinPlaceDoubleIteratorIndex(newIndex);

    setCurrentWinPlaceDoubleCombo(
      winPlaceDoubleHasWinOnTop.current
        ? winPlaceDoubleCombos[newIndex]
        : [...winPlaceDoubleCombos[newIndex]].reverse()
    );
  }, [winPlaceDoubleIteratorIndex, currentWinPlaceDoubleCombo]);

  const handleSingleHorseSelectedFromOutside = (
    betType: string,
    toReplace: string,
    newHorseName: string
  ) => {
    let indexToReplace: number = -1;
    let newHorseBetObj;
    let newCombo;
    if (betType === BET_TYPES.WIN_DOUBLE) {
      indexToReplace = horsesInCurrentWinDoubleRef.current.findIndex(
        (item) => item === toReplace
      );
      newHorseBetObj = winBets.find(
        (item) => item.eventDetailName === newHorseName
      );
      newCombo = [...currentWinDoubleComboRef.current];
      newCombo[indexToReplace] = newHorseBetObj!;
      setCurrentWinDoubleCombo(newCombo);
    } else if (betType === BET_TYPES.PLACE_TREBLE) {
      indexToReplace = horsesInCurrentPlaceTrebleRef.current.findIndex(
        (item) => item === toReplace
      );
      newHorseBetObj = placeBets.find(
        (item) => item.eventDetailName === newHorseName
      );
      newCombo = [...currentPlaceTrebleComboRef.current];
      newCombo[indexToReplace] = newHorseBetObj!;

      setCurrentPlaceTrebleCombo(newCombo);
    } else if (betType === BET_TYPES.WIN_PLACE_DOUBLE) {
      indexToReplace = horsesInCurrentWinPlaceDoubleRef.current.findIndex(
        (item) => item === toReplace
      );

      const resolvedTopRowList = winPlaceDoubleHasWinOnTop.current
        ? winBets
        : placeBets;
      const resolvedBottomRowList = winPlaceDoubleHasWinOnTop.current
        ? placeBets
        : winBets;

      newHorseBetObj =
        indexToReplace === 0
          ? resolvedTopRowList.find(
              (item) => item.eventDetailName === newHorseName
            )
          : resolvedBottomRowList.find(
              (item) => item.eventDetailName === newHorseName
            );
      newCombo = [...currentWinPlaceDoubleComboRef.current];
      newCombo[indexToReplace] = newHorseBetObj!;
      setCurrentWinPlaceDoubleCombo(newCombo);
    }
  };

  useEffect(() => {
    if (currentWinDoubleCombo.length > 0) {
      currentWinDoubleComboRef.current = currentWinDoubleCombo;

      horsesInCurrentWinDoubleRef.current = currentWinDoubleCombo?.map(
        (horse) => horse.eventDetailName
      );

      winBetsHorsesForWinDoubleNotUsedSoFar.current =
        winBetsHorsesForWinDoubleNotUsedSoFar.current.filter(
          (item) => !horsesInCurrentWinDoubleRef.current.includes(item)
        );

      if (winBetsHorsesForWinDoubleNotUsedSoFar.current.length === 0) {
        winBetsHorsesForWinDoubleNotUsedSoFar.current = winBets
          .map((horse) => horse.eventDetailName)
          .filter(
            (item) => !horsesInCurrentWinDoubleRef.current.includes(item)
          );
      }
    }
  }, [currentWinDoubleCombo]);

  useEffect(() => {
    winDoubleIteratorIndexRef.current = winDoubleIteratorIndex;
  }, [winDoubleIteratorIndex]);

  useEffect(() => {
    if (currentPlaceTrebleCombo?.length > 0) {
      currentPlaceTrebleComboRef.current = currentPlaceTrebleCombo;

      horsesInCurrentPlaceTrebleRef.current = currentPlaceTrebleCombo?.map(
        (horse) => horse?.eventDetailName
      );

      placeBetHorsesForPlaceTrebleNotUsedSoFar.current =
        placeBetHorsesForPlaceTrebleNotUsedSoFar.current.filter(
          (item) => !horsesInCurrentPlaceTrebleRef.current.includes(item)
        );

      if (placeBetHorsesForPlaceTrebleNotUsedSoFar.current.length === 0) {
        placeBetHorsesForPlaceTrebleNotUsedSoFar.current = placeBets
          .map((horse) => horse.eventDetailName)
          .filter(
            (item) => !horsesInCurrentPlaceTrebleRef.current.includes(item)
          );
      }
    }
  }, [currentPlaceTrebleCombo]);

  useEffect(() => {
    placeTrebleIteratorIndexRef.current = placeTrebleIteratorIndex;
  }, [placeTrebleIteratorIndex]);

  useEffect(() => {
    if (currentWinPlaceDoubleCombo?.length > 0) {
      currentWinPlaceDoubleComboRef.current = currentWinPlaceDoubleCombo;

      horsesInCurrentWinPlaceDoubleRef.current =
        currentWinPlaceDoubleCombo?.map((horse) => horse.eventDetailName);

      winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current =
        winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current.filter(
          (item) => !horsesInCurrentWinPlaceDoubleRef.current.includes(item)
        );

      placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current =
        placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current.filter(
          (item) => !horsesInCurrentWinPlaceDoubleRef.current.includes(item)
        );

      if (winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current.length === 0) {
        winbetsHorsesForWinPlaceDoubleNotUsedSoFar.current = winBets
          .map((horse) => horse.eventDetailName)
          .filter(
            (item) => !horsesInCurrentWinPlaceDoubleRef.current.includes(item)
          );
      }
      if (placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current.length === 0) {
        placeBetHorsesForWinPlaceDoubleNotUsedSoFar.current = placeBets
          .map((horse) => horse.eventDetailName)
          .filter(
            (item) => !horsesInCurrentWinPlaceDoubleRef.current.includes(item)
          );
      }
    }
  }, [currentWinPlaceDoubleCombo]);

  useEffect(() => {
    winPlaceDoubleIteratorIndexRef.current = winPlaceDoubleIteratorIndex;
  }, [winPlaceDoubleIteratorIndex]);

  return {
    currentWinDoubleCombo,
    currentPlaceTrebleCombo,
    currentWinPlaceDoubleCombo,
    generateWinDouble,
    generatePlaceTreble,
    generateWinPlaceDouble,
    refreshWinDouble,
    refreshPlaceTreble,
    refreshWinPlaceDouble,
    handleSingleHorseSelectedFromOutside,
    switchWinPlaceDouble
  };
};

export default useRandomHorses;
