export const months: Record<number, string> = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December"
};

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const FORMATS = {
  "HH:MM": "HH:MM",
  "D,DT FM FY": "Day, date Month fullyear",
  "D, DT FM": "Day, date Month",
  "SD, DT FM": "Short day, date month",
  "D, DT FM FY, HH:MM": "Day, date Month Fullyear, HH:MM",
  "DT, SM FY": "date shortMonth fullyear",
  "SD, DT FM FY": "shortDay, date Month fullyear",
  "DT,Month": "date Month"
};

const getMinutes = (date: Date): string => {
  let mins = date.getMinutes().toString();
  if (mins.length < 2) mins = `0${mins}`;
  return mins;
};

export const getFormatted = (input: string, format: string): string => {
  if (!input || input === "undefined") return "";

  let final = "";
  const dateObj = new Date(input);

  if (format === FORMATS["HH:MM"]) {
    final = `${final + dateObj.getHours()}:${getMinutes(dateObj)}`;
  } else if (format === FORMATS["D,DT FM FY"]) {
    final = `${days[dateObj.getDay()]}, ${dateObj.getDate()} ${
      months[dateObj.getMonth()]
    } ${dateObj.getFullYear()}`;
  } else if (format === FORMATS["D, DT FM"]) {
    final = `${days[dateObj.getDay()]}, ${dateObj.getDate()} ${
      months[dateObj.getMonth()]
    }`;
  } else if (format === FORMATS["SD, DT FM"]) {
    final = `${days[dateObj.getDay()].slice(0, 3)}, ${dateObj.getDate()} ${
      months[dateObj.getMonth()]
    }`;
  } else if (format === FORMATS["D, DT FM FY, HH:MM"]) {
    final = `${days[dateObj.getDay()]}, ${dateObj.getDate()} ${
      months[dateObj.getMonth()]
    } ${dateObj.getFullYear()}   ${dateObj.getHours()}:${getMinutes(dateObj)}`;
  } else if (format === FORMATS["DT, SM FY"]) {
    final =
      `${dateObj.getDate()}, ` +
      ` ${months[dateObj.getMonth()].slice(0, 3)} ${dateObj.getFullYear()}`;
  } else if (format === FORMATS["SD, DT FM FY"]) {
    final = `${days[dateObj.getDay()].slice(0, 3)}, ${dateObj.getDate()} ${
      months[dateObj.getMonth()]
    } ${dateObj.getFullYear()}`;
  } else if (format === FORMATS["DT,Month"]) {
    final = `${dateObj.getDate()} ${months[dateObj.getMonth()]}`;
  }

  return final;
};

export const isLaterThan = (
  inputDate: string,
  comparingtoDate?: string
): boolean => {
  const comapareToDate = !comparingtoDate
    ? new Date()
    : new Date(comparingtoDate);

  const comparingDate = new Date(inputDate);

  return comparingDate > comapareToDate;
};

export const isOlderThan = (
  date: string,
  comparingtoDate?: string
): boolean => {
  const comapareToDate = !comparingtoDate
    ? new Date()
    : new Date(comparingtoDate);

  const comparingDate = new Date(date);

  return comparingDate < comapareToDate;
};

export const timeRemainingInMins = (
  inputDate: string,
  comparingtoDate?: string
): number => {
  const futureDate = new Date(inputDate);

  const currentDate = !comparingtoDate ? new Date() : new Date(comparingtoDate);

  const diffInMS = futureDate.getTime() - currentDate.getTime();

  const diffInSecs = diffInMS / 1000;

  const diffInMins = diffInSecs / 60;

  return Math.ceil(diffInMins);
};

export const getTimeRemainingInMilliSeconds = (
  inputDate: string,
  comparingtoDate?: string
): number => {
  const futureDate = new Date(inputDate);

  const currentDateInMS = !comparingtoDate
    ? Date.now()
    : new Date(comparingtoDate).getTime();

  return futureDate.getTime() - currentDateInMS;
};

export const getTimeRemaining = (
  inputDate: string,
  comparingtoDate?: string | null,
  offsetInMins?: number
): { days: number; hours: number; mins: number; secs: number } => {
  const futureDate = new Date(inputDate);

  const offset = offsetInMins ? offsetInMins * 60 * 1000 : 0;
  const currentDate = !comparingtoDate ? new Date() : new Date(comparingtoDate);
  const diffInMS = futureDate.getTime() - currentDate.getTime() - offset;

  const days = Math.floor(diffInMS / (1000 * 60 * 60 * 24));
  const hrs = Math.floor((diffInMS % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.ceil((diffInMS % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.ceil((diffInMS % (1000 * 60)) / 1000);

  return { days, hours: hrs, mins: minutes, secs: seconds };
};

export const isToday = (dateString: string | number): boolean => {
  const today = new Date();
  const dt = new Date(dateString);

  return today.toDateString() === dt.toDateString();
};

export const isBeforeToday = (dateString: string): boolean => {
  const today = new Date();
  const dt = new Date(dateString);

  today.setHours(0, 0, 0, 0);

  return dt < today;
};

export const isPast = (dateString: string, cutOffTime: number): boolean => {
  const remainingTime = timeRemainingInMins(dateString) - cutOffTime;

  return remainingTime <= 0;
};

export const isTomorrow = (dateString: string | number): boolean => {
  const date = new Date(dateString);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const tomorrowStart = tomorrow.valueOf();
  tomorrow.setHours(23, 59, 59, 999);
  const tomorrowEnd = tomorrow.valueOf();
  return date.valueOf() >= tomorrowStart && date.valueOf() <= tomorrowEnd;
};

export const getDayByDate = (dateString: string | number): string => {
  const date = new Date(dateString);
  return days[date.getDay()];
};

export const isDayAfterTomorrow = (dateString: string | number): boolean => {
  const date = new Date(dateString);
  const today = new Date();
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  dayAfterTomorrow.setHours(0, 0, 0, 0);
  const dayAfterTomorrowStart = dayAfterTomorrow.valueOf();
  dayAfterTomorrow.setHours(23, 59, 59, 999);
  const dayAfterTomorrowEnd = dayAfterTomorrow.valueOf();
  return (
    date.valueOf() >= dayAfterTomorrowStart &&
    date.valueOf() <= dayAfterTomorrowEnd
  );
};
