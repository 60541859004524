export const hasSpecialChars = (val: string) => {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(val);
};

export const hasNoLetter = (val: string) => {
  return /^\d+$/.test(val);
};

export const hasLowercase = (val: string) => {
  return /[a-z]/.test(val);
};
