import PurpleArrow from "@assets/arrow-down-purple.svg";
import Lock from "@assets/lock-league-leaderboard-small.svg";
import {
  MainContainer,
  MiniContainer,
  MiniContainerDropdown,
  MiniContainerText,
  MiniDropdown
} from "./leaderboardMiniCard.style";

export interface ILeaderboardInfo {
  winners: number;
  secondPlace: number;
  thirdPlace: number;
  openSelections: boolean;
  isRestricted: boolean;
}

const LeaderboardInfoMiniCard = ({
  winners,
  secondPlace,
  thirdPlace,
  openSelections,
  isRestricted
}: ILeaderboardInfo) => {
  return (
    <MainContainer>
      <MiniContainer>
        <MiniContainerText>{winners}</MiniContainerText>
      </MiniContainer>
      <MiniContainer>
        <MiniContainerText>{secondPlace}</MiniContainerText>
      </MiniContainer>
      <MiniContainer>
        <MiniContainerText>{thirdPlace}</MiniContainerText>
      </MiniContainer>
      <MiniDropdown isDropdown openSelections={openSelections}>
        <MiniContainerDropdown
          openSelections={openSelections}
          src={isRestricted ? Lock : PurpleArrow}
        />
      </MiniDropdown>
    </MainContainer>
  );
};

export default LeaderboardInfoMiniCard;
