import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "../../../../../../../theme";
import { formatInDecimal } from "../../../../../../../utils/numberUtils";
import Container from "../../../../../../bricks/container";
import FlexContainer from "../../../../../../bricks/flexContainer";
import TextInSpan from "../../../../../../bricks/textInSpan";
import { RootState } from "../../../../../../../store/store";
import {
  GetLeaderboardForMeeting,
  ILeaderboardPunterUnit
} from "../../../../../../../store/features/leaderboardSlice";

const SelectionsText = styled.span`
  line-height: 1;
  color: white;
  width: max-content;
  text-align: center;
  font-size: ${theme.font.size.s};

  @media screen and (max-width: 768px) {
    width: fit-content;
  }
`;

interface IPointsAndRank {
  backgroundColor: string;
  clickCallback: () => void;
  hasSelections: boolean;
  points: number;
  rank: number;
  totalPunters: number;
  isSelectionOpen: boolean;
  meetingId: string;
  isAbandoned?: boolean;
  isPostponed?: boolean;
  width?: string;
}

const PointsAndRank = ({
  backgroundColor,
  clickCallback,
  hasSelections,
  rank,
  points,
  totalPunters,
  isSelectionOpen,
  isAbandoned,
  isPostponed,
  width = "25%",
  meetingId
}: IPointsAndRank) => {
  const dispatch = useDispatch();
  const { meetingIdToDataMap } = useSelector(
    (state: RootState) => state.leaderboard
  );

  const { meetings } = useSelector((state: RootState) => state.meetings);

  const { punterId } = useSelector((state: RootState) => state.user);

  const timers = useRef<Array<ReturnType<typeof setTimeout>>>();

  const [currentRank, setCurrentRank] = useState<number>(rank);
  const [currentPoints, setCurrentPoints] = useState<number>(points);
  const [currentTotalPunters, setCurrentTotalPunters] =
    useState<number>(totalPunters);

  const fetchFreshData = () => {
    if (timers.current?.length) {
      clearTimeout(timers.current.shift());
    }

    dispatch(
      GetLeaderboardForMeeting({
        punterId,
        meetingId
      })
    );
  };

  const extractInfo = (punters: ILeaderboardPunterUnit[]) => {
    if (punters.length) {
      const puntersOwnData = punters.find((item) => item.punterId === punterId);

      if (puntersOwnData) {
        const { rank, total, totalPunters } = puntersOwnData;

        setCurrentPoints(Number(total));
        setCurrentTotalPunters(Number(totalPunters));
        setCurrentRank(Number(rank));
      }
    }
  };

  const cancelTimers = () => {
    if (timers.current?.length) {
      timers.current.forEach((timer) => {
        clearTimeout(timer);
      });
    }
  };

  const getTextToDisplay = () => {
    if (isAbandoned) {
      return "Abandoned";
    }
    if (isPostponed) {
      return "Postponed";
    }

    return isSelectionOpen ? (
      <>
        Pick Now. <br /> Selections not made.
      </>
    ) : (
      <>
        Selections Closed. <br /> Selections not made.
      </>
    );
  };

  useEffect(() => {
    if (hasSelections && meetings && !timers.current) {
      timers.current = [];

      const meeting = meetings[meetingId];

      meeting?.races?.forEach((race) => {
        const fifteenMinuteOffset = 900000;

        const cutoffTime =
          new Date(race.raceTime).getTime() + fifteenMinuteOffset;

        const remainingTime = cutoffTime - Date.now();

        const isFutureRace = remainingTime >= 0;

        if (isFutureRace) {
          const timer = setTimeout(() => {
            fetchFreshData();
          }, remainingTime);

          timers.current!.push(timer);
        }
      });
    }
  }, [meetings]);

  useEffect(() => {
    if (hasSelections && meetingIdToDataMap) {
      const leaderboardData = meetingIdToDataMap[meetingId];

      if (leaderboardData) {
        extractInfo(leaderboardData.punters);
      }
    }
  }, [meetingIdToDataMap]);

  useEffect(() => {
    return () => {
      cancelTimers();
    };
  }, []);

  return (
    <Container
      width={width}
      bgcolor={backgroundColor}
      borderbottomrightradius="5px"
      bordertoprightradius="5px"
      display="grid"
      placecontent="center"
      paddingtop="5px"
      minheight="54px"
      paddingbottom="5px"
      cursor="pointer"
      onClick={clickCallback}>
      <FlexContainer
        cursor="pointer"
        direction="column"
        maxwidth="99%"
        alignitems="center"
        rowgap="8px"
        position="relative">
        {hasSelections && (
          <>
            <FlexContainer direction="column" rowgap="2px" cursor="pointer">
              <TextInSpan color="white" fontSize="0.6rem">
                Points
              </TextInSpan>
              <TextInSpan color="white" fontSize="0.7rem" fontWeight="bold">
                {formatInDecimal(currentPoints ?? 0)}
              </TextInSpan>
            </FlexContainer>
            <FlexContainer direction="column" rowgap="2px" cursor="pointer">
              <TextInSpan color="white" fontSize="0.6rem">
                Rank
              </TextInSpan>
              <TextInSpan
                color="white"
                fontSize="0.7rem">{`${currentRank ?? 0}/${currentTotalPunters ?? 0}`}</TextInSpan>
            </FlexContainer>
          </>
        )}
        {!hasSelections && (
          <SelectionsText>{getTextToDisplay()}</SelectionsText>
        )}
      </FlexContainer>
    </Container>
  );
};

export default PointsAndRank;
