import GallopTvLogo from "@assets/gallop-tv-final-logo.png";
import HollywoodBetsLogo from "@assets/hollywood-logo.png";
import WinningFormLogo from "@assets/winning-form-logo.png";
import TrophyJackpotTest from "@assets/trophy-how-to-play.svg";
import { useSelector } from "react-redux";
import openLink from "../../../../../utils/pageUtils";
import LinkButton from "../../../../composites/link-button/linkButton";
import LinkButtonContainer from "../homePostLogin.style";
import { RootState } from "../../../../../store/store";
import HowToPlay from "../../../../composites/link-button/howToPlay";

const Links = () => {
  const config = useSelector((state: RootState) => state.config.data);
  return (
    <LinkButtonContainer>
      <LinkButton
        onClick={() => {
          openLink(`${config.gallopTv}`);
        }}
        logo={GallopTvLogo}
        text="Watch Live"
        isGallopTv
      />
      <LinkButton
        onClick={() => {
          openLink(`${config.hollywoodBets}`);
        }}
        logo={HollywoodBetsLogo}
        text="Place A Bet"
      />
      <LinkButton
        onClick={() => {
          openLink(`${config.winningForm}`);
        }}
        logo={WinningFormLogo}
        text="Get Tips"
      />
      <HowToPlay
        onClick={() => {
          openLink(`${config.puntersHowToPlayVideoUrl}`);
        }}
        logo={TrophyJackpotTest}
        text="How to play"
      />
    </LinkButtonContainer>
  );
};

export default Links;
