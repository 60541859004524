import { useContext } from "react";
import BoxedMessage from "./common/BoxedMessage";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

const NextBigWinnerDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <BoxedMessage
      paddingLeft="10px"
      paddingRight="10px"
      paddingTop="20px"
      paddingBottom="20px"
      marginTop={isDesktop ? "0px" : "30px"}
      opacity={1}
      width={isDesktop ? "calc(80% - 20px)" : "70%"}>
      {isDesktop ? (
        <>
          <TextInSpan fontSize="0.8rem" lineHeight={1.5} zIndex={20}>
            At the Hollywoodbets Punters Challenge, we're dedicated to providing
            an exhilarating and rewarding experience for all horse racing fans.
          </TextInSpan>
          <TextInSpan
            fontSize="0.8rem"
            lineHeight={1.5}
            zIndex={20}
            fontWeight="bold"
            marginTop="5px">
            Join us today, and you could be our next big winner!
          </TextInSpan>
        </>
      ) : (
        <>
          <TextInSpan fontSize="0.8rem" lineHeight={1.5} zIndex={20}>
            At the Hollywoodbets Punters Challenge, we're
          </TextInSpan>
          <TextInSpan fontSize="0.8rem" lineHeight={1.5} zIndex={20}>
            dedicated to providing an exhilarating and
          </TextInSpan>
          <TextInSpan fontSize="0.8rem" lineHeight={1.5} zIndex={20}>
            rewarding experience for all horse racing fans.
          </TextInSpan>
          <TextInSpan
            fontSize="0.8rem"
            lineHeight={1.5}
            zIndex={20}
            fontWeight="bold"
            marginTop="5px">
            Join us today, and you could be our next big winner!
          </TextInSpan>
        </>
      )}
    </BoxedMessage>
  );
};

export default NextBigWinnerDisplay;
