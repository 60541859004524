import theme from "../../../../../../../theme";
import Container from "../../../../../../bricks/container";
import FlexContainer from "../../../../../../bricks/flexContainer";

interface IMeetingBaseContainer {
  width: string;
  children: JSX.Element | JSX.Element[];
  backgroundColor: string;
  borderColor: string;
  toShowBorder?: boolean;
  toShowShadow?: boolean;
  toShowDropDown?: boolean;
  mouseEnterHandler?: () => void;
  mouseRemoveHandler?: () => void;
}

const MeetingBaseContainer = ({
  width,
  children,
  backgroundColor,
  borderColor,
  toShowBorder,
  toShowShadow,
  toShowDropDown,
  mouseEnterHandler,
  mouseRemoveHandler
}: IMeetingBaseContainer) => {
  return (
    <Container
      width={width}
      padding="1px"
      bgcolor={toShowBorder ? borderColor : "transparent"}
      borderbottomrightradius={toShowDropDown ? "0px" : "5px"}
      borderradius="8px"
      boxshadow={
        toShowShadow ? `0px 4px 6px 0px ${theme.colors.cardShadow}` : "none"
      }>
      <FlexContainer
        borderradius="8px"
        width="100%"
        bgcolor={backgroundColor}
        justifycontent="space-between"
        zIndex={3}
        alignitems="center"
        maxheight="68px"
        cursor="pointer"
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseRemoveHandler}
        position="relative">
        {children}
      </FlexContainer>
    </Container>
  );
};

export default MeetingBaseContainer;
