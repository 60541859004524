import CalendarIconToday from "@assets/calendar-icon-today.svg";
import CalendarIconFuture from "@assets/calendar-icon-future.svg";
import ResultsIcon from "@assets/results-icon-white.svg";
import {
  GroupHeaderContainer,
  GroupTitle,
  GroupSubtitle,
  GroupIcon
} from "./groupHeader.style";
import Container from "../../bricks/container";

interface IGroupHeader {
  title?: string;
  subtitle?: string;
  color?: string;
  imageBackgroundColor?: string;
  toMask?: boolean;
}

const GroupHeader = ({
  title,
  subtitle,
  color,
  imageBackgroundColor,
  toMask
}: IGroupHeader) => {
  const formattedTitle = subtitle ? title?.concat(", ") : title;
  let imageSrc = "";

  if (title === "Today") {
    imageSrc = CalendarIconToday;
  } else if (title === "Results" && !subtitle) {
    imageSrc = ResultsIcon;
  } else {
    imageSrc = CalendarIconFuture;
  }

  return (
    <GroupHeaderContainer>
      {!imageBackgroundColor && !toMask && <GroupIcon src={imageSrc} />}
      {imageBackgroundColor && toMask && (
        <Container
          width="20px"
          height="20px"
          bgcolor={imageBackgroundColor}
          mask={`url(${imageSrc}) no-repeat center`}
        />
      )}
      {title && <GroupTitle color={color}> {formattedTitle} </GroupTitle>}
      {subtitle && <GroupSubtitle color={color}> {subtitle} </GroupSubtitle>}
    </GroupHeaderContainer>
  );
};

export default GroupHeader;
