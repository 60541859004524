export const getIntlFormat = (value: number | string): string => {
  const nfObject = new Intl.NumberFormat("en-US");
  return `R${nfObject.format(Number(value))}`;
};

export const getBalanceFormatted = (
  value: number | string,
  fractionDigits: number = 2
): string => {
  const nfObject = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: fractionDigits
  });

  return nfObject.format(Number(value)).replace("$", "R");
};
