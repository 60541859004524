import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../../theme";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import StatsValueDisplay from "./statsValueDisplay";
import { STATS_DROP_DOWN_ITEMS_ITEMS } from "../constants/friendsLeaguesConstants";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import { RootState } from "../../../../store/store";
import {
  ILeagueStatsMeeting,
  getStatsData
} from "../../../../store/features/friendsLeagueSlice";
import LeagueStatsDropDown from "./dropdown";

const StatsByRacecourse = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const dispatch = useDispatch();

  const { statsMeetings, leagueStatsData } = useSelector(
    (state: RootState) => state.friendsLeague
  );
  const { totalSelections, totalWins, winPercentage, winners, selections } =
    leagueStatsData ?? {};

  const [selectedRaceCourse, setSelectedRaceCourse] =
    useState<ILeagueStatsMeeting | null>(null);

  const currentDate = useRef<string>("2024-05-06");

  const { punterId } = useSelector((state: RootState) => state.user);

  const sendQuery = (racecourseId: number) => {
    dispatch(
      getStatsData({
        punterId,
        raceCourseId: racecourseId ?? selectedRaceCourse?.raceCourseId,
        date: currentDate.current
      })
    );
  };

  const onTabClick = (selected: ILeagueStatsMeeting) => {
    setSelectedRaceCourse(selected);

    sendQuery(selected.raceCourseId);
  };

  const onDropDownChange = (selection: string) => {
    let formatted: string = "";

    if (selection === STATS_DROP_DOWN_ITEMS_ITEMS[0]) {
      formatted = "2024-05-06";
    } else if (selection === STATS_DROP_DOWN_ITEMS_ITEMS[1]) {
      formatted = "2020-01-01";
    }

    currentDate.current = formatted;

    sendQuery(selectedRaceCourse?.raceCourseId!);
  };

  useEffect(() => {
    if (statsMeetings) {
      setSelectedRaceCourse(statsMeetings[0]);
      sendQuery(statsMeetings[0].raceCourseId);
    }
  }, [statsMeetings]);

  return (
    <FlexContainer
      id="my stats 1st row"
      direction="column"
      width="100%"
      height="auto"
      border={`1px solid ${theme.colors.lightGray}`}
      bgcolor={theme.colors.lightAliceBlue}
      borderradius="5px"
      alignitems="center"
      rowgap="10px">
      <FlexContainer
        id="row1"
        width="98%"
        height="auto"
        bordertopleftradius="5px"
        bordertoprightradius="5px"
        position="relative">
        <FlexContainer
          direction="column"
          marginleft="5px"
          color={theme.colors.purplePrimary}
          margintop="10px"
          width="100%">
          <Container textalign="left" fontWeight="bold" fontSize="1rem">
            My Stats
          </Container>

          <Container textalign="left" fontSize="0.8rem">
            Stats by racecourse
          </Container>
        </FlexContainer>

        <LeagueStatsDropDown
          right="11px"
          top="11px"
          dropDownCallback={onDropDownChange}
        />
      </FlexContainer>

      <Container width="97%" height="1px" bgcolor={theme.colors.lightGray} />

      <Container
        width="97%"
        overflow="scroll"
        height="max-content"
        paddingbottom="6px">
        <FlexContainer
          width="fit-content"
          columngap="10px"
          fontSize="0.7rem"
          marginleft="0px">
          {statsMeetings?.map((item) => {
            return (
              <Button
                key={`statMeeting${item.raceCourseName}`}
                text={item.raceCourseName}
                width="11vw"
                minWidth={isDesktop ? "140px" : "100px"}
                background={
                  item.raceCourseName === selectedRaceCourse?.raceCourseName
                    ? theme.colors.purplePrimary
                    : "none"
                }
                fontcolor={
                  item.raceCourseName === selectedRaceCourse?.raceCourseName
                    ? theme.colors.primary
                    : "black"
                }
                onClick={() => {
                  onTabClick(item);
                }}
              />
            );
          })}
        </FlexContainer>
      </Container>
      <Container width="100%" height="1px" bgcolor={theme.colors.lightGray} />

      <FlexContainer
        width="100%"
        height="auto"
        bgcolor={theme.colors.primary}
        margintop="-10px"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px"
        fontWeight="bold"
        justifycontent="space-between">
        <StatsValueDisplay
          title="Total Selections"
          value={totalSelections!}
          marginLeft={`${isDesktop ? 50 : 10}px`}
        />
        <StatsValueDisplay
          title="Total Wins"
          value={totalWins!}
          marginLeft={`${isDesktop ? 70 : 10}px`}
        />
        <StatsValueDisplay
          title="Win Percentage"
          value={winPercentage! ? `${winPercentage}%` : winPercentage!}
          marginLeft={`${isDesktop ? 70 : 0}px`}
        />
        <StatsValueDisplay
          title="Most winners in a single day"
          value={winners && selections ? `${winners}/${selections}` : null!}
          marginLeft={`${isDesktop ? 60 : 5}px`}
          marginRight="10px"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default StatsByRacecourse;
