import { useSelector } from "react-redux";
import { useContext } from "react";
import { ILeaderboardPunterUnit } from "../../../../store/features/leaderboardSlice";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";

import { RootState } from "../../../../store/store";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";
import GridStandardFooterPageNumber from "./gridStandardFooterPageNumber";
import NextPreviousButton from "../../../composites/next-previous-button/nextPreviousButton";

interface IGridStandardFooter {
  currentMeetingName: string | undefined;
  currentMeetingTime: string;
  previousCallback: () => void;
  nextCallback: () => void;
  currentPageLocal: number;
  currentPuntersList: ILeaderboardPunterUnit[];
  currentMeetingId: string | number;
  totalNumPages: number;
  currentPunters?: ILeaderboardPunterUnit[] | null;
}

const GridStandardFooter = ({
  currentMeetingName,
  currentMeetingTime,
  previousCallback,
  nextCallback,
  currentPageLocal,
  currentPuntersList,
  currentMeetingId,
  totalNumPages,
  currentPunters
}: IGridStandardFooter) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const totalNumbersWRTEvents = useSelector(
    (state: RootState) => state.leaderboard.totalPuntersWRTMeetings
  );

  return isDesktop ? (
    <FlexContainer
      bgcolor={theme.colors.lightAliceBlue}
      padding="12px 0 12px 0"
      border={`1px solid ${theme.colors.purplePrimary}`}
      borderradius="0 0 5px 5px"
      boxshadow={`1px 1px 5px ${theme.colors.lightGray}`}
      margintop="7px">
      <FlexContainer direction="column" width="50%" alignitems="center">
        <Container
          fontSize="2vw"
          fontWeight="bold"
          color={theme.colors.purplePrimary}
          margintop="6px">
          {" "}
          {currentMeetingName}{" "}
        </Container>

        <Container fontSize="1vw" color={theme.colors.purplePrimary}>
          {" "}
          {currentMeetingTime}{" "}
        </Container>
      </FlexContainer>

      <FlexContainer
        width="50%"
        justifycontent="space-between"
        alignitems="center"
        marginright="10px">
        <NextPreviousButton
          enabled={currentPageLocal > 1}
          callBack={previousCallback}
          isActive={!!currentPuntersList?.length}
        />

        <Container
          fontSize="0.7rem"
          margin="auto 0"
          color={theme.colors.purplePrimary}
          lineheight="1">
          <GridStandardFooterPageNumber
            currentPuntersList={currentPunters}
            totalNumPunters={totalNumbersWRTEvents[currentMeetingId]}
            totalPages={totalNumPages}
            currentPage={currentPageLocal}
          />
        </Container>
        <NextPreviousButton
          isNext
          enabled={currentPageLocal < totalNumPages}
          callBack={nextCallback}
          isActive={!!currentPuntersList?.length}
        />
      </FlexContainer>
    </FlexContainer>
  ) : (
    <FlexContainer
      direction="column"
      alignitems="center"
      rowgap="7px"
      bgcolor={theme.colors.lightAliceBlue}
      padding="12px 0 12px 0"
      border={`1px solid ${theme.colors.purplePrimary}`}
      borderradius="0 0 5px 5px"
      margintop="7px">
      <FlexContainer width="90%" justifycontent="space-between">
        <NextPreviousButton
          enabled={currentPageLocal > 1}
          callBack={previousCallback}
          isActive={!!currentPuntersList?.length}
        />

        <Container
          fontSize="0.7rem"
          margin="auto 0"
          color={theme.colors.purplePrimary}>
          <GridStandardFooterPageNumber
            currentPuntersList={currentPunters}
            totalNumPunters={totalNumbersWRTEvents[currentMeetingId]}
            totalPages={totalNumPages}
            currentPage={currentPageLocal}
          />
        </Container>

        <NextPreviousButton
          isNext
          enabled={currentPageLocal < totalNumPages}
          callBack={nextCallback}
          isActive={!!currentPuntersList?.length}
        />
      </FlexContainer>

      {currentPunters !== null && (
        <>
          <Container
            fontSize="0.7rem"
            fontWeight="bold"
            color={theme.colors.purplePrimary}
            margintop="6px">
            {" "}
            {currentMeetingName}{" "}
          </Container>

          <Container
            fontSize="0.55rem"
            color={theme.colors.purplePrimary}
            margintop="-4px">
            {" "}
            {currentMeetingTime}{" "}
          </Container>
        </>
      )}
    </FlexContainer>
  );
};

export default GridStandardFooter;
