import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IMeeting,
  IMeetingRace,
  IRaceRunner,
  getMeetingData
} from "../../../../../store/features/meetingsSlice";
import { RootState } from "../../../../../store/store";
import { GetLeaderboardForMeeting } from "../../../../../store/features/leaderboardSlice";

const INTERVAL_IN_SECONDS: number = 50;

interface IRaceObject {
  index: number | null;
  race: IMeetingRace | null;
  isDone: boolean;
  timerToRace: number | ReturnType<typeof setTimeout> | null;
}

const useCurrentMeetingResultTracker = (punterId: string) => {
  const dispatch = useDispatch();
  const { meetings } = useSelector((state: RootState) => state.meetings);
  const pendingRaces = useRef<IMeetingRace[] | null>(null);
  const currentMeetingId = useRef<number>(-1);
  const currentRaceInterval = useRef<number | ReturnType<typeof setInterval>>(
    -1
  );

  const allRaces = useRef<IRaceObject[] | null>(null);

  const currentOngoingRaceNumber = useRef<number>(-1);
  const currentOngoingRaceIndex = useRef<number>(-1);

  const pollForMeetingData = () => {
    dispatch(getMeetingData({ punterId, meetingId: currentMeetingId.current }));

    dispatch(
      GetLeaderboardForMeeting({
        punterId,
        meetingId: currentMeetingId.current
      })
    );
  };

  const hasAllResultedForRace = (runners: IRaceRunner[]): boolean => {
    const hasOneWithPosition = runners.find(
      (runner) =>
        Number(runner.finishingPosition) === 0 && runner.runnerStatus === 1
    );
    return !hasOneWithPosition;
  };

  const startTracking = (item: IMeetingRace, index: number) => {
    currentOngoingRaceNumber.current = Number(item.raceNumber);
    currentOngoingRaceIndex.current = Number(index);

    currentRaceInterval.current = setInterval(
      pollForMeetingData,
      INTERVAL_IN_SECONDS * 1000
    );
  };

  const addRacesToTrack = (races: IMeetingRace[], meetingId: number) => {
    pendingRaces.current = JSON.parse(JSON.stringify(races));
    currentMeetingId.current = meetingId;

    allRaces.current = races.map((item, index) => {
      const resulted = hasAllResultedForRace(item.runners);
      const timeToRace = new Date(item.raceTime).getTime() - Date.now();

      const timer = resulted
        ? null
        : setTimeout(() => {
            startTracking(item, index);
          }, timeToRace);

      return {
        index,
        race: item,
        isDone: resulted,
        timerToRace: timer
      };
    });
  };

  const stopCurrentRaceTracking = () => {
    if (currentRaceInterval.current) {
      clearInterval(currentRaceInterval.current);
    }
    currentRaceInterval.current = -1;
  };

  const cancelAllRaceTrackings = () => {
    if (allRaces.current) {
      /* eslint-disable no-param-reassign */
      allRaces.current.forEach((item: IRaceObject) => {
        item.race = null;
        item.index = null;
        if (item.timerToRace) clearTimeout(item.timerToRace);
      });
      /* eslint-enable no-param-reassign */

      allRaces.current = null;

      currentOngoingRaceNumber.current = -1;
      currentOngoingRaceIndex.current = -1;
      stopCurrentRaceTracking();
    }
  };

  const checkAndCearAllTrackers = () => {
    const len = allRaces.current!.length;

    if (currentOngoingRaceIndex.current === len - 1) {
      cancelAllRaceTrackings();
    }
  };

  useEffect(() => {
    if (meetings && currentOngoingRaceIndex.current > -1) {
      const meeting: IMeeting = meetings[currentMeetingId.current];

      const { runners } = meeting.races[currentOngoingRaceIndex.current];

      if (hasAllResultedForRace(runners)) {
        stopCurrentRaceTracking();
        checkAndCearAllTrackers();
      }
    }
  }, [meetings]);

  return {
    addRacesToTrack,
    cancelAllRaceTrackings
  };
};

export default useCurrentMeetingResultTracker;
