import { ILeaderboardPunter } from "../components/composites/leaderboard-card/leaderboardCard";
import { ILeagueLeaderboardItem } from "../store/features/friendsLeagueSlice";
import { ILeaderboardPunterUnit } from "../store/features/leaderBoardSlice";

export const MapFromLeagueLeaderboardItem = (
  punters: ILeagueLeaderboardItem[] | null
) => {
  const newPunters: ILeaderboardPunter[] = [];

  if (punters) {
    punters.forEach((punter) => {
      newPunters.push({
        punterId: punter.punterId,
        userId: punter.punterAlias,
        rank: punter.leagueRank,
        total: punter.meetingPoints,
        winners: punter.winners,
        secondPlace: punter.secondPlaces,
        thirdPlace: punter.thirdPlaces
      });
    });
  }

  return newPunters;
};

export const MapFromLeaderboardPunterUnit = (
  punters: ILeaderboardPunterUnit[] | null
) => {
  const newPunters: ILeaderboardPunter[] = [];

  if (punters) {
    punters.forEach((punter) => {
      newPunters.push({
        punterId: Number(punter.punterId),
        userId: punter.userId.toString(),
        rank: Number(punter.rank),
        total: Number(punter.total),
        winners: Number(punter.winners),
        secondPlace: Number(punter.secondPlace),
        thirdPlace: Number(punter.thirdPlace)
      });
    });
  }

  return newPunters;
};
