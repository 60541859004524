import Container from "../../../../bricks/container";
import Image from "../../../../bricks/image";
import BoxedMessage, { IBoxedMessage } from "./BoxedMessage";

interface IBoxMessageWithIcon extends IBoxedMessage {
  image: string;
  children: JSX.Element;
  isDesktop?: boolean;
}

const BoxMessageWithIcon = (props: IBoxMessageWithIcon) => {
  const { image, children, isDesktop } = props;

  return (
    <BoxedMessage
      paddingLeft="10px"
      paddingRight="10px"
      paddingBottom="20px"
      marginTop={isDesktop ? "0px" : "30px"}
      backgroundColor="white"
      width={isDesktop ? "calc(25% - 15px)" : "70%"}
      opacity={1}
      {...props}>
      <Container
        height={isDesktop ? "100px" : "auto"}
        width={isDesktop ? "30%" : "20%"}
        alignContent={isDesktop ? "center" : "normal"}
        display={isDesktop ? "flex" : "normal"}
        zIndex={20}
        margintop="10px">
        <Image src={image} objectFit="scale-down" width="100%" height="auto" />
      </Container>
      {children}
    </BoxedMessage>
  );
};

export default BoxMessageWithIcon;
