/* eslint-disable */
import Infoicon from "@assets/bs-information-icon.svg";
import InfoCloseicon from "@assets/bs-close-info-x.svg";

interface IBetTypeInfo {
  text?: string;
  toOpen?: boolean;
  toShowButton?: boolean;
}

const Info = ({ text, toOpen, toShowButton }: IBetTypeInfo) => {
  // const [toOpen, setToOpen] = useState(open)
  const displayText: string = text || "Info will soon be added.";

  return (
    <>
      {/* info button starts */}
      <div
        style={{
          position: "absolute",
          background: "none",
          width: "100%",
          height: "auto",
          top: "3px"
        }}>
        {toOpen && (
          <div
            style={{
              position: "absolute",
              top: "14px",
              width: "96.4%",
              height: "3.2rem",
              background: "white",
              border: "1px solid #764CBF",
              borderRadius: "3px",
              padding: "3px",
              fontSize: "0.5rem"
            }}>
            <div
              style={{
                height: "99%",
                background: "none",
                overflowY: "scroll"
              }}>
              {" "}
              <label
                style={{
                  fontSize: "0.7rem",
                  color: "#5C2D91",
                  lineHeight: "1.2",
                  display: "inline-block",
                  textAlign: "left"
                }}>
                {displayText}
              </label>{" "}
            </div>
          </div>
        )}
        {toShowButton && (
          <div
            onClick={() => {
              setToOpen(!toOpen);
            }}
            style={{
              position: "absolute",
              right: "0",
              top: "-10px",
              background: "white",
              color: "#7575a3",
              width: "24px",
              height: "20px",
              border: "1px solid #7575a3",
              borderRight: "0px",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              display: "grid",
              placeContent: "center",
              fontSize: "0.8rem",
              fontWeight: "bold"
            }}>
            {!toOpen && (
              <img src={Infoicon} style={{ width: "16px", height: "16px" }} />
            )}
            {toOpen && (
              <img
                src={InfoCloseicon}
                style={{ width: "16px", height: "16px" }}
              />
            )}
          </div>
        )}
      </div>{" "}
      {/* info button ends */}
    </>
  );
};
export default Info;
