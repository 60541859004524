import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import theme from "../../../../theme";
import hasBadWords from "../../../../utils/profanity";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import LabeledInput from "../../labled-input/labeledInput";
import { RootState } from "../../../../store/store";
import LeagueFormBase from "../modals/leagueFormBase";
import {
  hasLowercase,
  hasSpecialChars
} from "../../../../utils/validationUtils";
import { CloseIconDiv } from "../../../composites/close-icon/closeIcon.style";

interface IJoinALeagueForm {
  callbackForJoin: (value: string) => void;
  toDisable: boolean;
  code: string | null | undefined;
}

const getMessage = (val: string): string => {
  let msg = "";

  if (hasBadWords(val)) return "League code contains profanity";

  if (hasSpecialChars(val)) {
    return "League code can not contain special characters";
  }

  if (hasLowercase(val)) {
    return "Code cannot contain lower case letters";
  }

  if (val.length > 8) {
    msg = "League code cannot be longer than 8 characters";
  } else if (val.length < 8) {
    msg = "Not enough chars";
  }

  return msg;
};

const JoinALeagueForm = ({
  callbackForJoin,
  toDisable,
  code
}: IJoinALeagueForm) => {
  const { joinALeagueData } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const [inputErrorMessage, setInputErrorMessage] = useState<string>("");
  const [leagueCode, setLeagueCode] = useState<string | null | undefined>(code);
  const [toDisableInput, setToDisableInput] = useState<boolean>(false);

  const inputRef = useRef<HTMLDivElement | null>(null);
  const onLeagueCodeInput = (val: string) => {
    const newErrorMessage = getMessage(val);

    if (newErrorMessage.length > 0 && val.length > 0) {
      setInputErrorMessage(newErrorMessage);
      if (leagueCode) setLeagueCode(null);
    } else if (newErrorMessage.length === 0 && inputErrorMessage.length > 0) {
      setInputErrorMessage("");

      if (val.length) setLeagueCode(val);
    } else {
      setLeagueCode(val ?? null);
    }
  };

  const onJoinRequestButtonClick = () => {
    setToDisableInput(true);
    callbackForJoin(leagueCode!);
  };

  useEffect(() => {
    if (!toDisable) {
      setToDisableInput(false);
    }
  }, [toDisable]);

  useEffect(() => {
    if (joinALeagueData) {
      const { errorInJoinLeague } = joinALeagueData;

      if (errorInJoinLeague) {
        setInputErrorMessage(errorInJoinLeague);
      }
    }
  }, [joinALeagueData]);

  useEffect(() => {
    if (leagueCode && inputRef.current) inputRef.current.value = leagueCode;
  }, []);

  return (
    <LeagueFormBase text="Join A League">
      <>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          color={theme.colors.grey}
          fontStyle="italic">
          Please enter the code of the league that you want to join.
        </Container>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          margintop="10px">
          Enter league code.
        </Container>

        <LabeledInput
          width="92%"
          height="2.5rem"
          align="left"
          placeholder="Enter your league code"
          valueUpdater={onLeagueCodeInput}
          disable={toDisableInput}
          reference={inputRef}
        />

        {inputErrorMessage && (
          <Container textalign="left" fontSize="0.7rem" color="red">
            {inputErrorMessage}
          </Container>
        )}
        <CloseIconDiv>
          <Button
            margintop="20px"
            width="50%"
            background={`linear-gradient(
                ${theme.colors.purpleSecondary},
                ${theme.colors.purplePrimary}
                )`}
            text="Send Join Request"
            fontSize="0.7rem"
            disabled={!leagueCode || toDisable}
            onClick={onJoinRequestButtonClick}
          />
        </CloseIconDiv>
      </>
    </LeagueFormBase>
  );
};

export default JoinALeagueForm;
