import { useContext } from "react";
import Container from "../../bricks/container";
import FlexContainer from "../../bricks/flexContainer";
import SaddleNumberDisplay from "../../composites/saddleNumberDisplay";
import { formatInDecimal } from "../../../utils/numberUtils";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import theme from "../../../theme";

interface IRunnerItem {
  index: string | number;
  horseNum: string | number;
  horseName: string;
  points: string | number;
}

const RunnerItem = ({ index, horseNum, horseName, points }: IRunnerItem) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <FlexContainer
      height="max-content"
      columngap="2px"
      alignitems="center"
      justifycontent="space-between"
      width="100%">
      <FlexContainer
        width="65%"
        justifycontent="flex-start"
        alignitems="center">
        <Container
          width="20%"
          maxwidth={isDesktop ? "max-content" : "35px"}
          textalign="left"
          lineheight="1"
          fontSize={isDesktop ? "max(1vw,0.55rem)" : "min(2.5vw,0.6rem)"}>
          {`Race ${index}.`}
        </Container>

        <Container width="max-content" marginleft={isDesktop ? "5px" : "2px"}>
          <SaddleNumberDisplay
            value={Number(horseNum)}
            maxHeight={isDesktop ? "26px" : "18px"}
            maxWidthForLow={isDesktop ? "30px" : "20px"}
            maxHeightForLow={isDesktop ? "8px" : "10px"}
            fontSize={isDesktop ? "0.8rem" : "0.6rem"}
          />
        </Container>

        <Container
          margintop="1px"
          marginleft="10px"
          height="auto"
          width="max-content"
          fontSize={isDesktop ? "max(1vw,0.55rem)" : "min(2.5vw,0.6rem)"}
          textalign="left"
          lineheight="1"
          overflowwrap="anywhere">
          {horseName}
        </Container>
      </FlexContainer>

      <FlexContainer
        width={isDesktop ? "30%" : "max(23%,15vw)"}
        borderradius="5px"
        bgcolor={theme.colors.lbPointsPurple}
        alignitems="center"
        paddingtop="3px"
        paddingbottom="3px"
        justifycontent="center">
        <Container
          borderradius="5px"
          height={isDesktop ? "1.2rem" : "max(0.8rem,3vw)"}
          display="grid"
          placecontent="center"
          width="80%"
          fontSize="max(1.2vw,0.7rem)"
          fontWeight="bold"
          color="white"
          lineheight="1"
          overflowwrap="anywhere">
          {formatInDecimal(Number(points))}
        </Container>
      </FlexContainer>
    </FlexContainer>
  );
};

export default RunnerItem;
