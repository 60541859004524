import { useState } from "react";
import BurgerMenuIcon from "@assets/burger-menu.svg";
import BurgerMenuClose from "@assets/burger-menu-cancel.svg";
import GallopLogo from "@assets/nav-gallop-tv.svg";
import HollywoodLogo from "@assets/nav-hollywoodbets-icon.svg";
import WinningformLogo from "@assets/nav-winning-form-icon.svg";
import ProfileIcon from "@assets/burger-menu-profile.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  BURGER_MENU_ITEMS,
  OFFLINE_ITEMS,
  ONLINE_ITEMS,
  ONLINE_ITEMS_PRE_LEAGUE,
  burgerItemText
} from "./burgerMenuConsts";
import BurgerMenuItem from "./burgerMenuItem";
import {
  IconDiv,
  ItemsDiv,
  BackgroundShade,
  LinkImage,
  AllLinkImagesDiv,
  ImageDiv,
  ProfileContainer,
  ProfileTextContainer,
  BurgerIconImage
} from "./burgerMenu.style";
import openLink from "../../../utils/pageUtils";
import { RootState } from "../../../store/store";
import { SeperatorDiv } from "../../views/betslipNew/components/betTypeDisplay/betTypeDisplay.style";
import theme from "../../../theme";
import { logout } from "../../../store/features/userSlice";
import useLogin from "../../../hooks/useLogin";

interface IBurgerMenu {
  isLoggedIn: boolean;
}

const BurgerMenu = ({ isLoggedIn }: IBurgerMenu) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { signoutRedirect, onLogInClick } = useLogin();

  const userData = useSelector((state: RootState) => state.user);
  const config = useSelector((state: RootState) => state.config.data);

  const onLogoutClicked = () => {
    dispatch(logout());
    signoutRedirect();
  };

  const mapBurgerItems = (items: BURGER_MENU_ITEMS[]) => {
    return items.map((item) => {
      return (
        <BurgerMenuItem
          key={`burgerItem_${burgerItemText[item]}`}
          burgerItem={item}
          onItemSelected={() => setIsOpen(false)}
          onLoginSelect={onLogInClick}
          onLogoutSelect={onLogoutClicked}
        />
      );
    });
  };

  const onlineItems = config.showFriendsLeague
    ? ONLINE_ITEMS
    : ONLINE_ITEMS_PRE_LEAGUE;
  const items = mapBurgerItems(isLoggedIn ? onlineItems : OFFLINE_ITEMS);

  return (
    <>
      <IconDiv
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <BurgerIconImage src={isOpen ? BurgerMenuClose : BurgerMenuIcon} />
        {isOpen && (
          <ItemsDiv>
            <ProfileContainer>
              <img src={ProfileIcon} />
              <ProfileTextContainer>
                <span style={{ fontSize: `${theme.font.size.s}` }}>
                  Welcome {isLoggedIn ? "back" : "to"}
                </span>
                <b style={{ fontSize: `${theme.font.size.m}` }}>
                  {isLoggedIn ? userData.userId : "Punters Challenge"}
                </b>
              </ProfileTextContainer>
            </ProfileContainer>
            <SeperatorDiv
              borderColor={theme.colors.burgerMenuSeparator}
              width="96%"
            />
            {items}
            <SeperatorDiv
              borderColor={theme.colors.burgerMenuSeparator}
              width="96%"
            />
            <AllLinkImagesDiv>
              <ImageDiv>
                <span>Watch Live</span>
                <LinkImage
                  src={GallopLogo}
                  onClick={() => {
                    openLink(config.gallopTv);
                  }}
                />
              </ImageDiv>
              <ImageDiv>
                <span>Place A Bet</span>
                <LinkImage
                  src={HollywoodLogo}
                  onClick={() => {
                    openLink(config.hollywoodBets);
                  }}
                />
              </ImageDiv>
              <ImageDiv>
                <span>Get Tips</span>
                <LinkImage
                  src={WinningformLogo}
                  onClick={() => {
                    openLink(config.winningForm);
                  }}
                />
              </ImageDiv>
            </AllLinkImagesDiv>
          </ItemsDiv>
        )}
      </IconDiv>
      {isOpen && <BackgroundShade onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default BurgerMenu;
