export const getOrdinalForNumber = (
  num: number | string,
  append: boolean = false
): string => {
  const n = Number(num);
  if (Number.isNaN(n)) return "";
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return (append ? n : "") + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatInDecimal = (
  value: number,
  decimalPlaces: number = 2
): string => {
  const parts = String(value).split(".");

  const decimalPlacesStr = parts[1]
    ? parts[1].padEnd(decimalPlaces, "0")
    : "".padEnd(decimalPlaces, "0");

  const final = `${parts[0]}.${decimalPlacesStr.substring(0, decimalPlaces)}`;

  return final;
};

export const formatInDecimalAndLocal = (
  value: number,
  decimalPlaces: number = 2
): string => {
  if (!value) return formatInDecimal(0, decimalPlaces);
  const num = value.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
  return num;
};
