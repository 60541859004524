import {
  ICarousalItem,
  ItemImageStyle,
  ItemVideoStyle
} from "./carousel.style";

const CarousalItem = ({ type, content }: ICarousalItem) => (
  <>
    {type === "image" && <ItemImageStyle src={content} />}
    {type === "video" && <ItemVideoStyle src={content} autoPlay />}
  </>
);

export default CarousalItem;
