import styled from "styled-components";
import theme from "../../../../../theme";

interface ILightTotals {
  shouldUseLightTotals: boolean;
  isLuckyPick?: boolean;
}

export const TitleText = styled.span<ILightTotals>`
  width: 50%;
  padding: 5px;
  font-size: ${theme.font.size.m};
  color: ${(props) =>
    props.shouldUseLightTotals ? theme.colors.grey : "black"};

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.s};
  }
`;

export const ValueText = styled.span`
  width: 50%;
  font-size: ${theme.font.size.m};
  color: ${theme.colors.purplePrimary};
  font-weight: ${theme.font.weight.bold};
  padding: 5px;

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.s};
  }
`;

export const TotalsDiv = styled.div<ILightTotals>`
  width: ${(props) => (props.isLuckyPick ? "90%" : "none")};
  background-color: ${(props) =>
    props.shouldUseLightTotals ? "none" : theme.colors.purpleLight};
  border: 1px solid
    ${(props) =>
      props.shouldUseLightTotals ? "none" : theme.colors.purplePrimary};
  border-radius: 5px;
  margin: ${(props) => (props.shouldUseLightTotals ? "0px" : "15px 20px")};
`;
