import RefreshIcon from "@assets/regenerate-icon.svg";
import { useContext } from "react";
import styled from "styled-components";
import Container from "../../../../../bricks/container";
import FlexContainer from "../../../../../bricks/flexContainer";
import { LuckyPickContext } from "../../../context/luckyPickContext";
import { LP_ACTIONS } from "../../../store/luckyPickActions";
import { formatInDecimal } from "../../../../../../utils/numberUtils";
import { SELECTION_TYPES } from "../../../constants/luckyPickConstants";
import theme from "../../../../../../theme";
import SaddleNumberDisplay from "../../../../../composites/saddleNumberDisplay";

interface ISelectionRow {
  horseNumber: number;
  horseName: string;
  odds: number;
  bettype: string;
  selectionType?: string | null;
  winOrPlace?: string | null;
  eventNumber: number;
}

const HorseNameContainer = styled(Container)`
  @media screen and (min-width: 100px) and (max-width: 300px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 301px) and (max-width: 350px) {
    font-size: 0.8rem;
  }
`;

const SelectionRow = ({
  bettype,
  horseNumber,
  horseName,
  odds,
  selectionType,
  winOrPlace,
  eventNumber
}: ISelectionRow) => {
  const { lpDispatch } = useContext(LuckyPickContext);

  const doRefresh = () => {
    lpDispatch({
      type: LP_ACTIONS.REFRESH_SINGLE_HORSE_FOR_BETTYPE,
      bettype,
      toReplace: horseName,
      selectionType
    });
  };

  const onHorseClick = () => {
    lpDispatch({
      type: LP_ACTIONS.OPEN_HORSE_SELECTION,
      bettype,
      toReplace: horseName
    });
  };

  return (
    <FlexContainer
      width="100%"
      minheight="30px"
      marginbottom="5px"
      alignitems="center">
      <FlexContainer
        width="10%"
        alignitems="center"
        justifycontent="center"
        cursor="pointer"
        onClick={doRefresh}>
        <img src={RefreshIcon} style={{ transform: "scale(0.9)" }} />
      </FlexContainer>

      <Container
        width="6%"
        display="grid"
        alignitems="center"
        height="100%"
        textalign="left">
        <SaddleNumberDisplay value={horseNumber} />
      </Container>
      <HorseNameContainer
        width={`${winOrPlace ? 42 : 49}%`}
        textalign="left"
        display="grid"
        alignitems="center"
        fontSize="1rem"
        fontWeight="normal"
        marginleft="5px"
        cursor="pointer"
        onClick={onHorseClick}>
        {`R ${eventNumber} - ${horseName}`}
      </HorseNameContainer>
      {winOrPlace && (
        <Container
          width="7%"
          display="grid"
          alignitems="center"
          color={theme.colors.purplePrimary}
          fontSize="0.8rem"
          fontWeight="bold">
          {winOrPlace === SELECTION_TYPES.WIN ? "(W)" : "(P)"}
        </Container>
      )}
      <Container
        marginleft="5px"
        width="30%"
        textalign="left"
        display="grid"
        alignitems="center"
        fontSize="0.8rem"
        paddingleft="1px">
        {formatInDecimal(odds)}
      </Container>
    </FlexContainer>
  );
};

export default SelectionRow;
