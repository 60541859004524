/* eslint-disable */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { AuthProvider, type AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import Store from "./store/store";
import theme from "./theme";
import NotificationProvider from "./components/views/notificationProvider";
import { loadConfig } from "./store/features/configSlice";
import MediaQueriesProvider from "./components/views/mediaQueriesProvider";
import App from "./App";
import { getCookie } from "./utils/cookieUtils";
import { SEARCHWORDS_FOR_PROTECTED_ROUTES_ON_REFRESH } from "./components/views/friendsLeagues/constants/friendsLeaguesConstants";

document.title =
  "Punters' Challenge - Horse Racing Predictor Game | Hollywoodbets";

const container = document.getElementById("root");
const root = createRoot(container!);

const checkForSeachParamsInURLAndUpdate =()=>{ 

 const isUrlWithExtendedSearchParams =  !!SEARCHWORDS_FOR_PROTECTED_ROUTES_ON_REFRESH.find(item => window.location.href.includes(item));
 
  const loggedIn = getCookie("Loggedin")
 
  if(isUrlWithExtendedSearchParams && loggedIn !== "true" ){  
    localStorage.setItem("pendingProtectedRoute",window.location.href)
  }

}

Store.dispatch(loadConfig()).then(() => {
  const config = Store.getState().config.data;

  const oidcConfig: AuthProviderProps = {
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    authority: `${config.identityUrl}`,
    client_id: "punterschallenge",
    redirect_uri: window.location.origin,
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin,
    response_type: "code",
    post_logout_redirect_uri: window.location.origin,
    scope: "openid offline_access api",
    filterProtocolClaims: true
  };

  const onSSOSignIn = (user) => {
    window.location.href = "/";
  };

  
  checkForSeachParamsInURLAndUpdate()

  root.render(
    //  <StrictMode>
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <AuthProvider {...oidcConfig} onSigninCallback={onSSOSignIn}>
          <MediaQueriesProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </MediaQueriesProvider>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
    //  </StrictMode>
  );
});
