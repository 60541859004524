import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import RunnerItem from "../runnerItem";
import type { RootState } from "../../../../store/store";
import FlexContainer from "../../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import Container from "../../../bricks/container";
import Spinner from "../../../bricks/spinner";
import theme from "../../../../theme";
import usePunterItemsCommon from "./usePunterItemsCommon";
import {
  BoxedValue,
  LabelDisplay,
  LabelValueContainer
} from "./puntersSelection.style";

interface IPuntersSelection {
  toOpen: boolean;
  winners?: string | number;
  second?: string | number;
  third?: string | number;
  punterId?: string | number;
}

const PuntersSelection = ({
  toOpen,
  winners,
  second,
  third,
  punterId
}: IPuntersSelection) => {
  const { columsWithIndices, data, setData, adjustItemsLengthsInColumns } =
    usePunterItemsCommon();

  const currentMeetingMetaData = useSelector(
    (state: RootState) => state.leaderboard.puntersSelections
  )[punterId!];

  const { isDesktop } = useContext(MediaQueriesContext).data;

  useEffect(() => {
    if (toOpen && !!currentMeetingMetaData) {
      adjustItemsLengthsInColumns(currentMeetingMetaData.length);
      setData(currentMeetingMetaData);
    }
  }, [currentMeetingMetaData]);

  return (
    <>
      {toOpen && !data && (
        <Container
          width="100%"
          position="relative"
          height="50px"
          paddingtop="10px"
          paddingbottom="10px"
          bgcolor={theme.colors.lightAliceBlue}>
          <Spinner width="50px" height="50px" />
        </Container>
      )}

      {toOpen && data && (
        <FlexContainer
          direction="column"
          width="100%"
          bgcolor={theme.colors.lightAliceBlue}
          height="auto"
          alignitems="center"
          border={`1px solid ${theme.colors.lightGray}`}
          borderbottomleftradius="5px"
          borderbottomrightradius="5px">
          {Number(winners) >= 0 && (
            <FlexContainer
              width="95%"
              justifycontent="space-between"
              margintop="8px">
              <LabelValueContainer>
                <LabelDisplay>Winners :</LabelDisplay>
                <BoxedValue>{winners}</BoxedValue>
              </LabelValueContainer>

              <LabelValueContainer>
                <LabelDisplay>2nd :</LabelDisplay>
                <BoxedValue>{second}</BoxedValue>
              </LabelValueContainer>

              <LabelValueContainer>
                <LabelDisplay>3rd :</LabelDisplay>
                <BoxedValue>{third}</BoxedValue>
              </LabelValueContainer>
            </FlexContainer>
          )}

          {isDesktop ? (
            <FlexContainer
              width="100%"
              justifycontent="center"
              margintop="7px"
              columngap="12px"
              marginbottom="5px">
              {columsWithIndices.map((indices, indx) => (
                <FlexContainer
                  key={`PunterItem_col${indices[indx]}`}
                  direction="column"
                  width={`${100 / columsWithIndices.length - 2}%`}
                  height="fit-content"
                  rowgap="10px">
                  {!!data &&
                    data.map((item, index) => {
                      if (index >= indices[0] && index <= indices[1])
                        return (
                          <RunnerItem
                            key={`RunnerItemCol_${item.raceNumber}_${item.saddle}_${item.horseName}_${item.points}`}
                            index={item.raceNumber}
                            horseNum={item.saddle}
                            horseName={item.horseName}
                            points={item.points}
                          />
                        );

                      return null;
                    })}
                </FlexContainer>
              ))}
            </FlexContainer>
          ) : (
            <FlexContainer
              width="100%"
              maxheight="100px"
              margintop="10px"
              marginbottom="10px"
              justifycontent="center"
              overflow="auto">
              <FlexContainer direction="column" rowgap="5px" width="95%">
                {!!data &&
                  data.map((item) => {
                    return (
                      <RunnerItem
                        key={`RunnerItemCol_${item.raceNumber}_${item.saddle}_${item.horseName}_${item.points}`}
                        index={item.raceNumber}
                        horseNum={item.saddle}
                        horseName={item.horseName}
                        points={item.points}
                      />
                    );
                  })}
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    </>
  );
};

export default PuntersSelection;
