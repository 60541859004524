import React from "react";
import ProfileIcon from "@assets/profile.svg";
import Container from "../../bricks/container";

interface IUserMenuIcon {
  width: string;
  height: string;
  callback: () => void;
  background?: string;
  color?: string;
  open: boolean;
}
const UserMenuIcon = ({
  width,
  height,
  background,
  color,
  callback,
  open
}: IUserMenuIcon) => {
  const innerColor = color ?? "#5C2D91";

  return (
    <Container
      bgcolor={background ?? "white"}
      width={width}
      height={height}
      borderradius={`calc(${width}/2)`}
      display="grid"
      placecontent="center">
      {open ? (
        <Container
          bgcolor="pink"
          display="grid"
          placecontent="center"
          marginbottom="2px"
          onClick={callback}>
          <Container
            position="relative"
            bgcolor="pink"
            width={`calc(${width}/1.6)`}>
            <Container
              position="absolute"
              width="100%"
              height={`calc(${height}*0.05)`}
              minheight="2px"
              bgcolor={innerColor}
              style={{ transform: "rotateY(0deg) rotate(45deg)" }}
            />
            <Container
              position="absolute"
              width="100%"
              height={`calc(${height}*0.05)`}
              minheight="2px"
              bgcolor={innerColor}
              style={{ transform: "rotateY(0deg) rotate(-45deg)" }}
            />
          </Container>
        </Container>
      ) : (
        <Container
          onClick={callback}
          width={width}
          height={width}
          position="relative"
          bgcolor="none"
          display="grid"
          placecontent="center">
          <img
            src={ProfileIcon}
            style={{ width: "100%", height: "100%", position: "absolute" }}
          />
        </Container>
      )}
    </Container>
  );
};

export default UserMenuIcon;
