import { IBetslipBetType } from "../../../../store/features/betslipSlice";
import { getOdds, getPayouts } from "../utils/luckypickUtils";
import {
  BET_TYPES,
  DEFAULT_STAKES,
  STAKE_DISTRIBUTION
} from "../constants/luckyPickConstants";
import { LP_ACTIONS } from "./luckyPickActions";
import { ILimitset } from "../../betslipNew/betslip.models";

export interface ILuckyPickBetType {
  horses: IBetslipBetType[];
  stake: number;
  odds: number;
  payout: number;
  initialStake: number;
  isPayoutOverLimit: boolean;
}

export interface IHorseSelectionOpenData {
  betType: string;
  toReplace: string;
}

export interface ILuckyPick {
  betTypeWinDouble: ILuckyPickBetType;
  betTypePlaceTreble: ILuckyPickBetType;
  betTypeWinPlaceDouble: ILuckyPickBetType;
  winDoubleCombos: IBetslipBetType[][];
  placeTrebleCombos: IBetslipBetType[][];
  winPlaceCombos: IBetslipBetType[][];
  winBetHorses: IBetslipBetType[];
  placeBetHorses: IBetslipBetType[];
  randomizer: { [key: string]: any };
  horseSelectionOpenData: IHorseSelectionOpenData | null;
  isInSummary: boolean;
  cancelCallback: () => void;
  limits: { [Key: string]: ILimitset } | null;
  isWinOnTopInWinPlaceDouble: boolean;
}

export const luckyPickReducerInitialState: ILuckyPick = {
  betTypeWinDouble: {
    horses: [],
    initialStake: DEFAULT_STAKES.WIN_DOUBLE,
    stake: DEFAULT_STAKES.WIN_DOUBLE,
    odds: 0,
    payout: 0,
    isPayoutOverLimit: false
  },
  betTypePlaceTreble: {
    horses: [],
    initialStake: 0,
    stake: 0,
    odds: 0,
    payout: 0,
    isPayoutOverLimit: false
  },
  betTypeWinPlaceDouble: {
    horses: [],
    initialStake: 0,
    stake: 0,
    odds: 0,
    payout: 0,
    isPayoutOverLimit: false
  },
  winDoubleCombos: [],
  placeTrebleCombos: [],
  winPlaceCombos: [],
  winBetHorses: [],
  placeBetHorses: [],
  randomizer: {},
  horseSelectionOpenData: null,
  isInSummary: false,
  cancelCallback: () => null,
  limits: null,
  isWinOnTopInWinPlaceDouble: true
};

type LpAction =
  | {
      type: LP_ACTIONS.UPDATE_BET_TYPE_WIN_DOUBLE;
      horses: IBetslipBetType[];
      stake?: number;
    }
  | {
      type: LP_ACTIONS.UPDATE_BET_TYPE_PLACE_TREBLE;
      horses: IBetslipBetType[];
      stake?: number;
    }
  | {
      type: LP_ACTIONS.UPDATE_BET_TYPE_WIN_PLACE_DOUBLE;
      horses: IBetslipBetType[];
      stake?: number;
    }
  | {
      type: LP_ACTIONS.REFRESH_SINGLE_HORSE_FOR_BETTYPE;
      bettype: string;
      toReplace: string;
      selectionType: string;
    }
  | {
      type: LP_ACTIONS.GENERATE_NEW;
    }
  | {
      type: LP_ACTIONS.DISTRIBUTE_STAKE;
      stake: number;
    }
  | {
      type: LP_ACTIONS.OPEN_HORSE_SELECTION;
      bettype: string;
      toReplace: string;
    }
  | {
      type: LP_ACTIONS.UPDATE_SELECTED_HORSE;
      betType: string;
      toReplace: string;
      horse: IBetslipBetType;
    }
  | {
      type: LP_ACTIONS.CLOSE_HORSE_SELECTION;
    }
  | {
      type: LP_ACTIONS.SHOW_SUMMARY;
      toShow: boolean;
    }
  | {
      type: LP_ACTIONS.UPDATE_CANCEL_FUNCTION;
      func: () => void;
    }
  | {
      type: LP_ACTIONS.SWITCH_WIN_PLACE_DOUBLE;
      func: () => void;
    };

const checkIfPayoutIsOverLimit = (
  bettype: string,
  horses: IBetslipBetType[],
  payout: number,
  limits: { [Key: string]: ILimitset }
) => {
  const hasFirstTimer = horses.find((horse) => horse.firstTimer);

  let limit = 0;
  if (
    bettype === BET_TYPES.WIN_DOUBLE ||
    bettype === BET_TYPES.WIN_PLACE_DOUBLE
  )
    limit = hasFirstTimer
      ? limits?.firstTimerLimits?.double
      : limits?.regularLimits?.double;
  if (bettype === BET_TYPES.PLACE_TREBLE)
    limit = hasFirstTimer
      ? limits?.firstTimerLimits?.treble
      : limits?.regularLimits?.treble;

  return payout > limit;
};

const getUpdatedState = (
  bettype: string,
  horsesInAction: IBetslipBetType[],
  stakeFromAction: number | undefined,
  betTypeData: ILuckyPickBetType,
  limits: { [Key: string]: ILimitset }
): {
  newHorses: IBetslipBetType[];
  newStake: number;
  newinitialStake: number;
  newPayout: number;
  isPayoutOverLimit: boolean;
} => {
  const newHorses = horsesInAction ? [...horsesInAction] : betTypeData.horses;
  const newStake = stakeFromAction ?? betTypeData.stake;
  const newinitialStake = stakeFromAction
    ? betTypeData.initialStake + (stakeFromAction - betTypeData.stake)
    : betTypeData.initialStake;
  const newPayout = getPayouts([...newHorses], newStake);
  const isPayoutOverLimit = checkIfPayoutIsOverLimit(
    bettype,
    newHorses,
    newPayout,
    limits!
  );

  return { newHorses, newStake, newinitialStake, newPayout, isPayoutOverLimit };
};

const getRandomizerRightRefrerher = (
  bettype: string,
  state: ILuckyPick
): Function | undefined => {
  if (bettype === BET_TYPES.PLACE_TREBLE)
    return state.randomizer.refreshPlaceTreble;
  if (bettype === BET_TYPES.WIN_DOUBLE)
    return state.randomizer.refreshWinDouble;
  if (bettype === BET_TYPES.WIN_PLACE_DOUBLE)
    return state.randomizer.refreshWinPlaceDouble;

  return undefined;
};

const areAllPlaceBetsAvailable = (state: ILuckyPick): boolean => {
  const { betTypePlaceTreble, betTypeWinPlaceDouble } = state;

  const isPlaceTrebleAvailable = betTypePlaceTreble.horses.length > 0;
  const isWinPlaceDoubleAvailable = betTypeWinPlaceDouble.horses.length > 0;

  return isPlaceTrebleAvailable && isWinPlaceDoubleAvailable;
};

const areAllPlaceBetsMissing = (state: ILuckyPick): boolean => {
  const { betTypePlaceTreble, betTypeWinPlaceDouble } = state;

  const isPlaceTrebleAvailable = betTypePlaceTreble.horses.length > 0;
  const isWinPlaceDoubleAvailable = betTypeWinPlaceDouble.horses.length > 0;

  return !isPlaceTrebleAvailable && !isWinPlaceDoubleAvailable;
};

const getNewStakeFactorForWinDouble = (state: ILuckyPick): number => {
  if (areAllPlaceBetsAvailable(state)) return STAKE_DISTRIBUTION.WIN_DOUBLE;
  if (areAllPlaceBetsMissing(state))
    return STAKE_DISTRIBUTION.WIN_DOUBLE_WITH_NO_PLACE_BET;

  return STAKE_DISTRIBUTION.WIN_DOUBLE_WITH_ONE_PLACE_BET;
};

const getNewStakeFactorForPlaceTreble = (state: ILuckyPick) => {
  const { betTypeWinPlaceDouble } = state;

  const isWinPlaceDoubleAvailable = betTypeWinPlaceDouble.horses.length > 0;
  if (!isWinPlaceDoubleAvailable)
    return STAKE_DISTRIBUTION.PLACE_TREBLE_AS_ONLY_PLACE_BET;

  return STAKE_DISTRIBUTION.PLACE_TREBLE;
};

const getNewStakeFactorForWinPlaceDouble = (state: ILuckyPick) => {
  const { betTypePlaceTreble } = state;
  const isPlaceTrebleAvailable = betTypePlaceTreble.horses.length > 0;
  if (!isPlaceTrebleAvailable)
    return STAKE_DISTRIBUTION.WIN_PLACE_DOUBLE_AS_ONLY_PLACE_BET;

  return STAKE_DISTRIBUTION.WIN_PLACE_DOUBLE;
};

const LuckyPickReducer = (state: ILuckyPick, action: LpAction): ILuckyPick => {
  switch (action.type) {
    case LP_ACTIONS.UPDATE_BET_TYPE_WIN_DOUBLE: {
      const {
        newHorses,
        newStake,
        newinitialStake,
        newPayout,
        isPayoutOverLimit
      } = getUpdatedState(
        BET_TYPES.WIN_DOUBLE,
        action.horses,
        action.stake,
        state.betTypeWinDouble,
        state.limits!
      );

      return {
        ...state,
        betTypeWinDouble: {
          horses: newHorses,
          odds: getOdds([...newHorses]),
          payout: newPayout,
          stake: newStake,
          initialStake: newinitialStake,
          isPayoutOverLimit
        }
      };
    }
    case LP_ACTIONS.UPDATE_BET_TYPE_PLACE_TREBLE: {
      const {
        newHorses,
        newStake,
        newinitialStake,
        newPayout,
        isPayoutOverLimit
      } = getUpdatedState(
        BET_TYPES.PLACE_TREBLE,
        action.horses,
        action.stake,
        state.betTypePlaceTreble,
        state.limits!
      );
      return {
        ...state,
        betTypePlaceTreble: {
          horses: newHorses,
          odds: getOdds([...newHorses]),
          payout: newPayout,
          stake: newStake,
          initialStake: newinitialStake,
          isPayoutOverLimit
        }
      };
    }
    case LP_ACTIONS.UPDATE_BET_TYPE_WIN_PLACE_DOUBLE: {
      const {
        newHorses,
        newStake,
        newinitialStake,
        newPayout,
        isPayoutOverLimit
      } = getUpdatedState(
        BET_TYPES.WIN_PLACE_DOUBLE,
        action.horses,
        action.stake,
        state.betTypeWinPlaceDouble,
        state.limits!
      );

      return {
        ...state,
        betTypeWinPlaceDouble: {
          horses: newHorses,
          odds: getOdds([...newHorses]),
          payout: newPayout,
          stake: newStake,
          initialStake: newinitialStake,
          isPayoutOverLimit
        }
      };
    }
    case LP_ACTIONS.REFRESH_SINGLE_HORSE_FOR_BETTYPE: {
      const refresherFunction = getRandomizerRightRefrerher(
        action.bettype,
        state
      );

      if (refresherFunction) {
        refresherFunction(action.toReplace);
      }

      return {
        ...state
      };
    }
    case LP_ACTIONS.GENERATE_NEW: {
      state.randomizer.generateWinDouble();
      state.randomizer.generatePlaceTreble();
      state.randomizer.generateWinPlaceDouble();

      return {
        ...state
      };
    }
    case LP_ACTIONS.DISTRIBUTE_STAKE: {
      const { betTypeWinDouble, betTypePlaceTreble, betTypeWinPlaceDouble } =
        state;

      const newStakeForWinDouble =
        betTypeWinDouble.initialStake +
        action.stake * getNewStakeFactorForWinDouble(state);
      const newStakeForPlaceTreble = betTypePlaceTreble.horses.length
        ? betTypePlaceTreble.initialStake +
          action.stake * getNewStakeFactorForPlaceTreble(state)
        : 0;
      const newStakeForWinPlaceDouble = betTypeWinPlaceDouble.horses.length
        ? betTypeWinPlaceDouble.initialStake +
          action.stake * getNewStakeFactorForWinPlaceDouble(state)
        : 0;

      const newPayOutForWinDouble = getPayouts(
        [...betTypeWinDouble.horses],
        newStakeForWinDouble
      );
      const newPayOutForPlaceTreble = getPayouts(
        [...betTypePlaceTreble.horses],
        newStakeForPlaceTreble
      );
      const newPayOutFoWinPlaceDouble = getPayouts(
        [...betTypeWinPlaceDouble.horses],
        newStakeForWinPlaceDouble
      );

      const isOverLimitFoWinDouble = checkIfPayoutIsOverLimit(
        BET_TYPES.WIN_DOUBLE,
        betTypeWinDouble.horses,
        newPayOutForWinDouble,
        state.limits!
      );
      const isOverLimitForPlaceTreble = checkIfPayoutIsOverLimit(
        BET_TYPES.PLACE_TREBLE,
        betTypePlaceTreble.horses,
        newPayOutForPlaceTreble,
        state.limits!
      );
      const isOverLimitForWinPlaceDouble = checkIfPayoutIsOverLimit(
        BET_TYPES.WIN_PLACE_DOUBLE,
        betTypeWinPlaceDouble.horses,
        newPayOutFoWinPlaceDouble,
        state.limits!
      );

      const newBetTypeWinDouble = {
        ...betTypeWinDouble,
        stake: newStakeForWinDouble,
        payout: newPayOutForWinDouble,
        isPayoutOverLimit: isOverLimitFoWinDouble
      };
      const newBetTypePlaceTreble = {
        ...betTypePlaceTreble,
        stake: newStakeForPlaceTreble,
        payout: newPayOutForPlaceTreble,
        isPayoutOverLimit: isOverLimitForPlaceTreble
      };
      const newBetTypeWinPlaceDouble = {
        ...betTypeWinPlaceDouble,
        stake: newStakeForWinPlaceDouble,
        payout: newPayOutFoWinPlaceDouble,
        isPayoutOverLimit: isOverLimitForWinPlaceDouble
      };

      return {
        ...state,
        betTypeWinDouble: { ...newBetTypeWinDouble },
        betTypePlaceTreble: { ...newBetTypePlaceTreble },
        betTypeWinPlaceDouble: { ...newBetTypeWinPlaceDouble }
      };
    }
    case LP_ACTIONS.OPEN_HORSE_SELECTION: {
      const newState = action.bettype
        ? { betType: action.bettype, toReplace: action.toReplace }
        : null;

      return {
        ...state,
        horseSelectionOpenData: newState
      };
    }
    case LP_ACTIONS.UPDATE_SELECTED_HORSE: {
      state.randomizer.handleSingleHorseSelectedFromOutside(
        action.betType,
        action.toReplace,
        action.horse.eventDetailName
      );

      return {
        ...state,
        horseSelectionOpenData: null
      };
    }
    case LP_ACTIONS.CLOSE_HORSE_SELECTION: {
      return {
        ...state,
        horseSelectionOpenData: null
      };
    }
    case LP_ACTIONS.SHOW_SUMMARY: {
      return {
        ...state,
        isInSummary: action.toShow
      };
    }
    case LP_ACTIONS.UPDATE_CANCEL_FUNCTION: {
      return {
        ...state,
        cancelCallback: action.func
      };
    }
    case LP_ACTIONS.SWITCH_WIN_PLACE_DOUBLE: {
      state.randomizer.switchWinPlaceDouble();
      return {
        ...state,
        isWinOnTopInWinPlaceDouble: !state.isWinOnTopInWinPlaceDouble
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default LuckyPickReducer;
