import { memo } from "react";
import theme from "../../../../theme";
import openLink from "../../../../utils/pageUtils";
import Container from "../../../bricks/container";
import Image from "../../../bricks/image";
import { isAndroid } from "../../../../utils/deviceUtils";

interface ISocialMediaIcons {
  imageSrc: any;
  urlLink: string;
  imageWidth?: string;
  imageHeight?: string;
  toCheckForAndroid?: boolean;
  androidLink?: string;
}

const SocialMediaIcon = ({
  imageSrc,
  urlLink,
  imageWidth,
  imageHeight,
  androidLink,
  toCheckForAndroid
}: ISocialMediaIcons) => {
  const handleClick = () => {
    if (isAndroid() && toCheckForAndroid && androidLink) {
      window.open(androidLink, "_blank");
    } else {
      openLink(urlLink);
    }
  };
  return (
    <Container
      border={`1px solid ${theme.colors.purplePrimary}`}
      width="30px"
      height="30px"
      borderradius="15px"
      cursor="pointer"
      onClick={handleClick}>
      <Image
        src={imageSrc}
        width={imageWidth ?? "100%"}
        height={imageHeight ?? "100%"}
      />
    </Container>
  );
};

export default memo(SocialMediaIcon);
