import BrokenShoe from "@assets/horse-shoe-broken-icon.svg";
import Medal from "@assets/leaderboard-1st-place.svg";
import { useContext } from "react";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import Container from "../../../bricks/container";
import Image from "../../../bricks/image";

interface IMedalOrShoe {
  isForMiniLeagues: boolean;
  rank: number;
  isLast: boolean;
}

const MedalOrShoe = ({ isForMiniLeagues, rank, isLast }: IMedalOrShoe) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const toShowFirstPositionIcon = () => {
    return Number(rank) === 1;
  };

  const toShowLastPositionIcon = () => {
    return isLast && Number(rank) !== 1;
  };

  return isForMiniLeagues ? (
    <>
      {!isDesktop && toShowFirstPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image src={Medal} maxHeight="22px" width="50px" />
        </Container>
      )}
      {!isDesktop && toShowLastPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={BrokenShoe}
            maxHeight="15px"
            transform="scale(0.7)"
            width="50px"
          />
        </Container>
      )}

      {isDesktop && toShowFirstPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={Medal}
            maxHeight="min(50px,3vw)"
            paddingRight={32}
            maxWidth="50px"
            width="50px"
          />
        </Container>
      )}

      {isDesktop && toShowLastPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={BrokenShoe}
            maxHeight="min(50px,3vw)"
            transform="scale(0.7)"
            paddingRight={32}
            maxWidth="50px"
            width="50px"
          />
        </Container>
      )}
    </>
  ) : null;
};

export default MedalOrShoe;
