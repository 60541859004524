import { useContext } from "react";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import useNavigation from "../../../../hooks/useNavigation";
import theme from "../../../../theme";
import Button from "../../../bricks/button";

const HowToPlayControl = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const { toNavigate } = useNavigation();

  const handleClick = () => {
    toNavigate("Learn How to Play");
  };
  return isDesktop ? (
    <Button
      text="How To Play"
      width="130px"
      height="30px"
      fontcolor="white"
      border="1px solid white"
      backgroundcolor={theme.colors.purpleSecondary}
      fontWeight="none"
      borderradius="5px"
      onClick={handleClick}
    />
  ) : (
    <Button
      text="How To Play"
      width="73%"
      height="30px"
      backgroundcolor={theme.colors.lightGrey3}
      fontcolor="black"
      margintop="30px"
      onClick={handleClick}
    />
  );
};
export default HowToPlayControl;
