import FlexContainer from "../../../../bricks/flexContainer";
import Button from "../../../../bricks/button";
import theme from "../../../../../theme";

interface IActionButtons {
  cancelCallback: () => void;
  confirmCallback: () => void;
  toDisableconfirmButton: boolean;
  confirmButtonText: string;
  backgroundColor?: string;
}

const ActionButtons = ({
  cancelCallback,
  confirmCallback,
  toDisableconfirmButton,
  confirmButtonText,
  backgroundColor = "none"
}: IActionButtons) => {
  return (
    <FlexContainer
      width="100%"
      justifycontent="center"
      columngap="20px"
      paddingbottom="20px"
      paddingtop="20px"
      bgcolor={backgroundColor}>
      <Button
        width="30%"
        height="2.7rem"
        backgroundcolor={theme.colors.primary}
        fontcolor={theme.colors.purplePrimary}
        text="Cancel"
        border={`1px solid ${theme.colors.purplePrimary}`}
        fontSize="1rem"
        onClick={cancelCallback}
      />

      <Button
        fontSize="1rem"
        fontWeight="normal"
        height="2.7rem"
        width="30%"
        disabled={toDisableconfirmButton}
        gradient={{
          colors: [
            `${theme.colors.purpleSecondary}`,
            `${theme.colors.purplePrimary}`
          ],
          direction: "linear"
        }}
        text={confirmButtonText}
        onClick={confirmCallback}
      />
    </FlexContainer>
  );
};

export default ActionButtons;
