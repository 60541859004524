import { useSelector } from "react-redux";
import { getBalanceFormatted } from "../../../../../utils/currencyFormatUtils";
import Container from "../../../../bricks/container";
import { BalanceLabel, FundsLabel } from "./betSlipSubmission.style";
import { RootState } from "../../../../../store/store";

interface IBetSlipSubmissionBalance {
  currentStake: number;
}

const BetSlipSubmissionBalance = ({
  currentStake
}: IBetSlipSubmissionBalance) => {
  const balances = useSelector((state: RootState) => state.user.balance);

  const canConfirmABet = (): boolean => {
    const balanceAvailable = balances?.balanceAvailable as number;
    const balance = Math.abs(balanceAvailable);
    const isFundsAvailable = balance > 0 && currentStake <= balance;

    return isFundsAvailable;
  };

  return (
    <Container display="block">
      <BalanceLabel canConfirmBet={canConfirmABet()}>
        {`You have ${getBalanceFormatted(
          Math.abs(balances?.balanceAvailable ?? 0)
        )} in your Hollywoodbets account.`}
        <br />
      </BalanceLabel>
      {!canConfirmABet && (
        <div>
          <FundsLabel>
            You don't have sufficient funds to place this bet, please top up
            your account.
          </FundsLabel>
        </div>
      )}
      <BalanceLabel canConfirmBet>
        {currentStake >= 50
          ? "Your bet of R50 or more qualifies you for the DOUBLE YOUR MONEY bonus!"
          : "Your total stake is under R50. Place a bet of R50 or more for the DOUBLE YOUR MONEY bonus!"}
      </BalanceLabel>
    </Container>
  );
};

export default BetSlipSubmissionBalance;
