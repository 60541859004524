export enum BURGER_MENU_ITEMS {
  FriendsLeague,
  Home,
  Leaderboard,
  LearnToPlay,
  Login,
  Logout,
  Profile,
  Register,
  Results
}

export const burgerItemText: { [key in BURGER_MENU_ITEMS]: string } = {
  [BURGER_MENU_ITEMS.FriendsLeague]: "Friends Challenge",
  [BURGER_MENU_ITEMS.Home]: "Home",
  [BURGER_MENU_ITEMS.Leaderboard]: "Leaderboard",
  [BURGER_MENU_ITEMS.LearnToPlay]: "Learn To Play",
  [BURGER_MENU_ITEMS.Login]: "Login",
  [BURGER_MENU_ITEMS.Logout]: "Logout",
  [BURGER_MENU_ITEMS.Profile]: "Profile",
  [BURGER_MENU_ITEMS.Register]: "Register",
  [BURGER_MENU_ITEMS.Results]: "Results"
};

export const OFFLINE_ITEMS: BURGER_MENU_ITEMS[] = [
  BURGER_MENU_ITEMS.Home,
  BURGER_MENU_ITEMS.Login,
  BURGER_MENU_ITEMS.Register
];

export const ONLINE_ITEMS: BURGER_MENU_ITEMS[] = [
  BURGER_MENU_ITEMS.Home,
  BURGER_MENU_ITEMS.Leaderboard,
  BURGER_MENU_ITEMS.Results,
  BURGER_MENU_ITEMS.FriendsLeague,
  BURGER_MENU_ITEMS.LearnToPlay,
  BURGER_MENU_ITEMS.Logout
];

export const ONLINE_ITEMS_PRE_LEAGUE: BURGER_MENU_ITEMS[] = [
  BURGER_MENU_ITEMS.Home,
  BURGER_MENU_ITEMS.Leaderboard,
  BURGER_MENU_ITEMS.Results,
  BURGER_MENU_ITEMS.LearnToPlay,
  BURGER_MENU_ITEMS.Logout
];
