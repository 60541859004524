import styled from "styled-components";
import theme from "../../../theme";

export const HowToPlayLinkButton = styled.div`
  background-color: transparent;
  border-radius: 8px;
  border: ${(props) => `3px solid ${props.theme.colors.darkGrey2}`};
  width: 98%;
  height: 65px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: right;
`;

export const HowToPlayButtonLogo = styled.img`
  width: 45%;
  height: 55%;
  align-content: flex-end;
`;

export const LeftArrow = styled.span`
  color: white;
  font-size: ${theme.font.size.sm};
  font-family: poppins;
  width: 40%;
  height: 35px;
  padding-right: 20px;
  display: grid;
  align-items: center;
  font-weight: 800;
`;

export const HowToPlayButtonText = styled.span`
  font-size: ${theme.font.size.s};
  color: white;
  width: 84%;
  padding-right: 50%;
  font-weight: 600;
`;
