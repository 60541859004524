import styled from "styled-components";
import theme from "../../../theme";

interface IBackgroundDiv {
  heightOffset: string;
}

interface IRegistrationPositionDiv {
  xOffset: string;
  yOffset: string;
}

export const ModalContainer = styled.div`
  position: relative;
  height: 0px;
`;

export const RegistrationContainerPosition = styled.div<IRegistrationPositionDiv>`
  background-color: white;
  border-radius: 10px;
  max-width: 350px;
  height: fit-content;
  padding: 10px 20px 10px;
  margin: 10px 10px 10px;
  position: relative;
  z-index: 101;
  top: ${(props) => props.yOffset};
  left: ${(props) => props.xOffset};
`;

interface IRegistrationModalProps {
  isDesktop: boolean;
}

export const RegistrationContainer = styled.div<IRegistrationModalProps>`
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  font-size: 10px;
  text-align: left;
`;

export const ImageContainer = styled.div<IRegistrationModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: ${(props) => (props.isDesktop ? "12vw" : "50vw")};
`;

export const IconImage = styled.img`
  height: 50px;
  width: 50px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: fill;
`;

export const PurpleText = styled.b`
  color: ${theme.colors.titleAndTextPurple};
`;

export const ButtonContainer = styled.button`
  width: 50%;
  height: 35px;
  background-image: linear-gradient(
    ${theme.colors.purplePrimary},
    ${theme.colors.purpleSecondary}
  );
  color: white;
  text-align: center;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const RefContainer = styled.a`
  href: /home;
  text-decoration: underline;
  color: grey;
  text-align: center;
  cursor: pointer;
`;

export const LinkContainer = styled.div<IRegistrationModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  word-wrap: break-word;
  row-gap: 16px;
  width: 100%;
`;

export const BackgroundShadeOverlay = styled.div<IBackgroundDiv>`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  top: ${(props) => props.heightOffset};
  left: 0;
  position: fixed;
  z-index: 100;
`;
