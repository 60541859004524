import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";

import HorseNumber from "./horseNumber";
import Horsecard from "./horseCard";
import type { RootState } from "../../../store/store";
import {
  type IMeeting,
  type ISavePunterSelectionReqData,
  IRaceRunner,
  IMeetingRace
} from "../../../store/features/meetingsSlice";
import {
  FORMATS,
  getFormatted,
  timeRemainingInMins
} from "../../../utils/dateUtils";
import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";

import openLink from "../../../utils/pageUtils";
import { NotificationContext } from "../../../contexts/notificationContext";
import {
  RACE_SELECTION_CACHE,
  SELECTION_CUTOFF_TIME_IN_MINUTES
} from "../../../constants";

import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import { setInitialState } from "../../../store/features/newBetSlipSlice";
import {
  ButtonFlexContainer,
  CardColumnFlexContainer,
  LoadingStyle,
  MeetingDateTimeDesktopContainer,
  QuickpickDesktopContainer,
  TakeAbetTipsDesktopContainer
} from "./selection.style";
import theme from "../../../theme";

interface ISelectionArea {
  color: string;
  setSubmitData: (
    enable: boolean,
    data: ISavePunterSelectionReqData[],
    hasInteracted: boolean,
    wasQuickPicked: boolean
  ) => void;
  takeaBetCallback: () => void;
}

const Selection = ({
  color,
  setSubmitData,
  takeaBetCallback
}: ISelectionArea) => {
  const { notificationUpdater } = useContext(NotificationContext);
  const [selectedRaces, setSelectedRaces] = useState<
    ISavePunterSelectionReqData[]
  >([]);
  const dispatch = useDispatch();

  const { isDesktop } = useContext(MediaQueriesContext).data;

  const blankBetslip = () => {
    dispatch(
      setInitialState({
        allowBet: false,
        bets: [],
        meeting: {
          meetingDate: "",
          meetingId: 0,
          meetingName: "EMPTY"
        },
        races: [],
        limits: {},
        forcedSP: false
      })
    );
  };

  const hasInteracted = useRef(false);

  const currentSelectedMeetingId = useSelector(
    (state: RootState) => state.meetings.currentSelectedMeetingId
  );
  const allMeetingsMetaData = useSelector(
    (state: RootState) => state.meetings.meetingsMetaData
  );
  const allMeetings = useSelector(
    (state: RootState) => state.meetings.meetings
  );
  const allProgressiveWinners = useSelector(
    (state: RootState) => state.meetings.progressiveWinners
  );
  const allSelections = useSelector(
    (state: RootState) => state.meetings.selections
  );
  const punterId = useSelector(
    (state: RootState) => state.user.data?.user?.punterId
  );

  const selectedMeetingData: IMeeting = allMeetings[currentSelectedMeetingId];

  const meetingName = allMeetingsMetaData
    ? allMeetingsMetaData?.find(
        (meet) => meet.meetingId === currentSelectedMeetingId
      )?.raceCourseName
    : "";
  const meetingDateTime: string = selectedMeetingData?.meetingDate;

  const [toShowSelectionsClosed, setToShowSelectionsClosed] =
    useState<boolean>(false);

  const cachedSelectedRaces = (): ISavePunterSelectionReqData[] => {
    const parsedData = localStorage.getItem(
      currentSelectedMeetingId + RACE_SELECTION_CACHE
    );

    if (parsedData != null) return JSON.parse(parsedData);
    return [];
  };

  let savedSelections;

  const selectionCloseTimer = useRef<any>(-1);
  const selectionsHaveScratched = useRef<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scratchedInSelectionsList = useRef<Record<number, any> | null>(null);

  const wasQuickPicked = useRef<boolean>(false);

  useEffect(() => {
    if (selectionCloseTimer.current > -1) {
      clearTimeout(selectionCloseTimer.current);
      selectionCloseTimer.current = -1;
    }

    if (selectedMeetingData && meetingDateTime) {
      if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft = 0;

      const remainingTimeTime = timeRemainingInMins(meetingDateTime);
      const remainingTimeReducingCutoffTime =
        remainingTimeTime - SELECTION_CUTOFF_TIME_IN_MINUTES;

      if (remainingTimeReducingCutoffTime > 0) {
        setToShowSelectionsClosed(false);

        selectionCloseTimer.current = setTimeout(
          () => {
            setToShowSelectionsClosed(true);
            clearTimeout(selectionCloseTimer.current);
            notificationUpdater({
              status: "warning",
              message: " Selection has now closed for this meeting",
              duration: 3000
            });
          },
          remainingTimeReducingCutoffTime * 60 * 1000
        );
      } else {
        setToShowSelectionsClosed(true);
      }
    }
  }, [selectedMeetingData]);

  useEffect(
    () => () => {
      if (selectionCloseTimer.current > -1) {
        clearTimeout(selectionCloseTimer.current);
      }
    },
    []
  );

  useEffect(() => {
    if (allSelections) {
      savedSelections = allSelections[currentSelectedMeetingId];

      hasInteracted.current = false;
      selectionsHaveScratched.current = false;
      if (savedSelections?.length) {
        savedSelections.forEach((item, index) => {
          const selectedRunner = selectedMeetingData.races[index].runners.find(
            (runner) => runner.saddle === item.saddle
          );

          if (selectedRunner?.runnerStatus !== 1) {
            selectionsHaveScratched.current = true;

            if (!scratchedInSelectionsList.current)
              scratchedInSelectionsList.current = {};

            if (selectedRunner !== undefined) {
              scratchedInSelectionsList.current[
                Number(selectedRunner?.raceNumber)
              ] = selectedRunner;
            }
          }
        });

        const formatted = savedSelections?.map((item) => ({
          meetingId: Number(currentSelectedMeetingId),
          punterId,
          raceNumber: Number(item.raceNumber),
          selection: Number(item.saddle),
          horseName: item.horseName,
          isValid: true
        }));

        setSelectedRaces([...formatted]);
      } else if (cachedSelectedRaces().length) {
        const formatted = cachedSelectedRaces()
          .filter((selectedRace) => {
            return selectedRace ?? {};
          })
          .map((item) => {
            return {
              meetingId: Number(currentSelectedMeetingId),
              raceNumber: Number(item?.raceNumber),
              punterId,
              horseName: item?.horseName,
              selection: Number(item?.selection),
              isValid: item?.isValid
            };
          }, []);

        setSelectedRaces(formatted);
        hasInteracted.current = true;
      } else {
        setSelectedRaces([]);
      }
    }
  }, [allSelections, currentSelectedMeetingId]);

  const maxHorsesRender = () => {
    const maxHorses = () => {
      let longest: any[] = [];

      selectedMeetingData?.races.forEach((race) => {
        if (race.runners?.length > longest.length) longest = [...race.runners];
      });

      return longest;
    };

    const maxHorsesData = maxHorses().map((item, index) => (
      <HorseNumber
        key={`Horse${item.horseName}${item.raceNumber}${item.saddle}`}
        value={index + 1}
      />
    ));
    return maxHorsesData;
  };

  const getWinnerTotalSelect = (
    hasResulted: boolean,
    runners: IRaceRunner[]
  ) => {
    const { raceNumber } = runners[0];
    const thisRaceFiltered = allProgressiveWinners.progressiveWinner.filter(
      (item) => item.raceNumber === Number(raceNumber)
    );
    const thisRaceWinners =
      thisRaceFiltered.length === 0 ? null : thisRaceFiltered[0].winners;

    if (thisRaceWinners != null) {
      return <>{thisRaceWinners.toLocaleString()}</>;
    }
    return <>-</>;
  };

  let totalOdds = 0;

  const finalData = () => {
    const final = selectedMeetingData
      ? JSON.parse(JSON.stringify(selectedMeetingData))
      : null;
    let scrachedRunnerObjects: IRaceRunner[] | null = [];
    let proxyOfRace;
    final?.races.forEach((race: IMeetingRace) => {
      proxyOfRace = race;
      proxyOfRace.resulted =
        !!proxyOfRace.runners.find(
          (runner) => Number(runner.finishingPosition) > 0
        ) || proxyOfRace.resulted === true;
      scrachedRunnerObjects = [];

      proxyOfRace.favoriteHorse = "";
      let currentOdd = 1000000000;
      for (let i = race.runners.length - 1; i >= 0; i--) {
        const runner = race.runners[i];

        if (
          !race.resulted &&
          Number(runner.hollywoodOdds) > 0 &&
          Number(runner.hollywoodOdds) < currentOdd
        ) {
          currentOdd = Number(runner.hollywoodOdds);
          proxyOfRace.favoriteHorse = runner.horseName;
        }

        if (race.resulted && runner.favourite) {
          proxyOfRace.favoriteHorse = runner.horseName;
        }

        if (
          proxyOfRace.resulted &&
          runner.finishingPosition === 0 &&
          runner.horsePoints === 0
        ) {
          scrachedRunnerObjects?.push(...proxyOfRace.runners.splice(i, 1));
        }
      }

      race.runners.sort((p1, p2) => {
        if (p1.finishingPosition > p2.finishingPosition) {
          return 1;
        }
        if (p1.finishingPosition < p2.finishingPosition) {
          return -1;
        }

        return 0;
      });

      if (scrachedRunnerObjects?.length) {
        scrachedRunnerObjects.reverse();
        proxyOfRace.runners = [
          ...proxyOfRace.runners,
          ...scrachedRunnerObjects
        ];
      }
    });

    final?.races.forEach((race: IMeetingRace) => {
      race?.runners.forEach((runner) => {
        totalOdds += Number(runner.hollywoodOdds);
      });
    });

    return final;
  };

  const getSelecedRaceItemObject = (
    horse: any
  ): ISavePunterSelectionReqData => ({
    meetingId: Number(selectedMeetingData.meetingId),
    punterId: Number(punterId),
    raceNumber: Number(horse.raceNumber),
    selection: Number(horse.saddle),
    horseName: horse.horseName,
    isValid: true
  });

  const updateCurrentScrathedListOnSelection = (raceNumber: number) => {
    if (
      scratchedInSelectionsList?.current &&
      scratchedInSelectionsList?.current[raceNumber]
    ) {
      delete scratchedInSelectionsList.current[raceNumber];

      if (Object.keys(scratchedInSelectionsList.current).length <= 0)
        selectionsHaveScratched.current = false;
    }
  };

  const onCardClick = (race: IMeetingRace, horse: any) => {
    wasQuickPicked.current = false;
    hasInteracted.current = true;
    updateCurrentScrathedListOnSelection(Number(race.raceNumber));

    if (race.resulted || toShowSelectionsClosed || horse.runnerStatus !== 1)
      return;
    const ammendedSelectionList = [...selectedRaces];

    if (
      ammendedSelectionList[horse.raceNumber - 1] &&
      ammendedSelectionList[horse.raceNumber - 1].horseName === horse.horseName
    ) {
      delete ammendedSelectionList[horse.raceNumber - 1];
    } else {
      ammendedSelectionList[horse.raceNumber - 1] =
        getSelecedRaceItemObject(horse);
    }

    localStorage.setItem(
      currentSelectedMeetingId + RACE_SELECTION_CACHE,
      JSON.stringify(ammendedSelectionList)
    );

    setSelectedRaces([...ammendedSelectionList]);
  };

  const getRandom = (min: number, max: number) =>
    Math.round(Math.random() * (max - min) + min);

  const doQuickPick = () => {
    if (toShowSelectionsClosed) {
      notificationUpdater({
        status: "warning",
        message: " Selection has closed for this meeting",
        duration: 1000
      });
      window.scrollTo({ top: 0 });
      return;
    }

    let listWithoutScratch;
    const ammendedSelectionList = [...selectedRaces];
    selectedMeetingData.races.forEach((race, index) => {
      listWithoutScratch = race.runners.filter(
        (horse) => horse.runnerStatus === 1
      );
      const random = getRandom(0, listWithoutScratch.length - 1);
      const randomHorseObject = listWithoutScratch[random];
      ammendedSelectionList[index] =
        getSelecedRaceItemObject(randomHorseObject);
    });
    hasInteracted.current = true;

    localStorage.setItem(
      `${currentSelectedMeetingId}selectedRaces`,
      JSON.stringify(ammendedSelectionList)
    );
    setSelectedRaces([...ammendedSelectionList]);
    wasQuickPicked.current = true;
    blankBetslip();
  };

  const onTakeABetClick = () => {
    if (!selectionsHaveScratched.current) {
      takeaBetCallback();
    } else {
      notificationUpdater({
        status: "error",
        message:
          "A horse that you have selected is scratched , please choose a different horse.",
        duration: 4000
      });
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    if (
      selectedRaces?.length === selectedMeetingData?.races?.length &&
      selectedRaces.findIndex((item) => !item) < 0
    ) {
      setSubmitData(
        selectedMeetingData?.races?.length === selectedRaces?.length &&
          !toShowSelectionsClosed,
        selectedRaces,
        hasInteracted.current,
        wasQuickPicked.current
      );
    } else {
      setSubmitData(
        false,
        selectedRaces,
        hasInteracted.current,
        wasQuickPicked.current
      );
    }
  }, [selectedRaces]);

  const isSelected = (race: any, horse: any): boolean =>
    selectedRaces[race.raceNumber - 1]?.selection === horse.saddle;

  const config = useSelector((state: RootState) => state.config.data);

  const handleTips = () => {
    switch (meetingName) {
      case "Hollywoodbets Greyville":
      case "Hollywoodbets Scottsville":
        openLink(`${config.blogKZNUrl}`);
        break;

      case "Turffontein":
      case "Vaal":
        openLink(`${config.blogGautengUrl}`);
        break;

      case "Hollywoodbets Kenilworth":
      case "Hollywoodbets Durbanville":
        openLink(`${config.blogCPTUrl}`);
        break;

      case "Kranji":
        openLink(`${config.blogSingaporeUrl}`);
        break;

      case "Fairview":
        openLink(`${config.blogFairviewUrl}`);
        break;

      case "Happy Valley":
      case "Sha Tin":
        openLink(`${config.blogHongKongUrl}`);
        break;

      default:
        openLink(`${config.blogUrl}`);
        break;
    }
  };

  const finalDataRender = () => {
    const final = finalData()?.races.map(
      (race: IMeetingRace, index: number) => (
        <CardColumnFlexContainer
          key={`race${race.raceNumber}-${race.raceTime}`}
          direction="column"
          rowgap="8px"
          color="black">
          <FlexContainer
            width="94%"
            direction="column"
            bgcolor=""
            paddingleft="5px"
            alignitems="left"
            marginbottom="0px"
            height="max-height"
            rowgap="3px">
            <Container
              bgcolor=""
              textalign="left"
              fontSize="1rem"
              width="100%"
              lineheight="1"
              fontWeight="bold">
              {`Race ${index + 1}`}
            </Container>
            <Container
              fontSize="0.8rem"
              textalign="left"
              marginright="0px"
              color="red"
              height="max-content"
              lineheight="1">
              {`${
                race.resulted
                  ? "Result"
                  : getFormatted(race.raceTime.toString(), FORMATS["HH:MM"])
              }`}
            </Container>
            <Container
              fontSize="0.8rem"
              textalign="left"
              marginright="0px"
              color="black"
              height="max-content"
              fontWeight="bold"
              lineheight="2">
              {getWinnerTotalSelect(race.resulted, race.runners)}
            </Container>
          </FlexContainer>

          {race.runners.map((horse: IRaceRunner, indx: number) => (
            <Horsecard
              key={horse.horseName}
              callback={() => {
                onCardClick(race, horse);
              }}
              index={indx}
              saddleNumber={Number(horse.saddle)}
              name={horse.horseName}
              odds={horse.hollywoodOdds}
              totalSelected={horse.totalSelected}
              isScratched={horse.runnerStatus !== 1}
              isSelected={isSelected(race, horse)}
              finishingPosition={horse.finishingPosition}
              isClosed={toShowSelectionsClosed}
              horsePoints={Number(horse.horsePoints)}
              isPending={toShowSelectionsClosed}
              isReserve={horse.hollywoodOdds === 0}
              isfavorite={race.favoriteHorse === horse.horseName}
              totalOdds={totalOdds}
            />
          ))}
        </CardColumnFlexContainer>
      )
    );
    return final;
  };

  return (
    <FlexContainer
      bgcolor="white"
      direction="column"
      rowgap="8px"
      height="auto"
      width="100%"
      borderradius="6px"
      border={`2px solid ${color}`}>
      {!isDesktop && (
        <FlexContainer
          bgcolor={color}
          direction="column"
          rowgap="5px"
          paddingbottom="8px"
          width="100%"
          height="auto"
          bordertopleftradius="4px"
          bordertoprightradius="4px"
          paddingtop="3px"
          alignitems={`${theme.font.align.center}`}>
          <Container
            width="max-content"
            lineheight="1"
            color="white"
            fontSize="min('1.1rem','2rem')"
            fontWeight={`${theme.font.weight.bold}`}>
            {meetingName}
          </Container>

          <Container
            width="max-content"
            color="white"
            fontSize="0.7rem"
            margintop="-3px">
            {getFormatted(meetingDateTime, FORMATS["D, DT FM FY, HH:MM"])}
          </Container>

          <ButtonFlexContainer
            color="black"
            columngap="1.2rem"
            fontWeight="bold"
            height="max-content">
            <Container
              onClick={doQuickPick}
              display="grid"
              placecontent="center"
              border="1px solid black"
              borderradius="4px"
              width="20vw"
              height="1.4rem"
              bgcolor={`linear-gradient(
                ${theme.colors.yellowPrimary},
                ${theme.colors.yellowSecondary}
                )`}
              boxshadow={`2px 2px 5px ${theme.colors.orangePrimary}`}>
              Quick Pick
            </Container>

            <Container
              onClick={onTakeABetClick}
              display="grid"
              placecontent="center"
              border={`1px solid ${theme.colors.greyBlue}`}
              borderradius="4px"
              width="20vw"
              height="1.4rem"
              bgcolor="white"
              boxshadow={`2px 2px 5px ${theme.colors.orangePrimary}`}>
              Take a Bet
            </Container>

            <Container
              onClick={handleTips}
              display="grid"
              placecontent="center"
              border={`1px solid ${theme.colors.greyBlue}`}
              borderradius="4px"
              width="20vw"
              height="1.4rem"
              bgcolor="white"
              boxshadow={`2px 2px 5px ${theme.colors.orangePrimary}`}>
              Tips
            </Container>
          </ButtonFlexContainer>
        </FlexContainer>
      )}

      {isDesktop && (
        <FlexContainer
          bgcolor={color}
          width="100%"
          height="auto"
          bordertopleftradius="4px"
          bordertoprightradius="4px"
          paddingtop="3px"
          alignitems={`${theme.font.align.center}`}>
          <FlexContainer
            width="50%"
            direction="column"
            alignitems={`${theme.font.align.left}`}
            rowgap="5px"
            marginleft="20px"
            marginbottom="10px">
            <Container
              textalign="left"
              color="white"
              fontSize="2vw"
              fontWeight={`${theme.font.weight.bold}`}>
              {meetingName}
            </Container>

            <MeetingDateTimeDesktopContainer>
              {getFormatted(meetingDateTime, FORMATS["D, DT FM FY, HH:MM"])}
            </MeetingDateTimeDesktopContainer>
          </FlexContainer>

          <FlexContainer
            justifycontent="flex-end"
            width="50%"
            color="black"
            columngap="1.3rem"
            fontSize="0.7rem"
            fontWeight="bold"
            height="max-content"
            marginright="15px">
            <QuickpickDesktopContainer onClick={doQuickPick}>
              Quick Pick
            </QuickpickDesktopContainer>

            <TakeAbetTipsDesktopContainer onClick={onTakeABetClick}>
              Take a Bet
            </TakeAbetTipsDesktopContainer>

            <TakeAbetTipsDesktopContainer onClick={handleTips}>
              Tips
            </TakeAbetTipsDesktopContainer>
          </FlexContainer>
        </FlexContainer>
      )}

      {!selectedMeetingData && (
        <LoadingStyle>
          <ReactLoading
            type="spokes"
            color={`${theme.colors.aquaSecondary}`}
            height="80px"
            width="80px"
          />
        </LoadingStyle>
      )}
      {selectedMeetingData && (
        <FlexContainer columngap="9px">
          <FlexContainer
            marginleft="5px"
            direction="column"
            rowgap="8px"
            alignitems={`${theme.font.align.center}`}
            color="black">
            <FlexContainer
              direction="column"
              bgcolor=""
              paddingright="0px"
              alignitems="left"
              width="100%"
              marginbottom="0px"
              height="max-height"
              rowgap="3px">
              <Container
                bgcolor=""
                textalign={`${theme.font.align.center}`}
                fontSize="1rem"
                width="100%"
                lineheight="1"
                fontWeight={`${theme.font.weight.bold}`}>
                #
              </Container>
              <Container
                fontSize="0.8rem"
                textalign={`${theme.font.align.left}`}
                marginright="0px"
                color="red"
                height="max-content"
                lineheight="1"
                style={{ opacity: "0" }}>
                Number
              </Container>
              <Container
                fontSize="0.8rem"
                textalign="center"
                marginright="0px"
                color="black"
                height="max-content"
                fontWeight={`${theme.font.weight.bold}`}
                lineheight="1">
                Punters
                <br />
                Remaining
              </Container>
            </FlexContainer>
            {maxHorsesRender()}
          </FlexContainer>

          <Container
            width="90vw"
            overflow="scroll"
            paddingbottom="7px"
            height="max-content"
            ref={scrollContainerRef}>
            <FlexContainer
              columngap="10px"
              marginright="5px"
              height="100%"
              minwidth="100%"
              width="fit-content">
              {finalDataRender()}
            </FlexContainer>
          </Container>
        </FlexContainer>
      )}

      <FlexContainer
        columngap="2px"
        height="80%"
        fontSize="0.7rem"
        padding="0.7rem"
        bgcolor="none">
        <Container width="1.2rem" height="1.2rem" bgcolor="green" />
        <Container marginleft="5px"> Number of selections </Container>
        <Container
          width="1.2rem"
          height="1.2rem"
          bgcolor="blue"
          marginleft="10px"
        />
        <Container marginleft="5px"> Current price </Container>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Selection;
