import HorseShoe from "@assets/horse-shoe-unbroken.svg";
import Whip from "@assets/whip.svg";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import Container from "../../../bricks/container";
import Image from "../../../bricks/image";
import { RootState } from "../../../../store/store";

interface IHorseShoeOrWhip {
  isForFriendsLeague: boolean;
  rank: number;
}

const HorseShoeOrWhip = ({ isForFriendsLeague, rank }: IHorseShoeOrWhip) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const { currentLeaderboardLastRank } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const toShowFirstPositionIcon = () => {
    return Number(rank) === 1;
  };

  const toShowLastPositionIcon = () => {
    return Number(rank) === currentLeaderboardLastRank;
  };

  return isForFriendsLeague ? (
    <>
      {!isDesktop && toShowFirstPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image src={HorseShoe} maxHeight="22px" width="30px" />
        </Container>
      )}
      {!isDesktop && toShowLastPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={Whip}
            maxHeight="15px"
            transform="scale(1.2)"
            width="28px"
          />
        </Container>
      )}
      {isDesktop && toShowFirstPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={HorseShoe}
            maxHeight="min(50px,3vw)"
            paddingRight={32}
            maxWidth="50px"
            width="50px"
          />
        </Container>
      )}

      {isDesktop && toShowLastPositionIcon() && (
        <Container display="grid" placecontent="center">
          <Image
            src={Whip}
            maxHeight="min(50px,3vw)"
            transform="scale(0.7)"
            paddingRight={32}
            maxWidth="50px"
            width="50px"
          />
        </Container>
      )}
    </>
  ) : null;
};

export default HorseShoeOrWhip;
