import { useContext, useState } from "react";
import ArrowDown from "@assets/arrow-down-gold.svg";
import Container from "../../../../bricks/container";
import Logo from "../../../logo";
import HomeScrollContainer from "../../common/homeScrollContainer";
import AboutTheDisplay from "../aboutTheDisplay";
import NumberOneDisplay from "../numberOneDisplay";
import OurReachDisplay from "../ourReachDisplay";
import OurSuccessDisplay from "../ourSuccessDisplay";
import OverThousandDisplay from "../overThousandDisplay";
import PrizeMoneyDisplay from "../prizeMoneyDisplay";
import SignupOrLogin from "../signupOrLogin";
import DailyRewardsDisplay from "../dailyRewardsDisplay";
import ExcitingJackpotDisplay from "../excitingJackpotDisplay";
import HowToPlayControl from "../howToPlayControl";
import RegisterForFreeControl from "../registerForFreeControl";
import NextBigWinnerDisplay from "../nextBigWinnerDisplay";
import FlexContainer from "../../../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import Footer from "../../../../../layouts/footer";
import { MainDiv, TextBoxContainer } from "./homePreLogin.style";
import RegistrationModal from "../../../modals/registrationModal";
import { ArrowDiv } from "../../../../composites/upAndDownButton/upAndDownButton.style";
import { ModalContainer } from "../../../modals/registrationModal.style";

interface IHomePrelogin {
  scrollerCallback: () => void;
  scrollToTopCallback: () => void;
}

const HomePrelogin = ({
  scrollerCallback,
  scrollToTopCallback
}: IHomePrelogin) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const [openModal, setOpenModal] = useState(false);

  const handleModalState = (shouldShow: boolean) => {
    scrollToTopCallback();
    setOpenModal(shouldShow);
  };

  return (
    <HomeScrollContainer>
      <>
        {openModal && (
          <ModalContainer>
            <RegistrationModal
              xOffset="none"
              yOffset="50px"
              cancelCallback={() => handleModalState(false)}
            />
          </ModalContainer>
        )}
        {isDesktop ? (
          <>
            <Container height="auto" marginbottom="28px">
              <Logo maxwidth="35%" />
            </Container>
            <NumberOneDisplay />
            <OverThousandDisplay />
            <PrizeMoneyDisplay />
            <ArrowDiv onClick={scrollerCallback}>
              <img src={ArrowDown} alt="Arrow Down Gold" />
            </ArrowDiv>
            <AboutTheDisplay />
            <TextBoxContainer>
              <DailyRewardsDisplay />
              <ExcitingJackpotDisplay />
              <OurReachDisplay />
              <OurSuccessDisplay />
            </TextBoxContainer>
            <NextBigWinnerDisplay />
            <RegisterForFreeControl
              onRegisterClicked={() => handleModalState(true)}
            />
          </>
        ) : (
          <>
            <MainDiv>
              <Container
                width="100%"
                maxheight="50px"
                marginbottom="10px"
                margintop="10px">
                <Logo maxwidth="70%" />
              </Container>
              <NumberOneDisplay />
              <OverThousandDisplay />
              <PrizeMoneyDisplay />
              <SignupOrLogin onRegisterClicked={() => handleModalState(true)} />
              <ArrowDiv onClick={scrollerCallback}>
                <img src={ArrowDown} alt="Arrow Down Gold" />
              </ArrowDiv>
            </MainDiv>
            <FlexContainer width="100%" justifycontent="center">
              <AboutTheDisplay />
            </FlexContainer>
            <OurReachDisplay />
            <OurSuccessDisplay />
            <DailyRewardsDisplay />
            <ExcitingJackpotDisplay />
            <NextBigWinnerDisplay />
            <HowToPlayControl />
            <RegisterForFreeControl
              onRegisterClicked={() => handleModalState(true)}
            />
          </>
        )}
        <div style={{ width: "100%" }}>
          <Footer />
        </div>
      </>
    </HomeScrollContainer>
  );
};

export default HomePrelogin;
