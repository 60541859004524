import styled from "styled-components";
import Container from "../../../bricks/container";
import theme from "../../../../theme";

const StyledTick = styled.div<{ thickness?: number; color?: string }>`
  height: 50%;
  width: 19%;
  position: absolute;
  transform: translate(110%, -10%) rotate(40deg);
  border-bottom: ${(props) =>
    props.thickness
      ? `${props.thickness}px solid ${props.color ?? "green"}`
      : `9px solid ${props.color ?? props.theme.colors.greenPrimary}`};
  border-right: ${(props) =>
    props.thickness
      ? `${props.thickness}px solid ${props.color ?? "green"}`
      : `9px solid ${props.color ?? props.theme.colors.greenPrimary}`};
`;

interface ITick {
  width?: number;
  height?: number;
  callback: () => void;
}

const Tick = ({ width, height, callback }: ITick) => {
  return (
    <Container
      width={`${width ?? 50}px`}
      height={`${height ?? 50}px`}
      bgcolor={theme.colors.primary}
      position="relative"
      display="grid"
      alignitems="center"
      borderradius="5px"
      border={`1px solid ${theme.colors.purplePrimary}`}
      onClick={callback}
      cursor="pointer">
      {" "}
      <StyledTick
        thickness={width ? 0.12 * width : 0}
        color={theme.colors.purplePrimary}
      />
    </Container>
  );
};

export default Tick;
