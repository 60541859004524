import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import Container from "../components/bricks/container";
import FlexContainer from "../components/bricks/flexContainer";
import Carousal from "../components/views/carousal";
import TabScroller, {
  type ITabscrollerTabData
} from "../components/views/tabScroller";
import PuntersJackpot from "../components/views/puntersJackpot";
import PossibleWinners from "../components/views/possibleWinners";
import Selection from "../components/views/selection";
import Button from "../components/bricks/button";
import "react-loading-skeleton/dist/skeleton.css";

import {
  getProgressiveWinners,
  getMeetingData,
  getMeetingJackpot,
  getMeetingProgressive,
  getUpcomingMeetings,
  savePunterSelection,
  type ISavePunterSelectionReqData,
  setCurrentMeetingId,
  setSelectionSaved,
  clearAllSelections,
  getAllNotifications
} from "../store/features/meetingsSlice";
import type { RootState } from "../store/store";
import { getCMSImages, setCurrentPage } from "../store/features/pagesSlice";
import NotLoggedinModal from "../components/views/modals/notLoggedinModal";
import Betslip from "../components/views/betslip/index";
import {
  getBetslipData,
  toggleLuckyPick
} from "../store/features/betslipSlice";
import TakeaBetModal from "../components/views/modals/takeAbetModal";
import { NotificationContext } from "../contexts/notificationContext";
import { FORMATS, getFormatted } from "../utils/dateUtils";
import {
  GetLeaderboardForMeeting,
  GetLeaderboardMeetingIdMetaData,
  clearPuntersList
} from "../store/features/leaderboardSlice";
import QuickResults from "../components/views/quickResults";

import AliasModal from "../components/views/modals/alias-modal/aliasModal";
import BetSlipNew from "../components/views/betslipNew/betslip";
import { SELECTION_CUTOFF_TIME_IN_MINUTES } from "../constants";

import ErrorHandler from "../components/errorHandler";

import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import {
  getPayoutLimits,
  setInitialState
} from "../store/features/newBetSlipSlice";

import theme from "../theme";
import { LoadingDiv, DesktopDiv } from "./home.style";
import { sortRacesWithSAPriority } from "../utils/raceCourseUtils";
import { MapToBet } from "../components/views/newHomePage/postLogin/race-card-selections/selection/selectionsUtil";

const Home = () => {
  const { notificationUpdater } = useContext(NotificationContext);
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const dispatch = useDispatch();

  const pageRoute = useLocation().pathname;
  const submitRef = useRef<HTMLDivElement>(null);

  const currentMeetingId = useRef<number | string>(-1);

  const {
    meetingsMetaData,
    jackpots: jackpotsData,
    progressives: progressivesData,
    meetings: meetingsData,
    currentSelectedMeetingId,
    abandonedList: abandonedMeetingsList,
    postponedList: postponedMeetingsList,
    error: errorInGettingTrack,
    notifications,
    selectionSavedJustNow,
    selections: allSelections
  } = useSelector((state: RootState) => state.meetings);

  const [tabScrollerData, setTabScrollerData] = useState<
    ITabscrollerTabData[] | null
  >(null);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const [toShowNotLoggedInModal, setToShowNotLoggedInModal] = useState(false);
  const [selectionSubmissionData, setSelectionSubmissionData] = useState<
    [boolean, ISavePunterSelectionReqData[] | null]
  >([false, null]);
  const currentSelectionIndex = useRef(0);
  const [toOpenbetslip, setToOpenbetslip] = useState(false);

  const {
    bets: apiBets,
    placebets: apiPlacebets,
    spbets: apiSpbets,
    error: errorFetchingBetslip,
    isFetching: isWaitingForBetslip
  } = useSelector((state: RootState) => state.betslip);

  const { punterId, userName } = useSelector((state: RootState) => state.user);
  const { limits } = useSelector((state: RootState) => state.betslipNew);
  const [toResetTabScroller, setToResetTabScroller] = useState<boolean>(false);

  const [isBetDataBeingFetched, setIsBetDataBeingFetched] =
    useState<boolean>(false);
  const [toShowTakeABetModal, setToShowTakeABetModal] =
    useState<boolean>(false);
  const currentjackpot =
    jackpotsData && Number(currentSelectedMeetingId) >= 0
      ? jackpotsData[currentSelectedMeetingId]
      : null;
  const currentprogressives =
    progressivesData && Number(currentSelectedMeetingId) >= 0
      ? progressivesData[currentSelectedMeetingId]
      : null;

  const currentMeetingData = meetingsData
    ? meetingsData[currentSelectedMeetingId]
    : null;

  const currentMeetingMetaData = meetingsMetaData
    ? meetingsMetaData.find(
        (item) => Number(item.meetingId) === Number(currentSelectedMeetingId)
      )
    : null;

  const [toEnableSubmit, setToEnableSubmit] = useState<boolean>(false);
  const [isCurrentAbandoned, setIsCurrentAbandoned] = useState<boolean>(false);
  const [isCurrentPostponed, setIsCurrentPostponed] = useState<boolean>(false);

  const lbMeetingsMetaData = useSelector(
    (state: RootState) => state.leaderboard.lbMeetingsMetaData
  );

  const cmsImagesData = useSelector(
    (state: RootState) => state.pages.cmsImages
  );
  const useNewBetslip = useSelector(
    (state: RootState) => state.config.data.showNewBetSlip
  );

  useEffect(() => {
    dispatch(getPayoutLimits());
  }, []);

  const mountedJustNow = useRef<boolean>(false);

  const getJackpotDate = () => {
    const hasFirstRace =
      currentMeetingData?.races != null && currentMeetingData?.races.length > 0;
    const hasRunners =
      hasFirstRace && currentMeetingData?.races[0].runners?.length > 0;

    if (!hasFirstRace || !hasRunners) return null;

    const hasResulted =
      parseInt(
        String(currentMeetingData.races[0].runners[0].finishingPosition)
      ) > 0;
    if (hasResulted) return null;

    const firstRaceDate = new Date(currentMeetingData.meetingDate);

    const jackpotEndDateOffset = SELECTION_CUTOFF_TIME_IN_MINUTES * 60 * 1000;
    const jackpotEndDate = firstRaceDate.getTime() - jackpotEndDateOffset;

    if (jackpotEndDate <= new Date().getTime()) return null;

    return jackpotEndDate;
  };
  const isNotPostponedAndAbandoned = () =>
    !isCurrentAbandoned && !isCurrentPostponed;

  useEffect(() => {
    if (meetingsMetaData?.length) {
      const tsData: ITabscrollerTabData[] = sortRacesWithSAPriority(
        meetingsMetaData
      ).map(
        (item) =>
          ({
            label: item.raceCourseName,
            metadata: item
          }) as ITabscrollerTabData
      );

      setTabScrollerData(tsData);

      currentMeetingId.current =
        Number(currentSelectedMeetingId) > 0
          ? Number(currentSelectedMeetingId)
          : meetingsMetaData[0].meetingId;

      dispatch(
        setCurrentMeetingId(
          Number(currentSelectedMeetingId) > 0
            ? Number(currentSelectedMeetingId)
            : meetingsMetaData[0].meetingId
        )
      );

      window.scrollTo({ top: 0 });
    }
  }, [meetingsMetaData]);

  useEffect(() => {
    if (
      !!errorInGettingTrack &&
      Number(currentMeetingId) > 0 &&
      isNotPostponedAndAbandoned()
    ) {
      notificationUpdater({ status: "error", message: errorInGettingTrack });
    }
  }, [errorInGettingTrack]);

  useEffect(() => {
    setSelectionSubmissionData([false, null]);

    setIsCurrentAbandoned(
      Number(currentSelectedMeetingId) > 0 &&
        abandonedMeetingsList.includes(Number(currentSelectedMeetingId))
    );
    setIsCurrentPostponed(
      Number(currentSelectedMeetingId) > 0 &&
        postponedMeetingsList.includes(Number(currentSelectedMeetingId))
    );

    if (
      Number(currentSelectedMeetingId) > 0 &&
      !abandonedMeetingsList.includes(Number(currentSelectedMeetingId))
    ) {
      dispatch(
        GetLeaderboardForMeeting({
          punterId,
          meetingId: currentSelectedMeetingId
        })
      );
      dispatch(
        getMeetingData({ punterId, meetingId: currentSelectedMeetingId })
      );
      dispatch(getMeetingJackpot({ meetingId: currentSelectedMeetingId }));
      dispatch(getMeetingProgressive({ meetingId: currentSelectedMeetingId }));
      dispatch(getProgressiveWinners(currentSelectedMeetingId));
    }
  }, [currentSelectedMeetingId]);

  const blankBetslip = () => {
    dispatch(
      setInitialState({
        allowBet: false,
        bets: [],
        meeting: {
          meetingDate: "",
          meetingId: 0,
          meetingName: "EMPTY"
        },
        totalStake: 0,
        totalReturn: 0,
        betStrikeResponse: null,
        forcedSP: false,
        races: [],
        limits
      })
    );
  };

  const onTabClick = (index: number) => {
    getJackpotDate();
    currentSelectionIndex.current = index;

    if (
      meetingsMetaData &&
      meetingsMetaData[index]?.meetingId !== currentSelectedMeetingId
    ) {
      setToEnableSubmit(false);

      if (isBetDataBeingFetched) setIsBetDataBeingFetched(false);
      dispatch(setCurrentMeetingId(meetingsMetaData[index]?.meetingId));

      blankBetslip();
    }
  };

  const onSubmit = () => {
    if (
      isLoggedIn &&
      selectionSubmissionData[0] &&
      selectionSubmissionData[1]!.length
    ) {
      dispatch(savePunterSelection(selectionSubmissionData[1]));
    } else {
      setToShowNotLoggedInModal(true);
      window.scrollTo({ top: 0 });
    }
  };
  const bringSubmitButtonInView = () => {
    submitRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  };

  const onSelectionAndSubmitDataUpdate = (
    enable: boolean,
    data: ISavePunterSelectionReqData[],
    hasInteracted: boolean,
    wasQuickPicked: boolean
  ) => {
    setSelectionSubmissionData([enable, data]);
    if (enable && hasInteracted) {
      setToEnableSubmit(true);
      if (wasQuickPicked) bringSubmitButtonInView();
      blankBetslip();
    } else {
      setToEnableSubmit(false);
    }
  };

  useEffect(() => {
    mountedJustNow.current = true;
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    if (!meetingsMetaData) dispatch(getUpcomingMeetings());
    if (!lbMeetingsMetaData) dispatch(GetLeaderboardMeetingIdMetaData());

    dispatch(setCurrentPage(pageRoute));
    dispatch(getAllNotifications());
    if (!cmsImagesData) dispatch(getCMSImages());

    return () => {
      dispatch(setCurrentMeetingId(0));
      dispatch(clearPuntersList());
    };
  }, []);

  useEffect(() => {
    if (!mountedJustNow.current) {
      onTabClick(0);
      setToResetTabScroller(true);
    }

    if (isLoggedIn) {
      if (
        Number(currentSelectedMeetingId) > 0 &&
        !abandonedMeetingsList.includes(Number(currentSelectedMeetingId))
      ) {
        dispatch(
          GetLeaderboardForMeeting({
            punterId,
            meetingId: currentSelectedMeetingId
          })
        );
        dispatch(
          getMeetingData({ punterId, meetingId: currentSelectedMeetingId })
        );
      }
    } else {
      setToEnableSubmit(false);
      if (
        Number(currentSelectedMeetingId) > 0 &&
        !abandonedMeetingsList.includes(Number(currentSelectedMeetingId))
      ) {
        dispatch(
          GetLeaderboardForMeeting({
            punterId: 0,
            meetingId: currentSelectedMeetingId
          })
        );
      }

      dispatch(clearAllSelections());
    }

    mountedJustNow.current = false;
  }, [isLoggedIn]);

  const onNotLoginModalCancelled = () => {
    setToShowNotLoggedInModal(false);
  };

  const fetchBetslipData = () => {
    let submissionData: any[] = selectionSubmissionData?.[1]?.length
      ? selectionSubmissionData[1]
      : [];

    if (
      submissionData.length === 0 &&
      allSelections![currentSelectedMeetingId]?.length
    ) {
      submissionData = allSelections![currentSelectedMeetingId];
    }

    setIsBetDataBeingFetched(true);
    dispatch(
      getBetslipData({
        selection: submissionData,
        punterId,
        userName,
        meetingId: currentSelectedMeetingId
      })
    );
  };

  const onTakeABet = () => {
    if (isLoggedIn) {
      const selections = selectionSubmissionData?.[1];
      const allracesSelected =
        selections?.length === currentMeetingData?.races.length &&
        selections!.findIndex((item) => !item) < 0;

      if (allracesSelected) {
        fetchBetslipData();
      } else {
        notificationUpdater({
          status: "error",
          message: "Please select a horse from all races.",
          duration: 3000
        });
      }
    } else {
      setToShowNotLoggedInModal(true);
    }
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (isBetDataBeingFetched && !isWaitingForBetslip) {
      if (apiBets?.length || apiPlacebets?.length) {
        setToOpenbetslip(true);
      } else {
        notificationUpdater({
          status: "error",
          message: "Betslip not avalilable at the moment."
        });
      }
      setIsBetDataBeingFetched(false);
    }
  }, [apiBets, apiPlacebets, errorFetchingBetslip]);

  useEffect(() => {
    if (selectionSavedJustNow) {
      setToShowTakeABetModal(true);
      dispatch(setSelectionSaved(false));
      window.scrollTo({ top: 0 });
    }
  }, [selectionSavedJustNow]);

  const onAcceptTakeABet = () => {
    setToShowTakeABetModal(false);
    fetchBetslipData();
  };

  const onCancelTakeAbetModal = () => {
    setToShowTakeABetModal(false);
  };

  const getColor = () => {
    const { raceCourseName } = meetingsMetaData![currentSelectionIndex.current];

    return theme.raceColors(raceCourseName);
  };

  const getNotificationMesasge = (meetingid: number | string): string => {
    const notification = notifications?.find(
      (item) => item.meetingId === Number(meetingid)
    );
    return notification?.noteContent.replace(/<[^>]+>/g, "") || "";
  };

  const getWidth = () => {
    return isDesktop ? "95%" : "85%";
  };

  const betSlipClosureCallback = () => {
    setToOpenbetslip(false);

    dispatch(toggleLuckyPick(false));
  };

  return (
    <FlexContainer
      direction="column"
      alignitems={theme.font.align.center}
      rowgap="1rem"
      bgcolor={theme.colors.bodySecondary}
      height="max-content">
      {isLoggedIn && (
        <ErrorHandler>
          <QuickResults />
        </ErrorHandler>
      )}

      {toShowTakeABetModal && (
        <TakeaBetModal
          show
          acceptCallback={onAcceptTakeABet}
          cancelCallback={onCancelTakeAbetModal}
        />
      )}

      <NotLoggedinModal
        show={toShowNotLoggedInModal}
        cancelCallback={onNotLoginModalCancelled}
      />

      {toOpenbetslip && !useNewBetslip && (
        <Betslip
          open={toOpenbetslip}
          totalNumberOfRaces={allSelections![currentSelectedMeetingId].length}
          bets={apiBets}
          placeBets={apiPlacebets}
          spbets={apiSpbets}
          closureCallback={() => {
            setToOpenbetslip(false);
          }}
        />
      )}

      {toOpenbetslip && useNewBetslip && (
        <BetSlipNew
          bets={MapToBet(apiBets)}
          placeBets={MapToBet(apiPlacebets)}
          spBets={MapToBet(apiSpbets)}
          backToBetSlipCallback={fetchBetslipData}
          closureCallback={betSlipClosureCallback}
        />
      )}

      <AliasModal />
      <Container
        width={`${isDesktop ? "95%" : "98%"}`}
        margintop={isDesktop ? "10px" : "0px"}>
        <Carousal />
      </Container>

      {!isDesktop && (
        <FlexContainer direction="column" alignitems="center" width="85%">
          <Container
            fontWeight="bold"
            height="max-content"
            marginbottom="10px"
            fontSize="4.3vw"
            color={theme.colors.purplePrimary}>
            Play Your Next Challenge Today :
          </Container>
          <ErrorHandler>
            <TabScroller
              data={tabScrollerData}
              callback={onTabClick}
              currentIndex={meetingsMetaData?.findIndex(
                (item) => item.meetingId === currentSelectedMeetingId
              )}
              reset={toResetTabScroller}
            />
          </ErrorHandler>
        </FlexContainer>
      )}

      {isDesktop && (
        <FlexContainer
          width="95%"
          justifycontent={theme.font.align.spaceBetween}>
          <Container
            width="30%"
            textalign={theme.font.align.left}
            fontWeight={`${theme.font.weight.bold}`}
            color={theme.colors.purplePrimary}>
            Play Your Next Challenge Today :
          </Container>
          <ErrorHandler>
            <TabScroller
              data={tabScrollerData}
              callback={onTabClick}
              currentIndex={meetingsMetaData?.findIndex(
                (item) => item.meetingId === currentSelectedMeetingId
              )}
              reset={toResetTabScroller}
            />
          </ErrorHandler>
        </FlexContainer>
      )}

      {isNotPostponedAndAbandoned() ? (
        <>
          <Container width={getWidth()}>
            {!currentjackpot && (
              <Skeleton
                width="85vw"
                height="30px"
                baseColor={theme.colors.purpleLightSecondary}
                style={{ paddingTop: "5px" }}
              />
            )}
            {currentjackpot && currentprogressives && (
              <ErrorHandler>
                <PuntersJackpot
                  amount={Number(currentjackpot.jackpotFigure)}
                  color={getColor()}
                  winner={currentprogressives.winner}
                  races={currentprogressives.races}
                  totalPlayers={currentprogressives.players}
                  jackpotEndDate={getJackpotDate()}
                />
              </ErrorHandler>
            )}
          </Container>
          {isBetDataBeingFetched && (
            <div>
              <LoadingDiv />
              <ReactLoading
                type="spokes"
                color={theme.colors.aquaSecondary}
                height="80px"
                width="80px"
              />
            </div>
          )}
          {!isDesktop && (
            <div>
              <DesktopDiv />
              {!currentprogressives && (
                <Skeleton
                  width="85vw"
                  height="30px"
                  baseColor={theme.colors.purpleLightSecondary}
                  style={{ paddingTop: "5px" }}
                />
              )}
              {currentprogressives && (
                <ErrorHandler>
                  <PossibleWinners
                    winner={currentprogressives.winner}
                    races={currentprogressives.races}
                    color={getColor()}
                    totalPlayers={currentprogressives.players}
                  />
                </ErrorHandler>
              )}
            </div>
          )}

          <div style={{ width: getWidth() }}>
            {!currentMeetingData && (
              <Skeleton
                width="85vw"
                height="30px"
                baseColor={theme.colors.purpleLightSecondary}
                style={{ paddingTop: "5px" }}
              />
            )}
            {currentMeetingData && (
              <ErrorHandler>
                <Selection
                  color={getColor()}
                  setSubmitData={onSelectionAndSubmitDataUpdate}
                  takeaBetCallback={onTakeABet}
                />
              </ErrorHandler>
            )}
          </div>
        </>
      ) : (
        <FlexContainer
          bgcolor="white"
          width="85%"
          direction="column"
          rowgap="5px"
          paddingbottom="0px"
          height="auto"
          borderradius="5px"
          paddingtop="10px"
          alignitems="center"
          marginbottom="10px"
          border="2px solid red">
          <Container
            bgcolor="none"
            width="max-content"
            lineheight="1"
            color="red"
            fontSize="min('1.1rem','2rem')"
            fontWeight={`${theme.font.weight.bold}`}>
            {currentMeetingMetaData?.raceCourseName}
          </Container>

          <Container
            bgcolor="none"
            width="max-content"
            color="red"
            fontSize="0.8rem"
            margintop="-3px">
            {getFormatted(
              currentMeetingMetaData?.meetingDate as string,
              FORMATS["D, DT FM FY, HH:MM"]
            )}
          </Container>

          <h3 style={{ color: "red" }}>
            {`${isCurrentAbandoned ? "Abandoned. " : " Postponed."}`}
          </h3>
          <Container color="red" lineheight="1.2" padding="0px 10px 13px 10px">
            {getNotificationMesasge(currentSelectedMeetingId)}
          </Container>
        </FlexContainer>
      )}
      {currentMeetingData && (
        <div ref={submitRef} style={{ marginBottom: "20px" }}>
          <Button
            width="10rem"
            height="2.5rem"
            onClick={onSubmit}
            gradient={{
              colors: [
                `${theme.colors.purpleSecondary}`,
                `${theme.colors.purplePrimary}`
              ],
              direction: "linear"
            }}
            disabled={!toEnableSubmit}
            style={{ cursor: `${!toEnableSubmit ? "default" : "pointer"}` }}
            text="Submit"
            fontSize="0.8rem"
          />
        </div>
      )}
    </FlexContainer>
  );
};

export default Home;
