import styled from "styled-components";
import { IMyLeagueItem } from "../../../../store/features/friendsLeagueSlice";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import Button from "../../../bricks/button";

import NotificationBell from "./notificationBell";
import ConfirmationModal from "../../modals/confirmationModal";
import useLeagueItemCommonLogic from "./hooks/useLeagueItemCommonLogic";

interface ILeagueItemCard {
  data: IMyLeagueItem;
  index: number;
  isActive?: boolean;
  isForJoinedLeagues?: boolean;
}

interface IControl {
  borderColor: string;
  backgroundColor: string;
  $isActive: boolean;
  $isDisabled: boolean;
  color?: string;
}

const Control = styled(Button)<IControl>`
  width: 30%;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.backgroundColor ?? "transparent"};
  color: ${(props) => props.color ?? props.theme.colors.purplePrimary};
  font-weight: ${(props) => (props.$isActive ? "bold" : "normal")};
  font-size: 0.6rem;
  line-height: 1;
  overflow: auto;

  @media screen and (max-width: 420px) and (min-width: 375px) {
    font-size: 0.5rem;
  }
`;

const LeagueItemCardMob = ({
  data,
  index,
  isActive,
  isForJoinedLeagues
}: ILeagueItemCard) => {
  const { leagueName, rank, leagueCode, totalPlayers, unreadNotifications } =
    data;

  const {
    isModalOpen,
    setIsModalOpen,
    shareLeague,
    onManageClick,
    confirmExit,
    onViewLeaderboard
  } = useLeagueItemCommonLogic({ isForJoinedLeagues, leagueName, leagueCode });

  return (
    <FlexContainer
      direction="column"
      width="99%"
      borderradius="5px"
      border={`1px solid ${theme.colors.purplePrimary}`}
      bgcolor={`${index % 2 === 0 ? theme.colors.lightAliceBlue : "white"}`}
      margintop="10px">
      <FlexContainer margintop="10px" justifycontent="space-between">
        <Container
          maxwidth="50%"
          textalign="left"
          fontSize="0.9rem"
          fontWeight="bold"
          color={isActive ? theme.colors.purplePrimary : "black"}
          marginleft="20px"
          overflow="auto"
          lineheight="1">
          {leagueName}
        </Container>

        <Container
          textalign="left"
          fontSize="0.7rem"
          fontWeight="bold"
          color="black"
          marginright="20px"
          marginleft="5px">
          {`${rank}/${totalPlayers} ${totalPlayers > 1 ? "players" : "player"} `}
        </Container>
      </FlexContainer>

      <FlexContainer
        margintop="10px"
        justifycontent="space-between"
        bgcolor=""
        marginbottom="12px">
        <Control
          borderColor={theme.colors.shadowGrey}
          color={
            isActive ? theme.colors.darkSteelGrey2 : theme.colors.darkSteelGrey
          }
          backgroundColor={isActive ? "white" : theme.colors.greyBlue}
          $isActive
          $isDisabled={false}
          text="Share code"
          marginleft="20px"
          onClick={isActive ? shareLeague : undefined}
        />
        <Control
          borderColor={theme.colors.shadowGrey}
          backgroundColor="white"
          color={theme.colors.darkSteelGrey2}
          $isActive
          $isDisabled={false}
          text="View Leaderboard"
          onClick={onViewLeaderboard}
        />

        <FlexContainer
          width="26%"
          border={`1px solid ${theme.colors.purplePrimary}`}
          borderradius="4px"
          justifycontent="center"
          onClick={onManageClick}
          bgcolor={theme.colors.primary}
          marginright="20px"
          cursor="pointer">
          <Container
            fontSize="0.8rem"
            color={theme.colors.purplePrimary}
            margintop="4px"
            marginbottom="4px"
            marginleft={`${unreadNotifications > 0 ? 1.2 : 0}vw`}>
            {isForJoinedLeagues ? "Exit" : "Manage"}{" "}
          </Container>
          {unreadNotifications > 0 && (
            <NotificationBell numNotifications={unreadNotifications} />
          )}
        </FlexContainer>
        <ConfirmationModal
          isModalOpen={isModalOpen}
          modalTitle="Exit League?"
          modalDescription="Are you sure you want to exit the league?"
          onYesCallback={confirmExit}
          onNoCallback={() => setIsModalOpen(false)}
          left={52}
          bottom={130}
          maxWidth="93%"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default LeagueItemCardMob;
