import styled from "styled-components";

export interface IIsCancelled {
  isCancelled: boolean;
}

export const CancelDiv = styled.div<IIsCancelled>`
  margin: 0 auto;
  width: 90%;
  height: 80%;
  max-width: 30px;
  padding-top: 2px;
  background: white;
  border-radius: 3px;
  border: 1px solid ${(props) => (props.isCancelled ? "red" : "#86868d")};
  display: grid;
  place-content: center;
  color: ${(props) => (props.isCancelled ? "red" : "#86868d")};
  line-height: 1;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
`;
export const StakeButtonImg = styled.img`
  margin-left: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const SpDiv = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 30px;
  height: 90%;
  background: none;
  border: 1px solid #5c2d91;
  border-radius: 3px;
  padding-top: 1px;
  cursor: pointer;
`;
