import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import theme from "../../../../../theme";
import Button from "../../../../bricks/button";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import { RootState } from "../../../../../store/store";
import TeamMemberRowItem from "../teamMemberRowItem";
import {
  IMyLeagueItem,
  UPDATE_LEAGUE_ACTION_CODE_MAP,
  displayManageForLeague,
  removeALeagueMember,
  shareALeague
} from "../../../../../store/features/friendsLeagueSlice";
import Spinner from "../../../../bricks/spinner";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import LeagueScrollerTabsMob from "../leagueScrollerTabsMob";

interface IMembersView {
  currentLeague: IMyLeagueItem;
  leagues: IMyLeagueItem[];
  setCurrentLeague: (league: IMyLeagueItem) => void;
  confirmationCallback: (
    title: string,
    description: string,
    yesCallback: () => void
  ) => void;
}

const MembersView = ({
  currentLeague,
  leagues,
  setCurrentLeague,
  confirmationCallback
}: IMembersView) => {
  const dispatch = useDispatch();
  const { punterId } = useSelector((state: RootState) => state.user);
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const {
    currentLeagueMembers,
    currentLeagueToManage,
    errorMessageOnRejection
  } = useSelector((state: RootState) => state.friendsLeague);

  const onShareLeagueClick = () => {
    dispatch(shareALeague(currentLeague.leagueCode));
  };

  const handleCallToRemoveAPlayer = (name: string) => {
    const playerData = currentLeagueMembers?.find(
      (member) => member.punterAlias === name
    );

    if (playerData) {
      dispatch(
        removeALeagueMember({
          punterId: playerData.punterId,
          leagueCode: currentLeagueToManage?.leagueCode,
          action: UPDATE_LEAGUE_ACTION_CODE_MAP.REMOVED
        })
      );
    }
  };

  const onLeagueTabClick = (league: IMyLeagueItem) => {
    if (currentLeague?.leagueName !== league.leagueName) {
      setCurrentLeague(league);

      dispatch(displayManageForLeague(league.leagueName));
    }
  };

  const getCallback = (name: string) => {
    return confirmationCallback(
      "Remove Player?",
      "Are you sure you want to remove this player?",
      () => {
        handleCallToRemoveAPlayer(name);
      }
    );
  };

  return isDesktop ? (
    <FlexContainer
      position="relative"
      direction="column"
      height="100%"
      width="40%"
      border={`1px solid ${theme.colors.lightGray}`}
      borderradius="5px"
      bgcolor={theme.colors.bodyPrimary}
      alignitems="center">
      <FlexContainer width="96%" margintop="2px" alignitems="center">
        <Container
          textalign="left"
          width="70%"
          paddingbottom="10px"
          paddingtop="10px"
          fontSize="1.2rem"
          color={theme.colors.purplePrimary}
          bordertopleftradius="5px"
          bordertoprightradius="5px">
          Team Members
        </Container>

        <Button
          width="27%"
          minWidth="105px"
          text="Share league code"
          fontSize="max(0.5rem,0.7vw)"
          fontcolor={theme.colors.purplePrimary}
          backgroundcolor={theme.colors.lightAliceBlue}
          border={`1px solid ${theme.colors.lightGrey5}`}
          onClick={onShareLeagueClick}
        />
      </FlexContainer>

      <FlexContainer width="96%" marginleft="7px" margintop="5px">
        <Container
          textalign="left"
          width="70%"
          fontSize="1rem"
          color="black"
          fontWeight="bold">
          Player
        </Container>
        <Container
          textalign="left"
          width="30%"
          fontSize="1rem"
          color="black"
          fontWeight="bold">
          Manage
        </Container>
      </FlexContainer>

      {!currentLeagueMembers && !errorMessageOnRejection && <Spinner />}

      {currentLeagueMembers && currentLeagueMembers.length > 0 && (
        <FlexContainer
          width="96%"
          maxheight="400px"
          overflow="auto"
          direction="column"
          marginleft="7px"
          margintop="15px"
          rowgap="10px">
          {currentLeagueMembers.map((member) => {
            return (
              <TeamMemberRowItem
                key={`TeamMemberRowItem${member.punterAlias}`}
                name={member.punterAlias}
                callback={(name) => {
                  getCallback(name);
                }}
                isOwner={member.punterId === Number(punterId)}
              />
            );
          })}
        </FlexContainer>
      )}

      {currentLeagueMembers && currentLeagueMembers.length === 0 && (
        <Container
          fontStyle="italic"
          width="96%"
          margintop="55px"
          paddingleft="10px">
          {" "}
          {`There are no members in the league ${currentLeague.leagueName}.`}
        </Container>
      )}
      {!currentLeagueMembers && errorMessageOnRejection && (
        <Container
          fontStyle="italic"
          width="80%"
          color="red"
          fontSize="0.8rem"
          margintop="55px"
          padding="10px">
          {" "}
          {errorMessageOnRejection}
        </Container>
      )}
    </FlexContainer>
  ) : (
    <FlexContainer
      direction="column"
      width="100%"
      bgcolor={theme.colors.lightAliceBlue}
      borderradius="5px"
      border={`1px solid ${theme.colors.purplePrimary}`}>
      <LeagueScrollerTabsMob
        leagues={leagues}
        currentLeague={currentLeague}
        onLeagueTabClick={onLeagueTabClick}
      />

      <Container
        maxheight="400px"
        minheight="200px"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px"
        overflow="auto"
        width="100%"
        margintop="10px"
        position="relative"
        marginbottom="10px">
        {!currentLeagueMembers && <Spinner />}

        {currentLeagueMembers?.length === 0 && (
          <Container fontStyle="italic" width="100%" margintop="35px">
            {" "}
            {`There are no members for the league ${currentLeague.leagueName}`}{" "}
          </Container>
        )}

        {currentLeagueMembers?.length! > 0 ? (
          <FlexContainer direction="column" rowgap="5px" marginleft="10px">
            {currentLeagueMembers?.map((member) => {
              return (
                <TeamMemberRowItem
                  key={`leaguemember${member.punterAlias}`}
                  name={member.punterAlias}
                  callback={(name) => {
                    getCallback(name);
                  }}
                  isOwner={member.punterId === Number(punterId)}
                />
              );
            })}
          </FlexContainer>
        ) : null}
      </Container>
    </FlexContainer>
  );
};

export default MembersView;
