export enum TargetDeviceWidth {
  mobile = 280,
  mediumMobile = 400,
  largeMobile = 590,
  smallDesktop = 769,
  mediumDesktop = 970,
  largeDesktop = 1370,
  extraLargeDesktop = 1680
}

export enum TargetDeviceHeight {
  iPhoneSE = 667
}

export const mediaWidthSizeBetween = (
  targetMinDevice: TargetDeviceWidth | number,
  targetMaxDevice: TargetDeviceWidth | number,
  styles: string
): string => `
  @media only screen and (min-width: ${targetMinDevice}px ) and (max-width: ${targetMaxDevice}px) {
    ${styles}
  }
`;

export const mediaWidthSizeAbove = (
  targetMinDevice: TargetDeviceWidth | number,
  styles: string
): string => `
  @media only screen and (min-width: ${targetMinDevice + 1}px ) {
    ${styles}
  }
`;

export const mediaWidthSizeBelow = (
  targetMaxDevice: TargetDeviceWidth | number,
  styles: string
): string => `
  @media only screen and (max-width: ${targetMaxDevice}px ) {
    ${styles}
  }
`;

export const mediaSizeBetween = (
  targetMinDevice: TargetDeviceHeight,
  targetMaxDevice: TargetDeviceHeight,
  styles: string
): string => `
  @media only screen and (min-height: ${targetMinDevice}px ) and (max-height: ${targetMaxDevice}px) {
    ${styles}
  }
`;

export const mediaSizeAbove = (
  targetMinDevice: TargetDeviceHeight,
  styles: string
): string => `
  @media only screen and (min-height: ${targetMinDevice + 1}px ) {
    ${styles}
  }
`;

export const mediaSizeBelow = (
  targetMaxDevice: TargetDeviceHeight,
  styles: string
): string => `
  @media only screen and (max-height: ${targetMaxDevice}px ) {
    ${styles}
  }
`;
