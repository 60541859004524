import { memo } from "react";

import Container from "../../../../bricks/container";
import ValueDisplay from "./valueDisplay";

export interface IItemYourBestCourse {
  courseName: string;
  average: number | string;
  punterAverage: number | string;
}

const ItemYourBestCourse = ({
  courseName,
  punterAverage,
  average
}: IItemYourBestCourse) => {
  const isDataAvailable =
    courseName !== "undefined" &&
    punterAverage !== "undefined" &&
    average !== "undefined";

  return (
    <>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.9 : 0.6}rem`}
        lineheight="1"
        fontWeight={isDataAvailable ? "bold" : "normal"}>
        {isDataAvailable && (
          <span>
            Your best course by Win percentage is{" "}
            <ValueDisplay value={courseName} /> at {average}%.
          </span>
        )}
        {!isDataAvailable && <span> Data not available.</span>}
      </Container>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.7 : 0.6}rem`}
        fontStyle="italic">
        {isDataAvailable &&
          `Punter average for ${courseName} is ${punterAverage}%.`}
        {!isDataAvailable && <span> Data not available.</span>}
      </Container>
    </>
  );
};

export default memo(ItemYourBestCourse);
