import { useEffect, useMemo, useRef, useState } from "react";
import theme from "../../../../../../../theme";
import FlexContainer from "../../../../../../bricks/flexContainer";
import { isToday } from "../../../../../../../utils/dateUtils";
import MeetingCountdown from "./meetingCountdown";
import MeetingDropdown from "./meetingDropdown";
import { SELECTION_CUTOFF_TIME_IN_MINUTES } from "../../../../../../../constants";
import NameAndJackpot from "../common/nameAndJackpot";
import { IMeetingRace } from "../../../../../../../store/features/meetingsSlice";
import PointsAndRank from "../common/pointsAndRank";
import MeetingBaseContainer from "../common/meetingBaseContainer";

export interface IMeetingRankData {
  rank: number;
  points: number;
  totalPunters: number;
  meetingId: string;
}

interface IMeetingCard {
  width?: string;
  contentToOpen?: JSX.Element;
  raceCourseName: string;
  time?: string;
  jackpot: string;
  hasSelections: boolean;
  rankData: IMeetingRankData | null;
  isAbandoned: boolean;
  isPostponed: boolean;
  allRaces: IMeetingRace[];
  meetingId: string;
  canExpand: boolean;
  raceColor: string;
  onExpandCallback: (meetingId: string) => void;
}

const MeetingCardMob = ({
  width,
  contentToOpen,
  raceCourseName,
  time,
  jackpot,
  hasSelections,
  rankData,
  isAbandoned,
  isPostponed,
  allRaces,
  canExpand,
  meetingId,
  raceColor,
  onExpandCallback
}: IMeetingCard) => {
  const [toShowDropDown, setToShowDropDown] = useState<boolean>(false);
  const [isSelectionOpen, setIsSelectionOpen] = useState<boolean>(true);
  const [toShowNextRaceTimer, setToShowNextRaceTimer] = useState(false);
  const [nextUpComingRace, setNextUpComingRace] = useState<IMeetingRace>();
  const remainingRacesRef = useRef<IMeetingRace[]>(allRaces.concat());
  const currentIndex = useRef<number>(-1);
  const canShowTimer = useMemo(() => isToday(time!), [time]);
  const [toShowTimer, setToShowTimer] = useState<boolean>(canShowTimer);

  const hasAtLeastOneRacePending = () => {
    return (
      !isAbandoned &&
      new Date(allRaces[allRaces.length - 1].raceTime).getTime() > Date.now()
    );
  };

  const selectionClosingTime = useMemo(() => {
    if (!hasAtLeastOneRacePending()) return null;

    const firstRaceDate = new Date(time!);
    const cutOffTimeOffset = SELECTION_CUTOFF_TIME_IN_MINUTES * 60 * 1000;

    const selectionCloseTime = firstRaceDate.getTime() - cutOffTimeOffset;

    if (selectionCloseTime <= Date.now()) {
      currentIndex.current = remainingRacesRef.current.findIndex(
        (race) => new Date(race.raceTime).getTime() > Date.now()
      );

      setNextUpComingRace(allRaces[currentIndex.current]);
      setIsSelectionOpen(false);
      setToShowNextRaceTimer(true);
      return null;
    }

    return selectionCloseTime;
  }, [time]);

  const onCardClick = () => {
    setToShowDropDown(!toShowDropDown);
    onExpandCallback(meetingId);
    if (canShowTimer) setToShowTimer(!toShowTimer);
  };

  const getColorForRankContainer = () => {
    if (isAbandoned) return theme.colors.abandonedPink;

    if (hasSelections) {
      return raceColor;
    }
    if (isSelectionOpen) return theme.raceColors(raceCourseName);
    return toShowDropDown ? raceColor : theme.colors.pickNowRed;
  };

  const onCloseClick = () => {
    setToShowDropDown(false);
    if (canShowTimer) setToShowTimer(true);
  };

  const getJackpot = () => {
    return parseInt(jackpot);
  };

  const onCountdownComplete = () => {
    setToShowTimer(false);

    if (currentIndex.current + 1 < allRaces.length) {
      setToShowNextRaceTimer(true);
      ++currentIndex.current;

      setNextUpComingRace(allRaces[currentIndex.current]);

      setToShowTimer(true);
    } else {
      setToShowNextRaceTimer(false);
    }
  };

  const getNextRace = (): number | null => {
    if (!toShowNextRaceTimer) return null;

    if (canShowTimer) {
      const next = nextUpComingRace?.raceTime;

      return new Date(next!).getTime();
    }

    return 0;
  };
  const getNextRaceNumber = () => {
    return Number(nextUpComingRace?.raceNumber);
  };

  useEffect(() => {
    if (!canExpand && toShowDropDown) {
      onCloseClick();
    }
  }, [canExpand]);

  useEffect(() => {
    if (!hasAtLeastOneRacePending()) {
      setToShowTimer(false);
    }
  }, [allRaces]);

  return (
    <FlexContainer
      direction="column"
      alignitems="center"
      width={width ?? "100%"}
      height="max-content">
      <MeetingBaseContainer
        width="99%"
        toShowShadow={false}
        toShowBorder
        backgroundColor={
          toShowDropDown ? theme.colors.meetingsLightGrey : "white"
        }
        borderColor={raceColor ?? "red"}>
        <NameAndJackpot
          width="75%"
          clickCallback={!toShowDropDown ? onCardClick : onCloseClick}
          raceCourseName={raceCourseName}
          startTime={time!}
          jackpot={getJackpot()}
          isAbandoned={isAbandoned}
        />

        <PointsAndRank
          backgroundColor={getColorForRankContainer()}
          clickCallback={onCardClick}
          hasSelections={hasSelections}
          rank={rankData?.rank ?? 0}
          points={rankData?.points ?? 0}
          totalPunters={rankData?.totalPunters ?? 0}
          isSelectionOpen={isSelectionOpen}
          isAbandoned={isAbandoned}
          isPostponed={isPostponed}
          meetingId={rankData?.meetingId!}
        />
      </MeetingBaseContainer>

      {toShowTimer && (
        <MeetingCountdown
          width="99%"
          meetingStartTime={selectionClosingTime}
          completionCallback={onCountdownComplete}
          nextRaceTime={getNextRace()}
          nextRaceNumber={getNextRaceNumber()}
        />
      )}

      {toShowDropDown && (
        <MeetingDropdown
          color={theme.colors.meetingsLightGrey}
          borderColor={raceColor ?? "red"}
          contentToOpen={contentToOpen!}
          closureCallback={onCloseClick}
          isAbandoned={isAbandoned}
        />
      )}
    </FlexContainer>
  );
};

export default MeetingCardMob;
