import { useContext } from "react";
import { useSelector } from "react-redux";
import Icon from "@assets/our-success.svg";
import BoxMessageWithIcon from "./common/boxMessageWithIcon";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";
import { RootState } from "../../../../store/store";
import {
  getDailyPlayers,
  getJackpotPrizes,
  getJackpotWinners,
  getTotalStakesPaidOut
} from "./preLoginUtil";

const OurSuccessDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const { settings } = useSelector((state: RootState) => state.settings);

  const fontSize = isDesktop ? `${theme.font.size.s}` : "0.8rem";

  return (
    <BoxMessageWithIcon image={Icon} gap="15px" isDesktop={isDesktop}>
      <>
        <TextInSpan
          fontSize="1rem"
          fontWeight="bold"
          zIndex={20}
          lineHeight={1.5}>
          Our Success
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {"Our platform has "}
          <b>celebrated {getJackpotWinners(settings)} jackpot winners,</b>
          {" paying out a staggering "}
          <b>R{getJackpotPrizes(settings)} in jackpot prizes.</b>
          {" Overall, we distributed "}
          <b>R{getTotalStakesPaidOut(settings)} in total prize money</b>,
          highlighting our commitment
          {" to rewarding our dedicated players."}
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {"On average, "}
          <b>we host {getDailyPlayers(settings)} players daily</b>
          {" each vying for a share of our guranteed prize pools."}
        </TextInSpan>
      </>
    </BoxMessageWithIcon>
  );
};

export default OurSuccessDisplay;
