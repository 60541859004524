interface ICarousalItem {
  type: string;
  content: any;
}

const itemImageStyle = {
  width: "100%",
  height: "auto",
  objectFit: "cover"
};
const itemVideoStyle = {
  width: "100%",
  height: "20%"
};

const CarousalItem = ({ type, content }: ICarousalItem) => (
  <>
    {type === "image" && <img src={content} style={itemImageStyle} />}
    {type === "video" && (
      <video src={content} style={itemVideoStyle} autoPlay />
    )}
  </>
);

export default CarousalItem;
