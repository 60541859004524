import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BetSlipContainer,
  ConfirmationButton,
  ConfirmationButtonsDiv,
  Container,
  InnerContainer,
  ModalBody,
  Overlay,
  StyledHeading,
  StyledLabel,
  SubmissionWaitingContainer,
  SuccessHeading
} from "./betSlipConfirmation.style";
import LoadingSpinner from "./loadingSpinner";
import ConfirmationFailed from "./betSlipConfirmationFailed";
import { betStrikeResponseSelector } from "../../../../../store/features/newBetSlipSlice";
import ConfirmationTotals from "./betSlipConfirmationTotals";

const BET_PROCESSING = "Your bets are being processed";
const BET_SUCCESS = "All your bets have been placed successfully";
const BET_FAILED = "Some of your bets could not be placed";

interface IBetSlipConfirmation {
  isProccessing: boolean;
  backToBetslipCallback: () => void;
  backToHomeCallback: () => void;
  retryFailedBetsCallback: () => void;
}

const BetslipConfirmation = ({
  isProccessing,
  backToBetslipCallback,
  backToHomeCallback,
  retryFailedBetsCallback
}: IBetSlipConfirmation) => {
  const betStrikeResonse = useSelector(betStrikeResponseSelector);
  const [totalBets, setTotalBets] = useState(0);
  const [failedBetRequests, setFailedBetRequests] = useState(
    betStrikeResonse?.failedBetRequests ?? null
  );
  const [hasFailedBets, setHasFailedBets] = useState(false);

  useEffect(() => {
    if (betStrikeResonse !== null) {
      setTotalBets(betStrikeResonse.totalBets);
      setFailedBetRequests(betStrikeResonse.failedBetRequests);
      setHasFailedBets(betStrikeResonse.failedBetRequests?.length !== 0);
    }
  }, [betStrikeResonse]);

  const modalHeading =
    hasFailedBets && failedBetRequests?.length !== 0 ? BET_FAILED : BET_SUCCESS;

  const backToBetslip = () => {
    backToHomeCallback();
    backToBetslipCallback();
  };

  return (
    <Container id="betslip_main_container">
      <InnerContainer>
        <Overlay id="betslip_overlay" />
        <BetSlipContainer>
          <>
            {window.scrollTo({ top: 0, behavior: "smooth" })}
            {isProccessing ? (
              <SubmissionWaitingContainer>
                <LoadingSpinner />
                <StyledLabel> {BET_PROCESSING} </StyledLabel>
              </SubmissionWaitingContainer>
            ) : (
              <ModalBody>
                {!hasFailedBets && <SuccessHeading>FANTASTIC!</SuccessHeading>}
                <StyledHeading isFailedHeading={hasFailedBets}>
                  {modalHeading}
                </StyledHeading>
                {!hasFailedBets && (
                  <>
                    <ConfirmationTotals
                      totalBets={totalBets}
                      totalFailedBets={failedBetRequests!.length}
                    />
                    <ConfirmationButtonsDiv>
                      <ConfirmationButton width="250px" onClick={backToBetslip}>
                        Back To Betslip
                      </ConfirmationButton>
                      <ConfirmationButton
                        width="200px"
                        onClick={backToHomeCallback}>
                        Back To Home
                      </ConfirmationButton>
                    </ConfirmationButtonsDiv>
                  </>
                )}
                {hasFailedBets && (
                  <ConfirmationFailed
                    failedBetRequests={failedBetRequests}
                    totalBets={totalBets}
                    backToBetSlipCallback={backToBetslip}
                    backToHomeCallback={backToHomeCallback}
                    retryFailedBetsCallback={retryFailedBetsCallback}
                  />
                )}
              </ModalBody>
            )}
          </>
        </BetSlipContainer>
      </InnerContainer>
    </Container>
  );
};

export default BetslipConfirmation;
