import styled from "styled-components";
import theme from "../../../../theme";

interface IOptionalInteraction {
  hasNoInteraction?: boolean;
}

interface IMiniContainerProps extends IOptionalInteraction {
  isDropdown?: boolean;
  openSelections?: boolean;
}

export const MainContainerWeb = styled.div<IOptionalInteraction>`
  height: 50px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.hasNoInteraction ? "auto" : "pointer")};
  box-shadow: 0px 0px 8px ${theme.colors.cardShadow};
  justify-content: space-evenly;
`;

export const MiniContainerWeb = styled.div<IOptionalInteraction>`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 35px;
  background: ${theme.colors.resultsMercury};
  border-radius: 4px;
  cursor: inherit;
  @media screen and (max-width: 1000px) {
    width: 25px;
    height: 25px;
  }
`;

export const InfoText = styled.div`
  font-size: ${theme.font.size.sm};
  color: black;
  width: fit-content;
  align-content: center;
  cursor: inherit;
  @media screen and (max-width: 1000px) {
    font-size: ${theme.font.size.s};
  }
`;

export const MiniContainerTextWeb = styled.div`
  font-size: ${theme.font.size.sm};
  color: ${theme.colors.titleAndTextPurple};
  width: 40%;
  font-weight: bold;
  align-content: center;
  cursor: inherit;
`;

export const MiniDropdownWeb = styled.div<IMiniContainerProps>`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 60px;
  height: 35px;
  background: ${(props) =>
    props.openSelections ? "white" : theme.colors.cardSelected};
  border: 1px solid
    ${(props) =>
      props.openSelections
        ? theme.colors.titleAndTextPurple
        : theme.colors.cardSelected};
  border-radius: 4px;

  @media screen and (max-width: 1000px) {
    width: 50px;
    height: 25px;
  }
  cursor: inherit;
`;

export const MiniContainerDropdownWeb = styled.img<IMiniContainerProps>`
  color: ${(props) =>
    props.openSelections ? "white" : theme.colors.cardSelected};
  cursor: inherit;
  width: 45%;
  height: auto;
  margin-top: ${(props) => (props.openSelections ? "0px" : "5px")};
  margin-bottom: ${(props) => (props.openSelections ? "5px" : "0x")};
  transform: ${(props) => (props.openSelections ? "rotate(180deg)" : "none")};
`;

export const LockContainerWeb = styled.img<IMiniContainerProps>`
  color: ${(props) =>
    props.openSelections ? "white" : theme.colors.cardSelected};
  cursor: inherit;
  width: 33%;
  height: auto;
  margin-top: 7%;
  margin-bottom: 7%;
`;
