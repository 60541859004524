import { useEffect, useMemo, useReducer } from "react";
import { useSelector } from "react-redux";
import { LuckyPickContext } from "./luckyPickContext";
import { RootState } from "../../../../store/store";
import LuckyPickReducer, {
  luckyPickReducerInitialState
} from "../store/luckyPickReducer";
import { getCombos, getWinPlaceCombos } from "../utils/luckypickUtils";
import { IBetslipBetType } from "../../../../store/features/betslipSlice";

import { LP_ACTIONS } from "../store/luckyPickActions";
import useRandomHorses from "../hooks/useRandomHorses";
import { DEFAULT_STAKES } from "../constants/luckyPickConstants";

const LuckyPickPovider = (props: any) => {
  const { children } = props;

  const { bets, placebets } = useSelector((state: RootState) => state.betslip);
  const { limits } = useSelector((state: RootState) => state.betslipNew);

  const winBets = bets;
  const placeBets = useMemo(
    () => placebets?.filter((item) => item.eventDetailOfferedOdds >= 1),
    [placebets]
  );

  const winDoubleCombos: IBetslipBetType[][] = useMemo(
    () =>
      getCombos(2, winBets!, [])?.map((item) => {
        return item.map((index: number) => winBets![index]);
      }),
    [bets]
  );

  const placeTrebleCombos: IBetslipBetType[][] = useMemo(
    () =>
      getCombos(3, placeBets!, [])?.map((item) => {
        return item.map((index: number) => placeBets![index]);
      }),
    [placeBets]
  );

  const winPlaceCombos: IBetslipBetType[][] = useMemo(
    () => getWinPlaceCombos(winBets!, placeBets!),
    [winBets, placeBets]
  );

  const {
    currentWinDoubleCombo,
    currentPlaceTrebleCombo,
    currentWinPlaceDoubleCombo,
    generateWinDouble,
    generatePlaceTreble,
    generateWinPlaceDouble,
    refreshWinDouble,
    refreshPlaceTreble,
    refreshWinPlaceDouble,
    handleSingleHorseSelectedFromOutside,
    switchWinPlaceDouble
  } = useRandomHorses(
    winBets!,
    placeBets!,
    winDoubleCombos,
    placeTrebleCombos,
    winPlaceCombos
  );

  const [lpState, lpDispatch] = useReducer(LuckyPickReducer, {
    ...luckyPickReducerInitialState,
    winBetHorses: winBets ?? [],
    placeBetHorses: placeBets ?? [],
    winDoubleCombos,
    placeTrebleCombos,
    winPlaceCombos,
    randomizer: {
      generateWinDouble,
      generatePlaceTreble,
      generateWinPlaceDouble,
      refreshWinDouble,
      refreshPlaceTreble,
      refreshWinPlaceDouble,
      handleSingleHorseSelectedFromOutside,
      switchWinPlaceDouble
    },
    limits
  });

  useEffect(() => {
    if (currentWinDoubleCombo.length > 0) {
      lpDispatch({
        type: LP_ACTIONS.UPDATE_BET_TYPE_WIN_DOUBLE,
        horses: [...currentWinDoubleCombo]
      });
    }
  }, [currentWinDoubleCombo]);

  useEffect(() => {
    if (currentPlaceTrebleCombo?.length > 0) {
      lpDispatch({
        type: LP_ACTIONS.UPDATE_BET_TYPE_PLACE_TREBLE,
        horses: [...currentPlaceTrebleCombo],
        stake:
          lpState.betTypePlaceTreble.stake > 0
            ? lpState.betTypePlaceTreble.stake
            : DEFAULT_STAKES.PLACE_TREBLE
      });
    }
  }, [currentPlaceTrebleCombo]);

  useEffect(() => {
    if (currentWinPlaceDoubleCombo?.length > 0) {
      lpDispatch({
        type: LP_ACTIONS.UPDATE_BET_TYPE_WIN_PLACE_DOUBLE,
        horses: [...currentWinPlaceDoubleCombo],
        stake:
          lpState.betTypeWinPlaceDouble.stake > 0
            ? lpState.betTypeWinPlaceDouble.stake
            : DEFAULT_STAKES.WIN_PLACE_DOUBLE
      });
    }
  }, [currentWinPlaceDoubleCombo]);

  const contextData = useMemo(() => {
    return { lpState, lpDispatch };
  }, [lpState, lpDispatch]);

  return (
    <LuckyPickContext.Provider value={contextData}>
      {children}
    </LuckyPickContext.Provider>
  );
};

export default LuckyPickPovider;
