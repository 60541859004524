import styled from "styled-components";
import Container from "../../../bricks/container";
import theme from "../../../../theme";

const GridText = styled.span`
  font-size: 0.9rem;
  height: fit-content;
  line-height: 1;
`;

interface IGridMessage {
  message: string;
}

const GridMessage = ({ message }: IGridMessage) => {
  return (
    <Container
      bgcolor="white"
      border={`1px solid ${theme.colors.purplePrimary}`}
      height="29px"
      display="grid"
      placecontent="center">
      <GridText>{message}</GridText>
    </Container>
  );
};

export default GridMessage;
