import theme from "../../../../../theme";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import { MILESTONE_ITEM_TYPES } from "../../constants/friendsLeaguesConstants";
import ItemYourBestCourse, { IItemYourBestCourse } from "./itemYourBestCourse";
import ItemYourBestFinish, { IItemYourBestFinish } from "./itemYourBestFinish";
import ItemYourHighestPoints, {
  IItemYourHighestPoints
} from "./itemYourHighestPoint";
import ItemYourMostWins, { IItemYourMostWins } from "./itemYourMostWins";

interface IMilestoneItem {
  itemType: string;
  bestCourseData?: IItemYourBestCourse;
  mostWinsData?: IItemYourMostWins;
  bestFinishData?: IItemYourBestFinish;
  highestPointData?: IItemYourHighestPoints;
}

const MilestoneItem = ({
  itemType,
  bestCourseData,
  mostWinsData,
  bestFinishData,
  highestPointData
}: IMilestoneItem) => {
  const { courseName, average, punterAverage } = bestCourseData ?? {};
  const { mostWin, highestWin, highestWinCourse, highestWinDate } =
    mostWinsData ?? {};
  const {
    myBestRank,
    totalPlayers,
    courseName: courseNameOfBestFinish,
    date,
    outPerformed
  } = bestFinishData ?? {};
  const {
    point,
    highest,
    courseName: courseNameOfHighestpoints,
    date: dateOfHighestpoints
  } = highestPointData ?? {};

  return (
    <FlexContainer width="97%" columngap="5px">
      <Container
        width="5px"
        height="80%"
        bgcolor={theme.colors.purplePrimary}
        borderradius="4px"
      />

      <FlexContainer
        direction="column"
        alignitems="left"
        rowgap="5px"
        width="98%">
        {itemType === MILESTONE_ITEM_TYPES.bestCourse && (
          <ItemYourBestCourse
            courseName={courseName!}
            average={average!}
            punterAverage={punterAverage!}
          />
        )}
        {itemType === MILESTONE_ITEM_TYPES.mostWins && (
          <ItemYourMostWins
            mostWin={mostWin!}
            highestWin={highestWin!}
            highestWinCourse={highestWinCourse!}
            highestWinDate={highestWinDate!}
          />
        )}
        {itemType === MILESTONE_ITEM_TYPES.bestFinish && (
          <ItemYourBestFinish
            myBestRank={myBestRank!}
            totalPlayers={totalPlayers!}
            courseName={courseNameOfBestFinish!}
            date={date!}
            outPerformed={outPerformed!}
          />
        )}
        {itemType === MILESTONE_ITEM_TYPES.highestPoints && (
          <ItemYourHighestPoints
            point={point!}
            highest={highest!}
            courseName={courseNameOfHighestpoints!}
            date={dateOfHighestpoints!}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default MilestoneItem;
