import GetLeaderboardPosition from "../../../views/newHomePage/common/leaderbaordIconUtil";
import { RankContainer, RankText, RankIcon } from "./leaderboardRank.style";

export interface ILeaderboardRank {
  punterRank: number;
}

const LeaderboardRank = ({ punterRank }: ILeaderboardRank) => {
  const isTopThree = punterRank > 0 && punterRank <= 3;

  return isTopThree ? (
    <RankContainer>
      <RankIcon src={GetLeaderboardPosition[punterRank]} />
    </RankContainer>
  ) : (
    <RankContainer>
      <RankText>{punterRank}</RankText>
    </RankContainer>
  );
};

export default LeaderboardRank;
