import { useEffect, useRef, useState } from "react";
import { IBetRequestInfo } from "../../betslip.models";
import ConfirmationTotals from "./betSlipConfirmationTotals";
import {
  ConfirmationButtonsDiv,
  ConfirmationButton,
  ConfirmationFailedTitle,
  DisabledConfirmationButton,
  DisabledText
} from "./betSlipConfirmation.style";
import ConfirmationModal from "../../../modals/confirmationModal";
import { ButtonStyle } from "../betSlipSubmission/betSlipSubmission.style";
import FailedbetsGroupedView from "./failedBetsGroupings/failedbetsGroupedView";

interface IConfirmationFailed {
  failedBetRequests: IBetRequestInfo[] | null;
  totalBets: number;
  backToBetSlipCallback: () => void;
  backToHomeCallback: () => void;
  retryFailedBetsCallback: () => void;
}

export interface IFailedBetItemData {
  betTitle: string;
  payout: number;
  totalStake: number;
  failureReason: string;
  totalOdds: number;
}

const ConfirmationFailed = ({
  failedBetRequests,
  totalBets,
  backToBetSlipCallback,
  backToHomeCallback,
  retryFailedBetsCallback
}: IConfirmationFailed) => {
  const [showRetryConfimationModal, setShowRetryConfimationModal] =
    useState(false);

  const failedBetsInGroups = useRef<{
    [groupId: string]: IFailedBetItemData[];
  }>({});

  const totalFailedBets = failedBetRequests!.map(
    (request) => request.failureReason
  ).length;

  useEffect(() => {
    if (failedBetRequests) {
      failedBetsInGroups.current = {};

      failedBetRequests.forEach((request) => {
        const { failureReason } = request;
        const { betTitle, payout, totalStake, totalOdds } =
          request.request.combinatonInfo;
        const failedBetItemData: IFailedBetItemData = {
          betTitle,
          payout,
          totalStake,
          failureReason,
          totalOdds
        } as IFailedBetItemData;

        if (!failedBetsInGroups.current[betTitle])
          failedBetsInGroups.current[betTitle] = [];

        failedBetsInGroups.current[betTitle].push(failedBetItemData);
      });
    }
  }, [failedBetRequests]);

  const closeModalCallback = () => {
    setShowRetryConfimationModal(false);
  };

  return (
    <>
      <ConfirmationTotals
        totalBets={totalBets}
        totalFailedBets={totalFailedBets}
      />
      <ConfirmationButtonsDiv>
        <DisabledConfirmationButton
          width="250px"
          onClick={() => setShowRetryConfimationModal(true)}
          disabled>
          Retry Failed Bets
        </DisabledConfirmationButton>
        <ConfirmationButton width="200px" onClick={backToBetSlipCallback}>
          Back To Betslip
        </ConfirmationButton>
      </ConfirmationButtonsDiv>
      <DisabledText>Retrying failed bets is currently disabled</DisabledText>
      <ConfirmationFailedTitle>
        <b>FAILED BETS</b>
      </ConfirmationFailedTitle>

      <FailedbetsGroupedView failedBetsInGroups={failedBetsInGroups.current} />

      <ConfirmationModal
        isModalOpen={showRetryConfimationModal}
        modalTitle="RETRY FAILED BETS"
        modalDescription="Are you sure you want to retry your failed bets?"
        onYesCallback={retryFailedBetsCallback}
        onNoCallback={closeModalCallback}
        left={50}
        bottom={35}
      />
      <ButtonStyle margintop="20px" width="200px" onClick={backToHomeCallback}>
        Back To Home
      </ButtonStyle>
    </>
  );
};

export default ConfirmationFailed;
