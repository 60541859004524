import styled from "styled-components";
import theme from "../../../theme";

export const BetSlipHoverParent = styled.div`
  position: absolute;
  z-index: 80;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-family: ${theme.font.family.base};
`;

export const BetSlipBackGroundShade = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  top: 0;
  position: fixed;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  place-content: center;
  top: 0;
  position: fixed;
`;
