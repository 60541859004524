import styled from "styled-components";
import { useSelector } from "react-redux";
import theme from "../../../../theme";
import TextInSpan from "../../../bricks/textInSpan";
import {
  TargetDeviceHeight,
  mediaSizeBelow
} from "../../../../utils/mediaUtils";
import { RootState } from "../../../../store/store";
import { getTotalStakesPaidOut } from "./preLoginUtil";

const HousingDiv = styled.div`
  font-size: min(13vw, 3.8rem);
  min-width: 330px;
  width: 80%;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  display: flex;
  ${mediaSizeBelow(TargetDeviceHeight.iPhoneSE, `font-size: 2.5rem; `)};
`;

const PrizeMoneyDisplay = () => {
  const { settings } = useSelector((state: RootState) => state.settings);
  const payout = getTotalStakesPaidOut(settings);
  return (
    <HousingDiv>
      <TextInSpan
        fontSize="min(5.1vw,1.7rem)"
        lineHeight={1.5}
        color="white"
        shadow="2px 2px 6px black">
        Prize money paid out to date:
      </TextInSpan>
      <TextInSpan
        fontWeight="bold"
        lineHeight={1.5}
        color={theme.colors.yellowPrimary}
        shadow="2px 2px 6px black">
        R{payout}
      </TextInSpan>
    </HousingDiv>
  );
};

export default PrizeMoneyDisplay;
