import TimerClock from "@assets/count-down-timer-clock.svg";
import { useEffect } from "react";
import FlexContainer from "../../../../../../bricks/flexContainer";
import Image from "../../../../../../bricks/image";
import TextInSpan from "../../../../../../bricks/textInSpan";
import useCountdown from "../../../../../../../hooks/useCountdown";

interface IMeetingCountdown {
  width?: string;
  meetingStartTime: number | null;
  completionCallback: () => void;
  nextRaceTime: number | null;
  nextRaceNumber?: number;
}
const MeetingCountdown = ({
  width,
  meetingStartTime,
  completionCallback,
  nextRaceTime,
  nextRaceNumber
}: IMeetingCountdown) => {
  const {
    startCountDown,
    stopCountDown,
    resetWithNewTime,
    days,
    hours,
    minutes,
    seconds
  } = useCountdown(meetingStartTime ?? "");

  const canShow = () => {
    if (!meetingStartTime && Number.isNaN(nextRaceTime)) return false;

    return meetingStartTime ?? !!nextRaceTime;
  };

  useEffect(() => {
    if (meetingStartTime) startCountDown();
  }, []);

  useEffect(() => {
    if (nextRaceTime && !Number.isNaN(nextRaceTime)) {
      resetWithNewTime(nextRaceTime);
      startCountDown();
    }
  }, [nextRaceTime, nextRaceNumber]);

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      stopCountDown();

      setTimeout(() => {
        completionCallback();
      }, 200);
    }
  }, [days, hours, minutes, seconds]);

  return canShow() ? (
    <FlexContainer
      width={width}
      height="20px"
      bgcolor="black"
      border="1px solid black"
      borderbottomleftradius="7px"
      borderbottomrightradius="7px"
      margintop="-15px"
      zIndex={0}
      alignitems="center"
      justifycontent="center"
      columngap="1px"
      paddingtop="20px"
      paddingbottom="5px">
      <FlexContainer
        width="60%"
        justifycontent="flex-end"
        columngap="5px"
        alignitems="center">
        {meetingStartTime && !nextRaceTime && (
          <>
            <Image src={TimerClock} width="20px" scaleX={0.6} scaleY={0.6} />
            <TextInSpan
              marginTop="1px"
              lineHeight={1}
              color="white"
              fontSize="0.7rem"
              width="max-content"
              marginRight="5px">
              SELECTIONS CLOSE IN:
            </TextInSpan>
          </>
        )}

        {!!nextRaceTime && (
          <>
            <Image src={TimerClock} width="20px" scaleX={0.6} scaleY={0.6} />
            <TextInSpan
              marginTop="1px"
              color="white"
              fontSize="0.7rem"
              width="max-content"
              marginRight="3px"
              lineHeight={0.8}>
              TIME TO RACE <b> {`\u00A0${nextRaceNumber}`}</b> :
            </TextInSpan>
          </>
        )}
      </FlexContainer>

      <FlexContainer
        width="40%"
        height="100%"
        alignitems="center"
        margintop="1px">
        {meetingStartTime && !nextRaceTime && (
          <>
            <TextInSpan
              color="yellow"
              fontSize="0.8rem"
              fontWeight="bold"
              width="max-content"
              lineHeight={1}>
              {`0${hours}`.slice(-2)}:
            </TextInSpan>
            <TextInSpan
              color="yellow"
              fontSize="0.8rem"
              fontWeight="bold"
              width="max-content"
              lineHeight={1}>
              {`0${minutes}`.slice(-2)}:
            </TextInSpan>
            <TextInSpan
              color="yellow"
              fontSize="0.8rem"
              fontWeight="bold"
              width="max-content">
              {`0${seconds}`.slice(-2)}
            </TextInSpan>
          </>
        )}

        {!!nextRaceTime && (
          <>
            <TextInSpan
              marginTop="2px"
              color="yellow"
              fontSize="0.8rem"
              fontWeight="bold"
              width="max-content"
              lineHeight={1}>
              {`0${minutes}`.slice(-2)}:
            </TextInSpan>
            <TextInSpan
              marginTop="2px"
              color="yellow"
              fontSize="0.8rem"
              fontWeight="bold"
              width="max-content"
              lineHeight={1}>
              {`0${seconds}`.slice(-2)}
            </TextInSpan>
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  ) : null;
};

export default MeetingCountdown;
