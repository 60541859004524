import { useState } from "react";
import { IFailedBetItemData } from "../betSlipConfirmationFailed";
import FlexContainer from "../../../../../bricks/flexContainer";
import FailedBetItemDropdown from "./failedBetItemDropdown";

interface IFailedbetsGroupedView {
  failedBetsInGroups: { [groupId: string]: IFailedBetItemData[] };
}

const FailedbetsGroupedView = ({
  failedBetsInGroups
}: IFailedbetsGroupedView) => {
  const [openGroup, setOpenGroup] = useState<string>("");

  const groupNames = Object.keys(failedBetsInGroups);

  const handleGroupDropDownOpen = (title: string) => {
    setOpenGroup(title);
  };

  const canOpen = (groupName: string) => {
    if (openGroup === "") return false;
    if (groupName === openGroup) return true;

    return false;
  };

  return (
    <FlexContainer direction="column" width="70%">
      {groupNames.map((groupName: string) => {
        return (
          <FailedBetItemDropdown
            key={`failedBetGroup${groupName}`}
            title={groupName}
            items={failedBetsInGroups[groupName]}
            onOpenCallback={handleGroupDropDownOpen}
            toOpen={canOpen(groupName)}
          />
        );
      })}
    </FlexContainer>
  );
};

export default FailedbetsGroupedView;
