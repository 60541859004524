import styled from "styled-components";
import theme from "../../../theme";

export const ProfileContainer = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
  margin-bottom: 10px;
`;

export const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.titleAndTextPurple};
  align-items: flex-start;
`;

export const IconDiv = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 200;
`;

export const BurgerIconImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const ItemsDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background: white;
  width: 225px;
  border-radius: 5px;
  z-index: 200;
  top: 28px;
  right: 8px;
  row-gap: 5px;
  background-color: ${theme.colors.backgroundPurple};
  color: black;
`;

export const BackgroundShade = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  top: 0;
  position: fixed;
  z-index: 199;
  left: 0;
`;

export const AllLinkImagesDiv = styled.div`
  display: flex;
  width: 100%;
  column-gap: 2%;
  justify-content: space-between;
`;

export const ImageDiv = styled.div`
  display: grid;
  width: 32%;
  font-size: 0.8rem;
  font-weight: ${theme.font.weight.semiBold};
`;

export const LinkImage = styled.img`
  height: 44px;
  cursor: pointer;
`;
