import { useContext } from "react";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

interface IStatsValueDisplay {
  title: string;
  value: string | number;
  marginRight?: string;
}

const StatsValueDisplay = ({
  title,
  value,
  marginRight
}: IStatsValueDisplay) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <FlexContainer
      width="20%"
      direction="column"
      alignitems="left"
      margintop="10px"
      marginright={marginRight ?? "0px"}
      height="fit-content"
      marginbottom="5px">
      <FlexContainer
        direction="column"
        width="max-content"
        marginleft={isDesktop ? "10%" : "14%"}
        alignitems="center"
        maxwidth="70%">
        <Container
          textalign="center"
          width="fit-content"
          fontSize={`${isDesktop ? 0.9 : 0.7}rem`}
          height="fit-content"
          minheight="53px">
          {title}
        </Container>
        {value != null && (
          <Container
            textalign="left"
            fontSize={`${isDesktop ? 3 : 1.5}rem`}
            color={theme.colors.purplePrimary}
            fontWeight="bold"
            height="auto"
            lineheight="1"
            width="max-content">
            {value}
          </Container>
        )}
        {value == null && (
          <Container
            textalign="left"
            fontSize={`${isDesktop ? 0.7 : 0.5}rem`}
            color={theme.colors.purplePrimary}
            height="auto"
            lineheight="1">
            NA
          </Container>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default StatsValueDisplay;
