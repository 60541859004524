import RectSearchIcon from "@assets/magnifyglass-icon.svg";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import FlexContainer from "../../../bricks/flexContainer";
import { GetLeaderboardForMeetingWithPage } from "../../../../store/features/leaderboardSlice";
import {
  MainSearchContainer,
  SearchImageDiv,
  SearchInputDiv
} from "./newSearchBar.style";
import theme from "../../../../theme";
import { getPunterInfoFromSearch } from "../../../../store/features/friendsLeagueSlice";

interface ISearchBar {
  meetingId: number;
  isForFriendsLeague: boolean;
}

const NewSearchBar = ({ meetingId, isForFriendsLeague }: ISearchBar) => {
  const searchRef = useRef(null);
  const dispatch = useDispatch();

  const searchForFriends = (textToSearch: string) => {
    if (isForFriendsLeague) {
      dispatch(
        getPunterInfoFromSearch({
          pageId: 1,
          meetingId,
          numberPerPage: 10,
          searchFor: textToSearch
        })
      );
    } else {
      dispatch(
        GetLeaderboardForMeetingWithPage({
          pageId: 1,
          meetingId,
          numberPerPage: 10,
          searchFor: textToSearch
        })
      );
    }
  };

  return (
    <MainSearchContainer>
      <FlexContainer
        width="100%"
        height="100%"
        bgcolor={`${theme.colors.backgroundPurple}`}
        border={`1px solid  ${theme.colors.cardSelected}`}
        borderradius="8px"
        justifycontent="flex-end"
        paddingleft="8px"
        alignitems="center">
        <SearchInputDiv
          ref={searchRef}
          placeholder="Search for your friends"
          name="leaderboard-search-text"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchForFriends(searchRef?.current?.value);
            }
          }}
        />
        <SearchImageDiv
          src={RectSearchIcon}
          onClick={() => {
            searchForFriends(searchRef?.current?.value);
          }}
        />
      </FlexContainer>
    </MainSearchContainer>
  );
};

export default NewSearchBar;
