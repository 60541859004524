import styled from "styled-components";

export const MainDiv = styled.div`
  height: calc(100vh - 66px);
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
`;

export const TextBoxContainer = styled.div`
  width: 80%;
  height: fit-content;
  display: flex;
  column-gap: 20px;
`;
