import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { RootState } from "../../../../store/store";
import LabeledInput from "../../labled-input/labeledInput";
import Button from "../../../bricks/button";
import {
  submitAlias,
  checkAliasAvailability
} from "../../../../store/features/userSlice";
import hasBadWords from "../../../../utils/profanity";
import {
  AliasModalBody,
  AliasModalContainer,
  BackgroundContainer,
  OnBackgroundDiv,
  PunterNameSubText,
  PunterNameText,
  SeparatorDiv
} from "./aliasModal.style";
import {
  hasNoLetter,
  hasSpecialChars
} from "../../../../utils/validationUtils";
import theme from "../../../../theme";
import AliasSubmitButton from "./aliasSubmitButton";
import AliasMessage from "./aliasMessage";

const getMessage = (val: string): string => {
  let msg = "";

  if (hasBadWords(val)) msg = "Your alias contains profanity";

  if (val.length > 3 && val.length < 18) {
    if (hasNoLetter(val)) {
      msg = "Your alias must contain at least 1 letter.";
    }

    if (hasSpecialChars(val)) {
      return "Your alias can not contain special characters";
    }

    const valNumbers = val.match(/\d/gm) ?? [];
    if (valNumbers.length > 3) {
      msg = "Your alias cannot have more than 3 numbers";
    }
  } else if (val.length >= 18) {
    msg = "Your alias cannot be longer than 18 characters";
  } else {
    msg = "Your alias does not have enough characters";
  }

  return msg;
};

const AliasModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state: RootState) => state.user);
  const config = useSelector((state: RootState) => state.config.data);

  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [aliasRequiredMessage, setAliasRequiredMessage] = useState<string>("");
  const aliasInput = useRef<HTMLInputElement>(null);
  const [toShowNameAvailable, setToShowNameAvailable] =
    useState<boolean>(false);
  const [toShowIncorrect, setToShowIncorrect] = useState<boolean>(false);
  const [canSubmitAlias, setCanSubmitAlias] = useState<boolean>(false);
  const [toShow, setToShow] = useState(true);
  const [finalAlias, setFinalAlias] = useState<string>("");

  const onBackgroundClick = () => {
    setToShow(false);
    setCanSubmitAlias(false);
    setAliasRequiredMessage("");
    setToShowNameAvailable(false);
  };

  const onAvailabilityCheck = (response: any) => {
    if (!response.data) {
      setToShowNameAvailable(false);
    } else {
      setToShowNameAvailable(true);
      setCanSubmitAlias(response.data);
    }
  };

  const onAliasRequiredInput = (val: string) => {
    setToShowIncorrect(false);
    setToShowNameAvailable(false);

    setFinalAlias(val);

    const msg = getMessage(val);

    if (msg === "") {
      checkAliasAvailability({
        alias: val,
        callback: onAvailabilityCheck,
        config
      });
    } else {
      setCanSubmitAlias(false);
    }

    setAliasRequiredMessage(msg);
    setToShowNameAvailable(msg === "");
  };

  const onAliasSubmitClick = () => {
    if (canSubmitAlias) {
      setIsInProgress(true);
      setToShowNameAvailable(false);
      if (aliasInput.current) {
        aliasInput.current.value = "";
      }
      dispatch(
        submitAlias({
          alias: finalAlias,
          loginType: "Hollywoodbets",
          userName: userData.userName
        })
      );
    }
  };

  const onLearnHowToClick = () => {
    if (!isInProgress) navigate("/learntoplay");
  };

  useEffect(() => {
    setToShow(userData.aliasRequired);
    if (!userData.aliasRequired) {
      setIsInProgress(false);
    }
  }, [userData.aliasRequired]);

  useEffect(() => {
    if (userData.errorMessage.length > 1 && !toShowIncorrect) {
      setIsInProgress(false);
      setToShowIncorrect(true);
    }
  }, [userData.errorMessage]);

  const toShowModal = userData.aliasRequired && toShow;

  return (
    toShowModal && (
      <AliasModalContainer>
        <AliasModalBody>
          <OnBackgroundDiv onClick={onBackgroundClick} />
          <BackgroundContainer>
            <PunterNameText>Enter your punter alias</PunterNameText>
            <SeparatorDiv />
            <PunterNameSubText>
              This will be your name visible to all players.
            </PunterNameSubText>

            <LabeledInput
              width="92%"
              height="2.5rem"
              marginTop="5px"
              reference={aliasInput}
              isPassword={false}
              align="center"
              placeholder="Enter your punter alias"
              valueUpdater={onAliasRequiredInput}
            />

            <AliasMessage
              toShowNameAvailable={toShowNameAvailable}
              aliasRequiredMessage={aliasRequiredMessage}
              hasUserInput={finalAlias.length > 0}
              toShowIncorrect={toShowIncorrect}
            />

            <AliasSubmitButton
              canSubmitAlias={canSubmitAlias}
              isInProgress={isInProgress}
              hasErrorMessage={aliasRequiredMessage.length > 0}
              onAliasSubmitClick={onAliasSubmitClick}
            />

            <Button
              width="150px"
              height="40px"
              margintop="5px"
              border={`1px solid ${theme.colors.purplePrimary}`}
              backgroundcolor="white"
              onClick={onLearnHowToClick}
              fontcolor={theme.colors.purplePrimary}
              fontSize={theme.font.size.s}
              text="Learn How to Play"
            />
          </BackgroundContainer>
        </AliasModalBody>
      </AliasModalContainer>
    )
  );
};

export default AliasModal;
