import { styled } from "styled-components";
import { useEffect, useRef, useState, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import FlexContainer from "../bricks/flexContainer";
import "react-loading-skeleton/dist/skeleton.css";

import Container from "../bricks/container";
import { isHandHeldDevice } from "../../utils/deviceUtils";

import { MediaQueriesContext } from "../../contexts/mediaQueriesContext";

interface ITabElement {
  $isActive?: boolean;
}

const Tab = styled.div<ITabElement>`
  display: flex;
  background: ${(props) =>
    props.$isActive ? "linear-gradient(#FCEE21,#ECCB00)" : "white"};
  width: 100%;
  height: 1.5rem;
  color: ${(props) => (props.$isActive ? "#5C2D91" : "#764CBF")};
  text-align: center;
  font-size: 0.7rem;
  line-height: 1;
  align-items: center;
  justify-content: center;
  span {
    color: ${(props) => (props.$isActive ? "#5C2D91" : "#764CBF")};
    height: max-content;
  }
  border-radius: 6px;
  border: 1px solid #764cbf;
  cursor: pointer;
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1438px) and (max-width: 1593px) {
    font-size: 0.9rem;
    font-weight: bold;
  }

  @media screen and (min-width: 1594px) {
    font-size: 1rem;
    font-weight: bold;
  }
`;
interface ITabButton {
  $isDisabled?: boolean;
}
const TabButton = styled.div<ITabButton>`
  background: ${(props) => (props.$isDisabled ? "#efeff5" : "white")};
  width: 35px;
  height: 1.5rem;
  border-radius: 5px;
  border: ${(props) =>
    props.$isDisabled ? "1px solid #d0d0e2" : "1px solid #764CBF"};
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  pointer-events: ${(props) => (props.$isDisabled ? "none" : "")};
  color: ${(props) => (props.$isDisabled ? "#d0d0e2" : "inherit")};
  padding-bottom: 1px;
  padding-left: 1px;
  & label {
    line-height: 1;
    width: max-content;
  }
`;
export interface ITabscrollerTabData {
  label: string;
  metadata?: any;
}
export interface ITabScrollerData {
  data?: ITabscrollerTabData[] | null;
  callback?: (index: any) => void;
  currentIndex?: number;
  reset?: boolean;
}

const TabScroller = ({
  data,
  callback,
  currentIndex,
  reset
}: ITabScrollerData) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const hasInteracted = useRef<boolean>(false);
  const numTabsVisible: number = isDesktop ? 4 : 2;
  const dataLength: number = data?.length ?? 0;
  const [toDisableNext, setToDisableNext] = useState<boolean>(false);
  const [toDisablePrevious, setToDisablePrevious] = useState<boolean>(false);
  const scrollerContainer = useRef<HTMLDivElement | null>(null);
  const tabsParentContainer = useRef<HTMLDivElement | null>(null);
  const calculatedWidthOfScrollerInPerc =
    (100 / numTabsVisible) * dataLength >= 100
      ? (100 / numTabsVisible) * dataLength
      : 100;
  const currentScrollFromLeft = useRef<number>(0);
  const cutoffWidthForRightSccroll = useRef<number>(0);
  const oneTabAreaWidth = useRef<number>(0);
  const isInSliding = useRef<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(
    currentIndex && currentIndex >= 0 ? currentIndex : 0
  );

  const hasHorizontalScroll = useRef<boolean>(false);

  const isNextDisabled = useRef<boolean>(false);

  const tabScrollIndexCount = useRef<number>(
    currentIndex && currentIndex >= 0 ? currentIndex : 0
  );

  // for some reson the scrollerContainer was always returning null while unmounting, so had to create this ref to hold the ref.
  const refToScrollerContainer = useRef<HTMLDivElement | null>(null);

  const positionScroller = (
    isNext: boolean,
    behave: ScrollBehavior = "instant"
  ) => {
    if (tabsParentContainer?.current && scrollerContainer?.current) {
      const tabsContainer = tabsParentContainer.current;
      const scrollContainer = scrollerContainer.current;
      const distance = Math.ceil(tabsContainer.offsetWidth / dataLength);
      oneTabAreaWidth.current = Math.round(
        tabsContainer.offsetWidth / dataLength
      );
      currentScrollFromLeft.current = scrollContainer.scrollLeft;
      cutoffWidthForRightSccroll.current = Math.round(
        (dataLength - numTabsVisible) * (tabsContainer.offsetWidth / dataLength)
      );
      const offsetFromCurrentPosition =
        currentScrollFromLeft.current % oneTabAreaWidth.current;
      const effectiveDistance = Math.floor(
        oneTabAreaWidth.current - offsetFromCurrentPosition
      );
      const resolvedOffset =
        offsetFromCurrentPosition > 1
          ? offsetFromCurrentPosition
          : oneTabAreaWidth.current;
      const distanceWhenNotNext =
        activeIndex > 0 && !hasInteracted.current
          ? activeIndex * distance
          : -resolvedOffset;

      scrollContainer.scrollBy({
        left: isNext ? effectiveDistance : distanceWhenNotNext,
        behavior: behave
      });
    }
  };

  useEffect(() => {
    if (reset) {
      setActiveIndex(0);

      scrollerContainer.current?.scrollBy({
        left: -scrollerContainer.current.scrollLeft || 0,
        behavior: "instant"
      });
    }
  }, [reset]);

  const onNavButtonsClick = (isNext: boolean = true) => {
    if (!isInSliding.current) {
      isInSliding.current = true;
      hasInteracted.current = true;
      positionScroller(isNext, "smooth");
      isNextDisabled.current = false;

      if (isNext) {
        if (dataLength - ++tabScrollIndexCount.current === numTabsVisible) {
          isNextDisabled.current = true;
          setToDisableNext(true);
        }
      } else if (
        tabScrollIndexCount.current === 0 ||
        --tabScrollIndexCount.current === 0
      )
        setToDisablePrevious(true);
    }
  };

  const onTabItemClick = (tabIndex: number) => {
    hasInteracted.current = true;
    setActiveIndex(tabIndex);
    if (callback) callback(tabIndex);
  };

  useEffect(() => {
    if (data?.length) {
      hasHorizontalScroll.current = dataLength - numTabsVisible > 0;
      positionScroller(false);
    }
  }, [data]);

  const onScrollEnd = () => {
    const scrollCont = scrollerContainer.current!;
    const ratioOfscrollLeftTotabWidth = Math.floor(
      scrollCont.scrollLeft / oneTabAreaWidth.current
    );

    if (
      !isInSliding.current &&
      ratioOfscrollLeftTotabWidth <= Number(dataLength - numTabsVisible)
    ) {
      tabScrollIndexCount.current = ratioOfscrollLeftTotabWidth;
    }

    if (isInSliding.current) isInSliding.current = false;

    const extraDistance =
      scrollCont.scrollLeft - cutoffWidthForRightSccroll.current;

    if (extraDistance > 0 && extraDistance <= 1)
      scrollCont.scrollLeft = cutoffWidthForRightSccroll.current;

    const shortFallAfterRightScroll = Math.abs(
      cutoffWidthForRightSccroll.current / tabScrollIndexCount.current -
        currentScrollFromLeft.current
    );

    if (shortFallAfterRightScroll > 0 && shortFallAfterRightScroll <= 1)
      scrollCont.scrollLeft = cutoffWidthForRightSccroll.current;

    if (scrollCont.scrollLeft > 0 && scrollCont.scrollLeft <= 1)
      scrollCont.scrollLeft = 0;

    currentScrollFromLeft.current = scrollCont.scrollLeft;
    setToDisablePrevious(currentScrollFromLeft.current === 0);
    setToDisableNext(
      currentScrollFromLeft.current >= cutoffWidthForRightSccroll.current
    );
  };

  useEffect(() => {
    if (scrollerContainer.current) {
      const scrollCont = scrollerContainer.current;
      currentScrollFromLeft.current = scrollCont.scrollLeft;
      const noNeedForScroll = dataLength <= numTabsVisible;

      const ratioOfscrollLeftTotabWidth = Math.floor(
        scrollCont.scrollLeft / oneTabAreaWidth.current
      );
      const isMaxedOnRight =
        ratioOfscrollLeftTotabWidth >= Number(dataLength - numTabsVisible);

      if (noNeedForScroll) {
        setToDisablePrevious(true);
        setToDisableNext(true);
      } else {
        if (currentScrollFromLeft.current === 0) {
          setToDisablePrevious(true);
        } else if (isMaxedOnRight) {
          setToDisableNext(true);
        }

        tabScrollIndexCount.current = ratioOfscrollLeftTotabWidth;
        scrollerContainer.current.addEventListener("scrollend", onScrollEnd, {
          passive: true
        });
        refToScrollerContainer.current = scrollerContainer.current;
      }
    }
  }, [scrollerContainer.current]);

  useEffect(
    () => () => {
      refToScrollerContainer.current?.removeEventListener(
        "scrollend",
        onScrollEnd
      );
    },
    []
  );

  return (
    <FlexContainer
      position="relative"
      justifycontent="space-between"
      direction="row"
      height="100%"
      width="100%"
      alignitems="top"
      columngap="5px">
      {!data && (
        <Skeleton
          width="85vw"
          height="20px"
          baseColor="#ececf9"
          style={{ paddingTop: "5px" }}
        />
      )}
      {data && (
        <>
          <TabButton
            $isDisabled={toDisablePrevious}
            onClick={() => {
              onNavButtonsClick(false);
            }}>
            <span>{"<"}</span>
          </TabButton>

          <Container
            ref={scrollerContainer}
            cursor="true"
            width="91%"
            paddingright="1px"
            paddingbottom={isHandHeldDevice() ? "8px" : "0px"}
            style={{
              overflowX: `${hasHorizontalScroll.current ? "scroll" : "hidden"}`,
              overflowY: "hidden",
              userSelect: "none"
            }}>
            <FlexContainer
              id="tabscroller_tabs_container"
              ref={tabsParentContainer}
              columngap="4px"
              width={`${calculatedWidthOfScrollerInPerc}%`}
              paddingleft="1px"
              paddingright="2px"
              height="100%">
              {data.map((item, index) => (
                <Tab
                  key={`Tab${item.label}`}
                  $isActive={activeIndex === index}
                  onClick={() => {
                    onTabItemClick(index);
                  }}>
                  <span>{item?.label}</span>
                </Tab>
              ))}
            </FlexContainer>
          </Container>

          <TabButton
            $isDisabled={toDisableNext}
            onClick={() => {
              onNavButtonsClick();
            }}>
            <span>{">"}</span>
          </TabButton>
        </>
      )}
    </FlexContainer>
  );
};

export default TabScroller;
