import { useState } from "react";
import { ILeaderboardPuntersOwnSelectionUnit } from "../../../../store/features/leaderboardSlice";

const usePunterItemsCommon = () => {
  const [columsWithIndices, setColumsWithIndices] = useState<number[][]>([]);
  const [data, setData] = useState<
    ILeaderboardPuntersOwnSelectionUnit[] | null
  >(null);

  const adjustItemsLengthsInColumns = (total: number) => {
    const cols = 2;

    const baselength = Math.ceil(total / cols);
    const numsInColumm = [];

    let totalCount = 0;

    for (let i: number = 0; i <= cols - 1; i++) {
      numsInColumm.push(i === cols - 1 ? total - totalCount : baselength);
      totalCount += baselength;
    }

    const indexsArr: any[] = [];

    let itemsCount = 0;
    let colCount = 0;

    let newRange = [];

    for (let j: number = 0; j < total; j++) {
      if (itemsCount === 0) {
        newRange = [];
        newRange.push(j);
      }
      if (++itemsCount === numsInColumm[colCount]) {
        newRange.push(j);

        itemsCount = 0;
        colCount++;

        indexsArr.push(newRange);
      }
    }

    setColumsWithIndices(indexsArr);
  };

  return { columsWithIndices, data, setData, adjustItemsLengthsInColumns };
};

export default usePunterItemsCommon;
