import styled from "styled-components";
import { useContext } from "react";
import theme from "../../../../theme";
import { IButton, StyledButton } from "../../../bricks/button";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

interface IRegisterControl {
  onRegisterClicked: () => void;
}

interface IRegisterButton extends IButton {
  isDesktop: boolean;
}

const RegisterButton = styled(StyledButton)<IRegisterButton>`
  width: ${(props) => (props.isDesktop ? "31%" : "73%")};
  height: ${(props) => (props.isDesktop ? "40px" : "30px")};
  margin-bottom: 10px;
  color: ${theme.colors.purplePrimary};
  background: linear-gradient(
    ${theme.colors.yellowPrimary},
    ${theme.colors.yellowSecondary}
  );
  min-height: ${(props) => (props.isDesktop ? "40px" : "30px")};
`;

const RegisterForFreeControl = ({ onRegisterClicked }: IRegisterControl) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <RegisterButton isDesktop={isDesktop} onClick={onRegisterClicked}>
      Register For Free
    </RegisterButton>
  );
};

export default RegisterForFreeControl;
