import React, { Dispatch } from "react";
import {
  ILuckyPick,
  luckyPickReducerInitialState
} from "../store/luckyPickReducer";

export type LuckyPickContextData = {
  lpState: ILuckyPick;
  lpDispatch: Dispatch<any>;
};

const dispatch: Dispatch<any> = () => {};
const LuckyPickContextInitData: ILuckyPick = {
  ...luckyPickReducerInitialState
};

export const LuckyPickContext = React.createContext({
  lpState: LuckyPickContextInitData,
  lpDispatch: dispatch
});
