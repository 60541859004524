import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import Button from "../../../bricks/button";
import {
  getLeagueMembers,
  getLeagueNotifications
} from "../../../../store/features/friendsLeagueSlice";
import { RootState } from "../../../../store/store";
import theme from "../../../../theme";

interface ITab {
  $isActive: boolean;
}

const Tab = styled(Button)<ITab>`
  width: 100%;
  border: ${(props) => `1px solid ${props.theme.colors.purplePrimary} `};
  background: ${(props) =>
    props.$isActive ? props.theme.colors.lightAliceBlue : "transparent"};
  color: ${(props) => props.theme.colors.purplePrimary};
  font-weight: ${(props) => (props.$isActive ? "bold" : "normal")};
  font-size: 1rem;
`;

interface IManageLeagueTabsMob {
  isInLeagueActivity: boolean;
  setIsInLeagueActivity: (isInLeaague: boolean) => void;
}

const ManageLeagueTabsMob = ({
  isInLeagueActivity,
  setIsInLeagueActivity
}: IManageLeagueTabsMob) => {
  const dispatch = useDispatch();

  const { currentLeagueToManage } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const { punterId } = useSelector((state: RootState) => state.user);

  const onTeamMemberTabClick = () => {
    if (isInLeagueActivity) {
      setIsInLeagueActivity(false);
      dispatch(
        getLeagueMembers({ leagueCode: currentLeagueToManage!.leagueCode })
      );
    }
  };

  const onLeagueActivityTabClick = () => {
    if (!isInLeagueActivity) {
      setIsInLeagueActivity(true);
      dispatch(
        getLeagueNotifications({
          punterId,
          leagueCode: currentLeagueToManage!.leagueCode
        })
      );
    }
  };

  return (
    <FlexContainer
      rowgap="10px"
      justifyContent="center"
      alignitems="center"
      direction="column"
      paddingtop="10px">
      <Tab
        text="League Activity"
        $isActive={isInLeagueActivity}
        onClick={onLeagueActivityTabClick}
      />

      <Tab
        text="Team Members"
        $isActive={!isInLeagueActivity}
        onClick={onTeamMemberTabClick}
      />
      <Container
        display="flex"
        justifyContent="flex-start"
        alignitems="center"
        width="100%"
        textalign="left"
        paddingbottom="15px"
        paddingtop="5px"
        fontSize="max(0.7rem,3.4vw)"
        color={theme.colors.purplePrimary}
        fontWeight="bold">
        Manage
      </Container>
    </FlexContainer>
  );
};

export default ManageLeagueTabsMob;
