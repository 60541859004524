import Button from "../../../bricks/button";
import theme from "../../../../theme";

interface IAliasSubmitButtonProps {
  canSubmitAlias: boolean;
  isInProgress: boolean;
  hasErrorMessage: boolean;
  onAliasSubmitClick: () => void;
}

const AliasSubmitButton = ({
  canSubmitAlias,
  isInProgress,
  hasErrorMessage,
  onAliasSubmitClick
}: IAliasSubmitButtonProps) => {
  return (
    <Button
      width="150px"
      height="40px"
      margintop="5px"
      gradient={{
        colors: [theme.colors.purpleSecondary, theme.colors.purplePrimary],
        direction: "linear"
      }}
      onClick={onAliasSubmitClick}
      text="Submit"
      disabled={!canSubmitAlias || isInProgress || hasErrorMessage}
      fontSize={theme.font.size.sm}
    />
  );
};

export default AliasSubmitButton;
