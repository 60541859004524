import styled from "styled-components";
import theme from "../../../../../theme";

interface ISeperatorDiv {
  borderColor: string;
  width?: string;
  margin?: string;
}

interface IPayoutValue {
  overLimit: boolean;
}

export const NameDisplayDiv = styled.div`
  display: flex;
  align-text: left;
  width: fit-content;
  white-space: nowrap;
  margin: 10px 0px 10px 0px;
  align-items: center;
`;

export const NameDisplay = styled.b`
  font-size: ${theme.font.size.m};
  color: ${theme.colors.purplePrimary};
`;

export const CollapseDisplayDiv = styled.div`
  display: flex;
  max-width: 35px;
  padding-left: 7px;
`;

export const SeperatorDiv = styled.div<ISeperatorDiv>`
  display: flex;
  width: ${(props) => props.width ?? "auto"};
  margin: 0% ${(props) => props.margin ?? "2%"};
  border: 1px solid ${(props) => props.borderColor};
`;

export const LegsDisplayDiv = styled.div`
  display: block;
  align-text: left;
  height: fit-content;
  padding-r-left: 8px;
`;

export const RemoveButtonDisplay = styled.img`
  width: 15px;
  cursor: pointer;
`;

export const LabelDiv = styled.div`
  text-align: left;
  font-size: ${theme.font.size.s};
  width: wrap-content;
  white-space: nowrap;
`;

export const InputDisplay = styled.input`
  text-align: left;
  font-size: ${theme.font.size.sm};
  width: 100%;
  border: 1px solid ${theme.colors.purpleGrey};
  border-radius: 2px;
  padding: 2px 0px 2px 15px;
  &:focus {
    border: 1px solid ${theme.colors.purpleSecondary};
    outline: none;
    border-radius: 2px;
  }
`;

export const LabelValueDiv = styled.div`
  width: 100%;
  display: flex;
  align-text: left;
  font-size: ${theme.font.size.sm};
`;

export const StakeCurrencyText = styled.b`
  position: absolute;
  justify-content: center;
  align-text: left;
  padding: 0px 0px 0px 3px;
  font-size: ${theme.font.size.sm};
`;

export const PayoutValueDiv = styled.div<IPayoutValue>`
  width: 100%;
  display: flex;
  align-text: left;
  font-size: ${theme.font.size.sm};
  color: ${(props) => (props.overLimit ? "red" : "green")};
  padding-bottom: 2px;
`;

export const InfoDisplayDiv = styled.div`
  display: flex;
  width: fit-content;
  padding-right: 5px;
  align-items: left;
`;

export const InfoImageDisplay = styled.img`
  width: 15px;
  cursor: pointer;
`;

export const InfoTextDiv = styled.div`
  position: absolute;
  top: 27px;
  width: 95%;
  height: fit-content;
  background: white;
  border: 1px solid ${theme.colors.purplePrimary};
  border-radius: 3px;
  padding: 3px;
  font-size: ${theme.font.size.xs};
  z-index: 20;
`;

export const InfoTextScrollDiv = styled.div`
  max-height: 3.4rem;
  height: fit-content;
  background: none;
  overflow-y: scroll;
  overflow: auto;
`;

export const InfoText = styled.span`
  font-size: ${theme.font.size.s};
  color: ${theme.colors.purpleSecondary};
  line-height: 1.1;
  display: inline-block;
  text-align: left;
`;

export const OverLimitText = styled.text`
  font-size: ${theme.font.size.s};
  color: red;
  text-align: center;
`;
