import Cross from "@assets/cross.svg";
import { useDispatch } from "react-redux";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import {
  HorseDisplayDiv,
  HorseNumberDisplay,
  OddsDisplayDiv,
  PurpleText,
  ExtraBoldDisplay,
  RaceDisplayDiv,
  RemoveDisplayDiv,
  RemoveButtonDisplay
} from "./allToSelector.style";
import { updateLegEnabled } from "../../../../../store/features/newBetSlipSlice";
import { IBetSlipLeg, IBetSlipMeeting } from "../../betslip.models";
import { FORMATS, getFormatted } from "../../../../../utils/dateUtils";

export interface IAllToSelector {
  data: IBetSlipLeg;
  meeting: IBetSlipMeeting;
  betCardIndex: number;
  index: number;
  isWin: boolean;
  isFixedOdds: boolean;
}

const AllToSelector = ({
  data,
  meeting,
  betCardIndex,
  index,
  isWin,
  isFixedOdds
}: IAllToSelector) => {
  const { race, enabled } = data;
  const { horseNumber, horseName, raceNumber, meetingTime, odds, placeOdds } =
    race;
  const { meetingName, meetingDate } = meeting;

  const dispatch = useDispatch();

  const onEnabledClick = () => {
    dispatch(
      updateLegEnabled({
        index: betCardIndex,
        legIndex: index,
        isEnabled: !enabled
      })
    );
  };

  const getOddsBasedOnBet = isWin ? odds : placeOdds;

  return (
    <div>
      {enabled && (
        <FlexContainer bgcolor="white" borderradius="6px" height="fit-content">
          <Container
            borderradius="6px"
            height="fit-content"
            padding="8px"
            width="100%">
            <FlexContainer>
              <HorseDisplayDiv>
                <HorseNumberDisplay>{horseNumber}</HorseNumberDisplay>
                <b>{horseName.toUpperCase()}</b>
              </HorseDisplayDiv>
              <OddsDisplayDiv>
                <PurpleText>
                  {isFixedOdds ? "Fixed" : "SP"} - {isWin ? "Win" : "Place"}:
                  <ExtraBoldDisplay>
                    {" "}
                    {isFixedOdds ? getOddsBasedOnBet : 0}
                  </ExtraBoldDisplay>
                </PurpleText>
              </OddsDisplayDiv>
            </FlexContainer>

            <RaceDisplayDiv>
              <ExtraBoldDisplay>Leg {raceNumber}</ExtraBoldDisplay>
              {`: Race
              ${raceNumber} - ${meetingName.toUpperCase()} (${getFormatted(
                meetingDate.toString(),
                FORMATS["DT, SM FY"]
              )} -
              ${getFormatted(meetingTime.toString(), FORMATS["HH:MM"])})`}
            </RaceDisplayDiv>
          </Container>
          <RemoveDisplayDiv>
            <RemoveButtonDisplay onClick={onEnabledClick} src={Cross} />
          </RemoveDisplayDiv>
        </FlexContainer>
      )}
    </div>
  );
};

export default AllToSelector;
