import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { setCurrentMeetingId } from "../../../store/features/meetingsSlice";
import { type ITabscrollerTabData } from "../tabScroller";
import { FORMATS, getFormatted } from "../../../utils/dateUtils";
import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import {
  ItemRowContainer,
  OnNextChallengeClick,
  TitleLabel
} from "./index.style";

interface IPlayNextChallenge {
  data: ITabscrollerTabData[] | null;
}

const PlayNextChallenge = ({ data }: IPlayNextChallenge) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortedData, setSortedData] = useState<ITabscrollerTabData[] | null>(
    null
  );

  useEffect(() => {
    if (data && !sortedData) setSortedData(data);
  }, [data]);

  const onNextChallengeClick = (meeting: ITabscrollerTabData) => {
    const { metadata } = meeting;

    const identifiedRace = data?.find(
      (item) =>
        item.metadata.raceCourseName === metadata.raceCourseName &&
        item.metadata.meetingId === metadata.meetingId
    );

    if (identifiedRace) {
      dispatch(setCurrentMeetingId(identifiedRace.metadata.meetingId));
      navigate("/home");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "2px",
        width: `${isDesktop ? "100%" : "85%"}`,
        background: "#F4F6FD",
        height: "auto",
        padding: "12px 0px 6px 0px",
        border: "1px solid #5C2D91",
        borderRadius: "5px",
        marginTop: `${isDesktop ? "0px" : "-14px"}`,
        marginBottom: "10px",
        fontFamily: "Poppins, sans-serif"
      }}>
      <div
        style={{
          background: "",
          display: "flex",
          width: "95%",
          height: "100%",
          fontSize: "0.6rem",
          alignItems: "center",
          paddingRight: "0px",
          color: "black",
          borderBottom: "1px solid #d1d1e0",
          marginLeft: "0px",
          paddingTop: "3px",
          paddingBottom: "4px",
          columnGap: "1px"
        }}>
        <TitleLabel> Play Your Next Challenge Today :</TitleLabel>
      </div>

      {sortedData?.map((meeting: ITabscrollerTabData, index) => (
        <ItemRowContainer
          key={`${meeting.label}${meeting.metadata.meetingDate}`}
          style={{
            borderBottom: `${
              index < sortedData.length - 1 ? "1px solid #d1d1e0" : ""
            }`
          }}>
          <div
            style={{
              width: "50%",
              background: "none",
              textAlign: "left",
              marginLeft: "5px"
            }}>
            <span
              style={{ width: "100%", fontWeight: "bold", textAlign: "left" }}>
              {" "}
              {meeting.label}
            </span>
          </div>
          <div
            style={{
              background: "",
              height: "100%",
              width: "50%",
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              justifyContent: "flex-end",
              marginRight: "0px",
              paddingRight: "6px"
            }}>
            <div style={{ width: "80%", background: "" }}>
              <span style={{ width: "max-content", background: "" }}>
                {" "}
                {getFormatted(
                  meeting.metadata.meetingDate,
                  FORMATS["D, DT FM"]
                )}
              </span>
            </div>
            <div
              style={{
                width: "10%",
                height: "100%",
                background: "",
                display: "grid",
                placeContent: "center"
              }}>
              <OnNextChallengeClick
                onClick={() => {
                  onNextChallengeClick(meeting);
                }}>
                {">"}
              </OnNextChallengeClick>
            </div>
          </div>
        </ItemRowContainer>
      ))}
    </div>
  );
};

export default PlayNextChallenge;
