import styled from "styled-components";
import theme from "../../../../../theme";
import { IButton } from "../../../../bricks/button";

interface IFailedBet {
  isFailedHeading: boolean;
}

export const SubmissionWaitingContainer = styled.div`
  background: white;
  border-radius: 5px;
  width: 90%;
  height: max-content;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 9px;
  padding: 15px 0px 20px 0px;
  row-gap: 10px;

  & label {
    width: 100%;
    font-size: 3.6vw;
  }

  @media screen and (min-width: 769px) {
    & label {
      width: 90%;
      font-size: 1.7rem;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 80;
  width: 100%;
  height: calc(100vh - 20px);
  font-family: Poppins, sans-serif;
  top: 20%;
`;

export const InnerContainer = styled.div`
  position: relative;
  background: none;
  height: 100%;
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;
`;

export const BetSlipContainer = styled.div`
  background: white;
  border: 1px solid ${theme.colors.purplePrimary};
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  height: max-content;
  z-index: 81;
  position: relative;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  transform: translate(-50%, -70px);
`;

export const ModalBody = styled.div`
  background: white;
  border-radius: 5px;
  width: 100%;
  height: max-content;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  padding: 15px 0px 20px 0px;
`;

export const ErrorMessage = styled.p`
  font-size: 2.5vw;
  margin: 0;
  padding-bottom: 7px;
`;

export const StyledButton = styled.button`
  margin-top: 13px;
  width: 50%;
  height: 1.8rem;
  background-image: linear-gradient(
    to right,
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  color: white;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  max-width: 100%;
  text-align: center;
  color: ${theme.colors.purplePrimary};
  border-bottom: 0px solid ${theme.colors.purpleSecondary};
  font-weight: bold;
  padding-top: 5px;
  margin-left: 10px;
  padding-bottom: 7px;
`;

export const SuccessHeading = styled.label`
  max-width: 100%;
  text-align: center;
  font-size: ${theme.font.size.xl};
  color: ${theme.colors.purplePrimary};
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 1px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const StyledHeading = styled.label<IFailedBet>`
  max-width: 100%;
  text-align: center;
  font-size: ${(props) =>
    props.isFailedHeading ? theme.font.size.xl : theme.font.size.l};
  color: ${theme.colors.purplePrimary};
  border-bottom: 1px solid ${theme.colors.purpleSecondary};
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 7px;
  padding-top: ${(props) => (props.isFailedHeading ? "5px" : "1px")};
  padding-bottom: ${(props) => (props.isFailedHeading ? "7px" : "1px")};
  margin-left: 20px;
  margin-right: 20px;
`;

export const StyledImage = styled.img`
  width: 40px;
  height: 20px;
`;

export const ConfirmationFailedTitle = styled.div`
  color: ${theme.colors.purplePrimary};
  padding: 10px;
`;

export const ConfirmationTitle = styled.div`
  width: calc(100%-2px);
  background-color: ${theme.colors.purpleLight};
  color: ${theme.colors.grey};
  border: 1px solid ${theme.colors.purpleGrey};
  text-align: start;
  padding: 5px 5%;
  font-weight: ${theme.font.weight.bold};
`;

export const ConfirmationButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size.m};
  flex-direction: column;
  width: 60%;
  padding: 5px;
  gap: 10px;

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.s};
  }
`;

export const DisabledConfirmationButton = styled.button<IButton>`
  font-size: ${theme.font.size.sm};
  font-weight: normal;
  height: 2rem;
  width: ${(props) => props.width};
  background: grey;
  color: white;
  border-radius: 5px;
  letter-spacing: 0.5px;
  line-height: 1;
  outline: 0px;
  cursor: pointer;
  border-width: 0px;
  margin-top: ${(props) => props.margintop ?? "none"}
  &:disabled {
    background: linear-gradient(
      ${theme.colors.disabledPurpleSecondary},
      ${theme.colors.disabledPurplePrimary}
    );
    cursor: default;
  }
  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.s};
  }
  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.xs};
  }
`;

export const ConfirmationButton = styled.button<IButton>`
  font-size: ${theme.font.size.sm};
  font-weight: normal;
  height: 2rem;
  width: ${(props) => props.width};
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  color: white;
  border-radius: 5px;
  letter-spacing: 0.5px;
  line-height: 1;
  outline: 0px;
  cursor: pointer;
  border-width: 0px;
  margin-top: ${(props) => props.margintop ?? "none"}
  &:disabled {
    background: linear-gradient(
      ${theme.colors.disabledPurpleSecondary},
      ${theme.colors.disabledPurplePrimary}
    );
    cursor: default;
  }

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.s};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.xs};
  }
`;

export const ConfirmationTotalsDiv = styled.div`
  background-color: ${theme.colors.purpleLight};
  border: 1px solid ${theme.colors.purplePrimary};
  border-radius: 5px;
  margin: 15px 20px;
  width: 70%;
`;

export const ConfirmationTitleText = styled.span`
  width: 70%;
  padding: 5px;
  font-size: ${theme.font.size.m};
  color: black;

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.s};
  }
`;

export const ConfirmationValueText = styled.span`
  width: 30%;
  font-size: ${theme.font.size.m};
  color: ${theme.colors.purplePrimary};
  font-weight: ${theme.font.weight.bold};
  padding: 5px;

  @media screen and (max-width: 460px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 380px) {
    font-size: ${theme.font.size.s};
  }
`;

export const DisabledText = styled.span`
  font-size: ${theme.font.size.xs};
`;
