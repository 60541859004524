import BadWordsFilter from "bad-words";
import { Profanity, ProfanityOptions } from "@2toad/profanity";

const punterBadWordsFilter = new BadWordsFilter();
const ListOfSouthAfricanBadWords = ["poes"];
punterBadWordsFilter.addWords(...ListOfSouthAfricanBadWords);
const options = new ProfanityOptions();
options.wholeWord = false;
const PCIntensiveSearch = new Profanity(options);
PCIntensiveSearch.addWords([
  "poes",
  "fok",
  "madhir",
  "puss",
  "ass",
  "cake",
  "kak",
  "kaffer",
  "fok",
  "naai",
  "naaier",
  "doos",
  "gat",
  "moer",
  "moerse",
  "bliksem",
  "vok",
  "bliksemse",
  "Futsek",
  "Msunu",
  "Golo",
  "Nja",
  "Inja",
  "Mgodoyi",
  "Shlama",
  "Sihlama",
  "Masimba",
  "Amasimba",
  "Nhlunu",
  "Mbombo",
  "Mabele",
  "Zinqa",
  "Masende",
  "Sende",
  "Ngquza",
  "Ingquza",
  "Nqanda",
  "Slima",
  "Isilima",
  "Siwula",
  "Sduphunga",
  "Sifebe",
  "Ncanga",
  "Fuseg"
]);

const hasBadWords = (input: string): boolean =>
  punterBadWordsFilter.isProfane(input) || PCIntensiveSearch.exists(input);

export default hasBadWords;
