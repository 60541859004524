import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IMeetingRace,
  IRaceRunner
} from "../../../../../../store/features/meetingsSlice";
import HorseCard from "./horse-card/horseCard";
import { SortRunners } from "./selectionsUtil";

interface IRaceCard {
  race: IMeetingRace;
  isPending: boolean;
  onSelectionUpdated: (
    selectedRunner: IRaceRunner,
    isSelected: boolean
  ) => void;
  selectedRunner: IRaceRunner | null;
  isForcedSelection: boolean;
}

const RaceCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const RaceCard = ({
  race,
  onSelectionUpdated,
  selectedRunner,
  isPending,
  isForcedSelection
}: IRaceCard) => {
  const { runners, resulted: isResulted } = race;
  const [selectedHorse, setSelectedHorse] = useState<IRaceRunner | null>(
    selectedRunner
  );

  useEffect(() => {
    setSelectedHorse(selectedRunner);
  });

  const hasOdds =
    runners.filter((runner) => Number(runner.hollywoodOdds) > 0).length > 0;

  const sortedList: IRaceRunner[] = isResulted
    ? runners.toSorted((runnerA, runnerB) => SortRunners(runnerA, runnerB))
    : runners;

  const onCardClicked = (runner: IRaceRunner, selected: boolean) => {
    setSelectedHorse(selected ? runner : null);
    onSelectionUpdated(runner, selected);
  };

  const raceRunners = sortedList.map((runner: IRaceRunner, index: number) => {
    const isSelected = runner.horseName === selectedHorse?.horseName;
    return (
      <HorseCard
        key={runner.horseName}
        index={index}
        horseInfo={runner}
        onCardClicked={onCardClicked}
        isSelected={isSelected}
        isForcedSelection={isForcedSelection && isSelected && isPending}
        isReserved={hasOdds && runner.hollywoodOdds === 0}
        isPending={isPending}
      />
    );
  });

  return <RaceCardDiv>{raceRunners}</RaceCardDiv>;
};

export default RaceCard;
