import styled from "styled-components";
import theme from "../../../../theme";
import {
  TargetDeviceWidth,
  mediaWidthSizeAbove,
  mediaWidthSizeBetween
} from "../../../../utils/mediaUtils";

interface ILabelProps {
  isLuckyPick: boolean;
}

export const BetSlipHoverChild = styled.div`
  border-radius: 5px;
  position: relative;
  background: white;
  width: 100vw;
  max-width: 500px;
  min-height: fit-content;
  margin: 20px auto;
  overflow-y: auto;
`;

export const DescriptorDiv = styled.div`
  color: ${theme.colors.purplePrimary};
  font-size: ${theme.font.size.m};
`;

export const SelectAllDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.xs}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.s}; `
  )};
  color: ${theme.colors.purplePrimary};
  margin-bottom: 5px;
`;

export const ToggleAllDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: ${theme.colors.purplePrimary};
  ${mediaWidthSizeBetween(
    TargetDeviceWidth.mobile,
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.xs}; `
  )};
  ${mediaWidthSizeAbove(
    TargetDeviceWidth.smallDesktop,
    ` font-size: ${theme.font.size.s}; `
  )};
`;

export const ToggleAllText = styled.b`
  color: ${theme.colors.yellowPrimary};
  font-size: ${theme.font.size.sm};
`;

export const SelectAllCheckbox = styled.input`
  accent-color: ${theme.colors.purpleSecondary};
  margin-left: 0px;
  margin-bottom: 4px;
  cursor: pointer;
`;

export const ViewSelectionsText = styled.span`
  font-size: ${theme.font.size.s};
  color: grey;
  font-style: italic;
`;

export const CancelBetslip = styled.a`
  href: /home;
  text-decoration: underline;
  font-size: ${theme.font.size.s};
  padding-top: 25px;
  margin-bottom: -30px;
  color: grey;
  text-align: center;
  cursor: pointer;
`;

export const SelectAllText = styled.b`
  font-size: ${theme.font.size.sm};
`;
export const DivStyle = styled.div`
  color: ${theme.colors.purplePrimary};
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
`;

export const CancelDiv = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const TermsLabel = styled.label<ILabelProps>`
  margin-top: ${(props) => (props.isLuckyPick ? "5px" : "none")};
  font-size: ${theme.font.size.xs};
  font-style: italic;
`;

export const LimitLabel = styled.label<ILabelProps>`
  margin-top: ${(props) => (props.isLuckyPick ? "10px" : "-5px")};
  margin-bottom: ${(props) => (props.isLuckyPick ? "5px" : "none")};
  font-size: ${theme.font.size.xs};
  font-style: italic;
`;
