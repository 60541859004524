import styled from "styled-components";
import * as clipboard from "clipboard-polyfill";
import theme from "../../../../theme";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";

const LinkSpan = styled.span`
  padding-left: 10px;
  line-height: 1;
  text-align: left;
`;

interface ICopyCodeComponent {
  displayText: string;
  copiedAssertionCallback: () => void;
  isCopied: boolean;
  copiedType: string;
  isUrlLink?: boolean;
}

const CopyCodeComponent = ({
  displayText,
  copiedAssertionCallback,
  isUrlLink,
  isCopied,
  copiedType
}: ICopyCodeComponent) => {
  const handleClick = () => {
    if (!isCopied) {
      clipboard.writeText(displayText);
      copiedAssertionCallback();
    }
  };

  const getButtonDisplayText = () => {
    if (isCopied) return `${copiedType} copied`;
    return `Copy ${copiedType.toLowerCase()}`;
  };

  return (
    <FlexContainer width="100%" columngap="20px" alignitems="center">
      {!isUrlLink && (
        <Container
          width="60%"
          border={`1px solid ${theme.colors.purplePrimary}`}
          borderradius="5px"
          color={theme.colors.purplePrimary}
          fontSize="1.3rem"
          fontWeight="bold">
          {displayText}
        </Container>
      )}

      {isUrlLink && (
        <Container
          width="60%"
          border={`1px solid ${theme.colors.purplePrimary}`}
          borderradius="5px"
          color={theme.colors.bluishGray}
          fontSize="0.7rem"
          overflow="auto"
          padding="8px 0px 8px 0px">
          <LinkSpan>{displayText}</LinkSpan>
        </Container>
      )}

      <Button
        width="30%"
        background={
          !isCopied
            ? `linear-gradient( 
                 ${theme.colors.purpleSecondary},
                 ${theme.colors.purplePrimary}
                 )`
            : theme.colors.lightGreen
        }
        text={getButtonDisplayText()}
        fontSize="0.7rem"
        onClick={handleClick}
      />
    </FlexContainer>
  );
};

export default CopyCodeComponent;
