import { useContext } from "react";
import Icon from "@assets/daily-rewards.svg";
import BoxMessageWithIcon from "./common/boxMessageWithIcon";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";

const DailyRewardsDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const fontSize = isDesktop ? `${theme.font.size.s}` : "0.8rem";

  return (
    <BoxMessageWithIcon image={Icon} gap="15px" isDesktop={isDesktop}>
      <>
        <TextInSpan
          fontSize="1rem"
          fontWeight="bold"
          zIndex={20}
          lineHeight={1.5}>
          Daily Rewards
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={isDesktop ? `${theme.font.size.s}` : "1rem"}
          zIndex={20}
          lineHeight={1.5}>
          {"Every day, "}
          <b>we guarantee R5,000 in prizes</b>
          {
            " for the top 20 finishers, regardless of whether all the winners are tipped. "
          }
          <b>
            The top finishers receive R2,000, R1,000, R500, and R250,
            respectively.
          </b>
        </TextInSpan>

        <TextInSpan
          color="black"
          fontSize={fontSize}
          zIndex={20}
          lineHeight={1.5}>
          {"Those who place between "}
          <b>5th</b>
          {" and "}
          <b>10th</b>
          {" earn "}
          <b>R125 each</b>, {" while positions "}
          <b>11th</b>
          {" to "}
          <b>20th</b>
          {" secure "}
          <b>R50</b>
          {
            " each. Plus, if a punter places bets totalling R50 or more after making their selections, these prize amounts are doubled! "
          }
        </TextInSpan>
      </>
    </BoxMessageWithIcon>
  );
};

export default DailyRewardsDisplay;
