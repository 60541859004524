import styled from "styled-components";

interface ISliderContainer {
  itemsReceived: number;
  SLIDE_TIME_IN_SECS: number;
}

interface IItemContainer {
  itemWidthPercent: number;
}

export interface ICarousalItem {
  type: string;
  content: any;
}

interface IDesktopToggle {
  isDesktop: boolean;
}

interface IDots {
  position?: string;
  bottom?: number;
  isDesktop: boolean;
}

export const ItemImageStyle = styled.img`
  width: 100%;
  height: fit-content;
  object-fit: cover;
`;

export const ItemVideoStyle = styled.video`
  width: 100%;
  height: fit-content;
  object-fit: cover;
`;

export const CarouselContainer = styled.div<IDesktopToggle>`
  width: 100%;
  height: fit-content;
  flex-direction: row;
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

export const SliderContainer = styled.div<ISliderContainer>`
  display: flex;
  height: fit-content;
  width: ${(props) => props.itemsReceived * 100}%;
  flex-shrink: 0;
  transition: ${(props) => `all ${props.SLIDE_TIME_IN_SECS}s`};
`;

export const ItemContainer = styled.div<IItemContainer>`
  display: flex;
  width: ${(props) => props.itemWidthPercent}%;
  flex-basis: ${(props) => props.itemWidthPercent}%;
  align-items: center;
  justify-content: center;
`;

export const MainDotsContainer = styled.div<IDots>`
  width: 100%;
  display: flex;
  margin-top: ${(props) => (props.isDesktop ? "none" : "2")}px;
  position: ${(props) => (props ? props.position : "static")};
  bottom: ${(props) => (props ? props.bottom : 0)}px;
  justify-content: center;
  align-items: center;
`;
