import TimerClock from "@assets/count-down-timer-clock.svg";
import { useEffect, useMemo } from "react";
import FlexContainer from "../../../../../../bricks/flexContainer";
import TextInSpan from "../../../../../../bricks/textInSpan";
import useCountdown from "../../../../../../../hooks/useCountdown";
import Container from "../../../../../../bricks/container";
import theme from "../../../../../../../theme";
import useCountdownHelper from "./countdown/useCountdownHelper";
import CounterForSelection from "./countdown/counterForSelection";
import CounterForNextRace from "./countdown/counterForNextRace";

interface IMeetingCountDownWeb {
  width: string;
  meetingStartTime: number | null;
  completionCallback: () => void;
  nextRaceTime: number | null;
  nextRaceNumber?: number;
  isHover: boolean;
  canDoCountDown: boolean;
  clickCallback: () => void;
}

const MeetingCountDownWeb = ({
  width,
  meetingStartTime,
  completionCallback,
  nextRaceTime,
  nextRaceNumber,
  isHover,
  canDoCountDown,
  clickCallback
}: IMeetingCountDownWeb) => {
  const {
    startCountDown,
    stopCountDown,
    resetWithNewTime,
    days,
    hours,
    minutes,
    seconds
  } = useCountdown(meetingStartTime ?? "");

  const daysLeft = useMemo(() => {
    if (!meetingStartTime) return 0;
    return Math.round(Math.abs((meetingStartTime - Date.now()) / 86400000));
  }, [meetingStartTime]);

  const {
    getBackgroundColor,
    getClockIconContainerWidth,
    getClockIconColor,
    getSelectionCountDownWidth,
    canStartNextRace,
    canStartInitialMeetingCountDown,
    getDaysRemaining,
    getColorForText
  } = useCountdownHelper(nextRaceTime, isHover, meetingStartTime, daysLeft);

  useEffect(() => {
    if (canStartInitialMeetingCountDown()) startCountDown();
  }, []);

  useEffect(() => {
    if (canStartNextRace()) {
      resetWithNewTime(nextRaceTime!);
      startCountDown();
    }
  }, [nextRaceTime, nextRaceNumber]);

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      stopCountDown();

      setTimeout(() => {
        completionCallback();
      }, 200);
    }
  }, [days, hours, minutes, seconds]);

  return canDoCountDown ? (
    <FlexContainer
      minwidth={width}
      bgcolor={getBackgroundColor()}
      minheight="54px"
      alignitems="center"
      cursor="pointer"
      paddingtop="5px"
      paddingbottom="5px"
      onClick={clickCallback}>
      <FlexContainer
        cursor="pointer"
        direction="column"
        alignitems="center"
        rowgap="10px"
        width="100%">
        {meetingStartTime && !nextRaceTime && (
          <TextInSpan
            lineHeight={1}
            color={getColorForText()}
            fontSize="0.6rem"
            fontWeight="bold"
            width="max-content">
            SELECTIONS CLOSE IN:
          </TextInSpan>
        )}

        {!!nextRaceTime && daysLeft === 0 && (
          <TextInSpan
            lineHeight={1}
            color={getColorForText()}
            fontSize="0.7rem"
            fontWeight="bold"
            width="max-content">
            TIME TO RACE <b> {`\u00A0${nextRaceNumber}`}</b> :
          </TextInSpan>
        )}

        <FlexContainer
          columngap="3px"
          width="100%"
          justifycontent="center"
          alignitems="center"
          cursor="pointer">
          {daysLeft === 0 && (
            <FlexContainer
              justifycontent="flex-end"
              width={getClockIconContainerWidth()}
              height="max-content"
              lineheight="1"
              paddingright="5px"
              cursor="pointer">
              <Container
                marginright="5px"
                width="16px"
                height="16px"
                bgcolor={getClockIconColor()}
                mask={`url(${TimerClock}) no-repeat center`}
                cursor="pointer"
              />
            </FlexContainer>
          )}

          <FlexContainer
            fontSize="min(2vw,1.5rem)"
            margintop="2px"
            width={getSelectionCountDownWidth()}
            height="max-content"
            color={isHover ? "yellow" : theme.colors.purplePrimary}
            alignitems="center"
            cursor="pointer">
            {daysLeft === 0 ? (
              <>
                <CounterForSelection
                  meetingStartTime={meetingStartTime}
                  nextRaceTime={nextRaceTime}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />

                <CounterForNextRace
                  nextRaceTime={nextRaceTime}
                  minutes={minutes}
                  seconds={seconds}
                />
              </>
            ) : (
              <TextInSpan lineHeight={1} fontWeight="bold" width="100%">
                {getDaysRemaining()}
              </TextInSpan>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  ) : null;
};

export default MeetingCountDownWeb;
