import { styled } from "styled-components";
import { isHandHeldDevice } from "../utils/deviceUtils";
import theme from "../theme";

import HRPodcastsDesktopImage from "../assets/hr-podcasts-1538x855.png";
import HRPodcastsHandheldImage from "../assets/hr-podcasts-693x385.png";

export const MainPageDiv = styled.div`
  font-family: Poppins, sans-serif;
  background: ${theme.colors.bodySecondary};
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  row-gap: 1rem;
  position: relative;
  padding: 10px 0 10px 0;
`;

export const LabelDiv = styled.div`
  margin-top: 0px;
  color: ${theme.colors.purplePrimary};
  width: 90vw;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: left;
  height: max-content;
`;

export const VideoDiv = styled.div`
  width: 90vw;
  height: 50vw;
  video {
    width: 100%;
    height: 100%;
  }
`;

export const ImageDiv = styled.div`
  width: 90vw;
  height: 50vw;
  cursor: pointer;
  background-image: url(${isHandHeldDevice()
    ? HRPodcastsHandheldImage
    : HRPodcastsDesktopImage});
  background-size: cover;
`;
