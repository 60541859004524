import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container, { PointerContainer } from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import theme from "../../../../theme";
import {
  IMyLeagueItem,
  UPDATE_LEAGUE_ACTION_CODE_MAP,
  deleteALeague
} from "../../../../store/features/friendsLeagueSlice";
import Button from "../../../bricks/button";
import { RootState } from "../../../../store/store";
import LeagueModalbase from "../modals/leagueModalBase";
import LeagueFormBase from "../modals/leagueFormBase";
import ConfirmationModal from "../../modals/confirmationModal";
import CloseIcon from "../../../composites/close-icon/closeIcon";
import { CloseIconDiv } from "../../../composites/close-icon/closeIcon.style";

interface IDeleteALeagueModal {
  data: IMyLeagueItem[] | null;
  stateUpdaterCallback: () => void;
}

const DeleteALeagueModal = ({
  stateUpdaterCallback,
  data
}: IDeleteALeagueModal) => {
  const dispatch = useDispatch();

  const [showDeleteLeagueModal, setShowDeleteLeagueModal] = useState(false);

  const { punterId } = useSelector((state: RootState) => state.user);

  const [selectedLeague, setSelectedLeague] = useState<IMyLeagueItem | null>(
    null
  );

  const closeModalCallback = () => {
    setShowDeleteLeagueModal(false);
  };

  const onItemClick = (league: IMyLeagueItem) => {
    if (selectedLeague?.leagueName === league.leagueName) {
      setSelectedLeague(null);
    } else {
      setSelectedLeague(league);
    }
  };

  const deleteLeagueCallback = () => {
    dispatch(
      deleteALeague({
        punterId,
        leagueCode: selectedLeague?.leagueCode,
        newStatus: UPDATE_LEAGUE_ACTION_CODE_MAP.DISABLE
      })
    );
    stateUpdaterCallback();
  };

  const onButtonClick = () => {
    setShowDeleteLeagueModal(true);
  };

  const getAppropriateColor = (index: number, leagueName: string) => {
    if (leagueName === selectedLeague?.leagueName)
      return `linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`;

    return index % 2 === 0 ? theme.colors.lightAliceBlue : theme.colors.primary;
  };

  return (
    <LeagueModalbase closureCallback={stateUpdaterCallback}>
      <CloseIcon onClick={stateUpdaterCallback} />
      <LeagueFormBase text="Choose a league to delete!">
        <FlexContainer
          direction="column"
          width="100%"
          fontSize="1rem"
          fontWeight="bold"
          color={theme.colors.purplePrimary}>
          <FlexContainer
            width="100%"
            bgcolor={theme.colors.lightAliceBlue}
            marginbottom="5px">
            <Container width="60%" textalign="left">
              League Name
            </Container>
            <Container width="40%" textalign="left">
              League Code
            </Container>
          </FlexContainer>

          {data
            ?.filter((league) => league.leagueStatus)
            .map((item, index) => {
              return (
                <FlexContainer
                  key={`listedLeagueToDisable${item.leagueName}`}
                  width="100%"
                  bgcolor={getAppropriateColor(index, item.leagueName)}
                  fontSize="1rem"
                  fontWeight={
                    item.leagueName === selectedLeague?.leagueName
                      ? "bold"
                      : "normal"
                  }
                  onClick={() => {
                    onItemClick(item);
                  }}
                  cursor="pointer"
                  color={
                    item.leagueName === selectedLeague?.leagueName
                      ? theme.colors.purplePrimary
                      : "black"
                  }>
                  <PointerContainer>{item.leagueName}</PointerContainer>
                  <PointerContainer>{item.leagueCode}</PointerContainer>
                </FlexContainer>
              );
            })}
        </FlexContainer>
        <CloseIconDiv>
          <Button
            margintop="20px"
            width="50%"
            background={`linear-gradient(
                ${theme.colors.purpleSecondary},
                ${theme.colors.purplePrimary}
                )`}
            text="Delete the league"
            fontSize="0.7rem"
            disabled={!selectedLeague}
            onClick={onButtonClick}
          />
        </CloseIconDiv>
      </LeagueFormBase>
      <ConfirmationModal
        isModalOpen={showDeleteLeagueModal}
        modalTitle="Delete league?"
        modalDescription="Are you sure you want to delete the league?"
        onYesCallback={deleteLeagueCallback}
        onNoCallback={closeModalCallback}
        translationX={0}
        translationY={0}
        left={-4}
        bottom={88}
      />
    </LeagueModalbase>
  );
};

export default DeleteALeagueModal;
