import { useSelector } from "react-redux";
import Container from "../../../../bricks/container";
import { ButtonStyle, ButtonsDiv } from "./betSlipSubmission.style";
import BetSlipSubmissionBalance from "./betSlipSubmissionBalance";
import { RootState } from "../../../../../store/store";
import openLink from "../../../../../utils/pageUtils";

interface IBetSlipSubmissionConfirm {
  balance: number;
  currentStake: number;
  placeBetCallback: (canShowSummary: boolean) => void;
  strikeBet: () => void;
  isLuckyPick: boolean;
}

const BetSlipSubmissionConfirm = ({
  balance,
  currentStake,
  placeBetCallback,
  strikeBet,
  isLuckyPick
}: IBetSlipSubmissionConfirm) => {
  const config = useSelector((state: RootState) => state.config.data);

  const canSubmit = Math.abs(balance) >= currentStake;

  const onRightButtonClick = () => {
    if (canSubmit) {
      strikeBet();
      window.scrollTo({ top: 0 });
    } else {
      openLink(`${config.redeemVoucherUrl}`);
    }
  };

  const onBack = () => {
    window.scrollTo({ top: 0 });
    placeBetCallback(false);
  };

  return (
    <Container paddingbottom="10px" width={isLuckyPick ? "100%" : "none"}>
      <BetSlipSubmissionBalance currentStake={currentStake} />
      <ButtonsDiv flexDirection="row">
        <ButtonStyle width="50%" onClick={onBack}>
          Back
        </ButtonStyle>
        <ButtonStyle width="50%" onClick={onRightButtonClick}>
          {canSubmit ? "Confirm bet" : "Top Up Account"}
        </ButtonStyle>
      </ButtonsDiv>
    </Container>
  );
};

export default BetSlipSubmissionConfirm;
