import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDown from "@assets/arrow-down-gold.svg";
import HomeScrollContainer from "../common/homeScrollContainer";
import { RootState } from "../../../../store/store";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import FlexContainer from "../../../bricks/flexContainer";
import theme from "../../../../theme";
import Meetings from "./meetings/meetings";
import Carousal from "./Carousel/carousel";
import { getCMSImages } from "../../../../store/features/pagesSlice";
import Results from "./results/results";
import Footer from "../../../../layouts/footer";
import { ArrowDiv } from "../../../composites/upAndDownButton/upAndDownButton.style";
import Links from "./links/links";

interface IHomePostLogin {
  onSelectionMade: () => void;
  onSubmitClicked: () => void;
  onTakeABetClicked: () => void;
  scrollerCallback: () => void;
  onMeetingOpened: (newId: number) => void;
  scrollerCallbackTop: () => void;
}

const HomePostLogin = ({
  onSelectionMade,
  onSubmitClicked,
  onTakeABetClicked,
  scrollerCallback,
  onMeetingOpened,
  scrollerCallbackTop
}: IHomePostLogin) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const dispatch = useDispatch();
  const cmsImagesData = useSelector(
    (state: RootState) => state.pages.cmsImages
  );

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    if (!cmsImagesData) dispatch(getCMSImages());
  }, []);

  const takeABetClicked = () => {
    scrollerCallback();
    onTakeABetClicked();
  };

  return (
    <HomeScrollContainer>
      {isDesktop ? (
        <>
          <Carousal />
          <FlexContainer
            direction="column"
            padding="10px"
            width="88%"
            bgcolor={theme.colors.resultsMercury}
            borderradius="8px"
            height="max-content"
            margintop="30px"
            marginbottom="50px"
            alignitems="center"
            maxwidth="1350px">
            <Meetings
              onSelectionMade={onSelectionMade}
              onSubmitClicked={onSubmitClicked}
              onTakeABetClicked={takeABetClicked}
              onMeetingOpened={onMeetingOpened}
            />
            <Results />
          </FlexContainer>
        </>
      ) : (
        <>
          <Carousal />
          <Meetings
            onSelectionMade={onSelectionMade}
            onSubmitClicked={onSubmitClicked}
            onTakeABetClicked={takeABetClicked}
            onMeetingOpened={onMeetingOpened}
          />
          <ArrowDiv onClick={scrollerCallbackTop}>
            <img src={ArrowDown} alt="Arrow Down Gold" />
          </ArrowDiv>
          <Results />
          <Links />
        </>
      )}
      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </HomeScrollContainer>
  );
};

export default HomePostLogin;
