import { memo } from "react";
import Container from "../../../../bricks/container";
import ValueDisplay from "./valueDisplay";

export interface IItemYourBestFinish {
  myBestRank: number | string;
  totalPlayers: number | string;
  courseName: string;
  date: string;
  outPerformed: number | string;
}

const ItemYourBestFinish = ({
  myBestRank,
  totalPlayers,
  courseName,
  date,
  outPerformed
}: IItemYourBestFinish) => {
  const isDataAvailable =
    myBestRank !== "undefined" &&
    totalPlayers !== "undefined" &&
    courseName !== "undefined" &&
    date !== "undefined" &&
    outPerformed !== "undefined";

  return (
    <>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.9 : 0.6}rem`}
        lineheight="1"
        fontWeight={isDataAvailable ? "bold" : "normal"}>
        {isDataAvailable && (
          <span>
            Your best finish is{" "}
            <ValueDisplay value={`${myBestRank}/${totalPlayers}`} /> punters at{" "}
            <ValueDisplay value={`${courseName},${date}`} />
          </span>
        )}
        {!isDataAvailable && `Data not available.`}
      </Container>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.7 : 0.6}rem`}
        fontStyle="italic">
        {isDataAvailable && `You outperformed ${outPerformed} players.`}
        {!isDataAvailable && `Data not available.`}
      </Container>
    </>
  );
};

export default memo(ItemYourBestFinish);
