import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";
import { getFormatted, FORMATS } from "../../../utils/dateUtils";
import { formatInDecimal } from "../../../utils/numberUtils";

interface IQuickresultItem {
  name: string;
  rank: number;
  points: number;
  date: string;
  meetingId: number;
}

const Wrapper = styled(FlexContainer)`
  width: 100%;
  min-width: 250px;
  column-gap: 5px;
  height: auto;
  padding-left: 5px;
  align-items: center;
  border: 1px solid black;
  border-radius: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;

  @media screen and (min-width: 768px) {
    min-width: 300px;
    padding-left: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

const NameFlexContainer = styled(FlexContainer)`
  min-width: 40px;
  width: 35%;
  row-gap: 5px;

  flex-direction: column;
  justify-content: left;
  margin-left: 10px;
  row-gap: 1px;

  @media screen and (min-width: 769px) and (max-width: 869px) {
    row-gap: 1px;
    max-width: 105px;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    row-gap: 2px;
    max-width: 105px;
  }
  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    row-gap: 3px;
    max-width: 105px;
  }

  @media screen and (min-width: 1438px) {
    row-gap: 5px;
  }
`;

const PostNameFlexContainer = styled(FlexContainer)`
  width: 65%;
  column-gap: 5px;
  align-items: center;
`;

const NameContainer = styled(Container)`
  width: 44px;
  line-height: 1;
  color: #5c2d91;
  font-weight: bold;
  text-align: left;
  margin: auto 0;
  font-size: 0.6rem;
  @media screen and (min-width: 769px) and (max-width: 869px) {
    width: 100%;
    font-size: 0.6rem;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    width: 100%;
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    width: 100%;
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1438px) {
    width: 100%;
    font-size: 0.9rem;
  }
`;

const StyledLabel = styled.label`
  text-align: left;
  line-height: 1;
  font-size: 0.5rem;

  @media screen and (min-width: 769px) and (max-width: 869px) {
    font-size: 0.5rem;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    font-size: 0.6rem;
  }

  @media screen and (min-width: 1116px) {
    font-size: 0.7rem;
  }
`;

const RankContainer = styled(Container)`
  width: 40%;
  height: 20px;
  background: linear-gradient(#fcee21, #eccb00);
  border-radius: 3px;
  font-size: 0.6rem;
  line-height: 1;
  padding: 2px;
  display: grid;
  place-content: center;

  @media screen and (min-width: 769px) and (max-width: 869px) {
    height: 20px;
    font-size: 0.6rem;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    height: 24px;
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    height: 27px;
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1438px) {
    height: 30px;
    font-size: 0.9rem;
  }
`;
const PointsContainer = styled(Container)`
  width: 40%;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #5c2d91;
  font-size: 0.6rem;
  display: grid;
  place-content: center;
  padding: 3px 2px 3px 2px;
  line-height: 1;
  @media screen and (min-width: 769px) and (max-width: 869px) {
    height: 17px;
    font-size: 0.6rem;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    height: 20px;
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    padding-top: 5px;
    height: 22px;
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1438px) {
    height: 27px;
    font-size: 0.9rem;
  }
`;
const ArrowContainer = styled(Container)`
  width: 20%;
  height: 20px;
  max-width: 20px;
  cursor: pointer;
  border: 1px solid #5c2d91;
  border-radius: 3px;
  border: 1px solid #5c2d91;
  font-size: 0.6rem;
  display: grid;
  place-content: center;
  font-family: Verdana;
  font-weight: bold;
  box-shadow: 1px 1px 3px #c1c1d7;

  @media screen and (min-width: 769px) and (max-width: 869px) {
    font-size: 0.6rem;
  }
  @media screen and (min-width: 870px) and (max-width: 1115px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 0.9rem;
  }
`;
const QuickresultItem = ({
  name,
  rank,
  points,
  date,
  meetingId
}: IQuickresultItem) => {
  const navigate = useNavigate();

  const onNextClick = () => {
    navigate(`/results?meetingId=${meetingId}`);
  };

  return (
    <Wrapper>
      <Container
        id="main"
        width="94%"
        height="85%"
        marginleft="2px"
        margintop="2px"
        position="absolute"
        onClick={onNextClick}
        style={{ opacity: 0, cursor: "pointer" }}
      />
      <NameFlexContainer>
        <NameContainer>{name} </NameContainer>

        <FlexContainer alignitems="center">
          <StyledLabel>
            {" "}
            {getFormatted(date, FORMATS["SD, DT FM FY"])}
          </StyledLabel>
        </FlexContainer>
      </NameFlexContainer>

      <PostNameFlexContainer>
        <RankContainer color={`${rank > 0 ? "#5C2D91" : "red"}`}>
          <p>
            Rank: <span style={{ fontWeight: "bold" }}>{rank}</span>{" "}
          </p>
        </RankContainer>
        <PointsContainer color={`${points > 0 ? "#5C2D91" : "red"}`}>
          {" "}
          <p>
            Points:{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatInDecimal(points)}
            </span>{" "}
          </p>
        </PointsContainer>
        <ArrowContainer> {">"} </ArrowContainer>
        <Container width="2px" marginleft="2px" />
      </PostNameFlexContainer>
    </Wrapper>
  );
};

export default QuickresultItem;
