/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IPunterResults {
  punterId: number;
  userId: string;
  rank: number;
  total: number;
  bonus: number;
  racePoints: number;
  winners: number;
  places: number;
  selections: number;
  totalPunters: number;
  selectedHorses: string;
  date: string;
  totalSelections: number;
  secondPlace: number;
  thirdPlace: number;
}

export interface IPastMeetings {
  meetingId: number;
  meetingDate: string;
  meetingStatusId: number;
  raceCourseName: string;
  raceTrackAssetId: number;
}

interface IPastMeetingsInfo {
  pastMeetings: IPastMeetings[];
}

interface IPunterSelection {
  raceNumber: number;
  saddle: number;
  horseName: string;
  points: number;
  racePosition: number;
}

interface IResults {
  resultedMeetings: IPastMeetingsInfo | null;
  currentPunterResults: IPunterResults | null;
  topPunters: IPunterResults[];
  punterSelections: IPunterSelection[] | null;
  isFetching: boolean;
}

const initialState: IResults = {
  resultedMeetings: null,
  currentPunterResults: null,
  topPunters: [],
  punterSelections: null,
  isFetching: false
};

export interface IResultsInfoRequest {
  meetingId: number;
  userId: string;
}

export interface IPastMeetingsInfoRequest {
  numberOfRaces: number;
}

export interface IPunterSelectionRequest {
  meetingId: number;
  userId: number;
}

const configConst = (state: RootState) => state.config.data;

/**
 * Returns past meetings information
 */
export const GetPastMeetingInformation: any = createAsyncThunk(
  "GetPastMeetingInformation",
  async ({ numberOfRaces }: IPastMeetingsInfoRequest, { getState }) => {
    const config = configConst(getState() as RootState);
    const response = await axios.get(
      `${config.puntersApi}/api/Meeting/GetPastMeetings`,
      { params: { numberOfRaces } }
    );

    return response.data;
  }
);

/**
 * Returns current punter result plus the top 5 punters results for a particular race
 */
export const GetResultsInformation: any = createAsyncThunk(
  "GetResultsInformation",
  async ({ meetingId, userId }: IResultsInfoRequest, { getState }) => {
    const config = configConst(getState() as RootState);

    const pageId = 1;
    const numPages = 5;

    const punterResults = await axios.get(
      `${config.puntersApi}/api/Leaderboard/GetDailyLeaderboardPaged/${pageId}/1/${meetingId}?username=${
        userId || ""
      }`
    );

    const leadersResults = await axios.get(
      `${config.puntersApi}/api/Leaderboard/GetDailyLeaderboardPaged/${pageId}/${numPages}/${meetingId}?username=`
    );

    const results = {
      currentPunterResults: punterResults.data.dailyLeaderboard.punters[0],
      topPunters: leadersResults.data.dailyLeaderboard.punters
    };

    return results;
  }
);

/**
 * Returns the selected punters selections
 * */
export const GetPunterSelection: any = createAsyncThunk(
  "GetPunterSelection",
  async ({ userId, meetingId }: IPunterSelectionRequest, { getState }) => {
    const config = configConst(getState() as RootState);

    const response = await axios.get(
      `${config.puntersApi}/api/Result/GetPunterLeaderboardSelections?meetingId=${meetingId}&punterId=${userId}`
    );

    return response.data.punterLeaderboardDetails;
  }
);

const ResultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(GetResultsInformation.pending, (state) => {
      state.isFetching = true;
      state.topPunters = [];
      state.currentPunterResults = null;
    });
    builder.addCase(GetResultsInformation.fulfilled, (state, action) => {
      state.topPunters = action.payload.topPunters;
      state.currentPunterResults = action.payload.currentPunterResults;
      state.isFetching = false;
    });
    builder.addCase(GetResultsInformation.rejected, (state) => {
      state.topPunters = [];
      state.currentPunterResults = null;
      state.isFetching = false;
    });

    builder.addCase(GetPastMeetingInformation.pending, (state) => {
      state.resultedMeetings = null;
    });
    builder.addCase(GetPastMeetingInformation.fulfilled, (state, action) => {
      state.resultedMeetings = action.payload;
    });
    builder.addCase(GetPastMeetingInformation.rejected, (state) => {
      state.resultedMeetings = null;
    });

    builder.addCase(GetPunterSelection.pending, (state) => {
      state.punterSelections = null;
    });
    builder.addCase(GetPunterSelection.fulfilled, (state, action) => {
      state.punterSelections = action.payload;
    });
    builder.addCase(GetPunterSelection.rejected, (state) => {
      state.punterSelections = null;
    });
  }
});

export default ResultsSlice.reducer;
