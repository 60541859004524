import styled from "styled-components";
import theme from "../../../../../../theme";

interface IResultsBannerProps {
  shouldOpen?: boolean;
  isDesktop?: boolean;
}

export const RaceCardBanner = styled.div<IResultsBannerProps>`
  column-gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isDesktop ? "white" : theme.colors.resultsMercury};
  border-radius: ${(props) => (props.shouldOpen ? "8px 0 0 0" : "8px 0 0 8px")};
  width: 85%;
  height: 50px;
  padding: 8px;
`;

export const ResultsText = styled.div<IResultsBannerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
  text-align: left;
  justify-content: space-between;
  width: 100%;
  align-items: ${(props) => (props.isDesktop ? "center" : "flex-start")};
`;

export const ResultsSubText = styled.div<IResultsBannerProps>`
  display: flex;
  justify-content: ${(props) => (props.isDesktop ? "flex-end" : "flex-start")};
  column-gap: 10px;
  align-content: center;
  width: fit-content;
`;

export const ResultsSeparator = styled.span`
  font-size: ${theme.font.size.s};
  color: ${theme.colors.resultsLightGrey};
`;

export const CountryFlagIcon = styled.img`
  padding-right: 8px;
  padding-left: 8px;
  width: 35px;
  height: 45px;

  @media screen and (max-width: 425px) {
    width: 30px;
  }
`;

export const RaceCourseName = styled.span<IResultsBannerProps>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size.m};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.titleAndTextPurple};

  @media screen and (max-width: 850px) {
    font-size: ${theme.font.size.sm};
  }

  @media screen and (max-width: 400px) {
    font-size: ${theme.font.size.s};
  }
`;

export const RaceDate = styled.span`
  width: max-content;
  font-size: ${theme.font.size.s};
  color: ${theme.colors.resultsDarkGrey};

  @media screen and (max-width: 900px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 465px) {
    font-size: ${theme.font.size.xs};
  }
`;

export const PunterPoints = styled.span`
  width: max-content;
  font-size: ${theme.font.size.s};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.titleAndTextPurple};

  @media screen and (max-width: 800px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 465px) {
    font-size: ${theme.font.size.xs};
  }
`;

export const PunterRank = styled.span`
  width: max-content;
  font-size: ${theme.font.size.s};
  font-weight: ${theme.font.weight.heavyBold};
  color: ${theme.colors.resultsDarkGrey};

  @media screen and (max-width: 800px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 465px) {
    font-size: ${theme.font.size.xs};
  }
`;
