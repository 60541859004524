import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../../../../store/store";
import {
  UPDATE_LEAGUE_ACTION_CODE_MAP,
  displayManageForLeague,
  exitALeague,
  openLeaderboard,
  shareALeague
} from "../../../../../store/features/friendsLeagueSlice";

interface ILeagueItemCommonLogic {
  leagueName: string;
  leagueCode: string;
  isForJoinedLeagues?: boolean;
}
const useLeagueItemCommonLogic = ({
  isForJoinedLeagues,
  leagueName,
  leagueCode
}: ILeagueItemCommonLogic) => {
  const dispatch = useDispatch();

  const { punterId } = useSelector((state: RootState) => state.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const shareLeague = () => {
    dispatch(shareALeague(leagueCode));
  };

  const onManageClick = () => {
    if (!isForJoinedLeagues) {
      dispatch(displayManageForLeague(leagueName));
    } else {
      setIsModalOpen(true);
    }
  };

  const confirmExit = () => {
    dispatch(
      exitALeague({
        punterId,
        leagueCode,
        action: UPDATE_LEAGUE_ACTION_CODE_MAP.EXITED
      })
    );
    setIsModalOpen(false);
  };

  const onViewLeaderboard = () => {
    dispatch(openLeaderboard(leagueName));
  };

  return {
    isModalOpen,
    setIsModalOpen,
    shareLeague,
    onManageClick,
    confirmExit,
    onViewLeaderboard
  };
};

export default useLeagueItemCommonLogic;
