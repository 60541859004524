import { BetPlacementPayloadData } from "../../../store/features/betslipSlice";
import LuckyPickPovider from "./context/luckyPickProvider";
import ViewMain from "./views/viewMain";

interface ILuckyPick {
  closureCallback: () => void;
  onLuckyPickBet: (luckyPickBet: BetPlacementPayloadData) => void;
}

const LuckyPick = ({ closureCallback, onLuckyPickBet }: ILuckyPick) => {
  return (
    <LuckyPickPovider>
      <ViewMain
        closureCallback={closureCallback}
        onLuckyPickBet={onLuckyPickBet}
      />
    </LuckyPickPovider>
  );
};

export default LuckyPick;
