import FacebookIcon from "@assets/facebook.svg";
import WhatsappIcon from "@assets/whatsapp.svg";
import InstagramIcon from "@assets/instagram.svg";
import TwitterIcon from "@assets/twitter.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import FlexContainer from "../../../bricks/flexContainer";
import Container from "../../../bricks/container";
import Button from "../../../bricks/button";
import theme from "../../../../theme";
import SocialMediaIcon from "./socialMediaIcon";
import CopyCodeComponent from "./copyCodeComponent";
import LeagueFormBase from "../modals/leagueFormBase";
import { RootState } from "../../../../store/store";

interface IShareLeagueForm {
  leagueCode: string;
  link: string;
  continueCallback: () => void;
  cancelCallback: () => void;
}

const ShareLeagueForm = ({
  leagueCode,
  link,
  continueCallback,
  cancelCallback
}: IShareLeagueForm) => {
  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);

  const { myLeagues, joinedLeagues } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const onCopyCodeClick = () => {
    if (!isCodeCopied) {
      setIsCodeCopied(true);
      setIsLinkCopied(false);
    }
  };

  const onCopyLinkClick = () => {
    if (!isLinkCopied) {
      setIsCodeCopied(false);
      setIsLinkCopied(true);
    }
  };

  const getLeagueName = () => {
    const league = [...myLeagues!, ...joinedLeagues!].find(
      (item) => item.leagueCode === leagueCode
    );
    return league?.leagueName;
  };

  return (
    <LeagueFormBase text="Share A League">
      <>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          fontStyle="italic"
          color={theme.colors.grey}>
          Now that you've opened your league, maybe it's time to add more
          members?
        </Container>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          margintop="10px"
          color={theme.colors.grey}>
          League name
        </Container>
        <Container
          textalign="left"
          fontSize="1.1rem"
          color="black"
          margintop="-10px">
          {getLeagueName()}
        </Container>
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          color={theme.colors.grey}>
          League code
        </Container>

        <CopyCodeComponent
          displayText={leagueCode}
          copiedAssertionCallback={onCopyCodeClick}
          isUrlLink={false}
          isCopied={isCodeCopied}
          copiedType="Code"
        />
        <CopyCodeComponent
          displayText={link}
          copiedAssertionCallback={onCopyLinkClick}
          isUrlLink
          isCopied={isLinkCopied}
          copiedType="Link"
        />

        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          margintop="10px"
          color={theme.colors.grey}>
          Or share via social media
        </Container>

        <FlexContainer width="100%" columngap="15px">
          <SocialMediaIcon
            imageSrc={FacebookIcon}
            urlLink="https://www.facebook.com/"
            imageWidth="80%"
            imageHeight="auto"
          />
          <SocialMediaIcon
            imageSrc={InstagramIcon}
            urlLink="https://www.instagram.com/"
          />
          <SocialMediaIcon
            imageSrc={TwitterIcon}
            urlLink="https://twitter.com/"
          />
          <SocialMediaIcon
            imageSrc={WhatsappIcon}
            urlLink={`https://web.whatsapp.com/send?text=${link}`}
            androidLink="https://play.google.com/store/apps/details?id=com.whatsapp"
            toCheckForAndroid
          />
        </FlexContainer>

        <FlexContainer
          width="97%"
          margintop="10px"
          justifycontent="space-between"
          paddingleft="3px">
          <Button
            width="40%"
            background={`linear-gradient(
                ${theme.colors.purpleSecondary},
                ${theme.colors.purplePrimary}
                )`}
            text="Continue"
            fontSize="0.7rem"
            onClick={continueCallback}
          />

          <Button
            width="40%"
            background="transparent"
            text="Cancel"
            fontSize="0.7rem"
            fontcolor={theme.colors.grey}
            border={`1px solid ${theme.colors.grey}`}
            onClick={cancelCallback}
          />
        </FlexContainer>
      </>
    </LeagueFormBase>
  );
};

export default ShareLeagueForm;
