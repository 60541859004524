import styled from "styled-components";
import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";
import theme from "../../../theme";

export const CardColumnFlexContainer = styled(FlexContainer)`
  @media screen and (max-width: 768px) {
    width: 120px;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    min-width: 120px;
  }
`;

export const ButtonFlexContainer = styled(FlexContainer)`
  @media screen and (max-width: 320px) {
    font-size: 3.4vw;
  }
  @media screen and (min-width: 321px) and (max-width: 440px) {
    font-size: 2.9vw;
  }
  @media screen and (min-width: 441px) and (max-width: 540px) {
    font-size: 2.7vw;
  }
  @media screen and (min-width: 541px) and (max-width: 600px) {
    font-size: 2.5vw;
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    font-size: 2.2vw;
  }
`;

export const TakeAbetTipsDesktopContainer = styled(Container)`
  cursor: pointer;
  display: grid;
  place-content: center;
  border: 1px solid ${theme.colors.greyBlue};
  border-radius: 4px;
  width: 24vw;
  background: white;
  box-shadow: 2px 2px 5px ${theme.colors.orangePrimary};
  height: 1.4rem;
  max-width: 250px;
  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    height: 1.5rem;
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1438px) and (max-width: 1593px) {
    height: 1.6rem;
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1594px) {
    height: 1.7rem;
    font-size: 0.9rem;
  }
`;

export const QuickpickDesktopContainer = styled(Container)`
  cursor: pointer;
  display: grid;
  place-content: center;
  border: 1px solid black;
  border-radius: 4px;
  width: 24vw;
  background: white;
  box-shadow: 2px 2px 5px ${theme.colors.orangePrimary};
  height: 1.4rem;
  background: linear-gradient ${theme.colors.yellowSecondary};
  max-width: 250px;
  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    height: 1.5rem;
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1438px) and (max-width: 1593px) {
    height: 1.6rem;
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1594px) {
    height: 1.7rem;
    font-size: 0.9rem;
  }
`;

export const MeetingDateTimeDesktopContainer = styled(Container)`
  width: max-content;
  text-align: top;
  line-height: 1;
  color: white;
  font-size: 0.7rem;

  @media screen and (min-width: 1116px) and (max-width: 1437px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1438px) and (max-width: 1593px) {
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1594px) {
    font-size: 1rem;
  }
`;

export const LoadingStyle = styled.div`
    width: "99%",
    display: "grid",
    placeContent: "center",
    height: "auto",
    background: "none",
    borderTop: "0px",
    padding: "8px 0px 8px 0px",
    textAlign: "center"
`;
