import styled from "styled-components";
import theme from "../../../../theme";

export const BackgroundContainer = styled.div`
  background: white;
  border-radius: 10px;
  height: max-content;
  z-index: 81;
  position: absolute;
  margin: auto 0;
  top: 100px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 23px 0px;
  row-gap: 8px;
  transform: translate(-50%, -2%);

  @media screen and (min-width: 280px) and (max-width: 550px) {
    width: 85vw;
  }

  @media screen and (min-width: 551px) and (max-width: 768px) {
    width: 70vw;
  }

  @media screen and (min-width: 769px) and (max-width: 869px) {
    width: 60vw;
  }
  @media screen and (min-width: 870px) and (max-width: 969px) {
    width: 50vw;
  }
  @media screen and (min-width: 970px) and (max-width: 1069px) {
    width: 45vw;
  }
  @media screen and (min-width: 1070px) and (max-width: 1369px) {
    width: 35vw;
  }
  @media screen and (min-width: 1370px) and (max-width: 1679px) {
    width: 30vw;
  }
  @media screen and (min-width: 1680px) {
    width: 25vw;
  }
`;

export const AliasMessageLabel = styled.label`
  color: ${theme.colors.blueMagenta};
  height: max-content;
  place-items: center;
  text-align: left;
  font-size: ${theme.font.size.sm};
  @media screen and (min-width: 563px) and (max-width: 1115px) {
    font-size: 1.1rem;
  }

  @media screen and (min-width: 1116px) {
    font-size: 1.2rem;
  }
`;

export const OnBackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  display: grid;
  place-content: center;
  top: 0;
  position: fixed;
`;

export const AliasModalContainer = styled.div`
  position: absolute;
  z-index: 80;
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
`;

export const AliasModalBody = styled.div`
  position: relative;
  background: none;
  height: 100%;
  width: 100%;
`;

export const PunterNameText = styled.span`
  margin-top: 10px;
  font-weight: bold;
  color: ${theme.colors.purpleSecondary};
`;

export const SeparatorDiv = styled.div`
  width: 50%;
  height: 2px;
  border-bottom: 1px solid ${theme.colors.greyBlue};
`;

export const PunterNameSubText = styled.span`
  margin-top: 8px;
  font-size: ${theme.font.size.sm};
  color: ${theme.colors.blueMagenta};
  width: 92%;
  height: max-content;
  place-items: center;
`;

export const AliasMessageDiv = styled.div`
  display: flex;
  height: fit-content;
  column-gap: 5px;
  align-items: center;
`;

export const AliasRequiredMessage = styled.span`
  margin-top: 10px;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.s};
  color: red;
`;

export const AliasAvailableImage = styled.img`
  width: 20px;
  height: 20px;
`;
