import RegistrationIcon from "@assets/registration-modal-i-icon.svg";
import { useSelector } from "react-redux";
import {
  BackgroundShadeOverlay,
  ButtonContainer,
  IconImage,
  ImageContainer,
  LinkContainer,
  PurpleText,
  RefContainer,
  RegistrationContainer,
  RegistrationContainerPosition,
  TextContainer
} from "./registrationModal.style";
import openLink from "../../../utils/pageUtils";
import { RootState } from "../../../store/store";
import useLogin from "../../../hooks/useLogin";
import FlexContainer from "../../bricks/flexContainer";

interface IRegistration {
  xOffset: string;
  yOffset: string;
  shadeHeightOffset?: string;
  cancelCallback: () => void;
}

const RegistrationModal = ({
  xOffset,
  yOffset,
  shadeHeightOffset = "0",
  cancelCallback
}: IRegistration) => {
  const config = useSelector((state: RootState) => state.config.data);
  const { onLogInClick } = useLogin();

  return (
    <>
      <RegistrationContainerPosition xOffset={xOffset} yOffset={yOffset}>
        <RegistrationContainer>
          <ImageContainer>
            <IconImage src={RegistrationIcon} />
          </ImageContainer>
          <PurpleText style={{ textAlign: "center" }}>
            You are about to be redirected to Hollywoodbets to complete your
            signing up process!
          </PurpleText>
          <TextContainer>
            <span>1. This is where all your winnings will be paid out.</span>
            <span>
              2. All winnings paid out to your Hollywoodbets account will be
              immediately available for withdrawal.
            </span>
            <span>
              3. {"Upon creating a new Hollywoodbets account, "}
              <PurpleText>
                you get a R25 Sign Up BONUS into your account
              </PurpleText>
            </span>
            <FlexContainer width="100%" justifycontent="center">
              <ButtonContainer
                onClick={() => openLink(config.registerSouthAfricaUrl)}>
                Create Account
              </ButtonContainer>
            </FlexContainer>
            <span>
              {
                "4. Already have a Hollywoodbets account? Login with those details "
              }
              <RefContainer onClick={onLogInClick}>here</RefContainer>, or login
              in to your Hollywoodbets account and navigate to Punters Challenge
              to be redirected here.
            </span>
            <LinkContainer>
              <RefContainer onClick={cancelCallback}>Cancel</RefContainer>
            </LinkContainer>
          </TextContainer>
        </RegistrationContainer>
      </RegistrationContainerPosition>
      <BackgroundShadeOverlay
        heightOffset={shadeHeightOffset}
        onClick={cancelCallback}
      />
    </>
  );
};

export default RegistrationModal;
