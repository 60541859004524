import styled from "styled-components";
import theme from "../../../../../../../theme";

interface ISelectionDiv {
  isDesktop: boolean;
}

export const SelectionHeaderDiv = styled.div<ISelectionDiv>`
  width: ${(props) => (props.isDesktop ? "max-content" : "fit-content")};
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

export const CornerHeaderDiv = styled.div`
  width: 63px;
  font-weight: ${theme.font.weight.semiBold};
  display: flex;
  flex-direction: column;
  line-height: 1;
  justify-content: space-between;
`;

export const NumberHeaderSpan = styled.span`
  font-size: 1rem;
  line-height: 1.8;
`;
