import styled from "styled-components";
import theme from "../../../theme";

interface ILabledInputProps {
  marginTop?: string;
  width?: string;
  height?: string;
  textAlign?: string;
}

export const LabledInputContainer = styled.div<ILabledInputProps>`
  font-family: Poppins, sans-serif;
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "1rem")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;

export const LabelDiv = styled.div`
  position: absolute;
  left: 10px;
  background: white
  padding-left: 0.3rem;
  padding-right: 5px;
  margin-right: 0.6rem;
  top: 0.2rem;
  height: 10px;
`;

export const LabelText = styled.span`
  width: fit-content;
  font-size: 0.8rem;
  visibility: hidden;
`;

export const InputDiv = styled.span<ILabledInputProps>`
  display: inline-block;
  border: 0.5px solid ${theme.colors.inputPurple};
  width: 95%;
  height: ${(props) => (props.height ? props.height : "3.5rem")};
  border-radius: 5px;
  margin-top: 0.5rem;
  background: white;
  box-shadow: 2px 2px 6px ${theme.colors.lightRed};
  align-content: center;
`;

export const InputContainer = styled.input<ILabledInputProps>`
  font-size: ${theme.font.size.m};
  border: none;
  color: black;
  width: 90%;
  outline: none;
  line-height: 1;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
