import { styled } from "styled-components";

export const LoadingDiv = styled.div`
  position: fixed;
  top: calc((90% / 2));
  left: calc(50% - 50px / 2);
  z-index: 100;
`;

export const DesktopDiv = styled.div`
  margin-top: 0px;
  width: 85%;
`;

export const LoadingParent = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 32px);
`;
