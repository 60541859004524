import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersIcon from "@assets/active-users-icon.svg";
import {
  IMeetingRace,
  getMeetingProgressive
} from "../../../../../../../store/features/meetingsSlice";
import { isToday } from "../../../../../../../utils/dateUtils";
import { SELECTION_CUTOFF_TIME_IN_MINUTES } from "../../../../../../../constants";
import theme from "../../../../../../../theme";
import FlexContainer from "../../../../../../bricks/flexContainer";
import MeetingBaseContainer from "../common/meetingBaseContainer";
import NameAndJackpot from "../common/nameAndJackpot";
import OpenCloseControl from "./openCloseControl";
import PointsAndRank from "../common/pointsAndRank";
import MeetingDropdownWeb from "./meetingDropdownWeb";
import MeetingCountDownWeb from "./meetingCountdownWeb";
import { RootState } from "../../../../../../../store/store";
import Image from "../../../../../../bricks/image";

export interface IMeetingRankData {
  rank: number;
  points: number;
  totalPunters: number;
  meetingId: string;
}

interface IMeetingCard {
  width?: string;
  contentToOpen?: JSX.Element;
  raceCourseName: string;
  time?: string;
  jackpot: string;
  hasSelections: boolean;
  rankData: IMeetingRankData | null;
  isAbandoned: boolean;
  isPostponed: boolean;
  allRaces: IMeetingRace[];
  meetingId: string;
  canExpand: boolean;
  raceColor: string;
  onExpandCallback: (meetingId: string) => void;
}

const MeetingCardWeb = ({
  width,
  contentToOpen,
  raceCourseName,
  time,
  jackpot,
  hasSelections,
  rankData,
  isAbandoned,
  isPostponed,
  allRaces,
  canExpand,
  meetingId,
  raceColor,
  onExpandCallback
}: IMeetingCard) => {
  const dispatch = useDispatch();

  const [toShowDropDown, setToShowDropDown] = useState<boolean>(false);
  const [isSelectionOpen, setIsSelectionOpen] = useState<boolean>(true);
  const [toShowNextRaceTimer, setToShowNextRaceTimer] = useState(false);
  const [nextUpComingRace, setNextUpComingRace] = useState<IMeetingRace>();

  const remainingRacesRef = useRef<IMeetingRace[]>(allRaces.concat());
  const currentIndex = useRef<number>(-1);
  const canShowTimer = useMemo(() => isToday(time!), [time]);
  const [toShowTimer, setToShowTimer] = useState<boolean>(canShowTimer);

  const [toShowBorder, setToShowBorder] = useState<boolean>(false);

  const { progressives } = useSelector((state: RootState) => state.meetings);

  const currentprogressives =
    progressives && Number(meetingId) >= 0 ? progressives[meetingId] : null;

  useEffect(() => {
    dispatch(getMeetingProgressive({ meetingId }));
  }, []);

  const handleMouseOver = () => {
    setToShowBorder(true);
  };

  const handleMouseOut = () => {
    setToShowBorder(false);
  };

  const hasAtLeastOneRacePending = () => {
    return (
      !isAbandoned &&
      new Date(allRaces[allRaces.length - 1]?.raceTime).getTime() > Date.now()
    );
  };

  const onArrowClick = () => {
    setToShowDropDown(!toShowDropDown);
    onExpandCallback(meetingId);
  };

  const selectionClosingTime = useMemo(() => {
    if (!hasAtLeastOneRacePending()) return null;

    const firstRaceDate = new Date(time!);
    const cutOffTimeOffset = SELECTION_CUTOFF_TIME_IN_MINUTES * 60 * 1000;

    const selectionCloseTime = firstRaceDate.getTime() - cutOffTimeOffset;

    if (selectionCloseTime <= Date.now()) {
      currentIndex.current = remainingRacesRef.current.findIndex(
        (race) => new Date(race.raceTime).getTime() > Date.now()
      );

      setNextUpComingRace(allRaces[currentIndex.current]);
      setIsSelectionOpen(false);
      setToShowNextRaceTimer(true);
      return null;
    }

    return selectionCloseTime;
  }, [time]);

  const handleGenericClick = () => {
    if (!toShowDropDown) setToShowDropDown(true);
    onExpandCallback(meetingId);
  };

  const onCardClick = () => {
    handleGenericClick();

    if (canShowTimer) setToShowTimer(!toShowTimer);
  };

  const getColorForRankContainer = () => {
    if (isAbandoned) return theme.colors.abandonedPink;

    if (hasSelections) {
      return raceColor;
    }

    if (isSelectionOpen) return theme.raceColors(raceCourseName);
    return toShowDropDown ? raceColor : theme.colors.pickNowRed;
  };

  const onCloseClick = () => {
    setToShowDropDown(false);
    setToShowTimer(true);
  };

  const getJackpot = () => {
    return parseInt(jackpot);
  };

  const onCountdownComplete = () => {
    setToShowTimer(false);

    if (currentIndex.current + 1 < allRaces.length) {
      setToShowNextRaceTimer(true);
      ++currentIndex.current;

      setNextUpComingRace(allRaces[currentIndex.current]);

      setToShowTimer(true);
    } else {
      setToShowNextRaceTimer(false);
    }
  };

  const getNextRace = (): number | null => {
    if (!toShowNextRaceTimer) return null;

    if (canShowTimer) {
      const next = nextUpComingRace?.raceTime;

      return new Date(next!).getTime();
    }

    return 0;
  };
  const getNextRaceNumber = () => {
    return Number(nextUpComingRace?.raceNumber);
  };

  const hasCountdown = () => {
    if ((!getNextRace() && getNextRaceNumber()) || !hasAtLeastOneRacePending())
      return false;

    return true;
  };

  useEffect(() => {
    if (!canExpand && toShowDropDown) {
      onCloseClick();
    }
  }, [canExpand]);

  useEffect(() => {
    if (!hasAtLeastOneRacePending()) {
      setToShowTimer(false);
    }
  }, [allRaces]);

  const canShowCounter = () => {
    if (
      selectionClosingTime &&
      currentIndex.current < 0 &&
      !toShowNextRaceTimer
    )
      return true;
    if (currentIndex.current >= 0 && toShowNextRaceTimer) return true;

    return false;
  };

  const getBorderColor = () => {
    if (isAbandoned) return theme.colors.abandonedPink;
    return raceColor;
  };

  return (
    <FlexContainer
      direction="column"
      alignitems="center"
      width={width ?? "100%"}
      height="max-content">
      {!isAbandoned && (
        <FlexContainer
          justifyContent="flex-start"
          alignitems="center"
          width="100%"
          columngap="5px">
          <Image src={UsersIcon} width="20px" height="20px" />
          Total Players: <b>{currentprogressives?.players}</b>
        </FlexContainer>
      )}

      <MeetingBaseContainer
        mouseEnterHandler={handleMouseOver}
        mouseRemoveHandler={handleMouseOut}
        width="100%"
        toShowShadow
        backgroundColor="white"
        toShowBorder={toShowDropDown ? false : toShowBorder}
        borderColor={getBorderColor()}>
        <>
          <NameAndJackpot
            width={hasCountdown() ? "50%" : "70%"}
            clickCallback={!toShowDropDown ? onCardClick : onCloseClick}
            raceCourseName={raceCourseName}
            startTime={time!}
            jackpot={getJackpot()}
            isHover={toShowBorder}
            isExpanded={toShowDropDown}
            isAbandoned={isAbandoned}
          />

          <OpenCloseControl
            isOpen={toShowDropDown}
            width="10%"
            onClickCallback={onArrowClick}
          />

          <MeetingCountDownWeb
            width="20%"
            meetingStartTime={selectionClosingTime}
            completionCallback={onCountdownComplete}
            nextRaceTime={getNextRace()}
            nextRaceNumber={getNextRaceNumber()}
            isHover={toShowBorder}
            canDoCountDown={canShowCounter()}
            clickCallback={!toShowDropDown ? onCardClick : onCloseClick}
          />

          <PointsAndRank
            width="20%"
            backgroundColor={getColorForRankContainer()}
            clickCallback={!toShowDropDown ? onCardClick : onCloseClick}
            hasSelections={hasSelections}
            rank={rankData?.rank ?? 0}
            points={rankData?.points ?? 0}
            totalPunters={rankData?.totalPunters ?? 0}
            isSelectionOpen={isSelectionOpen}
            isAbandoned={isAbandoned}
            isPostponed={isPostponed}
            meetingId={rankData?.meetingId!}
          />
        </>
      </MeetingBaseContainer>

      {toShowDropDown && (
        <MeetingDropdownWeb
          color={theme.colors.meetingsLightGrey}
          borderColor={raceColor ?? "red"}
          contentToOpen={contentToOpen!}
          closureCallback={onCloseClick}
          isAbandoned={isAbandoned}
        />
      )}
    </FlexContainer>
  );
};

export default MeetingCardWeb;
