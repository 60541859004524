import styled from "styled-components";
import { IBetSlipBet } from "../../betslip.models";
import theme from "../../../../../theme";
import Container from "../../../../bricks/container";
import SummaryItem from "./summaryItem";

interface IBetSlipSummary {
  data: IBetSlipBet[];
}

const Title = styled.div`
  color: ${theme.colors.purplePrimary};
  padding-bottom: 10px;
`;

const BetSlipSummary = ({ data }: IBetSlipSummary) => {
  const summaryItems = data
    .filter((item) => item.stakeToBet > 0)
    .map((bet) =>
      bet.enabled && (!bet.overLimit || !bet.isFixedOdds) ? (
        <SummaryItem key={`SummaryItem${bet.betType}`} data={bet} />
      ) : null
    );

  return (
    <Container paddingbottom="20px" width="100%">
      <Title>
        <b>BET SUMMARY</b>
      </Title>
      {summaryItems}
    </Container>
  );
};

export default BetSlipSummary;
