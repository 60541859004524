import styled from "styled-components";

const Item = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  padding: 3px;
`;
interface IMenuItem {
  text?: string;
  callback?: (text: string) => void;
}
const MenuItem = ({ text, callback }: IMenuItem) => (
  <Item
    onClick={() => {
      callback(text);
    }}>
    {text}
  </Item>
);

export default MenuItem;
