import { useSelector } from "react-redux";
import { IJoinedLeagues } from ".";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import { LeagueActionButtons } from "../leaguesCommon/leagueButtons.style";
import LeagueItemCardMob from "../leaguesCommon/leagueItemCardMob";
import { RootState } from "../../../../store/store";

const JoinedLeaguesMob = ({ data, joinALeagueCallback }: IJoinedLeagues) => {
  const { errorMessageOnRejection } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  return (
    <FlexContainer direction="column" width="100%">
      <FlexContainer
        width="100%"
        justifycontent="center"
        margintop="10px"
        marginbottom="10px"
        direction="column"
        rowgap="5px">
        <LeagueActionButtons
          text="Join A New League"
          onClick={joinALeagueCallback}
        />
        <Container
          textalign="left"
          fontSize="1rem"
          color={theme.colors.purplePrimary}>
          Joined Leagues
        </Container>
      </FlexContainer>

      <Container maxheight="100%">
        {data?.length! > 0 &&
          data
            ?.filter((league) => league.leagueStatus)
            .map((item, index) => {
              return (
                <LeagueItemCardMob
                  key={`LeagueItemCardMob${item.leagueName}`}
                  data={item}
                  index={index}
                  isForJoinedLeagues
                />
              );
            })}
        {data?.length! > 0 &&
          data
            ?.filter((league) => !league.leagueStatus)
            .map((item, index) => {
              return (
                <>
                  {index === 0 && (
                    <Container
                      textalign="left"
                      fontSize="1.1rem"
                      color={theme.colors.purplePrimary}
                      margintop="5px">
                      Inactive leagues
                    </Container>
                  )}
                  <LeagueItemCardMob
                    data={item}
                    index={index}
                    isActive={false}
                    isForJoinedLeagues
                  />
                </>
              );
            })}

        {!data?.length && !errorMessageOnRejection && (
          <Container
            bgcolor="transparent"
            borderbottomleftradius="5px"
            borderbottomrightradius="5px"
            fontSize="0.8rem"
            paddingbottom="5px"
            paddingtop="5px"
            textalign="left"
            fontStyle="italic">
            You have not joined any league recently.
          </Container>
        )}
        {!data?.length && errorMessageOnRejection && (
          <Container
            bgcolor="transparent"
            borderbottomleftradius="5px"
            borderbottomrightradius="5px"
            color="red"
            fontSize="0.8rem"
            paddingbottom="5px"
            paddingtop="5px"
            textalign="left"
            fontStyle="italic">
            {" "}
            {errorMessageOnRejection}
          </Container>
        )}
      </Container>
    </FlexContainer>
  );
};

export default JoinedLeaguesMob;
