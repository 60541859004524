import TextInSpan from "../../../../../../../bricks/textInSpan";

interface ICounterForNextRace {
  nextRaceTime: number | null;
  minutes: number;
  seconds: number;
}
const CounterForNextRace = ({
  nextRaceTime,
  minutes,
  seconds
}: ICounterForNextRace) =>
  nextRaceTime ? (
    <>
      <TextInSpan fontWeight="bold" width="max-content" lineHeight={1}>
        {`0${minutes}`.slice(-2)}:
      </TextInSpan>
      <TextInSpan fontWeight="bold" width="max-content" lineHeight={1}>
        {`0${seconds}`.slice(-2)}
      </TextInSpan>
    </>
  ) : null;

export default CounterForNextRace;
