import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpandDownButton from "../../../composites/upAndDownButton/upAndDownButton";
import PuntersSelection from "./puntersSelection";
import { GetLeaderboardPuntersSelection } from "../../../../store/features/leaderboardSlice";
import FlexContainer from "../../../bricks/flexContainer";
import Container from "../../../bricks/container";
import SaddleNumberDisplay from "../../../composites/saddleNumberDisplay";
import { formatInDecimal } from "../../../../utils/numberUtils";
import LeaguePuntersSelection from "./leaguePuntersSelection";
import {
  MiddleContainer,
  PunterNameContainer,
  RankContainer,
  StatusContainer,
  WinnerSecThirdContainer,
  TotalContainer,
  TotalContainerWeb
} from "./punterItem.style";
import { RootState } from "../../../../store/store";
import { getLeagueLeaderboardDetails } from "../../../../store/features/friendsLeagueSlice";
import MedalOrShoe from "./medalOrShoe";
import theme from "../../../../theme";

interface IPunterItem {
  index: number;
  rank: string | number;
  leaderName: string;
  indexToOpen: number;
  total: number | string;
  openNotifier: (index: number) => void;
  winners?: string | number;
  second?: string | number;
  third?: string | number;
  punterData?: any;
  isSpecial?: boolean;
  isMobile?: boolean;
  isForMiniLeagues?: boolean;
  isLast?: boolean;
}

export const getColor = (isSpecial: boolean | undefined) => {
  return isSpecial ? theme.colors.purplePrimary : "white";
};
const getBorderColor = (isSpecial: boolean | undefined) => {
  return isSpecial ? theme.colors.purplePrimary : theme.colors.purpleLight2;
};

const PunterItem = ({
  index,
  rank,
  leaderName,
  indexToOpen,
  winners,
  third,
  second,
  punterData,
  total,
  openNotifier,
  isSpecial,
  isMobile,
  isForMiniLeagues,
  isLast
}: IPunterItem) => {
  const dispatch = useDispatch();

  const { currentMeetingId } = useSelector(
    (state: RootState) => state.leaderboard
  );

  const { punterId } = useSelector((state: RootState) => state.user);

  const [toExpand, setToExpand] = useState<boolean>(false);

  const sendQuery = () => {
    dispatch(
      GetLeaderboardPuntersSelection({
        punterId: punterData.punterId,
        meetingId: currentMeetingId
      })
    );
  };

  const sendQueryForMiniLeagues = () => {
    dispatch(getLeagueLeaderboardDetails({ punterId }));
  };

  const onExpansionButtonClick = () => {
    setToExpand((val) => !val);

    if (!isForMiniLeagues) {
      sendQuery();
    } else {
      sendQueryForMiniLeagues();
    }
  };

  useEffect(() => {
    if (toExpand && indexToOpen !== index) {
      setToExpand(false);
    }
  }, [indexToOpen]);

  return isMobile ? (
    <FlexContainer direction="column">
      <FlexContainer
        maxwidth="100%"
        width="100%"
        bgcolor="white"
        border={`1px solid ${getBorderColor(isSpecial)}`}
        justifycontent="space-between">
        <RankContainer id="parent_of_SaddleNumberDisplay">
          <FlexContainer
            id="parent_of_SaddleNumberDisplay"
            width="100%"
            paddingleft="2px"
            minheight="100%"
            alignitems="center"
            justifycontent="center"
            bgcolor={`${isSpecial ? theme.colors.purplePrimary : "none"}`}
            maxheight="20px">
            <SaddleNumberDisplay
              value={Number(rank)}
              maxHeight="24px"
              maxWidthForLow="25px"
              maxHeightForLow="15px"
              isSpecial={isSpecial}
              fontSize="0.6rem"
            />
          </FlexContainer>
        </RankContainer>

        <MiddleContainer id="parent_of_PunterNameContainer_WinnerSecThirdContainer">
          <PunterNameContainer id="punter_name">
            {" "}
            {leaderName}
          </PunterNameContainer>

          <MedalOrShoe
            isForMiniLeagues={!!isForMiniLeagues}
            rank={Number(rank)}
            isLast={!!isLast}
          />

          <WinnerSecThirdContainer id="winners_to_arrow">
            <StatusContainer>
              {" "}
              {`${winners} - ${second} - ${third}`}{" "}
            </StatusContainer>

            <Container
              height="max-content"
              onClick={() => {
                openNotifier(index);
                onExpansionButtonClick();
              }}>
              <UpandDownButton showDown={!toExpand} />
            </Container>
          </WinnerSecThirdContainer>
        </MiddleContainer>

        <TotalContainer $isSpecial={isSpecial}>
          {formatInDecimal(total)}
        </TotalContainer>
      </FlexContainer>

      {!isForMiniLeagues && (
        <PuntersSelection
          toOpen={toExpand}
          winners={winners}
          second={second}
          third={third}
          punterId={punterData.punterId}
        />
      )}
      {isForMiniLeagues && <LeaguePuntersSelection toOpen={toExpand} />}
    </FlexContainer>
  ) : (
    <FlexContainer direction="column">
      <FlexContainer
        width="100%"
        height="fit-content"
        alignitems="top"
        bgcolor="#ebf0fa"
        border={`1px solid ${getBorderColor(isSpecial)}`}>
        <FlexContainer
          id="parent_of_SaddleNumberDisplay"
          width="10%"
          minheight="100%"
          alignitems="center"
          justifycontent="center"
          borderright="1px solid #d1d1e0"
          boxshadow="2px 0px 2px #f0f0f5"
          bgcolor={getColor(isSpecial)}
          paddingtop="2px"
          paddingbottom="2px">
          <SaddleNumberDisplay
            value={Number(rank)}
            maxHeight="40px"
            maxWidthForLow="35px"
            maxHeightForLow="28px"
            isSpecial={isSpecial}
          />
        </FlexContainer>

        <FlexContainer
          width="40%"
          fontWeight={`${isSpecial ? "bold" : "normal"}`}
          fontSize={`${isSpecial ? "1.3vw" : "1.2vw"}`}
          textalign="left"
          bgcolor="white"
          paddingleft="10px"
          wordwrap="break-word"
          lineheight="1"
          alignitems="center"
          justifycontent="space-between">
          {leaderName}
          <MedalOrShoe
            isForMiniLeagues={!!isForMiniLeagues}
            rank={Number(rank)}
            isLast={!!isLast}
          />
        </FlexContainer>

        <TotalContainerWeb $isSpecial={isSpecial}>
          {formatInDecimal(+total)}
        </TotalContainerWeb>

        <FlexContainer
          width="34%"
          alignitems="center"
          paddingleft="10px"
          paddingright="10px"
          columngap="1vw">
          <span style={{ fontSize: "1vw" }}> Winners:</span>
          <Container
            display="grid"
            placecontent="center"
            width="3vw"
            height="2vw"
            border={`1px solid ${theme.colors.lightGrey4}`}
            borderradius="5px"
            bgcolor="white"
            boxshadow={`2px 2px 5px ${theme.colors.lightGrey4}`}
            fontSize="1.5vw">
            {winners}
          </Container>
          <span style={{ fontSize: "1vw" }}> 2nd:</span>
          <Container
            display="grid"
            placecontent="center"
            width="3vw"
            height="2vw"
            border={`1px solid ${theme.colors.lightGrey4}`}
            borderradius="5px"
            bgcolor="white"
            boxshadow={`2px 2px 5px ${theme.colors.lightGrey4}`}
            fontSize="1.5vw">
            {second}
          </Container>
          <span style={{ fontSize: "1vw" }}> 3rd:</span>
          <Container
            display="grid"
            placecontent="center"
            width="3vw"
            height="2vw"
            border={`1px solid ${theme.colors.lightGrey4}`}
            borderradius="5px"
            bgcolor="white"
            boxshadow={`2px 2px 5px ${theme.colors.lightGrey4}`}
            fontSize="1.5vw">
            {third}
          </Container>
        </FlexContainer>

        <Container
          width="6%"
          marginright="3px"
          display="grid"
          placecontent="center"
          onClick={() => {
            openNotifier(index);
            onExpansionButtonClick();
          }}>
          <UpandDownButton showDown={!toExpand} isBoxType />
        </Container>
      </FlexContainer>
      {!isForMiniLeagues && (
        <PuntersSelection toOpen={toExpand} punterId={punterData.punterId} />
      )}
      {isForMiniLeagues && <LeaguePuntersSelection toOpen={toExpand} />}
    </FlexContainer>
  );
};

export default PunterItem;
