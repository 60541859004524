import TextInSpan from "../../../../../../../bricks/textInSpan";

interface ICounterForSelection {
  meetingStartTime: number | null;
  nextRaceTime: number | null;
  hours: number;
  minutes: number;
  seconds: number;
}
const CounterForSelection = ({
  meetingStartTime,
  nextRaceTime,
  hours,
  minutes,
  seconds
}: ICounterForSelection) =>
  meetingStartTime && !nextRaceTime ? (
    <>
      <TextInSpan lineHeight={1} fontWeight="bold" width="max-content">
        {`0${hours}`.slice(-2)}:
      </TextInSpan>
      <TextInSpan lineHeight={1} fontWeight="bold" width="max-content">
        {`0${minutes}`.slice(-2)}:
      </TextInSpan>
      <TextInSpan lineHeight={1} fontWeight="bold" width="max-content">
        {`0${seconds}`.slice(-2)}
      </TextInSpan>
    </>
  ) : null;

export default CounterForSelection;
