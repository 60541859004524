import styled from "styled-components";
import theme from "../../../../theme";

export const MainFooterDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: ${theme.colors.titleAndTextPurple};
`;

export const PagesContainer = styled.div`
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  flex-direction: column;
  text-align: center;
  font-size: ${theme.font.size.xs};
  margin-bottom: 20px;
  padding-top: 10pt;
`;

export const MeetingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: ${theme.font.size.xs};
  justify-content: flex-end;
`;

export const MeetingInfo = styled.div`
  text-align: center;
  white-space: nowrap;
  padding-bottom: 10px;
`;
