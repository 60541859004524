import { useSelector } from "react-redux";
import styled from "styled-components";
import HeadingsContainer from "./headingsContainer";
import HorseStatistics from "./horseStatistics";
import theme from "../../../theme";
import { RootState } from "../../../store/store";
import LocalSpinner from "../../bricks/localSpinner";

export const PunterSelectionsContainer = styled.div`
  width: 100%;
  font-size: ${theme.font.size.sm};
  display: grid;
  row-gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${theme.colors.cardSelected};
  box-shadow: 0px 4px 8px ${theme.colors.cardShadow};
`;

const PunterSelections = () => {
  const selections = useSelector(
    (state: RootState) => state.results.punterSelections
  );

  const puntersOwnSelections = selections?.map((item) => (
    <HorseStatistics
      key={item.saddle}
      raceNumber={item.raceNumber}
      saddle={item.saddle}
      horseName={item.horseName}
      points={item.points}
      position={item.racePosition}
    />
  ));

  return (
    <PunterSelectionsContainer>
      <HeadingsContainer />

      {!selections && (
        <LocalSpinner containerWidth="100%" containerHeight="50px" />
      )}

      {puntersOwnSelections}
    </PunterSelectionsContainer>
  );
};

export default PunterSelections;
