import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { Landing } from "./pages/landing";

import Home from "./pages/home";
import Notfound from "./pages/notfound";
import Leaderboard from "./pages/leaderboard";
import Results from "./pages/results";
import LearnToPlay from "./pages/learnToPlay";
import TermsAndConditions from "./pages/termsAndConditions";
import FaqPage from "./pages/faqPage/faqpage";
import Paiamanual from "./pages/paiamanual";
import ProtectedRoutes from "./protectedroutes";
import HomeNew from "./pages/homeNew";
import { RootState } from "./store/store";
import { MediaQueriesContext } from "./contexts/mediaQueriesContext";
import FriendsLeague from "./pages/friendsLeague";

const App = () => {
  const { showNewHomeForMob, showNewHomeForWeb } = useSelector(
    (state: RootState) => state.config.data
  );

  const { isDesktop } = useContext(MediaQueriesContext).data;

  const canShowNew = () => {
    if (isDesktop) {
      return showNewHomeForWeb;
    }
    return showNewHomeForMob;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Landing />}>
          <Route index element={canShowNew() ? <HomeNew /> : <Home />} />
          <Route path="home" element={canShowNew() ? <HomeNew /> : <Home />} />
          {/* <Route path='login' element={<Login />} /> */}
          <Route element={<ProtectedRoutes />}>
            <Route path="results" element={<Results />} />
            <Route path="friendsChallenge" element={<FriendsLeague />} />
          </Route>
          {/* <Route path='results' element={<Results />} /> */}
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="learntoplay" element={<LearnToPlay />} />
          <Route path="termsandconditions" element={<TermsAndConditions />} />
          <Route path="faqpage" element={<FaqPage />} />
          <Route path="paia" element={<Paiamanual />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </>
    )
  );
  return <RouterProvider router={router} />;
};

export default App;
