import styled from "styled-components";
import theme from "../../../theme";

interface ILinkButtonProps {
  isGallopTv?: boolean;
}

export const LinkButtonHolder = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 99%;
  height: 65px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-align: right;
  font-weight: ${theme.font.weight.heavyBold};
`;

export const ButtonLogo = styled.img<ILinkButtonProps>`
  max-width: ${(props) => (props.isGallopTv ? "170px" : "150px")};
  height: ${(props) => (props.isGallopTv ? "75px" : "55px")};
  margin-left: ${(props) => (props.isGallopTv ? "-6px" : "0px")};
  display: flex;
  align-content: flex-end;
`;

export const ButtonText = styled.span`
  color: ${theme.colors.titleAndTextPurple};
  font-size: ${theme.font.size.sm};
  font-family: poppins;
  width: fit-content;
  padding-right: 20px;
  display: grid;
  align-items: center;
`;
