import SaddleIcon1 from "@assets/saddle-icons/saddle-icon-1.svg";
import SaddleIcon2 from "@assets/saddle-icons/saddle-icon-2.svg";
import SaddleIcon3 from "@assets/saddle-icons/saddle-icon-3.svg";
import SaddleIcon4 from "@assets/saddle-icons/saddle-icon-4.svg";
import SaddleIcon5 from "@assets/saddle-icons/saddle-icon-5.svg";
import SaddleIcon6 from "@assets/saddle-icons/saddle-icon-6.svg";
import SaddleIcon7 from "@assets/saddle-icons/saddle-icon-7.svg";
import SaddleIcon8 from "@assets/saddle-icons/saddle-icon-8.svg";
import SaddleIcon9 from "@assets/saddle-icons/saddle-icon-9.svg";
import SaddleIcon10 from "@assets/saddle-icons/saddle-icon-10.svg";
import SaddleIcon11 from "@assets/saddle-icons/saddle-icon-11.svg";
import SaddleIcon12 from "@assets/saddle-icons/saddle-icon-12.svg";
import SaddleIcon13 from "@assets/saddle-icons/saddle-icon-13.svg";
import SaddleIcon14 from "@assets/saddle-icons/saddle-icon-14.svg";
import SaddleIcon15 from "@assets/saddle-icons/saddle-icon-15.svg";
import SaddleIcon16 from "@assets/saddle-icons/saddle-icon-16.svg";
import SaddleIcon17 from "@assets/saddle-icons/saddle-icon-17.svg";
import SaddleIcon18 from "@assets/saddle-icons/saddle-icon-18.svg";
import SaddleIcon19 from "@assets/saddle-icons/saddle-icon-19.svg";
import SaddleIcon20 from "@assets/saddle-icons/saddle-icon-20.svg";
import SaddleIcon21 from "@assets/saddle-icons/saddle-icon-21.svg";

const GetImage: { [key: number]: any } = {
  1: SaddleIcon1,
  2: SaddleIcon2,
  3: SaddleIcon3,
  4: SaddleIcon4,
  5: SaddleIcon5,
  6: SaddleIcon6,
  7: SaddleIcon7,
  8: SaddleIcon8,
  9: SaddleIcon9,
  10: SaddleIcon10,
  11: SaddleIcon11,
  12: SaddleIcon12,
  13: SaddleIcon13,
  14: SaddleIcon14,
  15: SaddleIcon15,
  16: SaddleIcon16,
  17: SaddleIcon17,
  18: SaddleIcon18,
  19: SaddleIcon19,
  20: SaddleIcon20,
  21: SaddleIcon21
};

export default GetImage;
