import {
  BetType,
  IBetSlipBet,
  IBetSlipData,
  IBetslipBetType
} from "../betslip.models";
import { isAllToBet, isSingleBet } from "../helpers/betUtils";

const TYPES = {
  ALL_TO_WIN: "ALL TO WIN",
  WIN_SINGLE_BETS: "WIN - SINGLE BETS",
  ALL_TO_PLACE: "ALL TO PLACE",
  PLACE_SINGLE_BETS: "PLACE - SINGLE BETS",
  DOUBLES: "DOUBLES",
  TREBLES: "TREBLES",
  QUADS: "QUADS"
};

export interface IBetCombinatonInfo {
  BetTitle: string;
  CombinationBetTypeId: number;
  LegsPerBet: number;
  TotalStake: number;
  TotalOdds: number;
  Payout: number;
  GUID: string | null;
  pid: number;
}

export interface IBetslipCombo {
  combinatonInfo: IBetCombinatonInfo;
  dt: IBetslipBetType[];
}

export interface IBetSlipSingleBets {
  PID: number;
  SingleWinStake: number;
  SingleWinHorses: IBetslipBetType[];
  SinglePlaceStake: number;
  SinglePlaceHorses: IBetslipBetType[];
}

const getBetTypeName = (type: BetType): string => {
  switch (type) {
    case BetType.SingleWin:
      return TYPES.WIN_SINGLE_BETS;
    case BetType.SinglePlace:
      return TYPES.PLACE_SINGLE_BETS;
    case BetType.WinDoubles:
      return TYPES.DOUBLES;
    case BetType.WinTrebles:
      return TYPES.TREBLES;
    case BetType.WinQuads:
      return TYPES.QUADS;
    case BetType.AllToCome:
      return TYPES.ALL_TO_WIN;
    case BetType.AllToPlace:
      return TYPES.ALL_TO_PLACE;
    default:
      return "FAILED";
  }
};

const getLegsPerBet = (betType: BetType, legs: number): number => {
  switch (betType) {
    case BetType.WinDoubles:
      return 2;
    case BetType.WinTrebles:
      return 3;
    case BetType.WinQuads:
      return 4;
    default:
      return legs;
  }
};

const getOddsPerBet = (currentBet: IBetSlipBet): number => {
  return currentBet.isFixedOdds ? currentBet.finalOdds : 0;
};

const isSingleBetType = (betSlipData: IBetSlipData, betType: BetType) => {
  return (
    betSlipData.bets.filter(
      (bet) =>
        bet.betType === betType &&
        bet.enabled &&
        bet.stakeToBet > 0 &&
        (!bet.overLimit || !bet.isFixedOdds)
    ).length > 0
  );
};

const isSingleWinSP = (betslipData: IBetSlipData) => {
  return (
    betslipData.bets.filter(
      (bet) => bet.betType === BetType.SingleWin && !bet.isFixedOdds
    ).length > 0
  );
};

export const processBetslip = (
  userId: number,
  bets: IBetslipBetType[] | null,
  placeBets: IBetslipBetType[] | null,
  spBets: IBetslipBetType[] | null,
  betslipData: IBetSlipData
): {
  bets: IBetslipCombo[];
  singleBet: IBetSlipSingleBets;
  isLuckyPick: boolean;
} => {
  const combosBets: IBetslipCombo[] = [];
  const horseNames = betslipData.races
    .filter((horse) => horse.selected)
    .map((a) => a.horseName);

  const isSinglePlaceActive = isSingleBetType(betslipData, BetType.SinglePlace);

  const isSingleWinActive = isSingleBetType(betslipData, BetType.SingleWin);

  const horseWinList: IBetslipBetType[] = [];
  const horsePlaceList: IBetslipBetType[] = [];

  if (bets !== null && bets.length > 0) {
    const betHorses = bets.filter(
      (a) => horseNames.indexOf(a.eventDetailName) > -1
    );

    betslipData.bets
      .filter(
        (bet) =>
          !isSingleBet(bet.betType) &&
          bet.betType !== BetType.AllToPlace &&
          bet.stakeToBet > 0 &&
          bet.enabled === true &&
          (!bet.overLimit || !bet.isFixedOdds)
      )
      .forEach((bet) => {
        const totalStake = bet.stakeToBet * bet.betCount;
        const activeLegs = isAllToBet(bet)
          ? bet.legs.filter((leg) => leg.enabled)
          : [];

        const thisBet: IBetslipCombo = {
          combinatonInfo: {
            BetTitle: getBetTypeName(bet.betType),
            CombinationBetTypeId: isAllToBet(bet) ? 1 : 3,
            LegsPerBet: getLegsPerBet(bet.betType, activeLegs.length),
            TotalStake: isAllToBet(bet) ? bet.stakeToBet : totalStake,
            TotalOdds: getOddsPerBet(bet),
            Payout:
              bet.finalOdds * (isAllToBet(bet) ? bet.stakeToBet : totalStake),
            GUID: null,
            pid: userId
          },
          dt: []
        };

        betHorses.forEach((a) => {
          const spBetData = spBets?.filter((sp) => sp.eventID === a.eventID);

          if (!bet.isFixedOdds && spBetData?.length) {
            const {
              fK_EventBetTypeMapID: betTypeKey,
              eventBetTypeDetailMapID: betTypeMapId
            } = spBetData[0];
            if (
              !isAllToBet(bet) ||
              activeLegs.filter(
                (leg) => leg.race.horseName === a.eventDetailName
              ).length > 0
            ) {
              thisBet.dt.push({
                ...a,
                eventDetailOfferedOdds: 0,
                fK_EventBetTypeMapID: betTypeKey,
                eventBetTypeDetailMapID: betTypeMapId
              });
            }
          } else if (
            !isAllToBet(bet) ||
            activeLegs.filter((leg) => leg.race.horseName === a.eventDetailName)
              .length > 0
          ) {
            thisBet.dt.push({
              ...a
            });
          }
        });

        combosBets.push(thisBet);
      });

    if (horseWinList.length === 0) {
      betHorses.forEach((a) => {
        const spBetData = spBets?.filter((sp) => sp.eventID === a.eventID);

        if (isSingleWinSP(betslipData) && spBetData?.length) {
          const {
            fK_EventBetTypeMapID: betTypeKey,
            eventBetTypeDetailMapID: betTypeMapId
          } = spBetData[0];
          horseWinList.push({
            ...a,
            eventDetailOfferedOdds: 0,
            fK_EventBetTypeMapID: betTypeKey,
            eventBetTypeDetailMapID: betTypeMapId
          });
        } else {
          horseWinList.push({
            ...a
          });
        }
      });
    }
  }

  if (placeBets !== null && placeBets.length > 0) {
    const betHorses = placeBets.filter(
      (a) => horseNames.indexOf(a.eventDetailName) > -1
    );

    betslipData.bets
      .filter(
        (bet) =>
          bet.betType === BetType.AllToPlace &&
          bet.stakeToBet > 0 &&
          bet.enabled === true &&
          bet.overLimit === false
      )
      .forEach((bet) => {
        const totalStake = bet.stakeToBet * bet.betCount;
        const activeLegs = bet.legs.filter((leg) => leg.enabled);

        const thisBet: IBetslipCombo = {
          combinatonInfo: {
            BetTitle: getBetTypeName(bet.betType),
            CombinationBetTypeId: isAllToBet(bet) ? 1 : 3,
            LegsPerBet: getLegsPerBet(bet.betType, activeLegs.length),
            TotalStake: isAllToBet(bet) ? bet.stakeToBet : totalStake,
            TotalOdds: bet.finalOdds,
            Payout:
              bet.finalOdds * (isAllToBet(bet) ? bet.stakeToBet : totalStake),
            GUID: null,
            pid: userId
          },
          dt: []
        };

        betHorses.forEach((a) => {
          if (
            activeLegs.filter((leg) => leg.race.horseName === a.eventDetailName)
              .length > 0
          ) {
            thisBet.dt.push({
              ...a,
              eventDetailOfferedOdds: bet.isFixedOdds
                ? a.eventDetailOfferedOdds
                : 0
            });
          }
        });

        combosBets.push(thisBet);
      });

    if (horsePlaceList.length === 0) {
      betHorses.forEach((a) =>
        horsePlaceList.push({
          ...a
        })
      );
    }
  }

  const singleWinBet = betslipData.bets.filter(
    (bet) => bet.betType === BetType.SingleWin
  )[0];
  const singlePlaceBet = betslipData.bets.filter(
    (bet) => bet.betType === BetType.SinglePlace
  )[0];

  return {
    bets: combosBets,
    singleBet: {
      PID: userId,
      SingleWinStake: isSingleWinActive
        ? singleWinBet.betCount * singleWinBet.stakeToBet
        : 0,
      SingleWinHorses: isSingleWinActive ? horseWinList : [],
      SinglePlaceStake: isSinglePlaceActive
        ? singlePlaceBet.betCount * singlePlaceBet.stakeToBet
        : 0,
      SinglePlaceHorses: isSinglePlaceActive ? horsePlaceList : []
    },
    isLuckyPick: false
  };
};
