import { BetType } from "../components/views/betslipNew/betslip.models";
import { ILuckyPickBetType } from "../components/views/luckyPick/store/luckyPickReducer";

export const getFlavourText = (finalBetCount: number) => {
  return finalBetCount === 1 ? "1 BET" : `${finalBetCount} BETS`;
};

export const getBetName = (betType: BetType, betCount: number) => {
  let name = betType.toString().toUpperCase();

  if (betCount === 1 && name.lastIndexOf("S") > 1) {
    name = name.substring(0, name.length - 1);
  }

  return name;
};

export const getPotentialPayout = (bet: ILuckyPickBetType) => {
  return bet.isPayoutOverLimit ? 0 : bet?.payout! ?? 0;
};

export const getStake = (bet: ILuckyPickBetType) => {
  return bet.isPayoutOverLimit ? 0 : bet?.stake! ?? 0;
};

export const isInvalidBet = (bet: ILuckyPickBetType) => {
  return bet.stake === 0 || bet.payout === 0 || bet.isPayoutOverLimit;
};
