import styled from "styled-components";
import theme from "../../../theme";

interface IContainer {
  borderRight?: string;
  borderTop?: string;
  width?: number;
  justifyContent?: string;
  paddingLeft?: string;
}

export const HeadingContainer = styled.div<IContainer>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top: ${(props) => (props ? props.borderTop : "none")};
  font-size: ${theme.font.size.sm};

  @media screen and (max-width: 500px) {
    font-size: ${theme.font.size.s};
  }

  @media screen and (max-width: 420px) {
    font-size: 0.6rem;
  }
`;

export const GeneralContainer = styled.div<IContainer>`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-around"};
  align-items: center;
  height: 30px;
  width: ${(props) => props.width}%;
  font-weight: bold;
`;

export const SecondaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`;

export const PointsContainer = styled.div`
  border: 1px solid ${theme.colors.purplePrimary};
  width: 50%;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0px 0px 5px;
  background-image: linear-gradient(
    ${theme.colors.purplePrimary},
    ${theme.colors.titleAndTextPurple}
  );
  color: white;
  font-weight: bold;
  box-shadow: 1px 1px 5px grey;
`;

export const PositionContainer = styled.div`
  border: 1px solid ${theme.colors.purplePrimary};
  width: 50%;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  background-image: linear-gradient(
    ${theme.colors.yellowPrimary},
    ${theme.colors.horseCardYellow}
  );
  color: ${theme.colors.purplePrimary};
  font-weight: bold;
  box-shadow: 1px 1px 5px grey;
`;

export const SaddleImages = styled.img`
  max-width: 50%;
  height: auto;
`;
