import styled from "styled-components";
import theme from "../../../theme";

interface ILeaderboardCardProps {
  isDesktop: boolean;
}

export const LeaderboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LeaderboardTitle = styled.span`
  text-align: left;
  padding-left: 16px;
  color: ${theme.colors.purplePrimary};
  font-weight: ${theme.font.weight.bold};
  font-size: 1.1rem;
  width: 30%;
`;

export const LeaderboardItemsContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 80px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const LoadingParent = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
`;

export const LoadingDiv = styled.div`
  position: fixed;
  top: calc((90% / 2));
  left: calc(50% - 50px / 2);
  z-index: 100;
`;

export const SearchDiv = styled.div<ILeaderboardCardProps>`
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isDesktop ? "flex-end" : "center")};
  margin: 0 8px 5px 8px;
`;

export const SearchNotification = styled.div<ILeaderboardCardProps>`
  width: ${(props) => (props.isDesktop ? "92%" : "85%")};
  padding: 8px 16px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  border-radius: 8px;
  font-size: ${theme.font.size.s};
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.meetingsLightGrey};
`;
