import { useDispatch } from "react-redux";
import SaddleNumberDisplay from "../../../../composites/saddleNumberDisplay";
import Container from "../../../../bricks/container";
import {
  SelectedContainer,
  SaddleNumber,
  IndexText,
  HorseNameText,
  OddsText
} from "./horseSelector.style";
import theme from "../../../../../theme";
import { IBetSlipRace } from "../../betslip.models";
import { updateHorseSelected } from "../../../../../store/features/newBetSlipSlice";

interface IHorseSelector {
  data: IBetSlipRace;
  index: number;
}

const HorseSelector = ({ data, index }: IHorseSelector) => {
  const { raceNumber, horseNumber, horseName, odds, selected } = data;
  const dispatch = useDispatch();

  const handleItemClick = () => {
    dispatch(
      updateHorseSelected({
        index,
        isSelected: !selected
      })
    );
  };

  return (
    <Container
      borderradius="6px"
      border={`2px solid ${theme.colors.purplePrimary}`}
      color="black"
      margin="-2px 0px 0px 0px"
      cursor="pointer"
      bgcolor={
        selected
          ? `linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`
          : "white"
      }
      onClick={handleItemClick}>
      <SelectedContainer>
        <IndexText>{raceNumber}.</IndexText>
        <SaddleNumber>
          <SaddleNumberDisplay value={horseNumber} />
        </SaddleNumber>
        <HorseNameText>{horseName.toUpperCase()}</HorseNameText>
        <OddsText>{odds}</OddsText>
      </SelectedContainer>
    </Container>
  );
};

export default HorseSelector;
