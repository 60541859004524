import { styled } from "styled-components";
import theme from "../../../../../theme";

export const DotsContainer = styled.div`
  display: flex;
  position: relative;
  column-gap: 5px;
  cursor: pointer;
`;

export const Basedot = styled.div`
  border: 2px solid #d1d1e0;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
`;

export const InactiveDot = styled(Basedot)`
  background-color: white;
`;

export const ActiveDot = styled(Basedot)`
  background-color: ${theme.colors.yellowPrimary};
`;

export const DotsClickable = styled.div``;
