const TEXTS: Record<string, string> = {
  "ALL TO WIN":
    "Based on all your selections on the card, this bet requires all your selections to win. For example, if there are 8 races on the card, all 8 of your selections will need to win their races in order to win this bet type. Minimum spend is R1.",
  "WIN - SINGLE BETS":
    "These are single win bets taken on each race on the card. For example, if there are 8 races on the card, this bet type will take single win bets on each of your selections on the card. If any of your selected horses win then you will win one of the single bets. Minimum spend will be based on the number of races on the card (R1 per bet).",
  "ALL TO PLACE":
    "This is one single all-to-place bet. This means that all your selections will be taken as one multiple place bet. For example, if there are 8 races on the card, all 8 of your selections will be put into one multiple place bet. Depending on the number of runners in each race, you will get the maximum places offered for each race. The minimum spend is R1.",
  "PLACE - SINGLE BETS":
    "These are single place bets taken on each race on the card. For example, if there are 8 races on the card, this bet type will take single place bets on each of your selections on the card. If any of your selected horses place in their races, then you will win one of the place bets. Minimum spend will be based on the number of races on the card (R1 per bet).",
  DOUBLES:
    "A double bet is a bet involving a minimum of 2 horses to win on the day. For example, if there are 8 races on the card there will be 28 double combinations, you can take this bet for a minimum of R28.",
  TREBLES:
    "A treble bet is a bet involving a minimum of 3 horses to win on the day. For example, if there are 8 races on the card there will be 56 treble combinations, you can take this bet for a minimum of R56.",
  QUADS:
    "A quad bet is a bet involving a minimum of 4 horses to win on the day. For example, if there are 8 races on the card there will be 70 quad combinations, you can take this bet for a minimum of R70."
};
function useInfotextOld() {
  const getInfoForType = (type: string): string => TEXTS[type] || "";

  return [getInfoForType];
}

export default useInfotextOld;
