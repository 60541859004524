export const MILESTONE_ITEM_TYPES = {
  bestCourse: "Your best course",
  mostWins: "You most wins",
  bestFinish: "Your best finish",
  highestPoints: "Your highest points"
};

export const MOB_TABS = ["My Leagues", "Joined Leagues"]; // "My Stats" to be added back when we enable it again
export const WEB_HEADER_TABS = [
  "Leagues",
  "Join A League",
  "Create A League",
  "Delete A League"
];

export const STATS_DROP_DOWN_ITEMS_ITEMS = ["Current week", "All time stats"];

export const MY_LEAGUES_HEADERS = [
  "League name",
  "Rank/#Players",
  "League Code",
  "Share code",
  "Status",
  "Manage"
];

export const SEARCHWORDS_FOR_PROTECTED_ROUTES_ON_REFRESH = [
  "friendsLeague?CODE="
];
