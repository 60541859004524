import styled from "styled-components";
import { memo } from "react";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import Button from "../../../bricks/button";
import theme from "../../../../theme";
import NotificationBell from "./notificationBell";
import ConfirmationModal from "../../modals/confirmationModal";
import overrideBack from "../../../../utils/overrideBack";
import useLeagueItemCommonLogic from "./hooks/useLeagueItemCommonLogic";

const ViewLeaderboardButtonWeb = styled(Button)`
  font-size: 0.6rem;
  font-weight: 500;

  @media screen and (max-width: 884px) and (min-width: 767px) {
    font-size: 0.35rem;
  }

  @media screen and (max-width: 1110px) and (min-width: 885px) {
    font-size: 0.44rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 0.7rem;
  }
`;

interface ILeagueRowItemWeb {
  index: number;
  isLastRow: boolean;
  leagueName: string;
  rank: number;
  numPlayers: number;
  leagueCode: string;
  isActive: boolean;
  toCaterForSidebar: boolean;
  isForJoinedLeagues?: boolean;
  numNotifictions?: number;
  handleClose: () => boolean;
}

const LeagueRowItemWeb = ({
  index,
  isLastRow,
  leagueName,
  rank,
  numPlayers,
  leagueCode,
  isActive,
  isForJoinedLeagues,
  numNotifictions,
  handleClose,
  toCaterForSidebar
}: ILeagueRowItemWeb) => {
  const {
    isModalOpen,
    setIsModalOpen,
    shareLeague,
    onManageClick,
    confirmExit,
    onViewLeaderboard
  } = useLeagueItemCommonLogic({ isForJoinedLeagues, leagueName, leagueCode });

  const handleManageClick = () => {
    overrideBack(handleClose);
    onManageClick();
  };

  const getWidth = () => {
    return toCaterForSidebar ? "16.5%" : "16.4%";
  };
  return (
    <FlexContainer
      bgcolor={index % 2 === 0 ? "white" : theme.colors.lightAliceBlue}
      fontSize="0.8rem"
      color="black"
      paddingtop="5px"
      paddingbottom="5px"
      alignitems="center"
      borderbottomleftradius={`${isLastRow ? 5 : 0}px`}
      borderbottomrightradius={`${isLastRow ? 5 : 0}px`}>
      <Container
        width={getWidth()}
        textalign="left"
        paddingleft="20px"
        cursor="pointer"
        onClick={onViewLeaderboard}>
        {leagueName}
      </Container>
      <Container width={getWidth()} textalign="left" paddingleft="1.5px">
        {`${rank}/${numPlayers}`}
      </Container>
      <Container width={getWidth()} textalign="left" paddingleft="1.5px">
        {leagueCode}
      </Container>
      <Container width={getWidth()} textalign="left" paddingleft="2px">
        <ViewLeaderboardButtonWeb
          width="80%"
          text="Share Code"
          bordercolor={theme.colors.lightGray}
          borderWidth={1}
          backgroundcolor="white"
          fontcolor={theme.colors.bluishGray}
          fontSize="0.7rem"
          onClick={shareLeague}
        />
      </Container>
      <Container width={getWidth()} textalign="left" marginleft="2px">
        <Button
          width="80%"
          text={`${isActive ? "Active" : "Disabled"}`}
          bordercolor={theme.colors.refreshGreen}
          borderWidth={isActive ? 1 : 0}
          backgroundcolor={`${isActive ? theme.colors.refreshGreen : theme.colors.viewLeaderboardGray}`}
          fontcolor="white"
          fontSize="0.7rem"
          hasNoInteraction
        />
      </Container>

      <FlexContainer
        width="12%"
        border={`1px solid ${theme.colors.purplePrimary}`}
        borderradius="4px"
        justifycontent="center"
        onClick={handleManageClick}
        bgcolor="white"
        cursor="pointer">
        <Container
          cursor="pointer"
          fontSize="0.8rem"
          color={theme.colors.purplePrimary}
          margintop="4px"
          marginbottom="4px"
          marginleft={`${numNotifictions! > 0 ? 1.2 : 0}vw`}>
          {isForJoinedLeagues ? "Exit" : "Manage"}
        </Container>
        {numNotifictions! > 0 && (
          <NotificationBell numNotifications={numNotifictions!} />
        )}
      </FlexContainer>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        modalTitle="Exit League?"
        modalDescription="Are you sure you want to exit the league?"
        onYesCallback={confirmExit}
        onNoCallback={() => setIsModalOpen(false)}
        left={50}
        bottom={80}
        maxWidth="93%"
      />
    </FlexContainer>
  );
};

export default memo(LeagueRowItemWeb);
