import { useDispatch } from "react-redux";
import { useContext } from "react";
import ResultsBanner from "./results-race-card-banner/resultsRaceCardBanner";
import ResultsView from "./results-race-card-view/resultsRaceCardView";
import FlexContainer from "../../../../bricks/flexContainer";
import Container from "../../../../bricks/container";
import {
  IResultsInfoRequest,
  GetResultsInformation
} from "../../../../../store/features/resultsSlice";
import Leaderboard from "../leaderboard/leaderboard";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import theme from "../../../../../theme";

export interface IResultsRaceCard {
  index: number;
  raceCourseName: string;
  raceDate: string;
  meetingId: number;
  userId: string;
  shouldOpen: boolean;
  onViewClick: (index: number) => void;
}

const ResultsRaceCard = ({
  index,
  raceCourseName,
  raceDate,
  meetingId,
  userId,
  shouldOpen,
  onViewClick
}: IResultsRaceCard) => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const onViewMeetingResult = () => {
    const topPuntersRequest: IResultsInfoRequest = {
      meetingId,
      userId
    };

    if (!shouldOpen) {
      dispatch(GetResultsInformation(topPuntersRequest));
    }
    onViewClick(index);
  };

  return (
    <Container
      padding="5px 10px"
      width={isDesktop ? "calc(100% - 20px)" : "96%"}>
      <FlexContainer
        width="100%"
        borderradius="8px"
        boxshadow={`0px 4px 8px ${theme.colors.cardShadow}`}>
        <ResultsBanner
          raceCourseName={raceCourseName}
          raceDate={raceDate}
          shouldOpen={shouldOpen}
        />
        <ResultsView
          raceCourseName={raceCourseName}
          shouldOpen={shouldOpen}
          handleClick={onViewMeetingResult}
        />
      </FlexContainer>
      {shouldOpen && <Leaderboard punterId={userId} meetingId={meetingId} />}
    </Container>
  );
};

export default ResultsRaceCard;
