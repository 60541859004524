import { useContext } from "react";
import { getRaceCourseCountryFlag } from "../../../../../../../utils/raceCourseUtils";
import FlexContainer from "../../../../../../bricks/flexContainer";
import Image from "../../../../../../bricks/image";
import { MediaQueriesContext } from "../../../../../../../contexts/mediaQueriesContext";

interface IMeetingFlag {
  width?: string;
  raceCourseName?: string;
}

const MeetingFlag = ({ width, raceCourseName }: IMeetingFlag) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  return (
    <FlexContainer
      cursor="pointer"
      height="max-content"
      maxheight={isDesktop ? "45px" : "37px"}
      width={width ?? "20%"}
      maxwidth="81px">
      <Image src={getRaceCourseCountryFlag(raceCourseName ?? "")} />
    </FlexContainer>
  );
};

export default MeetingFlag;
