import styled from "styled-components";

const NUMBER_COLORS: { [key: number]: any } = {
  1: { color: "red", fontColor: "white" },
  2: { color: "white", fontColor: "black" },
  3: { color: "blue", fontColor: "white" },
  4: { color: "yellow", fontColor: "black" },
  5: { color: "green", fontColor: "white" },
  6: { color: "black", fontColor: "yellow" },
  7: { color: "tomato", fontColor: "black" },
  8: { color: "#ff80ff", fontColor: "black" },
  9: { color: "#00ccff", fontColor: "black" },
  10: { color: "#d11aff", fontColor: "white" },
  11: { color: "#d1d1e0", fontColor: "red" },
  12: { color: "#66ff66", fontColor: "black" },
  13: { color: "#992600", fontColor: "white" },
  14: { color: "#660000", fontColor: "yellow" },
  15: { color: "#dfbe9f", fontColor: "black" },
  16: { color: "#c6d9eb", fontColor: "red" },
  17: { color: "#00004d", fontColor: "white" },
  18: { color: "#006600", fontColor: "yellow" },
  19: { color: "#000033", fontColor: "red" },
  20: { color: "#ff4dff", fontColor: "yellow" }
};

interface INumberContainer {
  position?: string;
  background?: string;
  color?: string;
  border?: string;
  width?: string;
  height?: string;
  shadow?: string;
  fontSize?: string;
  radius?: string;
  maxwidth?: string;
  $noFancy?: boolean;
}

const NumberContainer = styled.div<INumberContainer>`
  display: grid;
  place-content: center;
  background: ${(props) => props.background ?? "#ececf9"};
  color: ${(props) => props.color ?? "white"};
  border: ${(props) => props.border ?? "1px solid black"};
  width: ${(props) => props.width ?? "10px"};
  height: ${(props) => props.height ?? "10px"};
  max-width: ${(props) => props.maxwidth ?? `${props.width ?? "10px"}`};
  box-shadow: ${(props) =>
    props.$noFancy ? "" : props.shadow ?? "4px 4px 10px #893101"};
  border-bottom-right-radius: ${(props) => props.radius ?? "12px"};
  font-size: ${(props) => props.fontSize ?? "1rem"};
  font-weight: bold;
`;

interface INumberDisplay {
  width?: string;
  height?: string;
  color?: string;
  value?: string | number;
  shadow?: string;
  border?: string;
  fontSize?: string;
  radius?: string;
  maxwidth?: string;
  cutOff?: number;
}
const NumberDisplay = ({
  color,
  value,
  shadow,
  width,
  height,
  border,
  fontSize,
  radius,
  maxwidth,
  cutOff
}: INumberDisplay) => {
  const withoutFancy: boolean = !!(cutOff && Number(value) > cutOff);

  return (
    <NumberContainer
      $noFancy={withoutFancy}
      width={width}
      height={height}
      radius={radius}
      shadow={shadow}
      border={border}
      background={NUMBER_COLORS[Number(value)!]?.color || "none"}
      color={NUMBER_COLORS[Number(value)!]?.fontColor || color || "black"}
      fontSize={fontSize}
      maxwidth={maxwidth}>
      {" "}
      {value}{" "}
    </NumberContainer>
  );
};

export default NumberDisplay;
