import styled from "styled-components";

interface ITextInSpan {
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  width?: string;
  lineHeight?: number;
  zIndex?: number;
  shadow?: string;
  marginTop?: string;
  textAlign?: string;
  marginRight?: string;
  padding?: string;
}
const TextInSpan = styled.span<ITextInSpan>`
  font-size: ${(props) => props.fontSize ?? "inherit"};
  width: ${(props) => props.width ?? "100%"};
  line-height: ${(props) => props.lineHeight ?? 1};
  color: ${(props) => props.color ?? "inherit"};
  opacity: 1;
  z-index: ${(props) => props.zIndex ?? "auto"};
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  text-shadow: ${(props) => props.shadow ?? "none"};
  margin-top: ${(props) => props.marginTop ?? "0px"};
  text-align: ${(props) => props.textAlign ?? "center"};
  margin-right: ${(props) => props.marginRight ?? "0px"};
  padding: ${(props) => props.padding};
`;

export default TextInSpan;
