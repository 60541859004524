import { formatInDecimal } from "../../../utils/numberUtils";
import GetImage from "../../views/newHomePage/common/saddleIconUtil";
import { GetPositionDecorator } from "../../views/newHomePage/postLogin/race-card-selections/selection/selectionsUtil";
import {
  GeneralContainer,
  HeadingContainer,
  PointsContainer,
  PositionContainer,
  SaddleImages,
  SecondaryContainer
} from "./headingsContainer.style";

interface IHorseStatistics {
  raceNumber: number;
  saddle: number;
  horseName: string;
  points: number;
  position: number;
}

const HorseStatistics = ({
  raceNumber,
  saddle,
  horseName,
  points,
  position
}: IHorseStatistics) => {
  const formattedPostion =
    points === 0
      ? "-"
      : position.toString().concat(GetPositionDecorator[position] || "th");
  return (
    <HeadingContainer borderTop="none">
      <GeneralContainer width={8}>
        <SecondaryContainer>{`${raceNumber}.`}</SecondaryContainer>
      </GeneralContainer>
      <GeneralContainer width={8}>
        <SaddleImages src={GetImage[Number(saddle)]} />
      </GeneralContainer>
      <GeneralContainer width={50} justifyContent="flex-start">
        {horseName}
      </GeneralContainer>
      <GeneralContainer width={20} justifyContent="center">
        <PointsContainer>{formatInDecimal(points)}</PointsContainer>
        <PositionContainer>{formattedPostion}</PositionContainer>
      </GeneralContainer>
    </HeadingContainer>
  );
};

export default HorseStatistics;
