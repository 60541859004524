import styled from "styled-components";
import theme from "../../../../../theme";

interface IOddsDisplay {
  fontWeight: number;
  color: string;
}

const OddsDisplay = styled.span<IOddsDisplay>`
  padding: 0px 5px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${theme.font.size.s};
`;

export default OddsDisplay;
