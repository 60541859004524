import { formatInDecimalAndLocal } from "../../../../../utils/numberUtils";
import theme from "../../../../../theme";
import Separator from "../seperator";
import FlexContainer from "../../../../bricks/flexContainer";
import { TotalsDiv, TitleText, ValueText } from "./betSlipTotals.style";

export interface IBetSlipTotals {
  stake: number;
  payout: number;
  bets?: number;
  hasSP: boolean;
  shouldUseLightTotals?: boolean;
  shouldShowAllTotals?: boolean;
  displayFailedBets?: boolean;
  failedReason?: string;
  isLuckyPick?: boolean;
}

const CURRENCY_SYMBOL = "R";
const SIDE_MARGIN = 6;

const BetSlipTotals = ({
  stake,
  payout,
  bets = 0,
  hasSP,
  shouldUseLightTotals = false,
  shouldShowAllTotals = false,
  displayFailedBets = false,
  failedReason,
  isLuckyPick = false
}: IBetSlipTotals) => {
  return (
    <TotalsDiv
      shouldUseLightTotals={shouldUseLightTotals}
      isLuckyPick={isLuckyPick}>
      {shouldShowAllTotals && (
        <>
          <FlexContainer
            marginleft={`${SIDE_MARGIN}%`}
            marginright={`${SIDE_MARGIN}%`}>
            <TitleText shouldUseLightTotals={shouldUseLightTotals}>
              Total Bets
            </TitleText>
            <ValueText>{bets}</ValueText>
          </FlexContainer>
          <Separator
            borderColor={theme.colors.purpleGrey}
            sideMargin={SIDE_MARGIN}
          />
        </>
      )}
      {displayFailedBets && (
        <>
          <FlexContainer
            marginleft={`${SIDE_MARGIN}%`}
            marginright={`${SIDE_MARGIN}%`}>
            <TitleText shouldUseLightTotals={shouldUseLightTotals}>
              Failure Reason
            </TitleText>
            <ValueText>{failedReason}</ValueText>
          </FlexContainer>
          <Separator
            borderColor={theme.colors.purpleGrey}
            sideMargin={SIDE_MARGIN}
          />
        </>
      )}
      <FlexContainer
        marginleft={`${SIDE_MARGIN}%`}
        marginright={`${SIDE_MARGIN}%`}>
        <TitleText shouldUseLightTotals={shouldUseLightTotals}>{`${
          !shouldUseLightTotals ? "Total " : ""
        } Stake`}</TitleText>
        <ValueText>{`${CURRENCY_SYMBOL} ${formatInDecimalAndLocal(
          stake
        )}`}</ValueText>
      </FlexContainer>
      <Separator
        borderColor={theme.colors.purpleGrey}
        sideMargin={SIDE_MARGIN}
      />
      <FlexContainer
        marginleft={`${SIDE_MARGIN}%`}
        marginright={`${SIDE_MARGIN}%`}>
        <TitleText shouldUseLightTotals={shouldUseLightTotals}>
          {`${!shouldUseLightTotals ? "Total " : ""} Potential Return`}
        </TitleText>
        <ValueText>
          {hasSP
            ? "SP"
            : `${CURRENCY_SYMBOL} ${formatInDecimalAndLocal(payout)}`}
        </ValueText>
      </FlexContainer>
    </TotalsDiv>
  );
};

export default BetSlipTotals;
