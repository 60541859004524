import { styled } from "styled-components";
import { IButton } from "../../../../bricks/button";
import theme from "../../../../../theme";

export interface ICanConfirmABet {
  canConfirmBet: boolean;
}

export const BalanceLabel = styled.label<ICanConfirmABet>`
  color: ${(props) =>
    props.canConfirmBet ? `${theme.colors.purplePrimary}` : "green"};
  font-size: ${theme.font.size.s};
  font-weight: bold;
`;

export interface IButtonsDiv {
  flexDirection?: string;
}

export const ButtonsDiv = styled.div<IButtonsDiv>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection};
  width: 85%;
  padding: 10px 7.5%;
  gap: 10px;
`;

export const ButtonStyle = styled.button<IButton>`
  font-size: ${theme.font.size.s};
  font-weight: normal;
  height: 1.7rem;
  width: ${(props) => props.width};
  margin-top: ${(props) => (props.margintop ? props.margintop : "none")};
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1;
  outline: 0px;
  cursor: pointer;
  border-width: 0px;
  &:disabled {
    background: linear-gradient(
      ${theme.colors.disabledPurpleSecondary},
      ${theme.colors.disabledPurplePrimary}
    );
    cursor: default;
  }
  font-family: Poppins;
`;

export const FundsLabel = styled.label`
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
`;
