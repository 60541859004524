import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoinALeagueForm from "./joinaLeagueForm";
import {
  joinALeague,
  resetJoinALeagueData
} from "../../../../store/features/friendsLeagueSlice";
import { RootState } from "../../../../store/store";
import LeagueModalBase from "../modals/leagueModalBase";
import JoinALeagueConfirmation from "./joinALeagueConfirmation";
import CloseIcon from "../../../composites/close-icon/closeIcon";

type TJoinALeagueModal = {
  stateUpdaterCallback: () => void;
  code?: string;
};
const JoinALeagueModal = ({
  stateUpdaterCallback,
  code
}: TJoinALeagueModal) => {
  const dispatch = useDispatch();

  const { punterId } = useSelector((state: RootState) => state.user);

  const { joinALeagueData } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const [toShowJoinLeagueForm, setToShowJoinLeagueForm] = useState(true);
  const [toDisableJoinLeague, setToDisableJoinLeague] = useState(false);
  const [toShowJoinLeagueConfirmation, setToShowJoinLeagueConfirmation] =
    useState(false);

  const leagueNameWithSuccessfulJoinRequest = useRef<string | null>(null);

  const onJoinALeagueButtonClick = (val: string) => {
    leagueNameWithSuccessfulJoinRequest.current = null;
    setToDisableJoinLeague(true);
    dispatch(joinALeague({ punterId, leagueCode: val }));
  };

  const onCancel = () => {
    stateUpdaterCallback();
  };

  useEffect(() => {
    if (joinALeagueData) {
      const { joinRequestSuccessful, errorInJoinLeague, leagueName } =
        joinALeagueData;

      if (errorInJoinLeague) {
        setToDisableJoinLeague(false);

        dispatch(resetJoinALeagueData());
      } else if (joinRequestSuccessful) {
        leagueNameWithSuccessfulJoinRequest.current = leagueName;
        setToShowJoinLeagueForm(false);
        setToShowJoinLeagueConfirmation(true);
        dispatch(resetJoinALeagueData());
      }
    }
  }, [joinALeagueData]);

  return (
    <LeagueModalBase closureCallback={stateUpdaterCallback}>
      <>
        <CloseIcon onClick={stateUpdaterCallback} />
        {toShowJoinLeagueForm && (
          <JoinALeagueForm
            callbackForJoin={onJoinALeagueButtonClick}
            toDisable={toDisableJoinLeague}
            code={code}
          />
        )}
        {toShowJoinLeagueConfirmation && (
          <JoinALeagueConfirmation
            backToHomeCallback={onCancel}
            leagueName={leagueNameWithSuccessfulJoinRequest.current!}
          />
        )}
      </>
    </LeagueModalBase>
  );
};

export default JoinALeagueModal;
