import { useState, useEffect, useContext } from "react";
import flyingMoney from "@assets/money-with-wings.svg";
import ConfettiAnimation from "../../../utils/confettiDisplay";
import Button from "../../bricks/button";
import {
  BackgroundOverlay,
  ModalBody,
  ModalContainer,
  ModalDescription,
  ModalTitle,
  TextContainer,
  TermsText,
  TitleUnderLine,
  TextColor,
  RotatingDiv,
  FlyingMoneyStyling
} from "./confirmationModal.style";
import theme from "../../../theme";
import { NotificationContext } from "../../../contexts/notificationContext";

interface ITakeABetModal {
  show: boolean;
  acceptCallback: () => void;
  cancelCallback: () => void;
  shadowStartHeight?: number;
  translationX?: number;
  translationY?: number;
}

const TakeaBetModal = ({
  show,
  acceptCallback,
  cancelCallback,
  shadowStartHeight = 0,
  translationX = -50,
  translationY = 50
}: ITakeABetModal) => {
  const [toShow, setToShow] = useState(show);
  const { notificationUpdater } = useContext(NotificationContext);

  const onYesClick = () => {
    acceptCallback();
    setToShow(false);
  };

  useEffect(() => {
    setToShow(show);
    notificationUpdater({
      status: "success",
      message: "Selections saved."
    });
  }, [show]);

  const onCancelClick = () => {
    cancelCallback();
    setToShow(false);
  };

  return toShow ? (
    <ModalContainer>
      <ModalBody>
        <BackgroundOverlay startHeight={shadowStartHeight} />
        <ConfettiAnimation />
        <TextContainer
          translationX={translationX}
          translationY={translationY}
          isTakeABetModal
          left={50}
          bottom={35}>
          <ModalTitle isTakeABetModal>
            <TextColor>
              <TitleUnderLine>
                DOUBLE YOUR <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <RotatingDiv rotationDirection="right">
                    <FlyingMoneyStyling>
                      <img src={flyingMoney} />
                    </FlyingMoneyStyling>
                  </RotatingDiv>
                  <span>MONEY!</span>
                  <RotatingDiv rotationDirection="left">
                    <FlyingMoneyStyling>
                      <img src={flyingMoney} />
                    </FlyingMoneyStyling>
                  </RotatingDiv>
                </div>
              </TitleUnderLine>{" "}
            </TextColor>
          </ModalTitle>

          <ModalDescription>
            Place a bet using the Punters Challenge bet slip at a total of R50
            or more, and DOUBLE any prize money you win on today's competition!{" "}
            <br />
            Take a bet and double your prize?
          </ModalDescription>
          <Button
            onClick={onYesClick}
            margintop="13px"
            width="7rem"
            height="1.8rem"
            gradient={{
              colors: [
                theme.colors.purpleSecondary,
                theme.colors.purplePrimary
              ],
              direction: "linear"
            }}
            text="Yes"
          />
          <Button
            onClick={onCancelClick}
            width="7rem"
            height="fit-content"
            backgroundcolor={theme.colors.primary}
            fontcolor={theme.colors.purplePrimary}
            text="No"
            border="1px solid #5C2D91"
          />
          <TermsText>T&Cs apply</TermsText>
        </TextContainer>
      </ModalBody>
    </ModalContainer>
  ) : (
    false
  );
};

export default TakeaBetModal;
