import { useDispatch } from "react-redux";
import { useContext } from "react";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import Cancel from "./cancel";
import {
  UPDATE_LEAGUE_ACTION_CODE_MAP,
  approveAJoinRequest,
  rejectAJoinRequest
} from "../../../../store/features/friendsLeagueSlice";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import theme from "../../../../theme";
import Tick from "./Tick";

interface ILeagueNotificationItem {
  message: string;
  needApproval: boolean;
  index: number;
  date: string;
  leagueCode: string;
  punterId: number;
  callbackForReject: (
    title: string,
    description: string,
    yesCallback: () => void
  ) => void;
}

const LeagueNotificationItem = ({
  message,
  needApproval,
  index,
  date,
  leagueCode,
  punterId,
  callbackForReject
}: ILeagueNotificationItem) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const dispatch = useDispatch();

  const handleAccept = () => {
    dispatch(
      approveAJoinRequest({
        punterId,
        leagueCode,
        action: UPDATE_LEAGUE_ACTION_CODE_MAP.APPROVED
      })
    );
  };
  const handleReject = () => {
    dispatch(
      rejectAJoinRequest({
        punterId,
        leagueCode,
        action: UPDATE_LEAGUE_ACTION_CODE_MAP.REJECTED
      })
    );
  };

  return (
    <FlexContainer
      bgcolor={`${index % 2 === 0 ? "white" : theme.colors.lightAliceBlue}`}
      width="100%"
      paddingtop="6px"
      paddingbottom="6px"
      alignitems="center">
      <FlexContainer
        direction="column"
        alignitems="flex-start"
        width={`${isDesktop ? 81 : 75}%`}
        marginleft={`${isDesktop ? 12 : 8}px`}>
        <Container lineheight="1.2" fontSize="0.9rem" textalign="left">
          {message}
        </Container>
        <Container
          lineheight="1"
          fontSize="0.7rem"
          textalign="left"
          fontStyle="italic">
          {date}
        </Container>
      </FlexContainer>

      {needApproval && (
        <FlexContainer
          columngap={`${isDesktop ? 15 : 10}px`}
          marginleft="5px"
          width={`${isDesktop ? 18 : 24}%`}
          justifycontent="center">
          <Tick
            width={isDesktop ? 25 : 20}
            height={isDesktop ? 25 : 20}
            callback={handleAccept}
          />

          <Cancel
            width={isDesktop ? 25 : 20}
            height={isDesktop ? 25 : 20}
            clickCallback={() => {
              callbackForReject(
                "Rejecting A Player?",
                "Are you sure you want to reject this player?",
                handleReject
              );
            }}
          />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default LeagueNotificationItem;
