import { styled } from "styled-components";
import { useSelector } from "react-redux";
import Container from "../bricks/container";
import FlexContainer from "../bricks/flexContainer";
import FacebookIcon from "../../assets/facebook.svg";
import InstagramIcon from "../../assets/instagram.svg";
import TwitterIcon from "../../assets/twitter.svg";
import YouTubeIcon from "../../assets/youtube.svg";
import openLink from "../../utils/pageUtils";
import type { RootState } from "../../store/store";

const SocialHeaderLabel = styled.label`
  width: max-content;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;

  @media screen and (min-width: 769px) and (max-width: 1437px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 0.9rem;
  }
`;

const Social = () => {
  const config = useSelector((state: RootState) => state.config.data);

  return (
    <Container bgcolor="none" width="100%" style={{ textAlign: "left" }}>
      <FlexContainer
        bgcolor="none"
        direction="column"
        padding="0px"
        alignitems="flex-start"
        rowgap="0px"
        width="90%"
        marginleft="0px">
        <SocialHeaderLabel> We're Social!</SocialHeaderLabel>
        <FlexContainer
          bgcolor="none"
          direction="row"
          columngap="0px"
          width="fit-content">
          <Container
            cursor="pointer"
            bgcolor="none"
            onClick={() => {
              openLink(`${config.facebookUrl}`);
            }}>
            <img style={{ width: "80%", height: "auto" }} src={FacebookIcon} />
          </Container>
          <Container
            cursor="pointer"
            bgcolor="none"
            onClick={() => {
              openLink(`${config.instagramUrl}`);
            }}
            marginleft="10px">
            <img style={{ width: "80%", height: "auto" }} src={InstagramIcon} />
          </Container>
          <Container
            cursor="pointer"
            bgcolor="none"
            onClick={() => {
              openLink(`${config.twitterUrl}`);
            }}
            marginleft="10px">
            <img style={{ width: "80%", height: "auto" }} src={TwitterIcon} />
          </Container>
          <Container
            cursor="pointer"
            bgcolor="none"
            onClick={() => {
              openLink(`${config.youtubeUrl}`);
            }}
            marginleft="10px">
            <img style={{ width: "80%", height: "auto" }} src={YouTubeIcon} />
          </Container>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};

export default Social;
