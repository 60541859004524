import styled from "styled-components";
import theme from "../../../../theme";

export const MainSearchContainer = styled.div`
  display: flex;
  width: 95%;
  height: 42px;
  align-items: center;
  margin-bottom: 5px;
  box-shadow: 1px 2px 6px ${theme.colors.cardShadow};
  justify-content: center;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 30%;
    min-width: 340px;
    justify-content: flex-end;
  }
`;

export const SearchInputDiv = styled.input`
  fontsize: 1rem;
  border: none;
  background-color: transparent;
  color: black;
  width: 90%;
  outline: none;
`;

export const SearchImageDiv = styled.img`
  width: 70px;
  height: 100%;
  background: linear-gradient(
    ${theme.colors.purpleSecondary},
    ${theme.colors.purplePrimary}
  );
  cursor: pointer;
  border-radius: 0 7px 7px 0;
`;
