import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import {
  IMyLeagueItem,
  displayManageForLeague
} from "../../../../../store/features/friendsLeagueSlice";
import theme from "../../../../../theme";
import Button from "../../../../bricks/button";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import { RootState } from "../../../../../store/store";
import Spinner from "../../../../bricks/spinner";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import LeagueScrollerTabsMob from "../leagueScrollerTabsMob";
import NotificationsRenderedList from "./notificationsRenderedList";

interface INotificationsView {
  currentLeague: IMyLeagueItem;
  setCurrentLeague: (league: IMyLeagueItem) => void;
  leagues: IMyLeagueItem[];
  confirmationCallback: (
    title: string,
    description: string,
    yesCallback: () => void
  ) => void;
}

const NotificationsView = ({
  leagues,
  currentLeague,
  setCurrentLeague,
  confirmationCallback
}: INotificationsView) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const dispatch = useDispatch();
  const { currentNotifications, errorMessageOnRejection } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const onLeagueTabClick = (league: IMyLeagueItem) => {
    if (currentLeague?.leagueName !== league.leagueName) {
      setCurrentLeague(league);

      dispatch(displayManageForLeague(league.leagueName));
    }
  };

  const canShowSpinner = () => {
    return !currentNotifications && !errorMessageOnRejection;
  };

  return isDesktop ? (
    <FlexContainer
      direction="column"
      height="100%"
      width="60%"
      border={`1px solid ${theme.colors.lightGray}`}
      borderradius="5px"
      bgcolor={theme.colors.lightAliceBlue}
      alignitems="flex-start">
      <Container
        textalign="left"
        bgcolor={theme.colors.lightAliceBlue}
        marginleft="1vw"
        paddingbottom="10px"
        paddingtop="10px"
        fontSize="1.2rem"
        color={theme.colors.purplePrimary}
        bordertopleftradius="5px"
        bordertoprightradius="5px">
        Manage Leagues
      </Container>

      <Container
        width="95%"
        overflow="auto"
        height="max-content"
        marginleft="1vw"
        paddingbottom="6px">
        <FlexContainer width="fit-content" minwidth="100%" fontSize="0.7rem">
          {leagues.map((league: IMyLeagueItem) => {
            return (
              <Button
                key={`LeagueTab_${league.leagueName}`}
                text={league.leagueName}
                border={
                  league.leagueName === currentLeague?.leagueName
                    ? `2px solid ${theme.colors.purplePrimary}`
                    : "none"
                }
                width="11vw"
                minWidth="max-content"
                background="transparent"
                fontcolor={theme.colors.purplePrimary}
                fontSize="max(0.6rem, 0.8vw)"
                fontWeight={
                  league.leagueName === currentLeague?.leagueName
                    ? "bold"
                    : "normal"
                }
                onClick={() => {
                  onLeagueTabClick(league);
                }}
              />
            );
          })}
        </FlexContainer>
      </Container>

      <FlexContainer direction="column" width="100%">
        <FlexContainer
          width="100%"
          paddingtop="6px"
          paddingbottom="6px"
          alignitems="center">
          <FlexContainer
            direction="column"
            alignitems="flex-start"
            width="81%"
            marginleft="12px">
            <Container fontWeight="bold"> Activity</Container>
          </FlexContainer>
          <Container fontWeight="bold"> Manage</Container>
        </FlexContainer>

        <Container
          maxheight="400px"
          minheight="200px"
          overflow="auto"
          position="relative">
          <NotificationsRenderedList
            currentLeague={currentLeague}
            confirmationCallback={confirmationCallback}
          />

          {currentNotifications && currentNotifications.length === 0 && (
            <Container fontStyle="italic" width="100%" margintop="15px">
              {" "}
              {`There are no activities for the league ${currentLeague.leagueName}`}{" "}
            </Container>
          )}
          {canShowSpinner() && <Spinner />}

          {!currentNotifications && errorMessageOnRejection && (
            <Container
              fontStyle="italic"
              width="90%"
              color="red"
              fontSize="0.8rem"
              margintop="15px"
              padding="15px">
              {" "}
              {errorMessageOnRejection}{" "}
            </Container>
          )}
        </Container>
      </FlexContainer>
    </FlexContainer>
  ) : (
    <FlexContainer
      direction="column"
      width="100%"
      bgcolor={theme.colors.lightAliceBlue}
      borderradius="5px"
      border={theme.colors.purplePrimary}>
      <LeagueScrollerTabsMob
        leagues={leagues}
        currentLeague={currentLeague}
        onLeagueTabClick={onLeagueTabClick}
      />

      <Container
        maxheight="400px"
        minheight="200px"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px"
        overflow="auto"
        width="100%"
        margintop="10px"
        position="relative">
        {canShowSpinner() && <Spinner />}

        {currentNotifications && currentNotifications.length === 0 && (
          <Container fontStyle="italic" width="100%" margintop="35px">
            {" "}
            {`There are no activities for the league ${currentLeague.leagueName}`}{" "}
          </Container>
        )}

        <NotificationsRenderedList
          currentLeague={currentLeague}
          confirmationCallback={confirmationCallback}
        />
      </Container>
    </FlexContainer>
  );
};

export default NotificationsView;
