import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "../../../../store/store";
import RunnerItemLeagues from "../runnerItemLeagues";
import FlexContainer from "../../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import Container from "../../../bricks/container";
import theme from "../../../../theme";
import Spinner from "../../../bricks/spinner";
import usePunterItemsCommon from "./usePunterItemsCommon";

interface ILeaguePuntersSelection {
  toOpen: boolean;
}

const LeaguePuntersSelection = ({ toOpen }: ILeaguePuntersSelection) => {
  const { columsWithIndices, data, setData, adjustItemsLengthsInColumns } =
    usePunterItemsCommon();

  const { isDesktop } = useContext(MediaQueriesContext).data;
  const { currentLeaderboardPunterDetail } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  useEffect(() => {
    if (toOpen && !!currentLeaderboardPunterDetail) {
      adjustItemsLengthsInColumns(currentLeaderboardPunterDetail.length);
      setData(currentLeaderboardPunterDetail);
    }
  }, [currentLeaderboardPunterDetail]);

  return (
    <>
      {toOpen && !data && (
        <Container
          width="100%"
          position="relative"
          height="50px"
          paddingtop="10px"
          paddingbottom="10px"
          bgcolor={theme.colors.lightAliceBlue}>
          <Spinner width="50px" height="50px" />
        </Container>
      )}

      {toOpen && data && (
        <FlexContainer
          direction="column"
          width="100%"
          bgcolor={theme.colors.lightAliceBlue}
          height="auto"
          alignitems="center"
          border={`1px solid ${theme.colors.lightGray}`}
          borderbottomleftradius="5px"
          borderbottomrightradius="5px">
          {isDesktop ? (
            <FlexContainer
              width="98%"
              justifycontent="center"
              margintop="7px"
              columngap="30px"
              marginbottom="5px">
              {columsWithIndices.map((indices, columnIndex) => (
                <FlexContainer
                  key={`PunterItem_col${indices[columnIndex]}`}
                  direction="column"
                  width={`${100 / columsWithIndices.length - 3}%`}
                  height="fit-content"
                  rowgap="10px">
                  {!!data &&
                    data.map((item, index) => {
                      if (index >= indices[0] && index <= indices[1])
                        return (
                          <RunnerItemLeagues
                            key={`RunnerItemCol_${item.raceNumber}_${item.saddle}_${item.horseName}_${item.points}`}
                            index={item.raceNumber}
                            horseNum={item.saddle}
                            horseName={item.horseName}
                            points={item.points}
                            position={item.racePosition}
                          />
                        );

                      return null;
                    })}
                </FlexContainer>
              ))}
            </FlexContainer>
          ) : (
            <FlexContainer
              width="100%"
              maxheight="100px"
              margintop="10px"
              marginbottom="10px"
              justifycontent="center"
              overflow="auto">
              <FlexContainer direction="column" rowgap="5px" width="95%">
                {!!data &&
                  data.map((item) => {
                    return (
                      <RunnerItemLeagues
                        key={`RunnerItemCol_${item.raceNumber}_${item.saddle}_${item.horseName}_${item.points}`}
                        index={item.raceNumber}
                        horseNum={item.saddle}
                        horseName={item.horseName}
                        points={item.points}
                        position={item.racePosition}
                      />
                    );
                  })}
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    </>
  );
};

export default LeaguePuntersSelection;
