import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import LeagueModalHeader from "./leagueModalHeader";

interface ILeagueFormBase {
  children: JSX.Element | JSX.Element[];
  text: string;
}

const LeagueFormBase = ({ children, text }: ILeagueFormBase) => {
  return (
    <FlexContainer
      direction="column"
      rowgap="10px"
      paddingtop="10px"
      paddingbottom="10px">
      <LeagueModalHeader text={text} />
      <Container width="100%" height="1px" bgcolor={theme.colors.dividerLine} />
      {children}
    </FlexContainer>
  );
};

export default LeagueFormBase;
