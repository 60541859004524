import Container from "../../../../bricks/container";
import BetSlipTotals from "../betSlipTotals/betSlipTotals";
import { ConfirmationTitle } from "./betSlipConfirmation.style";

interface IConfirmationItem {
  betTitle: string;
  failedReason: string;
  totalStake: number;
  payout: number;
  odds: number;
}

const ConfirmationItem = ({
  betTitle,
  failedReason,
  totalStake,
  payout,
  odds
}: IConfirmationItem) => {
  return (
    <Container width="100%">
      <ConfirmationTitle>{betTitle.toUpperCase()}</ConfirmationTitle>
      <BetSlipTotals
        stake={totalStake}
        payout={payout + totalStake}
        hasSP={odds === 0}
        shouldUseLightTotals
        displayFailedBets
        failedReason={failedReason}
      />
    </Container>
  );
};

export default ConfirmationItem;
