import styled from "styled-components";
import {
  TargetDeviceHeight,
  mediaSizeBelow
} from "../../../../utils/mediaUtils";
import theme from "../../../../theme";

export const CoveringDiv = styled.div`
  font-size: min(5.5vw, 1.2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  color: ${theme.colors.titleAndTextPurple};
  ${mediaSizeBelow(TargetDeviceHeight.iPhoneSE, `font-size: 0.9rem;`)};
`;

export const DesktopDiv = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
