import {
  HowToPlayButtonLogo,
  HowToPlayButtonText,
  HowToPlayLinkButton,
  LeftArrow
} from "./howToPlay.Styled";

interface IHowToPlayButton {
  logo: string;
  text: string;
  onClick: () => void;
}

const HowToPlay = ({ logo, text, onClick }: IHowToPlayButton) => {
  return (
    <HowToPlayLinkButton onClick={onClick}>
      <HowToPlayButtonLogo src={logo} />
      <HowToPlayButtonText>{text}</HowToPlayButtonText>
      <LeftArrow>{">"}</LeftArrow>
    </HowToPlayLinkButton>
  );
};

export default HowToPlay;
