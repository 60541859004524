import styled from "styled-components";

export const ArrowDown = styled.img`
  background: none;
  height: 1.8rem;
`;

export const ArrowUp = styled.img`
  background: none;
  height: 1.6rem;
  margin-top: 1px;
`;

export const ArrowDiv = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;
