import { useContext } from "react";
import { FORMATS, getFormatted } from "../../../../../../utils/dateUtils";
import { getRaceCourseCountryFlag } from "../../../../../../utils/raceCourseUtils";
import {
  CountryFlagIcon,
  RaceCardBanner,
  RaceCourseName,
  RaceDate,
  ResultsSubText,
  ResultsText
} from "./resultsRaceCardBanner.style";
import { MediaQueriesContext } from "../../../../../../contexts/mediaQueriesContext";

interface IResultsBanner {
  raceCourseName: string;
  raceDate: string;
  shouldOpen: boolean;
}

const ResultsBanner = ({
  raceCourseName,
  raceDate,
  shouldOpen
}: IResultsBanner) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const raceCourseCountryFlag = getRaceCourseCountryFlag(raceCourseName);
  const formattedDate = getFormatted(
    raceDate,
    isDesktop ? FORMATS["D,DT FM FY"] : FORMATS["SD, DT FM"]
  );

  return (
    <RaceCardBanner shouldOpen={shouldOpen} isDesktop={isDesktop}>
      <CountryFlagIcon src={raceCourseCountryFlag} />
      <ResultsText isDesktop={isDesktop}>
        <RaceCourseName isDesktop={isDesktop}>{raceCourseName}</RaceCourseName>
        <ResultsSubText isDesktop={isDesktop}>
          <RaceDate>{formattedDate}</RaceDate>
        </ResultsSubText>
      </ResultsText>
    </RaceCardBanner>
  );
};

export default ResultsBanner;
