import { memo, useContext, useRef, useState } from "react";
import styled from "styled-components";
import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import theme from "../../../../../theme";
import { STATS_DROP_DOWN_ITEMS_ITEMS } from "../../constants/friendsLeaguesConstants";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";
import useOutsideClick from "../../../../../hooks/useOutsideClick";

const Arrow = styled.div<{ angle: number; color?: string }>`
  border-color: ${(props) => props.color ?? props.theme.colors.purplePrimary};
  border-style: solid;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: ${(props) => `rotate(${props.angle}deg)`};
  -webkit-transform: ${(props) => `rotate(${props.angle}deg)`};
  margin-bottom: ${(props) => (props.angle === 45 ? "0px" : "-15%")};
  margin-top: ${(props) => (props.angle === 45 ? "-155%" : "0px")};
`;

interface ILeagueStatsDropDown {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  dropDownCallback: (selection: string) => void;
}

const LeagueStatsDropDown = ({
  top,
  left,
  bottom,
  right,
  dropDownCallback
}: ILeagueStatsDropDown) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const [selected, setSelected] = useState<string>(
    STATS_DROP_DOWN_ITEMS_ITEMS[0]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onOutsideClick = () => {
    setIsOpen(false);
  };

  useOutsideClick(onOutsideClick, ref);

  const onSelection = (newItem: string) => {
    setIsOpen(false);
    if (newItem !== selected) {
      setSelected(newItem);
      dropDownCallback(newItem);
    }
  };

  return (
    <FlexContainer
      ref={ref}
      direction="column"
      width="inherit"
      maxwidth={isDesktop ? "158px" : "115px"}
      maxheight="27px"
      position="absolute"
      right={right ?? "auto"}
      left={left ?? "auto"}
      top={top ?? "auto"}
      bottom={bottom ?? "auto"}>
      <FlexContainer
        width={isDesktop ? "92%" : "96%"}
        height="100%"
        bgcolor={theme.colors.primary}
        border={`1px solid ${theme.colors.purplePrimary} `}
        borderradius="7px"
        maxwidth="inherit"
        maxheight="27px"
        justifycontent="space-around"
        alignitems="center"
        paddingleft={isDesktop ? "12px" : "4px"}>
        <Container
          width="75%"
          height="max-content"
          textalign="left"
          lineheight="1"
          color={theme.colors.purplePrimary}
          fontSize="0.8rem">
          {selected}
        </Container>
        <Container
          width="15%"
          height="100%"
          bgcolor="transparent"
          color={theme.colors.orangePrimary}
          paddingbottom="4px"
          cursor="pointer">
          {isOpen && (
            <Arrow
              angle={-135}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          )}
          {!isOpen && (
            <Arrow
              angle={45}
              onClick={() => {
                setIsOpen(true);
              }}
            />
          )}
        </Container>
      </FlexContainer>

      {isOpen &&
        STATS_DROP_DOWN_ITEMS_ITEMS.map((item, index) => {
          return (
            <Container
              key={`StatsDropDownItem_${item}`}
              bgcolor="white"
              maxheight="inherit"
              color={theme.colors.purplePrimary}
              fontSize="0.8rem"
              borderradius="5px"
              border={`1px solid ${theme.colors.purplePrimary}`}
              bordertop={index === 0 ? "none" : ""}
              borderbottom={
                index !== STATS_DROP_DOWN_ITEMS_ITEMS.length - 1 ? "none" : ""
              }
              onClick={() => onSelection(item)}
              cursor="pointer">
              {item}{" "}
            </Container>
          );
        })}
    </FlexContainer>
  );
};

export default memo(LeagueStatsDropDown);
