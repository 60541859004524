import { useRef, BaseSyntheticEvent, KeyboardEvent } from "react";
import styled from "styled-components";

const ALLOWED_NUMBER_KEYS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Backspace",
  "ArrowUp",
  "ArrowDown"
];

interface IInput {
  type: string;
  defaultValue: number;
  callback: (value: number) => void;
  background?: string;
  width?: string;
  color?: string;
  fontWeight?: string;
  fontsize?: string;
  border?: string;
  outline?: string;
  lineheight?: number;
  verticalalign?: string;
  paddingtop?: string;
  textalign?: string;
}

const StyledInput = styled.input<IInput>`
  color: ${(props) => props.color ?? props.theme.colors.primary};
  background: ${(props) => props.background ?? "none"};
  color: ${(props) => props.color ?? "black"};
  font-size: ${(props) => props.fontsize ?? "1em"};
  outline: ${(props) => props.outline ?? "none"};
  line-height: ${(props) => props.lineheight ?? "normal"};
  width: ${(props) => props.width ?? "100%"};
  border: ${(props) => props.border ?? "none"};
  vertical-align: ${(props) => props.verticalalign ?? "inherit"};
  padding-top: ${(props) => props.paddingtop ?? "0px"};
  text-align: ${(props) => props.textalign ?? "left"};
`;

const Input = (props: IInput) => {
  const { callback, defaultValue } = props;

  const isInvalidChar = useRef<boolean>(false);
  const currentValue = useRef<number>(0);

  const onKeyPress = (e: BaseSyntheticEvent) => {
    isInvalidChar.current = false;

    const kbEvent = e.nativeEvent as KeyboardEvent;
    const target = e.target as HTMLInputElement;

    if (
      !ALLOWED_NUMBER_KEYS.includes(kbEvent.key) ||
      (target.value === "0" && kbEvent.key === "0")
    ) {
      isInvalidChar.current = true;
      currentValue.current = target.valueAsNumber;
      e.preventDefault();
    }
  };

  const onInput = (e: BaseSyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    if (isInvalidChar.current) {
      target.value = String(currentValue.current);
    } else if (target.value === "") {
      target.value = "0";
    }
    const inputValue = Number(target.value);
    callback(inputValue);
  };

  return (
    <StyledInput
      onChange={onInput}
      value={defaultValue}
      onKeyDown={onKeyPress}
      {...props}
    />
  );
};

export default Input;
