import {
  IPunterSetting,
  SETTING_IDS
} from "../../../../store/features/settingsSlice";
import { formatInDecimalAndLocal } from "../../../../utils/numberUtils";

const getSetting = (settings: IPunterSetting[], id: number) => {
  return formatInDecimalAndLocal(
    Number(
      settings.find((setting) => setting.settingId === id)?.settingValue ?? 0
    ),
    0
  );
};

export const getTotalStakesPaidOut = (settings: IPunterSetting[]) => {
  return getSetting(settings, SETTING_IDS.totalStakesPaidOut);
};

export const getJackpotWinners = (settings: IPunterSetting[]) => {
  return getSetting(settings, SETTING_IDS.jackpotWinners);
};

export const getJackpotPrizes = (settings: IPunterSetting[]) => {
  return getSetting(settings, SETTING_IDS.jackpotPrizes);
};

export const getDailyPlayers = (settings: IPunterSetting[]) => {
  return getSetting(settings, SETTING_IDS.playersDaily);
};
