import { ItemDiv, RaceNumberDiv, TextDiv } from "./selectionHeaderItem.style";

export interface ISelectionHeaderData {
  raceNumber: number;
  raceTime: string;
  puntersRemaining: string;
}

const SelectionHeaderItem = ({
  raceNumber,
  raceTime,
  puntersRemaining
}: ISelectionHeaderData) => {
  return (
    <ItemDiv>
      <RaceNumberDiv>
        {"Race "}
        {raceNumber}
      </RaceNumberDiv>
      <TextDiv>{raceTime}</TextDiv>
      <TextDiv>{puntersRemaining}</TextDiv>
    </ItemDiv>
  );
};

export default SelectionHeaderItem;
