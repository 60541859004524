import styled from "styled-components";
import theme from "../../../theme";

export const TitleLabel = styled.label`
  width: 100%;
  color: #5c2d91;
  font-weight: bold;
  padding-bottom: 7px;

  @media screen and (min-width: 769px) and (max-width: 999px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1201px) and (max-width: 1580px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 1581px) and (max-width: 2000px) {
    font-size: 1.2rem;
  }
`;

export const ItemRowContainer = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  align-items: center;
  padding-right: 0px;
  color: black;
  margin-left: 0px;
  padding-top: 3px;
  padding-bottom: 4px;
  column-gap: 1px;
  justify-content: space-between;
  font-size: 0.6rem;
  @media screen and (min-width: 769px) and (max-width: 999px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1201px) and (max-width: 1580px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 1581px) {
    font-size: 1rem;
  }
`;

export const OnNextChallengeClick = styled.div`
  text-align: center;
  width: 20px;
  height: 20px;
  font-size: 13px;
  font-weight: bold;
  background: ${theme.colors.purpleSecondary};
  color: white;
  border-radius: 20px;
  cursor: pointer;
`;
