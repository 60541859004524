import { useSelector } from "react-redux";
import RefreshLogo from "@assets/refresh-icon-purple.svg";
import Tooltip from "@mui/material/Tooltip";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import theme from "../../../../theme";
import LeagueRowItemWeb from "../leaguesCommon/leagueRowItemWeb";
import LeaguesTableHeader from "../leaguesCommon/leaguesTableHeader";
import { IMyLeagueItem } from "../../../../store/features/friendsLeagueSlice";
import { RootState } from "../../../../store/store";
import NoLeagueOrErrorMessage from "./noLeagueOrErrorMessage";
import { RefreshDiv } from "../../header/header.style";
import UseRefreshLeagues from "../hooks/useRefreshLeagues";

interface IMyLeaguesWeb {
  leagues: IMyLeagueItem[] | null;
  handleClose: () => boolean;
}

const MyLeaguesWeb = ({ leagues, handleClose }: IMyLeaguesWeb) => {
  const { errorMessageOnRejection } = useSelector(
    (state: RootState) => state.friendsLeague
  );

  const { refreshLeaguesList } = UseRefreshLeagues();

  const islastRow = (rowNum: number) => {
    return rowNum === leagues!.length - 1;
  };

  return (
    <FlexContainer
      direction="column"
      width="98%"
      border={`1px solid${theme.colors.lightGray}`}
      borderradius="5px">
      <Container
        display="flex"
        alignitems="center"
        gap={10}
        textalign="left"
        bgcolor={theme.colors.lightAliceBlue}
        paddingleft="20px"
        paddingbottom="15px"
        paddingtop="15px"
        fontSize="1.2rem"
        color={theme.colors.purplePrimary}
        bordertopleftradius="5px"
        bordertoprightradius="5px"
        justifyContent="flex-start">
        My Leagues
        <Tooltip title="Click Here to Refresh Notifications">
          <RefreshDiv src={RefreshLogo} onClick={refreshLeaguesList} />
        </Tooltip>
      </Container>

      <LeaguesTableHeader />

      <Container
        maxheight="210px"
        overflow="auto"
        scrollbarWidth="thin"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px">
        {leagues?.length! > 0 &&
          leagues?.map((item, index) => {
            return (
              <LeagueRowItemWeb
                key={`myleaguesWebRow${item.leagueName}`}
                index={index}
                isLastRow={islastRow(index)}
                leagueName={item.leagueName}
                rank={item.rank}
                numPlayers={item.totalPlayers}
                leagueCode={item.leagueCode}
                isActive={item.leagueStatus}
                numNotifictions={item.unreadNotifications}
                handleClose={handleClose}
                toCaterForSidebar={leagues?.length > 1}
              />
            );
          })}
      </Container>

      {!leagues?.length && !errorMessageOnRejection && (
        <NoLeagueOrErrorMessage text="You have not created a league yet." />
      )}

      {!leagues?.length && errorMessageOnRejection && (
        <NoLeagueOrErrorMessage text={errorMessageOnRejection} color="red" />
      )}
    </FlexContainer>
  );
};

export default MyLeaguesWeb;
