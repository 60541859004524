import { useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";
import {
  IMeeting,
  IMeetingRace,
  IMeetingSelections,
  IProgressiveWinners,
  IRaceRunner,
  savePunterSelection
} from "../../../../../store/features/meetingsSlice";
import HorsePosition from "./selection/horsePosition";
import RaceCard from "./selection/raceCard";
import SelectionHeader from "./selection/selection-header/selectionHeader";
import { ISelectionHeaderData } from "./selection/selection-header/selectionHeaderItem";
import { FORMATS, getFormatted, isPast } from "../../../../../utils/dateUtils";
import {
  ColorLegend,
  HorsePositionsDiv,
  LegendsDiv,
  RaceCardBottomDiv,
  RaceCardSelectionsDiv,
  SelectionContent,
  SelectionsButton,
  TsAndCsDiv
} from "./raceCardSelections.style";
import { SeperatorDiv } from "../../../betslipNew/components/betTypeDisplay/betTypeDisplay.style";
import theme from "../../../../../theme";
import { RACE_SELECTION_CACHE_NEW } from "../../../../../constants";
import FlexContainer from "../../../../bricks/flexContainer";
import {
  CachedSelectedRaces,
  MapToSaveSelections,
  UpdateWithFavHorse
} from "./selection/selectionsUtil";
import { MediaQueriesContext } from "../../../../../contexts/mediaQueriesContext";

interface IRaceCardSelections {
  currentMeetingId: number;
  meetingData: IMeeting;
  selections: IMeetingSelections[];
  progressives: IProgressiveWinners;
  punterId: number;
  isPending: boolean;
  onSelectionMade: () => void;
  onSubmitClicked: () => void;
  onTakeABetClicked: () => void;
  hasBeenQuickPicked: boolean;
  hasSelectedAllFavorites: boolean;
}

const hasUniqueEntries = (
  meetingSelections: IMeetingSelections[],
  cookieSelections: IRaceRunner[]
) => {
  const selectedHorseList = meetingSelections?.map((sub) => sub.horseName);

  return (
    cookieSelections.filter(
      (selection) => !selectedHorseList.includes(selection?.horseName)
    ).length > 0
  );
};

const RaceCardSelections = ({
  currentMeetingId,
  meetingData,
  selections,
  progressives,
  punterId,
  isPending,
  onSelectionMade,
  onSubmitClicked,
  onTakeABetClicked,
  hasBeenQuickPicked,
  hasSelectedAllFavorites
}: IRaceCardSelections) => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const submitRef = useRef<HTMLDivElement>(null);

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [hasSelections, setHasSelections] = useState<boolean>(false);

  const submissionsListRef = useRef<IRaceRunner[]>([]);

  submissionsListRef.current = CachedSelectedRaces(currentMeetingId);

  const hasMeetingData = meetingData ?? false;

  const racePositionLength = meetingData?.races?.reduce((raceA, raceB) => {
    return raceA.runners.length > raceB.runners.length ? raceA : raceB;
  }).runners;

  const positions = racePositionLength?.map((_, index) => (
    <HorsePosition key={`Saddle_${index.toString()}`} horseNumber={index + 1} />
  ));

  const getSelectionsForRace = (
    race: IMeetingRace
  ): {
    horse: IRaceRunner | null;
    isForcedSelection: boolean;
  } => {
    if (submissionsListRef.current.length === 0) {
      return { horse: null, isForcedSelection: false };
    }

    const selectedForRace = submissionsListRef.current.find(
      (selection) => selection.raceNumber === race.raceNumber
    );

    if (selectedForRace) {
      const validHorse = selectedForRace.runnerStatus === 1;
      if (validHorse || (!validHorse && !isPending)) {
        return { horse: selectedForRace, isForcedSelection: false };
      }

      if (isPending) {
        return {
          horse: race.runners.find((horse) => horse.favourite) ?? null,
          isForcedSelection: true
        };
      }
    }
    return { horse: null, isForcedSelection: false };
  };

  const updateCanSubmit = () => {
    const hasUpdate =
      selections.length === 0 ||
      hasUniqueEntries(selections, submissionsListRef?.current);

    setCanSubmit(
      meetingData?.races?.length === submissionsListRef.current.length &&
        hasUpdate
    );

    const openMeeting =
      meetingData.races.filter((race) => {
        return !isPast(race.raceTime.toString(), 0);
      }).length > 0;

    setHasSelections(
      submissionsListRef.current.length === meetingData?.races?.length &&
        openMeeting
    );
  };

  useEffect(() => {
    if (hasBeenQuickPicked || hasSelectedAllFavorites) {
      submitRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }, [hasBeenQuickPicked, hasSelectedAllFavorites]);

  useEffect(() => {
    updateCanSubmit();
  });

  const setSelectionCookies = (
    selectedRunner: IRaceRunner,
    isSelected: boolean
  ) => {
    onSelectionMade();

    const raceIndex = submissionsListRef.current.findIndex(
      (runner) => runner.raceNumber === selectedRunner.raceNumber
    );

    if (raceIndex > -1) {
      submissionsListRef.current.splice(raceIndex, 1);
    }

    if (isSelected) {
      submissionsListRef.current.push(selectedRunner);
    }

    localStorage.setItem(
      currentMeetingId + RACE_SELECTION_CACHE_NEW,
      JSON.stringify(submissionsListRef.current)
    );

    updateCanSubmit();
  };

  const saveSelections = () => {
    const saveSelections = MapToSaveSelections(
      currentMeetingId,
      punterId,
      submissionsListRef.current
    );

    dispatch(savePunterSelection(saveSelections));
    onSubmitClicked();
  };

  const races = meetingData?.races?.map((race) => {
    const updatedRace: IMeetingRace = {
      ...race,
      runners: UpdateWithFavHorse(race.runners),
      resulted:
        race.runners.filter((runner) => Number(runner.finishingPosition) > 0)
          .length > 0
    };

    const selection = getSelectionsForRace(updatedRace);

    return (
      <RaceCard
        key={`${currentMeetingId}_Race_${updatedRace.raceNumber}`}
        race={updatedRace}
        onSelectionUpdated={setSelectionCookies}
        selectedRunner={selection.horse}
        isForcedSelection={selection.isForcedSelection}
        isPending={isPending}
      />
    );
  });

  const headerData = meetingData?.races?.map((race: IMeetingRace) => {
    const raceTime = getFormatted(race.raceTime.toString(), FORMATS["HH:MM"]);

    const winners = progressives.progressiveWinner.find(
      (progressive) => progressive.raceNumber === race.raceNumber
    );

    const puntersRemaining =
      winners?.winners !== undefined ? winners?.winners.toString() : " - ";

    const headerDataItem: ISelectionHeaderData = {
      raceNumber: Number(race.raceNumber),
      raceTime,
      puntersRemaining
    };

    return headerDataItem;
  });

  return (
    <>
      <RaceCardSelectionsDiv>
        {hasMeetingData ? (
          <>
            <SelectionHeader headers={headerData} />
            <SelectionContent isDesktop={isDesktop}>
              <HorsePositionsDiv>{positions}</HorsePositionsDiv>
              {races}
            </SelectionContent>
          </>
        ) : (
          <span>No available meetings found</span>
        )}
      </RaceCardSelectionsDiv>
      <RaceCardBottomDiv>
        <SeperatorDiv
          borderColor={theme.colors.lightGrey3}
          width="calc(100% - 16px)"
          margin="8px"
        />
        <TsAndCsDiv>
          <LegendsDiv>
            <ColorLegend color={theme.colors.horseCardTotalPicksGreen} />
            <span>Number of selections</span>
            <ColorLegend color={theme.colors.horseCardOddsBlue} />
            <span>Current Price</span>
            <ColorLegend color={theme.colors.forcedSelection} />
            <span>Assigned Horse</span>
          </LegendsDiv>
          <span>T's & C's Apply</span>
        </TsAndCsDiv>
        <SeperatorDiv
          borderColor={theme.colors.lightGrey3}
          width="calc(100% - 16px)"
          margin="8px"
        />
        <FlexContainer
          width="calc(100% - 16px)"
          justifycontent="center"
          padding="0px 8px"
          columngap="8px">
          <div ref={submitRef} />
          <SelectionsButton
            isDisabled={!canSubmit}
            onClick={saveSelections}
            disabled={!canSubmit}>
            Submit Selections
          </SelectionsButton>
          <SelectionsButton
            isDisabled={!hasSelections}
            onClick={onTakeABetClicked}
            disabled={!hasSelections}>
            Take A Bet
          </SelectionsButton>
        </FlexContainer>
      </RaceCardBottomDiv>
    </>
  );
};

export default RaceCardSelections;
