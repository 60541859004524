import { useState } from "react";
import FlexContainer from "../../../bricks/flexContainer";
import { getFormatted, FORMATS } from "../../../../utils/dateUtils";
import NextPreviousButton from "../../next-previous-button/nextPreviousButton";
import {
  StyledContainer,
  PagesContainer,
  MeetingInfoContainer,
  MeetingInfo,
  MainFooterDiv
} from "./leaderboardFooter.style";
import theme from "../../../../theme";

interface ILeaderboardFooter {
  totalPages: number;
  meetingName: string;
  meetingDate: string;
  puntersPerPage: number;
  borderRadius?: string;
  onPageClick: (page: number) => void;
}

const LeaderboardFooter = ({
  totalPages,
  meetingName,
  meetingDate,
  puntersPerPage,
  borderRadius = "5px",
  onPageClick
}: ILeaderboardFooter) => {
  const [currentIndex, setCurrentIndex] = useState<number>(1);

  const handleNext = () => {
    if (currentIndex < totalPages) {
      setCurrentIndex(currentIndex + 1);
      onPageClick(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 1) {
      setCurrentIndex(currentIndex - 1);
      onPageClick(currentIndex - 1);
    }
  };

  const getButtonText = (page: number) => {
    if (page === 0 || page > totalPages) {
      return "";
    }

    const startIndex = (page - 1) * puntersPerPage + 1;
    const endIndex = page * puntersPerPage;

    return `${startIndex} - ${endIndex}`;
  };

  return (
    <MainFooterDiv id="leaderboard_footer_main">
      <FlexContainer
        bgcolor={theme.colors.cardSelected}
        width="100%"
        height="90px"
        paddingbottom="10px"
        justifycontent="space-between"
        borderbottomrightradius={borderRadius}
        borderbottomleftradius={borderRadius}>
        <NextPreviousButton
          enabled={currentIndex > 1}
          callBack={handlePrevious}
          isActive={currentIndex > 1}
          isMeetingRaceCard
          navigationText={getButtonText(currentIndex - 1)}
        />

        <StyledContainer>
          <PagesContainer>
            Pages <br />
            <strong>
              {currentIndex} / {totalPages}
            </strong>
          </PagesContainer>
          <MeetingInfoContainer>
            <MeetingInfo>
              <b>{meetingName}</b>
              <br />
              {getFormatted(meetingDate, FORMATS["D, DT FM"])}
            </MeetingInfo>
          </MeetingInfoContainer>
        </StyledContainer>

        <NextPreviousButton
          isNext
          enabled={currentIndex < totalPages}
          callBack={handleNext}
          isActive={currentIndex < totalPages}
          isMeetingRaceCard
          navigationText={getButtonText(currentIndex + 1)}
        />
      </FlexContainer>
    </MainFooterDiv>
  );
};

export default LeaderboardFooter;
