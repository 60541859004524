import styled from "styled-components";
import { ILeaderboardPunterUnit } from "../../../../store/features/leaderboardSlice";

const BoldSpan = styled.span`
  font-weight: bold;
`;

const GridStandardFooterPageNumber = ({
  totalPages,
  currentPage,
  currentPuntersList,
  totalNumPunters
}: {
  totalPages: number;
  currentPage: number;
  currentPuntersList: ILeaderboardPunterUnit[] | null | undefined;
  totalNumPunters: number;
}) => {
  const pagesAvailable = () => {
    if (totalPages > 0 && currentPage > 0) {
      if (currentPuntersList?.length || totalNumPunters > 0) return true;
      if (!currentPuntersList && totalNumPunters < 0) return true;
    }

    return false;
  };

  return pagesAvailable() ? (
    <span>
      Page <BoldSpan>{`${currentPage} of ${totalPages}`}</BoldSpan>
    </span>
  ) : (
    <span>Page 0</span>
  );
};

export default GridStandardFooterPageNumber;
