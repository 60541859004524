/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import Info from "../info";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getBalanceFormatted,
  getIntlFormat
} from "../../../../utils/currencyFormatUtils";
import FlexContainer from "../../../bricks/flexContainer";
import PrevButton from "@assets/arrow-down.svg";
import NextButton from "@assets/arrow-up.svg";
import useInfotextOld from "../hooks/useInfotext_old";
import Container from "../../../bricks/container";
import { CancelDiv, SpDiv, StakeButtonImg } from "./bettypeCardNew.style";

const ALLOWED_NUMBER_KEY_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8];

interface IBettypeCardNew {
  typeName: string;
  numberOfBets: number;
  stakeperBet?: number;
  payout?: number | string;
  updaterCallback: (type: string, stakePerbet: number) => void;
  infoText?: string;
  toShowSp?: boolean;
  isSp?: boolean;
  spCallback?: (type: string) => void;
}

const BettypeCardNew = ({
  typeName,
  numberOfBets,
  payout,
  stakeperBet,
  updaterCallback,
  infoText,
  toShowSp,
  isSp,
  spCallback
}: IBettypeCardNew) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const totalPayout = Number(payout) * stakeperBet!;
  const numBets = numberOfBets;
  const payoutPerUnit = useRef(Number((Number(payout) / numBets).toFixed(2)));
  const [stakePerBet, setStakePerBet] = useState<number>(stakeperBet ?? 1);
  const [totalStake, setTotalStake] = useState<number>(
    Number(stakeperBet! * numBets)
  );
  const [currentPayout, setCurrentPayout] = useState<number>(
    Number(totalPayout)
  );

  const [isCancelled, setIsCancelled] = useState<boolean>(stakeperBet! <= 0);
  const [isBetOverLimit] = useState<boolean>(false); // eslint-disable-line

  const [toOpenInfo, setToOpenInfo] = useState<boolean>(false);

  const isInvalidChar = useRef<boolean>(false);
  const currentValue = useRef<number>(0);

  const [getInfoForType] = useInfotextOld();

  useEffect(() => {
    if (isCancelled && stakeperBet! > 0) {
      setIsCancelled(false);
    }
  }, [stakeperBet]);

  useEffect(() => {
    if (stakePerBet >= 0 && inputRef.current) {
      inputRef.current.value = String(stakePerBet);
      currentValue.current = stakePerBet;
    }
  }, [stakePerBet]);
  useEffect(() => {
    setStakePerBet(stakeperBet!);
    setTotalStake(stakeperBet! * numBets);
    setCurrentPayout(totalPayout);
  }, [stakeperBet]);

  const onCancel = () => {
    if (!isCancelled && stakePerBet > 0) {
      onUpdateStakePerBetValue(!isCancelled ? 0 : 1);
      setIsCancelled(!isCancelled);
    }
  };

  const onUpdateStakePerBetValue = (inputValue: number) => {
    if (isCancelled && inputValue > 0) setIsCancelled(false);
    const updatedTotalStake = inputValue * numBets;
    const updatedPayout = payoutPerUnit.current * numBets * inputValue;

    setStakePerBet(inputValue);
    setTotalStake(updatedTotalStake);
    setCurrentPayout(updatedPayout);
    updaterCallback(typeName || "", inputValue);
  };

  const onInputChange = (e) => {
    if (isInvalidChar.current) {
      e.target.value = currentValue.current;
    } else {
      if (e.target.value === "") {
        e.target.value = 0;
      }
      const inputValue = Number(e.target.value);

      onUpdateStakePerBetValue(inputValue);
    }
  };

  const onKeyPress = (e) => {
    isInvalidChar.current = false;

    if (
      !ALLOWED_NUMBER_KEY_CODES.includes(e.keyCode) ||
      !ALLOWED_NUMBER_KEY_CODES.includes(e.which) ||
      (e.keyCode === 48 &&
        Number(currentValue.current) === 0 &&
        Number(currentValue.current) === Number(e.target.value))
    ) {
      isInvalidChar.current = true;
      currentValue.current = e.target.value;
      e.preventDefault();
    }
  };

  const onIncrDecrClick = (toIncrement: boolean = true) => {
    let final: number = stakePerBet;
    const incrementFactor: number = toIncrement ? 1 : final > 0 ? -1 : 0;
    final += incrementFactor;

    onUpdateStakePerBetValue(final);
  };

  return (
    <FlexContainer
      direction="column"
      width="97%"
      height="max-content"
      rowgap="0px"
      position="relative"
      marginbottom="10px"
      alignitems="left">
      <FlexContainer columngap="10px">
        <label
          style={{
            color: "#5C2D91",
            fontSize: "0.7rem",
            width: "fit-content",
            height: "fit-conten",
            fontWeight: "bold"
          }}>
          {typeName}
        </label>

        <Container
          bgcolor="white"
          border="1px solid #5C2D91"
          width="12px"
          height="12px"
          borderradius="7px"
          display="grid"
          placecontent="center"
          color="#5C2D91"
          paddingtop="0px"
          fontSize="0.7rem"
          cursor="pointer"
          onClick={() => {
            setToOpenInfo((prev) => !prev);
          }}>
          {" "}
          {toOpenInfo ? "X" : "i"}{" "}
        </Container>
      </FlexContainer>

      <Info
        text={getInfoForType(typeName)}
        toShowButton={false}
        toOpen={toOpenInfo}
      />

      <div style={{ display: "flex", flexDirection: "column", rowGap: "0px" }}>
        <div
          style={{
            display: "flex",
            fontSize: "0.7rem",
            marginTop: "5px",
            fontWeight: "normal"
          }}>
          <div
            style={{
              background: "#F4F6FD",
              color: "#5C2D91",
              width: "14%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "0px",
              display: "grid",
              placeContent: "center"
            }}>
            {" "}
            Bets
          </div>
          <div
            style={{
              background: "#F4F6FD",
              color: "#5C2D91",
              width: "24%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              display: "grid",
              placeContent: "center",
              lineHeight: 1
            }}>
            {" "}
            Stake per bet{" "}
          </div>
          <div
            style={{
              background: "#F4F6FD",
              color: "#5C2D91",
              width: "19%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              display: "grid",
              placeContent: "center",
              lineHeight: 1
            }}>
            {" "}
            Total stake{" "}
          </div>
          <div
            style={{
              background: "#F4F6FD",
              color: "#5C2D91",
              width: "30%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "0px",
              display: "grid",
              placeContent: "center",
              lineHeight: 1
            }}>
            {" "}
            Payout{" "}
          </div>

          <div
            style={{
              width: "9%",
              height: "60%",
              marginLeft: "5px",
              textAlign: "right"
            }}>
            <CancelDiv onClick={onCancel} isCancelled={isCancelled}>
              X
            </CancelDiv>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "0.7rem",
            marginTop: "0px",
            fontWeight: "normal"
          }}>
          <div
            style={{
              background: "#fff",
              color: "#5C2D91",
              width: "14%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderTopLeftRadius: "0px",
              borderTop: "0px",
              borderBottomLeftRadius: "0px",
              display: "grid",
              placeContent: "center"
            }}>
            {" "}
            {numBets}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#5C2D91",
              width: "24%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              borderTop: "0px",
              alignItems: "center",
              columnGap: "4px"
            }}>
            <StakeButtonImg
              onClick={() => {
                onIncrDecrClick(false);
              }}
              src={PrevButton}
            />

            <div
              id="stakePerePetInputContainer"
              style={{
                width: "2rem",
                height: "60%",
                border: `1px solid ${stakePerBet > 0 ? "#5C2D91" : "red"}`,
                borderRadius: "3px",
                display: "flex",
                columnGap: "0px",
                alignItems: "center",
                padding: "2px 2px",
                marginTop: "0px"
              }}>
              <label
                style={{
                  color: "#764CBF",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  background: "",
                  lineHeight: "1"
                }}>
                {" "}
                R
              </label>
              <input
                type="tel"
                min="0"
                size={0.5}
                onKeyDown={onKeyPress}
                onChange={onInputChange}
                ref={inputRef}
                style={{
                  background: "",
                  width: "93%",
                  color: "#764CBF",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                  border: "none",
                  outline: "none",
                  lineHeight: "1",
                  verticalAlign: "top",
                  paddingTop: "0px",
                  textAlign: "left"
                }}
              />
            </div>

            <StakeButtonImg
              onClick={() => {
                onIncrDecrClick();
              }}
              src={NextButton}
            />
          </div>

          <div
            style={{
              background: "",
              width: "19%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              display: "grid",
              placeContent: "center",
              borderTop: "0px"
            }}>
            <label
              style={{
                color: "#5C2D91",
                fontSize: "0.7rem",
                fontWeight: "bold",
                background: "",
                minWidth: "94%",
                overflow: "auto",
                textAlign: "left"
              }}>
              {" "}
              {getBalanceFormatted(totalStake)}
            </label>
          </div>

          <div
            style={{
              background: "#fff",
              color: "#5C2D91",
              width: "30%",
              height: "20px",
              border: "1px solid #5C2D91",
              borderLeft: "0px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "grid",
              placeContent: "center",
              borderTop: "0px"
            }}>
            <label
              style={{
                color: "#5C2D91",
                fontSize: "0.7rem",
                fontWeight: "bold",
                background: "",
                minWidth: "94%",
                overflow: "auto",
                textAlign: "left"
              }}>
              {" "}
              {isSp ? "SP" : getIntlFormat(currentPayout.toFixed(2))}
            </label>
          </div>

          {toShowSp && spCallback && (
            <div style={{ width: "9%", height: "98%", marginLeft: "5px" }}>
              <SpDiv
                onClick={() => {
                  spCallback(typeName);
                }}>
                {" "}
                {!isSp ? "SP" : "FO"}{" "}
              </SpDiv>
            </div>
          )}
        </div>
      </div>

      {isBetOverLimit && (
        <label style={{ color: "red", fontSize: "0.6rem" }}>
          {" "}
          Current payout bet is over the limit
        </label>
      )}
    </FlexContainer>
  );
};

export default BettypeCardNew;
