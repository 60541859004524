import styled from "styled-components";
import theme from "../../../../../theme";

interface IResultContainer {
  isDesktop: boolean;
}

export const ResultsContainer = styled.div<IResultContainer>`
  width: ${(props) => (props.isDesktop ? "100%" : "95%")};
  display: grid;
  margin-top: 20px;
`;

export const ResultsHeadings = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  align-content: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 8px;
`;

export const ViewMoreLink = styled.a<IResultContainer>`
  width: fit-content;
  font-size: ${theme.font.size.sm};
  href: /home;
  cursor: pointer;
  color: ${(props) => (props.isDesktop ? "black" : "white")};
  text-align: right;
  align-content: center;
  padding-right: 23px;
`;
