import Container from "../../../../bricks/container";
import FlexContainer from "../../../../bricks/flexContainer";
import { CURRENCY } from "../../constants/luckyPickConstants";
import Input from "../../../../bricks/input";
import theme from "../../../../../theme";

interface ITotalStakeView {
  defaultValue: number;
  callback: (val: number) => void;
  width?: number;
}

const TotalStakeView = ({ defaultValue, callback, width }: ITotalStakeView) => {
  return (
    <Container
      id="total_stake_input"
      border={`1px ${defaultValue <= 0 ? "red" : theme.colors.lightGrey3}  solid`}
      borderradius="5px"
      width={`${width ?? 80}%`}
      maxheight="36px"
      minheight="30px"
      display="grid"
      placecontent="center"
      padding="2px">
      <FlexContainer width="100%" height="100%" alignitems="center">
        <Container
          fontSize="0.9rem"
          height="100%"
          lineheight="1"
          display="absolute"
          alignitems="center"
          color="black"
          paddingleft="6px"
          paddingtop="2px"
          paddingbottom="0px">
          {CURRENCY}
        </Container>
        <Container width="70%" display="grid" alignitems="center" height="100%">
          <Input
            callback={callback}
            defaultValue={defaultValue}
            type="number"
            fontsize="0.9rem"
            lineheight={1}
          />
        </Container>
      </FlexContainer>
    </Container>
  );
};

export default TotalStakeView;
