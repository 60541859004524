import styled from "styled-components";
import theme from "../../../theme";

interface IGroupTitle {
  color?: string;
}

export const GroupHeaderContainer = styled.div`
  width: fit-content;
  column-gap: 5px;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const GroupTitle = styled.span<IGroupTitle>`
  width: fit-content;
  font-size: ${theme.font.size.m};
  font-weight: bold;
  color: ${(props) => props.color ?? "white"};
`;

export const GroupSubtitle = styled.span<IGroupTitle>`
  width: fit-content;
  font-size: ${theme.font.size.m};
  color: ${(props) => props.color ?? "white"};
`;

export const GroupIcon = styled.img`
  width: 20px;
  height: 20px;
  padding-right: 3px;
`;
