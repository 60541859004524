import { useSelector } from "react-redux";
import { useContext } from "react";
import { RootState } from "../../../../store/store";
import { formatInDecimal } from "../../../../utils/numberUtils";
import {
  PointsContainerMob,
  PointsContainerWeb,
  PointsText
} from "./leaderboardPoints.style";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

export interface ILeaderboardPoints {
  punterPoints: number;
  punterId: string;
}

const LeaderboardPoints = ({ punterPoints, punterId }: ILeaderboardPoints) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  const currentPunter = useSelector((state: RootState) => state.user.userId);
  const isCurrentPunter = currentPunter === punterId;

  return isDesktop ? (
    <PointsContainerWeb isCurrentPunter={isCurrentPunter}>
      <PointsText isCurrentPunter={isCurrentPunter}>
        {formatInDecimal(punterPoints)}
      </PointsText>
    </PointsContainerWeb>
  ) : (
    <PointsContainerMob isCurrentPunter={isCurrentPunter}>
      <PointsText isCurrentPunter={isCurrentPunter}>
        {formatInDecimal(punterPoints)}
      </PointsText>
    </PointsContainerMob>
  );
};

export default LeaderboardPoints;
