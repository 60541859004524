import styled from "styled-components";

const CourseNameDisplay = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.purplePrimary};
`;

interface IValueDisplay {
  value: string | number;
}

const ValueDisplay = ({ value }: IValueDisplay) => {
  return <CourseNameDisplay>{value}</CourseNameDisplay>;
};

export default ValueDisplay;
