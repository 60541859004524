import styled from "styled-components";
import useLogin from "../../../../hooks/useLogin";
import theme from "../../../../theme";
import Button from "../../../bricks/button";
import FlexContainer from "../../../bricks/flexContainer";

interface ISignupOrLogin {
  shouldBeInRow?: boolean;
  onRegisterClicked: () => void;
}

const MediaQueryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px) and (max-width: 1140px) {
    font-size: 12px;
  }
`;

const SignupOrLogin = ({
  shouldBeInRow,
  onRegisterClicked
}: ISignupOrLogin) => {
  const { onLogInClick } = useLogin();

  return (
    <FlexContainer
      direction={shouldBeInRow ? "row" : "column"}
      width="100%"
      alignitems={shouldBeInRow ? "right" : "center"}
      rowgap="14px">
      {shouldBeInRow ? (
        <>
          <MediaQueryContainer>
            <Button
              text="Register For Free"
              width="100%"
              height="28px"
              gradient={{
                colors: [
                  theme.colors.yellowPrimary,
                  theme.colors.yellowSecondary
                ],
                direction: "linear"
              }}
              fontcolor={theme.colors.purplePrimary}
              onClick={onRegisterClicked}
            />
          </MediaQueryContainer>
          <Button
            text="Log In"
            width="11vw"
            height="28px"
            fontcolor="white"
            border="1px solid white"
            backgroundcolor={theme.colors.purpleSecondary}
            fontWeight="none"
            marginleft="15px"
            borderradius="5px"
            onClick={onLogInClick}
          />
        </>
      ) : (
        <>
          <Button
            text="Login"
            width="80%"
            backgroundcolor={theme.colors.lightGrey3}
            fontcolor="black"
            onClick={onLogInClick}
          />
          <Button
            text="Register For Free"
            width="80%"
            gradient={{
              colors: [
                theme.colors.yellowPrimary,
                theme.colors.yellowSecondary
              ],
              direction: "linear"
            }}
            fontcolor={theme.colors.purplePrimary}
            onClick={onRegisterClicked}
          />
        </>
      )}
    </FlexContainer>
  );
};

export default SignupOrLogin;
