import Switch from "react-switch";
import FlexContainer from "../../../../bricks/flexContainer";
import theme from "../../../../../theme";
import OddsDisplay from "./toggleBetType.style";

export interface IToggleOdds {
  onOddsTypeToggled: (event: boolean) => void;
  isFixedOdds: boolean;
  padding?: number;
  textColor?: string;
  backgroundColor?: string;
  handleColor?: string;
  zeroOddsSelected: boolean;
}

const ToggleBetType = ({
  onOddsTypeToggled,
  isFixedOdds,
  padding = 0,
  zeroOddsSelected,
  textColor = theme.colors.purplePrimary,
  backgroundColor = theme.colors.purpleSecondary,
  handleColor = theme.colors.primary
}: IToggleOdds) => {
  return (
    <FlexContainer
      width="100%"
      justifycontent="right"
      padding={`${padding}px`}
      alignitems="center">
      <OddsDisplay
        fontWeight={
          isFixedOdds ? theme.font.weight.medium : theme.font.weight.bold
        }
        color={textColor}>
        SP
      </OddsDisplay>
      <Switch
        onChange={onOddsTypeToggled}
        checked={isFixedOdds}
        onColor={backgroundColor}
        offColor={backgroundColor}
        onHandleColor={handleColor}
        offHandleColor={handleColor}
        handleDiameter={10}
        activeBoxShadow="0px 0px 1px 2px yellow"
        height={15}
        width={40}
        uncheckedIcon={<div />}
        checkedIcon={<div />}
        disabled={zeroOddsSelected}
      />
      <OddsDisplay
        fontWeight={
          isFixedOdds ? theme.font.weight.bold : theme.font.weight.medium
        }
        color={textColor}>
        FO
      </OddsDisplay>
    </FlexContainer>
  );
};

export default ToggleBetType;
