import { useNavigate } from "react-router-dom";
import { MENU_ITEMS_LOGGED_IN } from "../constants";

const useNavigation = () => {
  const navigate = useNavigate();

  const toNavigate = (id: string) => {
    navigate(MENU_ITEMS_LOGGED_IN[id]);
  };

  return { toNavigate };
};

export default useNavigation;
