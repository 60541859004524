import GallopLogo from "@assets/galloptv-logo.svg";
import HollywoodLogo from "@assets/hwb-logo.svg";
import winningformLogo from "@assets/winningform-logo.svg";
import Downarrow from "@assets/down-new.png";
import ProfileIcon from "@assets/profile.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import Container from "../bricks/container";
import FlexContainer from "../bricks/flexContainer";
import MenuItems from "./menuItems";
import useOutsideClick from "../../hooks/useOutsideClick";
import type { RootState } from "../../store/store";
import {
  IClientBalance,
  IUser,
  attemptPersistedLogin,
  getClientBalance,
  getLegacyAccessToken,
  logout,
  puntersChallengeLogin,
  setUserName
} from "../../store/features/userSlice";
import { getBalanceFormatted } from "../../utils/currencyFormatUtils";
import openLink from "../../utils/pageUtils";
import Logo from "./logo";
import { MENU_ITEMS_LOGGED_IN, MENU_ITEMS_LOGGED_OUT } from "../../constants";
import useNavigation from "../../hooks/useNavigation";
import NavigationTabs from "./navigationTabs";
import { getCookie, setCookie } from "../../utils/cookieUtils";
import BurgerMenuIcon from "./burgerMenu/burgerMenuIcon";
import UserMenuIcon from "./usermenu/userMenuIcon";
import Button from "../bricks/button";
import RefreshButton from "../composites/refreshButton";
import {
  GallopTvDiv,
  HeaderContainerDesk,
  HeaderContainerMob,
  HeaderInternalContainerDesk,
  HollywoodBetsDiv,
  LoginContainer,
  LoginSpan,
  LogoutSpan,
  UpDownArowContainer,
  WinningFormDiv
} from "./header.style";
import useLogin from "../../hooks/useLogin";

interface IImageLinks {
  width?: string;
  height?: string;
}

const UserMenu = ({
  userData,
  userBalance,
  RefreshBalance,
  initiateLogout
}: {
  userData: IUser;
  userBalance: IClientBalance;
  RefreshBalance: () => void;
  initiateLogout: () => void;
}) => (
  <FlexContainer
    direction="column"
    alignitems="center"
    width="50vw"
    height="max-content"
    border="1px solid #b3b3cc"
    borderradius="9px"
    bgcolor="#F4F6FD"
    padding="10px"
    minwidth="200px">
    <FlexContainer
      id="first_line"
      width="100%"
      bgcolor=""
      height="max-content"
      columngap="0px"
      paddingtop="10px"
      paddingbottom="10px"
      borderbottom="1px solid #b3b3cc">
      <Container width="10%">
        <img src={ProfileIcon} style={{ width: "100%", height: "100%" }} />
      </Container>
      <FlexContainer
        width="46%"
        bgcolor=""
        height="auto"
        direction="column"
        alignitems="center"
        rowgap="5px"
        borderright="1px solid #b3b3cc">
        <span
          style={{
            fontSize: "0.6rem",
            color: "black",
            fontWeight: "bold",
            lineHeight: "1",
            width: "max-content"
          }}>
          Alias
        </span>

        <span
          style={{
            fontSize: "1rem",
            color: "#5C2D91",
            fontWeight: "bold",
            lineHeight: "1",
            width: "100%",
            background: "",
            wordWrap: "break-word"
          }}>
          {" "}
          {userData.data?.user.id}
        </span>
      </FlexContainer>
      <FlexContainer
        width="40%"
        bgcolor=""
        direction="column"
        alignitems="center"
        rowgap="5px">
        <span
          style={{
            fontSize: "0.6rem",
            color: "black",
            fontWeight: "bold",
            lineHeight: "1",
            width: "max-content"
          }}>
          Account
        </span>
        <span
          style={{
            fontSize: "1rem",
            color: "#5C2D91",
            fontWeight: "bold",
            lineHeight: "1",
            width: "max-content"
          }}>
          {userData.data?.user.username}
        </span>
      </FlexContainer>
    </FlexContainer>

    <FlexContainer
      id="second_line"
      width="100%"
      paddingtop="10px"
      paddingbottom="10px"
      borderbottom="1px solid #b3b3cc">
      <FlexContainer
        width="100%"
        bgcolor=""
        direction="column"
        alignitems="center"
        rowgap="5px">
        <span
          style={{
            fontSize: "0.6rem",
            color: "black",
            fontWeight: "bold",
            lineHeight: "1",
            width: "max-content"
          }}>
          My balance
        </span>
        <span
          style={{
            fontSize: "1rem",
            color: "#5C2D91",
            fontWeight: "bold",
            lineHeight: "1",
            width: "max-content"
          }}>
          {getBalanceFormatted(Math.abs(userBalance?.balanceAvailable))}
        </span>
        <Container
          width="4vw"
          height="4vw"
          marginleft="10px"
          onClick={RefreshBalance}>
          <RefreshButton color="#5C2D91" />
        </Container>
      </FlexContainer>
    </FlexContainer>

    <Button
      width="100px"
      height="30px"
      margintop="15px"
      marginbottom="5px"
      border="1px solid #5C2D91"
      backgroundcolor="white"
      onClick={initiateLogout}
      fontcolor="#5C2D91"
      fontSize="0.8rem"
      text="Logout"
    />
  </FlexContainer>
);

const ImageLinks = ({ width, height }: IImageLinks) => {
  const config = useSelector((state: RootState) => state.config.data);

  return (
    <FlexContainer
      width={width}
      height={height ?? "auto"}
      bgcolor="none"
      justifycontent="space-between">
      <GallopTvDiv
        onClick={() => {
          openLink(`${config.gallopTv}`);
        }}
        src={GallopLogo}
      />
      <HollywoodBetsDiv
        onClick={() => {
          openLink(`${config.hollywoodBets}`);
        }}
        src={HollywoodLogo}
      />
      <WinningFormDiv
        onClick={() => {
          openLink(`${config.winningForm}`);
        }}
        src={winningformLogo}
      />
    </FlexContainer>
  );
};

const Header = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const auth = useAuth();
  const dispatch = useDispatch();

  const { signoutRedirect, onLogInClick } = useLogin();

  const userData = useSelector((state: RootState) => state.user);
  const userBalance = useSelector((state: RootState) => state.user.balance);
  const currentMenuItems = useRef(MENU_ITEMS_LOGGED_OUT);

  const { showNewHomeForMob } = useSelector(
    (state: RootState) => state.config.data
  );

  const { toNavigate } = useNavigation();

  const [toOpenDropDown, setToOpenDropDown] = useState<boolean>(false);
  const [toOpenMenu, setToOpenMenu] = useState<boolean>(false);
  const [toOpenUserMenu, setToOpenUserMenu] = useState<boolean>(false);
  const handleOutSideClick = () => {
    setToOpenMenu(false);
    setToOpenUserMenu(false);
  };
  useOutsideClick(handleOutSideClick, ref); // eslint-disable-line
  useOutsideClick(handleOutSideClick, userMenuRef); // eslint-disable-line
  const toShowFriendsLeague = useSelector(
    (state: RootState) => state.config.data.showFriendsLeague
  );

  const initiateLogout = () => {
    setToOpenUserMenu(false);
    dispatch(logout());
    signoutRedirect();
  };

  const onMenuItemClick = (item: string) => {
    setToOpenMenu(false);

    if (item === "Logout") {
      initiateLogout();
    } else {
      toNavigate(item);
    }
  };

  const checksPostSSOAuthentication = () => {
    if (
      auth?.isAuthenticated &&
      auth?.user &&
      !auth?.isLoading &&
      !auth?.user.expired &&
      !userData?.isLoggedIn
    ) {
      const LoggedinCookie = getCookie("Loggedin");
      const storedPCData = localStorage.getItem("rememberpc");
      const persisted = LoggedinCookie && storedPCData;
      const isInLoginprocess = getCookie("isInLoginprocess");

      if (isInLoginprocess && !persisted) {
        dispatch(setUserName(auth.user.profile.sub));

        if (!LoggedinCookie) {
          const expiryDate = new Date();
          expiryDate.setTime(expiryDate.getTime() + 43200 * 1000);

          setCookie(
            "Loggedin",
            "true",
            expiryDate,
            "/",
            window.location.hostname
          );
        }

        dispatch(
          getLegacyAccessToken({ ssoAccessToken: auth.user.access_token })
        );
      }
    }
  };

  const filterOutRestricted = (item: string) => {
    if (!toShowFriendsLeague && item === "Friends Challenge") return false;

    return item;
  };
  useEffect(() => {
    currentMenuItems.current = userData?.isLoggedIn
      ? MENU_ITEMS_LOGGED_IN
      : MENU_ITEMS_LOGGED_OUT;

    if (!userData?.isLoggedIn) {
      setToOpenDropDown(false);
    }
  }, [userData]);

  useEffect(() => {
    if (userData.legacyAccessToken && userData.userName) {
      dispatch(puntersChallengeLogin({ userName: userData.userName }));
    }
  }, [userData.legacyAccessToken, userData.userName]);

  useEffect(() => {
    dispatch(attemptPersistedLogin({}));

    if (
      !toShowFriendsLeague &&
      window.location.pathname.substring(1) === "friendsLeague"
    ) {
      toNavigate("/home");
    }
  }, []);

  useEffect(() => {
    if (auth) {
      checksPostSSOAuthentication();
    }
  }, [auth]);

  const RefreshBalance = async () => {
    dispatch(getClientBalance({ userName: userData.userName }));
  };

  return (
    <>
      <HeaderContainerMob>
        <Container width="100%" bgcolor="none" height="auto">
          <FlexContainer
            direction="column"
            width="92vw"
            alignitems="flex-start"
            marginleft="4vw"
            rowgap="5px"
            bgcolor="none">
            <FlexContainer
              alignitems="center"
              columngap="10px"
              margin="0 0 10px 0"
              position="relative"
              bgcolor="none"
              width="100%"
              justifycontent="space-between">
              {toOpenMenu && (
                <div
                  ref={ref}
                  style={{ position: "absolute", top: "60px", zIndex: "150" }}>
                  <MenuItems
                    items={Object.keys(currentMenuItems.current).filter(
                      filterOutRestricted
                    )}
                    callback={onMenuItemClick}
                  />
                </div>
              )}
              {toOpenUserMenu && (
                <div
                  ref={userMenuRef}
                  style={{
                    position: "absolute",
                    top: "60px",
                    right: "0",
                    zIndex: "150"
                  }}>
                  <UserMenu
                    userData={userData}
                    userBalance={userBalance as IClientBalance}
                    RefreshBalance={RefreshBalance}
                    initiateLogout={initiateLogout}
                  />
                </div>
              )}
              <FlexContainer columngap="10px">
                <FlexContainer
                  direction="column"
                  rowgap="2px"
                  cursor="pointer"
                  alignitems="center">
                  <BurgerMenuIcon
                    width="40px"
                    height="40px"
                    callback={() => {
                      setToOpenMenu((prev) => !prev);
                    }}
                    open={toOpenMenu}
                  />
                  <span
                    style={{
                      width: "max-content",
                      color: "white",
                      fontWeight: "normal",
                      marginLeft: "0px",
                      height: "fit-content",
                      lineHeight: "1",
                      fontSize: "0.7rem"
                    }}>
                    {" "}
                    MENU
                  </span>
                </FlexContainer>
              </FlexContainer>

              <Container
                width="50%"
                marginleft="0px"
                margin="0"
                padding="0"
                height="100%"
                bgcolor="none"
                onClick={() => {
                  toNavigate("/home");
                }}>
                <Logo width="100%" />
              </Container>

              {userData?.isLoggedIn ? (
                <FlexContainer
                  direction="column"
                  alignitems="center"
                  bgcolor="none"
                  rowgap="2px">
                  <UserMenuIcon
                    width="40px"
                    height="40px"
                    callback={() => {
                      setToOpenUserMenu((prev) => !prev);
                    }}
                    open={toOpenUserMenu}
                  />
                  <span
                    style={{
                      width: "max-content",
                      color: "white",
                      fontWeight: "normal",
                      marginLeft: "0px",
                      height: "fit-content",
                      lineHeight: "1",
                      fontSize: "0.7rem"
                    }}>
                    PROFILE
                  </span>
                </FlexContainer>
              ) : (
                <Container
                  bgcolor="none"
                  lineheight="1"
                  display="grid"
                  placecontent="center"
                  margintop="-10px">
                  <LoginSpan onClick={onLogInClick} style={{}}>
                    Log in
                  </LoginSpan>
                </Container>
              )}
            </FlexContainer>
            {!showNewHomeForMob && <ImageLinks width="100%" />}
          </FlexContainer>
        </Container>
      </HeaderContainerMob>

      <HeaderContainerDesk>
        <HeaderInternalContainerDesk>
          <FlexContainer
            width="35%"
            alignitems="center"
            paddingleft="20px"
            columngap="5px"
            bgcolor="none">
            {!userData?.isLoggedIn ? (
              <LoginContainer onClick={onLogInClick}> Log in</LoginContainer>
            ) : (
              <FlexContainer
                direction="column"
                position="absolute"
                height="max-content"
                width="30%"
                rowgap="3px"
                top="30%"
                bgcolor="none">
                <FlexContainer
                  width="94%"
                  height="max-content"
                  bgcolor="none"
                  marginleft="20px"
                  alignitems="center">
                  <span
                    style={{
                      fontSize: "1.2vw",
                      color: "white",
                      lineHeight: "1"
                    }}>
                    Welcome
                  </span>
                  <Container width="8px" />
                  <span
                    style={{
                      fontSize: "1.3vw",
                      color: "white",
                      fontWeight: "bold",
                      lineHeight: "1"
                    }}>
                    {userData.data?.user.id}
                  </span>
                  <Container width="8px" />
                  <span
                    style={{
                      fontSize: "1.4vw",
                      color: "yellow",
                      fontWeight: "bold",
                      marginLeft: "5px",
                      lineHeight: "1"
                    }}>
                    {getBalanceFormatted(
                      Math.abs(userBalance?.balanceAvailable as number)
                    )}
                  </span>
                  <Container
                    width="1.5vw"
                    height="1.5vw"
                    marginleft="10px"
                    onClick={RefreshBalance}>
                    <RefreshButton />
                  </Container>
                </FlexContainer>

                <FlexContainer
                  id="dropdown_container_with_buttons"
                  direction="column"
                  bgcolor="none"
                  marginleft="6vw"
                  width="2vw"
                  alignitems="center"
                  style={{ zIndex: "40" }}>
                  <UpDownArowContainer
                    id="buttons"
                    onClick={() => {
                      setToOpenDropDown((prev) => !prev);
                    }}
                    bgcolor="none"
                    cursor="pointer"
                    lineheight="0.6">
                    {toOpenDropDown ? (
                      <img
                        style={{
                          background: "none",
                          height: "100%",
                          width: "100%",
                          color: "none",
                          filter: "invert(100%)",
                          transform: "scaleY(-1)"
                        }}
                        src={Downarrow}
                      />
                    ) : (
                      <img
                        style={{
                          background: "none",
                          height: "100%",
                          width: "100%",
                          color: "none",
                          filter: "invert(100%)"
                        }}
                        src={Downarrow}
                      />
                    )}
                  </UpDownArowContainer>

                  {toOpenDropDown && (
                    <FlexContainer
                      direction="column"
                      width="12vw"
                      height="max-content"
                      bgcolor="#5C2D91"
                      rowgap="5px"
                      margintop="5px"
                      paddingbottom="8px"
                      alignitems="center"
                      borderbottomrightradius="5px"
                      borderbottomleftradius="5px">
                      <Container
                        display="grid"
                        placecontent="center"
                        color="#5C2D91"
                        border="1px solid black"
                        minheight="20px"
                        borderradius="4px"
                        width="95%"
                        bgcolor="linear-gradient(#FCEE21,#ECCB00)"
                        fontSize="1.2vw"
                        fontWeight="bold">
                        {" "}
                        {`Account: ${userData.userName}`}
                      </Container>

                      <LogoutSpan onClick={initiateLogout}>Logout</LogoutSpan>
                    </FlexContainer>
                  )}
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>

          <Container width="15%" padding="0px" marginleft="-120px">
            <Logo width="100%" />
          </Container>
          <Container width="30%" marginright="10px">
            <ImageLinks width="100%" height="100%" />
          </Container>
        </HeaderInternalContainerDesk>
        <Container
          width="95%"
          height="fit-content"
          marginleft="2.5%"
          margintop="5px"
          marginbottom="5px">
          <NavigationTabs filteringFunction={filterOutRestricted} />
        </Container>
      </HeaderContainerDesk>
    </>
  );
};

export default Header;
