import { ActiveDot, Dot, DotsContainer, InactiveDot } from "./dots.style";

interface IDots {
  total?: number;
  clickCallback: (index: number) => void;
  activeIndex?: number;
}

const Dots = ({ total, clickCallback, activeIndex }: IDots) => {
  const arr = Array.from(Array(total).keys());

  return (
    <DotsContainer>
      {arr.map((item, index) => (
        <Dot
          key={`Dots_item_${item}`}
          onClick={() => {
            clickCallback(index);
          }}>
          {activeIndex === index && <ActiveDot />}
          {activeIndex !== index && <InactiveDot />}
        </Dot>
      ))}
    </DotsContainer>
  );
};

export default Dots;
