import arrowDownPurple from "@assets/arrow-down-purple.svg";
import {
  ViewArrow,
  ViewContainer,
  ViewText
} from "./resultsRaceCardView.style";

interface IResultsView {
  raceCourseName: string;
  shouldOpen: boolean;
  handleClick: () => void;
}

const ResultsView = ({
  raceCourseName,
  shouldOpen,
  handleClick
}: IResultsView) => {
  return (
    <ViewContainer
      raceCourseName={raceCourseName}
      shouldOpen={shouldOpen}
      onClick={handleClick}>
      <ViewText shouldOpen={shouldOpen}>View</ViewText>
      <ViewArrow
        shouldOpen={shouldOpen}
        src={arrowDownPurple}
        alt="Toggle Arrow"
      />
    </ViewContainer>
  );
};

export default ResultsView;
