import { memo, useContext } from "react";
import { IJoinedLeagueItem } from "../../../../store/features/friendsLeagueSlice";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

import JoinedLeaguesWeb from "./joinedLeaguesWeb";
import JoinedLeaguesMob from "./joinedLeaguesMob";

export interface IJoinedLeagues {
  data: IJoinedLeagueItem[] | null;
  joinALeagueCallback?: () => void;
  handleClose: () => boolean;
}

const JoinedLeagues = ({
  data,
  joinALeagueCallback,
  handleClose
}: IJoinedLeagues) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return isDesktop ? (
    <JoinedLeaguesWeb data={data} handleClose={handleClose} />
  ) : (
    <JoinedLeaguesMob
      data={data}
      joinALeagueCallback={joinALeagueCallback}
      handleClose={handleClose}
    />
  );
};

export default memo(JoinedLeagues);
