import styled from "styled-components";
import theme from "../../../theme";

export const CloseIconContainer = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: grey;
  &:hover {
    color: ${theme.colors.purplePrimary};
  }
`;

export const CloseIconDiv = styled.div`
  display: flex;
  justify-content: center;
`;
