import { useSelector } from "react-redux";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import { MILESTONE_ITEM_TYPES } from "../constants/friendsLeaguesConstants";
import MilestoneItem from "./milestoneItems/milestoneItem";
import { RootState } from "../../../../store/store";

const StatsMilestones = () => {
  const { leagueStatsData } = useSelector(
    (state: RootState) => state.friendsLeague
  );
  const {
    raceCourseNameBestWinPercentage,
    maxWinPercentage,
    overallAverageWinPercentageAllPunters,
    overallWinners,
    overallSelections,
    bestWinsByPunterWinners,
    bestWinsByPunterSelections,
    bestWinsByPunterRaceCourseName,
    bestWinsByPunterDate,
    bestPosition,
    bestPositionSelections,
    bestPositionRaceCourseName,
    bestPositionDate,
    puntersOutPerformed,
    highScore,
    highestScoreByAnyPunter,
    highestScoreByAnyPunterRaceCourseName,
    highestScoreByAnyPunterDate
  } = leagueStatsData ?? {};

  return (
    <FlexContainer
      direction="column"
      width="100%"
      height="auto"
      border={`1px solid ${theme.colors.lightGray}`}
      bgcolor={theme.colors.lightAliceBlue}
      borderradius="5px"
      alignitems="center"
      rowgap="10px">
      <FlexContainer
        width="98%"
        height="auto"
        bordertopleftradius="5px"
        bordertoprightradius="5px">
        <FlexContainer
          direction="column"
          marginleft="5px"
          color={theme.colors.purplePrimary}
          margintop="10px"
          width="100%">
          <Container textalign="left" fontWeight="bold" fontSize="1rem">
            Punter Milestones
          </Container>
        </FlexContainer>
      </FlexContainer>

      <Container width="97%" height="1px" bgcolor={theme.colors.lightGray} />

      <FlexContainer
        maxheight="200px"
        direction="column"
        overflow="scroll"
        width="97%"
        margintop="5px"
        marginbottom="20px"
        rowgap="10px">
        <MilestoneItem
          itemType={MILESTONE_ITEM_TYPES.bestCourse}
          bestCourseData={{
            courseName: `${raceCourseNameBestWinPercentage}`,
            average: `${maxWinPercentage}`,
            punterAverage: `${overallAverageWinPercentageAllPunters}`
          }}
        />
        <MilestoneItem
          itemType={MILESTONE_ITEM_TYPES.mostWins}
          mostWinsData={{
            mostWin: `${overallWinners}/${overallSelections}`,
            highestWin: `${bestWinsByPunterWinners}/${bestWinsByPunterSelections}`,
            highestWinCourse: `${bestWinsByPunterRaceCourseName}`,
            highestWinDate: `${bestWinsByPunterDate}`
          }}
        />
        <MilestoneItem
          itemType={MILESTONE_ITEM_TYPES.bestFinish}
          bestFinishData={{
            myBestRank: `${bestPosition}`,
            totalPlayers: `${bestPositionSelections}`,
            courseName: `${bestPositionRaceCourseName}`,
            date: `${bestPositionDate}`,
            outPerformed: `${puntersOutPerformed}`
          }}
        />
        <MilestoneItem
          itemType={MILESTONE_ITEM_TYPES.highestPoints}
          highestPointData={{
            point: `${highScore}`,
            highest: `${highestScoreByAnyPunter}`,
            courseName: `${highestScoreByAnyPunterRaceCourseName}`,
            date: `${highestScoreByAnyPunterDate}`
          }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default StatsMilestones;
