import styled from "styled-components";
import { useEffect } from "react";
import useCountdown from "../../hooks/useCountdown";
import FlexContainer from "../bricks/flexContainer";
import { isToday } from "../../utils/dateUtils";

interface ICountDownTimer {
  fontColor: string;
  borderColor: string;
  targetDate: number | string | null;
  fontSize: string;
  width: string;
}

interface ICounterState {
  $isLastMinute: boolean;
  $flashNow: boolean;
  $flashColor: string;
  color: string;
}

const CounterContainer = styled.div<ICounterState>`
  color: ${(props) =>
    props.$isLastMinute || props.$flashNow ? props.$flashColor : props.color};
  width: 100%;
  font-weight: bold;
  text-align: center;
`;

const CountdownTimer = ({
  targetDate,
  fontColor,
  borderColor,
  fontSize,
  width
}: ICountDownTimer) => {
  const { startCountDown, stopCountDown, days, hours, minutes, seconds } =
    useCountdown(targetDate ?? "");

  const isForToday = isToday(targetDate ?? "");

  useEffect(() => {
    if (isForToday && targetDate != null) {
      startCountDown();
    }

    return () => {
      stopCountDown();
    };
  }, [targetDate]);

  if (targetDate == null) {
    return false;
  }

  if (isForToday && days === 0 && hours + minutes + seconds > 0) {
    return (
      <FlexContainer
        bgcolor="white"
        alignitems="center"
        columngap="7px"
        borderradius="6px"
        width={width}
        height="fit-content"
        padding="8px"
        color="white"
        boxshadow="2px 2px 10px #a2a2c3"
        margintop="15px"
        fontSize={fontSize}
        border={`2px solid ${borderColor}`}>
        <CounterContainer
          color={fontColor}
          $flashColor="red"
          $isLastMinute={hours === 0 && minutes === 0}
          $flashNow={seconds === 0}>
          Selections Close in : {String(hours).padStart(2, "0")}:
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </CounterContainer>
      </FlexContainer>
    );
  }

  return null;
};

export default CountdownTimer;
