import { IBetslipBetType } from "../../../../store/features/betslipSlice";
import { BetType, IBetSlipBet } from "../../betslipNew/betslip.models";
import { BET_TYPES_DISPLAY_NAMES } from "../constants/luckyPickConstants";
import { ILuckyPickBetType } from "../store/luckyPickReducer";

export const getOdds = (horses: IBetslipBetType[]): number => {
  if (!horses.length) return 0;

  return horses
    ?.map((a) => a.eventDetailOfferedOdds)
    .reduce((c, d) => (c + 1) * (d + 1) - 1);
};

export const getPayouts = (
  horses: IBetslipBetType[],
  stake: number
): number => {
  if (!horses.length) return 0;
  const odds = getOdds(horses);

  return odds * stake;
};

const getNewMidIndex = (
  mIndex: number,
  inputLength: number,
  newStartIndex: number
): number => {
  let final = mIndex;
  let tmpMIndex = mIndex;
  if (mIndex + 1 === inputLength - 1) {
    final = newStartIndex + 1;
  } else if (mIndex + 1 < inputLength - 1) {
    final = ++tmpMIndex;
  }

  return final;
};

export const getCombos = (
  legs: number,
  input: any[],
  result: any[],
  startindex: number = 0,
  midIndex: number = -1
) => {
  let mIndex = midIndex;
  let sIndex = startindex;

  if (legs > 2 && mIndex < 0) {
    mIndex += 2;
  }

  for (let i: number = sIndex; i < input?.length; i++) {
    if (i !== sIndex && i > mIndex) {
      const tmpArr = [];

      tmpArr.push(sIndex);
      if (mIndex > 0) tmpArr.push(mIndex);
      tmpArr.push(i);

      result.push(tmpArr);
    }

    if (i === input.length - 1) {
      if (mIndex > 0 && sIndex < input.length - 3) {
        const newStartIndex =
          mIndex + 1 === input.length - 1 ? ++sIndex : sIndex;

        const nextMidIndex = getNewMidIndex(
          mIndex,
          input.length,
          newStartIndex
        );
        getCombos(legs, input, result, newStartIndex, nextMidIndex);
      } else if (legs <= 2 && sIndex < input.length - 2) {
        getCombos(legs, input, result, ++sIndex, mIndex);
      }
    }
  }

  return result;
};

export const getWinPlaceCombos = (
  wins: IBetslipBetType[],
  places: IBetslipBetType[]
) => {
  const final: IBetslipBetType[][] = [];

  wins?.forEach((item) => {
    places
      .filter((horse) => horse.eventDetailName !== item.eventDetailName)
      .forEach((placeItem) => {
        const combo = [];
        combo.push(item);
        combo.push(placeItem);
        final.push(combo);
      });
  });

  return final;
};

export const canSwitchWinPlace = (
  currentWinHorseName: string,
  places: IBetslipBetType[]
): boolean => {
  return !!places.find(
    (placeHorse) => placeHorse.eventDetailName === currentWinHorseName
  );
};

export const getCombo = (
  title: string,
  legsPerBet: number,
  totalStake: number,
  totalodds: number,
  payout: number,
  horses: IBetslipBetType[],
  punterId: number
) => {
  return {
    combinatonInfo: {
      betTitle: title,
      combinationBetTypeId: 3,
      legsPerBet,
      totalStake,
      totalOdds: totalodds,
      payout,
      guid: null,
      pid: punterId
    },
    dt: [...horses]
  };
};

export const getFormattedBet = (
  betTypesData: { type: string | null; data: ILuckyPickBetType | null }[],
  punterid: number
) => {
  const tmpBets = betTypesData.map((item) => {
    const { horses, stake, odds, payout } = item.data!;

    const legsPerBet =
      item.type === BET_TYPES_DISPLAY_NAMES.placeTreble ? 3 : 2;

    const correctBetType =
      item.type === "WIN DOUBLE" || item.type === "WIN/PLACE DOUBLE"
        ? "DOUBLES"
        : "TREBLES";

    return getCombo(
      correctBetType,
      legsPerBet,
      stake,
      odds,
      payout,
      horses,
      punterid
    );
  });

  const final = {
    bets: [...tmpBets],
    singleBet: {
      SingleWinStake: 0,
      SingleWinHorses: null,
      SinglePlaceStake: 0,
      SinglePlaceHorses: null,
      PID: punterid
    },
    isLuckyPick: true
  };

  return final;
};

const baseLPBet: IBetSlipBet = {
  betType: BetType.Default,
  betCount: 1,
  finalOdds: 0,
  stakeToBet: 0,
  enabled: true,
  isFixedOdds: true,
  legs: [],
  overLimit: false
};

export const getBetSlipSummaryFormat = (
  betTypeWinDouble: ILuckyPickBetType,
  betTypePlaceTreble: ILuckyPickBetType,
  betTypeWinPlaceDouble: ILuckyPickBetType
): IBetSlipBet[] => {
  const winDouble: IBetSlipBet = {
    ...baseLPBet,
    betType: BetType.LP_WinDoubles,
    finalOdds: betTypeWinDouble.odds,
    stakeToBet: betTypeWinDouble.stake,
    overLimit: betTypeWinDouble.isPayoutOverLimit
  };

  const winPlaceDouble: IBetSlipBet = {
    ...baseLPBet,
    betType: BetType.LP_WinPlaceDoubles,
    finalOdds: betTypeWinPlaceDouble.odds,
    stakeToBet: betTypeWinPlaceDouble.stake,
    overLimit: betTypeWinPlaceDouble.isPayoutOverLimit
  };

  const placeTreble: IBetSlipBet = {
    ...baseLPBet,
    betType: BetType.LP_PlaceTrebles,
    finalOdds: betTypePlaceTreble.odds,
    stakeToBet: betTypePlaceTreble.stake,
    overLimit: betTypePlaceTreble.isPayoutOverLimit
  };

  return [winDouble, placeTreble, winPlaceDouble];
};
