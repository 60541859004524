import theme from "../../../../theme";
import Button from "../../../bricks/button";
import Container from "../../../bricks/container";
import LeagueFormBase from "../modals/leagueFormBase";
import LeagueModalbase from "../modals/leagueModalBase";

type TLeagueSharedConfirmation = {
  backToHomeCallback: () => void;
};
const LeagueSharedConfirmation = ({
  backToHomeCallback
}: TLeagueSharedConfirmation) => {
  return (
    <LeagueModalbase closureCallback={backToHomeCallback}>
      <LeagueFormBase text="Code shared">
        <Container
          textalign="left"
          fontSize={theme.font.size.sm}
          color={theme.colors.grey}>
          Yes! Your code has been copied, you may go ahead and share with those
          you'd like to challenge.
        </Container>

        <Button
          margintop="20px"
          width="50%"
          background={`linear-gradient(
            ${theme.colors.purpleSecondary},
            ${theme.colors.purplePrimary}
            )`}
          text="Back to Leagues"
          fontSize="0.7rem"
          onClick={backToHomeCallback}
        />
      </LeagueFormBase>
    </LeagueModalbase>
  );
};

export default LeagueSharedConfirmation;
