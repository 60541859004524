import styled from "styled-components";
import theme from "../../../../../../../theme";
import Container from "../../../../../../bricks/container";
import FlexContainer from "../../../../../../bricks/flexContainer";

const AbandonedRaceText = styled.div`
  width: 75%;
  padding: 10px 14px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  font-size: ${theme.font.size.m};
  margin: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.meetingsLightGrey};
`;
interface IMeetingDropdown {
  color: string;
  borderColor: string;
  closureCallback?: () => void;
  contentToOpen: JSX.Element | JSX.Element[];
  isAbandoned: boolean;
}

const MeetingDropdownWeb = ({
  color,
  closureCallback,
  contentToOpen,
  borderColor,
  isAbandoned
}: IMeetingDropdown) => {
  return (
    <FlexContainer
      width="100%"
      direction="column"
      height="max-content"
      bgcolor={color}
      marginleft="0px"
      border={`0px solid ${borderColor}`}
      borderbottomleftradius="7px"
      borderbottomrightradius="7px"
      margintop="-5px"
      zIndex={2}
      boxshadow={`0px 4px 6px 0px${theme.colors.cardShadow}`}
      columngap="5px">
      {isAbandoned && (
        <Container
          display="flex"
          paddingbottom="0px"
          width="100%"
          height="max-content"
          bgcolor="white"
          bordertop="0"
          zIndex={1}>
          <AbandonedRaceText>
            When races are abandoned entries will be null and void and bets will
            get refunded.
          </AbandonedRaceText>
        </Container>
      )}
      {!isAbandoned && (
        <Container
          paddingbottom="0px"
          width="100%"
          height="max-content"
          bgcolor="white"
          bordertop="0"
          zIndex={1}>
          {contentToOpen}
        </Container>
      )}
      <Container
        width="100%"
        height="30px"
        margintop="-10px"
        bgcolor="white"
        borderbottomleftradius="7px"
        borderbottomrightradius="7px"
        zIndex={1}
        paddingtop="20px"
        display="grid"
        placecontent="center">
        <Container
          cursor="pointer"
          onClick={closureCallback}
          width="max-content">
          Close
        </Container>
      </Container>
    </FlexContainer>
  );
};

export default MeetingDropdownWeb;
