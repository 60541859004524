/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexContainer from "../components/bricks/flexContainer";
import { getCMSData } from "../store/features/pagesSlice";
import type { RootState } from "../store/store";
import "./styles/pagestyles.css";
import theme from "../theme";
import { TC_IDENTIFIER_TEXT, TC_ERROR_MESSAGE } from "../constants";

const TermsAndConditions = () => {
  const dispatch = useDispatch();

  const { cmsData, cmsErrorText } = useSelector(
    (state: RootState) => state.pages
  );

  const [bodyHtmlText, setBodyHtmlText] = useState<string>("");

  useEffect(() => {
    if (!cmsData) {
      dispatch(getCMSData());
    } else {
      setBodyHtmlText(cmsData[TC_IDENTIFIER_TEXT].body);
    }
  }, []);
  useEffect(() => {
    if (!bodyHtmlText && cmsData)
      setBodyHtmlText(cmsData[TC_IDENTIFIER_TEXT].body);
  }, [cmsData]);
  return (
    <FlexContainer
      height="70vh"
      direction="column"
      alignitems="center"
      rowgap="5px"
      bgcolor={theme.colors.bodyPrimary}
      paddingbottom="10px">
      {cmsErrorText && <span data-testid="tncError">{TC_ERROR_MESSAGE}</span>}

      <div
        data-testid="tncContent"
        className="TandC"
        dangerouslySetInnerHTML={{ __html: bodyHtmlText }}
      />
    </FlexContainer>
  );
};

export default TermsAndConditions;
