import HomeBgImage from "@assets/home-page-background.jpg";
import HomeBgImageMob from "@assets/home-page-background_mob.jpg";
import HomeBgImagePlaceHolder from "@assets/placeholders/home-page-background-ph.jpg";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import Container from "../components/bricks/container";
import FlexContainer from "../components/bricks/flexContainer";
import HomePrelogin from "../components/views/newHomePage/preLogin/homePreLogin/homePreLogin";
import { RootState } from "../store/store";
import HomePostLogin from "../components/views/newHomePage/postLogin/homePostLogin";
import TakeaBetModal from "../components/views/modals/takeAbetModal";
import { NotificationContext } from "../contexts/notificationContext";
import {
  getPayoutLimits,
  setInitialState
} from "../store/features/newBetSlipSlice";
import {
  getBetslipData,
  toggleLuckyPick
} from "../store/features/betslipSlice";
import { LoadingDiv, LoadingParent } from "./home.style";
import theme from "../theme";
import {
  IMeetingRace,
  IMeetingSelections,
  IRaceRunner,
  getMeetingProgressive,
  getUpcomingMeetings
} from "../store/features/meetingsSlice";
import {
  CachedSelectedRaces,
  findFavoriteHorse,
  MapToBet,
  MapToMeetingSelections
} from "../components/views/newHomePage/postLogin/race-card-selections/selection/selectionsUtil";
import BetSlipNew from "../components/views/betslipNew/betslip";
import useProgressiveImage from "../hooks/useProgressiveImage";
import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import { isHandHeldDevice } from "../utils/deviceUtils";
import { GetSettings } from "../store/features/settingsSlice";
import AliasModal from "../components/views/modals/alias-modal/aliasModal";
import { isPast } from "../utils/dateUtils";
import { SELECTION_CUTOFF_TIME_IN_MINUTES } from "../constants";

const HomeNew = () => {
  const { notificationUpdater } = useContext(NotificationContext);

  const scrollContainerRef = useRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const { loadedImage } = useProgressiveImage(
    isHandHeldDevice() ? HomeBgImageMob : HomeBgImage,
    HomeBgImagePlaceHolder
  );

  const {
    bets: apiBets,
    placebets: apiPlacebets,
    spbets: apiSpbets,
    error: errorFetchingBetslip,
    isFetching: isWaitingForBetslip
  } = useSelector((state: RootState) => state.betslip);
  const { meetingsMetaData, meetings } = useSelector(
    (state: RootState) => state.meetings
  );
  const { punterId, userName, isLoggedIn, hasCheckedLoggedIn } = useSelector(
    (state: RootState) => state.user
  );
  const { showNewBetSlip } = useSelector(
    (state: RootState) => state.config.data
  );
  const { limits } = useSelector((state: RootState) => state.betslipNew);
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const [currentOpenMeetingId, setCurrentOpenMeetingId] = useState<number>(0);

  const [isBetDataBeingFetched, setIsBetDataBeingFetched] =
    useState<boolean>(false);
  const [toShowTakeABetModal, setToShowTakeABetModal] =
    useState<boolean>(false);
  const [toOpenBetslip, setToOpenBetslip] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getPayoutLimits());
    dispatch(GetSettings());
    if (!meetingsMetaData) dispatch(getUpcomingMeetings());
  }, []);

  useEffect(() => {
    if (isBetDataBeingFetched && !isWaitingForBetslip) {
      if (apiBets?.length || apiPlacebets?.length) {
        setToOpenBetslip(true);
      } else {
        notificationUpdater({
          status: "error",
          message: "Betslip not avalilable at the moment."
        });
      }
      setIsBetDataBeingFetched(false);
    }
  }, [apiBets, apiPlacebets, errorFetchingBetslip, isWaitingForBetslip]);

  const races: IMeetingRace[] = meetings[currentOpenMeetingId]?.races;

  const getCookieDataMappedToSelections = (cookieData: IRaceRunner[]) => {
    return MapToMeetingSelections(
      cookieData.toSorted((race1, race2) => {
        return race1.raceNumber > race2.raceNumber ? 1 : -1;
      })
    );
  };

  const updatedCookieList = () => {
    const updatedCookieData: IRaceRunner[] = [];
    const cookieData = CachedSelectedRaces(currentOpenMeetingId);
    const isPending = isPast(
      races[0]?.raceTime.toString(),
      SELECTION_CUTOFF_TIME_IN_MINUTES
    );

    if (!isPending) return cookieData;

    cookieData.forEach((cookieHorse) => {
      const currentRace = races?.find(
        (race) => race.raceNumber === cookieHorse.raceNumber
      );

      if (cookieHorse.runnerStatus !== 1 && currentRace) {
        const favHorse = findFavoriteHorse(currentRace.runners);

        if (favHorse) {
          updatedCookieData.push(favHorse);
        }
      } else {
        updatedCookieData.push(cookieHorse);
      }
    });

    return updatedCookieData;
  };

  const fetchBetslipData = () => {
    const cookieData = updatedCookieList();
    const submissionData: IMeetingSelections[] =
      getCookieDataMappedToSelections(cookieData);

    if (submissionData) setIsBetDataBeingFetched(true);

    dispatch(
      getBetslipData({
        selection: submissionData,
        punterId,
        userName,
        meetingId: currentOpenMeetingId
      })
    );
  };

  const onAcceptTakeABet = () => {
    setToShowTakeABetModal(false);

    fetchBetslipData();
  };

  const onCancelTakeAbetModal = () => {
    setToShowTakeABetModal(false);
  };

  const scrollToNextFoldOnPreLogin = useCallback(() => {
    scrollContainerRef.current?.scroll({
      top: scrollContainerRef.current.offsetHeight,
      behavior: "smooth"
    });
  }, [scrollContainerRef.current]);

  const scrollToTopLogin = useCallback(() => {
    scrollContainerRef.current?.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, [scrollContainerRef.current]);

  const blankBetslip = () => {
    dispatch(
      setInitialState({
        allowBet: false,
        bets: [],
        meeting: {
          meetingDate: "",
          meetingId: 0,
          meetingName: "EMPTY"
        },
        totalStake: 0,
        totalReturn: 0,
        betStrikeResponse: null,
        forcedSP: false,
        races: [],
        limits
      })
    );
  };

  const betSlipClosureCallback = () => {
    setToOpenBetslip(false);
    blankBetslip();

    dispatch(toggleLuckyPick(false));
  };

  const onMeetingOpened = (newId: number) => {
    setCurrentOpenMeetingId(newId);
  };

  return (
    <FlexContainer
      backgroundImage={`url(${loadedImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      height="calc(100% - 66px)"
      justifycontent="center"
      position="relative">
      <Container
        bgcolor="black"
        width="100%"
        height="100%"
        opacity={0.25}
        position="absolute"
      />

      {showNewBetSlip && toOpenBetslip && (
        <BetSlipNew
          bets={MapToBet(apiBets)}
          placeBets={MapToBet(apiPlacebets)}
          spBets={MapToBet(apiSpbets)}
          backToBetSlipCallback={fetchBetslipData}
          closureCallback={betSlipClosureCallback}
          shouldToggleBackground
          currentMeetingId={currentOpenMeetingId}
        />
      )}

      {toShowTakeABetModal && (
        <TakeaBetModal
          show
          acceptCallback={onAcceptTakeABet}
          cancelCallback={onCancelTakeAbetModal}
          shadowStartHeight={0}
          translationY={15}
        />
      )}

      {isBetDataBeingFetched && (
        <LoadingParent>
          <LoadingDiv />
          <ReactLoading
            type="spokes"
            color={theme.colors.aquaSecondary}
            height="20px"
            width="20px"
          />
        </LoadingParent>
      )}

      <AliasModal />

      {!hasCheckedLoggedIn && (
        <Container
          position="absolute"
          bgcolor="transparent"
          width="100%"
          height="60vh"
          display="grid"
          placecontent="center"
          paddingtop="10px"
          paddingbottom="10px">
          <ReactLoading
            type="spokes"
            color={theme.colors.purpleLight}
            height={isDesktop ? "15vw" : "40vw"}
            width={isDesktop ? "15vw" : "40vw"}
          />
        </Container>
      )}

      {hasCheckedLoggedIn && (
        <Container
          ref={scrollContainerRef}
          bgcolor="none"
          width="100%"
          height="100%"
          overflow="auto"
          scrollbarWidth="thin"
          zIndex={0}>
          {isLoggedIn ? (
            <HomePostLogin
              onSelectionMade={blankBetslip}
              onSubmitClicked={() => {
                setToShowTakeABetModal(true);
                if (currentOpenMeetingId !== 0) {
                  dispatch(
                    getMeetingProgressive({ meetingId: currentOpenMeetingId })
                  );
                }
              }}
              onTakeABetClicked={onAcceptTakeABet}
              scrollerCallback={scrollToTopLogin}
              onMeetingOpened={onMeetingOpened}
              scrollerCallbackTop={scrollToNextFoldOnPreLogin}
            />
          ) : (
            <HomePrelogin
              scrollerCallback={scrollToNextFoldOnPreLogin}
              scrollToTopCallback={scrollToTopLogin}
            />
          )}
        </Container>
      )}
    </FlexContainer>
  );
};

export default HomeNew;
