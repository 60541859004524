import { type BaseSyntheticEvent } from "react";
import {
  InputContainer,
  InputDiv,
  LabelDiv,
  LabelText,
  LabledInputContainer
} from "./labledInput.style";

interface ILabelledInput {
  label?: string;
  placeholder?: string;
  valueUpdater?: (value: string) => void;
  reference?: any;
  isPassword?: boolean;
  focus?: boolean;
  width?: string;
  height?: string;
  marginTop?: string;
  align?: string;
  disable?: boolean;
}

export const LabeledInput = ({
  label,
  placeholder,
  valueUpdater,
  reference,
  isPassword,
  focus,
  width,
  height,
  marginTop,
  align,
  disable
}: ILabelledInput) => {
  const onChange = (event: BaseSyntheticEvent) => {
    if (valueUpdater) valueUpdater(event.target?.value);
  };

  return (
    <LabledInputContainer width={width} marginTop={marginTop}>
      {label?.length! > 0 && (
        <LabelDiv>
          <LabelText>{label}</LabelText>
        </LabelDiv>
      )}
      <InputDiv height={height}>
        <InputContainer
          disabled={disable}
          ref={reference}
          type={isPassword ? "password" : "text"}
          onChange={onChange}
          name="account-number-text"
          placeholder={placeholder}
          autoFocus={focus}
          textAlign={align}
        />
      </InputDiv>
    </LabledInputContainer>
  );
};

export default LabeledInput;
