import { useContext } from "react";
import SelectionHeaderItem, {
  ISelectionHeaderData
} from "./selectionHeaderItem";
import {
  SelectionHeaderDiv,
  CornerHeaderDiv,
  NumberHeaderSpan
} from "./selectionHeader.syles";
import { MediaQueriesContext } from "../../../../../../../contexts/mediaQueriesContext";

interface ISelectionHeader {
  headers: ISelectionHeaderData[];
}

const SelectionHeader = ({ headers }: ISelectionHeader) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const headerItems = headers?.map(
    ({ raceNumber, raceTime, puntersRemaining }) => (
      <SelectionHeaderItem
        key={`header_${raceNumber}`}
        raceNumber={raceNumber}
        raceTime={raceTime}
        puntersRemaining={puntersRemaining}
      />
    )
  );

  return (
    <SelectionHeaderDiv isDesktop={isDesktop}>
      <CornerHeaderDiv>
        <NumberHeaderSpan>#</NumberHeaderSpan>
        <span style={{ fontSize: "0.7rem" }}>Punters Remaining</span>
      </CornerHeaderDiv>

      {headerItems}
    </SelectionHeaderDiv>
  );
};

export default SelectionHeader;
