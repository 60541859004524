import { useContext, useEffect } from "react";
import { LuckyPickContext } from "../../context/luckyPickContext";
import Container from "../../../../bricks/container";
import theme from "../../../../../theme";
import BetTypeView from "./betTypeView/betTypeView";
import { BET_TYPES } from "../../constants/luckyPickConstants";
import Consolidation from "./consolidation/consolidation";
import HorsePick from "./horsePick";

const Selections = () => {
  const { betTypePlaceTreble, horseSelectionOpenData, betTypeWinPlaceDouble } =
    useContext(LuckyPickContext).lpState;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Container position="relative">
      <Container
        border={`2px solid ${theme.colors.purplePrimary}`}
        borderbottomrightradius="5px"
        borderbottomleftradius="5px"
        bgcolor="white"
        width="auto">
        <BetTypeView title={BET_TYPES.WIN_DOUBLE} type={BET_TYPES.WIN_DOUBLE} />
        {betTypePlaceTreble?.horses.length > 0 && (
          <BetTypeView
            title={BET_TYPES.PLACE_TREBLE}
            type={BET_TYPES.PLACE_TREBLE}
          />
        )}
        {betTypeWinPlaceDouble?.horses.length > 0 && (
          <BetTypeView
            title={BET_TYPES.WIN_PLACE_DOUBLE}
            type={BET_TYPES.WIN_PLACE_DOUBLE}
          />
        )}
        {/* 
        To be removed when business decides they dont need it anymore
        This commented out code is approved by SDL and TechLead
        <DistributeView /> */}
        <Consolidation />
      </Container>
      {horseSelectionOpenData && (
        <Container
          border={`2px solid ${theme.colors.purplePrimary}`}
          borderbottomrightradius="5px"
          borderbottomleftradius="5px"
          width="99%"
          position="absolute"
          top="0px"
          bgcolor="transparent">
          <Container
            width="100% "
            height="100%"
            position="absolute"
            bgcolor={theme.colors.primary}
            style={{ opacity: 0.8 }}
          />

          {horseSelectionOpenData && <HorsePick />}
        </Container>
      )}
    </Container>
  );
};

export default Selections;
