/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { IPunterResults } from "./resultsSlice";
import { IPunterQueryPageLeanInputData } from "./leaderboardSlice";

const ERROR_GENERIC_MESSAGE: string =
  "There was an error in retrieving data, please try again after some time.";

export enum UPDATE_LEAGUE_ACTION_CODE_MAP {
  DISABLE = 0,
  APPROVED = 1,
  REJECTED = 2,
  REMOVED = 3,
  EXITED = 4
}

interface ILeagueNotificationItem {
  leagueNotificationId: number;
  notificationType: number;
  punterId: number;
  message: string;
  createdDate: string;
}

interface ILeagueMemberItem {
  punterId: number;
  punterAlias: string;
}

export interface IMyLeagueItem {
  leagueName: string;
  rank: number;
  leagueCode: string;
  leagueStatus: boolean;
  punterLeagueStatus: number;
  punterOwnerId: number;
  totalPlayers: number;
  unreadNotifications: number;
}

export interface IJoinedLeagueItem extends IMyLeagueItem {}

interface IFriendsLeagueCreateQueryData {
  punterId: number;
  leagueName: string;
}

interface IFriendsLeagueJoinALeagueQueryData {
  punterId: number;
  leagueCode: string;
}

interface IFriendsLeagueUpdateALeagueQueryData {
  punterId: number;
  leagueCode: string;
  action: number;
}

interface IGetLeagueNotificationsQueryData
  extends IFriendsLeagueJoinALeagueQueryData {}

interface IGetLeaguesLederBoardQueryData {
  punterId: number;
  leagueCode: string;
  meetingId: number;
  pageNumber: number;
}

interface IGetLeagueLeaderboardDetailsQueryData {
  punterId: number;
}

interface IGetPunterLeaguesQueryData
  extends IGetLeagueLeaderboardDetailsQueryData {}

interface ICurrentMeetingForLeaderboard {
  id: number;
  name: string;
}

interface IGetLeagueMembersQueryData {
  leagueCode: string;
}

interface ILeagueStatsDataQueryData {
  punterId: number;
  raceCourseId: number;
  date: string;
}

interface IDeleteALeagueQueryData {
  punterId: number;
  leagueCode: string;
  newStatus: number;
}

interface INewleagueCreationData {
  requestedLeagueToCreate: string | null;
  leagueCreatedJustNow: string | null;
  createdLeagueCode: string | null;
  errorInLeagueCreation: string | null;
}

interface IShareLeagueData {
  leagueToShare: string;
}

interface IJoinALeagueData {
  leagueCode: string;
  leagueName: string;
  joinRequestSuccessful: boolean;
  errorInJoinLeague: string | null;
}

export interface ILeagueLeaderboardItem {
  punterId: number;
  leagueRank: number;
  punterAlias: string;
  meetingPoints: number;
  winners: number;
  secondPlaces: number;
  thirdPlaces: number;
}

export interface ILeagueStatsMeeting {
  raceCourseId: number;
  raceCourseName: string;
}

interface IStandardLeaderboardData {
  meetingId: string;
  hasResulted: boolean;
}

export interface ILeagueStatsData {
  punterID: number;
  totalSelections: number;
  totalWins: number;
  winPercentage: number;
  winners: number;
  selections: number;
  top10: number;
  top50: number;
  top500: number;
  top1000: number;
  maxWinPercentage: number;
  overallAverageWinPercentageAllPunters: number;
  overallWinners: number;
  overallSelections: number;
  bestWinsByPunterWinners: number;
  bestWinsByPunterSelections: number;
  bestPosition: number;
  bestPositionSelections: number;
  puntersOutPerformed: number;
  highScore: number;
  highestScoreByAnyPunter: number;
  raceCourseName: string;
  raceCourseNameBestWinPercentage: string;
  bestWinsByPunterRaceCourseName: string;
  bestWinsByPunterDate: string;
  bestPositionRaceCourseName: string;
  bestPositionDate: string;
  highestScoreByAnyPunterRaceCourseName: string;
  highestScoreByAnyPunterDate: string;
}

export interface ILeagueLeaderboardPuntersDataItem {
  raceNumber: number;
  saddle: number;
  horseName: string;
  points: number;
  winners: number;
  racePosition: number;
}

export interface IPunterSearchRequest {
  meetingId: string;
  pageId: string;
  numberPerPage: string;
  searchFor: string;
}

export interface IFriendsLeague {
  myLeagues: IMyLeagueItem[] | null;
  joinedLeagues: IJoinedLeagueItem[] | null;
  newLeagueCreationData: INewleagueCreationData | null;
  shareALeagueData: IShareLeagueData | null;
  joinALeagueData: IJoinALeagueData | null;
  currentLeagueToManage: IMyLeagueItem | null;
  toDisplayManageForLeague: string | null;
  currentNotifications: ILeagueNotificationItem[] | null;
  currentLeagueMembers: ILeagueMemberItem[] | null;
  toForceUpdateNotifications: boolean;
  errorMessageOnRejection: string | null;
  currentMeetingForLeaderboard: ICurrentMeetingForLeaderboard | null;
  currentLeagueForLeaderboard: IMyLeagueItem | null;
  currentLeaderboardPageData: ILeagueLeaderboardItem[] | null;
  currentLeaderboardPunterDetail: ILeagueLeaderboardPuntersDataItem[] | null;
  currentLeaderboardTotalPages: number;
  currentLeaderboardLastRank: number;
  currentPageNumber: number;
  isFetchingLeaderboard: boolean;
  statsMeetings: ILeagueStatsMeeting[] | null;
  leagueStatsData: ILeagueStatsData | null;
  isQueriedLeagueToJoinChecked: boolean;
  leaderboardMaxPages: number;
  mainLeaderboardStatusData: {
    [meetingid: string]: IStandardLeaderboardData;
  } | null;
}

const initialState: IFriendsLeague = {
  myLeagues: null,
  joinedLeagues: [],
  newLeagueCreationData: null,
  shareALeagueData: null,
  joinALeagueData: null,
  currentLeagueToManage: null,
  toDisplayManageForLeague: null,
  currentNotifications: null,
  currentLeagueMembers: null,
  toForceUpdateNotifications: false,
  errorMessageOnRejection: null,
  currentMeetingForLeaderboard: null,
  currentLeagueForLeaderboard: null,
  currentLeaderboardPageData: null,
  currentLeaderboardPunterDetail: null,
  currentLeaderboardTotalPages: 0,
  currentLeaderboardLastRank: 0,
  currentPageNumber: 0,
  isFetchingLeaderboard: false,
  statsMeetings: null,
  leagueStatsData: null,
  isQueriedLeagueToJoinChecked: false,
  leaderboardMaxPages: 0,
  mainLeaderboardStatusData: null
};

const configConst = (state: RootState) => state.config.data;

/**
 *
 */
export const getPunterLeagues: any = createAsyncThunk(
  "getPunterLeagues",
  async ({ punterId }: IGetPunterLeaguesQueryData, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );

    return data.data;
  }
);

/**
 *
 */
export const createLeague: any = createAsyncThunk(
  "createLeague",
  async (
    { punterId, leagueName }: IFriendsLeagueCreateQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);
    const data = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/CreateLeague`,
      { punterId, leagueName }
    );

    return data.data;
  }
);

const getUrlForUpdatePunterLeagueStatus = (
  state: RootState,
  punterId: number,
  leagueCode: string,
  action?: number
) => {
  const config = configConst(state);
  const payLoad = action
    ? { punterId, leagueCode, punterLeagueStatus: action }
    : { punterId, leagueCode };

  return axios.post(
    `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
    payLoad
  );
};

/**
 *
 */
export const joinALeague: any = createAsyncThunk(
  "joinALeague",
  async (
    { punterId, leagueCode }: IFriendsLeagueJoinALeagueQueryData,
    { getState }
  ) => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode
    );

    return data.data;
  }
);
/**
 *
 */
export const updateALeague: any = createAsyncThunk(
  "updateALeague",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    return data.data;
  }
);
/**
 *
 */
export const removeALeagueMember: any = createAsyncThunk(
  "removeALeagueMember",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    const newList = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueTeamMembers/${leagueCode}`
    );

    return { removed: data, updatedList: newList?.data?.leagueMembers };
  }
);

/**
 *
 */
export const approveAJoinRequest: any = createAsyncThunk(
  "approveAJoinRequest",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    return data.data;
  }
);

/**
 *
 */
export const rejectAJoinRequest: any = createAsyncThunk(
  "rejectAJoinRequest",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);
    const data = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
      { punterId, leagueCode, punterLeagueStatus: action }
    );

    return data.data;
  }
);
/**
 *
 */
export const exitALeague: any = createAsyncThunk(
  "exitALeague",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);
    const exitData = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
      { punterId, leagueCode, punterLeagueStatus: action }
    );

    const updatedList = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );

    return { exitData: exitData.data, updatedList: updatedList.data };
  }
);

/**
 *
 */
export const getLeagueNotifications: any = createAsyncThunk(
  "getLeagueNotifications",
  async (
    { leagueCode, punterId }: IGetLeagueNotificationsQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/getLeagueNotifications/${punterId}/${leagueCode}`
    );

    return data.data;
  }
);
/**
 *
 */

export const getLeagueMembers: any = createAsyncThunk(
  "getLeagueMembers",
  async ({ leagueCode }: IGetLeagueMembersQueryData, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueTeamMembers/${leagueCode}`
    );

    return data.data;
  }
);

export const getLeagueLeaderboard: any = createAsyncThunk(
  "getLeagueLeaderboard",
  async (
    {
      leagueCode,
      punterId,
      meetingId,
      pageNumber
    }: IGetLeaguesLederBoardQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueLeaderboard/${punterId}/${meetingId}/${leagueCode}/${pageNumber}`
    );

    return { ...data.data, pageNumber };
  }
);

export const getLeagueLeaderboardDetails: any = createAsyncThunk(
  "getLeagueLeaderboardDetails",
  async ({ punterId }: IGetLeagueLeaderboardDetailsQueryData, { getState }) => {
    const rootState = getState() as RootState;

    const config = configConst(rootState);

    console.log(rootState.friendsLeague.currentMeetingForLeaderboard);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeaderboardDetailsForPunter/${rootState.friendsLeague.currentMeetingForLeaderboard?.id}/${punterId}`
    );

    return data.data;
  }
);

export const getPunterInfoFromSearch: any = createAsyncThunk(
  "getPunterInfoFromSearch",
  async (
    { pageId, meetingId, numberPerPage, searchFor }: IPunterSearchRequest,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${
        config.puntersApi
      }/api/Leaderboard/GetDailyLeaderboardPaged/${pageId}/${numberPerPage}/${meetingId}?username=${
        searchFor || ""
      }`
    );

    return data.data.dailyLeaderboard.punters;
  }
);

export const getStatsMeetings: any = createAsyncThunk(
  "getStatsMeetings",
  async (_, { getState }) => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    console.log(rootState.friendsLeague.currentMeetingForLeaderboard);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetStatsMeetings`
    );

    return data.data;
  }
);

export const getStatsData: any = createAsyncThunk(
  "getStatsData",
  async (
    { punterId, raceCourseId, date }: ILeagueStatsDataQueryData,
    { getState }
  ) => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterStats/${punterId}/${raceCourseId}/${date}`
    );

    return data.data;
  }
);

export const deleteALeague: any = createAsyncThunk(
  "deleteALeague",
  async (
    { punterId, leagueCode, newStatus }: IDeleteALeagueQueryData,
    { getState }
  ) => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    await axios.post(`${config.puntersApi}/api/MiniLeagues/UpdateLeague`, {
      punterOwnerId: punterId,
      leagueCode,
      leagueStatus: newStatus
    });

    const updatedListOfLeagues = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );

    return { updatedList: updatedListOfLeagues?.data };
  }
);

export const checkHasLeaderboardDataForMeeting: any = createAsyncThunk(
  "checkHasLeaderboardDataForMeeting",
  async (
    { punterId, meetingId }: IPunterQueryPageLeanInputData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Leaderboard/GetDailyLeaderboard/${
        punterId || 0
      }/${meetingId}`
    );

    return { data: data.data, meetingid: meetingId };
  }
);

const FriendLeagueSlice = createSlice({
  name: "FriendLeague",
  initialState,
  reducers: {
    resetLeagueCreationData: (state) => {
      state.newLeagueCreationData = null;
    },
    resetJoinALeagueData: (state) => {
      state.joinALeagueData = null;
    },
    shareALeague: (state, action) => {
      if (action.payload) {
        state.shareALeagueData = { leagueToShare: action.payload };
      } else {
        state.shareALeagueData = null;
      }
    },
    displayManageForLeague: (state, action) => {
      state.currentNotifications = null;
      state.toDisplayManageForLeague = action.payload ?? null;

      if (state.toDisplayManageForLeague) {
        state.currentLeagueToManage = state.myLeagues?.find(
          (item) => item.leagueName === state.toDisplayManageForLeague
        )!;
      } else {
        state.currentLeagueToManage = null;
        state.currentNotifications = null;
      }
    },
    reset: (state) => {
      state.myLeagues = null;
      state.joinedLeagues = [];
      state.newLeagueCreationData = null;
      state.shareALeagueData = null;
      state.joinALeagueData = null;
      state.currentLeagueToManage = null;
      state.toDisplayManageForLeague = null;
      state.currentNotifications = null;
      state.currentLeagueMembers = null;
      state.toForceUpdateNotifications = false;
      state.currentMeetingForLeaderboard = null;
      state.currentLeagueForLeaderboard = null;
      state.currentLeaderboardPageData = null;
      state.currentLeaderboardPunterDetail = null;
      state.currentLeaderboardTotalPages = 0;
      state.isFetchingLeaderboard = false;
      state.currentPageNumber = 0;
    },
    openLeaderboard: (state, action) => {
      let indentifiedLeague = state.myLeagues?.find(
        (league) => league.leagueName === action.payload
      )!;
      if (!indentifiedLeague)
        indentifiedLeague = state.joinedLeagues?.find(
          (league) => league.leagueName === action.payload
        )!;

      state.currentLeagueForLeaderboard = action.payload
        ? indentifiedLeague
        : null;
      state.currentLeaderboardTotalPages = 0;
    },
    closeLeaderboard: (state) => {
      state.currentMeetingForLeaderboard = null;
      state.currentLeagueForLeaderboard = null;
      state.currentLeaderboardPageData = null;
      state.currentLeaderboardPunterDetail = null;
      state.currentLeaderboardTotalPages = 0;
      state.isFetchingLeaderboard = false;
      state.currentPageNumber = 0;
    },
    setCurrentMeeting: (state, action) => {
      state.currentMeetingForLeaderboard = action.payload ?? null;
    },
    setIsQueriedLeagueToJoinChecked: (state, action) => {
      state.isQueriedLeagueToJoinChecked = action.payload ?? null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPunterLeagues.pending, (state) => {
      state.errorMessageOnRejection = null;
    });

    builder.addCase(getPunterLeagues.fulfilled, (state, action) => {
      state.myLeagues = action.payload.ownedLeagues;

      state.joinedLeagues = action.payload.joinedLeagues;
    });
    builder.addCase(getPunterLeagues.rejected, (state, action) => {
      state.errorMessageOnRejection = ERROR_GENERIC_MESSAGE;
      console.log(
        "getPunterLeagues.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(createLeague.pending, (state, action) => {
      state.newLeagueCreationData = {
        requestedLeagueToCreate: action.meta.arg.leagueName,
        leagueCreatedJustNow: null,
        errorInLeagueCreation: null,
        createdLeagueCode: null
      };
      state.errorMessageOnRejection = null;
      console.log("createLeague.pending state");
    });

    builder.addCase(createLeague.fulfilled, (state, action) => {
      const {
        responseStatus,
        responseMessage,
        responseMessageDetail,
        createdLeague
      } = action.payload;

      if (
        responseStatus === 1 &&
        responseMessage === "Success" &&
        responseMessageDetail === "Success" &&
        createdLeague.leagueName ===
          state.newLeagueCreationData!.requestedLeagueToCreate
      ) {
        state.newLeagueCreationData!.leagueCreatedJustNow =
          createdLeague.leagueName;
        state.newLeagueCreationData!.createdLeagueCode =
          createdLeague.leagueCode;
      } else {
        state.newLeagueCreationData!.errorInLeagueCreation =
          responseMessageDetail;
      }

      console.log("createLeague.fulfilled state");
    });
    builder.addCase(createLeague.rejected, (state, action) => {
      console.log(
        "createLeague.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(joinALeague.pending, (state, action) => {
      state.joinALeagueData = {
        leagueCode: action.meta.arg.leagueCode,
        joinRequestSuccessful: false,
        errorInJoinLeague: null,
        leagueName: ""
      };

      console.log("joinALeague.pending state");
    });

    builder.addCase(joinALeague.fulfilled, (state, action) => {
      const {
        responseStatus,
        responseMessage,
        leagueName,
        responseMessageDetail,
        errorCode
      } = action.payload;

      if (responseStatus === 1 && responseMessage === "Success.") {
        state.joinALeagueData!.leagueName = leagueName;
        state.joinALeagueData!.joinRequestSuccessful = true;
      } else {
        let errorMsg = "The request to join was not successful";

        if (errorCode === 108 || errorCode === 109 || errorCode === 110) {
          errorMsg = responseMessageDetail;
        }
        state.joinALeagueData!.errorInJoinLeague = errorMsg;
      }
      console.log("joinALeague.fulfilled state");
    });

    builder.addCase(joinALeague.rejected, (state, action) => {
      state.joinALeagueData!.errorInJoinLeague =
        "The request to join was not successful";

      console.log(
        "joinALeague.rejected state ,reason = ",
        action.error.message
      );
    });
    builder.addCase(getLeagueNotifications.pending, (state) => {
      state.toForceUpdateNotifications = false;
      state.errorMessageOnRejection = null;
      console.log("getLeagueNotifications.pending state");
    });
    builder.addCase(getLeagueNotifications.fulfilled, (state, action) => {
      console.log(action.payload);

      if (state.toDisplayManageForLeague) {
        state.currentNotifications = [...action.payload.leagueNotifications];
      }

      console.log("getLeagueNotifications.fulfilled state");
    });
    builder.addCase(getLeagueNotifications.rejected, (state, action) => {
      console.log(action.payload);

      state.errorMessageOnRejection = ERROR_GENERIC_MESSAGE;

      console.log(
        "getLeagueNotifications.fulfilled state",
        action.error.message
      );
    });

    builder.addCase(getLeagueMembers.pending, (state) => {
      state.currentLeagueMembers = null;
      console.log("getLeagueMembers.pending state");
    });

    builder.addCase(getLeagueMembers.fulfilled, (state, action) => {
      console.log(action.payload);

      if (state.toDisplayManageForLeague) {
        state.currentLeagueMembers = [...action.payload.leagueMembers];
      }

      console.log("getLeagueNotifications.fulfilled state");
    });

    builder.addCase(removeALeagueMember.fulfilled, (state, action) => {
      const { updatedList } = action.payload;

      console.log(action.payload);

      state.currentLeagueMembers = [...updatedList];

      console.log("removeALeagueMember.fulfilled state");
    });

    builder.addCase(approveAJoinRequest.fulfilled, (state, action) => {
      const { errorCode, responseMessage, responseStatus } = action.payload;

      console.log(action.payload);

      if (
        errorCode === 0 &&
        responseMessage === "Success." &&
        responseStatus === 1
      ) {
        state.toForceUpdateNotifications = true;
      }

      console.log("approveAJoinRequest.fulfilled state");
    });

    builder.addCase(rejectAJoinRequest.fulfilled, (state, action) => {
      const { errorCode, responseMessage, responseStatus } = action.payload;

      console.log(action.payload);

      if (
        errorCode === 0 &&
        responseMessage === "Success." &&
        responseStatus === 1
      ) {
        state.toForceUpdateNotifications = true;
      }

      console.log("rejectAJoinRequest.fulfilled state");
    });

    builder.addCase(exitALeague.fulfilled, (state, action) => {
      const { exitData, updatedList } = action.payload;

      const { errorCode, responseMessage, responseStatus } = exitData;

      console.log(action.payload);

      if (
        errorCode === 0 &&
        responseMessage === "Success." &&
        responseStatus === 1
      ) {
        state.myLeagues = updatedList.ownedLeagues;

        state.joinedLeagues = updatedList.joinedLeagues;
      }
      console.log("exitALeague.fulfilled state");
    });

    builder.addCase(getLeagueLeaderboard.pending, (state, action) => {
      state.currentPageNumber = action.meta.arg.pageNumber;
      state.isFetchingLeaderboard = true;
    });

    builder.addCase(getLeagueLeaderboard.fulfilled, (state, action) => {
      const {
        responseMessage,
        responseStatus,
        currentLeaderboard,
        maxPages,
        pageNumber
      } = action.payload;

      if (responseMessage === "Success." && responseStatus === 1) {
        state.currentLeaderboardPageData = currentLeaderboard;
        state.currentLeaderboardTotalPages = maxPages;

        if (maxPages === pageNumber) {
          state.currentLeaderboardLastRank =
            currentLeaderboard[currentLeaderboard.length - 1].leagueRank;
        } else {
          state.currentLeaderboardLastRank = 0;
        }
      }

      state.isFetchingLeaderboard = false;
      console.log("getLeagueLeaderboard.fulfilled state");
    });

    builder.addCase(getLeagueLeaderboardDetails.fulfilled, (state, action) => {
      const { responseMessage, responseStatus, punterLeaderboardDetails } =
        action.payload;

      if (responseMessage === "Success." && responseStatus === 1) {
        state.currentLeaderboardPunterDetail = punterLeaderboardDetails;
      }

      console.log("getLeagueLeaderboard.fulfilled state");
    });
    builder.addCase(getStatsMeetings.fulfilled, (state, action) => {
      const { responseMessage, responseStatus, leagueStatsMeetings } =
        action.payload;

      if (responseMessage === "Success." && responseStatus === 1) {
        state.statsMeetings = leagueStatsMeetings;
      }

      console.log("getLeagueLeaderboard.fulfilled state");
    });

    builder.addCase(getPunterInfoFromSearch.pending, (state) => {
      state.currentLeaderboardPageData = [];
      state.isFetchingLeaderboard = true;
    });

    builder.addCase(getPunterInfoFromSearch.fulfilled, (state, action) => {
      const response = action.payload;
      const punterInfo: ILeagueLeaderboardItem[] = [];

      response.forEach((punter: IPunterResults) => {
        punterInfo.push({
          punterId: punter.punterId,
          leagueRank: punter.rank,
          punterAlias: punter.userId,
          meetingPoints: punter.total,
          winners: punter.winners,
          secondPlaces: punter.secondPlace,
          thirdPlaces: punter.thirdPlace
        });
      });

      state.currentLeaderboardPageData = punterInfo;
      state.isFetchingLeaderboard = false;
    });

    builder.addCase(getPunterInfoFromSearch.rejected, (state) => {
      state.currentLeaderboardPageData = [];
      state.isFetchingLeaderboard = false;
    });

    builder.addCase(getStatsData.fulfilled, (state, action) => {
      const { responseMessage, responseStatus, currentPunterLeagueStats } =
        action.payload;

      if (responseMessage === "Success." && responseStatus === 1) {
        state.leagueStatsData = currentPunterLeagueStats;
      }

      console.log("getLeagueLeaderboard.fulfilled state");
    });

    builder.addCase(deleteALeague.fulfilled, (state, action) => {
      const { ownedLeagues, joinedLeagues } = action.payload.updatedList;

      state.myLeagues = ownedLeagues;
      state.joinedLeagues = joinedLeagues;

      console.log("deleteALeague.fulfilled state");
    });

    builder.addCase(
      checkHasLeaderboardDataForMeeting.fulfilled,
      (state, action) => {
        const { data, meetingid } = action.payload;
        const { punters } = data.dailyLeaderboard;
        console.log(action.payload);

        if (!state.mainLeaderboardStatusData) {
          state.mainLeaderboardStatusData = {};
        }

        state.mainLeaderboardStatusData[meetingid] = {
          meetingId: meetingid,
          hasResulted: punters?.length > 0
        };

        console.log("checkHasLeaderboardDataForMeeting.fulfilled state");
      }
    );
  }
});

export const {
  resetLeagueCreationData,
  shareALeague,
  resetJoinALeagueData,
  displayManageForLeague,
  reset,
  openLeaderboard,
  setCurrentMeeting,
  closeLeaderboard,
  setIsQueriedLeagueToJoinChecked
} = FriendLeagueSlice.actions;

export default FriendLeagueSlice.reducer;
