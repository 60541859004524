import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCloseicon from "@assets/bs-close-info-x.svg";
import InfoIcon from "@assets/lp-information-icon.svg";
import { RootState } from "../../../../../store/store";
import { getBalanceFormatted } from "../../../../../utils/currencyFormatUtils";
import {
  HeaderDiv,
  BetSlipTitle,
  BalanceDiv,
  MeetingNameDiv,
  LuckyPickBetslipToggleButton,
  IconDiv,
  InfoImageDisplay2,
  LPInfoTextDiv,
  LPTextScrollDiv,
  LPInfoText
} from "./betSlipHeader.style";
import { ToggleAllDiv, ToggleAllText } from "../betSlipView.styled";
import ToggleBetType from "../betTypeDisplay/toggleBetType";
import { setAllBetTypeToggle } from "../../../../../store/features/newBetSlipSlice";
import theme from "../../../../../theme";
import FlexContainer from "../../../../bricks/flexContainer";
import { toggleLuckyPick } from "../../../../../store/features/betslipSlice";

const TOGGLES = ["LUCKY PICK", "BETSLIP"];

interface IBetSlipHeaderProps {
  forcedSP: boolean;
  zeroOddsSelected: boolean;
}

const BetSlipHeader = ({ forcedSP, zeroOddsSelected }: IBetSlipHeaderProps) => {
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(true);
  const [openInfo, setOpenInfo] = useState(false);
  const { meeting, races } = useSelector(
    (state: RootState) => state.betslipNew
  );
  const { balance } = useSelector((state: RootState) => state.user);
  const { meetingName } = meeting;
  const [toggleIndex, setToggleIndex] = useState<number>(0);
  const toShowLuckyPick = useSelector(
    (state: RootState) => state.config.data.showLuckyPick
  );

  const getRacesRemaining = () => {
    if (races.length > 0) {
      return `Race ${races[0].raceNumber} - ${
        races[races.length - 1].raceNumber
      }`;
    }

    return "";
  };

  const onAllSPOddsToggle = (checked: boolean) => {
    setIsToggled(checked);
    dispatch(setAllBetTypeToggle(checked));
  };

  const onToggleClick = () => {
    setToggleIndex(toggleIndex === 0 ? 1 : 0);
    dispatch(toggleLuckyPick(toggleIndex === 0));
  };

  const canDisableLuckyPickToggle = () => {
    return races.length > 0 && races[0].raceNumber > 1;
  };

  return (
    <HeaderDiv>
      <FlexContainer
        width="100%"
        bgcolor="transparent"
        justifycontent="space-between">
        <BetSlipTitle>Betslip</BetSlipTitle>
        {toShowLuckyPick && (
          <LuckyPickBetslipToggleButton
            onClick={!canDisableLuckyPickToggle() ? onToggleClick : undefined}
            toDisable={canDisableLuckyPickToggle()}>
            <span>{TOGGLES[toggleIndex]}</span>
          </LuckyPickBetslipToggleButton>
        )}
      </FlexContainer>
      <MeetingNameDiv>{meetingName.toUpperCase()}</MeetingNameDiv>
      <span>{getRacesRemaining()}</span>
      <BalanceDiv>
        <span>
          Balance:{" "}
          <b>{getBalanceFormatted(Math.abs(balance?.balanceAvailable ?? 0))}</b>
        </span>
        {toggleIndex === 0 && (
          <ToggleAllDiv>
            <ToggleAllText>SELECT ODDS</ToggleAllText>
            <ToggleBetType
              onOddsTypeToggled={onAllSPOddsToggle}
              isFixedOdds={forcedSP ? !isToggled : isToggled}
              textColor={theme.colors.yellowPrimary}
              backgroundColor={theme.colors.primary}
              handleColor={theme.colors.purpleSecondary}
              zeroOddsSelected={zeroOddsSelected}
            />
          </ToggleAllDiv>
        )}

        {toggleIndex === 1 && (
          <>
            <IconDiv>
              <InfoImageDisplay2
                src={openInfo ? InfoCloseicon : InfoIcon}
                onClick={() => setOpenInfo(!openInfo)}
              />
            </IconDiv>
            {openInfo && (
              <LPInfoTextDiv>
                <LPTextScrollDiv>
                  <LPInfoText as="ul">
                    <li>
                      Click the refresh button to have a random horse selected.
                    </li>
                    <li>
                      Click on the horses name that you would like to change.
                    </li>
                  </LPInfoText>
                </LPTextScrollDiv>
              </LPInfoTextDiv>
            )}
          </>
        )}
      </BalanceDiv>
    </HeaderDiv>
  );
};

export default BetSlipHeader;
