import theme from "../../../../theme";
import Button from "../../../bricks/button";
import FlexContainer from "../../../bricks/flexContainer";
import { WEB_HEADER_TABS } from "../constants/friendsLeaguesConstants";
import { MediaContainer } from "../leaguesCommon/leagueButtons.style";

interface ILeaguesHeader {
  statsCallback: () => void;
  actionCallBacks: Array<(dispayText: string) => void>;
  activeButton: string;
  totalOwnedLeagues: number;
}
const SHOULD_SHOW_MY_STATS = false;

const LeaguesHeader = ({
  statsCallback,
  actionCallBacks,
  totalOwnedLeagues,
  activeButton
}: ILeaguesHeader) => {
  return (
    <FlexContainer
      width="100%"
      minheight="20px"
      bgcolor={`linear-gradient(${theme.colors.purpleSecondary},${theme.colors.purplePrimary})`}
      bordertopleftradius="5px"
      bordertoprightradius="5px"
      padding="10px 0px 10px 0px"
      alignitems="center">
      <MediaContainer>
        <FlexContainer
          width="51%"
          columngap="25px"
          bgcolor="transparent"
          fontSize="min(0.8rem,1vw)"
          paddingleft="15px">
          {WEB_HEADER_TABS.map((item, index) => {
            return (
              <Button
                key={`LeaguesHeaderTab${item}`}
                fontWeight={item === activeButton ? "bold" : "normal"}
                width="25%"
                borderWidth="0.5"
                backgroundcolor={
                  item === activeButton
                    ? theme.colors.lightAliceBlue
                    : "transparent"
                }
                borderradius="5px"
                fontcolor={
                  item === activeButton
                    ? theme.colors.purplePrimary
                    : theme.colors.primary
                }
                bordercolor={
                  item === activeButton
                    ? theme.colors.purplePrimary
                    : theme.colors.primary
                }
                text={item}
                onClick={() => {
                  actionCallBacks[index](item);
                }}
                disabled={item === "Delete A League" && totalOwnedLeagues === 0}
              />
            );
          })}
        </FlexContainer>

        <FlexContainer
          width="47%"
          columngap="10px"
          bgcolor="transparent"
          justifycontent="flex-end"
          paddingright="10px">
          {SHOULD_SHOW_MY_STATS && (
            <Button
              width="30%"
              background={`linear-gradient(${theme.colors.yellowPrimary},${theme.colors.yellowSecondary})`}
              fontcolor={theme.colors.purplePrimary}
              fontSize="1rem"
              fontWeight="bold"
              borderWidth="0.5"
              borderradius="5px"
              bordercolor={theme.colors.primary}
              text="My Stats"
              onClick={statsCallback}
            />
          )}
        </FlexContainer>
      </MediaContainer>
    </FlexContainer>
  );
};

export default LeaguesHeader;
