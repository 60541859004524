import { useContext } from "react";
import styled from "styled-components";
import FlexContainer from "../../../bricks/flexContainer";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import {
  TargetDeviceHeight,
  mediaSizeBelow
} from "../../../../utils/mediaUtils";

const WrappingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: min(4.7vw, 1.5rem);
  ${mediaSizeBelow(TargetDeviceHeight.iPhoneSE, `font-size: 1.1rem; `)};
`;

const NumberOneDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;
  return (
    <FlexContainer direction="column" alignitems="center" width="83%">
      {isDesktop ? (
        <TextInSpan
          fontSize="min(4.7vw,1.5rem)"
          fontWeight="bold"
          lineHeight={1.5}
          color="white"
          shadow="2px 2px 6px black">
          SOUTH AFRICA'S
          <TextInSpan color="yellow" shadow="2px 2px 6px black">
            <b> #1 FREE TO PLAY </b>
          </TextInSpan>
          <TextInSpan
            fontSize="min(4.7vw,1.5rem)"
            fontWeight="bold"
            lineHeight={1.5}
            color="white"
            shadow="2px 2px 6px black">
            HORSE RACING TIPPING COMPETITION!
          </TextInSpan>
        </TextInSpan>
      ) : (
        <WrappingDiv>
          <TextInSpan
            fontWeight="bold"
            lineHeight={1.5}
            color="white"
            shadow="2px 2px 6px black">
            SOUTH AFRICA'S
            <TextInSpan color="yellow" shadow="2px 2px 6px black">
              <b> #1 FREE TO PLAY</b>
            </TextInSpan>
          </TextInSpan>
          <TextInSpan
            fontWeight="bold"
            lineHeight={1.5}
            color="white"
            shadow="2px 2px 6px black">
            HORSE RACING TIPPING
          </TextInSpan>
          <TextInSpan
            fontWeight="bold"
            lineHeight={1.5}
            color="white"
            shadow="2px 2px 6px black">
            COMPETITION!
          </TextInSpan>
        </WrappingDiv>
      )}
    </FlexContainer>
  );
};

export default NumberOneDisplay;
