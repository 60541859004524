import { useContext } from "react";
import BoxedMessage from "./common/BoxedMessage";
import TextInSpan from "../../../bricks/textInSpan";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";

const AboutTheDisplay = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  return (
    <BoxedMessage
      gap="15px"
      paddingLeft="10px"
      paddingRight="10px"
      paddingTop="20px"
      paddingBottom="20px"
      width={isDesktop ? "calc(80% - 20px)" : "70%"}
      opacity={1}>
      <TextInSpan fontWeight="bold" fontSize="1rem" zIndex={20}>
        About the Hollywood Punters Challenge
      </TextInSpan>
      <TextInSpan fontSize="0.9rem" zIndex={20} marginTop="5px">
        Welcome to the Hollywoodbets Punters Challenge, where thrilling racing
        excitement meets rewarding opportunities!
      </TextInSpan>
      <TextInSpan fontSize="0.9rem" zIndex={20} marginTop="5px">
        Since our inception on 7th June 2020, we've been the premier platform
        for racing enthusiasts to test their tipping skills and win big!
      </TextInSpan>
    </BoxedMessage>
  );
};

export default AboutTheDisplay;
