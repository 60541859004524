import { GeneralContainer, HeadingContainer } from "./headingsContainer.style";

const HeadingsContainer = () => {
  return (
    <HeadingContainer>
      <GeneralContainer width={8}>
        <span>Race</span>
      </GeneralContainer>
      <GeneralContainer width={8}>
        <span>No.</span>
      </GeneralContainer>
      <GeneralContainer width={50} justifyContent="flex-start">
        <span>Horse name</span>
      </GeneralContainer>
      <GeneralContainer width={20}>
        <span>Pts</span>
        <span>Pos</span>
      </GeneralContainer>
    </HeadingContainer>
  );
};

export default HeadingsContainer;
