import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import FlexContainer from "../bricks/flexContainer";
import Container from "../bricks/container";
import { MENU_ITEMS_LOGGED_IN, MENU_ITEMS_LOGGED_OUT } from "../../constants";
import { LoggedInSelector, logout } from "../../store/features/userSlice";

const SELECTED_BACKGROUND = "linear-gradient(#764CBF,#764CBF,#5C2D91)";
const BACKGROUND = "linear-gradient(#fff,#fff,#e4e4e7,#e4e4e7)";

const COLOR = "black";
const SELECTED_COLOR = "white";

const ButtonContainer = styled(Container)`
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 4px;
  line-height: 1;
  font-weight: bold;
  box-shadow: 2px 2px 2px #bbbbc3;
  width: 25vw;
  height: 1.4rem;
  border: 0px solid #e4e4e7;

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    font-size: 0.6rem;
  }
  @media screen and (min-width: 1251px) and (max-width: 1494px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1495px) {
    font-size: 0.8rem;
    padding-top: 6px;
  }
`;

const Tab = (
  text: string,
  isSelected: boolean,
  handleClick: (text: string) => void
) => (
  <ButtonContainer
    key={text}
    onClick={() => {
      handleClick(text);
    }}
    bgcolor={isSelected ? SELECTED_BACKGROUND : BACKGROUND}
    color={isSelected ? SELECTED_COLOR : COLOR}>
    {" "}
    {text}
  </ButtonContainer>
);
const NavigationTabs = ({
  filteringFunction
}: {
  filteringFunction: (item: string) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(LoggedInSelector);
  const [currentTab, setCurrentTab] = useState<string>();

  const list = isLoggedIn ? MENU_ITEMS_LOGGED_IN : MENU_ITEMS_LOGGED_OUT;

  const handleClick = (key: string) => {
    if (key === "Logout") dispatch(logout({}));
    setCurrentTab(key);
    const path = list[key];
    navigate(path);
  };

  const getName = (path: string) =>
    Object.keys(list).find((val) => {
      if (list[val] === path) return val;
      return null;
    }) ?? "Home";

  useEffect(() => {
    if (
      isLoggedIn &&
      (location.pathname.substring(1) === "results" ||
        location.pathname.substring(1) === "friendsLeague") &&
      currentTab === "Home"
    ) {
      setCurrentTab(getName(location.pathname.substring(1)));
    } else if (
      !isLoggedIn &&
      (currentTab === "Results" || currentTab === "friendsLeague")
    ) {
      navigate("home");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (currentTab && currentTab !== getName(location.pathname.substring(1))) {
      setCurrentTab(getName(location.pathname.substring(1)));
    }
  }, [location]);

  useEffect(() => {
    setCurrentTab(getName(location.pathname.substring(1)));
  }, []);

  return (
    <FlexContainer columngap="10px" width="100%" alignitems="center">
      {isLoggedIn
        ? Object.keys(MENU_ITEMS_LOGGED_IN)
            .filter(filteringFunction)
            .map((item) => Tab(item, item === currentTab, handleClick))
        : Object.keys(MENU_ITEMS_LOGGED_OUT).map((item) =>
            Tab(item, item === currentTab, handleClick)
          )}
    </FlexContainer>
  );
};

export default NavigationTabs;
