import FlexContainer from "../../../bricks/flexContainer";

type Props = {
  children: JSX.Element | JSX.Element[];
};
const HomeScrollContainer = ({ children }: Props) => {
  return (
    <FlexContainer
      direction="column"
      width="100%"
      height="fit-content"
      rowgap="15px"
      alignitems="center"
      position="relative">
      {children}
    </FlexContainer>
  );
};

export default HomeScrollContainer;
