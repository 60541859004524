import styled from "styled-components";
import { DIRECTIONS } from "../../constants";

export interface IArrowHead {
  thickness?: number;
  direction?: string;
  color?: string;
  width?: string;
  height?: string;
  callbackForClick?: () => void;
}

const ArrowImage = styled.div<{
  color?: string;
  thickness?: number;
  degree?: string;
  width?: string;
  height?: string;
}>`
 
  border:${(props): string => `solid ${props.color ?? "black"}`};
  border-width:${(props) => `0 ${props.thickness ?? 3}px ${props.thickness ?? 3}px 0`} ;
  display: inline-block;
  padding: 3px;
  transform:${(props) => `rotate(${props.degree ?? "45deg"}) translate(-25%,-25%)`}; 
  -webkit-transform::${(props) => `rotate(${props.degree ?? "45deg"})`}; 
  width:${(props) => props.width ?? "10px"};
  height:${(props) => props.height ?? "10px"};
  cursor:pointer;
  `;

const getDegree = (direction: string | undefined): string => {
  if (direction === DIRECTIONS.up) {
    return "-135deg";
  }
  if (direction === DIRECTIONS.down) {
    return "45deg";
  }
  if (direction === DIRECTIONS.left) {
    return "135deg";
  }
  if (direction === DIRECTIONS.right) {
    return "-45deg";
  }

  return "";
};

const ArrowHead = ({
  thickness,
  direction,
  color,
  width,
  height,
  callbackForClick
}: IArrowHead) => {
  return (
    <ArrowImage
      color={color}
      thickness={thickness}
      degree={getDegree(direction)}
      onClick={callbackForClick}
      width={width}
      height={height}
    />
  );
};

export default ArrowHead;
