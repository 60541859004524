import styled from "styled-components";

export const LabelValueContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: center;
`;

export const LabelDisplay = styled.div`
  width: max-content;
  font-size: clamp(0.5rem, 2.5vw, 18px);
`;

export const BoxedValue = styled.div`
  display: grid;
  place-content: center;
  max-width: 2rem;
  width: 7vw;
  height: auto;
  background: white;
  border-radius: 5px;
  border: ${(props) => `1px solid ${props.theme.colors.purpleLight2}`};
  box-shadow: ${(props) => `2px 2px 2px ${props.theme.colors.lightGray}`};
  font-size: min(3.5vw, 1rem);
  padding: 3px 0px 1px 0px;
  line-height: 1;
`;
