import { useContext } from "react";
import { useSelector } from "react-redux";
import theme from "../../../../theme";
import Container from "../../../bricks/container";
import FlexContainer from "../../../bricks/flexContainer";
import StatsValueDisplay from "./statsValueDisplay";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import { RootState } from "../../../../store/store";

const StatsNumberOfTimes = () => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const { top10, top50, top500, top1000 } = useSelector(
    (state: RootState) => state.friendsLeague.leagueStatsData! || {}
  );

  return (
    <FlexContainer
      direction="column"
      width="100%"
      height="auto"
      border={`1px solid ${theme.colors.lightGray}`}
      bgcolor={theme.colors.lightAliceBlue}
      borderradius="5px"
      alignitems="center"
      rowgap="10px">
      <FlexContainer
        width="98%"
        height="auto"
        bordertopleftradius="5px"
        bordertoprightradius="5px">
        <FlexContainer
          direction="column"
          marginleft="5px"
          color={theme.colors.purplePrimary}
          margintop="10px"
          width="100%">
          <Container textalign="left" fontWeight="bold" fontSize="1rem">
            The number of times you have finished in the:
          </Container>
        </FlexContainer>
      </FlexContainer>

      <Container width="100%" height="1px" bgcolor={theme.colors.lightGray} />

      <FlexContainer
        width="100%"
        height="auto"
        bgcolor={theme.colors.primary}
        margintop="-10px"
        borderbottomleftradius="5px"
        borderbottomrightradius="5px"
        fontWeight="bold"
        justifycontent="space-between">
        <StatsValueDisplay
          title="Top 10"
          value={top10}
          marginLeft={`${isDesktop ? 50 : 10}px`}
        />
        <StatsValueDisplay
          title="Top 50"
          value={top50}
          marginLeft={`${isDesktop ? 70 : 10}px`}
        />
        <StatsValueDisplay
          title="Top 500"
          value={top500}
          marginLeft={`${isDesktop ? 70 : 0}px`}
        />
        <StatsValueDisplay
          title="Top 1000"
          value={top1000}
          marginLeft={`${isDesktop ? 60 : 5}px`}
          marginRight="10px"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default StatsNumberOfTimes;
