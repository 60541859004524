import { memo } from "react";
import Container from "../../../../bricks/container";
import ValueDisplay from "./valueDisplay";

export interface IItemYourMostWins {
  mostWin: string;
  highestWin: string;
  highestWinCourse: string;
  highestWinDate: string;
}

const ItemYourMostWins = ({
  mostWin,
  highestWin,
  highestWinCourse,
  highestWinDate
}: IItemYourMostWins) => {
  const isDataAvailable =
    mostWin !== "undefined" &&
    highestWin !== "undefined" &&
    highestWinCourse !== "undefined" &&
    highestWinDate !== "undefined";

  return (
    <>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.9 : 0.6}rem`}
        lineheight="1"
        fontWeight={isDataAvailable ? "bold" : "normal"}>
        {isDataAvailable && (
          <span>
            The most wins you had in a day is <ValueDisplay value={mostWin} />
            <span>.</span>
          </span>
        )}
        {!isDataAvailable && `Data not available.`}
      </Container>
      <Container
        textalign="left"
        fontSize={`${isDataAvailable ? 0.7 : 0.6}rem`}
        fontStyle="italic">
        {isDataAvailable &&
          `Highest win by a punter is ${highestWin} (${highestWinCourse}, ${highestWinDate}).`}
        {!isDataAvailable && `Data not available.`}
      </Container>
    </>
  );
};

export default memo(ItemYourMostWins);
