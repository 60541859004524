import SearchIcon from "@assets/search-icon.svg";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexContainer from "../../bricks/flexContainer";

import {
  CurrentLeaderboardPage,
  GetLeaderboardForMeetingWithPage
} from "../../../store/features/leaderboardSlice";
import type { RootState } from "../../../store/store";
import { ImageDiv, InputDiv } from "./searchBar.style";

const SearchBar = () => {
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const currentPage = useSelector(CurrentLeaderboardPage);
  const currentMeetingId = useSelector(
    (state: RootState) => state.leaderboard.currentMeetingId
  );

  const searchForFriends = (textToSearch: string) => {
    dispatch(
      GetLeaderboardForMeetingWithPage({
        pageId: currentPage,
        meetingId: currentMeetingId,
        numberPerPage: 10,
        searchFor: textToSearch
      })
    );
  };

  return (
    <FlexContainer
      width="90%"
      paddingleft="5%"
      paddingright="5%"
      bgcolor="#F4F6FD"
      border="1px solid #5C2D91"
      height="50px"
      columngap="10px"
      alignitems="center"
      marginbottom="5px"
      justifycontent="center"
      borderradius="5px 5px 0 0">
      <FlexContainer
        paddingleft="5%"
        width="100%"
        height="60%"
        bgcolor="white"
        border="1px solid #5C2D91"
        borderradius="30px"
        padding="0"
        justifycontent="flex-end"
        alignitems="center">
        <InputDiv
          ref={searchRef}
          placeholder="Search for your friends"
          name="leaderboard-search-text"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchForFriends(searchRef?.current?.value);
            }
          }}
        />
        <ImageDiv
          src={SearchIcon}
          onClick={() => {
            searchForFriends(searchRef?.current?.value);
          }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default SearchBar;
