import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexContainer from "../../../bricks/flexContainer";
import { MediaQueriesContext } from "../../../../contexts/mediaQueriesContext";
import Container from "../../../bricks/container";
import theme from "../../../../theme";
import { RootState } from "../../../../store/store";
import {
  IMyLeagueItem,
  checkHasLeaderboardDataForMeeting,
  closeLeaderboard,
  getLeagueLeaderboard,
  setCurrentMeeting
} from "../../../../store/features/friendsLeagueSlice";
import DropDown, { IDropdownItem } from "../../dropdown";
import {
  getTimeRemainingInMilliSeconds,
  isBeforeToday,
  isToday
} from "../../../../utils/dateUtils";
import LeagueLeaderboardCard from "../../../composites/leaderboard-card/leaderboardCard";
import LeaderboardFooter from "../../../composites/leaderboard-card/leaderboard-footer/leaderboardFooter";
import { MapFromLeagueLeaderboardItem } from "../../../../utils/leaderboardUtils";
import { getMeetingData } from "../../../../store/features/meetingsSlice";

interface ILeagueLeaderboard {
  selectedLeague: IMyLeagueItem;
}

interface IMeetingData {
  date: string;
  id: string;
  raceCourseName: string;
  tabGoldRef: string;
}

const PUNTERS_PER_PAGE = 10;
const FREQUENCY_TO_CHECK_MAIN_LEADERBOARD_IN_MINUTES = 2;

const LeagueLeaderboard = ({ selectedLeague }: ILeagueLeaderboard) => {
  const { isDesktop } = useContext(MediaQueriesContext).data;

  const dispatch = useDispatch();

  const { friendsLeague } = useSelector((state: RootState) => state);

  const { myLeagues, currentLeaderboardPageData, mainLeaderboardStatusData } =
    useSelector((state: RootState) => state.friendsLeague);

  const { punterId, data } = useSelector((state: RootState) => state.user);
  const { meetings } = useSelector((state: RootState) => state.meetings);

  const [dropdownList, setDropdownList] = useState<IDropdownItem[] | null>(
    null
  );
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const currentSelectedMeeting = useRef<IMeetingData | null>(null);
  const pageNumberToQuery = useRef<number>(1);
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const meetingDataWaitedForId = useRef<string | null>(null);

  const competitionData = data?.competition;

  const selectedMeetingIsToday = useRef<boolean>(false);

  const timerForFirstRace = useRef<ReturnType<typeof setTimeout> | null>(null);
  const intervalToTrackMainLeaderboardStatus = useRef<ReturnType<
    typeof setInterval
  > | null>(null);

  const [isAllowedToViewOthersSelections, setIsAllowedToViewOthersSelections] =
    useState<boolean>(false);

  const sendQuery = (newMeetingId: number | null) => {
    const meetingId = newMeetingId ?? currentSelectedMeeting.current?.id;
    const { leagueCode } = selectedLeague;

    if (meetingId && leagueCode) {
      dispatch(
        getLeagueLeaderboard({
          punterId,
          meetingId: Number(meetingId),
          leagueCode,
          pageNumber: pageNumberToQuery.current
        })
      );
    }
  };

  const clearFirstRaceTimer = () => {
    if (timerForFirstRace.current) {
      clearTimeout(timerForFirstRace.current);
      timerForFirstRace.current = null;
    }
  };

  const clearPollingInterval = () => {
    if (intervalToTrackMainLeaderboardStatus.current) {
      clearInterval(intervalToTrackMainLeaderboardStatus.current);
      intervalToTrackMainLeaderboardStatus.current = null;
    }
  };

  const killAllTimers = () => {
    clearFirstRaceTimer();
    clearPollingInterval();
  };

  const startPollingToCheckMainLeaderBoardStatus = () => {
    intervalToTrackMainLeaderboardStatus.current = setInterval(
      () => {
        dispatch(
          checkHasLeaderboardDataForMeeting({
            punterId,
            meetingId: currentSelectedMeeting.current?.id
          })
        );
      },
      FREQUENCY_TO_CHECK_MAIN_LEADERBOARD_IN_MINUTES * 60 * 1000
    );
  };

  const onTimeElapsedToFirstRace = () => {
    clearFirstRaceTimer();

    startPollingToCheckMainLeaderBoardStatus();
  };

  const checkIfOthersSelectionCanBeViewed = () => {
    const currentMeetingData = meetings[currentSelectedMeeting.current?.id!];

    if (currentMeetingData?.races?.length) {
      const firstRaceTime: string = currentMeetingData.races[0]
        .raceTime as string;

      const timeRemainingForFirstRace =
        getTimeRemainingInMilliSeconds(firstRaceTime);

      const canViewOthersSelections = !(
        selectedMeetingIsToday.current && timeRemainingForFirstRace > 0
      );

      setIsAllowedToViewOthersSelections(canViewOthersSelections);

      if (!canViewOthersSelections) {
        timerForFirstRace.current = setTimeout(
          onTimeElapsedToFirstRace,
          timeRemainingForFirstRace
        );
      }
    }
  };

  const checkAndSendMeetingDataRequest = (isForToday: boolean, id: string) => {
    if (!isForToday) return;

    if (!meetings[id]) {
      meetingDataWaitedForId.current = id;

      dispatch(getMeetingData({ punterId, meetingId: id }));
    } else {
      checkIfOthersSelectionCanBeViewed();
    }
  };
  const onDropDownSelection = (data: IDropdownItem) => {
    setIsFetching(true);

    killAllTimers();

    currentSelectedMeeting.current = data.data;
    const { date, id } = currentSelectedMeeting.current!;

    selectedMeetingIsToday.current = isToday(date);

    if (!selectedMeetingIsToday.current) {
      setIsAllowedToViewOthersSelections(true);
    }

    checkAndSendMeetingDataRequest(selectedMeetingIsToday.current, id);

    pageNumberToQuery.current = 1;
    dispatch(
      setCurrentMeeting({ id: data.data.id, name: data.data.raceCourseName })
    );

    sendQuery(Number(data.data.id));
  };

  useEffect(() => {
    if (competitionData) {
      const ddListData: IDropdownItem[] = competitionData?.meetings
        .filter((item: any) => isToday(item.date) || isBeforeToday(item.date))
        .map(
          (item: any) =>
            ({
              label: `${item.date.split("T")[0]} - ${item.raceCourseName}`,
              data: item
            }) as IDropdownItem
        );

      setDropdownList(ddListData);
    }
  }, [competitionData]);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false);
    }
  }, [currentLeaderboardPageData]);

  useEffect(() => {
    return () => {
      killAllTimers();
      dispatch(closeLeaderboard());
    };
  }, []);

  useEffect(() => {
    if (meetings) {
      if (
        meetingDataWaitedForId.current &&
        isFetching &&
        currentLeaderboardPageData
      ) {
        setIsFetching(false);
      }

      checkIfOthersSelectionCanBeViewed();
    }
  }, [meetings]);

  useEffect(() => {
    if (scrollContainer.current) {
      const { scrollWidth, children } = scrollContainer.current;
      const index =
        myLeagues?.findIndex(
          (league) => league.leagueCode === selectedLeague.leagueCode
        ) ?? 0;
      const childWidth = scrollWidth / children.length;

      scrollContainer.current.scrollBy({
        left: childWidth * index,
        behavior: "instant"
      });
    }
  }, []);

  const pageTo = (page: number) => {
    pageNumberToQuery.current = page;

    sendQuery(null);
  };

  useEffect(() => {
    if (mainLeaderboardStatusData) {
      const mainLeaderboardDataForMeeting =
        mainLeaderboardStatusData[currentSelectedMeeting.current?.id!];

      if (mainLeaderboardDataForMeeting?.hasResulted) {
        clearPollingInterval();
        setIsAllowedToViewOthersSelections(true);
      }
    }
  }, [mainLeaderboardStatusData]);

  return (
    <FlexContainer
      minheight="200px"
      direction="column"
      width={`${isDesktop ? 98 : 100}%`}
      rowgap={`${isDesktop ? 20 : 8}px`}
      borderradius="5px"
      border={`1px solid ${theme.colors.lightGray}`}
      bgcolor={theme.colors.lightAliceBlue}
      height="max-content"
      alignitems="center">
      <FlexContainer
        bordertopleftradius="6px"
        bordertoprightradius="6px"
        paddingtop="10px"
        paddingleft={`${isDesktop ? 12 : 0}px`}
        paddingbottom={`${isDesktop ? 10 : 0}px`}
        alignitems="center"
        width="98%"
        justifycontent="space-between">
        <Container
          textalign="left"
          color={theme.colors.purplePrimary}
          fontWeight="bold"
          fontSize="1.1rem"
          width="100%">
          <span>{selectedLeague?.leagueName ?? ""} - Leaderboard</span>
        </Container>
      </FlexContainer>

      <FlexContainer
        direction="column"
        position="relative"
        width="98%"
        height="max-content">
        <DropDown data={dropdownList!} onSelection={onDropDownSelection} />

        <Container margintop="10px" width="100%" marginbottom="15px">
          <LeagueLeaderboardCard
            currentMeetingId={Number(currentSelectedMeeting.current?.id)}
            punterId={Number(punterId)}
            isFetching={isFetching}
            currentPuntersList={MapFromLeagueLeaderboardItem(
              currentLeaderboardPageData
            )}
            isWaitingForMeetingSelection={
              currentSelectedMeeting.current === null
            }
            canViewOthersSelection={isAllowedToViewOthersSelections}
          />
          <LeaderboardFooter
            totalPages={friendsLeague.currentLeaderboardTotalPages}
            meetingName={currentSelectedMeeting.current?.raceCourseName ?? ""}
            meetingDate={currentSelectedMeeting.current?.date ?? ""}
            puntersPerPage={PUNTERS_PER_PAGE}
            onPageClick={pageTo}
            borderRadius="3px"
          />
        </Container>
      </FlexContainer>
    </FlexContainer>
  );
};

export default LeagueLeaderboard;
