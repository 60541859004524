/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexContainer from "../../bricks/flexContainer";
import QuickresultItem from "./quickresultItem";
import Container from "../../bricks/container";
import type { RootState } from "../../../store/store";
import { isHandHeldDevice } from "../../../utils/deviceUtils";
import { isBeforeToday, isToday } from "../../../utils/dateUtils";
import {
  GetLeaderboardForMeeting,
  ICurrentMeetingMetaData,
  ILeaderboardPunterUnit,
  IQuickResultItem,
  clearPuntersList
} from "../../../store/features/leaderboardSlice";

import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";
import { IMeetingMetaData } from "../../../store/features/meetingsSlice";

const NUM_TO_SHOW_IN_QUICK_RESULTS: number = 5;

type MeetingData = { id: number; raceName: string; date: string };

const QuickResults = () => {
  const dispatch = useDispatch();

  const { isDesktop } = useContext(MediaQueriesContext).data;
  const quickResultsMeetingIds = useRef<Array<MeetingData> | null>(null);

  const { punterId, userId, data, balance } = useSelector(
    (state: RootState) => state.user
  );
  const { lbMeetingsMetaData, puntersinMeetingsWithPage, currentMeetingId } =
    useSelector((state: RootState) => state.leaderboard);

  const meetingMetaData = useSelector(
    (state: RootState) => state.meetings.meetingsMetaData
  );

  const competitionData = data?.competition;

  const tempQuickResultsData = useRef<IQuickResultItem[] | null>(null);

  const [quickResultsData, setQuickResultsData] = useState<
    IQuickResultItem[] | null
  >();

  const inRefreshMeetingIds = useRef<number[]>([]);
  const todaysMeetingsIds = useRef<number[]>([]);

  const processMatchedMeetingData = (
    matchedMeetingData: IQuickResultItem | null,
    puntersOwnData: ILeaderboardPunterUnit | null | undefined,
    allPuntersInPage: ILeaderboardPunterUnit[]
  ): boolean => {
    if (matchedMeetingData) {
      puntersOwnData = allPuntersInPage.find((item) => item.userId === userId);

      if (
        puntersOwnData &&
        (Number(puntersOwnData?.rank) !== matchedMeetingData.rank ||
          Number(puntersOwnData?.total) !== matchedMeetingData.points)
      ) {
        matchedMeetingData.points = Number(puntersOwnData?.total);
        matchedMeetingData.rank = Number(puntersOwnData?.rank);

        return true;
      }
    }

    return false;
  };

  const handleRefreshOfData = () => {
    const tempQuickResultObj = JSON.parse(JSON.stringify(quickResultsData));

    let matchedMeetingData: IQuickResultItem | null = null;
    let changed = false;

    let allPuntersInPage = null;
    const puntersOwnData: ILeaderboardPunterUnit | null | undefined = null;

    inRefreshMeetingIds.current.forEach((meetingid) => {
      allPuntersInPage = puntersinMeetingsWithPage[meetingid][1];

      matchedMeetingData = tempQuickResultObj.find(
        (item) => meetingid === item.meetingId
      );

      changed = processMatchedMeetingData(
        matchedMeetingData,
        puntersOwnData,
        allPuntersInPage
      );
    });

    const index = inRefreshMeetingIds.current.findIndex(
      (indx) => indx === currentMeetingId
    );

    if (index >= 0) {
      inRefreshMeetingIds.current.splice(index, 1);
    }

    if (changed) {
      setQuickResultsData(tempQuickResultObj);
    }
  };

  const createTheQuickResultsObjects = () => {
    tempQuickResultsData.current = [];

    quickResultsMeetingIds.current!.forEach((meeting) => {
      const allPuntersInPage = puntersinMeetingsWithPage[meeting.id][1];
      const puntersOwnData = allPuntersInPage.find(
        (item) => item.userId === userId
      );

      const rank = puntersOwnData?.rank ?? "0";
      const total = puntersOwnData?.total ?? "0";

      tempQuickResultsData.current!.push({
        name: meeting.raceName,
        rank: Number(rank),
        points: Number(total),
        meetingId: meeting.id,
        meetingDate: meeting.date
      });

      if (isToday(meeting.date)) {
        todaysMeetingsIds.current.push(meeting.id);
      }
    });

    setQuickResultsData(tempQuickResultsData.current);
  };

  useEffect(() => {
    if (
      !tempQuickResultsData.current?.length &&
      puntersinMeetingsWithPage &&
      quickResultsMeetingIds.current?.length > 0 &&
      lbMeetingsMetaData
    ) {
      let count = 0;
      quickResultsMeetingIds.current!.forEach((meeting) => {
        if (puntersinMeetingsWithPage[meeting.id]) count += 1;
      });

      if (count === NUM_TO_SHOW_IN_QUICK_RESULTS) {
        createTheQuickResultsObjects();
      }

      dispatch(clearPuntersList());
    } else if (quickResultsData?.length === NUM_TO_SHOW_IN_QUICK_RESULTS) {
      handleRefreshOfData();
    }
  }, [puntersinMeetingsWithPage]);

  const getDefaultMeetingFromMetaData = (
    meetingMetaData: IMeetingMetaData[],
    lbMeetingsMetaData: ICurrentMeetingMetaData
  ): IMeetingMetaData | undefined => {
    const meetingmetaData = meetingMetaData.find(
      (item) =>
        lbMeetingsMetaData.meetingId === item.meetingId &&
        lbMeetingsMetaData.meetingDate === item.meetingDate
    );

    return meetingmetaData;
  };

  const createListItem = (
    meetingId: number,
    racename: string,
    date: string
  ) => {
    return {
      id: meetingId,
      raceName: racename || "",
      date: date || ""
    };
  };

  useEffect(() => {
    if (
      !quickResultsData?.length &&
      competitionData?.meetings &&
      lbMeetingsMetaData &&
      meetingMetaData?.length
    ) {
      let finalList: Array<MeetingData> = [];

      let currentLbMeetingName: string = "";
      if (lbMeetingsMetaData.meetingId) {
        const meetingmetaData = getDefaultMeetingFromMetaData(
          meetingMetaData,
          lbMeetingsMetaData
        );

        if (meetingmetaData?.raceCourseName && meetingmetaData?.meetingDate) {
          currentLbMeetingName = meetingmetaData?.raceCourseName;
          finalList.push(
            createListItem(
              Number(lbMeetingsMetaData.meetingId),
              meetingmetaData?.raceCourseName,
              String(meetingmetaData?.meetingDate)
            )
          );
        }
      }

      const restOfTodaysRaces = meetingMetaData.filter(
        (item) =>
          isToday(item.meetingDate) &&
          item.raceCourseName !== currentLbMeetingName
      );

      if (restOfTodaysRaces?.length) {
        const restOfTodaysRacesFormatted = restOfTodaysRaces.map((item) =>
          createListItem(
            Number(item.meetingId),
            item?.raceCourseName,
            String(item?.meetingDate)
          )
        );
        finalList = [...finalList, ...restOfTodaysRacesFormatted];
      }

      const numbersToPickFrompast =
        NUM_TO_SHOW_IN_QUICK_RESULTS - finalList.length;

      let count = -1;

      const prevMeetingsIdsList: Array<MeetingData> = competitionData?.meetings
        .filter(
          (item: any) =>
            isBeforeToday(item.date) && ++count < numbersToPickFrompast
        )
        .map((item: { id: string; raceCourseName: string; date: string }) =>
          createListItem(parseInt(item.id), item.raceCourseName, item.date)
        );

      finalList = finalList.concat(prevMeetingsIdsList);

      quickResultsMeetingIds.current = finalList;

      finalList.forEach((meeting) => {
        dispatch(GetLeaderboardForMeeting({ punterId, meetingId: meeting.id }));
      });
    }
  }, [competitionData, lbMeetingsMetaData, meetingMetaData]);

  useEffect(() => {
    if (inRefreshMeetingIds.current.length === 0) {
      todaysMeetingsIds.current?.forEach((id) => {
        inRefreshMeetingIds.current.push(id);
        dispatch(GetLeaderboardForMeeting({ punterId, meetingId: id }));
      });
    }
  }, [balance]);

  return quickResultsData?.length ? (
    <FlexContainer
      id="QR-main"
      bgcolor="#F3F3F4"
      width="100%"
      justifycontent="space-around"
      alignitems="center"
      margintop="-10px"
      marginbottom="-15px">
      {!isDesktop && (
        <FlexContainer
          id="QR-main-ch1"
          width="20%"
          maxwidth="120px"
          height="100%"
          borderright="0px solid black"
          justifycontent="flex-end"
          alignitems="center"
          paddingright="3px">
          <span
            style={{
              color: "#5C2D91",
              fontSize: "0.7rem",
              fontWeight: "bold",
              marginRight: "5px",
              width: "fit-content",
              lineHeight: "1"
            }}>
            {" "}
            Quick Results
          </span>
        </FlexContainer>
      )}

      <Container
        id="QR-main-ch2"
        width={!isDesktop ? "90vw" : "95%"}
        overflow="auto"
        margintop="4px"
        paddingright={!isDesktop ? "5px" : "0px"}
        paddingbottom={isHandHeldDevice() ? "7px" : "0px"}
        style={{ scrollBehaviour: "smooth" }}>
        <FlexContainer columngap="5px" width="max-content" minwidth="100%">
          {quickResultsData?.map((item) => (
            <QuickresultItem
              key={`QuickresultItem${item.name}${item.meetingDate}`}
              name={item.name}
              rank={item.rank}
              points={item.points}
              date={item.meetingDate}
              meetingId={item.meetingId}
            />
          ))}
        </FlexContainer>
      </Container>
    </FlexContainer>
  ) : (
    <> </>
  );
};

export default QuickResults;
